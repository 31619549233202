import { gql } from '@apollo/client';

// Doesn't include presenter because it varies between queries
export const AWARD_FRAGMENT = gql`
  fragment AwardFragment on CcrcAwardLevel {
    recogConfigId
    configName
    awardDescription
    notifyNomineeManagerUponFirstNomination
    notifyGiverAfterEachSubmission
    notifyNomineeUponFirstNomination
    awardType
    adjudicationConfiguration {
      id
      name
      adjudicationRounds {
        adjudicationType
        advancement {
          type
        }
        definedApprovers {
          defaultDefinedApprover {
            definedApproverUuid
            approver {
              id: identityUuid
              firstName
              lastName
              preferredName
              profile {
                avatar
              }
            }
          }
          definedApproversForBusinessUnits {
            definedApproverUuid
            approver {
              id: identityUuid
              firstName
              lastName
              preferredName
              profile {
                avatar
              }
            }
            businessUnit {
              buUuid
              buName
              parentBusinessUnit {
                buUuid
                buName
              }
            }
          }
        }
        screener {
          screener
          otherUserId
        }
        commentOnlyRound
      }
    }
    relatedFlexFields {
      flexFieldQuestion {
        id
        fieldName
        helperText
        characterLimit
        question
        immutable
      }
      required
      flexfieldAnswerVisibility
      displayOrder
    }
    active
    mediaOptions {
      useMedia
      allowedMedia
      requireMedia
    }
    presentation {
      presentedBy
      daysToPresent
      presenter {
        id: identityUuid
        firstName
        lastName
        preferredName
        profile {
          avatar
        }
      }
    }
  }
`;

export const ERROR_FRAGMENT = gql`
  fragment ErrorFragment on CcrcAwardLevelResponse {
    error {
      message
      code
    }
  }
`;
