import { DateTime } from 'luxon';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../utils/context/AppContext';
import { authFetch } from '../../utils/auth/Auth';
import {
  ContentVersion,
  ContentVersionForm,
  PrivacyNoticeResponse,
  PrivacyNoticeResponseSingle,
  TermsOfUseResponse,
  TermsOfUseResponseSingle,
} from './types';

const transformContentVersion = (
  contentVersion: ContentVersion<string>
): ContentVersion<DateTime> => ({
  ...contentVersion,
  createdOn: DateTime.fromISO(contentVersion.createdOn),
  deactivatedOn: contentVersion.deactivatedOn
    ? DateTime.fromISO(contentVersion.deactivatedOn)
    : null,
  effectiveOn: DateTime.fromISO(contentVersion.effectiveOn),
  modifiedOn: DateTime.fromISO(contentVersion.modifiedOn),
});

export const useTermsOfUse = () => {
  const [data, setData] = useState<ContentVersion<DateTime>[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const {
    config: { termsUri },
  } = useContext(AppContext);

  useEffect(() => {
    authFetch(`${termsUri}/v1/terms-of-use`)
      .then((res) => {
        if (res.ok) return res.json();
        throw res;
      })
      .then((data: TermsOfUseResponse) =>
        setData(data.termsOfUse.reverse().map(transformContentVersion))
      )
      .catch((res) => setError(res.statusText))
      .finally(() => setLoading(false));
  }, [termsUri]);

  const createTermsOfUseVersion = (contentVersion: ContentVersionForm) => {
    return authFetch(`${termsUri}/v1/terms-of-use`, {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify(contentVersion),
    })
      .then((res) => {
        if (res.ok) return res.json();
        throw res;
      })
      .then((data: TermsOfUseResponseSingle) => {
        const termsOfUse = transformContentVersion(data.termsOfUse);
        setData((prevData) => [termsOfUse, ...prevData]);
        return termsOfUse;
      })
      .catch((res) => setError(res.statusText));
  };

  return {
    data,
    loading,
    error,
    actions: { createTermsOfUseVersion },
  };
};

export const usePrivacyNotices = () => {
  const [data, setData] = useState<ContentVersion<DateTime>[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const {
    config: { termsUri },
  } = useContext(AppContext);

  useEffect(() => {
    authFetch(`${termsUri}/v1/privacy-notices`)
      .then((res) => {
        if (res.ok) return res.json();
        throw res;
      })
      .then((data: PrivacyNoticeResponse) =>
        setData(data.privacyNotices.reverse().map(transformContentVersion))
      )
      .catch((res) => setError(res.statusText))
      .finally(() => setLoading(false));
  }, [termsUri]);

  const createPrivacyNoticeVersion = (contentVersion: ContentVersionForm) => {
    return authFetch(`${termsUri}/v1/privacy-notices`, {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify(contentVersion),
    })
      .then((res) => {
        if (res.ok) return res.json();
        throw res;
      })
      .then((data: PrivacyNoticeResponseSingle) => {
        const privacyNotice = transformContentVersion(data.privacyNotice);
        setData((prevData) => [privacyNotice, ...prevData]);
        return privacyNotice;
      })
      .catch((res) => setError(res.statusText));
  };

  return {
    data,
    loading,
    error,
    actions: { createPrivacyNoticeVersion },
  };
};
