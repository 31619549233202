import { CloseSharp } from '@material-ui/icons';
import { MBAlert, MBWrapper } from './styles';
import { ErrorHandlerProps } from './types';

const ErrorHandlers = ({
  eligibilityWarning = false,
  setEligibilityWarning,
  programDetailError,
  setProgramDetailError,
  productCategoryError,
  setProductCategoryError,
  eligibilitySaveError,
  setEligibilitySaveError,
  createProgramError,
  setCreateProgramError,
}: ErrorHandlerProps) => {
  return (
    <MBWrapper>
      {eligibilityWarning && (
        <MBAlert
          severity="error"
          action={<CloseSharp onClick={() => setEligibilityWarning(false)} />}
        >
          There was an issue attaching the participants to this program. Please
          try again. If the issue persists, please submit an issue.
        </MBAlert>
      )}
      {programDetailError.enabled && (
        <MBAlert
          severity="error"
          action={
            <CloseSharp
              onClick={() =>
                setProgramDetailError((prev) => ({ ...prev, enabled: false }))
              }
            />
          }
        >
          Program details is missing required information.
        </MBAlert>
      )}
      {productCategoryError.enabled && (
        <MBAlert
          severity="error"
          action={
            <CloseSharp
              onClick={() =>
                setProductCategoryError((prev) => ({
                  ...prev,
                  enabled: false,
                }))
              }
            />
          }
        >
          Product category settings is missing required information.
        </MBAlert>
      )}
      {eligibilitySaveError?.enabled && (
        <MBAlert
          severity="error"
          action={
            <CloseSharp
              onClick={() =>
                setEligibilitySaveError({ message: '', enabled: false })
              }
            />
          }
        >
          {eligibilitySaveError?.message || ''}
        </MBAlert>
      )}
      {createProgramError && createProgramError.length && (
        <MBAlert
          severity="error"
          action={<CloseSharp onClick={() => setCreateProgramError('')} />}
        >
          {createProgramError}
        </MBAlert>
      )}
    </MBWrapper>
  );
};

export default ErrorHandlers;
