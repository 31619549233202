import BasicPadding from '../../../../common/BasicPadding';
import { ErrorHandler } from '../ErrorHandler';
import Typography from '@octanner/prism-core/Typography';
import { Add } from '@octanner/prism-icons';
import {
  AddAdminButton,
  AdminContainer,
  AdminListContainer,
  AlertContainer,
  DAButtonContainer,
  NoResultsContainer,
  TypographyMB,
} from './styles';
import { useHistory, useLocation } from 'react-router';
import {
  Box,
  FormControlLabel,
  Search,
  SearchOptionCard,
  Switch,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@octanner/prism-core';
import { useEffect, useState } from 'react';
import {
  AdminsHeadCells,
  tableRows,
  TEST_ADMINS_PAGED_DATA,
} from './constants';
import { useDebouncedCallback } from 'use-debounce';
import SingleRow from '../components/SingleRow';
import { Errors, SortConfig, Success, SuccessData } from '../types';
import { tannerGray } from '@octanner/prism-core/ThemeProvider/colors';
import { IdentityWithAdminRoles } from './types';
import SuccessHandler from '../SuccessHandler';
import {
  SearchContainer,
  SortAscFCM,
  SortDescFCM,
  SortFCM,
  TableMT,
  TypographyPZero,
} from '../styles';
import DeleteAdminModal from '../components/DeleteAdminModal';
import { useGetAdminSummary } from './hooks/getAdminSummary';
import AdminPagination from '../components/AdminPagination';

const Admins = () => {
  const [{ content: allData, total: totalCount }] = useState(
    TEST_ADMINS_PAGED_DATA
  );
  const [loading] = useState(false);
  const [adminToDelete, setAdminToDelete] = useState<IdentityWithAdminRoles>();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchText, setSearchText] = useState('');
  const SetDebouncedText = useDebouncedCallback(setSearchText, 300);
  const [hideTerminated, setHideTerminated] = useState(false);
  const [tableData, setTableData] = useState(allData);
  const [sortConfig, setSortConfig] = useState<SortConfig>({
    key: 'name',
    direction: 'ASC',
  });
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const deletedIdentityUuid = queryParams.get('deleted');
  const { data: deletedIdentity, loading: deletedLoading } = useGetAdminSummary(
    deletedIdentityUuid || '',
    !deletedIdentityUuid
  );

  const [deletedSuccessData, setDeletedSuccessData] = useState<SuccessData>({
    enabled: !!deletedIdentity,
    message: `${deletedIdentity?.preferredName || deletedIdentity?.firstName} ${
      deletedIdentity?.lastName
    } was successfully deleted.`,
  });
  const deletedSuccess: Success = {
    successData: deletedSuccessData,
    setSuccessData: setDeletedSuccessData,
    color: 'info',
  };

  useEffect(() => {
    if (!deletedLoading && !!deletedIdentity) {
      setDeletedSuccessData({
        enabled: !!deletedIdentity,
        message: `${
          deletedIdentity?.preferredName || deletedIdentity?.firstName
        } ${deletedIdentity?.lastName} was successfully deleted.`,
      });
    }
  }, [deletedLoading, deletedIdentity]);

  // ? Keeping examples here for handling errors
  // const [genericError, setError] = useState({
  //   error: true,
  //   message: 'Error message',
  // });
  // const [otherError, setOtherError] = useState({
  //   error: true,
  //   message: 'Another error@!',
  // });
  //
  // let adminListErrors: Errors[] = [
  //   {
  //     key: 'genericError',
  //     errorData: genericError,
  //     handleErrorClose: setError,
  //   },
  //   {
  //     key: 'otherError',
  //     errorData: otherError,
  //     handleErrorClose: setOtherError,
  //   },
  // ];

  let adminListErrors: Errors[] = [];

  useEffect(() => {
    const { key, direction } = sortConfig;
    const sortedData = [...tableData];

    if (key === 'name') {
      sortedData.sort((a, b) => {
        return direction === 'ASC'
          ? (a.preferredName || a.firstName).localeCompare(
              b.preferredName || b.firstName
            )
          : (b.preferredName || b.firstName).localeCompare(
              a.preferredName || a.firstName
            );
      });
    } else if (key === 'activeRoles' || key === 'clients') {
      sortedData.sort((a, b) => {
        return direction === 'ASC'
          ? Number(a.rdAdminRoleSummary?.activeRoleCount) -
              Number(b.rdAdminRoleSummary?.activeRoleCount)
          : Number(b.rdAdminRoleSummary?.activeRoleCount) -
              Number(a.rdAdminRoleSummary?.activeRoleCount);
      });
    } else if (key === 'status') {
      sortedData.sort((a, b) => {
        return direction === 'ASC'
          ? (a.terminationDate === null ? 1 : 0) -
              (b.terminationDate === null ? 1 : 0)
          : (b.terminationDate === null ? 1 : 0) -
              (a.terminationDate === null ? 1 : 0);
      });
    }
    setTableData(sortedData);
  }, [sortConfig]);

  const onDelete = () => {
    if (adminToDelete) {
      setDeletedSuccessData({
        enabled: true,
        message: `${adminToDelete?.preferredName || adminToDelete?.firstName} ${
          adminToDelete?.lastName
        } was successfully deleted.`,
      });
      setAdminToDelete(undefined);
    }
  };

  useEffect(() => {
    setDeleteModalOpen(!!adminToDelete);
  }, [adminToDelete]);

  return (
    <>
      <BasicPadding padding="md">
        <DeleteAdminModal
          open={deleteModalOpen}
          setOpen={setDeleteModalOpen}
          onConfirm={onDelete}
          onCancel={() => {
            setDeleteModalOpen(false);
            setAdminToDelete(undefined);
          }}
        />
        <AdminListContainer>
          <AdminContainer>
            <Typography variant="h1">Admins</Typography>
            <DAButtonContainer>
              <AddAdminButton
                adminColor
                onClick={() => history.push('/admins/new')}
                startIcon={<Add />}
              >
                Add admin
              </AddAdminButton>
            </DAButtonContainer>
          </AdminContainer>
          <TypographyMB>
            Manage the list of admin accounts for CC Admin’s global tools.
          </TypographyMB>
          <AlertContainer>
            <ErrorHandler errors={adminListErrors} />
            <SuccessHandler success={deletedSuccess} />
          </AlertContainer>
          <SearchContainer>
            <Box>
              <Search
                id="people-search"
                noOptionsText="We couldn’t find anyone who matched your search"
                loading={loading}
                onChange={console.log}
                options={allData}
                filterSelectedOptions
                placeholder="Search by name, employee ID or email address"
                onInputChange={(_, e) => SetDebouncedText(e)}
                renderOption={(props, option) => {
                  // TODO: this gets it close to working, but the hover state doesn't work
                  const { className, ...optionProps } = props;
                  return (
                    <SearchOptionCard
                      {...optionProps}
                      key={option.identityUuid}
                      showAvatar
                      avatarUrl={option.profile?.avatar}
                      title={option.jobTitle}
                      subTitle={`${option.email} • ${option.businessUnit?.buName}`}
                    />
                  );
                }}
                sx={{
                  width: 540,
                }}
              />
            </Box>
            <Box>
              <FormControlLabel
                control={
                  <Switch
                    checked={hideTerminated}
                    onChange={() => setHideTerminated(!hideTerminated)}
                    name="hide-terminated"
                  />
                }
                label="Hide terminated admins"
                labelPlacement="start"
              />
            </Box>
          </SearchContainer>
          {/* TODO: refactor to match clientAccess searchTerms */}
          {searchText.length > 0 && allData.length > 0 && (
            <Typography
              variant="caption"
              sx={{ pl: 5, color: tannerGray[600] }}
            >
              {`Showing ${totalCount} results`}
            </Typography>
          )}
          <TableMT>
            <TableHead>
              <TableRow>
                {AdminsHeadCells.map(({ id, label, align, sortKey }) => (
                  <TableCell align={align} key={id}>
                    <TypographyPZero variant="h5">
                      {label}
                      {!!sortKey &&
                        (sortConfig.key === id ? (
                          sortConfig.direction === 'ASC' ? (
                            <SortAscFCM
                              onClick={() =>
                                setSortConfig({ key: id, direction: 'DESC' })
                              }
                            />
                          ) : (
                            <SortDescFCM
                              onClick={() =>
                                setSortConfig({ key: id, direction: 'ASC' })
                              }
                            />
                          )
                        ) : (
                          <SortFCM
                            onClick={() =>
                              setSortConfig({ key: id, direction: 'ASC' })
                            }
                          />
                        ))}
                    </TypographyPZero>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData.map((rowData) => (
                <TableRow key={rowData.identityUuid}>
                  <SingleRow
                    testKey="admins"
                    rowData={rowData}
                    getId={(rowData) => rowData.identityUuid}
                    columnDisplayDefs={tableRows}
                    onDelete={() => setAdminToDelete(rowData)}
                  />
                </TableRow>
              ))}
            </TableBody>
          </TableMT>
          <AdminPagination
            totalCount={totalCount}
            pageNumber={pageNumber}
            pageSize={pageSize}
            setPageNumber={setPageNumber}
            setPageSize={setPageSize}
          />
        </AdminListContainer>
        {totalCount < 1 && (
          <NoResultsContainer>
            <Typography variant="h3">No admins yet</Typography>
            <Typography>
              When you add an admin they will show up here.
            </Typography>
          </NoResultsContainer>
        )}
      </BasicPadding>
    </>
  );
};

export default Admins;
