import React from 'react';
import { SwitchControl, topLevelFormControlStyle } from './editorControls';
import {
  FormControl,
  FormGroup,
  FormLabel,
  Typography,
} from '@octanner/prism-core';

interface Props {
  allowGroupFileUpload: boolean;
  setAllowGroupFileUpload: (value: boolean) => void;
  allowRecipientListViewableByEveryone: boolean;
  setAllowRecipientListViewableByEveryone: (value: boolean) => void;
}

export const GroupRecognitionOptions: React.FC<Props> = ({
  allowGroupFileUpload,
  setAllowGroupFileUpload,
  allowRecipientListViewableByEveryone,
  setAllowRecipientListViewableByEveryone,
}) => {
  return (
    <FormControl sx={topLevelFormControlStyle}>
      <FormLabel component="legend">
        <Typography variant="h3">Group Recognition</Typography>
      </FormLabel>
      <FormGroup>
        <SwitchControl
          checked={allowGroupFileUpload}
          onChange={(e, checked) => setAllowGroupFileUpload(checked)}
        >
          Allow group file upload
        </SwitchControl>
        <SwitchControl
          checked={allowRecipientListViewableByEveryone}
          onChange={(e, checked) =>
            setAllowRecipientListViewableByEveryone(checked)
          }
        >
          Allow for full list of recipients to be viewable by everyone
        </SwitchControl>
      </FormGroup>
    </FormControl>
  );
};
