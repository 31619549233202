import styled from '@emotion/styled';
import { Alert, Button, CircularProgress, Typography } from "@octanner/prism-core";
import React, { useContext } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { CustomTextContext } from '../../Contexts/CustomTextContext';
import { defaults } from '../../utils/default';
import { AlertMessage, LanguageStringContainerProps } from '../../utils/types';
import AddCustomTextContainer from './AddCustomText/AddCustomTextContainer';
import EditCustomText from './EditCustomText';
import RadioButtonContainer from './RadioButtonContainer';
import SearchCustomText from './SearchCustomText';

const Container = styled.div<LanguageStringContainerProps>`
  margin: 20px 40px;
  height: ${(props) => (props.showFooter ? 'calc(100vh - 110px)' : '100vh')};
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
`;

const StyledCircularProgress = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  height: 100vh;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const MessageAlert = styled(Alert)`
  width: 40%;
  margin-bottom: 16px;
`;

const CustomText: React.FC = () => {

  const {
    searchLoading,
    showFooter, messages, setMessageText
  } = useContext(CustomTextContext);

  const history = useHistory();

  const addCustomText = () => {
    history.push('/custom-text/add');
  }

  return (
    <Container showFooter={searchLoading && showFooter}>
      <Switch>
        <Route exact path='/custom-text'>
          {<>
            <Header>
              <div>
                <Typography variant='h1'>
                  {defaults.custom_text}
                </Typography>
                <Typography>
                  {defaults.custom_text_subtitle}
                </Typography>
              </div>
              <div>
                <Button
                  onClick={addCustomText} adminColor>
                  {defaults.add_new}
                </Button>
              </div>
            </Header>
            {messages.map((message: AlertMessage) => (
              message?.text?.length > 0 &&
              (<MessageAlert
                key={message.key}
                onClose={() => setMessageText(message.key, '')}
                severity={message.severity}>
                {message.text}
              </MessageAlert>)
            ))}
            <RadioButtonContainer />
            <SearchCustomText />
          </>}
        </Route>
        <Route exact path={'/custom-text/add'}>
          <AddCustomTextContainer />
        </Route>
        <Route exact path='/custom-text/edit/:stringId'>
          {(
            <>
              {!searchLoading ? (
                <EditCustomText />
              ) : (
                <StyledCircularProgress>
                  <CircularProgress />
                </StyledCircularProgress>
              )}
            </>
          )}
        </Route>
      </Switch>
    </Container>
  );
}

export default CustomText;