const colors = {
  white: '#FFFFFF',
  black: '#000000',
  red: '#D70E16',
  blue: '#0060E0',
  green: '#07702A',
  lightGreen: '#D5F0E7',
  redOrange: '#B1480B',
  lightYellow: '#FDF4DA',
  grey: '#F5F7F9',
  grayLine: '#c4c4c4',
  cardTitleGray: '#374151',
  gray100: '#F1F1F1',
  gray200: '#E1E1E1',
  gray300: '#F8F8F8',
  gray400: '#ABABAE',
  gray500: '#8E8E8E',
  gray600: '#707070',
  gray700: '#525252',
  gray800: '#636363',
};

export default colors;
