import { Button } from '@octanner/prism-core';
import React, { ReactElement, useState } from 'react';
import {
  ColoredTabs,
  SolidLineAcross,
} from '../../../programs/modifyProgram/styles';
import { AdminRoleInformation } from '../types';
import { TabPanel } from '../../../systemSettings/tabs';
import RoleInformationList from './RoleInformationList';
import { Trash } from '@octanner/prism-icons';
import { Chip, RemoveAllButtonContainer, UnsavedChangesChip } from '../styles';
import ReAssignRoleModal from './ReAssignRoleModal';
import RemoveRoleModal from './RemoveRoleModal';
import { AdminHeightTabs } from '../styles';
import { tannerBlue } from '@octanner/prism-core/ThemeProvider/colors';
import Loading from '../../../../common/Loading';
import { PagedAdminRoles } from '../adminRoles/graphql/types';

interface RoleTabsProps {
  objectName: string;
  setRemoveAllModalOpen: (open: boolean) => void;
  allActiveRoles?: AdminRoleInformation[];
  pagedActiveRoles?: PagedAdminRoles;
  loadingActiveRoles?: boolean;
  pagedInactiveRoles?: PagedAdminRoles;
  loadingInactiveRoles?: boolean;
  activeRolesPagination: ReactElement;
  inactiveRolesPagination: ReactElement;
  rolesToAdd: AdminRoleInformation[];
  setRolesToAdd: (adminRoles: AdminRoleInformation[]) => void;
  rolesToRemove: AdminRoleInformation[];
  setRolesToRemove: (adminRoles: AdminRoleInformation[]) => void;
  setRemoveAllRoles: (removeAll: boolean) => void;
}

const arrayContainsRole = (
  roles: AdminRoleInformation[],
  role: AdminRoleInformation
) => {
  return roles.some((r) => r.role.uuid === role.role.uuid);
};

const arrayWithoutRole = (
  roles: AdminRoleInformation[],
  role: AdminRoleInformation
) => {
  return roles.filter((r) => r.role.uuid !== role.role.uuid);
};

const RoleTabs = ({
  objectName,
  setRemoveAllModalOpen,
  allActiveRoles,
  pagedActiveRoles,
  loadingActiveRoles,
  pagedInactiveRoles,
  loadingInactiveRoles,
  activeRolesPagination,
  inactiveRolesPagination,
  rolesToAdd,
  setRolesToAdd,
  rolesToRemove,
  setRolesToRemove,
  setRemoveAllRoles,
}: RoleTabsProps) => {
  const [tabValue, setTabValue] = useState(0);

  const handleTabValueChange = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    event.preventDefault();
    setTabValue(newValue);
  };

  const [roleToRemove, setRoleToRemove] = useState<AdminRoleInformation>();
  const [removeRoleModalOpen, setRemoveRoleModalOpen] = useState(false);
  const [roleToReassign, setRoleToReassign] = useState<AdminRoleInformation>();
  const [reassignRoleModalOpen, setReassignRoleModalOpen] = useState(false);

  return (
    <>
      <RemoveRoleModal
        roleName={roleToRemove?.name || ''}
        entityName={objectName}
        open={removeRoleModalOpen}
        setOpen={setRemoveRoleModalOpen}
        onConfirm={() => {
          if (!roleToRemove) return;
          if (arrayContainsRole(rolesToAdd, roleToRemove)) {
            setRolesToAdd(arrayWithoutRole(rolesToAdd, roleToRemove));
          } else if (!arrayContainsRole(rolesToRemove, roleToRemove)) {
            setRolesToRemove([roleToRemove, ...rolesToRemove]);
          }
        }}
      />
      <ReAssignRoleModal
        open={reassignRoleModalOpen}
        setOpen={setReassignRoleModalOpen}
        onConfirm={() => {
          if (!roleToReassign) return;
          if (arrayContainsRole(rolesToRemove, roleToReassign)) {
            setRolesToRemove(arrayWithoutRole(rolesToRemove, roleToReassign));
          } else if (!arrayContainsRole(rolesToAdd, roleToReassign)) {
            setRolesToAdd([roleToReassign, ...rolesToAdd]);
          }
          setRemoveAllRoles(false);
        }}
      />
      <AdminHeightTabs
        value={tabValue}
        onChange={handleTabValueChange}
        TabIndicatorProps={{
          style: {
            backgroundColor: tannerBlue[500],
          },
        }}
      >
        <ColoredTabs
          icon={
            <>
              <Chip>{pagedActiveRoles?.total || 0}</Chip>
              {rolesToAdd?.length > 0 && (
                <UnsavedChangesChip>
                  {rolesToAdd?.length || 0} unsaved
                </UnsavedChangesChip>
              )}
            </>
          }
          label="Active roles"
          iconPosition="end"
          sx={{ '.MuiTab-iconWrapper': { ml: 3 } }}
        />
        <ColoredTabs
          icon={
            <>
              <Chip>{pagedInactiveRoles?.total || 0}</Chip>
              {rolesToRemove?.length > 0 && (
                <UnsavedChangesChip>
                  {rolesToRemove?.length || 0} unsaved
                </UnsavedChangesChip>
              )}
            </>
          }
          label="Role history"
          iconPosition="end"
          sx={{ '.MuiTab-iconWrapper': { ml: 3 } }}
        />
      </AdminHeightTabs>
      <SolidLineAcross />
      <TabPanel value={tabValue} index={0}>
        {loadingActiveRoles ? (
          <Loading />
        ) : (
          <RoleInformationList
            type="active"
            pagedRoles={pagedActiveRoles?.content}
            changingRoles={rolesToAdd}
            onAction={(adminRole) => {
              if (!arrayContainsRole(rolesToAdd, adminRole)) {
                setRoleToRemove(adminRole);
                setRemoveRoleModalOpen(true);
              } else {
                setRolesToAdd(arrayWithoutRole(rolesToAdd, adminRole));
              }
            }}
            isActionDisabled={(_) => false}
          >
            <RemoveAllButtonContainer>
              <Button
                variant="text"
                color="primary"
                startIcon={<Trash />}
                onClick={() => setRemoveAllModalOpen(true)}
              >
                Remove all
              </Button>
            </RemoveAllButtonContainer>
          </RoleInformationList>
        )}
        {activeRolesPagination}
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        {loadingInactiveRoles ? (
          <Loading />
        ) : (
          <RoleInformationList
            type="inactive"
            pagedRoles={pagedInactiveRoles?.content}
            changingRoles={rolesToRemove}
            onAction={(adminRole) => {
              if (!arrayContainsRole(rolesToRemove, adminRole)) {
                setRoleToReassign(adminRole);
                setReassignRoleModalOpen(true);
              } else {
                setRolesToRemove(arrayWithoutRole(rolesToRemove, adminRole));
                setRemoveAllRoles(false);
              }
            }}
            isActionDisabled={(adminRole) =>
              !!adminRole.role.deactivatedTsz ||
              arrayContainsRole(rolesToAdd, adminRole) ||
              arrayContainsRole(
                allActiveRoles?.filter(
                  (role) => !arrayContainsRole(rolesToRemove, role)
                ) || [],
                adminRole
              )
            }
          />
        )}
        {inactiveRolesPagination}
      </TabPanel>
    </>
  );
};

export default RoleTabs;
