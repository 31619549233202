import { gql } from '@apollo/client';
import { useQuery, useMutation } from '@apollo/client';
import { BankLevelLocation, BankPayload, ExtendedBankById } from './types';
type Group = { customerCountrySalesOrgId: string; coreCountry: CoreCountry }[];
type SalesOrgResponse = {
  customerCountrySalesOrgMapsGroupBySalesOrg: {
    customerSalesOrgMap: {
      assignedSalesOrg: AssignedSalesOrg;
      group: Group;
    }[];
  };
};
const GET_SALES_ORG_AND_COUNTRY_GROUPED = gql`
  query CustomerCountrySalesOrgMapsGroupBySalesOrg($customerId: UUID!) {
    customerCountrySalesOrgMapsGroupBySalesOrg(customerId: $customerId) {
      customerSalesOrgMap {
        assignedSalesOrg {
          salesOrgName
          salesOrgCode
        }
        group {
          customerCountrySalesOrgId
          coreCountry {
            countryName
            id
          }
        }
      }
    }
  }
`;
export const useSalesOrgAndCountry = (input: { customerId: string }) => {
  return useQuery<SalesOrgResponse, SalesOrgInput>(
    GET_SALES_ORG_AND_COUNTRY_GROUPED,
    {
      variables: input,
      fetchPolicy: 'no-cache',
    }
  );
};
type SalesOrgInput = {
  customerId: string;
};
type AssignedSalesOrg = { salesOrgName: string; salesOrgCode: string };
type CoreCountry = { countryName: string; id: string };

const CREATE_BANK_QUERY = gql`
  mutation corePtMgmtCreateNewBank($request: CorePtMgmtCreateNewBankRequest!) {
    corePtMgmtCreateNewBank(request: $request) {
      information {
        pointBankUuid
        pointBankName
        billingConfig {
          billingConfigUuid
          billingLocationType
          billingLocation {
            locationUuid
          }
          billingType
          billToUserType
          billingLocationRecipientType
        }
        pointBankSalesOrges {
          pointBankSalesOrgUuid
          customerCountrySalesOrgId
          shippingLocationType
        }
        pointExpiryConfiguration: pointExpiryConfig {
          pointExpiryType
          monthlyRollingExpirationNum
          fixedDates {
            endDateTsz
            expirationDateTsz
            startDateTsz
          }
        }
        invoiceFieldConfig {
          invoiceField1
          invoiceField2
          invoiceField3
          invoiceField4
        }
      }
    }
  }
`;
export const useCreateBank = () => {
  return useMutation<any, BankPayload>(CREATE_BANK_QUERY);
};

const GET_BANK_BY_ID = gql`
  query CorePtMgmtGetBankInformation(
    $request: CorePtMgmtGetBankInformationRequest!
  ) {
    corePtMgmtGetBankInformation(request: $request) {
      information {
        pointBankUuid
        pointBankType
        defaultAccruingBank
        pointBankSalesOrges {
          pointBankSalesOrgUuid
          shippingLocationType
          customerCountrySalesOrgId
          customerCountrySalesOrg {
            coreCountry {
              countryName
              iso2Code
            }
            assignedSalesOrg {
              salesOrgName
              salesOrgCode
            }
          }
        }
        pointBankName
        billingConfig {
          billingLocationType
          billingLocationRecipientType
          billingType
          billingLocation {
            locationUuid
          }
          poNumberType
        }
        pointExpiryConfiguration: pointExpiryConfig {
          monthlyRollingExpirationNum
          pointExpiryType
          fixedDates {
            endDateTsz
            expirationDateTsz
            startDateTsz
          }
        }
        invoiceFieldConfig {
          invoiceFieldEntity1 {
            name
            code
            identityGraphqlMappedField
          }
          invoiceFieldEntity2 {
            name
            code
            identityGraphqlMappedField
          }
          invoiceFieldEntity3 {
            name
            code
            identityGraphqlMappedField
          }
          invoiceFieldEntity4 {
            name
            code
            identityGraphqlMappedField
          }
        }
      }
    }
  }
`;

type BankInputValues = { customerUuid: string; pointBankUuid: string };
type BankByIdInput = { request: BankInputValues };
// TODO: TYPE
export type BankByIdResponse = {
  corePtMgmtGetBankInformation: {
    information: ExtendedBankById;
  };
};
export const useGetBankById = (input: BankInputValues) => {
  return useQuery<BankByIdResponse, BankByIdInput>(GET_BANK_BY_ID, {
    variables: { request: input },
    fetchPolicy: 'no-cache',
  });
};

const UPDATE_BANK_QUERY = gql`
  mutation CoreUpdateBank($request: CoreUpdateBankRequest!) {
    coreUpdateBank(request: $request) {
      information {
        pointBankUuid
      }
    }
  }
`;
export const useUpdateBank = () => {
  return useMutation<any, BankPayload>(UPDATE_BANK_QUERY);
};
const GET_BILLING_LOCATION_BY_CUSTOMER = gql`
  query billingLocationsByCustomer($input: BillingLocationsByCustomerInput!) {
    billingLocationsByCustomer(input: $input) {
      listConnection {
        nodes {
          locationUuid
          locationName
          locationCode
          archivedTsz
        }
      }
    }
  }
`;
type BankLevelResponse = {
  billingLocationsByCustomer: {
    listConnection: {
      nodes: BankLevelLocation[];
    };
  };
};
type BankLevelInput = {
  customerUuid: string;
};
export const useBankLevelLocation = (customerUuid: string) => {
  const variables: { input: BankLevelInput } = {
    input: {
      customerUuid: customerUuid,
    },
  };
  return useQuery<BankLevelResponse>(GET_BILLING_LOCATION_BY_CUSTOMER, {
    variables,
  });
};

export const GET_CORE_PT_MGMT_INVOICE_FIELD_TYPE = gql`
  query CorePtMgmtGetInvoiceFieldType {
    corePtMgmtGetInvoiceFieldType {
      invoiceFieldTypes {
        nodes {
          code
          name
          identityGraphqlMappedField
        }
      }
    }
  }
`;

export type InvoiceFieldTypeNode = {
  code: string;
  name: string;
  identityGraphqlMappedField: string;
};

type InvoiceFieldTypes = {
  nodes: InvoiceFieldTypeNode[];
};

interface corePtMgmGetInvoiceFieldTypeResponse {
  corePtMgmtGetInvoiceFieldType: {
    invoiceFieldTypes: InvoiceFieldTypes;
  };
}

export const useCorePtMgmtGetInvoiceFieldType = () => {
  return useQuery<corePtMgmGetInvoiceFieldTypeResponse>(
    GET_CORE_PT_MGMT_INVOICE_FIELD_TYPE
  );
};

export const GET_COUNTRY_SALES_ORG_GROUPED_BY_COUNTRY = gql`
  query CustomerCountrySalesOrgMapsGroupByCountry($customerId: UUID!) {
    customerCountrySalesOrgMapsGroupByCountry(customerId: $customerId) {
      customerSalesOrgGroupByCountry {
        assignedCountry {
          countryName
          iso3Code
          id
        }
        group {
          customerCountrySalesOrgId
          coreCountry {
            id
            iso2Code
            countryName
          }
          assignedSalesOrg {
            salesOrgCode
            salesOrgName
          }
        }
      }
    }
  }
`;

type AssignedCountry = { countryName: string; iso3Code: string; id: number };

type AssignedSalesOrgByCountry = {
  salesOrgName: string;
  salesOrgCode: string;
  customerCountrySalesOrgId: number;
};

type CoreByCountry = { id: number; iso2Code: string; countryName: string };

type GroupByCountry = {
  customerCountrySalesOrgId: number;
  coreCountry: CoreByCountry;
  assignedSalesOrg: AssignedSalesOrgByCountry;
}[];

type SalesOrgGroupedByCountryResponse = {
  customerCountrySalesOrgMapsGroupByCountry: {
    customerSalesOrgGroupByCountry: {
      assignedCountry: AssignedCountry;
      group: GroupByCountry;
    }[];
  };
};

export const useCountrySalesOrgGroupedByCountry = (input: {
  customerId: string;
}) => {
  return useQuery<SalesOrgGroupedByCountryResponse, SalesOrgInput>(
    GET_COUNTRY_SALES_ORG_GROUPED_BY_COUNTRY,
    {
      variables: input,
      fetchPolicy: 'no-cache',
    }
  );
};
