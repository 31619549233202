(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("@octanner/prism-core"), require("@emotion/styled"), require("@octanner/prism-core/TableContainer"), require("@octanner/prism-core/TableCell"), require("@apollo/client"), require("@octanner/prism-icons"), require("@octanner/prism-core/Search"), require("@octanner/prism-core/ThemeProvider/colors"), require("@octanner/prism-core/TableRow"), require("@octanner/prism-icons/SortAscending"), require("@octanner/prism-icons/SortDescending"), require("@material-ui/core/styles"), require("@octanner/prism-core/context/Layout"));
	else if(typeof define === 'function' && define.amd)
		define(["react", "@octanner/prism-core", "@emotion/styled", "@octanner/prism-core/TableContainer", "@octanner/prism-core/TableCell", "@apollo/client", "@octanner/prism-icons", "@octanner/prism-core/Search", "@octanner/prism-core/ThemeProvider/colors", "@octanner/prism-core/TableRow", "@octanner/prism-icons/SortAscending", "@octanner/prism-icons/SortDescending", "@material-ui/core/styles", "@octanner/prism-core/context/Layout"], factory);
	else if(typeof exports === 'object')
		exports["Vibes"] = factory(require("react"), require("@octanner/prism-core"), require("@emotion/styled"), require("@octanner/prism-core/TableContainer"), require("@octanner/prism-core/TableCell"), require("@apollo/client"), require("@octanner/prism-icons"), require("@octanner/prism-core/Search"), require("@octanner/prism-core/ThemeProvider/colors"), require("@octanner/prism-core/TableRow"), require("@octanner/prism-icons/SortAscending"), require("@octanner/prism-icons/SortDescending"), require("@material-ui/core/styles"), require("@octanner/prism-core/context/Layout"));
	else
		root["Vibes"] = factory(root["react"], root["@octanner/prism-core"], root["@emotion/styled"], root["@octanner/prism-core/TableContainer"], root["@octanner/prism-core/TableCell"], root["@apollo/client"], root["@octanner/prism-icons"], root["@octanner/prism-core/Search"], root["@octanner/prism-core/ThemeProvider/colors"], root["@octanner/prism-core/TableRow"], root["@octanner/prism-icons/SortAscending"], root["@octanner/prism-icons/SortDescending"], root["@material-ui/core/styles"], root["@octanner/prism-core/context/Layout"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__8156__, __WEBPACK_EXTERNAL_MODULE__5202__, __WEBPACK_EXTERNAL_MODULE__5518__, __WEBPACK_EXTERNAL_MODULE__3807__, __WEBPACK_EXTERNAL_MODULE__9504__, __WEBPACK_EXTERNAL_MODULE__4209__, __WEBPACK_EXTERNAL_MODULE__4543__, __WEBPACK_EXTERNAL_MODULE__6548__, __WEBPACK_EXTERNAL_MODULE__9726__, __WEBPACK_EXTERNAL_MODULE__2120__, __WEBPACK_EXTERNAL_MODULE__3085__, __WEBPACK_EXTERNAL_MODULE__7628__, __WEBPACK_EXTERNAL_MODULE__8793__, __WEBPACK_EXTERNAL_MODULE__9267__) => {
return 