import { MenuItem } from '@octanner/prism-core';
import { ProgramCategoryProps } from '../../types';
import { useCoreProgramCategories, useProgramTypes } from '../../graphql';
import ProgramSkeleton from '../../../ProgramSkeletons';
import {
  CategorySelectWidth,
  ProgramSelectWidth,
  ProgramTitle,
  //?Do not need this for now
  // SwitchContainer,
  // SwitchSentence,
} from '../productCategorySettings/styles';

const ProgramCategory = ({
  programAssignmentCode,
  handleProgramSettingChange,
  typeError,
  type,
  //?Do not need this for now
  // managerDashBoardEnabled,
  programCategoryId,
  categoryError,
}: ProgramCategoryProps) => {
  const { data: programTypeData, loading: programTypeLoading } =
    useProgramTypes();
  const { data: programCategoryData } = useCoreProgramCategories();

  const programTypes =
    programTypeData?.coreProgramFinanceTypes?.programFinanceTypes?.nodes || [];

  const programCategoryNodes =
    programCategoryData?.coreProgramCategories?.coreProgramCategories?.nodes ||
    [];

  const handleProgramSelection = (e) => {
    handleProgramSettingChange('programFinanceTypeCode', e.target.value);
  };

  const sortedProgramCategories = [...programCategoryNodes].sort((a, b) => {
    switch (a.programCategoryName) {
      case 'Career Anniversary':
        return -1;
      case 'Recognition':
        if (b.programCategoryName.includes('Career Anniversary')) {
          return 1;
        } else {
          return -1;
        }
      default:
        if (
          b.programCategoryName === 'Career Anniversary' ||
          b.programCategoryName === 'Recognition'
        ) {
          return 1;
        }
        return a.programCategoryName.localeCompare(b.programCategoryName);
    }
  });

  const handleSwitchProgram = (programCategoryId: number) => {
    const programType = sortedProgramCategories.find(
      (program) => program.id === programCategoryId
    );
    if (programType) {
      const { programCategoryDscr } = programType;
      handleProgramSettingChange('programDscr', programCategoryDscr);
      handleProgramSettingChange('coreProgramTypeId', programCategoryId);
      handleProgramSettingChange('programCategoryId', programCategoryId);
    }
  };

  //?Do not need this for now
  // const handleSwitchChange = () => {
  //   handleProgramSettingChange(
  //     'managerDashboardEnabled',
  //     !managerDashBoardEnabled
  //   );
  // };

  if (programTypeLoading) return <ProgramSkeleton name={'productCategory'} />;
  return (
    <>
      <ProgramTitle variant="h2">Program category</ProgramTitle>
      <CategorySelectWidth
        label="Program category"
        value={programCategoryId || ''}
        onChange={(event) => handleSwitchProgram(Number(event.target.value))}
        disabled={type === 'edit'}
        error={categoryError}
      >
        {sortedProgramCategories.map(({ programCategoryName, id }) => (
          <MenuItem value={id} key={id}>
            {programCategoryName}
          </MenuItem>
        ))}
      </CategorySelectWidth>
      {/* <SwitchContainer>
        <Switch
          checked={managerDashBoardEnabled}
          onChange={handleSwitchChange}
        />
        <SwitchSentence>
          Display activity from this program on the manager dashboard
        </SwitchSentence>
      </SwitchContainer> */}
      <div>
        <ProgramSelectWidth
          label="Program type"
          value={programAssignmentCode}
          error={typeError}
          onChange={handleProgramSelection}
          disabled={type === 'edit'}
        >
          {programTypes.map(
            ({ programFinanceTypeName, programFinanceTypeCode }) => (
              <MenuItem
                key={programFinanceTypeCode}
                value={programFinanceTypeCode}
              >
                {programFinanceTypeName}
              </MenuItem>
            )
          )}
        </ProgramSelectWidth>
      </div>
    </>
  );
};

export default ProgramCategory;
