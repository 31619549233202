import React from 'react';
import { Modal, Button} from '@octanner/prism-core';
import { ModalDescription } from './styles';
import { useTranslation } from 'react-i18next';
import { UPDATE_CUSTOMER_CONTACT_INFO } from './query';
import { useMutation } from '@apollo/client';

export const ClientUsageTypeModal = ({
    showClientTypeModal,
    setShowClientTypeModal,
    setClientType,
    customerId,
    dataCustomerName,
    clientType,
    setRefetchEditCustomer,
}) => {
    const { customerUsage } = dataCustomerName?.customer;
    const [updateCustomerDetails,{ loading: updateCustomerDetailsLoading }] = useMutation(UPDATE_CUSTOMER_CONTACT_INFO);
    const { t } = useTranslation();
    return (
        <Modal
        id="client-usage-type-modal"
        open={showClientTypeModal}
        onClose={() => {
          setShowClientTypeModal(false);
          setClientType(customerUsage);
        }}
        actions={(
          <>
            <Button
              id="submit"
              onClick={() => {
                updateCustomerDetails({
                  variables: {
                    input: {
                      customerUuid: customerId,
                      customerUsage: clientType,
                    }
                  }
                }).then(() => {
                  setShowClientTypeModal(false);
                  setClientType(clientType);
                  setRefetchEditCustomer(true);
                });
              }}
              adminColor
              loading={updateCustomerDetailsLoading}
            >
              {t('yes')}
            </Button>
            <Button
              id="decline_button"
              color="secondary"
              variant="contained"
              onClick={() => {
                setShowClientTypeModal(false);
                setClientType(customerUsage);
              }}
              test-id="cancel"
            >
              {t('cancel')}
            </Button>
          </>
        )}
        title={t('client_usage_type_modal_title', {clientUsageType: clientType.charAt(0).toUpperCase() + clientType.slice(1) })}
      >
        <ModalDescription variant="body1" align="center">
          {t('client_usage_type_modal_description', {clientUsageType: customerUsage ,clientUsageType1: clientType})}
        </ModalDescription>

      </Modal>
    )
};

export default ClientUsageTypeModal;
