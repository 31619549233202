import styled from '@emotion/styled';

import {
  Button,
  Search,
  Select,
  Typography,
  TextField,
  Tabs,
  Checkbox
} from '@octanner/prism-core';
import TableContainer from '@octanner/prism-core/TableContainer';
import TableCell from '@octanner/prism-core/TableCell';


export const FilterContainer = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
  align-items: center;
`;
export const CountryHeader = styled.div`
  margin-left: 16px;
  width: 295px;
  display: flex;
`;

export const SortContainer = styled.div`
  margin-left: 10px;
  
  &:hover {
    cursor: pointer;
  }
`;

export const CancelButton3 = styled(Button)`
  margin-right: 8px;
  font-weight: 600;
`;

export const CountryCodeTab = styled(Typography)`
  width: 100px;
  font-weight: 800;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 16px;
`;

export const SalesOrgTab = styled(Typography)`
  width: 186px;
  font-weight: 800;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 16px;
`;

export const Header = styled(Typography)`
  font-weight: 800;
  margin-top: 26px;
  font-size: 16px;
`;

export const SalesOrgEditButton = styled(Button)`
  margin-top: 16px;
`;


export const ButtonsDiv = styled.div`
  margin-top: 30px;
`;

export const StpNumber = styled(Typography)`
  font-size: 12px;
`;

export const StpContainer = styled.div`
  margin-left: 20px;
`;

export const ClientNameTextFeild = styled(TextField)`
  width: 364px;
  margin-top: 16px;
`;


export const SalesOrgSelect = styled(Select)`
  width: 395px;
`;

export const SaveClientButton = styled(Button)`
  width: 100px;
  margin-left: 24px;
  font-weight: 600;
`;

export const TabsHeader = styled.span`
  font-size: 14px;
  font-weight: 401;
  color: #000000;
  text-transform: none;
`;

export const MigrationTabs = styled(Tabs)`
  margin-top: 20px;
  &.MuiTabs-root {
    border-bottom: 1px solid #e1e1e1;
    width: 100%;
  }
`;


export const SaveandContinueFlexContainer = styled.div`
  display: flex;
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  padding: 12px 12px 40px 40px;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.25);
  z-index: 7;
  left: 256px;
`;

export const SaveandContinue = styled(Button)`  
  margin-top: 16px;
  z-index: 8;
`;
export const CancelButton = styled(Button)`
  margin-top: 16px;
  margin-left: 8px;
  z-index: 7;
`;

export const DisplaySalesOrgFlexContainer = styled.div`
  display: flex;
  margin-top: 5px;
  width: 100%;
  min-height: 72px;
  padding-bottom: 17px;
  align-items: center;
`;

export const DisplayCountryContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 6px;
  width: 320px;
  display: flex;
  align-items: center;
`;

export const DisplayCountryCodeContainer = styled(Typography)`
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 24px;
  width: 100px;
`;

export const RecommendedSalesOrg = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: #ababae;
`;

export const SingleRecommendedSalesOrg = styled(Typography)`
  margin-top: 10px;
  margin-bottom: 25px;
  display: flex;
  align-items: center;
`;

export const DisplayNoneHeaderTextContainer = styled(Typography)`
  margin-top: 24px;
`;

export const SearchCountry = styled(Search)`
  width: 350px;
  backgroud: #d10a12;
  .MuiAutocomplete-inputRoot {
    background: #f1f1f1;
    .MuiOutlinedInput-notchedOutline {
      border-color: #f1f1f1;
    }
  }
  .MuiSvgIcon-root {
    color: #969696;
  }
`;

export const SubHeader = styled(Typography)`
  margin-top: 4px;
`;

export const CountryNameTableCell = styled(TableCell)`
  width: 330px;
`;

export const CountryCodeTableCell = styled(TableCell)`
  width: 90px;
`;


export const FlexContainer = styled.div`
  display: flex;
`;

export const StyledTableContainer = styled(TableContainer)`
  margin-bottom: 128px;
`;
export const StyledDiv = styled.div`
  margin-left: 16px;
  margin-top: 10px;
`;
export const SelectDiv = styled.div`
  display: flex;
  flex-direction: column;
`;
export const StyledButton = styled(Button)`
  display: inline-flex;
  margin-left: 10px;
  margin-top: 8px;
`;
export const DefaultTagDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 140px;
`;
export const AddButtonDiv = styled.div`
  display: flex;
  align-items: end;
  
`;
export const AddIconDiv = styled.div`
  display: flex;
  align-items: center;
`;
export const SalesOrgDiv = styled.div`
   padding-bottom: 10px;
`;
export const DisplayFlex = styled.div`
  display: flex;
  align-items: center;
`;
export const SingleRecommendedDiv = styled.div`
  display: flex;
`;
export const DropDownDivButton = styled(Button)`
  margin-bottom: 15px;
  margin-left: 4px;
`;

