import React from 'react';
import { Success } from './types';
import { MBAlert } from './styles';

const SuccessHandler: React.FC<{ success: Success }> = ({
  success: { successData, setSuccessData, color = 'success' },
}: {
  success: Success;
}) => {
  return (
    <>
      {successData.enabled && (
        <MBAlert
          color={color}
          onClose={() => {
            setSuccessData({
              ...successData,
              enabled: false,
            });
          }}
        >
          {successData.message || ''}
        </MBAlert>
      )}
    </>
  );
};

export default SuccessHandler;
