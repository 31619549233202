import styled from '@emotion/styled';
import {
  Button,
  Table, TableBody, TableCell,
  TableHead,
  TableRow,
  Typography
} from '@octanner/prism-core';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { CustomTextContext } from '../../Contexts/CustomTextContext';
import { LocaleContext } from '../../Contexts/LocaleContext';
import { formatDate } from '../../utils/commonActions';
import { defaults } from '../../utils/default';
import { CustomText } from '../../utils/types';

const StringIdCell = styled(TableCell)`
  width: 10%;
`;

const StpCell = styled(TableCell)`
  width: 10%;
`;

const PidCell = styled(TableCell)`
  width: 10%;
`;

const LocaleCell = styled(TableCell)`
  width: 15%;
`;

const ActionsCell = styled(TableCell)`
  width: 10%;
`;

const RequesterEmail = styled(TableCell)`
  width: 15%;
`;

const RequestedByCell = styled(TableCell)`
  width: 10%;
`;

const RequestedDateCell = styled(TableCell)`
  width: 10%;
`;

const SourceTextCell = styled(TableCell)`
  width: 15%
`;

const SearchCustomTextTable = () => {

  const history = useHistory();
  const { searchData, setMessageText, setCustomTextData } = useContext(CustomTextContext);
  const { localeMap } = useContext(LocaleContext);

  const handleEdit = (id: string) => {
    setMessageText('deleteSuccessMessage', '');
    setMessageText('errorMessage', '');
    setCustomTextData(null);
    history.push('/custom-text/edit/' + id);
  };

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>
            <Typography variant='h4'>{defaults.string_Id}</Typography>
          </TableCell>
          <TableCell>
            <Typography variant='h4'>{defaults.stp}</Typography>
          </TableCell>
          <TableCell>
            <Typography variant='h4'>{defaults.pid}</Typography>
          </TableCell>
          <TableCell>
            <Typography variant='h4'>{defaults.language}</Typography>
          </TableCell>
          <TableCell>
            <Typography variant='h4'>{defaults.custom_text}</Typography>
          </TableCell>
          <TableCell>
            <Typography variant='h4'>{defaults.requested_date}</Typography>
          </TableCell>
          <TableCell>
            <Typography variant='h4'>{defaults.requested_by_name}</Typography>
          </TableCell>
          <TableCell>
            <Typography variant='h4'>{defaults.requested_by_email}</Typography>
          </TableCell>
          <TableCell>
            <Typography variant='h4'>{defaults.actions}</Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {searchData?.lsFetchCustomTextListUsingSearchFilters?.customTextList
          .map((customText: CustomText, index) => (
            <TableRow key={index}>
              < StringIdCell>
                <Typography>{customText.stringId}</Typography>
              </ StringIdCell>
              < StpCell>
                <Typography>{customText.soldToPartyNumber || '-'}</Typography>
              </ StpCell>
              < PidCell>
                <Typography>{customText.programId || '-'}</Typography>
              </ PidCell>
              < LocaleCell>
                <Typography>{localeMap[String(customText.locale)]}</Typography>
              </ LocaleCell>
              < SourceTextCell>
                <Typography>{customText.sourceText}</Typography>
              </ SourceTextCell>
              < RequestedDateCell>
                <Typography>{formatDate(customText.requestRaisedDate) || defaults.not_announced}</Typography>
              </ RequestedDateCell>
              < RequestedByCell>
                <Typography>{customText.requestedByName}</Typography>
              </ RequestedByCell>
              < RequesterEmail>
                <Typography>{customText.requestedByEmail}</Typography>
              </ RequesterEmail>
              <ActionsCell>
                <Button variant='text' onClick={() => handleEdit(customText.stringId)}>
                  {defaults.edit}
                </Button>
              </ActionsCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
}

export default SearchCustomTextTable;