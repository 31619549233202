import styled from '@emotion/styled';
import { tannerGray } from '@octanner/prism-core/ThemeProvider/colors';
import {
  IconButton,
  Select,
  TableCell,
  Typography,
  Button,
  FormControlLabel,
  RadioGroup,
} from '@octanner/prism-core';
import colors from '../../../../../common/constants/Colors';
import { NoLine } from './types';

export const HorizontalLine = styled.div`
  border-bottom: 1.2px solid rgba(231, 231, 231);
  margin-top: 16px;
  margin-bottom: 32px;
`;

export const MainSection = styled.div`
  margin-bottom: 8px;
  gap: 16px;
  display: flex;
  width: 100%;
`;

export const Flex1 = styled.div`
  flex: 1;
`;

export const Flex2 = styled.div`
  flex: 2;
`;

export const MB16Div = styled.div`
  margin-bottom: 16px;
`;
export const MR4Button = styled(Button)`
  margin-right: 4px;
`;

export const PB16Typography = styled(Typography)`
  padding-bottom: 16px;
`;

export const DivFlexG16MT16 = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 16px;
`;

export const SubtitleTypo = styled(Typography)`
  color: ${tannerGray[600]};
  margin-left: 16px;
`;

export const AlignedTextBox = styled.div`
  height: 58px;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  padding-left: 48px;
`;

export const FlexCenterDiv = styled.div`
  display: flex;
  align-items: center;
`;
export const FlexCenterEvenlyDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const FlexColumnDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SpecificWidthSelect = styled(Select)`
  width: 330px;
  margin: 4px;
`;

export const VerticalAlignedIconButton = styled(IconButton)`
  vertical-align: top;
`;

export const SpecificWidthCell = styled(TableCell)<NoLine>`
  padding: 8px;
  width: 480px;
  border-bottom: ${(props) => (props.open ? 'none' : '')};
`;

export const MainText = styled(Typography)`
  font-weight: bold;
  margin-left: 8px;
`;

export const SubText = styled(Typography)`
  color: ${colors.gray400};
`;

export const SalesOrgTitle = styled(Typography)`
  margin-top: 24px;
`;

export const SpacingDiv = styled.div`
  margin-top: 16px;
  margin-bottom: 36px;
`;

export const SalesOrgError = styled(Typography)`
  color: #d10a12;
  font-size: 12px;
  margin-bottom: 8px;
`;

export const ButtonPadding = styled.div`
  padding: 2px;
`;

export const FormControlLabelW = styled(FormControlLabel)`
  width: 150px;
`;

export const RadioGroupMLT = styled(RadioGroup)`
  margin-left: 24px;
  margin-top: 9.5px;
  margin-bottom: 24px;
`;

export const SelectPOWidth = styled(Select)`
  width: 320px;
  margin-top: 6px;
  margin-bottom: 24px;
`;

export const PONumConfigTitle = styled(Typography)`
  margin-bottom: 8px;
`;

export const POOptions = styled(Typography)`
  margin-top: 16px;
`;
