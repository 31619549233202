import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_CUSTOMER } from './graphql/customer';
import {
  Button,
  Typography,
  TextField,
  Select,
  MenuItem,
  Alert,
  Tag,
  AdapterLuxon,
  DatePicker,
} from '@octanner/prism-core';
import URLSearchParams from '@ungap/url-search-params';
import clsx from 'clsx';
import { useCoupleCustomerAndCoreFeature } from './hooks/customer'; 
import { useGetAllFeatures } from './graphql/hooks';
import { BaseFeature, CustomerFeature, FeatureCode,CoreFeature } from './models/models';
import { DateTime } from 'luxon';
import { convertNativeDateStringToDate } from './index';
import { featuresRows } from './constants';
import { useStyles } from './styles';
import { CustomDatePickerProps } from './type';

const CustomDatePicker: React.FC<CustomDatePickerProps> = ({
  label,
  value,
  onChange,
  disabled,
  maxDate,
  minDate,
  errors,
}) => {
  const handleDateChange = (date: DateTime | null) => {
    onChange(date || null);
  };

  const errorKey = label === 'Start Date' ? 'effectiveDate' : 'expirationDate';

  return (
    <DatePicker
      label={label}
      inputFormat="dd/MM/yyyy"
      value={value && DateTime.fromISO(value).isValid ? DateTime.fromISO(value) : null}
      dateAdapter={AdapterLuxon}
      onChange={handleDateChange}
      disabled={disabled}
      maxDate={maxDate ? DateTime.fromISO(maxDate) : null}
      minDate={minDate ? DateTime.fromISO(minDate) : null}
      renderInput={(params) => (
        <TextField
          {...params}
          error={!!errors.find((e) => e?.key === errorKey)}
          helperText={errors.find((e) => e?.key === errorKey)?.text}
        />
      )}
    />
  );
};

const EmptyFeaturesMessage = () => (
  <Typography>
    You haven't added any applications yet. Please add an application to display in the table.
  </Typography>
);

const isValidDate = (expirationDate: string | null) => {
  const currentDay = new Date();
  //@ts-ignore
  const expireDate = new Date(expirationDate);
  if (!expirationDate) return false;
  if (currentDay > expireDate) {
    return true;
  } else {
    return false;
  }
};

const NEW_KEY = 'new';

const useGetCustomerData = (id: string, customerId: string) => {
  const { data, loading, error } = useQuery(GET_CUSTOMER, {
    variables: { id, customerId },
  });
  const customer = data?.customer;
  const socialConfig = data?.social;
  const welcomeMessage = data?.welcomeMessage;
  const features = data?.features?.nodes;
  const identitySearch = data?.identitySearch;

  return { customer, socialConfig, welcomeMessage, features, identitySearch, loading, error };
};

const Applications = ({customerId, historyLocationProp}) => {
  const classes = useStyles();
  const [coupleCustomerWithFeature] = useCoupleCustomerAndCoreFeature(
    customerId,
  );
  const location = historyLocationProp?.location;
  const history = historyLocationProp?.history;
  const params = new URLSearchParams(location?.search); 
  const {  features: customerFeatures } = useGetCustomerData(customerId, customerId);
   const [customFeatures, setCustomFeatures] = useState<CoreFeature[] | undefined>(undefined);
   const [editable, setEditable] = useState(true);
  const editCode = params.get('editCode');
  const [editFeature, setEditFeature] = useState<CustomerFeature>();
  const [editFeatureNew, setEditFeatureNew] = useState<CustomerFeature>();
  const [availableFeatures, setAvailableFeatures] = useState<BaseFeature[]>([]);
  const [editing, setEditing] = useState(false);
  const [errors, setErrors] = useState<
    { key: keyof CustomerFeature; text: string }[]
  >([]);
  const [error, setError] = useState<string>('');
  const [allFeatures] = useGetAllFeatures();
  const [isDropdownInteracted, setDropdownInteracted] = useState(false);
  const [isStartDateInteracted, setStartDateInteracted] = useState(false);
  const [isEndDateInteracted, setEndDateInteracted] = useState(false);

  params.delete('editRow');
  params.delete('featureCode');

  useEffect(() => {
    setCustomFeatures(customerFeatures); 
  }, [customerFeatures]);

  useEffect(() => {
    if (!editCode) {
      return;
    }
    if (editCode === NEW_KEY) {
      setEditFeature({
        code: FeatureCode.Empty,
        name: '',
        effectiveDate: '',
        expirationDate: '',
      });
      setEditFeatureNew({
        code: FeatureCode.Empty,
        name: '',
        effectiveDate: '',
        expirationDate: '',
      });
      return;
    }
    const feature = customerFeatures?.find((f) => f?.feature?.featureCode === editCode);
    if (!feature) return;
    setEditFeature({
      code: feature?.feature?.featureCode,
      name: feature?.feature?.featureName,
      effectiveDate: feature?.effectiveTsz,
      expirationDate: feature?.expirationTsz,
    });
  }, [editCode, customFeatures]);

  useEffect(() => {
    if (!allFeatures?.allFeatures?.nodes) {
      return;
    }
    if (!customFeatures) return; 

    const usedCodes = customFeatures?.reduce<string[]>((acc, feature) => {
      if (
        !feature?.expirationTsz ||
        DateTime?.fromISO(feature?.expirationTsz) > DateTime?.utc()
      ) {
        return [...acc, feature?.feature?.featureCode];
      }
      return acc;
    }, []);
    const unusedFeatures = customFeatures
  ? allFeatures?.allFeatures?.nodes?.filter(
      (feature) => !usedCodes?.includes(feature?.code),
    )
  : [];

    setAvailableFeatures(unusedFeatures);
  }, [allFeatures, customerFeatures, customFeatures]);

  useEffect(() => {
    handleEditClick(NEW_KEY);
  }, []);

  const handleExistingDateChange = (key: keyof CustomerFeature) => (val:DateTime) => {
    const value = val?.toISO() || null;
    if (!editFeature) {
      return;
    }

    setEditFeature({
      ...editFeature,
      [key]: value,
    });
  };

  const handleNewDateChange = (key: keyof CustomerFeature) => (val: unknown) => {
    const value = '' + val;
    if (!editFeatureNew) {
      return;
    }
    setEditFeatureNew({
      ...editFeatureNew,
      [key]: value,
    });
  };

  const handleDropdownChange = (value) => {
    handleNewDateChange('code')(value); 
    setDropdownInteracted(true); 
  };

  const handleStartDateChange = (value) => {
    handleNewDateChange('effectiveDate')(value?.toISO()); 
    setStartDateInteracted(true);
  };

  const handleEndDateChange = (value) => {
    handleNewDateChange('expirationDate')(value?.toISO()); 
    setEndDateInteracted(true); 
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event?.preventDefault();
    setErrors([]);
    setError('');
    if (!editFeature) {
      return;
    }
    const today = DateTime?.utc();
    let expirationDate:
      | DateTime
      | null
      | undefined = convertNativeDateStringToDate(editFeature?.expirationDate);

    if (expirationDate !== undefined && editing && expirationDate < today) {
      expirationDate = null;
    }
    const effectiveDate = convertNativeDateStringToDate(
      editFeature?.effectiveDate,
    );
    const feature = customerFeatures.find((f) => f?.feature?.featureCode === editCode);
    if (!editFeature?.code) {
      setErrors((errors) => [
        ...errors,
        {
          key: 'code',
          text: 'There must be an application.',
        },
      ]);
      return;
    }
    if (!effectiveDate) {
      setErrors((errors) => [
        ...errors,
        {
          key: 'effectiveDate',
          text: 'There must be an start date.',
        },
      ]);
      return;
    }
    if (
      expirationDate &&
      (expirationDate < today || expirationDate < effectiveDate)
    ) {
      setErrors((errors) => [
        ...errors,
        {
          key: 'expirationDate',
          text: 'End date must be in the future and must be after start date.',
        },
      ]);
      return;
    }
    if (
      feature?.effectiveTsz &&
      DateTime?.fromISO(feature?.effectiveTsz) > today &&
      effectiveDate < today
    ) {
      setErrors((errors) => [
        ...errors,
        {
          key: 'effectiveDate',
          text: 'Start date must be in the future.',
        },
      ]);
      return;
    }
    if (!feature && effectiveDate < today) {
      setErrors((errors) => [
        ...errors,
        {
          key: 'effectiveDate',
          text: 'Start date must be in the future.',
        },
      ]);
      return;
    }
    if (!feature) {
      const featureName = allFeatures?.allFeatures?.nodes?.find(
        (f) => f?.code === editFeature?.code,
      )?.name;
      setEditFeature({
        ...editFeature,
        name: featureName ? featureName : '',
      });
    }
    const res = await coupleCustomerWithFeature({
      variables: {
        customerId: customerId,
        coreFeatureCode: editFeature?.code,
        effective: effectiveDate?.toISO() || '',
        expiration: expirationDate?.toISO() || '',
      },
    });
    if (!res?.data?.coupleCustomerAndCoreFeature) {
      setError('Unable to save. Please try again later.');
      return;
    }
    handleSaveOrCancelClick();
  };

  const handleSubmitNew = async (event: React.FormEvent<HTMLFormElement>) => {
    event?.preventDefault();
    setErrors([]);
    setError('');
    if (!editFeatureNew) {
      return;
    }
    const today = DateTime?.utc();
    let expirationDate:
      | DateTime
      | null
      | undefined = convertNativeDateStringToDate(editFeatureNew?.expirationDate);

    if (expirationDate !== undefined && editing && expirationDate < today) {
      expirationDate = null;
    }
    const effectiveDate = convertNativeDateStringToDate(
      editFeatureNew?.effectiveDate,
    );
    const feature = customerFeatures.find((f) => f?.feature?.featureCode === editCode);
    if (!editFeatureNew?.code) {
      setErrors((errors) => [
        ...errors,
        {
          key: 'code',
          text: 'There must be an application.',
        },
      ]);
      return;
    }
    if (!effectiveDate) {
      setErrors((errors) => [
        ...errors,
        {
          key: 'effectiveDate',
          text: 'There must be an start date.',
        },
      ]);
      return;
    }
    if (
      expirationDate &&
      (expirationDate < today || expirationDate < effectiveDate)
    ) {
      setErrors((errors) => [
        ...errors,
        {
          key: 'expirationDate',
          text: 'End date must be in the future and must be after start date.',
        },
      ]);
      return;
    }
    if (
      feature?.effectiveTsz &&
      DateTime?.fromISO(feature?.effectiveTsz) > today &&
      effectiveDate < today
    ) {
      setErrors((errors) => [
        ...errors,
        {
          key: 'effectiveDate',
          text: 'Start date must be in the future.',
        },
      ]);
      return;
    }
    if (!feature && effectiveDate < today) {
      setErrors((errors) => [
        ...errors,
        {
          key: 'effectiveDate',
          text: 'Start date must be in the future.',
        },
      ]);
      return;
    }
    if (!feature) {
      const featureName = allFeatures?.allFeatures?.nodes?.find(
        (f) => f?.code === editFeatureNew?.code,
      )?.name;
      setEditFeatureNew({
        ...editFeatureNew,
        name: featureName ? featureName : '',
      });
    }
    const res = await coupleCustomerWithFeature({
      variables: {
        customerId: customerId,
        coreFeatureCode: editFeatureNew?.code,
        effective: effectiveDate?.toISO() || '',
        expiration: expirationDate?.toISO() || '',
      },
    });
    if (!res?.data?.coupleCustomerAndCoreFeature) {
      setError('Unable to save. Please try again later.');
      return;
    }
    handleSaveOrCancelClick();
  };

  const handleEditClick = (code: string) => {
    if (code === NEW_KEY) setEditing(false);
    const params = new URLSearchParams(location?.search);
    params?.set('editCode', code);
    const codeParam = params?.toString();
    history?.push(`?${codeParam}`);
  };

  const handleSaveOrCancelClick = () => {
    setDropdownInteracted(false);
    setStartDateInteracted(false);
    setEndDateInteracted(false);
    const params = new URLSearchParams(location?.search);
    setEditing(false);
    params?.delete('editCode');
    const codeParam = params?.toString();
    history?.push(`?${codeParam}`);
    setEditFeature({
      code: FeatureCode?.Empty,
      name: '', 
      effectiveDate: null, 
      expirationDate: null, 
    });
    setEditFeatureNew({
      code: FeatureCode?.Empty,
      name: '', 
      effectiveDate: null, 
      expirationDate: null, 
    });
    setError('');
    setErrors([]);
    handleEditClick(NEW_KEY);

  };

  const getFeatureStatus = (
    effectiveDate: string | null,
    expirationDate: string | null,
  ) => {
    if (effectiveDate && DateTime?.fromISO(effectiveDate) >= DateTime?.utc())
      return <Tag variant="warning">Build</Tag>;
    if (!expirationDate || DateTime?.fromISO(expirationDate) >= DateTime?.utc()) {
      return <Tag variant="success">Active </Tag>;
    } else {
      return <Tag variant="error">Expired </Tag>;
    }
  };

  return (
    <div className={classes?.featuresRoot}>
      <div className={clsx(classes?.featuresSectionHeader)}>
        <Typography variant="h2">Add Applications</Typography>
      </div>

      <form onSubmit={handleSubmitNew} data-test="featuresForm">
      <table
          className={clsx(classes?.featureTable)}
          data-test="featuresFormTable"
        >
              <tr>
                <div className={clsx(classes?.ButtonSectionHeader)}>
                <div>
                <td
                className={clsx(classes?.featureCell)}
                  data-test="newFeatureSelect"
                >
                  <Select
                    value={editFeatureNew?.code || ''}
                    onChange={(e) => handleDropdownChange(e?.target?.value)}
                    label="Application"
                    data-test="feature:new:code"
                    error={errors.find((e) => e?.key === 'code') ? true : false}
                    className={clsx(classes?.SelectComponent)}
                  >
                    {availableFeatures.map((feature) => (
                      <MenuItem
                        key={feature?.code}
                        value={feature?.code}
                        data-test={`feature:new:${feature?.code}`}
                      >
                        {feature?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </td>
                <td
                className={clsx(classes?.featureCell)}
                  data-test="newFeatureEffective"
                >
                  <CustomDatePicker
                    label="Start Date"
                    value={editFeatureNew?.effectiveDate}
                    onChange={handleStartDateChange}
                    disabled={!editable}
                    maxDate={editFeatureNew?.expirationDate}
                    minDate={null}
                    errors={errors}
                  />
                </td>
                <td
                className={clsx(classes?.featureCell)}
                  data-test="newFeatureExpiration"
                >
                <CustomDatePicker
                  label="End Date"
                  value={editFeatureNew?.expirationDate}
                  onChange={handleEndDateChange}
                  disabled={!editable}
                  maxDate={null}
                  minDate={editFeatureNew?.effectiveDate}
                  errors={errors}
                />
                </td>
                <td
                  className={clsx(classes?.featureCell)}
                  data-test="newFeatureNoStatus"
                ></td>
                </div>
                <div>
                <td
                  className={clsx(classes?.featureCell)}
                  data-test="addNewFeature"
                >
                 <div className={clsx(classes?.ButtonSection)}>
                  <Button
                    type="submit"
                    data-test={`feature:new:save}`}
                    adminColor
                    disabled={!isDropdownInteracted && !isStartDateInteracted && !isEndDateInteracted}
                  >
                    Add New Application
                  </Button>
                  { (isDropdownInteracted || isStartDateInteracted || isEndDateInteracted) && (
                  <Button
                    data-test="feature:new:cancel"
                    className={classes?.cancelButton}
                    color="secondary"
                    onClick={handleSaveOrCancelClick}
                  >
                    Reset
                  </Button>
                  )}
                  </div>
                </td>
                </div>
                </div>
              </tr>
            </table>
        </form>

      {error && (
        <Alert severity="error">
          <Typography data-test="feature:error">{error}</Typography>
        </Alert>
      )}
      <div className={clsx(classes?.ApplicationSectionHeader)}>
      <Typography variant="h3">Application List</Typography>
      </div>
      <form onSubmit={handleSubmit} data-test="featuresForm">
        <table
          className={clsx(classes?.featureTable)}
          data-test="featuresFormTable"
        >
          <thead>
            <tr className={clsx(classes?.featureHeader)}>
              {featuresRows.map(({ dataTest, name, key }) => (
                <th
                  align="left"
                  key={key}
                  className={clsx(classes?.featureCell)}
                  data-test={dataTest}
                >
                  <Typography variant="h4">{name}</Typography>
                </th>
              ))}
              <th className={clsx(classes?.featureCell)}></th>
            </tr>
          </thead>
          <tbody>
            {(!customerFeatures || customerFeatures?.length === 0) ? 
    (
      <tr>
    <td  colSpan={5} className={classes?.emptyFeaturesCell}> 
        <div className={clsx(classes?.EmptyComponent)}>
          <EmptyFeaturesMessage />
        </div>
      </td>
  </tr> ):
             customerFeatures.map(({ id, feature, effectiveTsz, expirationTsz }) => (
              <tr className={clsx(classes?.featureHeader)} key={id}>
                <td
                  className={clsx(classes?.featureCell)}
                  data-test={`featureName-${feature?.featureCode}`}
                >
                  <Typography variant="body1">{feature?.featureName}</Typography>
                </td>
                <td
                  className={clsx(classes?.featureCell)}
                  data-test={`featureEffective-${feature?.featureCode}`}
                >
                  {!(editCode === feature?.featureCode && isValidDate(effectiveTsz)) ? (
                  <Typography variant="body1">
                    {editFeature?.code === feature?.featureCode
                      ? (DateTime.fromISO(editFeature?.effectiveDate).isValid ? DateTime.fromISO(editFeature?.effectiveDate).toFormat('dd/MM/yyyy') : <span style={{ paddingLeft: '30px'}}>-</span>)
                      : (DateTime.fromISO(effectiveTsz).isValid ? DateTime.fromISO(effectiveTsz).toFormat('dd/MM/yyyy') : <span style={{ paddingLeft: '30px'}}>-</span>)}
                  </Typography>
                ) : (
                  <CustomDatePicker
                    label="Start Date"
                    value={editFeature?.code === feature?.featureCode ? editFeature?.effectiveDate : effectiveTsz}
                    onChange={handleExistingDateChange('effectiveDate')}
                    disabled={false}
                    maxDate={editFeature?.expirationDate}
                    minDate={null}
                    errors={[]}
                  />
                )}
                </td>
                <td
                  className={clsx(classes?.featureCell)}
                  data-test={`featureExpiration-${feature?.featureCode}`}
                >
                  {!(editCode === feature?.featureCode && (expirationTsz ? isValidDate(expirationTsz) : true)) ? (
                  <Typography variant="body1">
                    {editFeature?.code === feature?.featureCode
                      ? (DateTime.fromISO(editFeature?.expirationDate).isValid ? DateTime.fromISO(editFeature?.expirationDate).toFormat('dd/MM/yyyy') :<span style={{ paddingLeft: '30px'}}>-</span>)
                      : (DateTime.fromISO(expirationTsz).isValid ? DateTime.fromISO(expirationTsz).toFormat('dd/MM/yyyy') : <span  style={{ paddingLeft: '30px'}}>-</span>)}
                  </Typography>
                ) : (
                  <CustomDatePicker
                    label="End Date"
                    value={editFeature?.code === feature?.featureCode ? editFeature?.expirationDate : expirationTsz}
                    onChange={handleExistingDateChange('expirationDate')}
                    disabled={false}
                    maxDate={null}
                    minDate={editFeature?.effectiveDate}
                    errors={[]}
                  />
                )}
                </td>
                <td
                  className={clsx(classes?.featureCell)}
                  data-test={`featureStatus-${feature?.featureCode}`}
                >
                  {getFeatureStatus(effectiveTsz, expirationTsz)}
                </td>
                <td className={clsx(classes?.featureCell)}>
                  {editCode !== feature?.featureCode ? (
                    <div className={classes?.editButton}>
                      <Button
                        variant="text"
                        onClick={(e) => {
                          e.preventDefault();
                          setEditing(true);
                          handleEditClick(feature?.featureCode);
                        }}
                        data-test={`feature:edit:${feature?.featureCode}`}
                      >
                        Edit
                      </Button>
                    </div>
                  ) : (
                    <div>
                      <Button
                        data-test={`feature:edit:submit:${feature?.featureCode}`}
                        type="submit"
                        adminColor
                      >
                        Save
                      </Button>
                      <Button
                        data-test={`feature:edit:cancel:${feature?.featureCode}`}
                        className={classes?.cancelButton}
                        color="secondary"
                        onClick={handleSaveOrCancelClick}
                      >
                        Cancel
                      </Button>
                    </div>
                  )}
                </td>
              </tr>
            ))}
            </tbody>
            </table>
        
      </form>
    </div>
  );
};

export default Applications;
