import {
  Typography,
  MenuItem,
  SingleSelectionButtons,
  SelectionButton,
  Radio,
} from '@octanner/prism-core';
import { useState } from 'react';
import {
  ProgramDetailProps,
  //? These are not needed for now, but will be needed in the future
  // ModifiedProgramSalesOrgAndCountry,
  // SalesOrgList,
} from '../../types';
import {
  ButtonPadding,
  FormControlLabelW,
  HorizontalLine,
  PONumConfigTitle,
  POOptions,
  RadioGroupMLT,
  //? This is not needed for now, but will be needed in the future
  // SalesOrgError,
  SelectPOWidth,
  SpacingDiv,
  SpecificWidthSelect,
} from './styles';
//? These are not needed for now, but will be needed in the future
// import IncludedSalesOrg from './includedSalesOrg';
// import { SolidLineAccross } from '../salesOrg/styles';
import { useBankById } from '../../graphql';
import { ErrorTypography } from '../../styles';
import EligibilitySettings from './eligibilitySettings';
import ProgramCategory from './programCategory';
import { useFlags } from 'launchdarkly-react-client-sdk';
const CustomerPONunberData = [
  { id: 1, name: 'Dummy data 1' },
  { id: 2, name: 'Dummy data 2' },
  { id: 3, name: 'Dummy data 3' },
  { id: 4, name: 'Dummy data 4' },
  { id: 5, name: 'Dummy data 5' },
  { id: 6, name: 'Dummy data 6' },
  { id: 7, name: 'Dummy data 7' },
  { id: 8, name: 'Dummy data 8' },
  { id: 9, name: 'Dummy data 9' },
  { id: 10, name: 'Dummy data 10' },
  { id: 11, name: 'Dummy data 11' },
  { id: 12, name: 'Dummy data 12' },
];
const ProgramDetails = ({
  type,
  customerId,
  bankUuid,
  //? These two are not needed for now, but will be needed in the future
  // salesOrgs,
  // selectedSalesOrgs,
  handleProgramSettingChange,
  programSettings,
  bankError,
  modifiableParticipants,
  setModifiableParticipants,
  participantCount,
  handleParticipationSave,
  hasOriginalBank = false,
  typeError,
  categoryError,
  includedSalesOrgError,
}: ProgramDetailProps) => {
  const { data: bankData, loading } = useBankById(customerId);
  //? This is not needed for now, but will be needed in the future
  // const [salesOrgList, setSalesOrgList] = useState<SalesOrgList>({
  //   pointProgramSalsOrges: [],
  // });
  const [eligibilitySettings, toggleSettings] = useState(false);
  const [poNumConfig, setPoNunConfig] = useState('STANDARDPO');
  const { ccAdminPoNumberConfiguration } = useFlags();
  const bankList =
    bankData?.corePtMgmtGetBankInformationByCustomer?.information?.nodes;

  //? This is not needed for now, but will be needed in the future
  // useEffect(() => {
  //   let reformedProgramCustomerSalesOrg: ModifiedProgramSalesOrgAndCountry[] =
  //     [];
  //   salesOrgs?.forEach(({ customerCountrySalesOrg, pointBankSalesOrgUuid }) => {
  //     const { assignedSalesOrg, coreCountry } = customerCountrySalesOrg;
  //     const selectedSalesOrg = selectedSalesOrgs?.find(
  //       (x) => x.pointBankSalesOrgUuid === pointBankSalesOrgUuid
  //     );
  //     const enabled =
  //       selectedSalesOrg !== undefined &&
  //       selectedSalesOrg?.disabledTsz === null;
  //     const availableSalesOrg = reformedProgramCustomerSalesOrg
  //       .map((x) => x.salesOrg)
  //       .indexOf(assignedSalesOrg.salesOrgName);
  //     if (availableSalesOrg === -1) {
  //       const newData: ModifiedProgramSalesOrgAndCountry = {
  //         salesOrg: assignedSalesOrg.salesOrgName,
  //         enabled,
  //         countryOptions: [
  //           {
  //             location: coreCountry.countryName,
  //             enabled,
  //             pointBankSalesOrgUuid,
  //           },
  //         ],
  //       };
  //       reformedProgramCustomerSalesOrg.push({
  //         ...newData,
  //       });
  //     } else {
  //       const prev = reformedProgramCustomerSalesOrg[availableSalesOrg];
  //       reformedProgramCustomerSalesOrg.splice(availableSalesOrg, 1);
  //       const enabledExisting = salesOrgs
  //         .filter(
  //           (x) =>
  //             x.customerCountrySalesOrg.assignedSalesOrg.salesOrgName ===
  //             assignedSalesOrg.salesOrgName
  //         )
  //         .some(
  //           (y) =>
  //             selectedSalesOrgs?.find(
  //               (x) => x.pointBankSalesOrgUuid === y.pointBankSalesOrgUuid
  //             )?.disabledTsz === null
  //         );
  //       const newData: ModifiedProgramSalesOrgAndCountry[] = [
  //         ...reformedProgramCustomerSalesOrg,
  //         {
  //           salesOrg: assignedSalesOrg.salesOrgName,
  //           enabled: enabledExisting ? true : false,
  //           countryOptions: [
  //             ...prev.countryOptions,
  //             {
  //               location: coreCountry.countryName,
  //               enabled: enabled,
  //               pointBankSalesOrgUuid,
  //             },
  //           ],
  //         },
  //       ];
  //       reformedProgramCustomerSalesOrg = newData;
  //     }
  //   });
  //   setSalesOrgList({ pointProgramSalsOrges: reformedProgramCustomerSalesOrg });
  // }, [salesOrgs, selectedSalesOrgs]);
  const handleAddEligability = () => {
    setModifiableParticipants((prev) => [
      ...prev,
      { isIncluded: true, segmentType: 'all' },
    ]);
  };
  const handleRemoveEligibility = (index: number) => {
    setModifiableParticipants((prev) => {
      const newArray = [...prev];
      newArray.splice(index, 1);
      return newArray;
    });
  };
  //? This is not needed for now, but will be needed in the future
  // const handleStateChange = (_, arr) => {
  //   // ! REALLY gross, do not recommend.
  //   const newValues = arr
  //     .map((x) =>
  //       x.countryOptions
  //         .map((y) =>
  //           y.enabled
  //             ? {
  //                 pointBankSalesOrgUuid: y.pointBankSalesOrgUuid,
  //                 disabledTsz: null,
  //               }
  //             : {
  //                 pointBankSalesOrgUuid: y.pointBankSalesOrgUuid,
  //                 disabledTsz: Date.now(),
  //               }
  //         )
  //         .filter((n) => n)
  //     )
  //     .flat();
  //   handleProgramSettingChange('programSalesOrgs', newValues);
  // };
  const handleSave = () => {
    toggleSettings(false);
    if (handleParticipationSave) {
      handleParticipationSave();
    }
  };
  return (
    <div>
      <EligibilitySettings
        participantCount={participantCount}
        eligibilitySettings={eligibilitySettings}
        toggleSettings={toggleSettings}
        modifiableParticipants={modifiableParticipants}
        setModifiableParticipants={setModifiableParticipants}
        handleAddEligability={handleAddEligability}
        handleRemoveEligibility={handleRemoveEligibility}
        handleSave={handleSave}
      />
      <HorizontalLine />
      <Typography variant="h2">Bank association</Typography>
      <SpacingDiv>
        <SpecificWidthSelect
          label="Select bank"
          disabled={type === 'edit' && hasOriginalBank}
          value={
            bankUuid &&
            bankList?.some((bank) => bank.pointBankUuid === bankUuid)
              ? bankUuid
              : ''
          }
          error={bankError}
          onChange={(e) =>
            handleProgramSettingChange(
              'pointBankUuid',
              e.target.value as string
            )
          }
        >
          {!loading &&
            bankList &&
            bankList.map(({ pointBankName, pointBankUuid }) => (
              <MenuItem value={pointBankUuid} key={pointBankUuid}>
                {pointBankName}
              </MenuItem>
            ))}
        </SpecificWidthSelect>
        {bankError && (
          <ErrorTypography>This is a required field</ErrorTypography>
        )}
      </SpacingDiv>
      {ccAdminPoNumberConfiguration && (
        <>
          <PONumConfigTitle variant="h3">
            PO number configuration
          </PONumConfigTitle>
          <SingleSelectionButtons
            // onChange={(value) => handleBillingLocationTypeSelection(value)}
            // value={bankData.billingConfig?.billingLocationType || ''}
            value={poNumConfig}
            onChange={(value) => setPoNunConfig(value)}
          >
            <SelectionButton value="STANDARDPO">
              <ButtonPadding>Standard PO number</ButtonPadding>
            </SelectionButton>
            <SelectionButton value="CUSTOMERPO">
              Custom PO number
            </SelectionButton>
          </SingleSelectionButtons>
          {poNumConfig === 'STANDARDPO' ? (
            <>
              <POOptions variant="h4">Standard PO Number Options:</POOptions>
              <RadioGroupMLT
                aria-label="standardPO"
                name="standardPO"
                // value={bankData?.billingConfig.billingLocationRecipientType}
                // onChange={(e) => {
                //   handleBillingLocationRecipientTypeChange(e.target.value);
                // }}
              >
                <FormControlLabelW
                  value="PO1"
                  control={<Radio />}
                  label="PO Number 1"
                />
                <FormControlLabelW
                  value="PO2"
                  control={<Radio />}
                  label="PO Number 2"
                />
              </RadioGroupMLT>
            </>
          ) : (
            <SelectPOWidth
              label="Select PO number"
              // disabled={type === 'edit' && hasOriginalBank}
              // value={
              //   bankUuid &&
              //   bankList?.some((bank) => bank.pointBankUuid === bankUuid)
              //     ? bankUuid
              //     : ''
              // }
              value={''}
              // error={bankError}
              // onChange={(e) =>
              //   handleProgramSettingChange(
              //     'pointBankUuid',
              //     e.target.value as string
              //   )
              // }
            >
              {CustomerPONunberData.map(({ id, name }) => (
                <MenuItem key={id}>{name}</MenuItem>
              ))}
            </SelectPOWidth>
          )}
        </>
      )}
      {/* 
    //? This is not needed for now, but will be needed in the future
      <Typography variant="h2">Included sales org and countries</Typography>
      {includedSalesOrgError && (
        <SalesOrgError>No sales orgs assigned</SalesOrgError>
      )}
      <SolidLineAccross />

      <IncludedSalesOrg
        salesOrgList={salesOrgList}
        handleProgramValueUpdate={handleStateChange}
      /> */}
      <ProgramCategory
        type={type}
        programAssignmentCode={programSettings.programFinanceTypeCode}
        //?Do not need this for now
        // managerDashBoardEnabled={programSettings.managerDashboardEnabled}
        handleProgramSettingChange={handleProgramSettingChange}
        typeError={typeError}
        programCategoryId={programSettings.programCategoryId}
        categoryError={categoryError}
      />
    </div>
  );
};
export default ProgramDetails;
