import styled from '@emotion/styled';
import { Button, FileUpload, Modal, Typography } from '@octanner/prism-core';
import { Check, Close } from '@octanner/prism-icons';
import React, { useState } from 'react';
import { defaults } from '../../../utils/default';
import { UploadTranslationModalProps } from '../../../utils/types';

const RoundedBox = styled.div`
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
`;

const FileName = styled.div`
  flex-direction: column;
  margin-left: 20px;
  flex-grow: 1;
`

const FileUploadContainer = styled.div`
  margin-top: 48px;
  margin-bottom: 48px;
`

const UploadTranslationModal: React.FC<UploadTranslationModalProps> = ({
  showUploadTranslationModal,
  setShowUploadTranslationModal,
  uploadFile
}) => {

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [fileUploadError, setFileUploadError] = useState<string>('');

  const handleFileChange = (files: FileList): void => {
    setSelectedFile(files[0]);
  };

  return (
    <Modal
      actions={
        <>
          <Button onClick={() => setShowUploadTranslationModal(false)} color='secondary'>{defaults.cancel}</Button>
          <Button disabled={!selectedFile} onClick={() => uploadFile(selectedFile)} adminColor>{defaults.upload}</Button>
        </>
      }
      id='delete-job-modal'
      title={defaults.upload_translation}
      open={showUploadTranslationModal}
      onClose={() => setShowUploadTranslationModal(false)}>
      <FileUploadContainer>
        {!selectedFile && <FileUpload
          onChange={handleFileChange}
          supportedExtensions={['xlsx']}
          data-testid='file-upload'
          onError={(e: string) => setFileUploadError(e)}
          error={!!fileUploadError}
          helperText={fileUploadError}
        />}
        {selectedFile && <RoundedBox>
          <Check />
          <FileName>
            <Typography>{selectedFile.name}</Typography>
            <Typography color='textSecondary'>
              {defaults.file_upload_success}
            </Typography>
          </FileName>
          <Close onClick={() => setSelectedFile(null)} />
        </RoundedBox>}
      </FileUploadContainer>
    </Modal>);
}

export default UploadTranslationModal;