import { makeStyles, Theme } from '@material-ui/core/styles';
import Colors from '../../common/constants/Colors';
import styled from '@emotion/styled';
import { Tabs } from '@octanner/prism-core';
import { tannerSemantic } from '@octanner/prism-core/ThemeProvider/colors';

export const useStyles = makeStyles((theme: Theme) => ({
  page: {
    marginTop: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    width: 840,
  },
  pageInstructions: {
    marginTop: theme.spacing(2),
  },
  clientSearch: {
    marginTop: theme.spacing(3),
  },
  currentMigrations: {
    marginTop: theme.spacing(6),
  },
  root: {
    marginTop: theme.spacing(2),
  },
  back: {
    marginLeft: -5,
  },
  name: {
    marginTop: theme.spacing(3),
  },
  nameLoad: {
    display: 'flex',
    marginTop: theme.spacing(3),
  },
  instructions: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  mdBottom: {
    marginTop: theme.spacing(2),
  },
  card: {
    marginTop: theme.spacing(2),
  },
  rootContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  rootsubContent: {
    display: 'flex',
    padding: '24px 0px',
    width: '100%',
  },
  rootsubContentBody: {
    marginLeft: '24px',
    width: '100%',
  },
  rootsubContentBodyTypography: {
    marginTop: '12px',
    width: '520px',
    height: '40px',
    marginBottom: '16px',
  },

  preCheck: {
    marginTop: '32px',
  },

  cardContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  cardHeader: {
    display: 'flex',
    alignItems: 'center',
  },
  cardHeaderTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
  },
  cardLoad: {
    margin: 'auto',
    display: 'flex',
  },
  cardButton: {
    marginLeft: 'auto',
  },
  listheader: {
    color: Colors.black,
    fontWeight: 800,
  },
  listButton: {
    float: 'right',
  },
  listItemText: {
    width: '100%',
    marginLeft: '8px',
    marginTop: '8px',
    marginBottom: '8px',
  },
  searchLoad: {
    display: 'flex',
    margin: 'auto',
    marginTop: 100,
  },
  searchOverlay: {
    maxHeight: 860,
    overflow: 'auto',
  },
  tag: {
    height: 25,
    marginLeft: 8,
  },
  dateTime: {
    marginLeft: 8,
    fontSize: 12,
    color: Colors.gray600,
  },
  success: {
    color: Colors.green,
    fontSize: 14,
  },
  migrationCardContent: {
    display: 'flex',
  },
  error: {
    color: Colors.red,
    fontSize: 14,
  },
  failed: {
    color: Colors.red,
  },
  checking: {
    color: Colors.black,
    fontSize: 14,
  },
  notStarted: {
    color: Colors.gray600,
    fontSize: 14,
  },
  errorTag: {
    width: '100%',
    justifyContent: 'space-between',
    height: 36,
  },
  dataMigration: {
    marginBottom: 0,
  },
  checkAndWarning: {
    marginRight: 12,
    marginTop: 2,
  },
  errorDiv: {
    margin: 9,
    marginLeft: -25,
  },
  emptyText: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(10),
  },
  loadMore: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(1),
  },
  currentMigration: {
    opacity: '{currentMigrationDimOnTrue()}',
  },
  migrationLink: {
    textDecoration: 'none',
  },
  avatarSuccess: {
    backgroundColor: tannerSemantic.success.background,
    color: tannerSemantic.success.color,
  },
  avatarFailed: {
    color: Colors.black,
    backgroundColor: Colors.gray200,
    fontWeight: 800,
  },
  avatarCheck: {
    marginLeft: '10px',
  },
  rootDisable: {
    marginTop: theme.spacing(2),
    opacity: 0.5,
  },
}));

export const MigrationTabs = styled(Tabs)`
  &.MuiTabs-root {
    border-bottom: 1px solid #e1e1e1;
    width: 100%;
  }
`;

export const currentMigrationDimOnTrue = (flag = false) => {
  return {
    opacity: flag ? 0.35 : 1,
  };
};
