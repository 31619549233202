import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { LOCALES_LIST, CHECKED_LOCALES_LIST } from './query';
import LocalesLoaded from './LocalesLoaded';
import { customerId, langObjectTranfrom } from '../../utilities/utility';
import LanguagesLoaderIcon from './LanguagesLoaderIcon';
import EditLocales from './EditLocalesLoaded';
import { useTranslation } from 'react-i18next';
import { deafultLanguageType } from './types'

const Languages = ({
  confirmationData,
  onTabSwitch,
  errorsDisplayNumber,
  onSave,
  onSapUpdateError,
  tabsEditModeStatus,
  setTabsEditModeStatus,
  setUpdateSapError,
}) => {

  const {
    loading,
    error,
    data: localeList,
  } = useQuery(LOCALES_LIST);
  
  const {
    loading: loadingChecked,
    error: errorChecked,
    data: selectedlocale,
    refetch: refetchCustomerLocale
  } = useQuery(CHECKED_LOCALES_LIST, {
    variables: {
      input: {
        customerId: customerId,
        isActive: true,
      },
    },
  });

  const [editLangauge, setEditLanguage] = useState(false);
  const [languageDataChanged, setLanguageDataChanged] = useState(false);
  const { nodes:customerLocalList = [] } = selectedlocale?.customerCoreLocaleMapsByCustomer || {};
  const { t } = useTranslation();
  const [deafultLanguage, setDeafultLanguage] = useState<deafultLanguageType>({
    languageCode : '', defaultLocale : false, checked : false, language:'',country:''
  });
  const [currentDefaultLanguage, setCurrentDefaultLanguage] = useState(deafultLanguage);

  useEffect(() => {
    setEditLanguage(customerLocalList?.length === 0);
    const defaultLocale = customerLocalList.find((item: any) => item.isDefaultLocale === true);
    if(defaultLocale){
      setDeafultLanguage(langObjectTranfrom(defaultLocale));
      setCurrentDefaultLanguage(langObjectTranfrom(defaultLocale));
    }
  }, [customerLocalList]);

  useEffect(() => {
    if(languageDataChanged){
      onTabSwitch({...confirmationData, editMode: true });
    }else{
      onTabSwitch({...confirmationData, editMode: false});
    }
  }, [languageDataChanged]);

  return (
    <>
      {(loading || loadingChecked) && (
        <div style={{ marginTop: '50px' }}>
          <LanguagesLoaderIcon />
        </div>
      )}
      {(error || errorChecked) && <div>{t('error')}</div>}
      {(localeList && selectedlocale) && (editLangauge ? 
        (<LocalesLoaded
            localeList={localeList}
            selectedlocale={selectedlocale}
            refetchCustomerLocale={refetchCustomerLocale}
            setEditLanguage={setEditLanguage}
            deafultLanguage={deafultLanguage}
            setLanguageDataChanged={setLanguageDataChanged}
            onSave={onSave}
            onSapUpdateError={onSapUpdateError}
            errorsDisplayNumber = {errorsDisplayNumber}
            tabsEditModeStatus={tabsEditModeStatus}
            setTabsEditModeStatus={setTabsEditModeStatus}
            setDeafultLanguage={setDeafultLanguage}
            currentDefaultLanguage={currentDefaultLanguage}
            setUpdateSapError={setUpdateSapError}
          />)
        : (<EditLocales
            handleEdit={() => setEditLanguage(true)}
            selectedLanguages={customerLocalList}
            deafultLanguage={deafultLanguage}
            setDeafultLanguage={setDeafultLanguage}
            errorsDisplayNumber = {errorsDisplayNumber}
            onSave={onSave}
            refetchCustomerLocale={refetchCustomerLocale}
            onSapUpdateError={onSapUpdateError}
            currentDefaultLanguage={currentDefaultLanguage}
            setUpdateSapError={setUpdateSapError}
          />)
      )}
    </>
  );
};

export default Languages;
