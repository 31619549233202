import ConfirmModal from './ConfirmModal';
import { BaseModalProps } from '../types';

const UnsavedModal = (modalProps: BaseModalProps) => {
  return (
    <ConfirmModal
      {...modalProps}
      title="Unsaved changes"
      message="Are you sure you want to cancel? You have unsaved changes."
      confirmText="Discard changes"
      color="primary"
    />
  );
};

export default UnsavedModal;
