import { useContext } from 'react';
import { VibesLanding as VibeGraph } from '@octanner/cc-vibes-ui';
import { CustomerContext } from '../../utils/context/CustomerContext';
import { useNavigate } from './hooks';
import VibesStylesProvider from '../../utils/VibesStylesProvider';

export default function VibesLanding() {
  const {
    customer: { id: customerUuid, stpNumber },
  } = useContext(CustomerContext);
  const { navigate, location } = useNavigate(customerUuid);

  return (
    <VibesStylesProvider>
      <VibeGraph
        key={location.key}
        coreCustomerUuid={customerUuid}
        stp={stpNumber}
        onNavigation={navigate}
        location={location}
      />
    </VibesStylesProvider>
  );
}
