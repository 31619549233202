import { Avatar, Divider, Typography } from '@octanner/prism-core';
import { useStyles } from './styles';
import MigrationHeader from './MigrationHeader';

const DataMigration = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Divider />
      <div className={classes.rootContent} data-testid={'data-migration-card'}>
        <div className={classes.rootsubContent}>
          <div>
            <Avatar size="sm" alt="" className={classes.avatarFailed}>
              {' '}
              1
            </Avatar>
          </div>
          <div className={classes.rootsubContentBody}>
            <MigrationHeader
              stalled={false}
              failedTsz={null}
              completeTsz={null}
              isCreated={false}
              percentComplete={0}
              buttonLoading={false}
              preMigrationStatusLoading={false}
              handleRun={() => {}}
              handleReRun={() => {}}
              migrationName="Data Migration"
            />
            <div className={classes.rootsubContentBodyTypography}>
              <Typography
                className={classes.instructions}
                data-testid={'instructions'}
              >
                Please contact the Shared Web team in advance to manually kick
                off the data migration.
              </Typography>
            </div>
          </div>
        </div>
      </div>
      <Divider />
    </div>
  );
};

export default DataMigration;
