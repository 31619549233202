import { Route, Switch } from 'react-router-dom';
import ClientAccess from './index';
import EditClientRoles from './editClientRoles';

const ClientsRolesRoutes = () => {
  return (
    <Switch>
      <Route path={`/client-access`} component={ClientAccess} exact />
      <Route
        path={`/client-access/:customerUuid/edit`}
        component={EditClientRoles}
      />
    </Switch>
  );
};

export default ClientsRolesRoutes;
