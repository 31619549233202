import { TEST_ACTIVE_ADMIN_ROLES_PAGED_DATA } from '../../constants';
import { AppContext } from '../../../../../utils/context/AppContext';
import { useContext } from 'react';
import { GET_ADMIN_ACTIVE_ROLES } from '../graphql/gql';
import { useQuery } from '@apollo/client';
import {
  AdminPagedActiveRolesResponse,
  GetAdminPagedRolesInput,
} from '../graphql/types';

const mockData = {
  data: TEST_ACTIVE_ADMIN_ROLES_PAGED_DATA.content,
  loading: false,
  refetch: () => {},
};

export const useGetAdminActiveRoles = (
  identityUuid: string,
  skip: boolean = false
) => {
  const { config } = useContext(AppContext);
  const query = useQuery<
    AdminPagedActiveRolesResponse,
    GetAdminPagedRolesInput
  >(GET_ADMIN_ACTIVE_ROLES, {
    variables: {
      identityUuid: identityUuid,
      pageNumber: 0,
      pageSize: 250,
    },
    context: {
      clientName: 'roleDelegator',
    },
    skip: config.useRoleDelegatorMockData || skip,
  });
  return config.useRoleDelegatorMockData
    ? mockData
    : {
        ...query,
        data: query.data?.rdFindActiveRolesByAdmin.content,
      };
};
