import React, { useState, useEffect } from 'react';
import { Avatar, Divider, Typography } from '@octanner/prism-core';
import {
  INITIATIVE_CREATE_MIGRATION,
  INITIATVES_RUN_PREMIGRATION_CHECK,
  INITIATIVES_TRIGGER_MIGRATION,
} from './graphql/mutations';
import { INITIATIVE_MIGRATION_STATUS } from './graphql/queries';
import { useQuery, useMutation } from '@apollo/client';
import { DateTime } from 'luxon';
import { useStyles } from './styles';
import MigrationHeader from './MigrationHeader';
import { InitiativeMigrationProps } from './types';
import Items from './MigrationItems';
import {
  PreInitiativeMigrationItems,
  InitiativeDataMigrationItems,
} from './constants';
import { Check } from '@octanner/prism-icons';
import CircularProgress from '../../common/CircularProgress';

const CurrentMigration: React.FC<InitiativeMigrationProps> = ({
  coreCustomerUuid,
}) => {
  const classes = useStyles();
  const {
    data,
    loading,
    error: migrationStatusError,
    startPolling,
    stopPolling,
  } = useQuery(INITIATIVE_MIGRATION_STATUS, {
    fetchPolicy: 'network-only',
    variables: { coreCustomerUuid: coreCustomerUuid },
    pollInterval: 5000,
  });

  const [createMigration] = useMutation(INITIATIVE_CREATE_MIGRATION);
  const [runPremigrationStatusCheck, { loading: preMigrationStatusLoading }] =
    useMutation(INITIATVES_RUN_PREMIGRATION_CHECK, {
      fetchPolicy: 'network-only',
    });
  const [triggerProc] = useMutation(INITIATIVES_TRIGGER_MIGRATION);

  // Pre-Checks for initiatives
  const [coreMigrationCompleted, setCoreMigrationCompleted] =
    useState<boolean>(false);
  const [initiativesEnabled, setInitiativesEnabled] = useState<boolean>(false);

  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  const [failedTsz, setFailedTsz] = useState<string | null>(null);
  const [completeTsz, setCompleteTsz] = useState<string | null>(null);
  const [isCreated, setIsCreated] = useState<boolean>(false);
  const [migrationUuid, setMigrationUuid] = useState<string | null>(null);
  const [percentComplete, setPercentComplete] = useState<number>(0);

  useEffect(() => {
    if (migrationStatusError?.message === 'Migration was not found') {
      stopPolling();
    }
  }, [migrationStatusError, stopPolling]);

  useEffect(() => {
    if (data) {
      const { ccInitiativeMigrationStatusByCoreCustomerId = {} } = data;
      const { initiativeMigrationReport = {} } =
        ccInitiativeMigrationStatusByCoreCustomerId;
      const {
        classicToCCInitiativesMigration = {},
        initiativeMigrationUuid,
        migrationCompletedTsz,
        migrationFailedTsz,
      } = initiativeMigrationReport;
      const {
        preInitiativeMigrationStatus = {},
        storedProcedureStartedTsz,
        storedProcedureCompletedTsz,
        migrationProgress,
      } = classicToCCInitiativesMigration;
      const { coreMigrationCompleted, initiativesEnabled } =
        preInitiativeMigrationStatus;
      setCoreMigrationCompleted(coreMigrationCompleted);
      setInitiativesEnabled(initiativesEnabled);
      setMigrationUuid(initiativeMigrationUuid);
      setCompleteTsz(migrationCompletedTsz);
      setFailedTsz(migrationFailedTsz);

      if (migrationCompletedTsz || migrationFailedTsz) {
        stopPolling();
      }

      if (
        migrationCompletedTsz ||
        storedProcedureStartedTsz ||
        storedProcedureCompletedTsz
      ) {
        setIsCreated(true);
      }

      if (storedProcedureStartedTsz && !storedProcedureCompletedTsz) {
        setPercentComplete(
          Math.round(
            (migrationProgress?.stepsCompleted /
              migrationProgress?.totalSteps) *
              100
          ) || 1
        );
      }
    }
    //eslint-disable-next-line
  }, [data, stopPolling]);

  const handleRun = () => {
    setButtonLoading(true);
    setIsCreated(true);
    if (!migrationUuid) {
      createMigration({ variables: { coreCustomerUuid: coreCustomerUuid } })
        .then(({ data }) => {
          const migrationUuid =
            data?.ccCreateInitiativeMigration?.initiativeMigrationReport
              ?.initiativeMigrationUuid;
          setMigrationUuid(migrationUuid);
          preMigrationAndTriggerProc(migrationUuid);
          setButtonLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setButtonLoading(false);
        });
    } else {
      preMigrationAndTriggerProc(migrationUuid);
    }
  };

  const handleReRun = () => {
    setButtonLoading(true);
    preMigrationAndTriggerProc(migrationUuid);
  };

  const preMigrationAndTriggerProc = (mUuid: string | null) => {
    if (mUuid) {
      runPremigrationStatusCheck({
        variables: { migrationUuid: mUuid },
      })
        .then(async (migrationStatus) => {
          const { ccRunPreInitiativeMigrationStatusCheck = {} } =
            migrationStatus.data;
          const { initiativeMigrationReport = {} } =
            ccRunPreInitiativeMigrationStatusCheck;
          const {
            classicToCCInitiativesMigration = {},
            migrationCompletedTsz = '',
            migrationFailedTsz = '',
          } = initiativeMigrationReport;
          const { preInitiativeMigrationStatus = {} } =
            classicToCCInitiativesMigration;
          const {
            coreMigrationCompleted,
            initiativesEnabled,
            initiativesMigratedCustomer,
          } = preInitiativeMigrationStatus;
          failOrCompleteCheck(migrationCompletedTsz, migrationFailedTsz);
          preMigrationChecks(preInitiativeMigrationStatus);

          if (
            coreMigrationCompleted &&
            initiativesEnabled &&
            !initiativesMigratedCustomer
          ) {
            triggerProc({ variables: { migrationUuid: mUuid } })
              .then(() => {
                startPolling(1500);
              })
              .catch((err) => {
                console.error(err.message);
                setButtonLoading(false);
                setFailedTsz(DateTime.now().toString());
              });
          } else {
            setButtonLoading(false);
            setFailedTsz(DateTime.now().toString());
          }
        })
        .catch((err) => {
          console.log(err.message);
          setButtonLoading(false);
          setFailedTsz(DateTime.now().toString());
        });
    }
  };

  const failOrCompleteCheck = (
    migrationCompletedTsz: string,
    migrationFailedTsz: string
  ) => {
    if (migrationFailedTsz) {
      setFailedTsz(migrationFailedTsz);
      setButtonLoading(false);
    }
    if (migrationCompletedTsz) {
      setCompleteTsz(migrationCompletedTsz);
      setButtonLoading(false);
    }
  };

  const preMigrationChecks = (preInitiativeMigrationStatus) => {
    if (preInitiativeMigrationStatus) {
      setCoreMigrationCompleted(
        preInitiativeMigrationStatus?.coreMigrationCompleted
      );
      setInitiativesEnabled(preInitiativeMigrationStatus?.initiativesEnabled);
      setButtonLoading(false);
    }
  };

  const items = PreInitiativeMigrationItems(
    initiativesEnabled,
    coreMigrationCompleted
  );

  const dataMigrationitems = InitiativeDataMigrationItems(!!completeTsz);

  return (
    <div className={classes.root}>
      <div className={classes.cardContent} data-testid={'data-migration-card'}>
        {loading ? (
          <CircularProgress
            className={classes.cardLoad}
            size={40}
            thickness={4}
          />
        ) : (
          <>
            <div className={classes.rootsubContent}>
              <div>
                {completeTsz ? (
                  <Avatar size="sm" alt="" className={classes.avatarSuccess}>
                    {' '}
                    <Check />
                  </Avatar>
                ) : (
                  <Avatar size="sm" alt="" className={classes.avatarFailed}>
                    {' '}
                    2
                  </Avatar>
                )}
              </div>
              <div className={classes.rootsubContentBody}>
                <MigrationHeader
                  isCreated={isCreated}
                  stalled={false}
                  failedTsz={failedTsz}
                  completeTsz={completeTsz}
                  percentComplete={percentComplete}
                  buttonLoading={buttonLoading}
                  preMigrationStatusLoading={preMigrationStatusLoading}
                  handleRun={handleRun}
                  handleReRun={handleReRun}
                  migrationName="Initiative Migration"
                  isReRunAvailable={!completeTsz}
                />
                <div className={classes.rootsubContentBodyTypography}>
                  <Typography
                    className={classes.instructions}
                    data-testid={'instructions'}
                  >
                    This is run once and may take up to <b>15 minutes</b>.
                    Victories data is deleted after 30 days once the migration
                    has successfully run.
                  </Typography>
                </div>
                {data && (
                  <>
                    <Typography variant="h4" data-testid={'pre-check'}>
                      Pre-Check
                    </Typography>
                    <ul>
                      {items.map(
                        ({ status, type, itemName, key, errorMessage }) => {
                          return (
                            <Items
                              key={key}
                              status={status}
                              type={type}
                              itemName={itemName}
                              preMigrationFailure
                              migrationFailed={!status}
                              isCreated={isCreated}
                              errorMessage={errorMessage}
                            />
                          );
                        }
                      )}
                    </ul>
                    <Typography variant="h4" data-testid={'data-migration'}>
                      Data Migration
                    </Typography>
                    <ul>
                      {dataMigrationitems.map(
                        ({ type, status, itemName, key }) => {
                          return (
                            <Items
                              key={key}
                              type={type}
                              status={status}
                              itemName={itemName}
                              isCreated={isCreated}
                              migrationFailed={!!failedTsz}
                            />
                          );
                        }
                      )}
                    </ul>
                  </>
                )}
              </div>
            </div>
            <Divider />
          </>
        )}
      </div>
    </div>
  );
};

export default CurrentMigration;
