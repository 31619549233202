import SingleCard from './SingleCard';
import { BankListProps } from './types';
import { Link as RouterLink, useRouteMatch } from 'react-router-dom';
import {
  CardContainer,
  CardSkeleton,
  NoCardContainer,
  NoCardText,
  NoCardTitle,
} from './styles';
import { Button } from '@octanner/prism-core';

export const BankListCard = ({
  allVMs,
  enhancedTableProps,
  loading,
  checkIfDefaultAccruingExists,
}: BankListProps) => {
  const { classes } = enhancedTableProps;
  const match = useRouteMatch();

  return (
    <>
      {allVMs?.length > 0 ? (
        <CardContainer>
          {allVMs.map((value, index) => (
            <SingleCard
              key={value.uuid}
              classes={classes}
              {...value}
              index={index}
            />
          ))}
          {loading ? <BankCardSkeleton /> : <></>}
        </CardContainer>
      ) : (
        <NoCardContainer>
          <NoCardTitle variant="h3">Let's get things going</NoCardTitle>
          <NoCardText>Begin by creating your first bank</NoCardText>
          <Button
            disableRipple
            component={RouterLink}
            adminColor
            // @ts-ignore prism issue
            to={`${match.url}/new${
              checkIfDefaultAccruingExists === undefined ||
              checkIfDefaultAccruingExists === null
                ? '?defaultBank=false'
                : ''
            }`}
            data-test="banks:new"
          >
            Create a Bank
          </Button>
        </NoCardContainer>
      )}
    </>
  );
};

const BankCardSkeleton: any = () => {
  return Array.from({ length: 6 }, (_, i) => (
    <CardSkeleton key={i} variant="rectangular" height={218} animation="wave" />
  ));
};
