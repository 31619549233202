import React, { useEffect } from 'react';
import { Select, Typography, MenuItem } from '@octanner/prism-core';
import styled from '@emotion/styled';
import { defaults } from './utilities/defaults';

const SelectContainer = styled.div`
    width: 592px;
    margin-bottom: 120px;
`;

const Header = styled(Typography)`
    margin-bottom: 32px;
    margin-top: 40px;
`;

const StyledSelect = styled(Select)`
    margin-bottom: 16px;
`;

const InvoiceDetails = ({
    invoiceSelecedDetails,
    setInvoiceSelecedDetails,
    invoiceConfigData,
    invoiceConfig,
    setInvoiceConfig,
    setShowAlert,
}) => {


    useEffect(() => {
        if (invoiceConfigData?.data) {
          const {
            allCoreBillingSchedules = {},
            allCoreBillingTypes = {},
            allCoreInvoiceMediums = {},
          } = invoiceConfigData?.data || {};
      
          const {coreBillingSchedule,defaultCoreBillingSchedule} = allCoreBillingSchedules;
          const { coreBillingTypes, defaultCoreBillingType } = allCoreBillingTypes;
          const {coreInvoiceMediums,defaultCoreInvoiceMedium} = allCoreInvoiceMediums;
          const invoiceMediumList = coreInvoiceMediums?.nodes || [];
          const billingScheduleList = coreBillingSchedule?.nodes || [];
          const billingTypeList = coreBillingTypes?.nodes || [];
          const invoiceDetailsList = [invoiceMediumList, billingScheduleList, billingTypeList ];
          const invoiceDetailsNames = ['invoiceMedium', 'billingSchedule', 'billingType' ];
          const deafultInvoice = [defaultCoreInvoiceMedium, defaultCoreBillingSchedule, defaultCoreBillingType ];
      
      
          const updatedInvoiceConfig = invoiceConfig.map((configObj, index) => {
            const options = invoiceDetailsList[index].map((item: any) => {
              return {
                code : item[invoiceDetailsNames[index] + 'Code'],
                description : item[invoiceDetailsNames[index] + 'Description']
              }
            });
            const codeString =invoiceDetailsNames[index] + 'Code';
            setInvoiceSelecedDetails((prevState) => ({
                ...prevState,
                [invoiceDetailsNames[index]]: invoiceSelecedDetails[invoiceDetailsNames[index]] || deafultInvoice[index][codeString]
            }));
            return { ...configObj, options, defaultValue: deafultInvoice[index][codeString] };
          });
          setInvoiceConfig(updatedInvoiceConfig);
        }
      }, [invoiceConfigData]);

    return (
        <SelectContainer>
            <Header variant="h3">{defaults.invoicing_details}</Header>
            {
                invoiceConfig.map(({id,label,defaultValue,options}, index) => ( 
                    <StyledSelect
                    key={`invoice-detail-${index}`}
                    id={`invoice-detail-${index}`}
                    label = {label}
                    fullWidth
                    defaultValue={defaultValue}
                    value={invoiceSelecedDetails[id]}
                    onChange={(e) => {
                        setInvoiceSelecedDetails((prevState) => ({
                            ...prevState,
                            [id]: e.target.value
                        }));
                        setShowAlert(false);
                    }}
                >
                {
                    options.map(({code, description }) => (
                        <MenuItem value={code} key={code}>{description}</MenuItem>
                    ))
                }
                </StyledSelect>      
            ))
      }
        </SelectContainer>
    );


      

};

export default InvoiceDetails;
