import React from "react";
import { useQuery } from "@apollo/client";
import { GET_ALL_CORE_VALUES_BY_CUSTOMER } from './query';
import { customerId } from '../../utilities/utility';
import CoreValues from "./CoreValues";

const CoreValuesLoading = ({onPermissionError,setUpdateSapError}) => {
  const {
    loading: loadingAllCoreValues,
    data: dataAllCoreValues,
    refetch: refetchAllCoreValues,
  } = useQuery(GET_ALL_CORE_VALUES_BY_CUSTOMER, {
    variables: {
      customerId: customerId,
    },
  });
    return (
        <>
        {
            !loadingAllCoreValues && (
                <CoreValues
                  allCoreValues={dataAllCoreValues}
                  refetchAllCoreValues={refetchAllCoreValues}
                  onPermissionError={onPermissionError}
                  setUpdateSapError={setUpdateSapError}
                />
            )
        }
        </>
    );
};

export default CoreValuesLoading;
