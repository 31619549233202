import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Colors from '../common/constants/Colors';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    topNav: {
      overflowY: 'auto',
      overflowX: 'hidden',
      maxHeight: `calc(100vh - 190px)`,
    },
    bottomNav: {
      marginTop: 'auto',
      position: 'absolute',
      bottom: 0,
      right: 0,
      left: 0,
      backgroundColor: 'white',
    },
    profile: {
      margin: theme.spacing(3),
    },
    globalSettingsLink: {
      margin: theme.spacing(1),
      display: 'flex',
      alignItems: 'center',
    },
    backLink: {
      display: 'flex',
      margin: theme.spacing(2),
      cursor: 'pointer',
      '& > span': {
        marginTop: 'auto',
        marginBottom: 'auto',
        marginLeft: theme.spacing(1),
      },
    },
    cultureCloudLink: {
      display: 'block',
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(3),
      color: Colors.blue,
      '&:hover': {
        color: Colors.blue,
      },
      textDecoration: 'none',
    },
    cultureCloudText: {
      color: Colors.black,
    },
    signOut: {
      color: Colors.black,
      fontSize: theme.typography.overline.fontSize,
    },
    logo: {
      paddingTop: theme.spacing(0.75),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(1),
    },
  })
);
