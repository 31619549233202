import {
  BlockToolbarButton,
  getPluginType,
  MarkToolbarButton,
  ListToolbarButton,
  LinkToolbarButton,
  deleteColumn,
  deleteRow,
  deleteTable,
  insertTable,
  insertTableColumn,
  insertTableRow,
  TableToolbarButton,
} from '@udecode/plate';
import { BorderAll } from '@styled-icons/material/BorderAll';
import { BorderBottom } from '@styled-icons/material/BorderBottom';
import { BorderClear } from '@styled-icons/material/BorderClear';
import { BorderLeft } from '@styled-icons/material/BorderLeft';
import { BorderRight } from '@styled-icons/material/BorderRight';
import { BorderTop } from '@styled-icons/material/BorderTop';
import { useMyPlateEditorRef } from './plateTypes';

import { ToolbarButton } from './constants';
import { Link } from '@styled-icons/material';
export const BasicElementToolbarButtons = ({
  disabled,
}: {
  disabled: string[];
}) => {
  const editor = useMyPlateEditorRef()!;
  return (
    <>
      {ToolbarButton.map(({ element, icon, type }) => {
        if (disabled.indexOf(element) !== -1) return null;
        if (type === 'MARK') {
          return (
            <MarkToolbarButton
              key={element}
              type={getPluginType(editor, element)}
              icon={icon}
            />
          );
        } else if (type === 'BLOCK') {
          return (
            <BlockToolbarButton
              key={element}
              type={getPluginType(editor, element)}
              icon={icon}
            />
          );
        } else if (type === 'LIST') {
          return (
            <ListToolbarButton
              key={element}
              type={getPluginType(editor, element)}
              icon={icon}
            />
          );
        } else if (type === 'LINK') {
          // ? Note here, adding a type completely removes the button. Not sure why, docs dont seem to have a valid reason why either.
          return <LinkToolbarButton icon={<Link />} />;
        }
        return null;
      })}
      {disabled.indexOf('table') === -1 && (
        <>
          <TableToolbarButton icon={<BorderAll />} transform={insertTable} />
          <TableToolbarButton icon={<BorderClear />} transform={deleteTable} />
          <TableToolbarButton
            icon={<BorderBottom />}
            transform={insertTableRow}
          />
          <TableToolbarButton icon={<BorderTop />} transform={deleteRow} />
          <TableToolbarButton
            icon={<BorderLeft />}
            transform={insertTableColumn}
          />
          <TableToolbarButton icon={<BorderRight />} transform={deleteColumn} />
        </>
      )}
    </>
  );
};
