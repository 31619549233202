import React from 'react';
import styled from '@emotion/styled';
import { FlexFieldQuestion as FieldType } from '../types';
import {
  IconButton,
  Typography,
  Menu,
  MenuItem,
  Tag,
} from '@octanner/prism-core';
import FieldEditor from '../Fields/FieldEditor';
import { useSetError } from '../utils/SetErrorProvider';
import { MenuDots, ArrowDown, ArrowUp } from '@octanner/prism-icons';
import { useFieldList } from './useFieldList';
import { visibilitySettings, requiredSettings } from './settings';

const Container = styled.li`
  align-items: center;
  display: flex;
  padding: 16px;
`;

const Name = styled(Typography)`
  color: #000000;
`;

const Center = styled.div`
  flex: 1 0 auto;
`;

const Description = styled.div`
  color: #707070;
  font-size: 14px;
  font-weight: 400;
`;

const TagContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const VisibilityTag = styled(Tag)`
  margin-left: 8px;
  margin-right: 8px;
`;

const ShiftUpButton = styled(IconButton)`
  && {
    margin-right: 8px;
  }
`;
const ShiftDownButton = IconButton;

const Field: React.FC<Props> = ({
  field,
  setDisableNewField,
  onDelete,
  updateField,
  visibility,
  required,
  shiftUp,
  shiftDown,
}) => {
  const [showFieldEditor, setShowFieldEditor] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { saveFlexFieldQuestion } = useFieldList();
  const [selectedValue, setSelectedValue] = React.useState(visibility);
  const [menuOpen, setMenuOpen] = React.useState(null);
  const [requiredSetting, setRequiredSetting] = React.useState(required);
  const [requiredMenuOpen, setRequiredMenuOpen] = React.useState(null);
  const setError = useSetError();

  const visibilityOnChange = (e) => {
    setMenuOpen(null);
    const visibilityKey = e.target.textContent.split(' ')[0].toUpperCase();
    updateField(field.id, {
      ...field,
      flexfieldAnswerVisibility: visibilityKey,
    });
    setSelectedValue(visibilityKey);
  };

  const requiredOnChange = (e) => {
    const required = e.target.textContent === requiredSettings.REQUIRED;
    setRequiredMenuOpen(null);
    updateField(field.id, { ...field, required });
    setRequiredSetting(required);
  };

  const cancelEditing = () => {
    setShowFieldEditor(false);
    setDisableNewField(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const edit = () => {
    setShowFieldEditor(true);
    setDisableNewField(true);
    handleClose();
  };

  const saveField = (input: FieldType) => {
    saveFlexFieldQuestion({
      ...input,
    }).catch((err) => {
      setError(err.toString());
    });
  };

  const deleteField = () => {
    onDelete(field.id);
    handleClose();
  };

  return (
    <div>
      <Container>
        <ShiftDownButton
          onClick={shiftDown}
          aria-label="shift down"
          disabled={!shiftDown}
        >
          <ArrowDown />
        </ShiftDownButton>
        <ShiftUpButton
          onClick={shiftUp}
          aria-label="shift up"
          disabled={!shiftUp}
        >
          <ArrowUp />
        </ShiftUpButton>
        <Center>
          <Name data-testid="field">{field.fieldName}</Name>
          <Description>
            {field.helperText ? 'Has Helper text' : 'No Helper text'},{' '}
            {field.characterLimit} character limit
          </Description>
        </Center>
        <TagContainer>
          <>
            <Tag
              hasMenu
              open={Boolean(requiredMenuOpen)}
              onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                setRequiredMenuOpen((requiredMenuOpen) =>
                  requiredMenuOpen ? null : e.target,
                );
              }}
            >
              {requiredSetting === true
                ? requiredSettings.REQUIRED
                : requiredSettings.OPTIONAL}
            </Tag>
            <Menu
              anchorEl={requiredMenuOpen}
              open={Boolean(requiredMenuOpen)}
              onClose={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                setRequiredMenuOpen((requiredMenuOpen) =>
                  requiredMenuOpen ? null : e.target,
                );
              }}
              transformOrigin={{ horizontal: 'left', vertical: 'bottom' }}
            >
              <MenuItem value="true" onClick={requiredOnChange}>
                {requiredSettings.OPTIONAL}
              </MenuItem>
              <MenuItem value="false" onClick={requiredOnChange}>
                {requiredSettings.REQUIRED}
              </MenuItem>
            </Menu>
          </>
          <>
            <VisibilityTag
              hasMenu
              open={Boolean(menuOpen)}
              onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                setMenuOpen((menuOpen) => (menuOpen ? null : e.target));
              }}
            >
              {visibilitySettings[selectedValue]}
            </VisibilityTag>
            <Menu
              anchorEl={menuOpen}
              open={Boolean(menuOpen)}
              onClose={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                setMenuOpen((menuOpen) => (menuOpen ? null : e.target));
              }}
              transformOrigin={{ horizontal: 'left', vertical: 'bottom' }}
            >
              <MenuItem value="Approver" onClick={visibilityOnChange}>
                {visibilitySettings.APPROVER}
              </MenuItem>
              <MenuItem value="Recipient Only" onClick={visibilityOnChange}>
                {visibilitySettings.RECIPIENT}
              </MenuItem>
              <MenuItem value="Everyone" onClick={visibilityOnChange}>
                {visibilitySettings.EVERYONE}
              </MenuItem>
            </Menu>
            <IconButton onClick={handleClick} aria-label="field menu">
              <MenuDots />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={edit}>Edit</MenuItem>
              <MenuItem onClick={deleteField}>Delete</MenuItem>
            </Menu>
          </>
        </TagContainer>
      </Container>
      {showFieldEditor && (
        <FieldEditor field={field} onClose={cancelEditing} onSave={saveField} />
      )}
    </div>
  );
};

interface Props {
  field: FieldType;
  updateField: (fieldId, newValue) => void;
  onDelete: (fieldId: string) => void;
  setDisableNewField: (fieldToEdit: boolean) => void;
  visibility: string;
  required: boolean;
  shiftUp: () => void | undefined;
  shiftDown: () => void | undefined;
}

export default Field;
