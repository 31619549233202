import { List, ListItem, ListItemText } from '@octanner/prism-core';
import { formatTermsVersion } from './models';
import { useStyles } from './styles';
import { VersionListProps } from './types';

export default function VersionList({
  versions,
  onClick,
  selectedVersionId,
}: VersionListProps) {
  const classes = useStyles();

  return (
    <List className={classes.VersionRoot}>
      {versions.map((item) => (
        <ListItem
          key={item.id}
          button
          onClick={() => onClick(item)}
          selected={selectedVersionId === item.id}
          disableRipple
        >
          <ListItemText
            primary={formatTermsVersion(item)}
            secondary={`Effective: ${item.effectiveOn.toLocaleString()}`}
          />
        </ListItem>
      ))}
    </List>
  );
}
