import { useMutation } from '@apollo/client';
import { UPDATE_ADMIN_ROLE } from '../graphql/gql';
import { UpdateRoleInput, UpdateRoleResponse } from '../graphql/types';
import { AppContext } from '../../../../../utils/context/AppContext';
import { useContext } from 'react';

const mockData = () => [
  (options?) => {
    return new Promise((resolve) => {
      resolve({
        data: {
          rdUpdateRole: {
            role: {
              ...options.variables.input,
            },
          },
          rdUpdateAdminRoleWithPermissions: {
            role: { ...options.variables.input },
          },
        },
      });
    });
  },
];

export const useUpdateRole = () => {
  const { config } = useContext(AppContext);
  const mutation = useMutation<UpdateRoleResponse, UpdateRoleInput>(
    UPDATE_ADMIN_ROLE,
    {
      context: {
        clientName: 'roleDelegator',
      },
    }
  );
  return config.useRoleDelegatorMockData ? mockData() : mutation;
};
