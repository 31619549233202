import { Typography } from '@octanner/prism-core';
import { ContainerB, FlexGroupB, SpacedGroups } from './styles';
import { PermissionCategory, PermissionSubCategory } from '../types';
import { SubCategoriesView, NoSelectionContainer } from './SubCategory';
import { useEffect, useState } from 'react';
import { Permission } from '../../types';
import { tannerGray } from '@octanner/prism-core/ThemeProvider/colors';
import { filterSubCategories } from '../utils';

type SelectionContainerProps = {
  activeContainer?: PermissionCategory;
  selectedPermissions: Permission[];
  onPermissionSelect: (permission: Permission) => void;
  onPermissionDeselect: (permission: Permission) => void;
  search?: string;
};

const SelectionContainer = ({
  activeContainer,
  selectedPermissions,
  onPermissionSelect,
  onPermissionDeselect,
  search,
}: SelectionContainerProps) => {
  const [filteredSubCategories, setFilteredSubCategories] = useState<
    PermissionSubCategory[]
  >(activeContainer?.subCategories || []);
  useEffect(() => {
    if (!search || search.length === 0) {
      setFilteredSubCategories(activeContainer?.subCategories || []);
    } else if (activeContainer) {
      const lowerCaseSearch = search?.toLowerCase();
      setFilteredSubCategories(
        filterSubCategories(activeContainer, lowerCaseSearch)
      );
    }
  }, [search, activeContainer]);

  const [selectedCount, setSelectedCount] = useState(0);
  useEffect(() => {
    setSelectedCount(
      activeContainer?.subCategories.flatMap((sub) =>
        sub.permissionGroups.filter(
          (group) =>
            (group.readPermission &&
              selectedPermissions.includes(group.readPermission)) ||
            (group.writePermission &&
              selectedPermissions.includes(group.writePermission))
        )
      ).length || 0
    );
  }, [selectedPermissions, activeContainer]);

  return (
    <ContainerB>
      {search && search.length > 0 && filteredSubCategories.length < 1 && (
        <NoSelectionContainer
          header="No results found"
          text={`Your search for "${search}” did not have any results.`}
        />
      )}
      {(!search || search.length === 0) && !activeContainer && (
        <NoSelectionContainer
          header="No active permissions"
          text="When you select a category, the permissions will show up here."
        />
      )}
      {activeContainer && (activeContainer.permissionGroupTotal || 0) > 0 && (
        <div>
          <FlexGroupB>
            <SpacedGroups noPad>
              <Typography variant="h3">{activeContainer.name}</Typography>
              <Typography variant="body2" color={tannerGray[600]}>
                {selectedCount}/{activeContainer.permissionGroupTotal} active
              </Typography>
              <Typography>{activeContainer.description}</Typography>
            </SpacedGroups>
          </FlexGroupB>
          <SubCategoriesView
            subCategories={filteredSubCategories}
            selectedPermissions={selectedPermissions}
            onPermissionSelect={onPermissionSelect}
            onPermissionDeselect={onPermissionDeselect}
          />
        </div>
      )}
    </ContainerB>
  );
};

export default SelectionContainer;
