import styled from '@emotion/styled';
import { SelectChangeEvent } from '@mui/material';
import { Button, CircularProgress, Grid, MenuItem, Pagination, Select, TextField, Typography } from '@octanner/prism-core';
import React, { useContext, useState } from 'react';
import { CustomTextContext } from '../../Contexts/CustomTextContext';
import { durationTypes, emailregex, numRegex } from '../../utils/constants';
import { defaults } from '../../utils/default';
import SearchCustomTextTable from './SearchCustomtextTable';

const SearchContainer = styled.div`
  margin-top: 24px;
  margin-bottom: 30px;
`

const ButtonGrid = styled(Grid)`
  display: flex;
  align-items: center;
  border-radius: 4px;
`

const PaginationContainer = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 4px;
  align-self: stretch;
`

const MessageContainer = styled(Grid)`
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 3px;
  background: #FBFBFB;
`

const PlaceHolder = styled(Typography)`
  padding: 24px;
`

const StyledCircularProgress = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  height: 60vh;
`

const SearchCustomText: React.FC = () => {
  const {
    searchData, searchInDuration,
    SetSearchInDuration, searchCustomTextHandler, dataNotFound, searchLoading, setMessageText,
    searchRequestObject, setSearchRequestObject
  } = useContext(CustomTextContext);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);

  const handleSearch = (pageNumber: number, itemsPerPage: number) => {
    setPageNumber(pageNumber);
    setItemsPerPage(itemsPerPage)
    setMessageText('deleteSuccessMessage', '');
    searchCustomTextHandler(pageNumber, itemsPerPage);
  };

  const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value.trim();
    let isValidInput = true;
    if (searchRequestObject.type === 'stringId' || searchRequestObject.type === 'stp') {
      isValidInput = inputValue === '' || numRegex.test(inputValue);
    }
    setSearchRequestObject({
      ...searchRequestObject,
      value: isValidInput ? inputValue : ''
    });
  };

  const getInputLabel = (searchBy: string) => {
    switch (searchBy) {
      case 'stringId':
        return defaults.enter_string_id;
      case 'stp':
        return defaults.enter_sold_to_party;
      case 'requestedBy':
        return defaults.enter_requested_by_name;
      case 'requesterEmail':
        return defaults.enter_requested_by_email;
      default:
        return '';
    }
  };

  return (
    <>
      <SearchContainer>
        <Grid container>
          <Grid item xs={4}>
            <TextField
              value={searchRequestObject.value}
              onChange={handleSearchInputChange}
              label={getInputLabel(searchRequestObject.type)}
              fullWidth
              onKeyDown={(e) => e.key === 'Enter' && searchRequestObject.value && (searchRequestObject.type !== 'requesterEmail'
                || emailregex.test(searchRequestObject.value)) && handleSearch(1, itemsPerPage)}
            />
          </Grid>
          <ButtonGrid item xs={5} >
            <Button onClick={() => handleSearch(1, itemsPerPage)} disabled={searchLoading || !searchRequestObject.value ||
              (searchRequestObject.type === 'requesterEmail' && !emailregex.test(searchRequestObject.value))} adminColor>
              {defaults.search}
            </Button>
          </ButtonGrid>
          <Grid item xs={3}>
            <Select
              label={defaults.custom_text_within}
              fullWidth
              onChange={(event: SelectChangeEvent<unknown>) => SetSearchInDuration(event.target.value as string)}
              value={searchInDuration}>
              {durationTypes.map((duration: { label: string, value: string }) => (
                <MenuItem key={duration.label} value={duration.value}>
                  {duration.label}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
      </SearchContainer>
      {!searchLoading && searchData?.lsFetchCustomTextListUsingSearchFilters?.customTextList?.length > 0 && (
        <>
          <SearchCustomTextTable />
          {searchData.lsFetchCustomTextListUsingSearchFilters.pageInfo.totalItemCount > 10 && <PaginationContainer>
            <Pagination
              size='small'
              rowsPerPage
              menuProps={{ value: 'rows per page' }}
              page={pageNumber}
              itemsPerPageOptions={[10, 30, 50]}
              itemsPerPage={itemsPerPage}
              count={searchData.lsFetchCustomTextListUsingSearchFilters.pageInfo.totalPageCount}
              onChange={(event, page) => handleSearch(page, itemsPerPage)}
              onItemsPerPageChange={(itemsPerPage) => handleSearch(1, itemsPerPage)}
            />
          </PaginationContainer>}
        </>)}
      {!searchLoading && !searchData?.lsFetchCustomTextListUsingSearchFilters?.customTextList?.length && <MessageContainer>
        <PlaceHolder color='textSecondary' variant='body1'>
          {
            dataNotFound ? defaults.no_match_data : defaults.enter_value_to_search
          }
        </PlaceHolder>
      </MessageContainer>}
      {searchLoading && <StyledCircularProgress>
        <CircularProgress />
      </StyledCircularProgress>}
    </>
  );
}

export default SearchCustomText;