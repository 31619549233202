import { styled } from '@mui/material/styles'
import { MenuItem, Select, TextField } from '@octanner/prism-core'
import React, { useMemo } from 'react'
import {
  useGroupSettings,
  useGroupSettingsDispatch,
} from '../../../utilities/GroupSettingsContext'

const Root = styled('section')`
    display: grid;
    grid-gap: ${({ theme }) => theme.spacing(5)};
    grid-template-columns: repeat(2, 410px 180px [col-start]);
  `,
  NameInput = styled(TextField)`
    height: 100%;
    > div {
      height: 100%;
    }
  `,
  TypeSelect = styled(Select)`
    height: 100%;
  `

export default function NameAndType() {
  const groupSettingsContext = useGroupSettings()
  const dispatch = useGroupSettingsDispatch()

  const selectValue = useMemo(() => {
    return groupSettingsContext?.milestone ? '0' : '1'
  }, [groupSettingsContext?.milestone])

  return (
    <Root>
      <NameInput
        id="celebration-group-name"
        label="Name"
        error={Boolean(groupSettingsContext?.groupNameErrors?.length > 0)}
        helperText={groupSettingsContext?.groupNameErrors?.join(', ')}
        value={groupSettingsContext?.name}
        onChange={(e) =>
          dispatch({ type: 'setGroupName', name: e.target.value })
        }
        autoFocus
        inputProps={{ 'data-testid': 'celebration:edit:name' }}
      />
      <TypeSelect
        label="Type"
        value={selectValue}
        onChange={() => dispatch({ type: 'toggleMilestone' })}
        inputProps={{ 'data-testid': 'celebration:edit:type' }}
      >
        <MenuItem value="0">Milestone</MenuItem>
        <MenuItem value="1">Non-Milestone</MenuItem>
      </TypeSelect>
    </Root>
  )
}
