import { CustomerSearchInput, CustomerSearchResponse } from '../graphql/types';
import { SEARCH_CUSTOMERS } from '../graphql/gql';
import { useQuery } from '@apollo/client';
import { AppContext } from '../../../../../utils/context/AppContext';
import { useContext } from 'react';
import { TEST_CLIENTS_PAGED_DATA } from '../constants';

function compareClients(search: CustomerSearchInput) {
  return (a, b) => {
    const { sortA, sortB } =
      search.sortDirection === 'DESC'
        ? { sortA: b, sortB: a }
        : { sortA: a, sortB: b };
    if (search.sortBy === 'ADMIN_COUNT') {
      return sortA.adminCount - sortB.adminCount;
    } else if (search.sortBy === 'ROLE_COUNT') {
      return sortA.activeRoleCount - sortB.activeRoleCount;
    } else if (search.sortBy === 'STATUS') {
      return sortA.customerStatus.localeCompare(sortB.customerStatus);
    } else {
      return sortA.name.localeCompare(sortB.name);
    }
  };
}

const mockData = (input: CustomerSearchInput) => ({
  data: {
    content: TEST_CLIENTS_PAGED_DATA.content
      .filter(
        (client) =>
          input.searchTerms.length === 0 ||
          input.searchTerms.every((search) => search.length < 3) ||
          input.searchTerms.some(
            (search) =>
              client.name.toLowerCase().includes(search.toLowerCase()) ||
              client.stpNumber.toLowerCase().includes(search.toLowerCase()) ||
              client.customerUuid.toLowerCase().includes(search.toLowerCase())
          )
      )
      .sort(compareClients(input)),
    total: 234,
    totalPages: 24,
  },
  loading: false,
  refetch: () => {},
});

export const useSearchCustomers = ({
  input,
  skip = false,
}: {
  input: CustomerSearchInput;
  skip?: boolean;
}) => {
  const { config } = useContext(AppContext);
  const query = useQuery<CustomerSearchResponse, CustomerSearchInput>(
    SEARCH_CUSTOMERS,
    {
      variables: input,
      context: {
        clientName: 'roleDelegator',
      },
      skip: config.useRoleDelegatorMockData || skip,
    }
  );
  return config.useRoleDelegatorMockData
    ? mockData(input)
    : {
        ...query,
        data: query.data?.rdPagedClients || {
          content: [],
          total: 0,
          totalPages: 0,
        },
      };
};
