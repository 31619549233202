import { useMutation } from "@apollo/client";
import styled from "@emotion/styled";
import { BadgeSecondary, Box, Button, Checkbox, CircularProgress, Grid, Pagination, Tab, Table, TableBody, TableCell, TableHead, TableRow, Tabs, TextField, Tooltip, Typography } from "@octanner/prism-core";
import { InformationCircle } from "@octanner/prism-icons";
import React, { useContext, useEffect, useRef, useState } from 'react';
import { LocaleContext } from "../../Contexts/LocaleContext";
import { TranslationRequestContext } from "../../Contexts/TranslationRequestContext";
import { numRegex, translationCategories } from "../../utils/constants";
import { defaults } from "../../utils/default";
import { CREATE_JOB, TOGGLE_HOLD_TRANSLATION } from "../../utils/query";
import { UnTranslatedString } from "../../utils/types";
import DeleteTranslationRequestModal from "./DeleteTranslationRequestModal";
import TranslationRequestAlert from "./TranslationRequestAlert";
import TranslationRequestFooter from "./TranslationRequestFooter";
import TranslationRequestTableRow from "./TranslationRequestTabelRow";

const TranslationTableGrid = styled(Grid)`
  position: relative;
  height: inherit;
  overflow: auto;
  padding-left: 0px !important;
  padding-top: 0px !important;
`

const TranslationTableContainer = styled.div`
  padding: 24px;
`

const TabsContainer = styled(Box)`
  margin-top: 12px;
  border-bottom: 1px solid #e0e0e0;
`

const ButtonGrid = styled(Grid)`
  display: flex;
  align-items: center;
`

const SearchGrid = styled(Grid)`
  margin-top: 12px;
`

const CategoryTab = styled(Tab)`
  min-width: fit-content !important;
`

const CategoryTabLabel = styled.div`
  display: flex;
  align-items: center;
`

const TableContainer = styled.div`
  margin-top: 12px;
`

const CheckboxCellDiv = styled.div`
  display: flex;
  align-items: center;
`

const PaginationContainer = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 4px;
  align-self: stretch;
`

const StyledCircularProgress = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: 45vh;
`;

const NoPendingRequestBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: 25vh;
`

const TableDivLoaderDiv = styled.div`
  min-height: 76vh;
`

const TextFieldCell = styled(TableCell) <{ customTextIndicator: boolean }>`
  width: ${props => (props.customTextIndicator ? '35%' : '40%')};
`;


const TranslationsRequestTable = () => {

  const { localeMap } = useContext(LocaleContext);
  const { selectedLocale, translationCategory, setTranslationCategory, messages,
    unTranslatedStringList, setCreatedJobNumber, unTranslatedStringData, selectAll, setSelectAll,
    searchLoading, fetchUntranslatedStringList, closeMessage, pageNumber, setSearchId,
    setPageNumber, setUnTranslatedStringList, setUnTranslatedStringData, searchId,
    setMessageText, unTranslatedLocaleDataLoading } = useContext(TranslationRequestContext);

  const [createJob, { loading: createJobLoading }] = useMutation(CREATE_JOB, {
    onError: () => {
      setMessageText('errorMessage', defaults.create_job_error_message);
    }
  });

  const [toggleHoldRequest, { loading: holdRequestLoading }] = useMutation(TOGGLE_HOLD_TRANSLATION, {
    onError: () => {
      setMessageText('errorMessage', defaults.update_error);
    }
  });

  const [showDeleteTranslationRequestModal, setShowDeleteTranslationRequestModal] = useState<boolean>(false);
  const [stringId, setStringId] = useState<string>('');
  const [mmId, setMmid] = useState<string>('');
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const translationCategoryRef = useRef(translationCategory);
  const selectedLocaleRef = useRef(selectedLocale);
  const pageNumberRef = useRef(pageNumber);
  const [requestSearched, setRequestSearched] = useState<boolean>(false);

  useEffect(() => {
    fetchUnTranslatedStringListByCategoryAndLocale(1, 10);
  }, []);

  useEffect(() => {
    if (searchId === '' && requestSearched) {
      setRequestSearched(false);
      fetchUnTranslatedStringListByCategoryAndLocale(1, 10);
    }
  }, [searchId]);

  const searchRequest = () => {
    if (searchId.trim().length === 0 || searchLoading || createJobLoading || holdRequestLoading ||
      selectedLocale[translationCategories[translationCategory].countVariableName] === 0) {
      return;
    }
    setRequestSearched(true);
    fetchUnTranslatedStringListByCategoryAndLocale(1, 10)
  }

  useEffect(() => {
    if (translationCategory !== translationCategoryRef.current ||
      selectedLocale.locale !== selectedLocaleRef.current.locale) {
      translationCategoryRef.current = translationCategory;
      selectedLocaleRef.current = selectedLocale;
      setSearchId('');
      setStringId('');
      setRequestSearched(false);
      setMmid('');
      closeMessage('successMessage');
      closeMessage('errorMessage');
      setCreatedJobNumber(0);
      closeMessage('releaseRequestInfo');
      closeMessage('selectOnHoldInfo');
      if (!searchLoading && (selectedLocale[translationCategories[translationCategory].countVariableName] === null ||
        selectedLocale[translationCategories[translationCategory].countVariableName] > 0)) {
        fetchUnTranslatedStringListByCategoryAndLocale(1, 10);
      } else {
        setUnTranslatedStringList([]);
        setUnTranslatedStringData(null);
      }
    }
  }, [translationCategory, selectedLocale]);

  const searchBoxChange = (id: string) => {
    if (id === '' || numRegex.test(id)) {
      if (translationCategory === translationCategories.MATERIAL_DESCRIPTION.value) {
        setMmid(id);
        setSearchId('');
      } else {
        setMmid('');
        setStringId(id);
      }
      setSearchId(id);
    }
  }

  useEffect(() => {
    if (pageNumber !== pageNumberRef.current) {
      fetchUnTranslatedStringListByCategoryAndLocale(pageNumber, itemsPerPage);
    }
  }, [pageNumber])

  const fetchUnTranslatedStringListByCategoryAndLocale = (pageNumber: number, pageSize: number) => {
    if (searchLoading) {
      return;
    }
    setSelectAll(false);
    pageNumberRef.current = pageNumber;
    setPageNumber(pageNumber);
    setItemsPerPage(pageSize);
    if (selectedLocale.locale === 'en-US' && translationCategory !== translationCategories.CUSTOM_TEXT.value) {
      setTranslationCategory(translationCategories.CUSTOM_TEXT.value);
    }
    fetchUntranslatedStringList({
      variables: {
        mmId: parseInt(mmId),
        stringId: parseInt(stringId),
        translationCategory: (selectedLocale.locale === 'en-US' && translationCategory !== translationCategories.CUSTOM_TEXT.value
          ? translationCategories.CUSTOM_TEXT.value : translationCategory),
        locale: selectedLocale.locale,
        pageInfo: {
          pageNumber,
          pageSize
        }
      }
    });
  }

  const handleAllCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (messages[0]?.text?.length > 0) {
      closeMessage('infoMessage');
    }
    const isChecked = e.target.checked;
    setSelectAll(isChecked);
    if (isChecked && unTranslatedStringList.some((item) => item.onHoldInd)) {
      setMessageText('releaseRequestInfo', defaults.select_all_on_hold_message);
    } else {
      closeMessage('releaseRequestInfo');
    }
    setUnTranslatedStringList(prevList => {
      return prevList.map((item: UnTranslatedString) => {
        return { ...item, selected: isChecked && !item.onHoldInd };
      });
    });
  }

  const processSetTranslationCategory = (category: string) => {
    setStringId('');
    setMmid('');
    setTranslationCategory(category);
  }

  return (
    <>
      {showDeleteTranslationRequestModal && <DeleteTranslationRequestModal
        showDeleteTranslationRequestModal={showDeleteTranslationRequestModal}
        setShowDeleteTranslationRequestModal={setShowDeleteTranslationRequestModal} />}
      <TranslationTableGrid item xs={9}>
        <TableDivLoaderDiv>
          <TranslationTableContainer>
            <Typography variant='h3'>{localeMap[selectedLocale.locale]}</Typography>
            <Typography color='textSecondary' variant='body2'>
              {selectedLocale.languageStringCount + selectedLocale.materialDescriptionCount + selectedLocale.customTextCount}&nbsp;{defaults.requested_strings}</Typography>
            <Grid container spacing={3}>
              <Grid item xs={9}>
                <TabsContainer>
                  <Tabs textColor='inherit' indicatorColor='primary' variant='fullWidth'
                    value={translationCategory} onChange={(event, newValue) => processSetTranslationCategory(newValue)}>
                    {Object.values(translationCategories).map(category => (
                      <CategoryTab disabled={searchLoading || createJobLoading || holdRequestLoading ||
                        (selectedLocale.locale === 'en-US' && category.value !== translationCategories.CUSTOM_TEXT.value)}
                        key={category.value}
                        label={
                          <CategoryTabLabel>
                            <Typography>{category.label}</Typography>&nbsp;
                            {unTranslatedLocaleDataLoading ? <CircularProgress size='14px' /> : <BadgeSecondary variant="archived">{selectedLocale[category.countVariableName]}</BadgeSecondary>
                            }
                          </CategoryTabLabel>}
                        value={category.value}
                      />
                    ))}
                  </Tabs>
                </TabsContainer>
              </Grid>
            </Grid>
            <TranslationRequestAlert />
            <SearchGrid container spacing={3}>
              <Grid item xs={5}>
                <TextField
                  value={searchId}
                  disabled={searchLoading || createJobLoading || holdRequestLoading || selectedLocale[translationCategories[translationCategory].countVariableName] === 0}
                  onChange={(e) => searchBoxChange(e.target.value)}
                  label={translationCategory === translationCategories.MATERIAL_DESCRIPTION.value ? defaults.enter_material_master_id : defaults.enter_string_id}
                  fullWidth
                  onKeyDown={(e) => e.key === 'Enter' && searchRequest()}
                />
              </Grid>
              <ButtonGrid item xs={4}>
                <Button disabled={searchId.trim().length === 0 || searchLoading || createJobLoading || holdRequestLoading ||
                  selectedLocale[translationCategories[translationCategory].countVariableName] === 0} adminColor
                  onClick={() => searchRequest()}>
                  {defaults.search}
                </Button>
              </ButtonGrid>
            </SearchGrid>
            <TableContainer>
              {!searchLoading && unTranslatedStringList && <>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <CheckboxCellDiv>
                          <Checkbox disabled={createJobLoading || holdRequestLoading}
                            checked={selectAll} onChange={(e) => handleAllCheckbox(e)} />
                          &nbsp;<Tooltip title={defaults.select_request_info} placement="top">
                            <InformationCircle />
                          </Tooltip>
                        </CheckboxCellDiv>
                      </TableCell>
                      {translationCategory === translationCategories.MATERIAL_DESCRIPTION.value && <TableCell>
                        <Typography variant='h4'>
                          {defaults.sap_part_number}
                        </Typography>
                      </TableCell>}
                      <TableCell>
                        <Typography variant='h4'>
                          {defaults.requested_by}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant='h4'>{defaults.date}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant='h4'>
                          {translationCategory === translationCategories.MATERIAL_DESCRIPTION.value ? defaults.type : defaults.id}
                        </Typography>
                      </TableCell>
                      <TextFieldCell customTextIndicator={translationCategory === translationCategories.CUSTOM_TEXT.value}>
                        <Typography variant='h4'>{defaults.sourceText}</Typography>
                      </TextFieldCell>
                      {translationCategory === translationCategories.CUSTOM_TEXT.value && <TableCell>
                        <Typography variant='h4'>{defaults.locale}</Typography>
                      </TableCell>}
                    </TableRow>
                  </TableHead>
                  {unTranslatedStringList.length > 0 && <TableBody>
                    {unTranslatedStringList.map((data) => (
                      <TranslationRequestTableRow
                        createJobLoading={createJobLoading}
                        holdRequestLoading={holdRequestLoading}
                        key={data.requestStatus?.uniqueId}
                        data={data} />
                    ))}
                  </TableBody>}
                </Table>
                {unTranslatedStringData?.lsFetchUntranslatedStringListForLocaleAndCategory?.pageInfo.totalItemCount > 10 && !createJobLoading
                  && !holdRequestLoading && <PaginationContainer>
                    <Pagination
                      size='small'
                      rowsPerPage
                      menuProps={{ value: defaults.rows_per_page }}
                      page={pageNumber}
                      itemsPerPageOptions={[10, 30, 50]}
                      itemsPerPage={itemsPerPage}
                      count={unTranslatedStringData?.lsFetchUntranslatedStringListForLocaleAndCategory?.pageInfo.totalPageCount}
                      onChange={(event, page) => fetchUnTranslatedStringListByCategoryAndLocale(page, itemsPerPage)}
                      onItemsPerPageChange={(itemsPerPage) => fetchUnTranslatedStringListByCategoryAndLocale(1, itemsPerPage)}
                    />
                  </PaginationContainer>}
                {!searchLoading && unTranslatedStringList.length === 0 && <NoPendingRequestBox>
                  <Typography color="textSecondary">{defaults.no_pending_requests}</Typography>
                </NoPendingRequestBox>}
              </>}
            </TableContainer>
          </TranslationTableContainer>
          {searchLoading && <StyledCircularProgress>
            <CircularProgress />
          </StyledCircularProgress>}
        </TableDivLoaderDiv>
        {!searchLoading && <TranslationRequestFooter
          createJob={createJob}
          createJobLoading={createJobLoading}
          toggleHoldRequest={toggleHoldRequest}
          setShowDeleteTranslationRequestModal={setShowDeleteTranslationRequestModal}></TranslationRequestFooter>}
      </TranslationTableGrid>
    </>);
}

export default TranslationsRequestTable;