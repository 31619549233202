import { useStyles } from './styles';
import { Link, TextField, Typography, Alert } from '@octanner/prism-core';
import React, { useContext, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { CustomerContext } from '../../utils/context/CustomerContext';
import Loading from '../../common/Loading';
import { RoleNameFormProps } from './types';

const RoleNameFormComponent = ({
  name,
  onSave,
  isNew,
  title,
  disabled,
  error,
}: RoleNameFormProps) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [roleName, setRoleName] = useState(() => name || '');
  const {
    customer: { id: customerId },
  } = useContext(CustomerContext);
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const trimmedRoleName = roleName.trim();
    if (!trimmedRoleName) return;
    setLoading(true);
    await onSave(trimmedRoleName);
    setLoading(false);
  };
  return (
    <form onSubmit={handleSubmit}>
      <div className={classes.back}>
        <Link
          component={RouterLink}
          to={`/customer/${customerId}/roles-and-permissions`}
          data-test="role:back"
        >
          {'< Back to Roles and Permissions'}
        </Link>
      </div>
      {error && (
        <Alert severity="error" className={classes.errorAlert}>
          <Typography data-test="role:error">{error}</Typography>
        </Alert>
      )}
      <Typography variant="h4" className={classes.roleName}>
        {title}
      </Typography>
      <Typography variant="h3" className={classes.roleNameLabel}>
        Role name
      </Typography>
      <TextField
        id="role-name"
        fullWidth
        placeholder="Role name"
        value={roleName}
        onChange={(e) => setRoleName(e.target.value)}
        autoFocus={isNew}
        disabled={disabled}
        inputProps={{ 'data-test': 'role:name' }}
        InputProps={{
          endAdornment: loading ? (
            <Loading height={24} size={24} />
          ) : (
            <Link
              component="button"
              type="submit"
              className={classes.save}
              data-test="role:save"
            >
              Save
            </Link>
          ),
        }}
      />
    </form>
  );
};
export default RoleNameFormComponent;
