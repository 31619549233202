import styled from '@emotion/styled';
import { Tab, Typography } from '@octanner/prism-core';

export const BlackTab = styled(Tab)`
  color: #000 !important;
`;

export const SystemSettingHeader = styled(Typography)`
  margin-bottom: 32px;
`;
