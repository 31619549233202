import { MenuItem } from '@mui/material';
import {
  TableBody,
  TableCell,
  Button,
  Switch,
  Select,
  Typography,
} from '@octanner/prism-core';
import { AddLocaleProps } from './types';
import { useStyles } from './styles';

const AddLocale = ({
  locales,
  newLocale,
  setNewLocale,
  addLocale,
  setAddLocale,
  handleSave,
}: AddLocaleProps) => {
  const styles = useStyles();
  const handleNewLocaleValueChange = (key: string, value: any) => {
    setNewLocale((currentLocale) => ({ ...currentLocale, [key]: value }));
  };

  const clearLocaleValues = () => {
    setAddLocale(false);
    setNewLocale({
      localeCode: '',
      isDefault: false,
    });
  };

  return (
    <TableBody>
      <TableCell align="center">
        {addLocale && (
          <Select
            data-testid="locale:select:code"
            onChange={(e) =>
              handleNewLocaleValueChange('localeCode', e.target.value)
            }
          >
            {locales &&
              locales.map(({ code, languageDescription }) => (
                <MenuItem key={code} value={code}>
                  {languageDescription}
                </MenuItem>
              ))}
          </Select>
        )}
      </TableCell>
      <TableCell align="center" data-testid="locale:select:active">
        {addLocale && <Typography>Active by default</Typography>}
      </TableCell>
      <TableCell align="center" data-testid="locale:select:isDefault">
        {addLocale && (
          <Switch
            checked={newLocale.isDefault}
            onChange={(_, bool) =>
              handleNewLocaleValueChange('isDefault', bool)
            }
          />
        )}
      </TableCell>
      <TableCell align="right">
        {addLocale ? (
          <>
            <Button
              className={styles.buttonMR}
              onClick={handleSave}
              data-testid="locale:add:save"
              adminColor
            >
              Save
            </Button>
            <Button
              variant="outlined"
              onClick={clearLocaleValues}
              data-testid="locale:add:cancel"
            >
              Cancel
            </Button>
          </>
        ) : (
          <Button
            onClick={() => setAddLocale(true)}
            data-testid="locale:add"
            adminColor
          >
            Add New Locale
          </Button>
        )}
      </TableCell>
    </TableBody>
  );
};

export default AddLocale;
