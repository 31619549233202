import { Skeleton } from '@octanner/prism-core';
import { skeletonData } from './types';

export const ProgramSkeleton = ({ name }: { name: string }) => {
  let skeletonData: skeletonData[] = [];

  const baseStyle = { borderRadius: 3 };

  const EditSkeltonData = [
    {
      width: '40%',
      height: 56,
      style: { marginBottom: 16, ...baseStyle, color: '#F1F1F1' },
    },
    {
      width: '65%',
      height: 56,
      style: { marginBottom: 24, ...baseStyle },
    },
    {
      width: '100%',
      height: 48,
      style: { marginBottom: 22, ...baseStyle },
    },
    {
      width: '22%',
      height: 28,
      style: { marginBottom: 14, ...baseStyle },
    },
    {
      width: '16%',
      height: 20,
      style: { marginBottom: 8, ...baseStyle },
    },
    {
      width: '28%',
      height: 20,
      style: { marginBottom: 8, ...baseStyle },
    },
    {
      width: '80%',
      height: 56,
      style: { marginBottom: 24, ...baseStyle },
    },
    {
      width: '20%',
      height: 20,
      style: { marginBottom: 8, ...baseStyle },
    },
    {
      width: '30%',
      height: 56,
      style: { marginBottom: 24, ...baseStyle },
    },
    {
      width: '30%',
      height: 28,
      style: { marginBottom: 24, ...baseStyle },
    },
    {
      width: '100%',
      height: 40,
      style: { marginBottom: 38, ...baseStyle },
    },
    {
      width: '30%',
      height: 28,
      style: { marginBottom: 14, ...baseStyle },
    },
    {
      width: '100%',
      height: 56,
      style: { ...baseStyle, marginBottom: 50 },
    },
  ];

  const productCategoryData = [
    {
      width: '15%',
      height: 28,
      style: { ...baseStyle, marginBottom: 20, marginTop: 24 },
    },
    { width: '61%', height: 40, style: { ...baseStyle, marginBottom: 24 } },
    { width: '35%', height: 28, style: { ...baseStyle, marginBottom: 24 } },
    { width: '16%', height: 20, style: { ...baseStyle, marginBottom: 8 } },
    { width: '28%', height: 56, style: { ...baseStyle, marginBottom: 5 } },
  ];

  if (name === 'productCategory') {
    skeletonData = productCategoryData;
  } else if (name === 'editProgram') {
    skeletonData = EditSkeltonData;
  }

  return (
    <div>
      {skeletonData?.map((skeleton, index) => (
        <Skeleton
          key={index}
          variant="rectangular"
          width={skeleton.width}
          height={skeleton.height}
          style={skeleton.style}
        />
      ))}
    </div>
  );
};

export default ProgramSkeleton;
