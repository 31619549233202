import {
  Autocomplete,
  Button,
  MenuItem,
  Modal,
  Radio,
  SelectionButton,
  SingleSelectionButtons,
  Switch,
  TextField,
} from '@octanner/prism-core';
import { useContext, useEffect, useState } from 'react';
import { CustomerContext } from '../../../../utils/context/CustomerContext';
import { useBankLevelLocation } from '../../modifyBank/graphql';
import { BankLevelLocation, BankSection } from '../../modifyBank/types';

import {
  AddBankSubtitle,
  BankInfoContainer,
  ButtonPadding,
  FormControlLabelWidth,
  ModalTypography,
  PoNumberTitleMB,
  RadioGroupMarginLT,
  RadioGroupMLTB,
  SelectPONumW,
  SwitchContainer,
  TextFieldWidthHeight,
} from './styles';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import ExpirationTypes from './ExpirationTypes';

const CustomerPONunberData = [
  { id: 1, name: 'Dummy data 1' },
  { id: 2, name: 'Dummy data 2' },
  { id: 3, name: 'Dummy data 3' },
  { id: 4, name: 'Dummy data 4' },
  { id: 5, name: 'Dummy data 5' },
  { id: 6, name: 'Dummy data 6' },
  { id: 7, name: 'Dummy data 7' },
  { id: 8, name: 'Dummy data 8' },
  { id: 9, name: 'Dummy data 9' },
  { id: 10, name: 'Dummy data 10' },
  { id: 11, name: 'Dummy data 11' },
  { id: 12, name: 'Dummy data 12' },
];

const GeneralBankInfo = ({
  type,
  bankData,
  handleValueUpdate,
  submitted,
  checkDates,
}: BankSection) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const defaultBank = Boolean(params.get('defaultBank'));
  const [hasName, setHasName] = useState(false);
  const [bankLevelLocationData, setBankLevelLocationData] = useState<
    BankLevelLocation[]
  >([]);
  const [showModal, setShowModal] = useState(false);
  const handleModalClose = () => {
    setShowModal(false);
  };
  const [poNumConfig, setPoNumConfig] = useState('STANDARDPO');

  useEffect(() => {
    if (defaultBank && bankData.pointBankType === 'ACCRUING_BANK') {
      handleValueUpdate('defaultAccruingBank', true);
    } else if (bankData.pointBankType === 'ONE_TIME_BANK') {
      handleValueUpdate('defaultAccruingBank', false);
    }
    // eslint-disable-next-line
  }, [bankData.pointBankType]);

  const handleModalConfirm = () => {
    handleValueUpdate('defaultAccruingBank', true);
    setShowModal(false);
  };

  const {
    customer: { id: customerId },
  } = useContext(CustomerContext);

  const { data, loading } = useBankLevelLocation(customerId);

  const { ccAdminBankBillingLocation } = useFlags();

  useEffect(() => {
    if (data) {
      setBankLevelLocationData(
        data.billingLocationsByCustomer.listConnection.nodes
          ?.map(
            ({ locationName, locationUuid, locationCode, archivedTsz }) => ({
              locationName,
              locationUuid,
              locationCode,
              archivedTsz,
            })
          )
          .filter(({ archivedTsz }) => archivedTsz === null) || []
      );
    }
  }, [data]);

  useEffect(() => setHasName(!!bankData.pointBankName.length), [bankData]);

  const handleAutoCompleteChange = (_, value) => {
    if (value && value.locationUuid) {
      handleValueUpdate('billingConfig', {
        ...bankData.billingConfig,
        billingLocation: {
          locationUuid: value.locationUuid.toString(),
        },
      });
    } else {
      handleValueUpdate('billingConfig', {
        ...bankData.billingConfig,
        billingLocation: {
          locationUuid: '',
        },
      });
    }
  };

  const handleSwitchChange = () => {
    const newSwitchValue = !bankData.defaultAccruingBank;

    if (newSwitchValue) {
      setShowModal(true);
    } else {
      handleValueUpdate('defaultAccruingBank', newSwitchValue);
    }
  };

  const handleBankTypeSelection = (value) => {
    if (bankData.pointBankType === 'ONE_TIME_BANK') {
      handleValueUpdate('defaultAccruingBank', false);
    }
    handleValueUpdate('pointBankType', value);
  };

  const handleBillingLocationRecipientTypeChange = (value) => {
    handleValueUpdate('billingConfig', {
      ...bankData.billingConfig,
      billingLocationRecipientType: value,
    });
  };

  const handleBillingLocationTypeSelection = (value) => {
    handleValueUpdate('billingConfig', {
      ...bankData.billingConfig,
      billingLocationType: value,
    });
  };

  return (
    <BankInfoContainer>
      <Modal
        actions={
          <>
            <Button onClick={handleModalConfirm}>Change Default Bank</Button>

            <Button onClick={handleModalClose} color="secondary">
              Cancel
            </Button>
          </>
        }
        id="collapse-Modal"
        onClose={handleModalClose}
        open={showModal}
        title="Changing your default bank?"
      >
        <ModalTypography>
          This bank will now show as the default bank in your store.
        </ModalTypography>
      </Modal>
      <TextFieldWidthHeight
        helperText={!hasName && submitted && 'This is a required field.'}
        error={!hasName && submitted}
        id="bank-name"
        label="Bank name"
        value={bankData?.pointBankName || ''}
        onChange={(e) => {
          e.preventDefault();
          handleValueUpdate('pointBankName', e?.target?.value);
        }}
      />
      <div>
        <AddBankSubtitle variant="h4">Bank type</AddBankSubtitle>
        {type === 'add' && (
          <SingleSelectionButtons
            onChange={handleBankTypeSelection}
            value={bankData?.pointBankType}
          >
            <SelectionButton
              value="ACCRUING_BANK"
              disabled={!ccAdminBankBillingLocation}
            >
              <ButtonPadding>Accruing bank</ButtonPadding>
            </SelectionButton>
            <SelectionButton value="ONE_TIME_BANK">
              One-time bank
            </SelectionButton>
          </SingleSelectionButtons>
        )}
        {type === 'edit' && (
          <SingleSelectionButtons
            onChange={handleBankTypeSelection}
            value={bankData?.pointBankType}
          >
            <SelectionButton
              value="ACCRUING_BANK"
              disabled={bankData.pointBankType !== 'ACCRUING_BANK'}
            >
              <ButtonPadding>Accruing bank</ButtonPadding>
            </SelectionButton>
            <SelectionButton
              value="ONE_TIME_BANK"
              disabled={bankData.pointBankType !== 'ONE_TIME_BANK'}
            >
              One-time bank
            </SelectionButton>
          </SingleSelectionButtons>
        )}

        {bankData.pointBankType === 'ACCRUING_BANK' &&
        ccAdminBankBillingLocation ? (
          <>
            <SwitchContainer>
              <Switch
                checked={bankData.defaultAccruingBank}
                onChange={handleSwitchChange}
                disabled={
                  (type === 'edit' && bankData.defaultAccruingBank) ||
                  defaultBank
                }
              />
              <Typography>Show as default bank</Typography>
            </SwitchContainer>
          </>
        ) : (
          ''
        )}
      </div>
      <div>
        <AddBankSubtitle variant="h4">
          Billing location - User-selected awards
        </AddBankSubtitle>

        {type === 'edit' && (
          <SingleSelectionButtons
            onChange={(value) => handleBillingLocationTypeSelection(value)}
            value={bankData.billingConfig?.billingLocationType || ''}
          >
            <SelectionButton
              value={bankData?.billingConfig?.billingLocationType || ''}
              disabled={type === 'edit'}
            >
              <ButtonPadding>
                {bankData.billingConfig?.billingLocationType === 'RECIPIENT'
                  ? 'Location on recipient record'
                  : 'Bank-level location'}
              </ButtonPadding>
            </SelectionButton>
          </SingleSelectionButtons>
        )}
        {type === 'add' && (
          <SingleSelectionButtons
            onChange={(value) => handleBillingLocationTypeSelection(value)}
            value={bankData.billingConfig?.billingLocationType || ''}
          >
            <SelectionButton value="RECIPIENT">
              <ButtonPadding>Location on recipient record</ButtonPadding>
            </SelectionButton>

            {type === 'add' && (
              <SelectionButton
                value="BANK"
                disabled={!ccAdminBankBillingLocation}
              >
                Bank-level location
              </SelectionButton>
            )}
          </SingleSelectionButtons>
        )}

        {ccAdminBankBillingLocation &&
          bankData?.billingConfig?.billingLocationType === 'RECIPIENT' && (
            <RadioGroupMarginLT
              aria-label="recipient"
              name="recipient"
              value={bankData?.billingConfig.billingLocationRecipientType}
              onChange={(e) => {
                handleBillingLocationRecipientTypeChange(e.target.value);
              }}
            >
              <FormControlLabelWidth
                value="BILL1"
                control={<Radio />}
                label="Billing location 1"
              />
              <FormControlLabelWidth
                value="BILL2"
                control={<Radio />}
                label="Billing location 2"
              />
            </RadioGroupMarginLT>
          )}
        {ccAdminBankBillingLocation &&
          bankData.billingConfig?.billingLocationType === 'BANK' && (
            <>
              <Autocomplete
                style={{ marginTop: 6, width: 358 }}
                disablePortal
                loading={loading}
                options={bankLevelLocationData}
                value={
                  bankLevelLocationData.find(
                    (option) =>
                      option.locationUuid ===
                      bankData.billingConfig?.billingLocation?.locationUuid
                  ) || null
                }
                getOptionLabel={(option) => option.locationCode}
                renderInput={(params) => (
                  <TextField {...params} label={'Add billing location'} />
                )}
                onChange={handleAutoCompleteChange}
                renderOption={(props, option: any) => (
                  <MenuItem {...props}>{option.locationCode}</MenuItem>
                )}
              />
            </>
          )}
      </div>
      <div>
        <PoNumberTitleMB variant="h4">PO number configuration</PoNumberTitleMB>
        <SingleSelectionButtons
          value={poNumConfig}
          onChange={(value) => {
            setPoNumConfig(value);
          }}
        >
          <SelectionButton value="STANDARDPO">
            <ButtonPadding>Standard PO number</ButtonPadding>
          </SelectionButton>
          <SelectionButton value="CUSTOMPO" disabled>
            Custom PO number
          </SelectionButton>
        </SingleSelectionButtons>

        {poNumConfig === 'STANDARDPO' && (
          <>
            <RadioGroupMLTB
              aria-label="standardPO"
              name="standardPO"
              value={bankData.poNumberType}
              onChange={(e) => {
                handleValueUpdate('poNumberType', e.target.value);
              }}
            >
              <FormControlLabelWidth
                value="PO_1"
                control={<Radio />}
                label="PO Number 1"
              />
              <FormControlLabelWidth
                value="PO_2"
                control={<Radio />}
                label="PO Number 2"
              />
            </RadioGroupMLTB>
          </>
        )}
        {poNumConfig === 'CUSTOMPO' && (
          <SelectPONumW label="Select PO number" value={''}>
            {CustomerPONunberData.map(({ id, name }) => (
              <MenuItem key={id}>{name}</MenuItem>
            ))}
          </SelectPONumW>
        )}
      </div>

      <div>
        <ExpirationTypes
          bankData={bankData}
          handleValueUpdate={handleValueUpdate}
          type={type}
          checkDates={checkDates}
        />
      </div>
    </BankInfoContainer>
  );
};

export default GeneralBankInfo;
