import { useQuery } from '@apollo/client'
import { YbAccrualPointsRegion } from '../common/models/YbAccrualPointsRegion'
import { useMemo } from 'react'
import { GET_BUDGET_GROUP_REGIONS } from '.'

interface Response {
  ybProgramOffsetsByProgramId: {
    program: { accrualPointsRegions: YbAccrualPointsRegion[] }
  }
}

const useGetBudgetGroup = (
  programId: string,
  budgetGroupId: string
): YbAccrualPointsRegion => {
  const { data } = useQuery<Response | undefined>(GET_BUDGET_GROUP_REGIONS, {
      variables: { input: { programId: programId } },
    }),
    result = useMemo(
      () =>
        (
          data?.ybProgramOffsetsByProgramId.program.accrualPointsRegions || []
        ).find((group) => group.id == budgetGroupId),
      [
        data?.ybProgramOffsetsByProgramId.program.accrualPointsRegions,
        budgetGroupId,
      ]
    )

  return result
}

export default useGetBudgetGroup
