import styled from '@emotion/styled';
import { Alert, Button, CircularProgress, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Typography } from "@octanner/prism-core";
import React, { useContext, useEffect } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { LanguageStringsContext } from './../../Contexts/LanguageStringsContext';
import { LocaleContext } from './../../Contexts/LocaleContext';
import { defaults } from './../../utils/default';
import { AlertMessage, LanguageStringContainerProps } from './../../utils/types';
import AddString from './AddString/AddString';
import EditTranslations from './EditTranslation/EditTranslations';
import SearchStrings from './SearchStrings';

const Container = styled.div<LanguageStringContainerProps>`
  margin: 20px 40px;
  height: calc(100vh - 110px);
  height: ${(props) => (props.showFooter ? 'calc(100vh - 110px)' : '100vh')};
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
`

const RadioButtonContainer = styled.div`
  margin-top: 30px;
  margin-left: 8px;
`

const RadioFormControlLabel = styled(FormControlLabel)`
  margin-right: 24px;
`

const StyledCircularProgress = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  height: 100vh;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`

const MessageAlert = styled(Alert)`
  margin-top: 12px;
  margin-bottom: 12px;
`;

const LanguageStrings: React.FC = () => {

  const {
    searchBy, setSearchBy,
    setRefetchInd, refetchInd,
    setStringText, setSearchLocale,
    setStringId, setSearchData,
    showFooter, setDataNotFound,
    setShowFooter, setMessageText, messages
  } = useContext(LanguageStringsContext);

  const { localeLoading, locales } = useContext(LocaleContext);

  const history = useHistory();

  const searchByChange = (event: React.ChangeEvent<{ value: string }>) => {
    setSearchBy(event.target.value);
    setSearchData(null);
    setStringText('');
    setSearchLocale('en-US');
    setStringId('');
    setDataNotFound(false);
  }

  const handleAddString = () => {
    setMessageText('errorMessage', '');
    history.push('/language-string/add');
  }

  useEffect(() => {
    if (refetchInd) {
      setRefetchInd(false);
      setShowFooter(false);
    }
  }, [refetchInd]);
  return (
    <Container showFooter={showFooter}>
      <Switch>
        <Route exact path='/language-string'>
          {<>
            <Header>
              <div>
                <Typography variant='h1'>
                  {defaults.language_string}
                </Typography>
                <Typography>
                  {defaults.language_subtitle}
                </Typography>
              </div>
              <div>
                <Button
                  onClick={() => handleAddString()} adminColor>
                  {defaults.add_new}
                </Button>
              </div>
            </Header>
            {messages.map((message: AlertMessage) => (
              message?.text?.length > 0 && message.key === 'errorMessage' &&
              (<Grid key={message.key} container>
                <Grid item xs={message.gridSize}>
                  <MessageAlert
                    onClose={() => setMessageText(message.key, '')}
                    severity={message.severity}>
                    {message.text}
                  </MessageAlert>
                </Grid>
              </Grid>)
            ))}
            <RadioButtonContainer>
              <FormControl>
                <RadioGroup row aria-label='searchBy' name='searchBy'
                  value={searchBy} onChange={searchByChange}>
                  <RadioFormControlLabel value='id' control={<Radio />} label={defaults.search_id} />
                  <RadioFormControlLabel value='string' control={<Radio />} label={defaults.search_string_text} />
                </RadioGroup>
              </FormControl>
            </RadioButtonContainer>
            <SearchStrings
              localeLoading={localeLoading}
              locales={locales}
            />
          </>}
        </Route>
        <Route exact path={'/language-string/edit/:stringId'}>
          {(
            <>
              {!refetchInd ? (
                <EditTranslations locales={locales} />
              ) : (
                <StyledCircularProgress>
                  <CircularProgress />
                </StyledCircularProgress>
              )}
            </>
          )}
        </Route>
        <Route exact path={'/language-string/add'}>
          <AddString />
        </Route>
      </Switch>
    </Container>
  );
}

export default LanguageStrings;