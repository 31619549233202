import { Button, Typography } from '@octanner/prism-core'
import { styled } from '@mui/material/styles'
import React from 'react'
import colors from '../../common/colors'
import {
  ContentContainer,
  HeaderContainer,
  Label,
} from '../../common/components/Styled'
import { YbCelebrationDateOffsets } from '../../common/models/YbCelebrationDateOffsets'
import { YbInHandDateOffsets } from '../../common/models/YbInHandDateOffsets'

interface Props {
  celebrationDateOffsets?: YbCelebrationDateOffsets
  inHandDateOffsets?: YbInHandDateOffsets
  onEditClick: () => void
  hideEdit?: boolean
}

const Header = styled(Typography)`
    font-weight: 700;
  `,
  SpanHeader = styled(Header)`
    grid-column: span 2;
  `,
  SubHeader = styled(Header)`
    color: ${colors.gray600};
  `,
  Highlight = styled(Typography)`
    display: inline;
    color: ${colors.black};
  `,
  Content = styled(ContentContainer)`
    grid-template-columns: repeat(2, 460px);
  `,
  Column = styled(Content)`
    grid-template-columns: 250px 200px;
    margin: 0;
  `,
  DataContainer = styled('div')`
    display: flex;
    gap: 3px;
  `

const OffsetElement: React.FC<{ title: string; offset?: number }> = ({
  offset,
  title,
}) => {
  const absOffset = Math.abs(offset || 0),
    postText = offset < 0 ? 'before' : offset > 0 ? 'after' : ''
  return (
    <>
      <Label>{title}</Label>
      <DataContainer>
        {(offset || offset === 0) && (
          <>
            <Highlight>{absOffset}</Highlight>
            <Label>
              day{absOffset === 1 ? '' : 's'} {postText}
            </Label>
          </>
        )}
      </DataContainer>
    </>
  )
}

export default function SchedulingSettings({
  celebrationDateOffsets,
  inHandDateOffsets,
  onEditClick,
  hideEdit,
}: Props): JSX.Element {
  return (
    <section data-testid="mainSettings:scheduleSettings:container">
      <HeaderContainer>
        <Typography variant="h3" data-testid="scheduling:view:title">
          Scheduling
        </Typography>
        {!hideEdit && (
          <Button
            data-testid="scheduling:view:edit"
            variant="text"
            onClick={onEditClick}
          >
            Edit
          </Button>
        )}
      </HeaderContainer>
      <Content>
        <Column>
          <SpanHeader>Celebration Date Timeline</SpanHeader>
          <OffsetElement
            title="Celebration Window Creation"
            offset={celebrationDateOffsets?.celebrationWindowStart}
          />

          <OffsetElement
            title="Presentation Preparation Email"
            offset={celebrationDateOffsets?.presentationPreparationEmail}
          />

          <OffsetElement
            title="Points Deposit"
            offset={celebrationDateOffsets?.pointsDeposit}
          />

          <OffsetElement
            title="In-Hand Date"
            offset={celebrationDateOffsets?.inHandTszOffset}
          />

          <SubHeader>Celebration Date</SubHeader>
          <Header>0</Header>
          <OffsetElement
            title="Survey Email"
            offset={celebrationDateOffsets?.surveyEmail}
          />
          <OffsetElement
            title="Fallback Order Shipping"
            offset={celebrationDateOffsets?.fallbackAwardOrder}
          />
          <OffsetElement
            title="Celebration Window End"
            offset={celebrationDateOffsets?.celebrationWindowEnd}
          />
        </Column>
        <Column>
          <SpanHeader>In-Hand Date Timeline</SpanHeader>
          <OffsetElement
            title="Initial Email to Celebrant"
            offset={inHandDateOffsets?.updateProfile}
          />
          <OffsetElement
            title="Email to Manager"
            offset={inHandDateOffsets?.managerNotification}
          />
          <OffsetElement
            title="Email to Peers"
            offset={inHandDateOffsets?.peerNotification}
          />
          <OffsetElement
            title="Empty Yearbook Email"
            offset={inHandDateOffsets?.emptyYearbookEmail}
          />
          <OffsetElement
            title="Autoship Order Trigger"
            offset={inHandDateOffsets?.autoshipAward}
          />

          <SubHeader>In-Hand Date</SubHeader>
          <Header>
            {celebrationDateOffsets?.inHandTszOffset ||
            celebrationDateOffsets?.inHandTszOffset === 0
              ? Math.abs(celebrationDateOffsets.inHandTszOffset)
              : ''}
          </Header>
        </Column>
      </Content>
    </section>
  )
}
