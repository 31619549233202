import { useFlags } from 'launchdarkly-react-client-sdk';
import AddProgram from './AddProgram';
import { default as OldAddProgram } from './NewProgram.old';
import EditProgram from './EditProgram';
import { default as OldEditProgram } from './ViewProgram.old';
import { RouteComponentProps } from 'react-router-dom';
import { useContext } from 'react';
import { CustomerContext } from '../../../utils/context/CustomerContext';
const LDAddProgram = (_: RouteComponentProps) => {
  const { ccAdminHideProgramV2, ccAdminSpecificProgramToggleJson } = useFlags();
  const {
    customer: { id: customerId },
  } = useContext(CustomerContext);
  return ccAdminHideProgramV2 ||
    (ccAdminSpecificProgramToggleJson &&
      ccAdminSpecificProgramToggleJson.indexOf(customerId) !== -1) ? (
    <AddProgram />
  ) : (
    <OldAddProgram />
  );
};
const LDEditProgram = (props: RouteComponentProps<{ programId: string }>) => {
  const { ccAdminHideProgramV2, ccAdminSpecificProgramToggleJson } = useFlags();
  const {
    customer: { id: customerId },
  } = useContext(CustomerContext);
  return ccAdminHideProgramV2 ||
    (ccAdminSpecificProgramToggleJson &&
      ccAdminSpecificProgramToggleJson.indexOf(customerId) !== -1) ? (
    <EditProgram {...props} />
  ) : (
    <OldEditProgram {...props} />
  );
};
export { LDAddProgram, LDEditProgram };
