import styled from '@emotion/styled';
import { Select, Typography } from '@octanner/prism-core';

export const ProgramTitle = styled(Typography)`
  margin-bottom: 12px;
  margin-top: 10px;
  display: flex;
`;

export const ProgramMapContainer = styled.div`
  display: flex;
`;

export const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 27px;
`;

export const SwitchSentence = styled(Typography)`
  margin-left: 8px;
`;

export const ProgramType = styled(Typography)`
  margin-bottom: 8px;
  margin-top: 24px;
`;
export const ProgramSelectWidth = styled(Select)`
  width: 288px;
  margin-bottom: 50px;
`;

export const ProgramSelctionPadding = styled.div`
  padding: 4px 2px;
`;

export const CategorySelectWidth = styled(Select)`
  width: 288px;
  margin-bottom: 16px;
`;
