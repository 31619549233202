import { AdminRoleInformation } from '../../types';
import { Chip, MenuItem, Search } from '@octanner/prism-core';
import {
  SearchTitleAndTypeContainer,
  TypographySearchSubTitle,
  TypographySearchTitle,
  TypographySearchType,
} from '../../styles';
import { tannerGray } from '@octanner/prism-core/ThemeProvider/colors';
import { useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { useSearchAdminRoles } from '../hooks/searchAdminRoles';
import { TEST_ADMIN_ROLE_DATA } from '../../constants';

type AdminRoleSearchParams = {
  // setSearchTerms: (searchTerms: string[]) => void;
  setSearchTerms: (searchTerms: string[]) => void;
};

const AdminRoleSearch = ({ setSearchTerms }: AdminRoleSearchParams) => {
  const [searchValues, setSearchValues] = useState<
    (string | AdminRoleInformation)[]
  >([]);
  const [searchText, setSearchText] = useState('');
  const SetDebouncedText = useDebouncedCallback(setSearchText, 300);
  const { data: suggestionsData, loading: suggestionsLoading } =
    useSearchAdminRoles({
      page: 0,
      pageSize: 20,
      searchTerms: [searchText],
    });

  useEffect(() => {
    const newTerms = searchValues.map((value) => {
      if (typeof value === 'string') {
        return value;
      }
      return value.role.uuid;
    });
    setSearchTerms(newTerms || []);
  }, [searchValues]);

  return (
    <Search<AdminRoleInformation>
      id="people-search"
      noOptionsText="No results found"
      loading={suggestionsLoading}
      options={suggestionsData?.content}
      multiple
      freeSolo
      value={searchValues}
      placeholder="Search by role name"
      onInputChange={(_, e) => {
        SetDebouncedText(e);
      }}
      filterSelectedOptions
      onChange={(e, value) => {
        setSearchValues(value);
      }}
      getOptionLabel={(option) => {
        if (typeof option === 'string') {
          return option;
        }
        return option.name;
      }}
      renderOption={(props, option) => {
        const { className, ...optionProps } = props;
        const { role } = option;
        return (
          <MenuItem {...optionProps} key={role.uuid}>
            <div>
              <SearchTitleAndTypeContainer>
                <TypographySearchTitle>{role.name}</TypographySearchTitle>
                <TypographySearchType>{role.accessType}</TypographySearchType>
              </SearchTitleAndTypeContainer>
              <TypographySearchSubTitle>
                {role.description}
              </TypographySearchSubTitle>
            </div>
          </MenuItem>
        );
      }}
      renderTags={(value, getTagProps) => {
        return value.map((option: string | AdminRoleInformation, index) => (
          <Chip
            variant="outlined"
            label={typeof option === 'string' ? option : option.name}
            {...getTagProps({ index })}
          />
        ));
      }}
      sx={{
        width: 540,
        '& .MuiSvgIcon-root': {
          color: tannerGray[400],
        },
      }}
    />
  );
};

export default AdminRoleSearch;
