import { useMutation } from '@apollo/client';
import { DELETE_ADMIN_ROLE } from '../graphql/gql';
import { DeleteRoleInput, DeleteRoleResponse } from '../graphql/types';
import { AppContext } from '../../../../../utils/context/AppContext';
import { useContext } from 'react';
import { ROLE_1 } from '../../constants';

const mockData = () => [
  (options?) => {
    return new Promise((resolve) => {
      resolve({
        data: {
          rdDeleteRole: {
            role: { ROLE_1 },
          },
        },
      });
    });
  },
];

export const useDeleteRole = () => {
  const { config } = useContext(AppContext);
  const mutation = useMutation<DeleteRoleResponse, DeleteRoleInput>(
    DELETE_ADMIN_ROLE,
    {
      context: {
        clientName: 'roleDelegator',
      },
    }
  );
  return config.useRoleDelegatorMockData ? mockData() : mutation;
};
