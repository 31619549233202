import styled from '@emotion/styled';
import { Divider, Grid, List, ListItemButton, ListItemText, Search, Skeleton, Typography } from "@octanner/prism-core";
import React, { useContext, useEffect, useRef, useState } from 'react';
import { LocaleContext } from '../../Contexts/LocaleContext';
import { TranslationRequestContext } from '../../Contexts/TranslationRequestContext';
import { defaults } from '../../utils/default';
import { SelectLocaleProps, unTranslatedLocale } from '../../utils/types';

const LocaleGrid = styled(Grid)`
  border-right: 1px solid #E1E1E1;
  .MuiList-padding {
    padding: 0px !important;
  }
  padding: 0px !important;
  height: inherit;
  overflow: auto;
`

const LocaleSearch = styled(Search)`
  padding: 16px;
`

const NoLocaleBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60vh;
`

const SkeletonLoader = styled(Skeleton)`
  width: 50%
`

const SelectLocale: React.FC<SelectLocaleProps> = ({ locales }) => {

  const { selectedLocale, setSelectedLocale, unTranslatedLocaleDataLoading, allLocales } = useContext(TranslationRequestContext);
  const { localeMap } = useContext(LocaleContext);
  const [filteredUnTranslatedLocaleData, setFilteredUnTranslatedLocaleData] = useState<Locale[]>([]);
  const selectedLocaleRef = useRef(null);
  selectedLocaleRef.current = selectedLocale?.locale;

  useEffect(() => {
    if (locales?.length > 0) {
      setFilteredUnTranslatedLocaleData(locales);
    }
  }, [locales]);

  const handleSearchLocale = (searchLocaleString: string) => {
    if (searchLocaleString?.trim().length === 0) {
      setFilteredUnTranslatedLocaleData(locales)
    } else {
      setFilteredUnTranslatedLocaleData(locales
        .filter(({ code }) => {
          return code.toLowerCase().includes(searchLocaleString.toLowerCase()) ||
            localeMap[code].toLowerCase().includes(searchLocaleString.toLowerCase())
        }));
    }
  }

  const selectLocale = (unTranslatedDataLocale: Locale) => {
    if (selectedLocaleRef.current !== unTranslatedDataLocale.code) {
      selectedLocaleRef.current = unTranslatedDataLocale.code;
      allLocales.map((item: unTranslatedLocale) => {
        if (item.locale === unTranslatedDataLocale.code) {
          setSelectedLocale(item);
        }
      }
      )
    }
  }

  return (
    <LocaleGrid item xs={3}>
      <List>
        <LocaleSearch
          renderTags={null}
          options={[]}
          getOptionLabel={null}
          disableClearable={true}
          onInputChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => handleSearchLocale(event.target.value)}
          placeholder={defaults.search} />
        <Divider />
        {filteredUnTranslatedLocaleData.map((unTranslatedData: Locale, index: number) => {
          const matchedLocale = allLocales.find(item => item.locale === unTranslatedData.code);
          return (
            <React.Fragment key={unTranslatedData.code}>
              <ListItemButton
                selected={selectedLocale && unTranslatedData.code === selectedLocale.locale}
                onClick={() => selectLocale(unTranslatedData)}>
                <ListItemText>
                  <Typography variant='h4'>{localeMap[unTranslatedData.code]}</Typography>
                  {unTranslatedLocaleDataLoading && <SkeletonLoader variant="text" animation="wave" />}
                  {!unTranslatedLocaleDataLoading && matchedLocale && (
                    <Typography color='textSecondary' variant='body2'>
                      {matchedLocale.languageStringCount + matchedLocale.materialDescriptionCount + matchedLocale.customTextCount}&nbsp;{defaults.requested_strings}</Typography>)}
                </ListItemText>
              </ListItemButton>
              {index !== filteredUnTranslatedLocaleData.length - 1 && <Divider />}
            </React.Fragment>
          );
        })}
      </List>
      {filteredUnTranslatedLocaleData.length === 0 && (
        <NoLocaleBox>
          <Typography color="textSecondary">{defaults.no_locale_text}</Typography>
        </NoLocaleBox>
      )}
    </LocaleGrid>
  );
}

export default SelectLocale;