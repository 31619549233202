import { useContext } from 'react';
import { AppContext } from '../../../../../utils/context/AppContext';
import { useQuery } from '@apollo/client';
import { RoleByUuidResponse } from '../graphql/types';
import { GET_ROLE_BY_UUID } from '../graphql/gql';
import { TEST_ROLE_DATA } from '../constants';

const mockData = (adminRoleUuid: string) => ({
  data: TEST_ROLE_DATA.find((r) => r.uuid === adminRoleUuid),
  loading: false,
  refetch: () => {},
});

export const useGetAdminRole = (
  adminRoleUuid: string,
  shouldSkip: boolean = false
) => {
  const { config } = useContext(AppContext);
  const query = useQuery<RoleByUuidResponse>(GET_ROLE_BY_UUID, {
    variables: {
      uuid: adminRoleUuid,
    },
    context: {
      clientName: 'roleDelegator',
    },
    skip: shouldSkip || config.useRoleDelegatorMockData,
  });
  return config.useRoleDelegatorMockData
    ? mockData(adminRoleUuid)
    : {
        ...query,
        data: query.data?.rdFindRoleByUuid,
      };
};
