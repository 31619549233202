import { Button, Typography } from '@octanner/prism-core';
import { useTranslation } from 'react-i18next';
import URLSearchParams from '@ungap/url-search-params';
import clsx from 'clsx';
import { useContext, useState } from 'react';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import ClientDetail from './ClientDetail';
import { CustomerContext } from '../../utils/context/CustomerContext';
import {
  updateCustomerFromCache,
  useUpdateCustomer,
  useUpdateMessage,
  useGetWelcomeMessage,
} from './hooks/customer';
import {
  Customer,
  SocialConfiguration,
  SocialConfigurationItems,
} from './models/Customer';
import { clientInfoForm, LOCALES } from './constants';
import { ClientInformationPayload, WelcomeMessageData } from './types';
import WelcomeMessage from './WelcomeMessage';
import { useStyles } from './styles';
import { useEffect } from 'react';
import SnackbarPopup from '../../common/snackbar';
import { isEqual } from 'lodash';
import { TElement } from '@udecode/plate';
import permissionAuthorizer from '../../utils/auth/PermissionAuthorizer';
import { AppContext } from '../../utils/context/AppContext';

const ClientInformationComponent = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    customer: contextCustomer,
    identitySearch,
    social,
    welcomeMessage: messageEnabled,
  } = useContext(CustomerContext);
  const [updateCustomer] = useUpdateCustomer();
  const welcomeMessageConfig: WelcomeMessageData = {
    enabled: messageEnabled.enabled,
    data: [],
  };
  const location = useLocation();
  const history = useHistory();
  const params = new URLSearchParams(location.search);
  const [customer, setCustomer] = useState<Customer>(contextCustomer);
  const [customerSocial, setCustomerSocial] =
    useState<SocialConfiguration>(social);
  const [welcomeMessage, setWelcomeMessage] =
    useState<WelcomeMessageData>(welcomeMessageConfig);
  const [selectedLocale, setSelectedLocale] = useState<string>('en-US');
  const {
    data: selectedWelcomeMessage,
    loading,
    error,
  } = useGetWelcomeMessage(customer.id, selectedLocale);
  const [saveError, setSaveError] = useState({ err: false, msg: '' });
  const [saveSuccess, setSaveSuccess] = useState({ save: true, msg: '' });
  const [defaultWelcomeMessage, setDefaultWelcomeMessage] =
    useState<WelcomeMessageData>({ enabled: messageEnabled.enabled, data: [] });
  const [check, setCheck] = useState<boolean>(
    social.socialConfig.personalFeedOverride?.toString() === 'false'
  );
  const [updateMessage] = useUpdateMessage(contextCustomer.id, selectedLocale);
  const { ccAdminHideSocialWallD230425, ccAdminHideSummaryWelcomeMessage } =
    useFlags();
  const appContext = useContext(AppContext);
  const welcomeAuthorizer = permissionAuthorizer('CC_WEBCONFIG_WRITE');
  const welcomeMessageAllowed = welcomeAuthorizer(appContext, customer.id);

  const isEditing =
    Boolean(params.get('edit')) || location.pathname.indexOf('edit') > -1;

  params.set('edit', 'true');
  const editLink = location.pathname.replace(
    'client-summary',
    'client-summary/edit'
  );
  params.delete('edit');
  const cancelLink = location.pathname.replace(
    'client-summary/edit',
    'client-summary'
  );

  const keyList = defaultWelcomeMessage?.data.map((x) => Object.keys(x)[0]);

  useEffect(() => {
    if (loading || !isEditing || selectedWelcomeMessage === undefined) return;
    const oldArray = defaultWelcomeMessage.data;
    const defaultKey = oldArray.map((x) => Object.keys(x)[0]);
    const newArray = oldArray;
    if (selectedWelcomeMessage?.ccWebWelcomeMessage) {
      const { locale, content } = selectedWelcomeMessage.ccWebWelcomeMessage;
      // ! This might be redundant at this point, older code but should be fine to stay. Else shouldnt ever happen
      if (defaultKey.indexOf(selectedLocale) === -1) {
        if (!content.data) {
          newArray.push({
            [locale]: [{ children: [{ text: '' }] }] as TElement[],
          });
        }
        newArray.push({
          [locale]: content.data,
        });
      } else {
        newArray.push({
          [locale]: [{ children: [{ text: '' }] }] as TElement[],
        });
      }
      if (!newArray.length) {
        setDefaultWelcomeMessage((prev) => ({
          ...prev,
          data: [{ [locale]: content.data || [{ children: [{ text: '' }] }] }],
        }));
      } else setDefaultWelcomeMessage((prev) => ({ ...prev, data: newArray }));
    } else {
      // ! No welcome message exists in DB, provide default
      newArray.push({
        [selectedLocale]: [{ children: [{ text: '' }] }] as TElement[],
      });
      setDefaultWelcomeMessage((prev) => ({ ...prev, data: newArray }));
    } // eslint-disable-next-line
  }, [selectedWelcomeMessage, error, loading]);

  useEffect(() => {
    if (isEditing) {
      const activeMessageKeys = welcomeMessage.data.map(
        (x) => Object.keys(x)[0]
      );
      defaultWelcomeMessage.data.forEach((content) => {
        let key = Object.keys(content)[0];
        if (activeMessageKeys.indexOf(key) === -1) {
          setWelcomeMessage((prev) => ({
            ...prev,
            data: [...prev.data, { [key]: content[key] }],
          }));
        }
      });
    }

    //eslint-disable-next-line
  }, [defaultWelcomeMessage]);

  if (!customer) {
    return <Typography>Loading</Typography>;
  }

  const handleChange = (key: keyof Customer) => (value: any) => {
    setCustomer({
      ...customer,
      [key]: value,
    });
  };

  const handleSocialChange =
    (key: keyof SocialConfigurationItems) => (value: any) => {
      const val = value === 'true';
      setCheck(!val);
      setCustomerSocial({ ...customerSocial, [key]: !val });
    };

  const handleMessageDataChange = (e: TElement[]) => {
    const oldArray = welcomeMessage.data;
    const newArray = oldArray.map((x) => {
      const key = Object.keys(x)[0];
      if (key === selectedLocale) return { [key]: e };
      else return x;
    });
    setWelcomeMessage((oldData) => ({
      ...oldData,
      data: newArray,
    }));
  };

  const isValid = Boolean(customer.name);

  // TODO: Find the correct typing for this.
  const handleSubmit = async (event: any) => {
    try {
      event.preventDefault();
      // TODO add error to user
      if (!isValid) return;
      const { id, name, region, stpNumber } = customer;
      const {
        viewType,
        displayComments,
        displayLikes,
        displayAwardLevel,
        // displayBusinessUnit,
        // productFilters,
      } = customerSocial.socialConfig;
      if (welcomeMessage.enabled) {
        const queuedLocales = LOCALES.map(({ locale }, i) => {
          const defaultLocaleLocation = keyList.indexOf(locale);
          if (defaultLocaleLocation !== -1) {
            const oldMessage =
              defaultWelcomeMessage.data[defaultLocaleLocation][locale];
            const newMessage =
              welcomeMessage.data[keyList.indexOf(locale)][locale];
            if (!isEqual(oldMessage, newMessage)) {
              return {
                customerUuid: customer.id,
                locale,
                index: i,
                content: newMessage,
              };
            }
          }
          return null;
        }).filter((n) => n); // ? removes nulls
        queuedLocales.forEach((data) => {
          if (data === null) return;
          const { locale, content, customerUuid } = data;
          const input = {
            customerUuid,
            locale,
            content: JSON.stringify({ data: content }),
          };
          updateMessage({ variables: input });
          setDefaultWelcomeMessage(welcomeMessage);
        });
      }
      let payload: ClientInformationPayload = {
        variables: {
          id,
          name: name.trim(),
          region,
          input: {
            customerId: id,
            viewType,
            displayComments,
            displayLikes,
            displayAwardLevel,
            // displayBusinessUnit,
            // productFilters,
            personalFeedOverride: !check,
          },
        },
        update: updateCustomerFromCache({
          id,
          customerId: id,
          social: {
            personalFeedOverride: check,
          },
        }),
      };
      if (stpNumber) {
        payload.variables.stpNumber = stpNumber.trim();
      }
      await updateCustomer(payload).then(() =>
        setSaveSuccess({ save: true, msg: 'Saved Successfully!' })
      );
      history.push(cancelLink);
    } catch (e) {
      console.log('Something bad happened!', e);
      setSaveError({ err: true, msg: 'Failed Saving, please try again.' });
    }
  };

  const resetPayloadData = () => {
    if (check === customerSocial.socialConfig.personalFeedOverride) {
      setCheck(check);
    }
    setCustomer(contextCustomer);
    setCustomerSocial(social);
    setWelcomeMessage(defaultWelcomeMessage);
    setSelectedLocale('en-US');
  };

  return (
    <div className={classes.root}>
      <div
        className={clsx(
          classes.sectionHeader,
          isEditing ? classes.editingHeader : ''
        )}
      >
        <Typography variant="h3">{t('client-summary')}</Typography>
        {!isEditing && (
          <RouterLink to={editLink} data-test="client:edit">
            <Button variant="text">Edit</Button>
          </RouterLink>
        )}
      </div>
      <div
        className={clsx(
          classes.sectionContent,
          isEditing ? classes.editingSection : ''
        )}
      >
        {customer !== undefined &&
          clientInfoForm.map(({ key, label, isEditable, trimmedLabel }) => (
            <ClientDetail
              label={label}
              value={customer[key]?.toString() || ''}
              isEditing={isEditing}
              isEditable={isEditable}
              dataTest={`client:${key}`}
              onChange={handleChange(key)}
              trimmedLabel={trimmedLabel}
            />
          ))}
        {/* It would be nice if both of these values could be combined with user, but for now they stay here */}
        <ClientDetail
          label="Total Users"
          value={identitySearch.totalRecordsCount.toString()}
          isEditing={isEditing}
          dataTest="client:total-users"
          trimmedLabel="totalusers"
        />
        {ccAdminHideSocialWallD230425 && (
          <ClientDetail
            label="Social Wall"
            value={check.toString()}
            isEditing={isEditing}
            isEditable
            dataTest="client:social-wall"
            onChange={handleSocialChange('personalFeedOverride')}
            trimmedLabel="socialwall"
          />
        )}
        {!ccAdminHideSummaryWelcomeMessage && (
          <WelcomeMessage
            customerUuid={customer.id}
            data={welcomeMessage}
            setData={setWelcomeMessage}
            isEditing={isEditing}
            locale={selectedLocale}
            setLocale={setSelectedLocale}
            handleDataChange={handleMessageDataChange}
            loading={loading}
            error={error}
            editable={welcomeMessageAllowed}
          />
        )}

        <SnackbarPopup
          text={saveError.msg}
          open={saveError.err}
          handleClose={() => setSaveError({ err: false, msg: '' })}
          severity="error"
        />
        <SnackbarPopup
          text={saveSuccess.msg}
          open={saveSuccess.save}
          handleClose={() => setSaveSuccess({ save: false, msg: '' })}
          severity="success"
        />
        {isEditing && (
          <div>
            <Button
              data-test="client:edit:submit"
              onClick={handleSubmit}
              adminColor
            >
              Save Changes
            </Button>
            <RouterLink to={cancelLink} data-test="client:edit:cancel">
              <Button
                className={classes.cancelButton}
                color="secondary"
                onClick={resetPayloadData}
              >
                Cancel
              </Button>
            </RouterLink>
          </div>
        )}
      </div>
    </div>
  );
};

export default ClientInformationComponent;
