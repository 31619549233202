import Loading from '../../common/Loading';
import ContentItemComponent from './ContentItem';
import { useTermsOfUse } from './hooks';

const TermsComponent = () => {
  const { data, loading, error, actions } = useTermsOfUse();

  if (loading) {
    return <Loading />;
  }

  return (
    <ContentItemComponent
      contentVersions={data}
      createVersion={actions.createTermsOfUseVersion}
      error={error}
      typeText="Terms of Use"
    />
  );
};

export default TermsComponent;
