import { Button, Skeleton, Typography } from '@octanner/prism-core';
import { FlexBetweenDiv } from './styles';
import DraggableRow from './draggableRow';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { SortableList } from './sortableList';
import { BaseItem } from './types';
import {
  useGetSearchConfig,
  useUpdateSearchDisable,
  useUpdateSearchDisableAll,
  useUpdateSearchEnable,
  useUpdateSearchOrder,
} from '../graphql';
import { useParams } from 'react-router-dom';
import { ORDER_VALUES } from './constants';
import { CustomerContext } from '../../../utils/context/CustomerContext';
import Errors from './errors';

const UniversalSearch = () => {
  const { customerId } = useParams<{ customerId: string }>();
  const { data, loading, error, refetch } = useGetSearchConfig({
    customerUuid: customerId,
  });
  const {
    customer: { id: customerUuid },
  } = useContext(CustomerContext);

  const [updateSearchOrder] = useUpdateSearchOrder();
  const [updateSearchEnable] = useUpdateSearchEnable();
  const [updateSearchDisable] = useUpdateSearchDisable();
  const [updateSearchDisableAll] = useUpdateSearchDisableAll();
  const [items, setItems] = useState<BaseItem[]>([]);
  const [universalSearchError, setUniversalSearchError] = useState(!!error);
  const [saveError, setSaveError] = useState<string | null>(null);

  const sortOrder = (arr: BaseItem[]): BaseItem[] =>
    arr.sort((a, b) => a.order - b.order);

  useEffect(() => {
    if (data?.ccWebUserSearchConfig.fields) {
      const fields = data.ccWebUserSearchConfig.fields;
      setItems(sortOrder([...fields]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleDeselect = () => {
    updateSearchDisableAll({ variables: { customerUuid } }).then(({ data }) => {
      if (data)
        setItems(sortOrder(data?.ccWebDisableUserSearchFieldTypes.fields));
    });
    refetch();
  };

  const handleSwitchChange = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean,
    translationStringId: string
  ): void => {
    event.preventDefault();
    const fieldType = ORDER_VALUES[translationStringId];

    const totalSelected = items.map((x) => x.enabled).filter((x) => x).length;

    // ? Check if we are enabling a field with more than 5, we do not have to check if this is false!
    if (totalSelected >= 5 && checked) {
      setSaveError('Only 5 fields can be enabled at a time.');
    } else {
      if (checked) {
        updateSearchEnable({
          variables: { input: { fieldType, customerUuid } },
        })
          .then(({ data }) => {
            if (data) setItems(data.ccWebEnableUserSearchFieldType.fields);
          })
          .catch((e) => {
            setSaveError(e.message);
          });
      } else if (!checked) {
        updateSearchDisable({
          variables: { input: { fieldType, customerUuid } },
        })
          .then(({ data }) => {
            if (data) setItems(data.ccWebDisableUserSearchFieldType.fields);
          })
          .catch((e) => {
            setSaveError(e.message);
          });
      }

      // ? automatically switch push before update, just to look prettier.
      const position = items.findIndex(
        (x) => x.translationStringId === translationStringId
      );
      const newItems = items.map((x, i) =>
        position === i ? { ...x, enabled: checked } : x
      );
      setItems(newItems);
    }
  };

  const handleUpdateOrder = (newItems: BaseItem[]) => {
    const oldOrder = items;
    const newOrder = newItems.map((x) => ORDER_VALUES[x.translationStringId]);
    setItems(newItems);

    updateSearchOrder({
      variables: {
        input: {
          customerUuid,
          fieldTypes: newOrder,
        },
      },
    }).catch((e) => {
      setItems(oldOrder);
      setSaveError(e.message);
    });

    refetch();
  };

  if (loading) {
    return (
      <div>
        <Skeleton height={40} />
        <Skeleton height={65} />
        <Skeleton height={65} />
        <Skeleton height={65} />
        <Skeleton height={65} />
      </div>
    );
  }

  return (
    <div>
      <Errors
        universalSearchError={universalSearchError}
        setUniversalSearchError={setUniversalSearchError}
        saveError={saveError}
        setSaveError={setSaveError}
      />
      <FlexBetweenDiv>
        <Typography>
          Choose which order and fields are displayed and associated with a
          user, when searched. You can select up to 5 fields.
        </Typography>
        <Button
          onClick={handleDeselect}
          variant="text"
          data-testid="universal-search:button:deselect-all"
        >
          Deselect All
        </Button>
      </FlexBetweenDiv>
      <div>
        <SortableList
          items={items}
          onChange={handleUpdateOrder}
          renderItem={(item) => (
            <DraggableRow
              item={item}
              onChange={handleSwitchChange}
              data-testid={`draggable-row-${item.translationStringId}`}
            />
          )}
        />
      </div>
    </div>
  );
};

export default UniversalSearch;
