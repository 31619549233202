import { OrgColumns } from './types';

export const ORG_COLUMNS_PHASE1: OrgColumns[] = [
  {
    key: 'salesOrg',
    title: 'Included sales org and countries',
    cellStyle: { width: 400 },
  },
  {
    key: 'awardShippingLocation',
    title: 'Award shipping location',
    cellStyle: null,
  },
  {
    key: 'blank',
    title: '',
    cellStyle: null,
  },
];

export const ORG_COLUMNS_PHASE2: OrgColumns[] = [
  {
    key: 'country',
    title: 'Country',
    cellStyle: { width: 180, paddingTop: 0, paddingBottom: 0 },
  },
  {
    key: 'invoiceSalesOrg',
    title: 'Invoicing sales org',
    cellStyle: { width: 340, paddingTop: 0, paddingBottom: 0 },
  },
  {
    key: 'awardShippingLocation',
    title: 'Award shipping location',
    cellStyle: { width: 300, paddingTop: 0, paddingBottom: 0 },
  },
  {
    key: 'blank',
    title: '',
    cellStyle: null,
  },
];

export const AWARD_SHIP_OPTIONS_PHASE1 = [
  {
    value: 'VARIES_PER_USER',
    text: 'Varies by user',
  },
  {
    value: 'BY_HOME',
    text: 'Varies by home',
  },
  {
    value: 'BY_WORK',
    text: 'Varies by work',
  },
];

export const AWARD_SHIP_OPTIONS_PHASE2 = [
  {
    value: 'VARIES_PER_USER',
    text: 'Varies by user',
  },
  {
    value: 'WORK1',
    text: 'Work 1',
  },
  {
    value: 'WORK2',
    text: 'Work 2',
  },
  {
    value: 'BY_HOME',
    text: 'Home',
  },
];
