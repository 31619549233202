import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@octanner/prism-core';
import { ProgramListViewModel } from '../models/models';
import { Link as RouterLink } from 'react-router-dom';
import {
  HeaderCellContainer,
  NoProgramText,
  NoProgramTitle,
  ProgramContainer,
  ProgramSubTitle,
  TypographyWS,
} from '../styles';
import { useContext, useEffect, useRef, useState } from 'react';
import SingleRow from './SingleRow';
import { headCells } from '../constants';
import ProgramRowSkeleton from './programRowSkeleton';
import { CustomerContext } from '../../../utils/context/CustomerContext';

function saveDataToLocalStorage(key: string, data: ProgramListViewModel[]) {
  localStorage.setItem(key, JSON.stringify(data));
}

function getDataFromLocalStorage(key: string) {
  const data = localStorage.getItem(key);
  return data ? JSON.parse(data) : null;
}

export default function ProgramListRow({ match, allVMs, loading }) {
  const [newOrder, setNewOrder] = useState<ProgramListViewModel[]>([]);
  const {
    customer: { id: customerUuid },
  } = useContext(CustomerContext);

  useEffect(() => {
    const localStorageData = getDataFromLocalStorage(
      `CustomerId: ${customerUuid}`
    );

    if (localStorageData && Array.isArray(localStorageData)) {
      setNewOrder(localStorageData);
    } else if (allVMs && allVMs.length > 0) {
      setNewOrder([...allVMs]);
    }
  }, [allVMs, customerUuid]);

  useEffect(() => {
    if (newOrder && newOrder.length > 0) {
      saveDataToLocalStorage(`CustomerId: ${customerUuid}`, newOrder);
    }
  }, [newOrder, customerUuid]);

  useEffect(() => {
    const newProgram = allVMs.find(
      (a) => !newOrder.find((b) => b.uuid === a.uuid)
    );
    if (newProgram) {
      setNewOrder([...newOrder, newProgram]);
    }
  }, [allVMs, newOrder]);

  const dragItem = useRef<any>(null);
  const dragOverItem = useRef<any>(null);

  const handleSort = () => {
    let _allVMs = [...newOrder];

    const draggedItemContent = _allVMs.splice(dragItem.current, 1)[0];

    _allVMs.splice(dragOverItem.current, 0, draggedItemContent);

    dragItem.current = null;
    dragOverItem.current = null;

    setNewOrder(_allVMs);
  };

  const reorderArray = (event, originalArray) => {
    const movedItem = originalArray.find(
      (_, index) => index === event.oldIndex
    );
    const remainingItems = originalArray.filter(
      (_, index) => index !== event.oldIndex
    );

    const reorderedItems = [
      ...remainingItems.slice(0, event.newIndex),
      movedItem,
      ...remainingItems.slice(event.newIndex),
    ];

    return reorderedItems;
  };

  const changeOrder = (index: number, direction: 'UP' | 'DOWN') => {
    var newIndex = index;

    if (direction === 'UP' && index - 1 >= 0) {
      newIndex -= 1;
    }
    if (direction === 'DOWN' && index + 1 < newOrder.length) {
      newIndex += 1;
    }

    setNewOrder(
      reorderArray({ oldIndex: index, newIndex: newIndex }, newOrder)
    );
  };

  useEffect(() => {
    const updatedOrder = newOrder.map((orderItem) => {
      const updatedItem = allVMs.find((item) => item.uuid === orderItem.uuid);
      return updatedItem || orderItem;
    });

    if (JSON.stringify(updatedOrder) !== JSON.stringify(newOrder)) {
      setNewOrder(updatedOrder);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allVMs]);

  if (loading && allVMs?.length === 0) {
    return (
      <Table>
        <TableBody>
          <ProgramRowSkeleton />
        </TableBody>
      </Table>
    );
  }

  return (
    <>
      {allVMs?.length > 0 ? (
        <div>
          <ProgramSubTitle>
            Your program list order effects how they are viewed in Culture
            Cloud.
          </ProgramSubTitle>

          <Table>
            <TableHead>
              <HeaderCellContainer>
                {headCells.map(({ id, label, align }) => (
                  <TableCell align={align} key={id}>
                    <TypographyWS variant="h5">{label}</TypographyWS>
                  </TableCell>
                ))}
              </HeaderCellContainer>
            </TableHead>

            <TableBody>
              {newOrder.map((newOrderAllVMs, index) => (
                <TableRow
                  key={newOrderAllVMs.uuid}
                  draggable
                  onDragStart={() => (dragItem.current = index)}
                  onDragEnter={() => (dragOverItem.current = index)}
                  onDragEnd={handleSort}
                  onDragOver={(e) => e.preventDefault()}
                >
                  <SingleRow
                    index={index}
                    newOrderAllVMs={newOrderAllVMs}
                    changeOrder={changeOrder}
                  />
                </TableRow>
              ))}

              {loading ? <ProgramRowSkeleton /> : <></>}
            </TableBody>
          </Table>
        </div>
      ) : (
        <ProgramContainer>
          <NoProgramTitle variant="h3">Let's get things going!</NoProgramTitle>
          <NoProgramText>Begin by creating your first program</NoProgramText>
          <Button
            disableRipple
            component={RouterLink}
            // @ts-ignore
            to={`${match.url}/new`}
            data-test="programs:new"
          >
            Create a program
          </Button>
        </ProgramContainer>
      )}
    </>
  );
}
