import React, { useState } from 'react';
import { Checkbox, Tag, Typography } from '@octanner/prism-core';
import { StyledTableCell, TagContainer} from './style';
import { DisplayCountriesProps } from './types';
import { useTranslation } from 'react-i18next';
import TableRow from '@octanner/prism-core/TableRow';

const DisplayCountries: React.FC<DisplayCountriesProps> = ({
  locale,
  handlelocaleSelection,
  setLanguageDataChanged,
  setUpdateSapError,
  
}) => {

  const {language, country, languageCode, defaultLocale, checked} = locale;

  const [selectedLocale, setSelectedLocale] = useState(checked);
  const { t } = useTranslation();

  const onChange = () => {
    setLanguageDataChanged(true);
    setSelectedLocale(!selectedLocale);
    handlelocaleSelection(languageCode, !selectedLocale);
    setUpdateSapError(false);
  }

  return (
    <TableRow>
      <StyledTableCell>
        <Checkbox
          disabled={defaultLocale}
          checked={selectedLocale}
          onChange={() => onChange()}
        />
        <div>
          <Typography>{language}</Typography>
          <Typography variant='body2' color='textSecondary'>{country}</Typography>
        </div>
        {defaultLocale && (
          <TagContainer>
            <Tag variant="archived" >
              {t('default')}
            </Tag>
          </TagContainer>
        )}
      </StyledTableCell>
    </TableRow>
  );
};

export default DisplayCountries;
