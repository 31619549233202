import { Button } from '@octanner/prism-core';
import Typography from '@octanner/prism-core/Typography';
import { ArrowLeft } from '@octanner/prism-icons';
import { FlexBetweenDiv } from '../styles';

interface ClientHeaderProps {
  onNavigateAway: () => void;
}

const ClientHeader = ({ onNavigateAway }: ClientHeaderProps) => {
  return (
    <>
      <FlexBetweenDiv>
        <div>
          <Button
            variant="text"
            data-test={`client:edit:back`}
            onClick={onNavigateAway}
            startIcon={<ArrowLeft />}
          >
            Back to client access
          </Button>
          <Typography variant="h1" mt={4} mb={2}>
            Edit Client Roles
          </Typography>
          <Typography>Manage the roles for this client.</Typography>
        </div>
      </FlexBetweenDiv>
    </>
  );
};

export default ClientHeader;
