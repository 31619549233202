import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Tag,
} from '@octanner/prism-core';
import Typography from '@octanner/prism-core/Typography';
import { tannerGray } from '@octanner/prism-core/ThemeProvider/colors';
import { useGetAdminSummary } from '../hooks/getAdminSummary';
import Loading from '../../../../../common/Loading';
import { useEffect } from 'react';

const AdminInfoField = ({
  fieldName,
  fieldValue,
}: {
  fieldName: string;
  fieldValue: string;
}) => {
  return (
    <Box sx={{ flex: '1' }}>
      <Typography
        variant="body2"
        component="span"
        sx={{ color: tannerGray[700] }}
      >{`${fieldName} : `}</Typography>
      <Typography component="span">{fieldValue}</Typography>
    </Box>
  );
};

const AdminCard = ({
  identityUuid,
  onReplaceAdmin,
}: {
  identityUuid: string;
  onReplaceAdmin?: () => void;
}) => {
  const { data: identity, loading } = useGetAdminSummary(identityUuid);

  useEffect(() => {
    if (!loading && !identity) {
      onReplaceAdmin?.();
    }
  }, [loading, identity]);

  return (
    <>
      {(loading && <Loading />) ||
        (identity && (
          <Card
            sx={{
              mt: 4,
              width: 540,
            }}
          >
            <CardHeader
              title={`${identity!.preferredName || identity!.firstName} ${
                identity!.lastName
              }`}
              subheader={identity!.email}
              action={
                onReplaceAdmin && (
                  <Button
                    variant="text"
                    color="primary"
                    onClick={onReplaceAdmin}
                  >
                    Replace admin
                  </Button>
                )
              }
            />
            <CardContent sx={{ paddingTop: 1 }}>
              <AdminInfoField
                fieldName="Employee ID"
                fieldValue={identity!.uniqueId}
              />
              <AdminInfoField
                fieldName="Title"
                fieldValue={identity!.jobTitle}
              />
              <Box sx={{ display: 'flex' }}>
                <AdminInfoField
                  fieldName="Department"
                  fieldValue={identity!.businessUnit?.buName || ''}
                />
                <Tag
                  variant={identity!.terminationDate ? 'warning' : 'success'}
                >
                  {identity!.terminationDate ? 'Inactive' : 'Active'}
                </Tag>
              </Box>
            </CardContent>
          </Card>
        ))}
    </>
  );
};

export default AdminCard;
