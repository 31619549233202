import React from 'react';
import { Tag } from '@octanner/prism-core';
import { Check, Warning } from '@octanner/prism-icons';
import { useStyles } from './styles';
import { ItemProps } from './types';
import CircularProgress from '../../common/CircularProgress';

const Items: React.FC<ItemProps> = ({
  status,
  type,
  itemName,
  preMigrationFailure,
  migrationFailed,
  isCreated,
  errorMessage,
}) => {
  const classes = useStyles();

  const statement = () => {
    if (preMigrationFailure)
      return `${itemName} is not populated in the NTP database. Please contact the Core team.`;
  };

  const whichStyles = (status: boolean | undefined) => {
    if (status === true) {
      return classes.success;
    }
    if (status === false && migrationFailed === true) {
      return classes.error;
    }
    if (status === false && isCreated === true && migrationFailed === false) {
      return classes.checking;
    }
    return classes.notStarted;
  };

  return (
    <>
      <li
        className={whichStyles(status)}
        style={
          status === undefined || (status === false && !isCreated)
            ? {
                display: 'list-item',
                textIndent: 10,
                marginLeft: -20,
                marginBottom: 7,
              }
            : { display: 'flex', marginLeft: -40, marginBottom: 7 }
        }
        data-testid={itemName.toLowerCase().replaceAll(/\s/g, '-')}
      >
        {status === true && <Check className={classes.checkAndWarning} />}
        {status === false && migrationFailed === true && (
          <Warning className={classes.checkAndWarning} />
        )}
        {status === false && migrationFailed === false && isCreated && (
          <CircularProgress
            style={{ marginRight: 10 }}
            size={16}
            thickness={10}
          />
        )}
        {itemName}
      </li>
      {status === false &&
        type === 'preMigration' &&
        migrationFailed === true && (
          <div
            className={classes.errorDiv}
            data-testid={`${itemName
              .toLowerCase()
              .replaceAll(/\s/g, '-')}-error`}
          >
            <Tag className={classes.errorTag} open variant="error">
              {errorMessage || statement()}
            </Tag>
          </div>
        )}
    </>
  );
};

export default Items;
