import styled from '@emotion/styled';
import { Button } from '@octanner/prism-core';
import { tannerGray } from '@octanner/prism-core/ThemeProvider/colors';
import Typography from '@octanner/prism-core/Typography';

export const AddAdminButton = styled(Button)`
  margin: 8px 0;
`;

export const AdminContainer = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 744px) {
    /* width: 100%;
    flex-wrap: wrap; */
  }
`;

export const AdminListContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AlertContainer = styled.div`
  margin-bottom: 24px;
`;

export const BUNameTypography = styled(Typography)`
  color: ${tannerGray[600]};
`;

export const DAButtonContainer = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
`;

export const FlexBetweenDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 24px;
`;

export const NoResultsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: ${tannerGray[50]};
  padding: 16px;
  flex-direction: column;
  margin-top: 16px;
  gap: 3;
`;

export const TypographyMB = styled(Typography)`
  margin-bottom: 24px;
`;
