import { CoreFeaturesResponse } from '../../../graphql/core-feature/models';

export enum SocialFeedSearchView {
  CORPORATE = 'CORPORATE',
  BUSINESS_UNIT = 'BUSINESS_UNIT',
}

export interface CustomerBase {
  id: string;
  name: string;
  stp?: string; //! can be both stp or stp number???
  stpNumber?: string;
  customerStatus?: string;
  customerUsage?: string;
}

export interface Customer extends CustomerBase {
  region: CustomerRegion;
  stpNumber?: string;
  modifiedOn: string;
  modifiedBy: string;
  launchedAtTsz: string;
  settings: {
    emailEditingDisabledTsz: string | null;
  };
}

export interface SocialConfigurationItems {
  displayAwardLevel: boolean;
  // displayBusinessUnit: boolean;
  displayComments: boolean;
  displayFollowingTab: boolean;
  displayLikes: boolean;
  personalFeedOverride: boolean;
  // productFilters:{
  //   key: string;
  //   values: string[];
  // }[];
  viewType: SocialFeedSearchView;
}

export interface SocialConfiguration {
  socialConfig: SocialConfigurationItems;
}

export interface CustomerWithFeatures {
  customer: Customer;
  features: CoreFeaturesResponse;
  social: SocialConfiguration;
  welcomeMessage: {
    enabled: boolean;
  };
  identitySearch: {
    totalRecordsCount: number;
  };
}

export interface CustomersResponse {
  customers: CustomerBase[];
}

export interface CustomersInput {
  searchText: string;
}

export enum CustomerRegion {
  aus = 'aus',
  gbr = 'gbr',
  usa = 'usa',
}

export interface CreateCustomerInput {
  input: {
    name: string;
    region: CustomerRegion;
    customerStatus: string;
  };
}

export interface CreateCustomerResponse {
  createCustomer: CustomerBase;
}

export interface SearchCustomersInput {
  searchText: string;
  pagination: {
    pageSize: number;
    pageNumber: number;
  };
}

export interface GetCustomersResponse {
  searchCustomers: {
    nodes: CustomerBase[];
    pageInfo: {
      pageNumber: number;
      pageSize: number;
      totalPages: number;
      totalElements: number;
      hasPrevious: boolean;
      hasNext: boolean;
    };
  };
}

export interface GetCustomerInput {
  id: string;
  customerId: string;
  social: {
    personalFeedOverride: boolean;
  };
}

export interface UpdateCustomerInput {
  id: string;
  name: string;
  region: CustomerRegion;
  stpNumber?: string;
  input: {
    customerId: string;
    displayComments: boolean;
    displayLikes: boolean;
    displayAwardLevel: boolean;
    viewType: SocialFeedSearchView;
    personalFeedOverride: boolean;
  };
}

export interface UpdateCustomerResponse {
  editCustomer: Customer;
  social: {
    personalFeedOverride: boolean;
  };
}

export interface CoupleCustomerAndCoreFeatureInput {
  customerId: String;
  coreFeatureCode: String;
  effective: String;
  expiration?: String;
}

export interface CoupleCustomerAndCoreFeatureResponse {
  coupleCustomerAndCoreFeature: { customer: CustomerWithFeatures };
}

export interface UpdateWelcomeConfigResponse {
  enabled: boolean;
}

export interface UpdateWelcomeConfigInput {
  customerUuid: string;
  enabled: boolean;
}

export interface UpdateWelcomeInput {
  customerUuid: string;
  locale: string;
  content: string;
}

export interface UpdateWelcomeResponse {
  content: string;
  locale: string;
}
