import { useCallback } from 'react'
import { useApolloClient } from '@apollo/client'
import {
  GET_PROGRAM_OFFSETS_BY_PROGRAM_ID,
  buildPatchProgramConfigMutation,
} from '.'
import { ProgramRepresentation } from '../common/models/ProgramRepresentation'
import { YbProgramInput } from '../common/models/YbProgramInput'
import { YbProgramYearbookLocalesInput } from '../common/models/YbProgramYearbookLocales'

type Input = {
  programConfigInput: YbProgramInput
  addLocalesInput?: YbProgramYearbookLocalesInput
  removeLocalesInput?: YbProgramYearbookLocalesInput
}

type Response = {
  ybPatchProgramConfigs: ProgramRepresentation
}

// this mutation is a bit abnormal because when saving for
// the first time we have to save make sure the languages get
// saved last, but other saves they should be done first
export const usePatchProgram = (programId: string) => {
  const client = useApolloClient(),
    mutation = useCallback(
      async (input: Input, isNew = false) => {
        const nodeDocument = buildPatchProgramConfigMutation(isNew, {
            add: Boolean(input.addLocalesInput),
            remove: Boolean(input.removeLocalesInput),
          }),
          { data } = await client.mutate<Response, Input>({
            mutation: nodeDocument,
            variables: input,
            refetchQueries: [
              isNew && {
                query: GET_PROGRAM_OFFSETS_BY_PROGRAM_ID,
                variables: { programId },
              },
            ],
          })
        return data.ybPatchProgramConfigs
      },
      [client, programId]
    )

  return mutation
}
