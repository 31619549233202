import {
  Autocomplete,
  TextField,
  Chip,
  Typography,
} from '@octanner/prism-core';
import { useContext, useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { useSearchBusinessUnits } from '../../../graphql/business-unit/hooks';
import { listToText } from '../../../common/models';
import { CustomerContext } from '../../../utils/context/CustomerContext';
import { BusinessUnitProps } from '../types';
import { BusinessUnit } from '../modifyProgram/types';
import { tannerGray } from '@octanner/prism-core/ThemeProvider/colors';
import { Check, Search } from '@octanner/prism-icons';
import { MenuItem } from '@mui/material';

const BusinessUnitInput = ({
  selectedBusinessUnits,
  onChange,
  dataTestId,
}: BusinessUnitProps) => {
  const {
    customer: { id: customerId },
  } = useContext(CustomerContext);
  const [, setValueText] = useState('');
  const [searchContent, setSearchContent] = useState('');
  const { data: options, loading } = useSearchBusinessUnits({
    customerId,
    searchContent,
  });
  const selectedUUID = selectedBusinessUnits.map((x) => x.uuid);

  useEffect(() => {
    const names = selectedBusinessUnits.map((bu) => bu.name);
    setValueText(listToText(names, 'Business Units', 40));
  }, [selectedBusinessUnits]);

  const handleTextFieldChange = useDebouncedCallback(setSearchContent, 250);

  return (
    <Autocomplete
      multiple
      loading={loading}
      options={options}
      disableCloseOnSelect
      onChange={(_, o) => onChange(o)}
      value={selectedBusinessUnits}
      isOptionEqualToValue={(option, selectedOption) =>
        option.uuid === selectedOption.uuid
      }
      getOptionLabel={(option) => option.name}
      disableClearable
      renderOption={(props: any, option: any) => (
        <MenuItem
          {...props}
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <div>
            <Typography>{option.name}</Typography>
            <Typography style={{ color: tannerGray[500] }}>
              Secondary Copy
            </Typography>
          </div>

          {selectedUUID.indexOf(option.uuid) !== -1 && <Check />}
        </MenuItem>
      )}
      renderInput={(params) => (
        <TextField
          style={{ height: '100px !important' }}
          {...params}
          multiline
          variant="outlined"
          onChange={(e) => handleTextFieldChange(e.target.value)}
          placeholder="Search"
          inputProps={{
            ...params.inputProps,
            'data-test': `${dataTestId}:businessUnit:input`,
          }}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <>
                <Search /> {params.InputProps.startAdornment}
              </>
            ),
          }}
        />
      )}
      renderTags={(value: BusinessUnit[], getTagProps) =>
        value.map((option: BusinessUnit, index: number) => (
          <Chip
            variant="outlined"
            label={option.name}
            {...getTagProps({ index })}
          />
        ))
      }
    />
  );
};

export default BusinessUnitInput;
