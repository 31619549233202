import { CSSProperties } from 'react';
import { styled } from '@material-ui/core';
import PrismCircularProgress from '@octanner/prism-core/CircularProgress';
import Colors from '../common/constants/Colors';

interface Props {
  style?: CSSProperties;
  size?: string | number;
  className?: string;
  thickness?: number;
}

const CircularProgressWrapper = styled('div')<any, Props>(() => ({
  color: Colors.blue,
}));

const CircularProgress = ({ size, style, className, thickness }: Props) => {
  return (
    <CircularProgressWrapper>
      <PrismCircularProgress
        size={size}
        style={style}
        color="inherit"
        className={className}
        thickness={thickness}
      />
    </CircularProgressWrapper>
  );
};

export default CircularProgress;
