import { gql } from '@apollo/client';
import { CORE_FEATURE_BASE_FRAGMENT } from '../../graphql/core-feature/graphql';
import { CORE_BUSINESS_UNIT_FRAGMENT } from '../../graphql/business-unit/graphql';
import { CORE_COUNTRY_FRAGMENT } from '../../graphql/country/graphql';

const CORE_PROGRAM_TYPE_FRAGMENT = gql`
  fragment CoreProgramTypeFragment on CoreProgramType {
    id
    programTypeName
    programTypeCode
  }
`;

const CORE_PROGRAM_TYPE_WITH_FEATURE_FRAGMENT = gql`
  ${CORE_PROGRAM_TYPE_FRAGMENT}
  ${CORE_FEATURE_BASE_FRAGMENT}
  fragment CoreProgramTypeWithFeatureFragment on CoreProgramType {
    ...CoreProgramTypeFragment
    feature {
      ...CoreFeatureBaseFragment
    }
  }
`;

const CORE_PROGRAM_FRAGMENT = gql`
  fragment CoreProgramFragment on CoreProgram {
    id
    programUuid
    programName
    startTsz
    endTsz
    displayTz
    eligibleCount {
      eligibleIdentityCount
    }
  }
`;

const CORE_PROGRAM_WITH_TYPE_FRAGMENT = gql`
  ${CORE_PROGRAM_FRAGMENT}
  ${CORE_PROGRAM_TYPE_WITH_FEATURE_FRAGMENT}
  fragment CoreProgramWithTypeFragment on CoreProgram {
    ...CoreProgramFragment
    coreProgramType {
      ...CoreProgramTypeWithFeatureFragment
    }
  }
`;

const CORE_PROGRAM_ELIGIBILITY_FRAGMENT = gql`
  ${CORE_BUSINESS_UNIT_FRAGMENT}
  ${CORE_COUNTRY_FRAGMENT}
  fragment CoreProgramEligibilityFragment on CoreProgramEligibility {
    programEligibilityUuid
    allParticipants
    businessUnitEligibilities {
      businessUnit {
        ...CoreBusinessUnitFragment
      }
      eligibilitySet
    }
    homeCountryEligibilities {
      coreCountry {
        ...CoreCountryFragment
      }
      eligibilitySet
    }
    workCountryEligibilities {
      coreCountry {
        ...CoreCountryFragment
      }
      eligibilitySet
    }
  }
`;

export const GET_PROGRAM_TYPES = gql`
  ${CORE_PROGRAM_TYPE_WITH_FEATURE_FRAGMENT}
  query getProgramTypes {
    coreProgramTypes {
      coreProgramTypes {
        nodes {
          ...CoreProgramTypeWithFeatureFragment
        }
      }
    }
  }
`;

export const CREATE_PROGRAM = gql`
  mutation createProgram(
    $coreProgramTypeId: Int!
    $programName: String!
    $customerId: UUID!
  ) {
    createNewCoreProgram(
      request: {
        customerId: $customerId
        coreProgramTypeId: $coreProgramTypeId
        programName: $programName
      }
    ) {
      ...CoreProgramFragment
    }
  }
  ${CORE_PROGRAM_FRAGMENT}
`;

export const GET_PROGRAM_WITH_ELGIBILITY = gql`
  ${CORE_PROGRAM_WITH_TYPE_FRAGMENT}
  ${CORE_PROGRAM_ELIGIBILITY_FRAGMENT}
  query getProgramWithEligibility($programUuid: UUID!) {
    coreProgramByProgramUuid(input: { programUuid: $programUuid }) {
      coreProgram {
        ...CoreProgramWithTypeFragment
      }
    }
    coreProgramEligibilityByProgram(input: { programUuid: $programUuid }) {
      programEligibility {
        ...CoreProgramEligibilityFragment
      }
    }
  }
`;

export const UPDATE_PROGRAM = gql`
  ${CORE_PROGRAM_WITH_TYPE_FRAGMENT}
  mutation updateProgram($request: CoreProgramUpdateRequest!) {
    updateOneCoreProgram(request: $request) {
      ...CoreProgramWithTypeFragment
    }
  }
`;

export const PROGRAMS_BY_CUSTOMER = gql`
  ${CORE_PROGRAM_WITH_TYPE_FRAGMENT}
  query getProgramsByCustomer(
    $customerId: UUID!
    $pageNumber: NonNegativeInt!
    $pageSize: PositiveInt!
  ) {
    coreProgramsByCustomer(
      input: {
        customerId: $customerId
        pageNumber: $pageNumber
        pageSize: $pageSize
      }
    ) {
      nodes {
        ...CoreProgramWithTypeFragment
      }
      pageInfo {
        pageNumber
        totalPages
      }
    }
  }
`;

export const CREATE_PROGRAM_ELIGIBLITY = gql`
  ${CORE_PROGRAM_ELIGIBILITY_FRAGMENT}
  mutation createProgramEligibility(
    $input: CoreProgramEligibilityInsertRequest!
  ) {
    createNewCoreProgramEligibility(request: $input) {
      ...CoreProgramEligibilityFragment
    }
  }
`;

export const UPDATE_PROGRAM_ELIGIBLITY = gql`
  ${CORE_PROGRAM_ELIGIBILITY_FRAGMENT}
  mutation updateProgramEligibility(
    $input: CoreProgramEligibilityUpdateRequest!
  ) {
    updateOneCoreProgramEligibility(request: $input) {
      ...CoreProgramEligibilityFragment
    }
  }
`;

export const GET_PROGRAM_SALES_ORG_AND_COUNTRY = gql`
  query CustomerCountrySalesOrgMapsByCustomer($customerId: UUID!) {
    customerCountrySalesOrgMapsByCustomer(customerId: $customerId) {
      customerCountrySalesOrgMap {
        customerCountrySalesOrgId
        coreCountry {
          countryName
          id
          iso2Code
          iso3Code
        }
        assignedSalesOrg {
          salesOrgCode
          salesOrgName
        }
      }
    }
  }
`;

export const GET_SALES_REPS = gql`
  query SearchSalesRep($input: SearchSalesRepInput!) {
    searchSalesRep(input: $input) {
      nodes {
        salesRepName
        salesRepNumber
        salesRepUuid
        email
      }
    }
  }
`;
