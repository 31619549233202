import React from 'react';
import styled from '@emotion/styled';
import { ConfigRelatedFlexFieldDetail as FieldType } from '../types';
import Field from './Field';

const Heading = styled.div`
  font-size: 14px;
  font-weight: 700;
  margin-top: 32px;
  margin-bottom: 16px;
`;

const Ul = styled.ul`
  border-bottom: solid 1px #e1e1e1;
  margin-top: 16px;
  margin-bottom: 0;
  padding: 0;

  & > li {
    border-top: solid 1px #e1e1e1;
  }
`;

const FieldList: React.FC<Props> = ({
  fields,
  onDelete,
  setDisableNewField,
  updateField,
  orderField,
}) => {
  return (
    <div>
      <Heading>Fields in the order they appear</Heading>
      <Ul>
        {fields.map((field, index) => (
          <Field
            key={field.flexFieldQuestion.id}
            field={field.flexFieldQuestion}
            updateField={updateField}
            visibility={field.flexfieldAnswerVisibility}
            onDelete={onDelete}
            setDisableNewField={setDisableNewField}
            required={field.required}
            shiftUp={index === 0 ? null : () => orderField(index, 'up')}
            shiftDown={
              index === fields.length - 1
                ? null
                : () => orderField(index, 'down')
            }
          />
        ))}
      </Ul>
    </div>
  );
};

interface Props {
  fields: FieldType[];
  onDelete: (fieldId: string) => void;
  setDisableNewField: (fieldToEdit: boolean) => void;
  updateField: (fieldId, newValue) => void;
  orderField: (fieldIndex: number, direction: string) => void;
}

export default FieldList;
