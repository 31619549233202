import styled from '@emotion/styled';
import {
  Button,
  Search,
  Select,
  Typography,
  TextField,
  Autocomplete
} from '@octanner/prism-core';


export const FlexContainer = styled.div`
  display: flex;
  margin : 16px 0px;
`;

export const CustomTextField = styled(TextField)(
  ({ width } : any) => `
  width: ${width};
  margin-top: 16px;
`
);

export const Feild = styled.div`
  margin-top: 8px;
`;

export const PhoneFax = styled.div`
  display: flex;
  margin-top: 8px;
`;

export const Fax = styled.div`
  margin-left: 50px;
`;

export const StateZipCode = styled.div`
  display: flex;
`;

export const ActionButton = styled.div`
  display: flex;
  margin-top: 16px;
`;

export const CancelButton = styled(Button)`
  margin-top: 24px;
  margin-left: 8px;
`;

export const PhoneFaxContainer = styled.div`
  display: flex;
  align-items: flex-end;
`;

export const InvoiceHeader = styled.div`
  display: flex;
`;

export const TextFeildSmall = styled(TextField)`
  width: 440px;
  margin-top: 16px;
`;

export const TextFeildLarge = styled(TextField)`
  width: 592px;
  margin-top: 16px;
`;

export const InvoiceSelect = styled(Select)`
  width: 360px;
  margin-bottom: 16px;
`;

export const SearchBox = styled(Search)`
  width: 440px;
  margin-top: 16px;
`;

export const CityTextFeild = styled(TextField)`
  width: 364px;
  margin-top: 16px;
`;


export const Invoice = styled.div`
  margin: 32px 0px;
`;

export const InvoiceSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

export const InvoiceSelectBilling = styled(Select)`
  width: 360px;
  margin-bottom: 0px;
`;


export const StateSelect = styled(Autocomplete)`
  width: 288px;
  margin-right: 16px;
  margin-top: 16px;
`;

export const FaxContainer = styled.div`
  margin-left: 16px;
`;

export const StateTextBoxContainer = styled(TextField)`
  .MuiInputBase-root { 
    .MuiInputBase-input {
     padding: 7px 7px;
    }
  }
`;

export const FlexDiv = styled.div`
  display: flex;
`;

export const EditButton = styled(Button)`
  margin-bottom: 5px;
`;

export const Header = styled(Typography)`
  margin-top: 7px;
`;

export const EditSectionButton = styled(Button)`
  margin : 8px 0px 0px 8px;
`;

export const InvoiceHead = styled(Typography)`
  margin-top: 7px;
`;
