import { createStyles, makeStyles } from '@material-ui/core/styles';
import { CustomerContext } from './utils/context/CustomerContext';
import { useState, useContext } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import impersonationAuthorizer from './utils/auth/ImpersonationAuthorizer';
import xcaAuthorizer from './utils/auth/XcaAuthorizer';
import NotFound from './pages/notFoundPage';
import ErrorPage from './pages/errorPage';
import ProtectedRoute from './common/ProtectedRoute';
import CustomerRoutes from './pages/customer/CustomerRoutes';
import GlobalRoutes from './pages/global/GlobalRoutes';
import Impersonate from './pages/impersonate';
import DrawerContentWrapper from './common/drawer';
import DrawerConfig from './navigation/DrawerConfig';
import { useHistory } from 'react-router-dom';
import { useCurrentIdentity } from './pages/user-auth/hooks/identity';
import { usePendoScript } from './utils/pendo';
import AdminRoleRoutes from './pages/global/adminAccess/adminRoles/routes';
import RemediationRoutes from './pages/core/Routes';
import AdminsRoutes from './pages/global/adminAccess/admins/routes';
import ClientsRolesRoutes from './pages/global/adminAccess/clientAccess/routes';
const useStyles = makeStyles(() =>
  createStyles({
    main: {
      flex: '1 1 auto',
      display: 'flex',
      height: '100%',
      width: '100%',
      flexDirection: 'column',
      position: 'sticky',
    },
    menuPositioningHamburger: {
      position: 'fixed',
      top: 0,
      left: 0,
    },
    cursor: {
      margin: 10,
      cursor: 'pointer',
    },
  })
);

const AppRoutes = () => {
  const classes = useStyles();
  const [back, setBack] = useState(false);
  const history = useHistory();
  const { unset } = useContext(CustomerContext);
  const { data } = useCurrentIdentity();
  usePendoScript(
    data?.currentIdentity?.id || '',
    data?.currentIdentity?.customer?.id || ''
  );

  const handleBack = () => {
    unset();
    history.push('/');
    setBack(false);
  };

  return (
    <div style={{ display: 'flex' }}>
      <DrawerContentWrapper
        open={true}
        back={back}
        handleBack={handleBack}
        drawerComponents={() => DrawerConfig({ back, setBack })}
      >
        <div className={classes.main}>
          <Switch>
            <Route path="/customer/:customerId" component={CustomerRoutes} />
            <ProtectedRoute
              path="/impersonate"
              component={Impersonate}
              authorizer={impersonationAuthorizer}
            />
            <ProtectedRoute
              path="/admins"
              component={AdminsRoutes}
              authorizer={xcaAuthorizer}
            />
            <ProtectedRoute
              path="/admin-roles"
              component={AdminRoleRoutes}
              authorizer={xcaAuthorizer}
            />
            <ProtectedRoute
              path="/client-access"
              component={ClientsRolesRoutes}
              authorizer={xcaAuthorizer}
            />
            <ProtectedRoute
              path="/remediation"
              component={RemediationRoutes}
              authorizer={xcaAuthorizer}
            />
            <ProtectedRoute
              path="/"
              component={GlobalRoutes}
              authorizer={xcaAuthorizer}
            />
            <Route path="/not-found" component={NotFound} />
            <Route path="/error" component={ErrorPage} />
            <Route path="/" exact>
              <Redirect to="/client-list" />
            </Route>
            <Route>
              <Redirect to="/not-found" />
            </Route>
          </Switch>
        </div>
      </DrawerContentWrapper>
    </div>
  );
};

export default AppRoutes;
