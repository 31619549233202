import React from 'react';
import { authFetch } from './auth/Auth';
import { useCurrentIdentity } from '../pages/user-auth/hooks/identity';
import { useGetAllLocales } from '../pages/locale/graphql';
import { Locale } from '../pages/locale/types';
import { useTranslation } from 'react-i18next';
import { Settings } from 'luxon';
import i18n, { InitOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';

export const i18nConfig: InitOptions = {
  // For simplicity the translation key is set to 'en' regardless
  // of what language is returned from the translations-api.
  lng: 'en',
  keySeparator: false, // we do not use keys in form messages.welcome
  interpolation: {
    prefix: '{',
    suffix: '}',
    escapeValue: false, // react already safes from xss
  },
  react: {
    useSuspense: false,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init(i18nConfig);

const TranslationLoader: React.FC<{
  translationsApi: string;
}> = ({ children, translationsApi }) => {
  const { data } = useCurrentIdentity();
  const currentIdentity = data?.currentIdentity;

  const { data: allLocales } = useGetAllLocales();
  const preFilterLocales: Locale[] =
    allLocales?.getCoreLocales?.coreLocales?.nodes;
  const { i18n } = useTranslation();
  const [lang, setLang] = React.useState(currentIdentity?.language);

  React.useEffect(() => {
    if (!currentIdentity || currentIdentity.language === lang) return;
    setLang(currentIdentity.language);

    /**
     * only allow officially supported language codes
     */
    if (preFilterLocales && preFilterLocales.length > 0) {
      if (
        preFilterLocales.some(({ code }) => code === currentIdentity.language)
      ) {
        Settings.defaultLocale = currentIdentity.language as string;
      } else if (
        preFilterLocales.some(({ code }) => code === navigator.language)
      ) {
        Settings.defaultLocale = navigator.language;
      } else Settings.defaultLocale = 'en-US';
    } else {
      Settings.defaultLocale = 'en-US';
    }

    authFetch(translationsApi, {
      headers: currentIdentity.language
        ? { 'accept-language': currentIdentity.language }
        : {},
    })
      .then((res) => {
        if (res.ok) return res.json();
        throw res;
      })
      .then((data) => {
        i18n
          .addResourceBundle('en', 'translation', data)
          .emit('languageChanged');
      })
      .catch((error) => {
        console.error('Unable to retrieve translation data:', error.message);
      });
  }, [currentIdentity, i18n, translationsApi, lang, preFilterLocales]);

  if (!currentIdentity) return null;

  return <>{children}</>;
};

TranslationLoader.displayName = 'TranslationLoader';

export default TranslationLoader;
