import { gql } from '@apollo/client';
import { CORE_FEATURE_BASE_FRAGMENT } from '../../../graphql/core-feature/graphql';

export const PERMISSION_BASE_FRAGMENT = gql`
  fragment PermissionBaseFragment on Permission {
    id
    uuid
    name
    code
  }
`;

export const PERMISSION_SCOPE_FRAGMENT = gql`
  ${PERMISSION_BASE_FRAGMENT}
  ${CORE_FEATURE_BASE_FRAGMENT}
  fragment PermissionScopeFragment on Permission {
    ...PermissionBaseFragment
    scope {
      id
      name
      feature {
        ...CoreFeatureBaseFragment
      }
    }
  }
`;

export const COUPLE_PERMISSIONS = gql`
  ${PERMISSION_SCOPE_FRAGMENT}
  mutation couplePermissions(
    $customerId: UUID!
    $permissionIds: [UUID!]!
    $roleId: UUID!
  ) {
    couplePermissionsAndRole(
      customerId: $customerId
      permissionIds: $permissionIds
      roleId: $roleId
    ) {
      rolePermissionMapperList {
        id
        permission {
          ...PermissionScopeFragment
        }
      }
    }
  }
`;

export const DECOUPLE_PERMISSIONS = gql`
  ${PERMISSION_SCOPE_FRAGMENT}
  mutation decouplePermissions(
    $customerId: UUID!
    $permissionIds: [UUID!]!
    $roleId: UUID!
  ) {
    decouplePermissionsAndRole(
      customerId: $customerId
      permissionIds: $permissionIds
      roleId: $roleId
    ) {
      rolePermissionMapperList {
        id
        permission {
          ...PermissionScopeFragment
        }
      }
    }
  }
`;

export const GET_PERMISSION_SUMMARIES = gql`
  ${PERMISSION_SCOPE_FRAGMENT}
  ${CORE_FEATURE_BASE_FRAGMENT}
  query getPermissionSummaries(
    $roleId: Long!
    $customerUuid: UUID!
    $featureCode: String!
    $page: Int!
    $size: Int!
  ) {
    feature(featureCode: $featureCode) {
      ...CoreFeatureBaseFragment
    }
    getPermissionSummaryByFeatureAndRole(
      customerId: $customerUuid
      featureCode: $featureCode
      page: $page
      roleId: $roleId
      size: $size
    ) {
      content {
        isCoupled
        permission {
          ...PermissionScopeFragment
        }
      }
      total
      last
      totalPages
    }
  }
`;

export const COUPLE_FEATURE_PERMISSIONS = gql`
  ${PERMISSION_SCOPE_FRAGMENT}
  mutation coupleFeaturePermissions(
    $customerId: UUID!
    $featureCode: String!
    $roleId: UUID!
  ) {
    couplePermissionsToRoleByFeature(
      customerId: $customerId
      featureCode: $featureCode
      roleUuid: $roleId
    ) {
      permission {
        ...PermissionScopeFragment
      }
    }
  }
`;

export const DECOUPLE_FEATURE_PERMISSIONS = gql`
  ${PERMISSION_SCOPE_FRAGMENT}
  mutation decoupleFeaturePermissions(
    $customerId: UUID!
    $featureCode: String!
    $roleId: UUID!
  ) {
    decouplePermissionsToRoleByFeature(
      customerId: $customerId
      featureCode: $featureCode
      roleUuid: $roleId
    ) {
      permission {
        ...PermissionScopeFragment
      }
    }
  }
`;
