import { gql } from '@apollo/client';


export const CHECKED_SALES_ORG = gql`
  query CustomerCountrySalesOrgMap($customerId: UUID!) {
    customerCountrySalesOrgMapsByCustomer(customerId: $customerId) {
      customerCountrySalesOrgMap {
        coreCountry {
          iso2Code
          iso3Code
          countryName
        }

        assignedSalesOrg {
          salesOrgCode
          salesOrgName
          currency {
            currencyCode
            currencyName
            currencyNum
            currencySymbol
          }
        }
      }
    }
  }
`;

export const ADD_SALES_ORG = gql`
  mutation modifyCustomerAndCountrySalesOrgMaps(
    $input: CustomerCountrySalesOrgMapsModificationRequest!
  ) {
    modifyCustomerAndCountrySalesOrgMaps(input: $input) {
      customerCountrySalesOrgMap {
        customer {
          customerUuid
        }
        coreCountry {
          iso2Code
        }
        assignedSalesOrg {
          salesOrgCode
        }
      }
    }
  }
`;

