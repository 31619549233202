import Box from '@mui/system/Box';
import Button from '@octanner/prism-core/Button';
import ArrowTailLeft from '@octanner/prism-icons/ArrowTailLeft';
import { CelebrationGroupSettings } from '@octanner/yearbook-program-settings';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { YearbookCelebrationsParams } from './types';

const YearbookCelebrations: React.FC = () => {
  const history = useHistory(),
    params = useParams<YearbookCelebrationsParams>(),
    { customerId, programId, celebrationGroupId } = params,
    navigateToProgram = () => {
      history.push(`/customer/${customerId}/programs/${programId}/edit`);
    };

  return (
    <Box sx={{ m: 7, maxWidth: 960 }}>
      <Button
        variant="text"
        onClick={navigateToProgram}
        startIcon={<ArrowTailLeft />}
        sx={{ mb: 6 }}
      >
        Back to program
      </Button>
      <CelebrationGroupSettings
        programId={programId}
        celebrationGroupId={celebrationGroupId}
        onChange={navigateToProgram}
      />
    </Box>
  );
};

export default YearbookCelebrations;
