import { useState, useEffect } from 'react';
import {
  TextField,
  Autocomplete,
  Typography,
  MenuItem,
} from '@octanner/prism-core';
import { CustomerBase } from '../customer/models/Customer';
import { useSearchCustomers } from '../global/hooks/customer';
import CircularProgress from '../../common/CircularProgress';
import { useHistory } from 'react-router-dom';

const MigrationSearch = () => {
  const history = useHistory();
  const [input, setInput] = useState<string>('');
  const [customers, setCustomers] = useState<CustomerBase[]>([]);
  const [pageNumber, setPageNumber] = useState(0);
  const { data, loading, fetchMore } = useSearchCustomers(
    { pageSize: 25, pageNumber: pageNumber },
    input
  );
  const nodes = data?.searchCustomers?.nodes;
  useEffect(() => {
    const users = nodes ? nodes : [];
    if (customers.length > 0 && pageNumber > 0) {
      setCustomers((cus) => {
        return cus.concat(users);
      });
    } else {
      setCustomers(users);
    }
    /* eslint-disable */
  }, [data, pageNumber]);

  const reset = () => {
    setInput('');
    setCustomers([]);
  };

  const handleSearchClick = ({ name, id: coreCustomerUuid }: any) => {
    if (!coreCustomerUuid) return;

    setInput(`${name} (${coreCustomerUuid})`);
    history.push(`migration/${coreCustomerUuid}`);
  };

  const handleTextChange = (input: string, customers?: CustomerBase[]) => {
    setInput(input);
    if (customers) setCustomers(customers!);
  };

  const handleScroll = ({ currentTarget }: any) => {
    if (
      currentTarget.scrollTop + currentTarget.clientHeight >=
      currentTarget.scrollHeight
    ) {
      setPageNumber(pageNumber + 1);
      fetchMore({});
    }
  };
  return (
    <Autocomplete
      loading={loading}
      options={customers}
      getOptionLabel={(customer: any) =>
        customer.id || customer.stpNumber || customer.id
          ? `${customer.name} (${customer.stpNumber})(${customer.id})`
          : input
      }
      autoHighlight
      freeSolo
      clearOnBlur
      data-testid={'client-search-field'}
      disablePortal={true}
      onChange={(_, value) => {
        if (value === null) return;
        handleSearchClick(value);
      }}
      onInputChange={(event, newInputValue, reason) => {
        if (reason === 'clear') {
          reset();
          return;
        } else {
          setInput(newInputValue);
        }
      }}
      ListboxProps={{
        onScroll: (event: React.SyntheticEvent) => handleScroll(event),
      }}
      renderOption={(props, { name, id: coreCustomerUuid }) => (
        <MenuItem key={coreCustomerUuid} {...props}>
          <Typography style={{ fontSize: 14 }}>
            {name + ' - ' + coreCustomerUuid}
          </Typography>
        </MenuItem>
      )}
      renderInput={(params: any) => (
        <>
          <TextField
            value={input}
            {...params}
            fullWidth
            label="Client"
            placeholder="Search by Name, Core ID, or STP"
            onChange={(e) => handleTextChange(e.target.value, nodes)}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress size={20} style={{ marginBottom: 15 }} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        </>
      )}
    />
  );
};

export default MigrationSearch;
