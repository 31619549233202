import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Button, TextField, Select, MenuItem } from '@octanner/prism-core';
import { FlexFieldQuestion as FieldType } from '../types';

const FIELDNAME_CHAR_LIMIT = 50;
const QUESTION_CHAR_LIMIT = 180;
const HELPERTEXT_CHAR_LIMIT = 180;

const Container = styled.div`
  padding: 20px 16px 0;
`;

const FieldsContainer = styled.div`
  display: flex;
`;

const Fields = styled(TextField)`
  && {
    flex-grow: 1;
    margin: 8px 0px 8px 0px;
    box-sizing: content-box;
  }
`;

const FieldName = Fields;
const Question = Fields;
const Helper = Fields;

const FieldEditor: React.FC<Props> = ({ field, onClose, onSave }) => {
  const [fieldName, setFieldName] = useState(field.fieldName || '');
  const [question, setQuestion] = useState(field.question || '');
  const [helperText, setHelperText] = useState(field.helperText || '');
  const [characterLimit, setCharacterLimit] = useState(
    field.characterLimit || 500,
  );

  const disableSave = !!(
    fieldName.length < 1 ||
    fieldName.length > FIELDNAME_CHAR_LIMIT ||
    question.length < 1 ||
    question.length > QUESTION_CHAR_LIMIT ||
    (helperText && helperText.length > HELPERTEXT_CHAR_LIMIT)
  );

  const save = () => {
    onSave({
      id: field.id,
      fieldName,
      question,
      helperText,
      characterLimit,
      immutable: false,
    });
    onClose();
  };

  const [wasFieldNameFocusLost, setWasFieldNameFocusLost] = useState(false);
  const [wasQuestionFocusLost, setWasQuestionFocusLost] = useState(false);

  const onFieldFocusLost = () => setWasFieldNameFocusLost(true);
  const onQuestionFocusLost = () => setWasQuestionFocusLost(true);

  return (
    <Container>
      <FieldsContainer>
        <FieldName
          inputProps={{ 'data-testid': 'field-name' }}
          defaultValue={fieldName}
          onBlur={onFieldFocusLost}
          fullWidth
          label="Field name"
          maxLength={FIELDNAME_CHAR_LIMIT}
          displayCount
          onChange={(event) => {
            setFieldName(event.target.value);
          }}
          required
          error={wasFieldNameFocusLost && fieldName.length < 1}
        />
      </FieldsContainer>
      <FieldsContainer>
        <Question
          inputProps={{ 'data-testid': 'question' }}
          defaultValue={question}
          fullWidth
          label="Question"
          maxLength={QUESTION_CHAR_LIMIT}
          onBlur={onQuestionFocusLost}
          displayCount
          onChange={(event) => {
            setQuestion(event.target.value);
          }}
          required
          error={wasQuestionFocusLost && question.length < 1}
        />
      </FieldsContainer>
      <FieldsContainer>
        <Helper
          defaultValue={helperText}
          id="helper-text"
          fullWidth
          label="Helper text"
          maxLength={HELPERTEXT_CHAR_LIMIT}
          displayCount
          helperText="Optional"
          onChange={(event) => {
            setHelperText(event.target.value);
          }}
          required
        />
      </FieldsContainer>
      <FieldsContainer>
        <Select
          defaultValue={characterLimit || 500}
          onChange={(e) => setCharacterLimit(Number(e.target.value))}
          label="Character limit"
          sx={{ width: '144px' }}
        >
          <MenuItem value={500}>500</MenuItem>
          <MenuItem value={3000}>3000</MenuItem>
        </Select>
      </FieldsContainer>

      <Button
        variant="contained"
        onClick={save}
        disabled={disableSave}
        data-testid="close-field"
        sx={{ margin: '16px 8px 16px 0px' }}
      >
        Save Field
      </Button>
    </Container>
  );
};

interface Props {
  field: FieldType;
  onClose: () => void;
  onSave: (input: {
    id: string;
    fieldName: string;
    question: string;
    helperText: string;
    characterLimit: number;
    immutable: boolean;
  }) => void;
}

export default FieldEditor;
