export const visibilitySettings = {
  APPROVER: 'Approver Only',
  RECIPIENT: 'Recipient Only',
  EVERYONE: 'Everyone',
};

export const requiredSettings = {
  REQUIRED: 'Required',
  OPTIONAL: 'Optional',
};
