import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@octanner/prism-core/Typography';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    text: {
      marginTop: theme.spacing(3),
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  })
);

const Inprogress = () => {
  const classes = useStyles();
  return (
    <Typography variant="h1" className={classes.text}>
      Page is still in progress
    </Typography>
  );
};

export default Inprogress;
