import {
  Switch,
  Typography,
  Select,
  MenuItem,
  Skeleton,
} from '@octanner/prism-core';
import { WelcomeMessageProps } from './types';
import { TElement } from '@udecode/plate';
import RichTextEditor from '../../common/slateEditor/RichTextEditor';
import { useStyles } from './styles';
import { LOCALES } from './constants';
import { useEffect, useState } from 'react';
import { useUpdateMessageConfig } from './hooks/customer';
import { PermissionsTooltipWrapper } from '../../common/PermissionTooltipWrapper';

const WelcomeMessage = ({
  customerUuid,
  data: welcomeMessage,
  setData,
  isEditing,
  locale: selectedLocale,
  setLocale: setSelectedLocale,
  handleDataChange,
  loading,
  error,
  editable,
}: WelcomeMessageProps) => {
  const classes = useStyles();
  const [message, setMessage] = useState(
    welcomeMessage.data.filter((x) => x[selectedLocale])[0]
  );
  const [toggleWelcome] = useUpdateMessageConfig(
    customerUuid,
    !welcomeMessage.enabled
  );

  useEffect(() => {
    setMessage(welcomeMessage.data.filter((x) => x[selectedLocale])[0]);
    // eslint-disable-next-line
  }, [selectedLocale, welcomeMessage]);

  const handleUpdateWelcomeMessageConfig = () => {
    toggleWelcome();
    setData((oldData) => ({
      ...oldData,
      enabled: !welcomeMessage.enabled,
    }));
  };

  if (!isEditing) {
    return (
      <div className={classes.clientDetailRoot}>
        <div className={classes.label}>
          <Typography color="textSecondary">Welcome Message</Typography>
        </div>
        <div>
          <Typography>
            {welcomeMessage.enabled ? 'Enabled' : 'Disabled'}
          </Typography>
        </div>
      </div>
    );
  }
  return (
    <>
      <hr className={classes.lineBreak} />
      <div className={classes.spaceBetween}>
        <div>
          <Typography variant="h4" className={classes.typoMargin8}>
            Welcome Message
          </Typography>
          {welcomeMessage.enabled && (
            <Select
              label="Language"
              data-test="welcome-message-selector"
              value={selectedLocale}
              onChange={({ target }) =>
                setSelectedLocale(target.value as string)
              }
              disabled={!editable}
            >
              {LOCALES.map(({ locale, name }) => (
                <MenuItem key={locale} value={locale}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          )}
        </div>
        <PermissionsTooltipWrapper
          text="You do not have permission to edit this section"
          enabled={!editable}
          placement="left"
        >
          <Switch
            data-test="welcome-message-toggle"
            checked={welcomeMessage.enabled}
            onChange={() => handleUpdateWelcomeMessageConfig()}
            disabled={!editable}
          />
        </PermissionsTooltipWrapper>
      </div>
      {welcomeMessage.enabled && message && (
        <div className={classes.RTEBackground}>
          <div className={editable ? '' : classes.disabledTextBox}>
            {message[selectedLocale] !== undefined && !loading ? (
              <RichTextEditor
                id={editable ? 'edit' : 'read'}
                data-test="welcome-message-editor"
                value={message[selectedLocale]}
                disabled={['h1', 'h2', 'h4', 'h5', 'h6', 'table']}
                onChange={(e: TElement[]) => handleDataChange(e)}
                enableToolbar={editable}
              />
            ) : (
              <Skeleton variant="text" height={100} />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default WelcomeMessage;
