import { TElement, Value } from '@udecode/plate';
import { DateTime } from 'luxon';

export enum EditModes {
  item = 'editItem',
  version = 'editVersion',
}

export interface ContentItemProps {
  contentVersions: ContentVersion<DateTime>[];
  createVersion(
    formData: ContentVersionForm
  ): Promise<ContentVersion<DateTime> | void>;
  error: string | null;
  typeText: string;
}

export interface ContentVersionItemFormProps {
  cloneVersion?: ContentVersion<DateTime>;
  onSave: (form: ContentVersionForm) => void;
  onCancel: () => void;
  material?: boolean;
  typeText: string;
  disableConfirmation?: boolean;
}

export interface ElementProps {
  attributes: any;
  children: React.ReactNode;
  element: any;
}

export interface LeafProps {
  attributes: any;
  children: React.ReactNode;
  leaf: any;
}

export interface VersionListProps {
  versions: ContentVersion<DateTime>[];
  onClick: (version: ContentVersion<DateTime>) => void;
  selectedVersionId?: number;
}

export enum SupportedLocales {
  enUS = 'en_US',
  frCA = 'fr_CA',
}
export interface ContentVersion<DateType> {
  id: number;
  locale: string;
  materialVersion: number;
  minorVersion: number;
  effectiveOn: DateType;
  content: TElement[];
  createdBy: string;
  createdOn: DateType;
  deactivatedBy?: string | null;
  deactivatedOn?: DateType | null;
  modifiedBy: string;
  modifiedOn: DateType;
  type: { id: number; name: string };
}

export interface ContentVersionForm {
  locale?: SupportedLocales;
  effectiveOn?: string;
  isMaterial: boolean;
  content: Value;
}
export interface TermsOfUseResponse {
  termsOfUse: ContentVersion<string>[];
}
export interface TermsOfUseResponseSingle {
  termsOfUse: ContentVersion<string>;
}

export interface PrivacyNoticeResponse {
  privacyNotices: ContentVersion<string>[];
}
export interface PrivacyNoticeResponseSingle {
  privacyNotice: ContentVersion<string>;
}
