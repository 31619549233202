export let customerId = '2c02b1fe-1b0b-43ab-990a-88028e7fed91';
export const loadCustomerId = (ccId) => {
  customerId = ccId;
};

export const sortArray = (arr, key) => arr.slice().sort((a, b) => a[key] < b[key] ? -1 : 1);

export const langObjectTranfrom = (lang) => {

  const { languageDescription, code } = lang?.locale;
  const { isDefaultLocale } = lang;
  const isLanguageAndCountryNotSame = languageDescription.indexOf('-');

  const langObject = {
    languageCode: code,
    defaultLocale: isDefaultLocale,
    checked: true,
  }

  if (isLanguageAndCountryNotSame !== -1) {
    langObject.language = languageDescription.substring(
        0,languageDescription.indexOf('-') - 1);
    langObject.country = languageDescription.substring(
        languageDescription.indexOf('-') + 3,
        languageDescription.length - 1);
  } else {
      langObject.language = languageDescription;
      langObject.country = languageDescription;
  }
  return langObject;
}

export const reAssignCoreValues = (data) =>{
  let tempCoreValues = data.data.updateCustomerCoreValue.coreValues.nodes.map((item)=>{
      return {
          name : item.coreValueName,
          isActive : item.status!="inactive",
          editMode : false,
          anchorEls : null,
          id : item.customerCoreValueId
      }
  });
  return tempCoreValues;
}

export const validateEmail = (mail) => (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(mail));

