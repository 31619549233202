import styled from '@emotion/styled';
import { Button, Checkbox, CircularProgress, Grid, MenuItem, Pagination, Select, Skeleton, TextField, Typography } from '@octanner/prism-core';
import React, { useContext, useState } from 'react';
import { LanguageStringsContext } from './../../Contexts/LanguageStringsContext';
import { constraints, numRegex } from './../../utils/constants';
import { defaults } from './../../utils/default';
import { Locale, SearchStringsProps } from './../../utils/types';
import SearchStringsTable from './SearchStringsTable';

const SearchContainer = styled.div`
  margin-top: 24px;
  margin-bottom: 30px;
`

const ButtonGrid = styled(Grid)`
  display: flex;
  align-items: center;
`

const PaginationContainer = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 4px;
  align-self: stretch;
`

const MessageContainer = styled(Grid)`
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 3px;
  background: var(--Gray-25, #FBFBFB);
`
const PlaceHolder = styled(Typography)`
  padding: 24px;
`

const StyledCircularProgress = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  height: 60vh;
`;

const CheckBoxGrid = styled(Grid)`
  display:flex;
  align-items: center;
`

const SearchStrings: React.FC<SearchStringsProps> = ({
  localeLoading,
  locales
}) => {
  const {
    searchBy, stringText, setStringText, searchLocale, setSearchLocale,
    stringId, setStringId, searchData, dataNotFound, searchStringByTextOrId, searchLoading, searchConstraint, setSearchConstraint,
    ignoreCaseInd, setIgnoreCaseInd } = useContext(LanguageStringsContext);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);

  const handleSearch = (pageNumber: number, itemsPerPage: number) => {
    if (searchLoading) {
      return;
    }
    if (pageNumber === 1) {
      if (searchBy === 'string' && !(stringText && stringText.trim().length)) {
        return;
      }
      if (searchBy === 'id' && !stringId) {
        return;
      }
    }
    setPageNumber(pageNumber);
    setItemsPerPage(itemsPerPage)
    searchStringByTextOrId({
      variables: {
        stringId: stringId ? parseInt(stringId) : null,
        stringText,
        searchLocale,
        searchConstraint: searchConstraint,
        ignoreCaseCheckInd: ignoreCaseInd,
        pageInfo: {
          pageNumber: pageNumber,
          pageSize: itemsPerPage
        }
      }
    });
  };

  const onLocaleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchLocale(event.target.value);
  }

  const handleStringIdChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const inputValue = event.target.value;
    if (inputValue === '' || numRegex.test(inputValue)) {
      setStringId(inputValue);
    }
  };

  return (
    <>
      <SearchContainer>
        {searchBy === 'id' && <Grid container spacing={3}>
          <Grid item xs={4.5}>
            <TextField
              value={stringId}
              onChange={handleStringIdChange}
              label={defaults.enter_string_id}
              fullWidth
              onKeyDown={(e) => e.key === 'Enter' && handleSearch(1, itemsPerPage)}
            />
          </Grid>
          <ButtonGrid item xs={3.5}>
            <Button onClick={() => handleSearch(1, itemsPerPage)} disabled={(!stringId || searchLoading)} adminColor>
              {defaults.search}
            </Button>
          </ButtonGrid>
        </Grid>}
        {searchBy === 'string' && <>
          {!localeLoading && locales && locales.length > 0 && <Grid container spacing={3}>
            <Grid item xs={3.5}>
              {locales && locales.length && <Select
                label={defaults.language_string_whose}
                fullWidth
                onChange={onLocaleChange}
                value={searchLocale}>
                {locales.map((locale: Locale) => (
                  <MenuItem key={locale.code} value={locale.code}>
                    {locale.languageDescription}
                  </MenuItem>
                ))}
              </Select>}
            </Grid>
            <Grid item xs={3.5}>
              <TextField
                value={stringText}
                onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => setStringText(event.target.value)}
                label={defaults.string_text}
                fullWidth
                onKeyDown={(e) => e.key === 'Enter' && handleSearch(1, itemsPerPage)}
              />
            </Grid>
            <Grid item xs={3.5}>
              <Select
                label={defaults.constraints}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setSearchConstraint(event.target.value)
                }}
                fullWidth
                value={searchConstraint}>
                {constraints.map((constraint) => (
                  <MenuItem key={constraint.value} value={constraint.value}>
                    {constraint.label}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <CheckBoxGrid item xs={1.5}>
              <Checkbox
                checked={ignoreCaseInd}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setIgnoreCaseInd(event.target.checked)}
                color="primary"
                inputProps={{ 'aria-label': 'ignore case checkbox' }}
              />
              <Typography variant="body2">
                {defaults.ignore_case}
              </Typography>
            </CheckBoxGrid>
            <ButtonGrid item xs={4}>
              <Button disabled={(!(stringText && stringText.trim().length) || searchLoading)}
                onClick={() => handleSearch(1, itemsPerPage)} adminColor>
                {defaults.search}
              </Button>
            </ButtonGrid>
          </Grid>}
          {localeLoading && <Grid container spacing={3}>
            <Grid item xs={4}>
              <Skeleton variant="rectangular" />
            </Grid>
            <Grid item xs={4}>
              <Skeleton variant="rectangular" />
            </Grid>
          </Grid>}
        </>
        }
      </SearchContainer>
      {!searchLoading && searchData?.lsFetchLanguageStringListUsingSearchFilters?.languageStringList?.length > 0 && (
        <>
          <SearchStringsTable />
          {searchData.lsFetchLanguageStringListUsingSearchFilters.pageInfo.totalPageCount > 1 && <PaginationContainer>
            <Pagination
              size='small'
              rowsPerPage
              menuProps={{ value: 'rows per page' }}
              page={pageNumber}
              itemsPerPageOptions={[10, 30, 50]}
              itemsPerPage={itemsPerPage}
              count={searchData.lsFetchLanguageStringListUsingSearchFilters.pageInfo.totalPageCount}
              onChange={(event, page) => handleSearch(page, itemsPerPage)}
              onItemsPerPageChange={(itemsPerPage) => handleSearch(1, itemsPerPage)}
            />
          </PaginationContainer>}
        </>)}
      {!searchLoading && !searchData?.lsFetchLanguageStringListUsingSearchFilters?.languageStringList?.length && <MessageContainer>
        <PlaceHolder color='textSecondary' variant='body1'>
          {
            dataNotFound ? defaults.no_match_data : (
              searchBy === 'id' ? defaults.enter_string_id_to_search : defaults.enter_string
            )
          }
        </PlaceHolder>
      </MessageContainer>}
      {searchLoading && <StyledCircularProgress>
        <CircularProgress />
      </StyledCircularProgress>}
    </>
  );
}

export default SearchStrings;