import { useStyles } from './styles';
import { withPad } from '../../common/BasicPadding';
import { ClientConfig } from '@octanner/cc-customer-config-ui';
import { useHistory, useLocation } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import permissionAuthorizer from '../../utils/auth/PermissionAuthorizer';
import { AppContext } from '../../utils/context/AppContext';
import { useContext } from 'react';
import { CustomerContext } from '../../utils/context/CustomerContext';

const ClientConfiguration = () => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const customerUUID = location.pathname.split('/')[2];
  const { ccAdminEnableComboModel } = useFlags() as {
    ccAdminEnableComboModel: boolean;
  };
  const historyLocationProp = { history, location };
  const authorizer = permissionAuthorizer(
    'PROGRAM_ELIGIBILITY_CUSTOMER_AND_FEATURE'
  );
  const {
    customer: { id },
    isDefault,
  } = useContext(CustomerContext);
  const customerId = isDefault ? undefined : id;
  const enableApplicationsTab = authorizer(useContext(AppContext), customerId);

  return (
    <div className={classes.clientConfigRoot}>
      <ClientConfig
        customerUUID={customerUUID}
        ccAdminEnableComboModel={ccAdminEnableComboModel}
        historyLocationProp={historyLocationProp}
        enableApplicationsTab={enableApplicationsTab}
      />
    </div>
  );
};

export default withPad()(ClientConfiguration);
