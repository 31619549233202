import { useStyles } from './styles';
import { Chip, Autocomplete, TextField } from '@octanner/prism-core';
import { Search } from '@material-ui/icons';
import { useContext, useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { CustomerContext } from '../../utils/context/CustomerContext';
import { useIdentitySearch } from './hooks/identity';
import { IdentityBase, formatName } from './models/Identity';
import { IdentityBaseSelectProps } from './types';
import { MenuItem } from '@mui/material';

const IdentityMultiselect = ({
  onRemove,
  onAdd,
  selectedIdentities,
}: IdentityBaseSelectProps) => {
  const classes = useStyles();
  const {
    customer: { id: customerId },
  } = useContext(CustomerContext);
  const [searchText, setSearchText] = useState<string>('');
  const { data, loading, error } = useIdentitySearch({
    customerId: searchText ? customerId : '',
    name: searchText,
    active: true,
    internalUser: true,
  });

  const [options, setOptions] = useState<IdentityBase[]>([]);
  useEffect(() => {
    const identityOptions = data?.identitySearch.identities || [];
    setOptions(identityOptions);
  }, [data]);

  const handleTextFieldChange = useDebouncedCallback(setSearchText, 500);
  const handleChange = (options: IdentityBase[]) => {
    const existingItems = selectedIdentities;
    const existingIds = existingItems.map((i) => i.id);
    const added = options.find((o) => !existingIds.includes(o.id));
    if (added) {
      onAdd(added);
    }
  };

  const selectedIds = selectedIdentities.map((i) => i.id);
  const filterOptions = (options: IdentityBase[]) =>
    options.filter((o) => !selectedIds.includes(o.id));

  if (error) {
    return <div>Error loading identity groups</div>;
  }

  return (
    <Autocomplete
      multiple
      loading={loading}
      onChange={(_, o) => handleChange(o)}
      options={options}
      getOptionLabel={(identity) => identity.firstName}
      filterOptions={filterOptions}
      filterSelectedOptions
      isOptionEqualToValue={(identity, selectedIdentity) =>
        identity.id === selectedIdentity.id
      }
      value={selectedIdentities}
      renderOption={(props, identity) => (
        <MenuItem
          {...props}
          data-test={`identity-selector:identity:${identity.id}`}
        >
          {formatName(identity as IdentityBase)}
        </MenuItem>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          onChange={(e) => handleTextFieldChange(e.target.value)}
          inputProps={{
            ...params.inputProps,
            'data-test': 'role:identity-selector',
          }}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <div className={classes.adornmentContainer}>
                <Search style={{ fontSize: 28 }} />
                {selectedIdentities.map((identity) => (
                  <Chip
                    className={classes.chip}
                    color="primary"
                    key={identity.id}
                    label={formatName(identity)}
                    onDelete={() => onRemove(identity)}
                    data-test={`role:identity:${identity.id}`}
                  />
                ))}
              </div>
            ),
            endAdornment: null,
          }}
        />
      )}
    />
  );
};
export default IdentityMultiselect;
