import { gql } from '@apollo/client'
import {
  IDENTITY,
  YB_ACRUAL_POINTS_REGION_FRAGMENT,
  YB_CELEBRATION_GROUP_CONFIG,
  YB_PROGRAM_BASE_FRAGMENT,
  YB_PROGRAM_OFFSETS_FRAGMENT,
} from './fragments'

// queries --------------------------------------------------

export const IDENTITY_SEARCH = gql`
  ${IDENTITY}
  query IdentitySearch($identityCondition: IdentityInput!) {
    identitySearch(condition: $identityCondition) {
      identities {
        ...Identity
      }
    }
  }
`

export const GET_BUDGET_GROUP_REGIONS = gql`
  ${YB_ACRUAL_POINTS_REGION_FRAGMENT}
  query getBudgetGroupRegions($input: YbProgramOffsetsByProgramIdInput!) {
    ybProgramOffsetsByProgramId(input: $input) {
      program {
        accrualPointsRegions {
          ...YbAccrualPointsRegion
        }
      }
    }
  }
`

export const GET_PROGRAM_OFFSETS_BY_PROGRAM_ID = gql`
  ${YB_PROGRAM_OFFSETS_FRAGMENT}
  query ybProgramOffsetsByProgramId($programId: UUID!) {
    ybProgramOffsetsByProgramId(input: { programId: $programId }) {
      ...YbProgramOffsets
    }
  }
`

// mutations --------------------------------------------------

const patchInputs = {
  main: '$programConfigInput: YbProgramInput!',
  add: '$addLocalesInput: YbProgramYearbookLocalesInput!',
  remove: '$removeLocalesInput: YbProgramYearbookLocalesInput!',
}

const patchMutations = {
  main: `ybPatchProgramConfigs(input: $programConfigInput) { ...YbProgramBaseFragment }`,
  add: `ybAddProgramYearbookLocales(input: $addLocalesInput) { id }`,
  remove: `ybRemoveProgramYearbookLocales(input: $removeLocalesInput) { yearbookLocales }`,
}

export const buildPatchProgramConfigMutation = (
  isNew: boolean,
  options = { add: false, remove: false }
) => {
  const { add, remove } = options,
    mutations = (
      isNew
        ? [
            patchMutations.main,
            add && patchMutations.add,
            remove && patchMutations.remove,
          ]
        : [
            add && patchMutations.add,
            remove && patchMutations.remove,
            patchMutations.main,
          ]
    ).filter(Boolean),
    inputs = [
      patchInputs.main,
      add && patchInputs.add,
      remove && patchInputs.remove,
    ].filter(Boolean)

  return gql`
    mutation patchProgramConfig(
      ${inputs.join(' ')}
    ) {
      ${mutations.join(' ')}
    }
    ${YB_PROGRAM_BASE_FRAGMENT}
  `
}

export const PATCH_CELEBRATION_CONFIG = gql`
  ${YB_CELEBRATION_GROUP_CONFIG}
  mutation patchConfig($input: YbCelebrationLevelGroupConfigUpdateInput!) {
    ybPatchCelebrationLevelGroupConfigs(input: $input) {
      ...YbCelebrationGroupConfig
    }
  }
`

export const YB_CREATE_ACCRUAL_POINTS_CONFIG = gql`
  ${YB_ACRUAL_POINTS_REGION_FRAGMENT}
  mutation ybCreateAccrualPointsConfig($input: YbCreateAccrualPointsConfig!) {
    ybCreateAccrualPointsConfig(input: $input) {
      ...YbAccrualPointsRegion
    }
  }
`
export const YB_UPDATE_ACCRUAL_POINTS_CONFIG = gql`
  ${YB_ACRUAL_POINTS_REGION_FRAGMENT}
  mutation ybUpdateAccrualPointsConfig($input: YbUpdateAccrualPointsConfig) {
    ybUpdateAccrualPointsConfig(input: $input) {
      ...YbAccrualPointsRegion
    }
  }
`

export const YB_DELETE_ACCRUAL_POINTS_REGION = gql`
  mutation ybDeleteAccrualPointsConfig($input: YbDeleteAccrualPointsConfig!) {
    ybDeleteAccrualPointsConfig(input: $input) {
      id
    }
  }
`

export const PATCH_OFFSETS = gql`
  ${YB_PROGRAM_OFFSETS_FRAGMENT}
  mutation patchOffsets($input: YbProgramOffsetsInput!) {
    ybPatchProgramOffsets(input: $input) {
      ...YbProgramOffsets
    }
  }
`
export const CREATE_DEFAULT_PROGRAM = gql`
  ${YB_PROGRAM_BASE_FRAGMENT}
  mutation createDefaultProgram($input: YbCreateDefaultProgramInput!) {
    ybCreateDefaultProgram(input: $input) {
      ...YbProgramBaseFragment
    }
  }
`

export const UPDATE_CORE_AWARD_LEVELS = gql`
  mutation UpdateCoreAwardLevels($input: UpdateCoreAwardLevelsRequest!) {
    updateCoreAwardLevels(input: $input) {
      awardLevels {
        nodes {
          awardLevelName
          minPoints
          maxPoints
        }
      }
    }
  }
`
