import { gql, MutationUpdaterFn } from '@apollo/client';
import { CUSTOMER_BASE_FRAGMENT } from '../../customer/graphql/customer';
import {
  DeleteRoleResponse,
  DuplicateRoleResponse,
  isDeleteRoleResponse,
  RequireCustomerId,
  RolesResponse,
} from '../models/Role';
import { IDENTITY_BASE_FRAGMENT } from './identity';

export const ROLE_FRAGMENT = gql`
  fragment RoleFragment on Role {
    id
    uuid
    name
  }
`;

export const DELETE_ROLE = gql`
  ${ROLE_FRAGMENT}
  mutation deleteRole($customerId: UUID!, $id: Long!) {
    deleteOneRole(customerId: $customerId, id: $id) {
      ...RoleFragment
    }
  }
`;

export const GET_ROLES = gql`
  ${ROLE_FRAGMENT}
  query getRoles($customerId: UUID!, $page: Int!) {
    roles(customerId: $customerId, page: $page, size: 50) {
      content {
        ...RoleFragment
      }
      total
      totalPages
      last
    }
  }
`;

export const UPDATE_ROLE = gql`
  ${ROLE_FRAGMENT}
  mutation updateRole($customerId: UUID!, $roleName: String!, $roleId: Long!) {
    updateOneRole(
      request: { id: $roleId, customerId: $customerId, name: $roleName }
    ) {
      ...RoleFragment
    }
  }
`;

export const DUPLICATE_ROLE = gql`
  mutation duplicateRole(
    $customerId: UUID!
    $existingRoleId: Long!
    $name: String!
  ) {
    createDuplicateRole(
      customerId: $customerId
      existingRoleId: $existingRoleId
      name: $name
    ) {
      id
      uuid
      name
      customer {
        id
        name
        modifiedOn
      }
    }
  }
`;

export const GET_MAPPING_BY_CUSTOMER = gql`
  ${ROLE_FRAGMENT}
  ${IDENTITY_BASE_FRAGMENT}
  ${CUSTOMER_BASE_FRAGMENT}
  query getMappingByCustomer(
    $customerUuid: UUID!
    $customerId: String!
    $identityId: UUID!
  ) {
    customer(id: $customerId) {
      ...CustomerBaseFragment
    }
    getNonDefaultIdentitiesRolesForCustomerId(
      customerUuid: $customerUuid
      identityUuid: $identityId
    ) {
      identity {
        ...IdentityBaseFragment
      }
      roles {
        ...RoleFragment
      }
    }
  }
`;

export const MAP_USER = gql`
  mutation mapUser($customerId: UUID!, $identityId: UUID!, $roleId: Long!) {
    coupleIdentityRoleAndCustomerAsmt(
      customerAsmt: $customerId
      identityId: $identityId
      roleId: $roleId
    ) {
      id
    }
  }
`;

export const UNMAP_USER = gql`
  mutation unmapUser($customerId: UUID!, $identityId: UUID!, $roleId: Long!) {
    decoupleIdentityRoleAndCustomerAsmt(
      customerAsmt: $customerId
      identityId: $identityId
      roleId: $roleId
    ) {
      id
    }
  }
`;

export const UNMAP_USER_ROLES = gql`
  mutation unmapUser(
    $customerId: UUID!
    $identityId: UUID!
    $roleIds: [Long!]!
  ) {
    decoupleIdentityRolesAndCustomerAsmt(
      customerAsmt: $customerId
      identityId: $identityId
      roleIds: $roleIds
    ) {
      id
    }
  }
`;

export const updateRoleFromCache =
  (
    queryVariables: RequireCustomerId
  ): MutationUpdaterFn<DeleteRoleResponse | DuplicateRoleResponse> =>
  (cache, res) => {
    const data = res?.data;
    if (!data) {
      return;
    }
    const cachedQuery = cache.readQuery<RolesResponse, RequireCustomerId>({
      query: GET_ROLES,
      variables: queryVariables,
    });
    if (!cachedQuery) {
      return;
    }
    const { roles } = cachedQuery;
    // const contenta = roles.content.filter(r => r.uuid !== uuid)
    const content = isDeleteRoleResponse(data)
      ? roles.content.filter((r) => r.uuid !== data.deleteOneRole.uuid)
      : roles.content.concat([data.createDuplicateRole]);
    cache.writeQuery({
      query: GET_ROLES,
      variables: queryVariables,
      data: {
        roles: {
          ...roles,
          content,
        },
      },
    });
  };
