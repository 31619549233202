import { Select, MenuItem, Button } from '@octanner/prism-core';
import { Participants } from '../../types';
import { Flex1, Flex2, MainSection } from './styles';
import { segmentTypeMenu } from '../../../constants';
import BusinessUnitInput from '../../../programEligibilityInputs/BusinessUnitInput';
import CountryInput from '../../../programEligibilityInputs/CountryInput';
import { ParticipationRowProps } from './types';

export const ParticipationRow = ({
  participant,
  index,
  modifiableParticipants,
  setModifiableParticipants,
  handleRemoveEligibility,
}: ParticipationRowProps) => {
  const handleParticipantChange = (key: keyof Participants, value: any) => {
    let prev = modifiableParticipants;
    prev = prev.map((x, i) => (i === index ? { ...x, [key]: value } : x));
    setModifiableParticipants(prev);
  };

  return (
    <MainSection>
      <Flex1>
        <Select
          fullWidth
          value={participant.isIncluded ? 1 : 0}
          onChange={(e) =>
            handleParticipantChange('isIncluded', !!e.target.value)
          }
          label="Include/Exclude"
          inputProps={{
            name: 'isInclude',
          }}
        >
          <MenuItem value={1} data-test={`program:eligibility:include`}>
            Include
          </MenuItem>
          <MenuItem value={0} data-test="program:eligibility:exclude">
            Exclude
          </MenuItem>
        </Select>
      </Flex1>
      <Flex1>
        <Select
          fullWidth
          label="Segment Type"
          value={participant.segmentType}
          onChange={(e) =>
            handleParticipantChange('segmentType', e.target.value)
          }
          inputProps={{
            name: 'isInclude',
          }}
        >
          {segmentTypeMenu.map(({ key, value, test }) => {
            return (
              <MenuItem //@ts-ignore
                value={key}
                key={key}
                data-test={`program:eligibility:segmentType:${test}`}
              >
                {value}
              </MenuItem>
            );
          })}
        </Select>
      </Flex1>
      <Flex2>
        {participant.segmentType === 'businessUnit' && (
          <BusinessUnitInput
            selectedBusinessUnits={participant.businessUnits || []}
            onChange={(val) => handleParticipantChange('businessUnits', val)}
          />
        )}
        {(participant.segmentType === 'homeCountry' ||
          participant.segmentType === 'workCountry') && (
          <CountryInput
            selectedCountries={participant.countries || []}
            onChange={(val) => handleParticipantChange('countries', val)}
          />
        )}
      </Flex2>
      <Button
        variant="text"
        style={{ margin: '8px 0' }}
        onClick={() => handleRemoveEligibility(index)}
      >
        {' '}
        {modifiableParticipants.length > 1 ? 'Remove' : 'Clear'}
      </Button>
    </MainSection>
  );
};
