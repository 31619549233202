import Authorizer from '../utils/auth/Authorizer';

interface BaseLinkItem {
  text: string;
  dataTest: string;
  authorizer?: Authorizer;
  translationId?: string;
  visible?: boolean;
}

export interface InternalLinkItem extends BaseLinkItem {
  to: string;
}

export interface ExternalLinkItem extends BaseLinkItem {
  href: string;
  target: string;
}

export type LinkItem = InternalLinkItem | ExternalLinkItem;

export interface ParentLinkItem extends BaseLinkItem {
  children: LinkItem[];
}

export function isParentLinkItem(object: any): object is ParentLinkItem {
  return 'children' in object;
}

export function isInternalLinkItem(object: any): object is InternalLinkItem {
  return 'to' in object;
}

export function isExternalLinkItem(object: any): object is ExternalLinkItem {
  return 'href' in object;
}

export type AppMenuItem = LinkItem | ParentLinkItem;
export type MenuItems = AppMenuItem[];
