import Box from '@mui/system/Box';
import Button from '@octanner/prism-core/Button';
import { BudgetGroupFormContainer } from '@octanner/yearbook-program-settings';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ArrowTailLeft } from '@octanner/prism-icons';
import { YearbookBudgetGroupsParams } from './types';

const YearbookBudgetGroups: React.FC = () => {
  const history = useHistory(),
    params = useParams<YearbookBudgetGroupsParams>(),
    { customerId, programId, budgetGroupId } = params,
    navigateToProgram = () => {
      history.push(`/customer/${customerId}/programs/${programId}/edit`);
    };

  return (
    <Box sx={{ m: 7, maxWidth: 960 }}>
      <Button
        variant="text"
        onClick={navigateToProgram}
        startIcon={<ArrowTailLeft />}
        sx={{ mb: 3 }}
      >
        Back to program
      </Button>

      <BudgetGroupFormContainer
        onChange={navigateToProgram}
        programId={programId}
        budgetGroupId={budgetGroupId}
      />
    </Box>
  );
};

export default YearbookBudgetGroups;
