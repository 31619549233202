import React,{ useState, useEffect } from 'react';
import { Select, TextField, Typography, Search, MenuItem } from '@octanner/prism-core';
import { regions, clientTypes } from './constants'
import { SearchOptionCard } from '@octanner/prism-core/Search';
import styled from '@emotion/styled';
import { defaults } from './utilities/defaults';

const SelectContainer = styled.div`
    margin-bottom: 16px;
`;

const StyledSearch = styled(Search)`
    margin-bottom: 16px;
`;

const StyledTextField = styled(TextField)`
    margin-bottom: 16px;  
`;



const defaultSalesOrg = {
    coreCountry:{},
        eligibleSalesOrgs: [
          {
            currency : {},
            salesOrgCode : '',
            salesOrgCompanyCode : '',
            salesOrgDistribution: '',
            salesOrgName : '',
            salesOrgDivision : '',
          }
        ] || '',
        recommendedSalesOrg: {}
  }

const ClientInfo = ({
    salesOrgList,
    searchSalesOrgList,
    setSearchSalesOrgList,
    clientInfo,
    setClientInfo,
    clientInfoError,
    setClientInfoError,
    setShowAlert,
}) => {
    const [searchSalesOrgText, setSearchSalesOrgText] = useState('');
    const { countryWithEligibleSalesOrgs = []} = salesOrgList?.allCountriesWithEligibleSalesOrgs || {};
    useEffect(() => {
        const filteredSalesOrgList = countryWithEligibleSalesOrgs.filter((salesOrg => salesOrg?.coreCountry?.countryName?.toLowerCase()?.includes(searchSalesOrgText?.toLowerCase())));
        filteredSalesOrgList.sort((countryA, countryB) => {
            if(countryA?.coreCountry?.countryName?.toLowerCase() < countryB?.coreCountry?.countryName?.toLowerCase()) { return -1; }
            if(countryA?.coreCountry?.countryName?.toLowerCase() > countryB?.coreCountry?.countryName?.toLowerCase()) { return 1; }
            return 0;
        })
        setSearchSalesOrgList(filteredSalesOrgList);
    },[searchSalesOrgText])

    const handleInputChange =  (value, key) => {
        setClientInfoError({
            ...clientInfoError,
            [key + 'Err']: false
        })
        if(key === 'salesOrgCountry') {
            if(value === '') {
                setClientInfo({
                    ...clientInfo,
                    [key]:defaultSalesOrg,
                    salesOrg: ''
                })
                return;
            }
            setClientInfo({
                ...clientInfo,
                [key]: value,
                salesOrg: value?.recommendedSalesOrg?.salesOrgCode || ''
            })
            return;
        }
        setClientInfo({
            ...clientInfo,
            [key]: value
        });
        setShowAlert(false);
    }

    const handleDisplaySalesOrg = (salesOrgCode,salesOrgName) => {
       return `${salesOrgCode} - ${salesOrgName}`
    }

    return (
        <div style={{ width: '592px', marginBottom:'120px'}}>
            <Typography variant="h3" style={{marginTop: '40px', marginBottom: '32px'}}>Client Information</Typography>
            <StyledTextField
                label={defaults.company_name}
                maxLength={35}
                displayCount
                fullWidth
                value={clientInfo.clientName || ''}
                onChange={(e) => handleInputChange(e.target.value, 'clientName')}
                error={clientInfoError.clientNameErr}
                helperText={clientInfo.clientName.length > 35 ? defaults.too_many_characters : clientInfoError.clientNameErr && defaults.this_field_is_required}
            />
            <SelectContainer>
            <Select
                label={defaults.select_pii_region}
                fullWidth
                onChange={(e) => handleInputChange(e.target.value, 'piiRegion')}
                value={clientInfo.piiRegion || ''}
                error={clientInfoError.piiRegionErr}
                helperText={clientInfoError.piiRegionErr && defaults.this_field_is_required}
            >
                {regions.map(({ name, value }) => (
              <MenuItem
                key={value}
                value={value}
              >
                {name}
              </MenuItem>
            ))}

            </Select>
            </SelectContainer>
            <SelectContainer>
            <Select
                label={defaults.select_client_type}
                id="select-people-single"
                fullWidth
                onChange={(e) => handleInputChange(e.target.value, 'clientType')}
                value={clientInfo.clientType || ''}
                error={clientInfoError.clientTypeErr}
                helperText={clientInfoError.clientTypeErr && defaults.this_field_is_required}
            >
                {clientTypes.map(({ name, value }) => (
                    <MenuItem key={value} value={value}>
                        {name}
                    </MenuItem>
                ))}
            </Select>
            </SelectContainer>
            <StyledSearch
                placeholder={defaults.select_primary_country}
                id="people-single-search"
                options={searchSalesOrgList}
                onInputChange={(e, value) => {setSearchSalesOrgText(value);
                }}
                renderOption={(props, option: any) => (
                    <SearchOptionCard
                        {...props}
                        key={option?.coreCountry?.countryName}
                        title={option?.coreCountry?.countryName}
                    />
                )}
                onChange={(e, value, details) =>{if(details === 'createOption')return; handleInputChange(value, 'salesOrgCountry')}}
                getOptionLabel={(option: any) => option?.coreCountry?.countryName || ''}
                multiple={false}
                error={clientInfoError.salesOrgCountryErr}
                helperText={clientInfoError.salesOrgCountryErr && defaults.this_field_is_required}
                value={clientInfo.salesOrgCountry || ''}
                defaultValue={clientInfo.salesOrgCountry || ''}

            />
            {
                clientInfo.salesOrgCountry?.eligibleSalesOrgs[0].salesOrgCode && (
                    <Select
                    label={defaults.select_primary_sales_org}
                    style={{marginBottom: '8px'}}
                    fullWidth
                    defaultValue={
                        clientInfo.salesOrgCountry?.recommendedSalesOrg?.salesOrgCode
                    }
                    onChange={(e) => handleInputChange(e.target.value, 'salesOrg')}
                    value={clientInfo.salesOrg || ''}
                    error={clientInfoError.salesOrgErr}
                    helperText={clientInfoError.salesOrgErr && defaults.this_field_is_required}
                >
                    {
                        clientInfo.salesOrgCountry?.eligibleSalesOrgs?.map(({
                            salesOrgCode,
                            salesOrgName,
                        }) => (
                            <MenuItem
                                key={salesOrgCode}
                                value={salesOrgCode}
                            >
                                {clientInfo.salesOrgCountry?.recommendedSalesOrg?.salesOrgCode ===
                    salesOrgCode ? (
                        <>
                          {handleDisplaySalesOrg(salesOrgCode, salesOrgName)}
                          <span style={{
                            color: '#ababae',
                            fontSize: '14px',
                            fontWeight: 500,
                           }}>
                            {`\u00A0`} recommended
                          </span>
                        </>
                      ) : (
                        <>{handleDisplaySalesOrg(salesOrgCode, salesOrgName)}</>
                      )}
                        </MenuItem>
                        ))
                    }
                </Select>

                )
            }

        </div>
    )
}

export default ClientInfo;
