import { gql } from '@apollo/client';

export const CORE_COUNTRY_FRAGMENT = gql`
  fragment CoreCountryFragment on CoreCountry {
    id
    countryName
    iso2Code
  }
`;

export const GET_COUNTRIES_BY_CUSTOMER = gql`
  ${CORE_COUNTRY_FRAGMENT}
  query getCountriesByCustomer($customerId: UUID!) {
    countriesByCustomer(input: { customerId: $customerId }) {
      countries {
        nodes {
          ...CoreCountryFragment
        }
      }
    }
  }
`;
