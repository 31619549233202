import { useLazyQuery, useMutation } from '@apollo/client';
import styled from '@emotion/styled';
import {
  Alert,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow, Typography
} from '@octanner/prism-core';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { LanguageStringsContext } from '../../../Contexts/LanguageStringsContext';
import { defaults } from '../../../utils/default';
import { FILTER_BY_STRING_ID, INSERT_CORRECTION } from '../../../utils/query';
import { EditTranslationsProps, FetchLanguageString, Locale, Translation } from '../../../utils/types';
import SelectLanguage from '../../Common/SelectLanguage';
import Footer from '../../Common/Footer';
import EditEnglishTranslationModal from './Modals/EditEnglishTranslationModal';
import RequestTranslationModal from './Modals/RequestTranslationModal';
import StringDetails from './StringDetails';
import TranslationRow from './TranslationRow';

const StyledCircularProgress = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  height: 100vh;
`;

const ControlContainer = styled.div`
  margin-top: 24px;
  width: 35%;
`;

const LanguageChip = styled(Chip)`
  margin-top: 12px;
  margin-right: 10px;
`;

const AlertBox = styled(Grid)`
  margin-top: 12px;
`

const EditTranslations: React.FC<EditTranslationsProps> = ({ locales }) => {

  const { stringId } = useParams();
  const [showInfoMessage, setShowInfoMessage] = useState<boolean>(true);
  const [requestedLocales, setRequestedLocales] = useState<Partial<Translation>[]>([]);
  const [requestAll, setRequestAll] = useState<boolean>(false);
  const [openEditEnglishTranslationModal, setOpenEditEnglishTranslationModal] = useState<boolean>(false);
  const [englishRequestedString, setEnglishRequestedString] = useState<string>('');
  const [showRequesTranslationtModal, setShowRequesTranslationtModal] = useState<boolean>(false);
  const [saveAllLoading, setSaveAllLoading] = useState<boolean>(false);
  const [stringTranslationList, setStringTranslationList] = useState<Partial<Translation>[]>([]);
  const [filteredStringTranslationList, setFilteredStringTranslationList] = useState<Partial<Translation>[]>([]);
  const [selectedString, setSelectedString] = useState<FetchLanguageString>();
  const [allUnTranslatedLocales, setAllUntranslatedLocales] = useState<Partial<Translation>[]>([]);
  const [selectedLocales, setSelectedLocales] = useState<Locale[]>([]);
  const [getTranslationsByStringId, { loading: translationDataLoading, data: translationsByIdData, refetch: refetchById }] = useLazyQuery(FILTER_BY_STRING_ID, {
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      setRefetchInd(false);
    }
  });

  const {
    setRefetchInd, showFooter, setShowFooter, setMessageText,
  } = useContext(LanguageStringsContext);

  const history = useHistory();

  useEffect(() => {
    if (stringId) {
      getTranslationsByStringId({
        variables: {
          stringId: parseInt(stringId)
        }
      }).then((response) => {
        if (!response || response.error || response.data.lsFetchLanguageStringUsingStringID === null) {
          setMessageText('errorMessage', defaults.fetch_string_data_error)
          history.push('/language-string');
        }
      });
    }
  }, [stringId, getTranslationsByStringId]);

  useEffect(() => {
    if (translationsByIdData) {
      setSelectedString(translationsByIdData.lsFetchLanguageStringUsingStringID);
      const translationList = translationsByIdData.lsFetchLanguageStringUsingStringID?.translationList.map(
        ({ translationString, localeDescription, translationStatus, locale }) => {
          return {
            text: translationString ? translationString : '',
            originalText: translationString ? translationString : '',
            locale,
            localeDescription,
            translationStatus,
            editMode: false,
            checked: false,
            loading: false
          };
        }
      )
      setStringTranslationList(translationList);
      setFilteredStringTranslationList(translationList)
    }
  }, [translationsByIdData]);

  useEffect(() => {
    if (stringTranslationList && stringTranslationList.length) {
      const allUnTranslatedLocalesObj =
        stringTranslationList.filter((item) => (item.translationStatus === 'UNTRANSLATED')).map(
          (item) => ({ locale: item.locale, localeDescription: item.localeDescription }));
      setAllUntranslatedLocales(allUnTranslatedLocalesObj)
    }
  }, [stringTranslationList]);

  const [upsertTranslation, { loading: upsertTranslationLoading }] =
    useMutation(INSERT_CORRECTION, {
      onError: () => {
        setMessageText('errorMessage', defaults.update_error);
      }
    });

  const handleSearch = (selectedLocalesFromModal: Locale[]) => {
    if (selectedLocalesFromModal.length) {
      setSelectedLocales(selectedLocalesFromModal)
      const filteredLocales: Partial<Translation>[] = stringTranslationList.filter(translation =>
        selectedLocalesFromModal.some(locale => translation.locale === locale.code)
      );
      setFilteredStringTranslationList(filteredLocales);
    }
    else {
      setFilteredStringTranslationList(stringTranslationList);
      setSelectedLocales([]);
    }
  };

  const handleSaveAll = () => {
    if (saveAllLoading) {
      return;
    }
    setSaveAllLoading(true);
    const editModeLocales = filteredStringTranslationList.filter((item) => item.editMode && item.locale !== 'en-US');
    const translationStringInput = editModeLocales.map((item) => {
      return {
        locale: item.locale,
        stringText: item.text
      }
    });
    if (translationStringInput && translationStringInput.length > 0) {
      upsertTranslation({
        variables: {
          input: {
            deleteExistingDataInd: false,
            stringId: parseInt(stringId),
            translationStringInput
          }
        }
      }).then((response) => {
        if (response.errors) {
          setMessageText('errorMessage', defaults.update_error);
        } else {
          setSaveAllLoading(false);
          const englishLocaleIndex = filteredStringTranslationList.findIndex((item) => item.locale === 'en-US');
          if (englishLocaleIndex > -1 && filteredStringTranslationList[englishLocaleIndex].editMode) {
            setSaveAllLoading(false);
            setOpenEditEnglishTranslationModal(true);
            const englishTranslationString = filteredStringTranslationList[englishLocaleIndex].text;
            setEnglishRequestedString(englishTranslationString);
            return;
          } else {
            setMessageText('editSuccessMessage', defaults.update_success);
            setRefetchInd(true);
          }
        }
      })
    } else {
      const englishLocaleIndex = filteredStringTranslationList.findIndex((item) => item.locale === 'en-US');
      setSaveAllLoading(false);
      setOpenEditEnglishTranslationModal(true);
      const englishTranslationString = filteredStringTranslationList[englishLocaleIndex].text;
      setEnglishRequestedString(englishTranslationString);
    }
  }

  const handleAllCheckBox = (checked: boolean) => {
    if (checked) {
      setRequestAll(true);
      setShowFooter(true);
      setRequestedLocales(allUnTranslatedLocales);
    } else {
      setShowFooter(false);
      setRequestAll(false);
      setRequestedLocales([]);
    }
    setFilteredStringTranslationList((prevList) => {
      return prevList.map((item) => {
        const matchingLocale = allUnTranslatedLocales.find((unTranslatedLocale) => unTranslatedLocale.locale === item.locale);
        if (matchingLocale) {
          item.checked = checked;
        }
        return item;
      });
    });
  }

  const onRemoveLocale = (locale: string): void => {
    setSelectedLocales((prevLocales: Locale[]) => {
      const newSelectedLocales = prevLocales.filter(localeObj => (localeObj.code !== locale));
      setFilteredStringTranslationList(filteredStringTranslationList.filter(localeObj => localeObj.locale !== locale));
      if (!newSelectedLocales.length) {
        setFilteredStringTranslationList(stringTranslationList);
      }
      return newSelectedLocales;
    })
  }

  return (
    <>
      {!translationDataLoading && stringTranslationList && stringTranslationList.length > 0 && <>
        <RequestTranslationModal
          showRequestTranslationModal={showRequesTranslationtModal}
          setShowRequestTranslationModal={setShowRequesTranslationtModal}
          requestedLocales={requestedLocales}
          selectedString={selectedString}
        />
        <EditEnglishTranslationModal
          openEditEnglishTranslationModal={openEditEnglishTranslationModal}
          setOpenEditEnglishTranslationModal={setOpenEditEnglishTranslationModal}
          selectedString={{ ...selectedString, translationList: stringTranslationList }}
          englishRequestedString={englishRequestedString}
        />
        <StringDetails
          selectedString={selectedString}
          showRequesTranslationtModal={showRequesTranslationtModal}
          openEditEnglishTranslationModal={openEditEnglishTranslationModal}
          refetchById={refetchById}
        />
        {locales && locales.length > 0 && <ControlContainer>
          <Typography variant='h3'>
            {defaults.filter_languages}
          </Typography>
          <SelectLanguage selectedLocales={selectedLocales}
            setSelectedLocales={handleSearch}
            placeholderName={defaults.filter_languages}
            addButtonName={defaults.search}
            locales={locales} optionalInd={false}></SelectLanguage>
        </ControlContainer>}
        {!!(selectedLocales && selectedLocales.length) &&
          selectedLocales.map((locale: Locale) => (
            <LanguageChip key={locale.code} label={locale.languageDescription} onDelete={() => onRemoveLocale(locale.code)}></LanguageChip>
          ))
        }
        {showInfoMessage && <AlertBox container>
          <Grid item xs={6}>
            <Alert
              onClose={() => setShowInfoMessage(false)}
              severity="info">
              {defaults.request_translation_info_message}
            </Alert>
          </Grid>
        </AlertBox>}
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Checkbox
                  checked={requestAll}
                  onChange={(e) => handleAllCheckBox(e.target.checked)}
                  disabled={(!stringTranslationList || !stringTranslationList.some((item) => item.translationStatus === 'UNTRANSLATED'))}
                />
              </TableCell>
              <TableCell>
                <Typography variant='h4'>{defaults.locale_desc}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant='h4'>{defaults.locale_code}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant='h4'>{defaults.status}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant='h4'>{defaults.language_string}</Typography>
              </TableCell>
              <TableCell>
                <Button disabled={!filteredStringTranslationList.some((item) => item.editMode) || !filteredStringTranslationList
                  .filter((item) => item.editMode)
                  .every((item) => item.text && item.text.length > 0 && item.text.trim() !== item.originalText.trim() && item.text?.trim()?.length <= 4000)}
                  onClick={() => handleSaveAll()}
                  loading={saveAllLoading}
                  variant='text'>{defaults.save_all}</Button>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredStringTranslationList.map(
              (translation) => {
                return (
                  <TranslationRow
                    key={translation.locale}
                    {...translation}
                    setFilteredStringTranslationList={setFilteredStringTranslationList}
                    setRequestedLocales={setRequestedLocales}
                    setRequestAll={setRequestAll}
                    allUnTranslatedLocales={allUnTranslatedLocales}
                    setStringTranslationList={setStringTranslationList}
                    setOpenEditEnglishTranslationModal={setOpenEditEnglishTranslationModal}
                    setEnglishRequestedString={setEnglishRequestedString}
                    stringId={stringId}
                    filteredStringTranslationList={filteredStringTranslationList}
                    upsertTranslation={upsertTranslation}
                    upsertTranslationLoading={upsertTranslationLoading}
                    stringTranslationList={stringTranslationList}
                    saveAllLoading={saveAllLoading}
                  />
                );
              }
            )}
          </TableBody>
        </Table>
        {showFooter && <Footer
          cancelActionText={defaults.cancel}
          saveActionText={defaults.request_translation}
          cancelAction={() => handleAllCheckBox(false)}
          cancelButtonDisabled={false}
          saveAction={() => setShowRequesTranslationtModal(true)} loading={false} saveButtonDisabled={false} />
        }
      </>}
      {(translationDataLoading) && <StyledCircularProgress>
        <CircularProgress />
      </StyledCircularProgress>}
    </>
  );
}

export default EditTranslations;