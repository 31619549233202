import { Table, TableBody } from '@mui/material';
import { MenuItem, Switch, TableRow } from '@octanner/prism-core';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Fragment, useState } from 'react';
import {
  AWARD_SHIP_OPTIONS_PHASE1,
  AWARD_SHIP_OPTIONS_PHASE2,
} from './constants';
import {
  FlexCenterEvenlyDiv,
  MainText,
  PaddingZero,
  RecommendedSalesOrg,
  SalesOrgWidth,
  SelectW,
  SpecificWidthCell,
  SpecificWidthSelect,
  TableCellW,
  TypographyWP,
} from './styles';
import {
  ModifiedSalesOrgAndCountryGroupByCountry,
  SalesOrgProps,
} from './types';

const OrgRow = ({
  row,
  handleValueUpdate,
  bankData,
  bankDataIndex,
}: SalesOrgProps) => {
  const { assignedCountry, group } = row;

  const { ccAdminSalesOrgAndCountries } = useFlags();
  const [rowEnabled, setRowEnabled] = useState(assignedCountry?.enabled);

  const handleRowChange = (
    newRow: ModifiedSalesOrgAndCountryGroupByCountry,
    index: number
  ) => {
    const newBankCountry = bankData.pointBankSalesOrgesByCountry.map((x, i) =>
      i === index ? newRow : x
    );
    const isEnabled = newRow.assignedCountry.enabled;

    if (isEnabled) {
      const updateBankCountry = newBankCountry.map((x, i) =>
        i === index
          ? {
              ...x,
              assignedCountry: {
                ...x.assignedCountry,
              },
              group: newRow.group.map((option) => ({
                ...option,
                coreCountry: {
                  ...option.coreCountry,
                  enabled: !rowEnabled,
                },
              })),
            }
          : x
      );
      handleValueUpdate('pointBankSalesOrgesByCountry', updateBankCountry);
    } else {
      handleValueUpdate('pointBankSalesOrgesByCountry', newBankCountry);
    }
  };

  const handleAwardShippingLocation = (e) => {
    const { value } = e.target;
    const updatedAssignedCountry = {
      ...assignedCountry,
      value: value as string,
    };

    const updatedGroup = group.map((x) => ({
      ...x,
      coreCountry: {
        ...x.coreCountry,
      },
    }));

    const newRow = {
      assignedCountry: updatedAssignedCountry,
      group: updatedGroup,
    };

    handleRowChange(newRow, bankDataIndex);
  };

  const handleSwitch = () => {
    setRowEnabled(!rowEnabled);
    const updatedAssignedCountry = {
      ...assignedCountry,
      enabled: !rowEnabled,
    };

    const updatedGroup = group.map((x) => ({
      ...x,
      coreCountry: {
        ...x.coreCountry,
        enabled: !rowEnabled,
      },
    }));
    const newRow = {
      assignedCountry: updatedAssignedCountry,
      group: updatedGroup,
    };
    handleRowChange(newRow, bankDataIndex);
  };

  const handleSalesOrgValue = (e) => {
    const updatedAssignedCountry = {
      ...assignedCountry,
      selectedSalesOrg: e.target.value,
    };

    const updatedGroup = group.map((x) => ({
      ...x,
      coreCountry: {
        ...x.coreCountry,
      },
    }));

    const newRow = {
      assignedCountry: updatedAssignedCountry,
      group: updatedGroup,
    };

    handleRowChange(newRow, bankDataIndex);
  };

  return (
    <Fragment>
      <Table>
        <TableBody>
          <TableRow>
            <SpecificWidthCell>
              <FlexCenterEvenlyDiv>
                <Switch checked={rowEnabled} onChange={handleSwitch} />
                <SalesOrgWidth>
                  <MainText>{assignedCountry.countryName}</MainText>
                </SalesOrgWidth>
                <div>
                  {group.length >= 2 ? (
                    <SelectW
                      disabled={!rowEnabled}
                      label="Sales org"
                      value={assignedCountry.selectedSalesOrg}
                      onChange={handleSalesOrgValue}
                    >
                      {group.map(({ assignedSalesOrg }, i) => (
                        <MenuItem
                          key={assignedSalesOrg.salesOrgCode}
                          value={
                            assignedSalesOrg.salesOrgCode as unknown as number
                          }
                        >
                          {i === 0 ? (
                            <>
                              {`${assignedSalesOrg.salesOrgCode} - ${assignedSalesOrg.salesOrgName}`}
                              <RecommendedSalesOrg>
                                {`\u00A0`}
                                {'recommended'}
                              </RecommendedSalesOrg>
                            </>
                          ) : (
                            `${assignedSalesOrg.salesOrgCode} - ${assignedSalesOrg.salesOrgName}`
                          )}
                        </MenuItem>
                      ))}
                    </SelectW>
                  ) : (
                    <div>
                      <TypographyWP>
                        {`${group[0].assignedSalesOrg.salesOrgCode} - ${group[0].assignedSalesOrg.salesOrgName}`}
                      </TypographyWP>
                    </div>
                  )}
                </div>
              </FlexCenterEvenlyDiv>
            </SpecificWidthCell>
            <PaddingZero>
              <SpecificWidthSelect
                label="Where will the awards ship?"
                disabled={!rowEnabled}
                value={assignedCountry.value}
                onChange={handleAwardShippingLocation}
              >
                {ccAdminSalesOrgAndCountries
                  ? AWARD_SHIP_OPTIONS_PHASE2.map(({ value, text }) => (
                      <MenuItem key={value} value={value}>
                        {text}
                      </MenuItem>
                    ))
                  : AWARD_SHIP_OPTIONS_PHASE1.map(({ value, text }) => (
                      <MenuItem key={value} value={value}>
                        {text}
                      </MenuItem>
                    ))}
              </SpecificWidthSelect>
            </PaddingZero>
            <TableCellW>{}</TableCellW>
          </TableRow>
        </TableBody>
      </Table>
    </Fragment>
  );
};

export default OrgRow;
