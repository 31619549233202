import { Checkbox, FormControlLabel, Switch } from '@octanner/prism-core';
import { HorizontalLine } from '../../../../styles';
import { BoldBlack } from '../../styles';
import {
  SectionProps,
  SettingsEnabledType,
  SocialWallSettings,
} from '../../types';
import { AlignedTextBox } from '../awardLevels/styles';
import { PostsSubTitle, PostsTitle, RowContainer } from './styles';
import {
  useHideAwardLevelInBanner,
  useHideAwardLevelInTitle,
  useHideBusinessUnit,
  useHideMilestoneAwardLevel,
  useHideRecognitionAwardLevel,
  useHideRecognitionMessage,
  useShowAwardLevelInBanner,
  useShowAwardLevelInTitle,
  useShowBusinessUnit,
  useShowMilestoneAwardLevel,
  useShowRecognitionAwardLevel,
  useShowRecognitionMessage,
} from '../../hooks/posts/posts';
import { PostDataNames, PostDataTerms } from '../../models/socialWall';
import { ChangeEvent, useEffect, useState } from 'react';

const PostSection = ({ data }: SectionProps) => {
  const getEnabled = (key: PostDataNames) =>
    data?.posts.find((p) => p.name === key)?.enabled;
  const businessUnitEnabled = getEnabled(PostDataNames.BUSINESSUNIT);
  const awardLevelEnabled = getEnabled(PostDataNames.RECOGNITIONAWARDLEVEL);
  const milestoneAwardEnabled = getEnabled(PostDataNames.MILESTONEAWARD);
  const recognitionMessageEnabled = getEnabled(
    PostDataNames.RECOGNITIONMESSAGE
  );
  const [settingEnabled, setSettingEnabled] = useState<SettingsEnabledType>({
    [PostDataNames.BUSINESSUNIT]: businessUnitEnabled,
    [PostDataNames.RECOGNITIONAWARDLEVEL]: awardLevelEnabled,
    [PostDataNames.MILESTONEAWARD]: milestoneAwardEnabled,
    [PostDataNames.RECOGNITIONMESSAGE]: recognitionMessageEnabled,
  });

  useEffect(() => {
    setSettingEnabled({
      [PostDataNames.BUSINESSUNIT]: businessUnitEnabled,
      [PostDataNames.RECOGNITIONAWARDLEVEL]: awardLevelEnabled,
      [PostDataNames.MILESTONEAWARD]: milestoneAwardEnabled,
      [PostDataNames.RECOGNITIONMESSAGE]: recognitionMessageEnabled,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.posts]);

  const [showRecognitionAwardLevel] = useShowRecognitionAwardLevel(
    data.customerUuid
  );
  const [hideRecognitionAwardLevel] = useHideRecognitionAwardLevel(
    data.customerUuid
  );
  const [showAwardLevelInBanner] = useShowAwardLevelInBanner(data.customerUuid);
  const [hideAwardLevelInBanner] = useHideAwardLevelInBanner(data.customerUuid);
  const [showAwardLevelInTitle] = useShowAwardLevelInTitle(data.customerUuid);
  const [hideAwardLevelInTitle] = useHideAwardLevelInTitle(data.customerUuid);
  const [showMilestoneAwardLevel] = useShowMilestoneAwardLevel(
    data.customerUuid
  );
  const [hideMilestoneAwardLevel] = useHideMilestoneAwardLevel(
    data.customerUuid
  );
  const [showRecognitionMessage] = useShowRecognitionMessage(data.customerUuid);
  const [hideRecognitionMessage] = useHideRecognitionMessage(data.customerUuid);
  const [showBusinessUnit] = useShowBusinessUnit(data.customerUuid);
  const [hideBusinessUnit] = useHideBusinessUnit(data.customerUuid);

  const customerUuidVariable = {
    variables: {
      customerUuid: data.customerUuid,
    },
  };

  const handlePostCheckBox = (e: ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    switch (e.target.name) {
      case PostDataTerms.BANNER:
        if (checked) {
          showAwardLevelInBanner(customerUuidVariable);
        } else {
          hideAwardLevelInBanner(customerUuidVariable);
        }
        break;
      case PostDataTerms.TITLE:
        if (checked) {
          showAwardLevelInTitle(customerUuidVariable);
        } else {
          hideAwardLevelInTitle(customerUuidVariable);
        }
        break;
    }
  };

  const handleUpdate = (
    key: keyof SettingsEnabledType,
    value: SocialWallSettings
  ) => {
    setSettingEnabled((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleSwitchChange = (e: ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    switch (e.target.name) {
      case PostDataNames.BUSINESSUNIT:
        if (checked) {
          showBusinessUnit(customerUuidVariable);
        } else {
          hideBusinessUnit(customerUuidVariable);
        }
        handleUpdate(PostDataNames.BUSINESSUNIT, checked);
        break;
      case PostDataNames.RECOGNITIONAWARDLEVEL:
        if (checked) {
          showRecognitionAwardLevel(customerUuidVariable);
        } else {
          hideRecognitionAwardLevel(customerUuidVariable);
        }
        handleUpdate(PostDataNames.RECOGNITIONAWARDLEVEL, checked);
        break;
      case PostDataNames.MILESTONEAWARD:
        if (checked) {
          showMilestoneAwardLevel(customerUuidVariable);
        } else {
          hideMilestoneAwardLevel(customerUuidVariable);
        }
        handleUpdate(PostDataNames.MILESTONEAWARD, checked);
        break;
      case PostDataNames.RECOGNITIONMESSAGE:
        if (checked) {
          showRecognitionMessage(customerUuidVariable);
        } else {
          hideRecognitionMessage(customerUuidVariable);
        }
        handleUpdate(PostDataNames.RECOGNITIONMESSAGE, checked);
        break;
    }
  };

  useEffect(() => {
    if (
      milestoneAwardEnabled &&
      milestoneAwardEnabled !== settingEnabled[PostDataNames.MILESTONEAWARD]
    ) {
      handleUpdate(PostDataNames.MILESTONEAWARD, milestoneAwardEnabled);
    }
    if (
      recognitionMessageEnabled &&
      recognitionMessageEnabled !==
        settingEnabled[PostDataNames.RECOGNITIONMESSAGE]
    ) {
      handleUpdate(PostDataNames.RECOGNITIONMESSAGE, recognitionMessageEnabled);
    }
    //eslint-disable-next-line
  }, [milestoneAwardEnabled, recognitionMessageEnabled]);

  return (
    <div>
      <div>
        <PostsTitle variant="h2">Posts</PostsTitle>
        <PostsSubTitle>
          Configure what information is displayed in posts on the social feed.
        </PostsSubTitle>
        <HorizontalLine />
      </div>
      {data?.posts.map((post, postIndex) => {
        return (
          <div key={postIndex}>
            <RowContainer>
              <Switch
                checked={settingEnabled[post.name]}
                onChange={(e) => handleSwitchChange(e)}
                name={post.name}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
                disabled={!data.enabled}
                data-testid={`social-wall:posts:enable-${post.name}`}
              />
              <BoldBlack>{post.name}</BoldBlack>
            </RowContainer>
            {postIndex === 1 && (
              <>
                {post?.options?.map((postOption, postOptionIndex) => (
                  <AlignedTextBox key={postOptionIndex}>
                    <div>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={postOption.selected}
                            disabled={
                              !settingEnabled[post.name] || !data.enabled
                            }
                            name={postOption.term}
                            onChange={(e) => handlePostCheckBox(e)}
                          />
                        }
                        data-testid={`social-wall:posts:enable-check-${postOption.term}`}
                        label={postOption.term}
                      />
                    </div>
                  </AlignedTextBox>
                ))}
              </>
            )}
            <HorizontalLine />
          </div>
        );
      })}
    </div>
  );
};

export default PostSection;
