import { useLazyQuery } from '@apollo/client';
import React, { createContext, PropsWithChildren, useState } from 'react';
import { constraints } from './../utils/constants';
import { defaults } from './../utils/default';
import { SEARCH_BY_STRING_OR_ID } from './../utils/query';
import { AlertMessage, LanguageStringsContextType, SearchData } from './../utils/types';

const LanguageStringsContext = createContext<LanguageStringsContextType>({
  searchBy: "id",
  setSearchBy: () => { },
  refetchInd: false,
  setRefetchInd: () => { },
  stringText: '',
  setStringText: () => { },
  searchLocale: 'en-US',
  setSearchLocale: () => { },
  stringId: '',
  setStringId: () => { },
  searchData: null,
  setSearchData: () => { },
  showFooter: false,
  setShowFooter: () => { },
  dataNotFound: false,
  setDataNotFound: () => { },
  searchStringByTextOrId: () => { },
  searchLoading: false,
  searchConstraint: '',
  setSearchConstraint: () => { },
  ignoreCaseInd: false,
  setIgnoreCaseInd: () => { },
  messages: [],
  setMessageText: () => { },
});

const LanguageStringsProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const [searchBy, setSearchBy] = useState<string>("id");
  const [refetchInd, setRefetchInd] = useState<boolean>(false);
  const [stringText, setStringText] = useState<string>('');
  const [searchLocale, setSearchLocale] = useState<string>('en-US');
  const [stringId, setStringId] = useState<string>('');
  const [searchData, setSearchData] = useState<SearchData>(null);
  const [showFooter, setShowFooter] = useState<boolean>(false);
  const [dataNotFound, setDataNotFound] = useState<boolean>(false);
  const [searchConstraint, setSearchConstraint] = useState<string>(constraints[0].value);
  const [ignoreCaseInd, setIgnoreCaseInd] = useState<boolean>(true);
  const [searchStringByTextOrId, { loading: searchLoading }] =
    useLazyQuery(SEARCH_BY_STRING_OR_ID, {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (data?.lsFetchLanguageStringListUsingSearchFilters?.languageStringList?.length === 0) {
          setDataNotFound(true);
        }
        setSearchData(data);
      },
      onError: () => {
        setMessageText('errorMessage', defaults.fetch_string_data_error);
      }
    });
  const [messages, setMessages] = useState<AlertMessage[]>([
    { key: 'errorMessage', text: '', severity: 'error', gridSize: 6 },
    { key: 'createSuccessMessage', text: '', severity: 'success', gridSize: 4 },
    { key: 'createWarningMessage', text: '', severity: 'warning', gridSize: 6 },
    { key: 'editSuccessMessage', text: '', severity: 'success', gridSize: 3 },
    { key: 'requestSuccessMessage', text: '', severity: 'success', gridSize: 9 }
  ]);

  const setMessageText = (messageKey: string, text: string) => {
    setMessages(prevMessages =>
      prevMessages.map(message =>
        message.key === messageKey ? { ...message, text: text } : message
      )
    );
  }

  return (
    <LanguageStringsContext.Provider value={{
      searchBy, setSearchBy,
      refetchInd, setRefetchInd,
      stringText, setStringText,
      searchLocale, setSearchLocale,
      stringId, setStringId,
      searchData, setSearchData,
      showFooter, setShowFooter,
      dataNotFound, setDataNotFound,
      searchStringByTextOrId, searchLoading,
      searchConstraint, setSearchConstraint,
      ignoreCaseInd, setIgnoreCaseInd,
      messages, setMessageText,
    }}>
      {children}
    </LanguageStringsContext.Provider>
  );
};

export { LanguageStringsContext, LanguageStringsProvider };

