import { MouseEvent, useContext, useEffect, useState } from 'react';
import { Button, Modal } from '@octanner/prism-core';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import BasicPadding from '../../../common/BasicPadding';
import GeneralBankInfo from '../sections/generalInfo';
import SalesOrgBankInfo from '../sections/salesOrg';
import { CustomerContext } from '../../../utils/context/CustomerContext';
import {
  LoadingWrapper,
  MBAlert,
  ModalSubTitle,
  NewBankTypography,
  SaveBankButton,
} from './styles';
import {
  BankKeys,
  InitialBankData,
  BankPayload,
  ModifiedPointBankSalesOrges,
} from './types';
import { INITIAL_BANK_STATE } from './constants';
import { CloseSharp } from '@material-ui/icons';
import { ArrowLeft } from '@octanner/prism-icons';
import { useCountrySalesOrgGroupedByCountry, useCreateBank } from './graphql';
import {
  AwardShippingValuesPhase1,
  AwardShippingValuesPhase2,
  ModifiedSalesOrgAndCountryGroupByCountry,
  ValueOf,
} from '../sections/salesOrg/types';
import InvoiceTextFieldsBankInfo from '../sections/invoiceTextFields';
import { useFlags } from 'launchdarkly-react-client-sdk';

const CreateBank = () => {
  const { ccAdminInvoiceTextFields } = useFlags();
  const history = useHistory();
  const [newBankData, setNewBankData] =
    useState<InitialBankData>(INITIAL_BANK_STATE);

  const [pageLoading, setPageLoading] = useState<boolean>(true);
  const [hasCountry, setHasCountry] = useState(true);
  const [hasName, setHasName] = useState(true);
  const [submitted, setSubmitted] = useState(false);
  const [genericError, setGenericError] = useState(false);
  const [open, setOpen] = useState(false);
  const [isValueChanged, setIsValueChanged] = useState(false);
  const [checkDates, setCheckDates] = useState(false);
  const handleModalClose = () => {
    setOpen(false);
  };

  const {
    customer: { id: customerId },
  } = useContext(CustomerContext);

  const { data: groupByCountryData, loading: groupByCountryLoading } =
    useCountrySalesOrgGroupedByCountry({
      customerId,
    });

  const [createBank] = useCreateBank();
  useEffect(() => {
    // ? Check here for complete data!
    setPageLoading(false);
  }, []);

  const handleSalesOrgSort = (
    salesOrgMap: ModifiedSalesOrgAndCountryGroupByCountry[]
  ) => {
    return salesOrgMap.sort((a, b) => {
      const nameA = a.assignedCountry.countryName.toUpperCase();
      const nameB = b.assignedCountry.countryName.toUpperCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
  };

  const customerSalesOrgGroupedByCountry = handleSalesOrgSort(
    groupByCountryData?.customerCountrySalesOrgMapsGroupByCountry
      ?.customerSalesOrgGroupByCountry ?? []
  );

  useEffect(() => {
    if (!groupByCountryLoading && customerSalesOrgGroupedByCountry) {
      const updatedData = customerSalesOrgGroupedByCountry.map((entry) => {
        const salesOrgCode = entry.group[0].assignedSalesOrg.salesOrgCode;
        const { assignedCountry, group } = entry;
        const selectedSalesOrg = salesOrgCode;
        const updatedAssignedCountry = {
          ...assignedCountry,
          enabled: false,
          selectedSalesOrg,
          value: 'VARIES_PER_USER' as
            | AwardShippingValuesPhase1
            | AwardShippingValuesPhase2,
        };

        const updatedGroup = group.map(({ coreCountry, ...rest }) => ({
          coreCountry: {
            ...coreCountry,
          },
          ...rest,
        }));

        return {
          ...entry,
          assignedCountry: updatedAssignedCountry,
          group: updatedGroup,
        };
      });
      setNewBankData((prev) => ({
        ...prev,
        pointBankSalesOrgesByCountry: updatedData,
      }));
    }
  }, [customerSalesOrgGroupedByCountry, groupByCountryLoading]);

  const handleValueUpdate = (key: BankKeys, value: ValueOf<BankKeys>) => {
    setNewBankData((prev) => ({ ...prev, [key]: value }));
    setIsValueChanged(!!value);
  };

  const modifySalesOrgData = () => {
    const modifiedData: ModifiedPointBankSalesOrges[] = [];

    newBankData.pointBankSalesOrgesByCountry.forEach((entry) => {
      const { assignedCountry, group } = entry;

      if (assignedCountry.enabled) {
        const { selectedSalesOrg, value: shippingLocationType } =
          assignedCountry;

        group.forEach(({ customerCountrySalesOrgId, assignedSalesOrg }) => {
          if (selectedSalesOrg === assignedSalesOrg.salesOrgCode) {
            modifiedData.push({
              customerCountrySalesOrgId: customerCountrySalesOrgId,
              shippingLocationType: shippingLocationType as
                | AwardShippingValuesPhase2
                | AwardShippingValuesPhase1,
            });
          }
        });
      }
    });
    return modifiedData;
  };

  const resetState = () => {
    // ? State reset
    setCheckDates(false);
    setSubmitted(true);
    setHasName(true);
    setHasCountry(true);
    setGenericError(false);
  };

  const handleSubmit = (e: MouseEvent) => {
    e.preventDefault();
    resetState();
    const modifiedPointBankSalesOrges = modifySalesOrgData();
    const bankNameLength = newBankData.pointBankName.length;
    const salesOrgCount = modifiedPointBankSalesOrges.length;
    const pointsExpiryType =
      newBankData?.pointExpiryConfiguration?.pointExpiryType;
    const startDate =
      newBankData.pointExpiryConfiguration.fixedDates?.[0]?.startDateTsz ?? '';
    const endDate =
      newBankData.pointExpiryConfiguration.fixedDates?.[0]?.endDateTsz ?? '';
    const expirationDate =
      newBankData.pointExpiryConfiguration.fixedDates?.[0]?.expirationDateTsz ??
      '';

    if (!bankNameLength) {
      setHasName(false);
    }
    if (!salesOrgCount) {
      setHasCountry(false);
    }

    if (pointsExpiryType === 'FIXED_DATE') {
      if (!startDate || !endDate || !expirationDate) {
        setCheckDates(true);
      }
    }

    if (bankNameLength && salesOrgCount && !checkDates) {
      const {
        billingConfig,
        pointBankSalesOrgesByCountry,
        ...newBankDataWithoutBillingConfig
      } = newBankData;
      const { billingLocationType, billingLocationRecipientType } =
        billingConfig || {};
      const payload: BankPayload = {
        request: {
          ...newBankDataWithoutBillingConfig,
          customerUuid: customerId,
          pointBankSalesOrges: modifiedPointBankSalesOrges,
          billingLocationRecipientType,
          billingLocationType,
          invoiceFieldConfig: {
            invoiceField1:
              newBankData.invoiceFieldConfig?.invoiceFieldEntity1?.code || null,
            invoiceField2:
              newBankData.invoiceFieldConfig?.invoiceFieldEntity2?.code || null,
            invoiceField3:
              newBankData.invoiceFieldConfig?.invoiceFieldEntity3?.code || null,
            invoiceField4:
              newBankData.invoiceFieldConfig?.invoiceFieldEntity4?.code || null,
          },
        },
      };

      if (newBankData.billingConfig?.billingLocation.locationUuid) {
        payload.request.billingLocationUuid =
          newBankData.billingConfig.billingLocation.locationUuid;
      }

      if (pointsExpiryType === 'FIXED_DATE') {
        payload.request.pointExpiryConfiguration.monthlyRollingExpirationNum =
          null;
      } else if (pointsExpiryType === 'MONTHLY_ROLLING_EXPIRATION') {
        payload.request.pointExpiryConfiguration.fixedDates = null;
      } else {
        payload.request.pointExpiryConfiguration.fixedDates = null;
        payload.request.pointExpiryConfiguration.monthlyRollingExpirationNum =
          null;
      }

      createBank({ variables: payload })
        .then(({ data }) => {
          history.push(
            `/customer/${customerId}/banks/${data.corePtMgmtCreateNewBank.information.pointBankUuid}/edit`,
            { successMessage: true }
          );
        })
        .catch((e) => setGenericError(true));
    }
  };

  const handleModalDiscardClick = () => {
    history.push(`/customer/${customerId}/banks`);
    setOpen(true);
  };

  const handleCancel = () => {
    history.goBack();
  };

  if (pageLoading) {
    return <LoadingWrapper />;
  }
  return (
    <BasicPadding padding="sm">
      <Button
        variant="text"
        data-test="new-bank:back"
        //@ts-ignore prism issue!
        underline="none"
        startIcon={<ArrowLeft />}
        component={isValueChanged ? 'button' : RouterLink}
        to={`/customer/${customerId}/banks`}
        onClick={() => {
          if (isValueChanged) {
            setOpen(true);
          }
        }}
      >
        View All Banks
      </Button>
      <Modal
        actions={
          <>
            <Button onClick={handleModalDiscardClick}>Discard</Button>
            <Button onClick={handleModalClose} color="secondary">
              Cancel
            </Button>
          </>
        }
        id="collapse-Modal"
        onClose={handleModalClose}
        open={open}
        title="You have unsaved changes."
      >
        <ModalSubTitle>
          Going away from this page will discard all progress creating this
          bank.
        </ModalSubTitle>
      </Modal>

      <NewBankTypography variant="h1">Add Bank</NewBankTypography>
      {!hasName && (
        <MBAlert
          severity="error"
          action={<CloseSharp onClick={() => setHasName(true)} />}
        >
          Bank name is required information.
        </MBAlert>
      )}
      {!hasCountry && (
        <MBAlert
          severity="error"
          action={<CloseSharp onClick={() => setHasCountry(true)} />}
        >
          There are no countries included in this bank.
        </MBAlert>
      )}
      {genericError && (
        <MBAlert
          severity="error"
          action={<CloseSharp onClick={() => setGenericError(false)} />}
        >
          An error has occured. Please try again later.
        </MBAlert>
      )}
      {checkDates && (
        <MBAlert
          severity="error"
          action={<CloseSharp onClick={() => setCheckDates(false)} />}
        >
          There are no date ranges selected.
        </MBAlert>
      )}
      <GeneralBankInfo
        type="add"
        bankData={newBankData}
        handleValueUpdate={handleValueUpdate}
        submitted={submitted}
        checkDates={checkDates}
      />
      {ccAdminInvoiceTextFields && (
        <InvoiceTextFieldsBankInfo
          type="add"
          bankData={newBankData}
          handleValueUpdate={handleValueUpdate}
        />
      )}
      <SalesOrgBankInfo
        type="add"
        bankData={newBankData}
        handleValueUpdate={handleValueUpdate}
        submitted={submitted}
        error={!hasCountry}
      />
      <SaveBankButton adminColor onClick={(event) => handleSubmit(event)}>
        Save Bank
      </SaveBankButton>
      <Button
        color="secondary"
        onClick={() => (isValueChanged ? setOpen(true) : handleCancel())}
      >
        Cancel
      </Button>
    </BasicPadding>
  );
};

export default CreateBank;
