import React from 'react';
import styled from '@emotion/styled';

import AdditionalProgramSettings from './AdditionalProgramSettings/AdditionalProgramSettings';
import AwardList from './Awards/AwardList';
import ErrorBoundary from './utils/error-boundary';
import {
  LaunchDarklyClient,
  FeatureFlagProvider,
} from '@octanner/prism-utilities';
const Container = styled.div`
  box-sizing: border-box;

  * {
    box-sizing: inherit;
  }
`;

const App: React.FC<Props> = ({
  className,
  customerId,
  programId,
  launchDarklyClientId,
}) => {
  const featureFlagClient = React.useMemo(
    () => new LaunchDarklyClient(launchDarklyClientId),
    [launchDarklyClientId],
  );

  return (
    <Container className={className}>
      <FeatureFlagProvider client={featureFlagClient}>
        <ErrorBoundary>
          <AdditionalProgramSettings programId={programId} />
          <AwardList customerId={customerId} programId={programId} />
        </ErrorBoundary>
      </FeatureFlagProvider>
    </Container>
  );
};

export interface Props {
  className?: string;
  customerId: string;
  programId: string;
  launchDarklyClientId: string;
}

export default App;
