import { gql } from '@apollo/client';
import { CUSTOMER_BASE_FRAGMENT } from '../../customer/graphql/customer';

export const IDENTITY_BASE_FRAGMENT = gql`
  fragment IdentityBaseFragment on Identity {
    firstName
    lastName
    id
  }
`;

export const IDENTITY_FRAGMENT = gql`
  ${IDENTITY_BASE_FRAGMENT}
  fragment IdentityFragment on Identity {
    ...IdentityBaseFragment
    email
    uniqueId
    customerId
    employeeSecurityRole
    language
  }
`;

export const IDENTITY_WITH_CUSTOMER_FRAGMENT = gql`
  ${IDENTITY_FRAGMENT}
  ${CUSTOMER_BASE_FRAGMENT}
  fragment IdentityWithCustomerFragment on Identity {
    ...IdentityFragment
    customer {
      ...CustomerBaseFragment
    }
  }
`;

export const GET_IDENTITY_GROUPS_BY_ROLE = gql`
  query identityGroupsByRole(
    $customerId: UUID!
    $roleUuid: UUID!
    $page: Int!
    $size: Int!
  ) {
    identityGroupsByRole(
      customerId: $customerId
      roleId: $roleUuid
      page: $page
      size: $size
    ) {
      content {
        name: identityGroupName
        id: identityGroupUuid
      }
      last
      total
      totalPages
    }
  }
`;

export const IDENTITIES_BY_ROLE = gql`
  query identitiesByRole(
    $customerId: UUID!
    $roleUuid: UUID!
    $page: Int!
    $size: Int!
  ) {
    identitiesByRole(
      customerId: $customerId
      roleUuid: $roleUuid
      page: $page
      size: $size
    ) {
      content {
        id
        firstName
        lastName
      }
      last
    }
  }
`;

export const SEARCH_IDENTITY_GROUPS = gql`
  query searchIdentityGroups(
    $clause: String!
    $customerId: UUID!
    $returnAmount: Int
  ) {
    searchIdentityGroups(
      clause: $clause
      customerId: $customerId
      returnAmount: $returnAmount
    ) {
      name: identityGroupName
      id: identityGroupUuid
    }
  }
`;

export const IDENTITY_SEARCH = gql`
  ${IDENTITY_WITH_CUSTOMER_FRAGMENT}
  query identitySearch($condition: IdentityInput!) {
    identitySearch(condition: $condition) {
      identities {
        ...IdentityWithCustomerFragment
      }
      page
      size
      pagesCount
      recordsCount
      totalRecordsCount
    }
  }
`;

export const ADD_IDENTITY_GROUP = gql`
  mutation addIdentityGroup(
    $customerId: UUID!
    $identityGroupId: UUID!
    $roleUuid: UUID!
  ) {
    coupleIdentityGroupAndRole(
      customerId: $customerId
      identityGroupId: $identityGroupId
      roleId: $roleUuid
    ) {
      id
      identityGroup {
        name: identityGroupName
        id: identityGroupUuid
      }
    }
  }
`;

export const REMOVE_IDENTITY_GROUP = gql`
  mutation removeIdentityGroup(
    $customerId: UUID!
    $identityGroupId: UUID!
    $roleUuid: UUID!
  ) {
    decoupleIdentityGroupAndRole(
      customerId: $customerId
      identityGroupId: $identityGroupId
      roleId: $roleUuid
    ) {
      id
      identityGroup {
        name: identityGroupName
        id: identityGroupUuid
      }
    }
  }
`;

export const ADD_IDENTITY = gql`
  mutation addIdentity($identityId: UUID!, $roleId: Long!) {
    coupleIdentityAndRole(identityId: $identityId, roleId: $roleId) {
      identity {
        id
        firstName
        lastName
      }
    }
  }
`;

export const REMOVE_IDENTITY = gql`
  mutation removeIdentity($identityId: UUID!, $roleId: Long!) {
    decoupleIdentityAndRole(identityId: $identityId, roleId: $roleId) {
      identity {
        id
        firstName
        lastName
      }
    }
  }
`;

export const CURRENT_IDENTITY = gql`
  ${IDENTITY_WITH_CUSTOMER_FRAGMENT}
  query currentIdentity {
    currentIdentity {
      ...IdentityWithCustomerFragment
    }
  }
`;
