import { useLazyQuery } from '@apollo/client';
import React, { createContext, PropsWithChildren, useState } from 'react';
import { MATERIAL_DESC_USING_SEARCH_FILTER } from '../utils/query';
import { AlertMessage, MaterialDescriptionListPayload, MaterialStringsContextType } from '../utils/types';

const MaterialStringsContext = createContext<MaterialStringsContextType>({
  searchBy: "mmId",
  setSearchBy: () => { },
  searchText: '',
  setSearchText: () => { },
  searchLocale: 'en-US',
  setSearchLocale: () => { },
  mmId: '',
  setMmId: () => { },
  searchData: null,
  setSearchData: () => { },
  showFooter: false,
  setShowFooter: () => { },
  dataNotFound: false,
  setDataNotFound: () => { },
  openEditEnglishDescriptionModal: true,
  setOpenEditEnglishDescriptionModal: () => { },
  materialDescriptionLoading: false,
  messages: [],
  getMaterialDescriptionByFilters: () => { },
  setMessageText: () => { },
});

const MaterialStringsContextProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const [searchBy, setSearchBy] = useState<string>("mmId");
  const [searchText, setSearchText] = useState<string>('');
  const [searchLocale, setSearchLocale] = useState<string>('en-US');
  const [mmId, setMmId] = useState<string>('');
  const [searchData, setSearchData] = useState<MaterialDescriptionListPayload>(null);
  const [showFooter, setShowFooter] = useState<boolean>(false);
  const [dataNotFound, setDataNotFound] = useState<boolean>(false);
  const [openEditEnglishDescriptionModal, setOpenEditEnglishDescriptionModal] = useState<boolean>(false);

  const [messages, setMessages] = useState<Partial<AlertMessage>[]>([
    { key: 'errorMessage', text: '', severity: 'error', gridSize: 6 },
    { key: 'editSuccessMessage', text: '', severity: 'success', gridSize: 3 },
    { key: 'requestSuccessMessage', text: '', severity: 'success', gridSize: 9 }
  ]);

  const setMessageText = (messageKey: string, text: string) => {
    setMessages(prevMessages =>
      prevMessages.map(message =>
        message.key === messageKey ? { ...message, text: text } : message
      )
    );
  }

  const [getMaterialDescriptionByFilters, { loading: materialDescriptionLoading }] =
    useLazyQuery(MATERIAL_DESC_USING_SEARCH_FILTER, {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!data?.lsFetchMaterialDescriptionListUsingSearchFilter?.materialDescriptionList?.length) {
          setDataNotFound(true)
        }
        setSearchData(data);
      }
    });

  return (
    <MaterialStringsContext.Provider value={{
      searchBy, setSearchBy,
      searchText, setSearchText,
      searchLocale, setSearchLocale,
      mmId, setMmId,
      searchData, setSearchData,
      showFooter, setShowFooter,
      dataNotFound, setDataNotFound,
      messages, setMessageText, materialDescriptionLoading,
      getMaterialDescriptionByFilters,
      openEditEnglishDescriptionModal, setOpenEditEnglishDescriptionModal
    }}>
      {children}
    </MaterialStringsContext.Provider>
  );
};

export { MaterialStringsContextProvider, MaterialStringsContext };
