import { useLazyQuery } from "@apollo/client";
import styled from "@emotion/styled";
import { Button, CircularProgress, Grid, Modal, Pagination, Search, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from '@octanner/prism-core';
import React, { useContext, useEffect, useState } from "react";
import { JobManagementContext } from "../../../Contexts/JobManagementContext";
import { numRegex, translationCategories } from "../../../utils/constants";
import { defaults } from "../../../utils/default";
import { FETCH_JOB_DETAILS } from "../../../utils/query";
import { ViewJobModalProps } from "../../../utils/types";

const ButtonGrid = styled(Grid)`
  display: flex;
  align-items: center;
`;

const SearchGrid = styled(Grid)`
  margin-top: 24px;
`;

const StyledCircularProgress = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  height: 50vh;
`;

const ModalContent = styled.div`
  padding-left: 48px;
  padding-right: 48px;
`

const ViewJobTable = styled(Table)`
  margin-top: 24px;
`;

const PaginationContainer = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 4px;
  align-self: stretch;
`;

const IdCell = styled(TableCell)`
  width: 15%;
  verticalAlign: 'middle',
`;

const RequestedByCell = styled(TableCell)`
  width: 15%;
  verticalAlign: middle,
`;

const NoDataBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60vh;
`

const ViewJobModal: React.FC<ViewJobModalProps> = ({
  showViewJobModal,
  setShowViewJobModal,
  translationCategory,
  jobNumber
}) => {

  const [stringId, setStringId] = useState<string>('');
  const [mmId, setMmId] = useState<string>('');
  const [jobDetails, setJobDetails] = useState(null);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [jobSearched, setJobSearched] = useState<boolean>(true);
  const { enter_mm_id, enter_string_id, job_details, search, requested_by,
    sap_part_number, string_Id, english_description, rows_per_page, no_match_data } = defaults;
  const { setMessageText } = useContext(JobManagementContext);

  const viewStringDetails = (id: string) => {
    let url = `${window.location.href.split('/translation-request')[0].split('/job-management')[0]}/`;
    if (translationCategory === translationCategories.LANGUAGE_STRING.value) {
      url += `language-string/edit/${parseInt(id)}?type=jobManagement`;
    } else if (translationCategory === translationCategories.CUSTOM_TEXT.value) {
      url += `custom-text/edit/${parseInt(id)}?type=jobManagement`;
    } else {
      url += `material-description/edit/${parseInt(id)}?type=jobManagement`;
    }
    window.open(url, "_blank");
  }

  const [fetchJobDetails, { loading }] = useLazyQuery(FETCH_JOB_DETAILS, {
    onCompleted: (data) => {
      if (data?.lsFetchStringDetailsForJob?.untranslatedStringList) {
        setJobDetails(data);
      }
    },
    onError: () => {
      setMessageText('errorMessage', defaults.fetch_job_management_error);
      setShowViewJobModal(false);
    }
  });

  useEffect(() => {
    if (showViewJobModal) {
      handleFetch(1, 10);
    }
  }, [showViewJobModal]);

  useEffect(() => {
    if (mmId === '' && stringId === '' && jobSearched) {
      handleFetch(1, 10);
      setJobSearched(false);
    }
  }, [mmId, stringId])

  const handleFetch = (pageNumber: number, itemsPerPage: number) => {
    if (loading) {
      return;
    }
    setPageNumber(pageNumber);
    setItemsPerPage(itemsPerPage);
    fetchJobDetails({
      variables: {
        translationCategory,
        jobNumber: jobNumber,
        mmId: parseInt(mmId),
        stringId: parseInt(stringId),
        pageInfo: {
          pageNumber: pageNumber,
          pageSize: itemsPerPage
        }
      }
    });
    if (mmId.trim().length > 0 || stringId.trim().length > 0) {
      setJobSearched(true);
    }
  }

  const handleSearchIdChange = (inputValue: string) => {
    if (inputValue === '' || numRegex.test(inputValue)) {
      if (translationCategory === translationCategories.MATERIAL_DESCRIPTION.value) {
        setMmId(inputValue);
        setStringId('');
      } else {
        setMmId('');
        setStringId(inputValue);
      }
    }
  };

  return (
    <Modal
      actions={<></>}
      id='view-job-modal'
      title={job_details}
      maxWidth='xl'
      open={showViewJobModal}
      onClose={() => setShowViewJobModal(false)}>
      <ModalContent>
        <SearchGrid container spacing={3}>
          <Grid item xs={11}>
            <Search
              renderTags={null}
              getOptionLabel={null}
              inputValue={translationCategory === translationCategories.MATERIAL_DESCRIPTION.value ? mmId : stringId}
              options={[]}
              disableClearable={true}
              disabled={loading}
              onInputChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => handleSearchIdChange(event.target.value)}
              placeholder={(translationCategory === translationCategories.MATERIAL_DESCRIPTION.value ?
                enter_mm_id : enter_string_id)} />
          </Grid>
          <ButtonGrid item xs={1}>
            <Button disabled={loading || (!mmId && !stringId)} adminColor onClick={() => handleFetch(1, 10)}>
              {search}
            </Button>
          </ButtonGrid>
        </SearchGrid>
        {!loading && jobDetails?.lsFetchStringDetailsForJob?.untranslatedStringList.length > 0 && <>
          <ViewJobTable>
            <TableHead>
              <TableRow>
                <RequestedByCell>
                  <Typography variant='h4'>{requested_by}</Typography>
                </RequestedByCell>
                <IdCell>
                  <Typography variant='h4'>{(translationCategory === translationCategories.MATERIAL_DESCRIPTION.value ?
                    sap_part_number : string_Id)}</Typography>
                </IdCell>
                <TableCell>
                  <Typography variant='h4'>{english_description}</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {jobDetails.lsFetchStringDetailsForJob.untranslatedStringList.map(({ stringId, sapPartNumber, text, requestedByName, sourceText }, index) => (
                <TableRow key={index}>
                  <RequestedByCell>
                    <Typography>{requestedByName || '-'}</Typography>
                  </RequestedByCell>
                  <IdCell>
                    <Button variant="text" onClick={() => viewStringDetails(translationCategory === translationCategories.MATERIAL_DESCRIPTION.value ? sapPartNumber : stringId)}>
                      {(translationCategory === translationCategories.MATERIAL_DESCRIPTION.value ? sapPartNumber : stringId)}
                    </Button>
                  </IdCell>
                  <TableCell>
                    <TextField fullWidth value={(translationCategory === translationCategories.MATERIAL_DESCRIPTION.value ? sourceText : text)} InputProps={{ readOnly: true }} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </ViewJobTable>
          {jobDetails.lsFetchStringDetailsForJob.pageInfo.totalItemCount > 10 && <PaginationContainer>
            <Pagination
              size='small'
              rowsPerPage
              menuProps={{ value: rows_per_page }}
              page={pageNumber}
              itemsPerPageOptions={[10, 30, 50]}
              itemsPerPage={itemsPerPage}
              count={jobDetails.lsFetchStringDetailsForJob.pageInfo.totalPageCount}
              onChange={(event, page) => handleFetch(page, itemsPerPage)}
              onItemsPerPageChange={(itemsPerPage) => handleFetch(1, itemsPerPage)}
            />
          </PaginationContainer>}
        </>}
        {!loading && !jobDetails?.lsFetchStringDetailsForJob?.untranslatedStringList?.length && <NoDataBox>
          <Typography color="textSecondary">{no_match_data}</Typography>
        </NoDataBox>}
      </ModalContent>
      {loading && <StyledCircularProgress>
        <CircularProgress />
      </StyledCircularProgress>}
    </Modal>
  );
}

export default ViewJobModal;