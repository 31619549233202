import { DateTime } from 'luxon';

export interface FormValue<V = string> {
  value: V;
  error: string;
  changed: boolean;
}

export const updateFormValue = <V = string>(
  value: V,
  error?: string,
  intial?: boolean
): FormValue<V> => {
  const changed = typeof intial === 'undefined' ? true : intial;
  return {
    value: value,
    error: error || '',
    changed,
  };
};

export const formatDate = (date: Date) => {
  //@ts-ignore
  const formatter = new Intl.DateTimeFormat('en-GB', { dateStyle: 'medium' });
  return formatter.format(date);
};

export const formatDateTime = (date: DateTime, includeTz = false) => {
  const options: object = {
    hour: 'numeric',
    minute: 'numeric',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour12: true,
    ...(includeTz ? { timeZoneName: 'short' } : {}),
  };

  return date.toLocal().toLocaleString(options);
};

export const formatOffset = (date?: number): string => {
  if (!date) {
    return '';
  }
  const dateObj = new Date(date);
  return formatDate(dateObj);
};

export const isEndOfTime = (val: string): boolean => {
  const offset = Date.parse(val);
  if (isNaN(offset)) {
    return false;
  }
  const date = new Date(offset);
  return date.getFullYear() === 9999;
};

export const convertNativeDateStringToDate = (
  value: string | null,
  zone?: string
): DateTime | undefined => {
  if (!value) {
    return;
  }
  const dateTimeIso = DateTime.fromISO(value);
  const { year, month, day } = dateTimeIso;
  const dateTime = DateTime.fromObject(
    {
      year,
      month,
      day,
    },
    { zone }
  );
  return dateTime;
};

export function listToText(
  values: string[],
  pluralText: string,
  maxChar = 100,
  maxItems = 3
) {
  if (maxChar <= 3) {
    throw new Error('maxChar must be greater than 3');
  }
  if (maxItems <= 1) {
    throw new Error('maxItems must be greater than 1');
  }

  if (!values.length) {
    return '';
  }

  let result = values[0];
  let currentItem = 1;

  if (result.length > maxChar) {
    const remainLen = values.length - currentItem;
    const remainText = remainLen ? `, +${remainLen} ${pluralText}` : '';
    return `${result.substring(0, maxChar - 3)}...${remainText}`;
  }

  while (currentItem < maxItems) {
    if (values.length <= currentItem) {
      // not enough items in the list to continue
      break;
    }

    const tempResult = `${result}, ${values[currentItem]}`;

    if (tempResult.length > maxChar) {
      break;
    }

    result = tempResult;
    currentItem += 1;
  }

  const remainLen = values.length - currentItem;
  const remainText = remainLen ? `, +${remainLen} ${pluralText}` : '';
  return result + remainText;
}

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export type Order = 'asc' | 'desc';

export function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
