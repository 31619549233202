import { useContext, useEffect } from 'react';
import { CustomerContext } from '../../../utils/context/CustomerContext';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Tag,
  Typography,
  CircularProgress as Loader,
} from '@octanner/prism-core';
import { useGetCustomer } from '../../customer/hooks/customer';

import { useStyles } from '../styles';
import { RECENT_CLIENT_HEADERS, CLIENT_STATUS } from '../constants';
import { CustomerBase } from '../../customer/models/Customer';
import { getCustomerQueries } from '../graphql/customer';
import { useQuery } from '@apollo/client';

const RecentClients = ({
  setCurrentImpersonateClient,
  id,
  updateRecentClientsList,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const { recent } = useContext(CustomerContext);

  const { loading } = useGetCustomer(id || '', true);

  //eslint-disable-next-line
  useEffect(() => {}, [JSON.stringify(recent), loading]);

  const rowClickFunction = (row: CustomerBase) => {
    const { id, customerStatus: status } = row;
    updateRecentClientsList(row);
    history.push(
      `/customer/${id}/${
        status?.toLowerCase() === 'active'
          ? 'client-summary'
          : 'client-summary/edit'
      }`
    );
  };

  let recentClients = [...recent];
  let query = getCustomerQueries(1);
  const customerVariables: any = {};
  if (recentClients.length > 0) {
    recentClients =
      recentClients.length > 10 ? recentClients.slice(0, 10) : recentClients;
    const variables = recentClients.map((a) => a.id).toString();
    const myArray = variables.split(',');
    myArray.forEach((arr, index) => {
      customerVariables[`customer${index + 1}Id`] = arr;
    });
    query = getCustomerQueries(recentClients.length);
  }
  const { data, loading: recentLoading } = useQuery(query, {
    variables: customerVariables,
    skip: recentClients.length === 0,
    fetchPolicy: 'network-only',
  });

  return (
    <div className={classes.recentClientsWrapper}>
      <div className={classes.recentClientsTitle}>
        {t('recent-clients', 'Recent Clients')}
      </div>
      {recent.length > 0 ? (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {RECENT_CLIENT_HEADERS.map(({ key, name }) => (
                  <TableCell key={key} className={classes.clientTableTitle}>
                    {t(key, name)}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {recentLoading ? (
              <tr
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100px',
                  width: '96%',
                  position: 'absolute',
                }}
              >
                <Loader />
              </tr>
            ) : (
              <TableBody>
                {data &&
                  Object.values(data)
                    .map((a: any) => a)
                    .map((row: any, i) => (
                      <TableRow
                        key={row.id}
                        className={classes.recentClientsTableRow}
                      >
                        <TableCell onClick={() => rowClickFunction(row)}>
                          {row.name}
                        </TableCell>
                        <TableCell onClick={() => rowClickFunction(row)}>
                          {row.stpNumber || 'N/A'}
                        </TableCell>
                        <TableCell onClick={() => rowClickFunction(row)}>
                          {row.id}
                        </TableCell>
                        <TableCell onClick={() => rowClickFunction(row)}>
                          <Tag
                            className={classes.customerStatusTag}
                            variant={
                              row?.customerStatus?.toLowerCase() === 'active'
                                ? 'success'
                                : 'info'
                            }
                          >
                            {CLIENT_STATUS.find(
                              (status) =>
                                status.key === row.customerStatus.toLowerCase()
                            )?.name || 'N/A'}
                          </Tag>
                        </TableCell>
                        <TableCell>
                          {row.customerUsage &&
                            row.customerUsage.charAt(0).toUpperCase() +
                              row.customerUsage.slice(1)}
                        </TableCell>
                        <TableCell className={classes.impersonateCell}>
                          <Button
                            variant="text"
                            onClick={() => {
                              setCurrentImpersonateClient(row);
                              history.push('/impersonate');
                            }}
                          >
                            {t('impersonate', 'Impersonate')}
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      ) : (
        <Typography>
          {t(
            'no-recent-clients',
            'No recent clients to view. Search clients to view'
          )}
        </Typography>
      )}
    </div>
  );
};

export default RecentClients;
