import { ClientList } from './types';
import { MenuItems } from '../../navigation/types';
import xcaAuthorizer from '../../utils/auth/XcaAuthorizer';
import permissionAuthorizer from '../../utils/auth/PermissionAuthorizer';

export const ClientListRows: ClientList[] = [
  {
    key: 'name',
    name: 'Client Name',
    align: 'left',
  },
  {
    key: 'stpNumber',
    name: 'STP Number',
    align: 'left',
  },
  {
    key: 'coreId',
    name: 'Client Core ID',
    align: 'left',
  },

  {
    key: 'rep',
    name: 'OC Tanner Rep.',
    align: 'right',
  },
  {
    key: 'role',
    name: 'Roles',
    align: 'center',
  },
  {
    key: 'manage',
    name: '',
    align: 'right',
  },
];

export const regions = [
  {
    name: 'United States',
    value: 'usa',
  },
  {
    name: 'Australia',
    value: 'aus',
  },
  {
    name: 'Europe',
    value: 'gbr',
  },
];

export const clientTypes = [
  {
    name: 'Real',
    value: 'real',
  },
  {
    name: 'Test',
    value: 'test',
  },
  {
    name: 'Demo',
    value: 'demo',
  },
];

export const globalMenuItems: MenuItems = [
  {
    translationId: 'client-list',
    text: 'Client list',
    to: '/client-list',
    dataTest: 'clientList',
    authorizer: xcaAuthorizer,
  },
  {
    translationId: 'client-creation',
    text: 'Client Creation',
    to: '/client-creation',
    dataTest: 'createNewClient',
  },
  {
    translationId: 'client-mapping',
    text: 'Client Mapping',
    to: '/client-mapping',
    dataTest: 'clientMapping',
    authorizer: xcaAuthorizer,
  },
  {
    translationId: 'client-migration-tool',
    text: 'Client Migration Tool',
    to: '/migration',
    dataTest: 'clientMigration',
    authorizer: xcaAuthorizer,
  },
  {
    translationId: 'language-control',
    text: 'Language Control',
    dataTest: 'languageControl',
    authorizer: permissionAuthorizer(['LANGUAGE_CONTROL']),
    children: [
      {
        translationId: 'language-string',
        text: 'Language String',
        dataTest: 'languageString',
        authorizer: permissionAuthorizer(['LANGUAGE_CONTROL']),
        to: '/language-string',
      },
      {
        translationId: 'bulk-upload',
        text: 'LS Bulk Upload',
        dataTest: 'bulkUpload',
        authorizer: permissionAuthorizer(['LANGUAGE_CONTROL']),
        to: '/bulk-upload',
      },
      {
        translationId: 'material-descriptions',
        text: 'Material Descriptions',
        dataTest: 'materialDescriptions',
        authorizer: permissionAuthorizer(['LANGUAGE_CONTROL']),
        to: '/material-description',
      },
      {
        translationId: 'custom-text',
        text: 'Custom Text',
        dataTest: 'customText',
        authorizer: permissionAuthorizer(['LANGUAGE_CONTROL']),
        to: '/custom-text',
      },
      {
        translationId: 'translation-request',
        text: 'Translation Request',
        dataTest: 'translationRequest',
        authorizer: permissionAuthorizer(['LANGUAGE_CONTROL']),
        to: '/translation-request',
      },
      {
        translationId: 'job-management',
        text: 'Job Management',
        dataTest: 'jobManagement',
        authorizer: permissionAuthorizer(['LANGUAGE_CONTROL']),
        to: '/job-management',
      },
    ],
  },
  {
    translationId: 'admin-access',
    text: 'Admin Access',
    dataTest: 'adminAccess',
    authorizer: xcaAuthorizer,
    children: [
      {
        translationId: 'admins',
        text: 'Admins',
        to: '/admins',
        dataTest: 'admins',
        authorizer: xcaAuthorizer,
        target: '_self',
      },
      {
        translationId: 'admin-roles',
        text: 'Admin Roles',
        to: '/admin-roles',
        dataTest: 'admin-roles',
        authorizer: xcaAuthorizer,
        target: '_self',
      },
      {
        translationId: 'admin-roles-new',
        text: 'Create Admin Roles',
        to: '/admin-roles/new',
        dataTest: 'admin-roles-new',
        authorizer: xcaAuthorizer,
        target: '_self',
        visible: false,
      },
      {
        translationId: 'client-access',
        text: 'Client Access',
        to: '/client-access',
        dataTest: 'client-access',
        authorizer: xcaAuthorizer,
        target: '_self',
      },
    ],
  },
  {
    translationId: 'remediation',
    text: 'Remediation',
    to: '/remediation',
    dataTest: 'clientMigration',
    authorizer: xcaAuthorizer,
  },
  {
    translationId: 'required-fields',
    text: 'Required Fields',
    to: '/required-fields',
    dataTest: 'required-fields',
    authorizer: xcaAuthorizer,
  },
  {
    translationId: 'award-store',
    text: 'Award Store',
    dataTest: 'awardStore',
    authorizer: permissionAuthorizer([
      'TWC_PA_READ',
      'TWC_PA_WRITE',
      'TWC_PR_READ',
      'TWC_PR_WRITE',
      'TWC_MA_READ',
      'TWC_MA_WRITE',
      'TWC_AC_READ',
      'TWC_AC_WRITE',
      'TWC_SV_READ',
      'TWC_SV_WRITE',
    ]),
    children: [
      {
        translationId: 'approval-center',
        text: 'Approval Center',
        href: '/ucat12c/approvalcenter',
        dataTest: 'approvalCenter',
        authorizer: permissionAuthorizer(['TWC_AC_READ', 'TWC_AC_WRITE']),
        target: '_self',
      },
      {
        translationId: 'design-management',
        text: 'Design Management',
        href: '/ucat12c/design-management',
        dataTest: 'designManagement',
        authorizer: permissionAuthorizer(['TWC_DM_READ', 'TWC_DM_WRITE']),
        target: '_self',
      },
      {
        translationId: 'refund-deduction',
        text: 'Bank Refund/Deduction',
        to: '/orders',
        dataTest: 'orders',
        authorizer: permissionAuthorizer(['UCAT_REFUND_ORDER_CHANGE']),
      },
      {
        translationId: 'price-agreements',
        text: 'Price Agreements',
        href: '/ucat12c/priceagreements',
        dataTest: 'priceAgreements',
        authorizer: permissionAuthorizer(['TWC_PA_READ', 'TWC_PA_WRITE']),
        target: '_self',
      },
      {
        translationId: 'price-models',
        text: 'Price Models',
        href: '/ucat12c/pricing',
        dataTest: 'priceModels',
        authorizer: permissionAuthorizer(['TWC_PR_READ', 'TWC_PR_WRITE']),
        target: '_self',
      },
      {
        translationId: 'products',
        text: 'Products',
        href: '/ucat12c/products',
        dataTest: 'products',
        authorizer: permissionAuthorizer(['TWC_MA_READ', 'TWC_MA_WRITE']),
        target: '_self',
      },
      {
        translationId: 'services',
        text: 'Services',
        href: '/ucat12c/services',
        dataTest: 'services',
        authorizer: permissionAuthorizer(['TWC_SV_READ', 'TWC_SV_WRITE']),
        target: '_self',
      },
      {
        translationId: 'store-admin',
        text: 'Store Admin',
        href: '/ucat12c/storeAdmin',
        dataTest: 'storeAdmin',
        authorizer: permissionAuthorizer(['TWC_SA_READ', 'TWC_SA_WRITE']),
        target: '_self',
      },
    ],
  },
  {
    translationId: 'privacy-notice',
    text: 'Privacy Notice',
    to: '/privacy-notice',
    dataTest: 'privacyPolicies',
    authorizer: xcaAuthorizer,
  },
  {
    translationId: 'terms-of-use',
    text: 'Terms of Use',
    to: '/terms-of-use',
    dataTest: 'termsAndConditions',
    authorizer: xcaAuthorizer,
  },
  {
    translationId: 'campaigns',
    text: 'Campaigns',
    to: '/campaigns',
    dataTest: 'campaigns',
    authorizer: permissionAuthorizer(['cc_campaign_read', 'cc_campaign_write']),
  },
];

export const RECENT_CLIENT_HEADERS = [
  {
    key: 'client-name',
    name: 'Client Name',
  },
  {
    key: 'stp-number',
    name: 'STP Number',
  },
  {
    key: 'core-id',
    name: 'Core ID',
  },
  {
    key: 'client-status',
    name: 'Client Status',
  },
  {
    key: 'client-usage-type',
    name: 'Client Usage Type',
  },
  {
    key: 'actions',
    name: 'Actions',
  },
];

export const CLIENT_STATUS = [
  {
    key: 'active',
    name: 'Active',
  },
  {
    key: 'impl',
    name: 'Implementation',
  },
  {
    key: 'sold',
    name: 'Sold',
  },
  {
    key: 'lost',
    name: 'Lost',
  },
];

export const LanguageRoutes = [
  '/language-string',
  '/bulk-upload',
  '/material-description',
  '/translation-request',
  '/job-management',
  '/custom-text',
];
