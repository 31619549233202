import { gql } from '@apollo/client';

export const SEARCH_INITIATIVE_MIGRATIONS = gql`
  query CcSearchInitiativeMigrations($searchInput: MigrationSearchInput) {
    ccSearchInitiativeMigrations(searchInput: $searchInput) {
      initiativeMigrations {
        edges {
          node {
            classicToCCInitiativesMigration {
              modifiedTsz
              preInitiativeMigrationStatus {
                coreMigrationCompleted
                initiativesEnabled
                initiativesMigratedCustomer
              }
              migrationProgress {
                totalSteps
                stepsCompleted
              }
            }
            migrationCompletedTsz
            migrationFailedTsz
            coreCustomer {
              name
              customerUuid
            }
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
      totalCompleted
      totalActive
      totalFailed
    }
  }
`;

// Initiatives Migration
export const INITIATIVE_MIGRATION_STATUS = gql`
  query ccInitiativeMigrationStatusByCoreCustomerId($coreCustomerUuid: UUID!) {
    ccInitiativeMigrationStatusByCoreCustomerId(
      coreCustomerUuid: $coreCustomerUuid
    ) {
      initiativeMigrationReport {
        initiativeMigrationUuid
        migrationFailedTsz
        migrationCompletedTsz
        classicToCCInitiativesMigration {
          storedProcedureStartedTsz
          storedProcedureCompletedTsz
          classicSystemInitiativesCollectedTsz
          modifiedTsz
          initiativesData {
            isCompleted
          }
          initiativeOwnersData {
            isCompleted
          }
          initiativeRequestsData {
            isCompleted
          }
          initiativeParticipantsData {
            isCompleted
          }
          communicationConfData {
            isCompleted
          }
          awardsData {
            isCompleted
          }
          awardConfigData {
            isCompleted
          }
          migrationProgress {
            totalSteps
            stepsCompleted
          }
          preInitiativeMigrationStatus {
            initiativesEnabled
            initiativesMigratedCustomer
            coreMigrationCompleted
          }
        }
      }
    }
  }
`;
