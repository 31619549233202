import 'regenerator-runtime/runtime'
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
  Typography,
  DatePicker,
  Alert,
  AdapterLuxon,
} from '@octanner/prism-core'
import { styled } from '@mui/material/styles'
import { YbProgramYearbookLocalesInput } from 'library/src/common/models/YbProgramYearbookLocales'
import { DateTime } from 'luxon'
import React, { useState } from 'react'
import { ActionsContainer, Form, Row } from '../../common/components/Styled'
import {
  CelebrationFrequency,
  ProgramRepresentation,
} from '../../common/models/ProgramRepresentation'
import { YbProgramInput } from '../../common/models/YbProgramInput'
import { FormValue } from '../CelebrationGroupEdit/models'
import { useGetLanguages } from './hooks'
import Box from '@mui/material/Box'

const LanguageContainer = styled(FormGroup)`
  display: grid;
  grid-auto-flow: column;
  grid-gap: ${({ theme }) => theme.spacing(3)};
`

export type OnSubmitForm = Omit<YbProgramInput, 'id' | 'programStatus'> &
  Omit<YbProgramYearbookLocalesInput, 'programId'>

interface Props {
  onCancel: () => void
  onSubmit: (input: OnSubmitForm) => void
  program?: ProgramRepresentation
}

type MonthType = 'previous' | 'same' | 'after'

interface State {
  celebrationFrequency: CelebrationFrequency
  isBatchedMonthly: boolean
  batchMonthlyDay: string
  catchUpDate: FormValue<DateTime | undefined>
  month: MonthType
  annualStart: FormValue<DateTime | undefined>
  annualDate: FormValue<DateTime | undefined>
  languages: string[]
  victoriesProgramId: string
}

interface ErrorState {
  key: keyof State
  message: string
}

const initializeDate = (
    value?: string | DateTime
  ): FormValue<DateTime | undefined> => {
    const date = value
      ? typeof value === 'string'
        ? DateTime.fromISO(value)
        : value
      : undefined
    return {
      value: date,
    }
  },
  getAnnualTimestamp = (
    program?: ProgramRepresentation
  ): DateTime | undefined => {
    if (program?.celebrationFrequency !== 'ANNUALLY') return
    if (!program.batchMonth || !program.batchDay) return
    const date = DateTime.local()
    date.set({ month: program.batchMonth, day: program.batchDay })
    return date
  },
  initializeState = (program?: ProgramRepresentation): State => ({
    celebrationFrequency:
      (program?.celebrationFrequency === 'DAILY_BATCH_MONTHLY'
        ? 'DAILY'
        : program?.celebrationFrequency) ?? 'DAILY',
    // TODO where do we get the month?
    month: 'previous',
    annualStart: initializeDate(getAnnualTimestamp(program)),
    catchUpDate: initializeDate(program?.catchupTimestamp),
    annualDate: initializeDate(program?.annualCelebrationTimestamp),
    batchMonthlyDay:
      program?.batchDay === 0
        ? 'last_day'
        : program?.batchDay?.toString() ?? '',
    isBatchedMonthly: program?.celebrationFrequency === 'DAILY_BATCH_MONTHLY',
    languages: program?.yearbookLocales || [],
    victoriesProgramId: program?.victoriesProgram?.id || '',
  }),
  getBatchDay = (state: State): number | undefined => {
    const { celebrationFrequency, batchMonthlyDay } = state
    if (
      celebrationFrequency === 'DAILY' ||
      celebrationFrequency === 'DAILY_BATCH_MONTHLY'
    ) {
      return
    }
    if (celebrationFrequency === 'ANNUALLY') {
      return getAnnualBatchDate(state)
    }
    if (batchMonthlyDay === 'last_day') {
      return 0
    }
    const parsed = parseInt(batchMonthlyDay)
    if (isNaN(parsed)) {
      return
    }
    return parsed
  },
  getAnnualBatchDate = ({
    celebrationFrequency,
    annualStart,
  }: State): number | undefined => {
    if (celebrationFrequency !== 'ANNUALLY') {
      return
    }

    if (!annualStart.value?.isValid) {
      return
    }

    return annualStart.value.day
  },
  getBatchMonth = ({
    celebrationFrequency,
    annualStart,
  }: State): number | undefined => {
    if (celebrationFrequency !== 'ANNUALLY') {
      return
    }

    if (!annualStart.value?.isValid) {
      return
    }

    return annualStart.value.month
  },
  getCelebrationFrequency = ({
    celebrationFrequency,
    isBatchedMonthly,
  }: State): CelebrationFrequency => {
    if (isBatchedMonthly && celebrationFrequency === 'DAILY') {
      return 'DAILY_BATCH_MONTHLY'
    }
    return celebrationFrequency
  },
  getAnnualCelebrationTimeStamp = ({
    celebrationFrequency,
    annualDate,
  }: State): string | undefined => {
    if (celebrationFrequency !== 'ANNUALLY') {
      return
    }
    if (!annualDate.value?.isValid) {
      return
    }

    return annualDate.value.toISO()
  },
  getCatchupDate = ({ catchUpDate }: State): string | undefined => {
    if (!catchUpDate.value?.isValid) {
      return
    }

    return catchUpDate.value.toISO()
  }

function* validate(state: State): Generator<ErrorState> {
  if (!state.languages.length) {
    yield {
      key: 'languages',
      message: 'Language selection is required.',
    }
  }
}

type DateKey = 'catchUpDate' | 'annualStart' | 'annualDate'

export default function GeneralProgramSettingsEdit({
  onCancel,
  program,
  onSubmit,
}: Props): JSX.Element {
  const languageList = useGetLanguages(),
    [state, setState] = useState(() => initializeState(program)),
    { catchUpDate, languages, victoriesProgramId } = state,
    [errors, setErrors] = useState<ErrorState[]>([]),
    handleDateChange = (key: DateKey) => (date: DateTime) => {
      setState((prevState) => ({
        ...prevState,
        [key]: {
          ...prevState[key],
          value: date,
        },
      }))
    },
    handleLanguageChange = (language: string, checked: boolean) => {
      if (!checked) {
        setState((prevState) => ({
          ...prevState,
          languages: prevState.languages.filter((lan) => lan !== language),
        }))
        return
      }

      setState((prevState) => ({
        ...prevState,
        languages: [...prevState.languages, language],
      }))
    },
    numberOfRows = Math.ceil(languageList.length / 3),
    handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault()
      const errors = Array.from(validate(state))
      if (errors.length) {
        setErrors(errors)
        return
      }
      const victoriesProgramId = parseInt(state.victoriesProgramId)
      onSubmit({
        celebrationFrequency: getCelebrationFrequency(state),
        batchMonth: getBatchMonth(state),
        annualCelebrationTimestamp: getAnnualCelebrationTimeStamp(state),
        batchDay: getBatchDay(state),
        catchupTimestamp: getCatchupDate(state),
        yearbookLocales: languages,
        victoriesProgramId: victoriesProgramId || undefined,
      })
    },
    languageError = errors.find((e) => e.key === 'languages')

  return (
    <Form onSubmit={handleSubmit}>
      <section>
        <Typography variant="h3" data-testid="yps:general-edit:title">
          Program Settings
        </Typography>
      </section>
      <Row>
        <label htmlFor="recognition-program-id">
          <Typography>Recognition Program ID</Typography>
        </label>
        <TextField
          id="recognition-program-id"
          label="Recognition Program ID"
          value={victoriesProgramId}
          helperText="Recognition Program is required for points for service"
          style={{ width: 456 }}
          inputProps={{
            'data-testid': 'yps:general-edit:recognition-program-id',
          }}
          onChange={(event) =>
            setState((cur) => ({
              ...cur,
              victoriesProgramId: event.target.value,
            }))
          }
        />
      </Row>
      <Row>
        <label htmlFor="catch-up-date">
          <Typography>Catch Up Date</Typography>
        </label>
        <DatePicker
          label="Catch Up Date"
          dateAdapter={AdapterLuxon}
          renderInput={(params) => (
            <TextField
              {...params}
              id="catch-up-date"
              style={{ width: 200 }}
              error={Boolean(catchUpDate.errors)}
              helperText={catchUpDate.errors}
              inputProps={{
                ...params.inputProps,
                'data-testid': 'yps:general-edit:catch-up-date',
              }}
            />
          )}
          value={catchUpDate.value ?? ''}
          onChange={handleDateChange('catchUpDate')}
        />
      </Row>
      <Row>
        <Typography>Yearbook Language(s)</Typography>
        <Box>
          {languageError && (
            <Alert color="error" data-testid="yps:general-edit:language-error">
              {languageError.message}
            </Alert>
          )}
          <LanguageContainer
            style={{ gridTemplateRows: `repeat(${numberOfRows}, 1fr)` }}
          >
            {languageList.map((locale) => (
              <FormControlLabel
                key={locale}
                control={
                  <Checkbox
                    checked={languages.includes(locale)}
                    onChange={(e) =>
                      handleLanguageChange(locale, e.target.checked)
                    }
                    name={locale}
                    inputProps={{
                      // @ts-ignore actual prop on checkbox
                      'data-testid': `yps:general-edit:language:${locale}`,
                    }}
                  />
                }
                label={locale}
              />
            ))}
          </LanguageContainer>
        </Box>
      </Row>
      <Row>
        <ActionsContainer>
          <Button
            onClick={onCancel}
            color="inherit"
            data-testid="yps:general-edit:cancel"
          >
            Cancel
          </Button>
          <Button type="submit" data-testid="yps:general-edit:submit">
            Save Settings
          </Button>
        </ActionsContainer>
      </Row>
    </Form>
  )
}
