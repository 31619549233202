import styled from "@emotion/styled";
import { Button, Checkbox, TableCell, TableRow, Tag, TextField, Typography } from "@octanner/prism-core";
import React, { useContext } from "react";
import { useParams } from 'react-router-dom';
import { LocaleContext } from "../../Contexts/LocaleContext";
import { MaterialStringsContext } from "../../Contexts/MaterialStringsContext";
import { MmdCharLimitEnglishLocale, MmdCharLimitForeignLocales, TagVariant } from "../../utils/constants";
import { defaults } from "../../utils/default";
import { Locale, MaterialDescription, MaterialDescriptionTranslationProps } from "../../utils/types";

const CheckBoxCell = styled(TableCell)`
  width: 1%;
`;

const LocaleDescCell = styled(TableCell)`
  width: 15%;
`;

const ActionCell = styled(TableCell)`
  width: 15%;
  text-align: center;
`;

const StatusCell = styled(TableCell)`
  width: 14%;
`;

const TextCell = styled(TableCell)`
  width: 45%;
`;

const MaterialDescriptionRow: React.FC<Partial<MaterialDescriptionTranslationProps>> = ({
  description,
  descriptionLocale,
  setFilteredMaterialDescriptionList,
  materialDescriptionList,
  setMaterialDescriptionList,
  updateMaterialDescription,
  saveAllLoading,
  editMode,
  setDescriptionLocalesAvailable,
  setDescriptionLocalesAvailableInd,
  setEditedTextField,
  materialDescriptionLoadingByMmId,
  updatingMaterialDescription,
  filteredMaterialDescriptionList }) => {

  const { mmId } = useParams();
  const { setMessageText, setShowFooter, setOpenEditEnglishDescriptionModal } = useContext(MaterialStringsContext);
  const { localeMap } = useContext(LocaleContext);

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, fieldType: string) => {
    setFilteredMaterialDescriptionList((prevList: Partial<MaterialDescription[]>) => {
      const updatedList = prevList.map((item: MaterialDescription) => {
        if (item.fieldType === fieldType) {
          return {
            ...item,
            text: e.target.value
          };
        }
        return item;
      });
      return updatedList;
    });
  };

  const handleSave = (fieldType: string) => {
    const index = filteredMaterialDescriptionList.findIndex(item => item.fieldType === fieldType);
    const editModeFields = filteredMaterialDescriptionList.filter((item) => item.editMode === true);
    setEditedTextField(editModeFields);
    setFilteredMaterialDescriptionList((prevList: MaterialDescription[]) => {
      const updateList = [...prevList];
      const fieldIndex = updateList.findIndex(item => item.fieldType === fieldType);
      if (fieldIndex !== -1) {
        updateList[fieldIndex].loading = true;
      }
      return updateList;
    })
    updateMaterialDescription({
      variables: {
        input: {
          mmId: parseInt(mmId),
          descriptionList: [{
            text: filteredMaterialDescriptionList[index].text,
            fieldType: fieldType
          }],
          descriptionLocale
        }
      }
    }).then(response => {
      if (response.errors) {
        setMessageText('errorMessage', defaults.update_error);
        setFilteredMaterialDescriptionList((prevList: MaterialDescription[]) => {
          const updateList = [...prevList];
          const fieldIndex = updateList.findIndex(item => item.fieldType === fieldType);
          if (fieldIndex !== -1) {
            updateList[fieldIndex].loading = false;
          }
          return updateList;
        })
      } else {
        if (response?.data?.lsUpsertMaterialDescription?.materialDescription?.descriptionLocalesAvailable?.length > 0) {
          if (descriptionLocale === 'en-US') {
            setDescriptionLocalesAvailableInd(true);
            setOpenEditEnglishDescriptionModal(true);
          }
          const localeWithDescription = response.data.lsUpsertMaterialDescription.materialDescription.descriptionLocalesAvailable.map((locale: Locale) => {
            return {
              code: locale.code,
              languageDescription: localeMap[locale.code]
            };
          });
          setDescriptionLocalesAvailable(localeWithDescription);
        }
        setMessageText('editSuccessMessage', defaults.update_success_for_material);
        setFilteredMaterialDescriptionList((prevList: MaterialDescription[]) => {
          const updateList = [...prevList];
          const fieldIndex = updateList.findIndex(item => item.fieldType === fieldType);
          if (fieldIndex !== -1) {
            updateList[fieldIndex].loading = false;
            updateList[fieldIndex].editMode = false;
            updateList[fieldIndex].originalText = filteredMaterialDescriptionList[index].text;
            updateList[fieldIndex].translationStatus = 'TRANSLATED';
          }
          const updateTranslationList = materialDescriptionList.map((item: MaterialDescription) => {
            if (updateList[fieldIndex].fieldType === item.fieldType) {
              return {
                text: updateList[fieldIndex].text,
                originalText: updateList[fieldIndex].originalText,
                translationStatus: 'TRANSLATED'
              }
            }
            return item;
          });
          setMaterialDescriptionList([...updateTranslationList]);
          return updateList;
        });
      }
    });
  };

  const toggleEdit = (fieldType: string, editMode: boolean) => {
    setFilteredMaterialDescriptionList(prevList => {
      const updatedList = prevList.map(item => {
        if (item.fieldType === fieldType) {
          return {
            ...item,
            editMode: !item.editMode,
            text: editMode ? item.text : (item.originalText ? item.originalText : '')
          };
        }
        return item;
      });
      return updatedList;
    });
  };

  const handleCheckBox = (fieldType: string, e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    setFilteredMaterialDescriptionList((prevList: MaterialDescription[]) => {
      const updatedList = prevList.map((item: MaterialDescription) => {
        if (item.fieldType === fieldType) {
          item.checked = isChecked;
        }
        return item;
      });
      const updatedFields = updatedList.filter((item: MaterialDescription) => item.checked).map((item: MaterialDescription) => ({
        fieldType: item.fieldType,
        text: item.text
      }));
      setShowFooter(updatedFields.length > 0);
      return updatedList;
    });
  };

  return (
    <TableRow>
      {descriptionLocale === 'en-US' &&
        <CheckBoxCell>
          <Checkbox checked={description.checked} disabled={description.translationStatus === 'UNTRANSLATED'}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleCheckBox(description.fieldType, e)} />
        </CheckBoxCell>}
      <LocaleDescCell>
        <Typography>{(descriptionLocale === 'en-US') ? MmdCharLimitEnglishLocale[description.fieldType].text : MmdCharLimitForeignLocales[description.fieldType].text}</Typography>
      </LocaleDescCell>
      <StatusCell>
        {(descriptionLocale === 'en-US') ?
          <Typography>{defaults.default}</Typography> :
          <Tag variant={TagVariant[description.translationStatus].variant}>{TagVariant[description.translationStatus].text}</Tag>}
      </StatusCell>
      <TextCell>
        {(descriptionLocale === 'en-US') ? <TextField fullWidth value={description.text} displayCount={editMode && MmdCharLimitEnglishLocale[description.fieldType].text !== 'Details'}
          maxLength={MmdCharLimitEnglishLocale[description.fieldType].limit} onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => handleTextChange(e, description.fieldType)} InputProps={{ readOnly: !editMode }} /> :
          <TextField fullWidth value={description.text} displayCount={editMode && MmdCharLimitForeignLocales[description.fieldType].text !== 'Details'}
            maxLength={MmdCharLimitForeignLocales[description.fieldType].limit} onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => handleTextChange(e, description.fieldType)} InputProps={{ readOnly: !editMode }} />}
      </TextCell>
      <ActionCell>
        {!editMode ? <Button variant='text' onClick={() => toggleEdit(description.fieldType, true)}>{defaults.edit}</Button> :
          (<>
            <Button variant='text' color='primary' loading={description.loading} disabled={updatingMaterialDescription || materialDescriptionLoadingByMmId
              || (description.text.trim() === description.originalText.trim()) || saveAllLoading
              || !(description.text && description.text.trim().length) || (descriptionLocale === 'en-US' && MmdCharLimitEnglishLocale[description.fieldType].text !== 'Details' && description.text.trim().length > MmdCharLimitEnglishLocale[description.fieldType].limit) || (descriptionLocale !== 'en-US' && MmdCharLimitForeignLocales[description.fieldType].text !== 'Details' && description.text.trim().length > MmdCharLimitForeignLocales[description.fieldType].limit)} onClick={() => handleSave(description.fieldType)}>{defaults.save}</Button>
            <Button variant='text' color="secondary" buttonType="danger" disabled={description.loading || saveAllLoading} onClick={() => toggleEdit(description.fieldType, false)}>{defaults.cancel}</Button>
          </>)
        }
      </ActionCell>
    </TableRow>
  )
}

export default MaterialDescriptionRow;