import React, { useState, useEffect } from 'react';
import {  useSearchSalesTerritory, useGetCountryDetails } from './hooks/customer';
import { Autocomplete, TextField, Typography, Search } from '@octanner/prism-core';
import { SearchOptionCard } from '@octanner/prism-core/Search';
import { validLength } from '../utils/commonConstants';
import styled from '@emotion/styled';
import { defaults } from './utilities/defaults';
import validator from 'validator';

const MainContainer = styled.div`
  margin-bottom: 32px;
  width: 592px;
`;

const Header = styled(Typography)`
  margin-top: 40px;
  margin-bottom: 32px;
`;

const StateContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const ContactContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 128px;
`;

const StyledSearch = styled(Search)`
  margin-bottom: 16px;
`;

const AddressFields = styled(TextField)`
  margin-bottom: 16px;
`;

const TextFieldData = styled(TextField)`
  margin-bottom: 16px;
`;

const ClientAddressDetails = ({
    customerAddressInfo,
    setCustomerAddressInfo,
    statesOptions,
    setStatesOptions,
    getStates,
    stateData,
    addressDetailsError,
    setAddressDetailsError,
    isEmailvalid,
    setIsEmailvalid,
    setShowAlert,
}) => {
    const {
    data: salesTerritoryData,
    loading: salesTerritoryLoading,
    refetch: salesTerritoryRefetch
    } = useSearchSalesTerritory('');
    const {
      data: countryData,
      loading: countryLoading,
      refetch: countryRefetch
  } = useGetCountryDetails('');
    const [salesTerritoryOptions, setSalesTerritoryOptions] = useState<any>(
       salesTerritoryData?.searchSalesTerritory?.nodes || []
    );
    useEffect(() => { if(salesTerritoryData)setSalesTerritoryOptions(salesTerritoryData?.searchSalesTerritory?.nodes || [])
    }, [salesTerritoryData]);
    const [countryOptions, setCountryOptions] = useState<any>(countryData?.searchCountries?.content || []);
    useEffect(() => { if(countryData){setCountryOptions(countryData?.searchCountries?.content || [])}}, [countryData]);

    const handleCountryChange = (value,key) => {
      if (value!== null) {
        if (value?.countryName !== customerAddressInfo?.country?.countryName) {
            getStates({ variables: { countryIso2Code: value.iso2Code } })
            setCustomerAddressInfo({
                ...customerAddressInfo,
                [key]: value,
                addressLine1: '',
                addressLine2: '',
                addressLine3: '',
                city: '',
                postalCode: '',
                state: {},
            });
        }
      }else{
        setCustomerAddressInfo({
            ...customerAddressInfo,
            [key]: {},
        });
      }
    }


    const handleInput = (value, key) => {
        if(key === 'city' || key === 'postalCode' || key === 'primaryContactName' || key === 'addressLine1'){
            const removeSpace = value?.trimStart();
            if(removeSpace?.length === 0 && value.length !== 0){
                return;
            }
        }
        setAddressDetailsError({
            ...addressDetailsError,
            [`${key}Err`]: false,
        });
        switch (key) {
            case 'country':
                handleCountryChange(value, key);
                setAddressDetailsError({});
                break;
            case 'emailAddress':
                if (value !== '' && !validator.isEmail(value)) {
                    setIsEmailvalid(true);
                } else {
                    setIsEmailvalid(false);
                }
                setCustomerAddressInfo({
                    ...customerAddressInfo,
                    [key]: value,
                });
                break;
            default:
                setCustomerAddressInfo({
                    ...customerAddressInfo,
                    [key]: value,
                });
        };
        setShowAlert(false);
    }

    useEffect(() => {setStatesOptions(stateData?.getStatesByCountry || [])}, [stateData]);
    const fields = {
      searchConfigs:[
        {
          placeholder: defaults.search_sales_territory,
          id: "search-sales-territory",
          options: salesTerritoryOptions,
          loading: salesTerritoryLoading,
          renderOption: (props, option) => (
            <SearchOptionCard
              {...props}
              title={option.salesTerritoryDescription}
            />
          ),
          onInputChange: (e, value) => { salesTerritoryRefetch({ searchText: value }) },
          onChange: (e, value) => { handleInput(value, 'salesTerritory') },
          getOptionLabel: (option) => option.salesTerritoryDescription || '',
          multiple: false,
          value: customerAddressInfo.salesTerritory,
          error: addressDetailsError.salesTerritoryErr,
          helperText: addressDetailsError.salesTerritoryErr && defaults.this_field_is_required
        },
        {
          placeholder: defaults.search_country,
          id: "search-country",
          options: countryOptions,
          loading: countryLoading,
          renderOption: (props, option) => (
            <SearchOptionCard
              {...props}
              title={option.countryName}
            />
          ),
          onInputChange: (e, value) => { countryRefetch({ clause: value?.toLowerCase() }) },
          onChange: (e, value) => { handleInput(value, 'country') },
          getOptionLabel: (option) => option.countryName || '',
          multiple: false,
          value: customerAddressInfo.country || {},
          error: addressDetailsError.countryErr,
          helperText: addressDetailsError.countryErr && defaults.this_field_is_required
        }
      ],
      addressFields : [
        {
          label: defaults.address_1,
          maxLength: validLength.addressLine1,
          onChange: (e) => handleInput(e.target.value, 'addressLine1'),
          value: customerAddressInfo.addressLine1,
          error: addressDetailsError.addressLine1Err,
          helperText: addressDetailsError.addressLine1Err ? defaults.this_field_is_required : customerAddressInfo.addressLine1.length > validLength.addressLine1 && defaults.too_many_characters,
        },
        {
          label: defaults.address_2,
          maxLength: validLength.addressLine2,
          helperText: customerAddressInfo.addressLine2.length > validLength.addressLine2 ? defaults.too_many_characters : defaults.optional,
          onChange: (e) => handleInput(e.target.value, 'addressLine2'),
          value: customerAddressInfo.addressLine2,
        },
        {
          label: defaults.address_3,
          maxLength: validLength.addressLine3,
          helperText: customerAddressInfo.addressLine3.length > validLength.addressLine3 ? defaults.too_many_characters : defaults.optional,
          onChange: (e) => handleInput(e.target.value, 'addressLine3'),
          value: customerAddressInfo.addressLine3,
        },
        {
          label: defaults.city,
          maxLength: validLength.city,
          onChange: (e) => handleInput(e.target.value, 'city'),
          value: customerAddressInfo.city,
          error: addressDetailsError.cityErr,
          helperText: addressDetailsError.cityErr ? defaults.this_field_is_required : customerAddressInfo.city.length > validLength.city && defaults.too_many_characters,
        }
      ],
      textFieldData : [
        {
          label: defaults.primary_contact_name,
          maxLength: validLength.primaryContactName,
          name: "primaryContactName",
          onChange: (e) => { handleInput(e.target.value, 'primaryContactName'); },
          error: addressDetailsError.primaryContactNameErr,
          helperText: addressDetailsError.primaryContactNameErr ? defaults.this_field_is_required : customerAddressInfo.primaryContactName.length > validLength.primaryContactName && defaults.too_many_characters,
        },
        {
          label: defaults.email,
          maxLength: validLength.emailAddress,
          name: "emailAddress",
          onChange: (e) => { handleInput(e.target.value, 'emailAddress'); },
            error: isEmailvalid && customerAddressInfo.emailAddress !== '',
            helperText: customerAddressInfo.emailAddress !== '' && (isEmailvalid ? 'Please enter a valid email address.' : customerAddressInfo.emailAddress.length > validLength.emailAddress && defaults.too_many_characters) || defaults.optional
        },
      ],
      contactFields : [
        {
          label: defaults.phone,
          maxLength: validLength.phoneNumber,
          name: "phoneNumber",
          helperText: customerAddressInfo.phoneNumber.length > validLength.phoneNumber ? defaults.too_many_characters : defaults.optional,
          style: { width: '48%' },
          onChange: (e) => { handleInput(e.target.value, 'phoneNumber'); },
          value: customerAddressInfo.phoneNumber,
        },
        {
          label:  defaults.fax,
          maxLength: validLength.faxNumber,
          name: "faxNumber",
          helperText: customerAddressInfo.faxNumber.length > validLength.faxNumber ? defaults.too_many_characters : defaults.optional,
          style: { width: '48%' },
          onChange: (e) => { handleInput(e.target.value, 'faxNumber'); },
          value: customerAddressInfo.faxNumber,
        },
      ]
    };



    return (
    <MainContainer>
      <Header variant="h3">{defaults.client_address_details}</Header>
      {
        fields.searchConfigs.map(({placeholder,id,options,loading,
          renderOption,onInputChange,onChange,getOptionLabel,multiple,value,
          error,helperText
        }) => (
          <StyledSearch
          key={id}
          placeholder={placeholder}
          id={id}
          options={options}
          loading={loading}
          renderOption={renderOption}
          onInputChange={onInputChange}
          onChange={onChange}
          getOptionLabel={getOptionLabel}
          multiple={multiple}
          value={value}
          error={error}
          helperText={helperText}
        />
        ))
      }
      {
        customerAddressInfo?.country.iso2Code && (
            <>
            {
              fields.addressFields.map(({
                label, maxLength, onChange, value, error, helperText
              }) => (
                <AddressFields
                  key={label}
                  label={label}
                  maxLength={maxLength}
                  onChange={onChange}
                  value={value}
                  error={error}
                  helperText={helperText}
                  displayCount
                  fullWidth
                />
              ))
            }
          <StateContainer>
          {
            statesOptions.length > 0 && (
              <div style={{ width: '48%' }}>
                <Autocomplete
                  id="states"
                  options={statesOptions}
                  getOptionLabel={(option) => option.stateName || ''}
                  defaultValue={customerAddressInfo.state || {}}
                  onChange={(e, v) => handleInput(v, 'state')}
                  renderInput={(props) => (
                    <TextField
                      {...props}
                      label={defaults.state_province_region}
                      autoComplete="off"
                      error={addressDetailsError.stateErr}
                      helperText={addressDetailsError.stateErr && defaults.this_field_is_required}
                    />
                  )}
                  renderOption={(props, option) => (
                    <SearchOptionCard
                      {...props}
                      title={option?.stateName || ''}
                    />
                  )}
                />
              </div>
            )
          }
              <TextField
                label={defaults.postal_zip_code}
                maxLength={10}
                displayCount
                style={{width: '48%'}}
                onChange={(e) => {handleInput(e.target.value, 'postalCode');}}
                value={customerAddressInfo.postalCode}
                helperText={(customerAddressInfo.postalCode.length > 10 ? defaults.too_many_characters : '') ||  defaults.optional}
              />
          </StateContainer>
          </>
        )}

      <Header variant="h3">{defaults.client_contact_information}</Header>
      {
        fields.textFieldData.map(({
          label, maxLength, name, onChange, error, helperText
        }) => (
          <TextFieldData
            key={label}
            label={label}
            maxLength={maxLength}
            displayCount
            fullWidth
            onChange={onChange}
            value={customerAddressInfo[name]}
            error={error}
            helperText={helperText}
        />
        ))
      }
      <ContactContainer>
        {
          fields.contactFields.map(({
            label, maxLength, name, helperText, style, onChange, value
          }) => (
            <TextField
              key={label}
              label={label}
              maxLength={maxLength}
              displayCount
              fullWidth
              style={style}
              onChange={onChange}
              value={value}
              helperText={helperText}
            />
          ))
        }
      </ContactContainer>
    </MainContainer>
    )

};

export default ClientAddressDetails;
