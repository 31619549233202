import React , {useEffect, useState} from 'react';
import { useQuery, useLazyQuery } from '@apollo/client';
import InvoiceDetails from './InvoiceDetails';
import ContactDetails from './ContactDetails';
import ClientUsageTypeSelect from '../HomePage/ClientUsageType';
import { useTranslation } from 'react-i18next';
import { customerId } from '../../utilities/utility';
import {
  GET_CUSTOMER_INFO,
  GET_INVOICE_CUSTOMER_INFO,
} from './query';
import {
  GET_INVOICE_CONFIGURATION,
  GET_STATES
}  from '../../../utils/commonQueries';

const ClientFinancialInfos = ({
  confirmationData,
  onTabSwitch,
  errorsDisplayNumber,
  onSave,
  clientAddressWarning,
  onSapUpdateError,
  tabsEditModeStatus,
  setTabsEditModeStatus,
  setClientAddressWarning,
  setUpdateSapError,
  dataCustomerName,
}) => {

  const [getStates, { data: stateList } ] = useLazyQuery(GET_STATES);
  const { t } = useTranslation();
  // Customer Address Infomation
  const {
    loading: customerInfoProgress,
    data: customerAddress,
    refetch: refetchCustomerAddress,
    error: customerAddressError
  } = useQuery(GET_CUSTOMER_INFO, {
    variables: { customerId: customerId },
  });

  useEffect(() => {
    if(!customerInfoProgress){
      if(customerAddress){
      const { country } = customerAddress?.customerContactInformationMapByCustomer?.customerContactInfo;
      getStates({variables: { countryIso2Code: country?.iso2Code }})
      }
    }
  },[customerInfoProgress]);


  // Customer Invoice details
  const {
    loading: customerInvoiceProgress,
    data: customerinvoice,
    refetch: refetchCustomerInvoice,
    error: invoiceError
  } = useQuery(GET_INVOICE_CUSTOMER_INFO, {
    variables: { customerId: customerId },
  });
  const { loading: invoiceConfigProgress, data: invoiceInfo } = useQuery(GET_INVOICE_CONFIGURATION);


  const [isChangedValue, setIsChangedValue] = useState(false);
  useEffect(() => {
    if(isChangedValue){
      onTabSwitch({...confirmationData, editMode: true });
    }else{
      onTabSwitch({...confirmationData, editMode: false });
    }
  }, [isChangedValue]);


  return (
    <>
      {(customerInfoProgress || customerInvoiceProgress || invoiceConfigProgress) ? (
        <div>{t('loading')}</div>
      ) : (
        <>
          <ContactDetails
            customerAddress={customerAddress}
            refetchCustomerAddress={refetchCustomerAddress}
            customerAddressError={customerAddressError}
            stateListInfo={stateList?.getStatesByCountry || []}
            errorsDisplayNumber={errorsDisplayNumber}
            onSave={onSave}
            setIsChangedValue = {setIsChangedValue}
            clientAddressWarning={clientAddressWarning}
            onSapUpdateError={onSapUpdateError}
            tabsEditModeStatus={tabsEditModeStatus}
            setTabsEditModeStatus={setTabsEditModeStatus}
            setClientAddressWarning={setClientAddressWarning}
            setUpdateSapError={setUpdateSapError}
          />
          <InvoiceDetails
            invoiceDetailsInfo={invoiceInfo}
            refetchInvoice={refetchCustomerInvoice}
            customerinvoiceInfo={customerinvoice}
            error={invoiceError}
            errorsDisplayNumber = {errorsDisplayNumber}
            onSave = {onSave}
            setIsChangedValue = {setIsChangedValue}
            onSapUpdateError={onSapUpdateError}
            tabsEditModeStatus={tabsEditModeStatus}
            setTabsEditModeStatus={setTabsEditModeStatus}
            setUpdateSapError={setUpdateSapError}
          />
          <div>
          <ClientUsageTypeSelect
              dataCustomerName={dataCustomerName}
            />
          </div>
        </>
      )}
    </>
  );
};

export default ClientFinancialInfos;
