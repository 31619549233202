import { Collapse, FormControlLabel, Switch } from '@octanner/prism-core';
import { ArrowDown, ArrowUp } from '@octanner/prism-icons';
import { useState } from 'react';
import { HorizontalLine } from '../../../../styles';
import { BoldBlack } from '../../styles';
import { AwardLevelRowtype } from '../../types';
import {
  AlignedTextBox,
  CheckboxMargin,
  FlexCenterDiv,
  FlexColumnDiv,
  RowContainer,
  SpecificWidthCell,
  VerticalAlignedIconButton,
} from './styles';
import {
  useActivateSocialFilter,
  useDeactivateSocialFilter,
  useSelectSocialFilter,
  useDeselectSocialFilter,
} from '../../hooks/awardLevels/awardLevels';

const AwardLevelRow = ({ data, customerUuid, disabled }: AwardLevelRowtype) => {
  const [open, setOpen] = useState<boolean>();

  const [rowEnabled, setRowEnabled] = useState(true);
  const [activateSocialFilter] = useActivateSocialFilter(customerUuid);
  const [deactivateSocialFilter] = useDeactivateSocialFilter(customerUuid);
  const [selectSocialFilter] = useSelectSocialFilter(customerUuid);
  const [deselectSocialFilter] = useDeselectSocialFilter(customerUuid);

  const handleCheckBox = (optionIndex, value) => {
    const newProgramValue = data.options;
    newProgramValue[optionIndex].selected = value;
    if (value) {
      selectSocialFilter();
    } else {
      deselectSocialFilter();
    }
  };

  const handleSwitchChange = (e) => {
    const newObject = data;
    newObject.enabled = e.target.checked;
    if (e.target.checked) {
      activateSocialFilter();
    } else {
      deactivateSocialFilter();
    }
    setRowEnabled(!rowEnabled);
  };

  return (
    <>
      <RowContainer>
        <SpecificWidthCell>
          <Switch
            data-testid="social-wall:award-level-row:switch"
            checked={data.enabled}
            name="programAwards"
            onChange={(e) => handleSwitchChange(e)}
            disabled={disabled}
          />
          <FlexCenterDiv>
            <BoldBlack data-testid="social-wall:award-level-row:title">
              {data.name}
            </BoldBlack>
          </FlexCenterDiv>
        </SpecificWidthCell>
        <VerticalAlignedIconButton
          aria-label="expand row"
          size="small"
          onClick={() => setOpen(!open)}
          data-testid="social-wall:award-level-row:toggle-accordian"
        >
          {open ? <ArrowUp /> : <ArrowDown />}
        </VerticalAlignedIconButton>
      </RowContainer>
      <Collapse in={open} timeout="auto">
        <div>
          <FlexColumnDiv>
            {data.options.map(({ term, selected }, i) => (
              <AlignedTextBox key={data.name + term + i}>
                <FormControlLabel
                  control={
                    <CheckboxMargin
                      data-testid={`social-wall:award-level-row:checkbox-${term}`}
                      checked={selected}
                      disabled={!rowEnabled || disabled}
                      onChange={(e) => handleCheckBox(i, e.target.checked)}
                    />
                  }
                  label={term}
                />
              </AlignedTextBox>
            ))}
          </FlexColumnDiv>
        </div>
      </Collapse>
      <HorizontalLine />
    </>
  );
};

export default AwardLevelRow;
