import { createStyles, makeStyles } from '@material-ui/core/styles';
import { tannerSemantic } from '@octanner/prism-core/ThemeProvider/colors';
import Colors from '../../common/constants/Colors';
import Styles from '../../common/constants/Styles';
export const useStyles = makeStyles((theme) =>
  createStyles({
    lineBreak: { width: '100%', color: '#C4C4C4' },
    spaceBetween: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    typoMargin8: { marginRight: 8, marginBottom: 10 },
    RTEBackground: {
      backgroundColor: '#fff',
      paddingLeft: 20,
      paddingRight: 20,
      paddingBottom: 10,
    },
    disabledTextBox: {
      opacity: 0.5,
      cursor: 'default',
      pointerEvents: 'none',
      '& a': {
        color: 'inherit',
      },
    },
    clientDetailRoot: {
      flex: '1 1 100%',
      display: 'flex',
      flexDirection: 'row',
    },
    label: {
      flex: '0 0 410px',
      color: Colors.gray600,
      display: 'flex',
      alignItems: 'center',
    },
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
    sectionHeader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    editingHeader: {
      padding: theme.spacing(3),
      backgroundColor: Colors.gray300,
      boxShadow: Styles.boxShadowTop,
    },
    sectionContent: {
      flex: '1 1 auto',
      padding: theme.spacing(2),
      display: 'flex',
      boxShadow: Styles.boxShadowBottom,
      flexDirection: 'column',
      '& > * + *': {
        marginTop: theme.spacing(1),
      },
    },
    editingSection: {
      paddingTop: 0,
      padding: theme.spacing(3),
      backgroundColor: Colors.gray300,
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
    cancelButton: {
      marginLeft: theme.spacing(1),
    },
    maxHeight: {
      maxHeight: 400,
    },
    boxHeight: {
      maxHeight: 32,
      whiteSpace: 'nowrap',
    },
    CustomerRoutesRoot: {
      marginTop: theme.spacing(8),
      marginLeft: 'auto',
      marginRight: 'auto',
      textAlign: 'center',
    },
    centering: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    },
    centeringMiddle: {
      height: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    backButton: {
      margin: theme.spacing(3),
      padding: theme.spacing(1),
      cursor: 'pointer',
    },
    idLabel: {
      color: '#707070',
      fontSize: theme.typography.overline.fontSize,
      marginTop: theme.spacing(3),
    },
    textfield: {
      marginTop: theme.spacing(3),
    },
    results: {
      flex: '1 1 auto',
      marginTop: theme.spacing(2),
    },
    listHeaders: {
      display: 'flex',
      color: Colors.gray600,
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(1),
    },
    splitButtons: {
      display: 'flex',
      justifyContent: 'space-evenly',
      alignItems: 'center',
      width: '100%',
    },
    cursor: {
      cursor: 'pointer',
    },
    featuresRoot: {
      display: 'flex',
      flexDirection: 'column',
    },
    featuresSectionHeader: {
      marginTop: theme.spacing(3),
      display: 'flex',
      '& a': {
        cursor: 'pointer',
      },
    },
    editButton: {
      marginLeft: 'auto',
    },
    featureSectionContent: {
      flex: '1 1 auto',
      padding: theme.spacing(2),
      display: 'flex',
      boxShadow: Styles.boxShadowBottom,
      flexDirection: 'column',
      '& > * + *': {
        marginTop: theme.spacing(1),
      },
    },
    featureTable: {
      borderCollapse: 'collapse',
      width: '100%',
    },
    featureHeader: {
      borderBottom: '1px solid #DADCDD',
      textAlign: 'left',
      height: '44px',
    },
    featureCell: {
      padding: theme.spacing(1),
      '& .MuiFormControl-root': {
        width: '100%',
      },
    },
    featureBuild: {
      backgroundColor: tannerSemantic.warning.background,
      padding: 5,
      color: tannerSemantic.warning.color,
      maxWidth: 80,
      textAlign: 'center',
    },
    featureExpired: {
      backgroundColor: tannerSemantic.error.background,
      padding: 5,
      color: tannerSemantic.error.color,
      maxWidth: 80,
      textAlign: 'center',
    },
    featureActive: {
      backgroundColor: tannerSemantic.success.background,
      padding: 5,
      color: tannerSemantic.success.color,
      maxWidth: 80,
      textAlign: 'center',
    },
    addNewFeature: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: 2,
    },
    clientConfigRoot: {
      margin: -20,
    },
    clientDetailRootMain: {
      margin: 0,
    },
    divider: {
      margin: '8px 0',
    },
  })
);
