export const supportedLang = (locale: string) => {
  switch (locale) {
    case 'enUS':
      return 'English';
    case 'frCA':
      return 'French Canadian';
    default:
      return 'English';
  }
};
