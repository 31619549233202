import Typography from '@octanner/prism-core/Typography';
import { CustomerWithAdminRoles } from '../types';
import ClientCard from './ClientCard';

interface ClientDetailsProps {
  customer: CustomerWithAdminRoles;
}

const AdminDetails = ({ customer }: ClientDetailsProps) => {
  return (
    <>
      <Typography variant="h2" mt={4}>
        Client details
      </Typography>
      <ClientCard customer={customer} />
    </>
  );
};

export default AdminDetails;
