import { MenuItem, Button,Typography } from '@octanner/prism-core';
import React, {useState} from 'react';
import { StpContainer, StpNumber } from '../salesOrg/style';
import {
  ButtonContainer,
  ClientInfo,
  StatusDualButton,
  CustomerContainer,
  DivStatusContainer,
  PipelineContainer,
  DisplayFlex,
} from "./styles";
import { ChangeStatusConfirmation } from './ChangeStatusConfirmation';
import ClientUsageTypeModal from './ClientUsageTypeModal';
import { useTranslation } from 'react-i18next';
import { StatusTag } from './StatusTag';
import { CustomerStatus } from './constants';

export const DisplayClientInfo = ({
  setcompanyDetail,
  dataCustomerName,
  showStatus,
  setShowStatus,
  setShowWarning,
  setSapErrorDispaly,
  setRefetchEditCustomer,
  setErrorMessage,
  confirmationData,
  tabsEditModeStatus,
  setTabsEditModeStatus,
  errorsDisplayNumber,
  setCheckedSalesOrg,
  setCustomerInfo,
  setCalledRefetch,
  isMoveToActive,
  setIsMoveToActive,
  statusText,
  setStatusText,
  clientType,
  setClientType,
  customerId,
}) => {

  const { t } = useTranslation();
  const { name, customerStatus, stpNumber, customerUuid } =
    dataCustomerName?.customer;

  const [showClientTypeModal, setShowClientTypeModal] = useState(false);
  const handleDualButton = () => {
    setShowStatus(true);
    if (statusText === t("moveToActive")) {
      setIsMoveToActive(true);
    } else {
      setIsMoveToActive(false);
    }
  };

  return (
    <div>
      <CustomerContainer>
        {showStatus && (
          <ChangeStatusConfirmation
            setShowStatus={setShowStatus}
            setShowWarning={setShowWarning}
            setSapErrorDispaly={setSapErrorDispaly}
            dataCustomerName={dataCustomerName}
            setRefetchEditCustomer={setRefetchEditCustomer}
            setErrorMessage={setErrorMessage}
            confirmationData={confirmationData}
            tabsEditModeStatus={tabsEditModeStatus}
            setTabsEditModeStatus={setTabsEditModeStatus}
            errorsDisplayNumber={errorsDisplayNumber}
            setCustomerInfo={setCustomerInfo}
            setCalledRefetch={setCalledRefetch}
            isMoveToActive={isMoveToActive}
          />
        )}
        <ClientUsageTypeModal
          showClientTypeModal={showClientTypeModal}
          setShowClientTypeModal={setShowClientTypeModal}
          setClientType={setClientType}
          customerId={customerId}
          dataCustomerName={dataCustomerName}
          clientType={clientType}
          setRefetchEditCustomer={setRefetchEditCustomer}
        />
        <div>
        <ClientInfo>
          <Typography
            variant="h2"
            style={{ maxWidth: "500px", lineHeight: "200%" }}
            test-id="client-name"
          >
            {name}
          </Typography>
          <Button
            variant="text"
            onClick={() => setcompanyDetail(false)}
            style={{ marginLeft: "8px" }}
            test-id="edit-button"
          >
            {t("edit")}
          </Button>
          {/* <ClientUsageContainer>
            <ClientUsageTypeSelect
              clientType={clientType}
              setClientType={setClientType}
              setShowClientTypeModal={setShowClientTypeModal}
            />
          </ClientUsageContainer>  */}
        </ClientInfo>
        <DisplayFlex>
        {/* Stp contanier */}
        {customerStatus !== CustomerStatus.SOLD && (
         <DisplayFlex>
          <div>
            <StpNumber>
              <>
                <Typography
                  color="textSecondary"
                  variant="body2"
                  test-id="stp-header"
                >
                  {t("stp") + " :"} {stpNumber}
                </Typography>
              </>
            </StpNumber>
          </div>
          <PipelineContainer>
          {'|'}
          </PipelineContainer>
          <StpContainer>
            <StpNumber>
              <>
                <Typography
                  color="textSecondary"
                  variant="body2"
                  test-id="stp-header"
                >
                  {t("customerUuid") + " :"} {customerUuid}
                </Typography>
              </>
            </StpNumber>
          </StpContainer>
         </DisplayFlex>
        )}
      </DisplayFlex>
        </div>
        <ButtonContainer>
          <DivStatusContainer>
            <StpNumber>
              <>
                <Typography
                  color="textSecondary"
                  variant="body2"
                  test-id="client-status-header"
                >
                  {t("client_status")}
                </Typography>
                <Typography>
                  <StatusTag customerStatus={customerStatus} />
                </Typography>
              </>
            </StpNumber>
          </DivStatusContainer>
          {(customerStatus === CustomerStatus.SOLD ||
            customerStatus === CustomerStatus.LOST) && (
            <Button
              adminColor
              onClick={() => {
                setShowStatus(true);
              }}
            >
              {t("moveToImplementation")}
            </Button>
          )}
          {customerStatus === CustomerStatus.IMPLEMENTATION && (
            <StatusDualButton
              adminColor
              label={statusText}
              onClick={() => handleDualButton()}
            >
              <MenuItem onClick={() => setStatusText(t("moveToActive"))}>
                {t("moveToActive")}
              </MenuItem>
              <MenuItem onClick={() => setStatusText(t("moveToLost"))}>
                {t("moveToLost")}
              </MenuItem>
            </StatusDualButton>
          )}
          {customerStatus === CustomerStatus.ACTIVE && (
            <Button
              adminColor
              onClick={() => {
                setShowStatus(true);
              }}
            >
              {t("moveToLost")}
            </Button>
          )}
        </ButtonContainer>
      </CustomerContainer>
    </div>
  );
};
