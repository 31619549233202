import React, { useState } from "react";
import { TextField, Button, Alert, Typography } from "@octanner/prism-core";
import { useTranslation } from 'react-i18next';
import {
  CoreValuesContainer, Header, HeaderContainer, CancelButton, ListContainer, FlexContainer,
  ListHeader
  } from './style';
import { AlertProps } from './type'
import { useMutation } from '@apollo/client';
import { CREATE_CORE_VALUE, CHANGE_STATUS_OF_CORE_VALUE, CREATE_STRING_ID } from './query';
import { customerId } from '../../utilities/utility';
import CoreValue from './CoreValue';
import ConfirmationModal from './ConfirmatioModal';

 const CoreValues = ({
  allCoreValues,
  refetchAllCoreValues,
  onPermissionError,
  setUpdateSapError,
}) => {
  const { t } = useTranslation();
   let  tempCoreValues = allCoreValues?.customerCoreValues.coreValues.nodes.map((item)=>{
     return {
       name : item.coreValueName,
       isActive : item.status!="inactive",
       anchorEls : null,
       id : item.customerCoreValueId,
       languageStringId: item.languageStringId
     }
   }) || [];

  const [createCoreValue , {loading:createCoreValueLoading}] = useMutation(CREATE_CORE_VALUE);
  const [updateCoreValueStatus ] = useMutation(CHANGE_STATUS_OF_CORE_VALUE);
  const [createStringId , {loading:createStringIdLoading}] = useMutation(CREATE_STRING_ID);
  const [openConfirmationModal, setOpenConfirmationModal] = useState<boolean>(false);
  const [CoreValuesList, setCoreValuesList] = useState(tempCoreValues);
  const [addCoreValue, setAddCoreValue] = useState('');
  const [addCoreValueError, setAddCoreValueError] = useState(false);
  const [alertData,setAlertData]=useState<AlertProps>({
    name : '',
    severity: 'success',
  });
  const [modalEntity, setModalEntity] = useState({
    desc : '',
    confirmAction : () => {},
    color : '',
    actionText : 'Delete'
  });
  const [loading, setLoading] = useState<boolean>(false);
  const handleAddCoreValue = () => {
    if(addCoreValue.length>145)return;
    const index = CoreValuesList.findIndex((item) => item.name === addCoreValue);
    if(index === -1){
      createStringId({
        variables : {
          input : {
            languageStringText : addCoreValue,
            languageStringNotes : null,
            enableVoiceDescriptionInd : false
          }
          }
        }).then((data)=>{
          const { stringId } = data?.data?.lsCreateLanguageStringInUSEnglishLocale?.languageString;
          createCoreValue({
            variables : {
              input : {
                customerUuid : customerId,
                coreValueName : addCoreValue,
                languageStringId: stringId,
                status: "active"
              }
            }
          }).then ((data)=>{
          refetchAllCoreValues().then(() => {
            const { customerCoreValueId, coreValueName } = data.data.createCustomerCoreValue
            const currentCoreValue = {
              name : coreValueName,
              isActive : true,
              anchorEls : null,
              id : customerCoreValueId,
              languageStringId: stringId
            }
            setCoreValuesList([...CoreValuesList, currentCoreValue]);
            setAlertData({
              name: t('core_value_added_sucessfully'),
              severity: 'success',
            });
            setAddCoreValueError(false);
            setAddCoreValue('');
          });
        }).catch((error) => {
          onPermissionError(error?.message);
        })
      })

    } else {
      setAddCoreValueError(true);
    }
  };

  const handleAllStatusChange = (status) => {
    const allCustomerCoreValueIds = CoreValuesList.map((item)=>{
        return item.id
    });
    setLoading(true);
    updateCoreValueStatus(
        {
            variables : {
                input : {
                    customerUuid : customerId,
                    customerCoreValueId : allCustomerCoreValueIds,
                    status: status
                }
            }
        }
    ).then((data)=>{
        refetchAllCoreValues().then(() => {
          let tempCoreValues = data.data.updateCustomerCoreValuesStatus.coreValues.nodes;
          let tempCoreValue = tempCoreValues.map((item)=>{
              return{
                  name : item.coreValueName,
                  isActive : item.status==="active",
                  anchorEls : null,
                  id : item.customerCoreValueId,
                  languageStringId: item.languageStringId
              }
          });
          setCoreValuesList(tempCoreValue);
          setAlertData({
              name: `All core values ${status === 'active' ? 'activated' : 'deactivated'} successfully.`,
              severity:'success',
          });
          setLoading(false);
          setOpenConfirmationModal(false);
        });
    }).catch((error)=>{
      if(error?.message?.includes('OCT Permission not match')){
        onPermissionError(error?.message);
      }
      else{
        setAlertData({
            name: `An error occurred when trying to ${status==='active' ? 'activate' : 'deactivate'} the core values. Please try again.`,
            severity:'error',
        })};
        setOpenConfirmationModal(false);
    })
}

  return (
    <CoreValuesContainer>
      {
        alertData.name!='' && (
          <div style={{ width: '800px', margin : '16px 0px'}}>
            <Alert
              data-testid='test-alert'
              severity={alertData.severity}
              onClose={() => setAlertData({ name : '', severity: 'success'})}
            >
              {alertData.name}
            </Alert>
          </div>
        )
      }
      <ConfirmationModal
        openConfirmationModal={openConfirmationModal}
        modalEntity={modalEntity}
        setOpenConfirmationModal={setOpenConfirmationModal}
        sumbitLoading={loading}
      />
      <div>
          <Header variant="h3">{t('add_core_values')}</Header>
          <TextField
            inputProps={{ "data-testid": "enter-name" }}
            label={t('enter_name')}
            fullWidth
            value={addCoreValue}
            onChange={(e) => {
              setAddCoreValueError(false);
              setAddCoreValue(e.target.value);
              setAlertData({
                name: '',
                severity: 'success',
              });
              setUpdateSapError(false);
            }}
            error={addCoreValueError}
            helperText={
              addCoreValue.length > 145 ? t('char_limit_warning') : addCoreValueError && t('duplicate_name_error')
            }
            displayCount
            maxLength={145}
            disabled={createCoreValueLoading}
          />
        <HeaderContainer>
          <Button
            data-testid="test-add-value"
            adminColor
            onClick={() => handleAddCoreValue()}
            disabled={addCoreValue.length === 0}
            loading={createCoreValueLoading || createStringIdLoading}
          >
            {t('add_value')}
          </Button>
          <CancelButton color="secondary" data-testid="corevalues-cancel-button"
            onClick={() => { setAddCoreValue(''); setAddCoreValueError(false); }}
          >{t('cancel')}</CancelButton>
        </HeaderContainer>
      </div>
      {
        CoreValuesList.length === 0 ? (
          <>
            <ListHeader variant="h4" data-testid="test-core-value">{t('core_values')}</ListHeader>
            <Typography
              data-testid="no-core-value"
              variant="body2"
              color="textSecondary"
              align="center"
              style={{ marginTop: '16px' }}
            >
              {t('no_core_values')}
            </Typography>

          </>
        ) : (<>
          <ListContainer>
            <FlexContainer>
              <ListHeader variant="h4">{t('core_values')}</ListHeader>
              <div>
              <Button
                data-testid="deactivate"
                  variant="text"
                  disabled={!CoreValuesList.some(item => item.isActive)}
                  onClick={() => {
                    setModalEntity({
                      desc : t('deactivate_all_confirm'),
                      confirmAction : () => handleAllStatusChange('inactive'),
                      color : 'error',
                      actionText : t('deactivate')
                    });
                    setOpenConfirmationModal(true);
                  }}
                color="secondary"
                buttonType="danger"
                >
                  {t('deactivate_all')}
                </Button>
                  <Button
                    data-testid="test-activate"
                    variant="text"
                    disabled={!CoreValuesList.some(item => !item.isActive)}
                    onClick={() => {
                      setModalEntity({
                        desc : t('activate_all_confirm'),
                        confirmAction : () => handleAllStatusChange('active'),
                        color : 'primary',
                        actionText : t('activate')
                      });
                      setOpenConfirmationModal(true);

                    }}
                  >
                  {t('activate_all')}
                </Button>
              </div>
            </FlexContainer>
            {
              CoreValuesList.map((item, index) => {
                return (
                  <CoreValue
                    item={item}
                    index={index}
                    setCoreValuesList={setCoreValuesList}
                    setAlertData={setAlertData}
                    CoreValuesList={CoreValuesList}
                    refetchAllCoreValues={refetchAllCoreValues}
                    onPermissionError={onPermissionError}
                  />
                )
              })
            }
          </ListContainer>
        </>)
      }
    </CoreValuesContainer>
  );
};

export default CoreValues;
