import { MouseEvent, useContext, useEffect, useState } from 'react';
import { Button } from '@octanner/prism-core';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import BasicPadding from '../../../common/BasicPadding';
import GeneralBankInfo from '../sections/generalInfo';
import SalesOrgBankInfo from '../sections/salesOrg';
import { CustomerContext } from '../../../utils/context/CustomerContext';
import {
  LoadingWrapper,
  MBAlert,
  NewBankTypography,
  SaveBankButton,
} from './styles';
import {
  BankKeys,
  ExtendedBankById,
  BankPayload,
  InitialBankData,
  LocationState,
  ModifiedPointBankSalesOrges,
} from './types';
import { INITIAL_BANK_STATE } from './constants';
import { CloseSharp } from '@material-ui/icons';
import { ArrowLeft } from '@octanner/prism-icons';
import {
  useCountrySalesOrgGroupedByCountry,
  useGetBankById,
  useUpdateBank,
} from './graphql';
import {
  AwardShippingValuesPhase1,
  AwardShippingValuesPhase2,
  ModifiedSalesOrgAndCountry,
  ModifiedSalesOrgAndCountryGroupByCountry,
  ValueOf,
} from '../sections/salesOrg/types';
import { useParams, useHistory } from 'react-router-dom';
import InvoiceTextFieldsBankInfo from '../sections/invoiceTextFields';
import { useFlags } from 'launchdarkly-react-client-sdk';

const CreateBank = () => {
  const { ccAdminInvoiceTextFields } = useFlags();
  const { bankId } = useParams<{ bankId: string }>();
  const history = useHistory();
  const {
    customer: { id: customerId },
  } = useContext(CustomerContext);

  const { data: bankData, loading } = useGetBankById({
    customerUuid: customerId,
    pointBankUuid: bankId,
  });

  const { data: groupByCountryData } = useCountrySalesOrgGroupedByCountry({
    customerId,
  });

  const [newBankData, setNewBankData] = useState<
    ExtendedBankById | InitialBankData
  >(INITIAL_BANK_STATE);
  const [pageLoading, setPageLoading] = useState<boolean>(true);
  const [hasCountry, setHasCountry] = useState(true);
  const [hasName, setHasName] = useState(true);
  const [submitted, setSubmitted] = useState(false);
  const [genericError, setGenericError] = useState(false);
  const [updateBank] = useUpdateBank();
  const [checkDates, setCheckDates] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [customerSalesOrgByCountryData, setCustomerSalesOrgByCountryData] =
    useState<ModifiedSalesOrgAndCountryGroupByCountry[]>([]);
  const [createdBankSuccessMsg, setCreatedBankSuccessMsg] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);

  const location = useLocation<LocationState>();
  const newBankSuccessMessage = location.state?.successMessage;

  useEffect(() => {
    // ? Check here for complete data!
    setPageLoading(false);
  }, []);

  useEffect(() => {
    if (bankData) {
      setNewBankData(bankData.corePtMgmtGetBankInformation.information);
      setDataLoaded(true);
    }

    if (newBankSuccessMessage) {
      setCreatedBankSuccessMsg(true);
    }
  }, [bankData, newBankSuccessMessage]);

  const handleSalesOrgSort = (
    salesOrgMap: ModifiedSalesOrgAndCountryGroupByCountry[]
  ) => {
    return salesOrgMap.sort((a, b) => {
      const nameA = a.assignedCountry.countryName.toUpperCase();
      const nameB = b.assignedCountry.countryName.toUpperCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
  };

  useEffect(() => {
    if (groupByCountryData) {
      setCustomerSalesOrgByCountryData(
        handleSalesOrgSort(
          groupByCountryData?.customerCountrySalesOrgMapsGroupByCountry
            ?.customerSalesOrgGroupByCountry || []
        )
      );
    }
  }, [groupByCountryData]);

  useEffect(() => {
    if (bankData && customerSalesOrgByCountryData) {
      const activeData =
        bankData.corePtMgmtGetBankInformation.information.pointBankSalesOrges.map(
          ({
            customerCountrySalesOrgId,
            customerCountrySalesOrg,
            shippingLocationType,
          }: ModifiedSalesOrgAndCountry) => ({
            customerCountrySalesOrgId,
            salesOrgCode:
              customerCountrySalesOrg?.assignedSalesOrg.salesOrgCode,
            shippingLocationType,
          })
        ) || [];

      const updatedData1 = customerSalesOrgByCountryData.map((entry) => {
        const { assignedCountry, group } = entry;
        const match = group
          .map((item) => {
            const matchData = activeData.find(
              (activeItem) =>
                activeItem.customerCountrySalesOrgId ===
                item.customerCountrySalesOrgId
            );

            if (matchData) {
              return {
                salesOrgCode: matchData.salesOrgCode,
                shippingLocationType: matchData.shippingLocationType,
              };
            }
            return undefined;
          })
          .filter((item) => item !== undefined)[0];

        const enabled = !!match;
        const value = match ? match.shippingLocationType : 'VARIES_PER_USER';
        const selectedSalesOrg = match
          ? match.salesOrgCode
          : group[0].assignedSalesOrg.salesOrgCode;

        const updatedAssignedCountry = {
          ...assignedCountry,
          enabled,
          value: value as AwardShippingValuesPhase1 | AwardShippingValuesPhase2,
          selectedSalesOrg,
        };

        const updatedGroup = group.map(({ coreCountry, ...rest }) => ({
          coreCountry: {
            ...coreCountry,
          },
          ...rest,
        }));

        return {
          ...entry,
          assignedCountry: updatedAssignedCountry,
          group: updatedGroup,
        };
      });

      const { poNumberType, ...billingConfigWithoutPoNumberType } =
        bankData.corePtMgmtGetBankInformation.information.billingConfig || {};

      setNewBankData((prev) => ({
        ...prev,
        pointBankSalesOrgesByCountry: updatedData1,
        poNumberType,
        billingConfig: {
          ...billingConfigWithoutPoNumberType,
        },
      }));
    }
  }, [bankData, customerSalesOrgByCountryData]);

  const handleValueUpdate = (key: BankKeys, value: ValueOf<BankKeys>) => {
    setNewBankData((prev) => ({ ...prev, [key]: value }));
  };

  const modifySalesOrgData = () => {
    const modifiedData: ModifiedPointBankSalesOrges[] = [];

    newBankData.pointBankSalesOrgesByCountry.forEach((entry) => {
      const { assignedCountry, group } = entry;

      if (assignedCountry.enabled) {
        const { selectedSalesOrg, value: shippingLocationType } =
          assignedCountry;

        group.forEach(({ customerCountrySalesOrgId, assignedSalesOrg }) => {
          if (selectedSalesOrg === assignedSalesOrg.salesOrgCode) {
            modifiedData.push({
              customerCountrySalesOrgId: customerCountrySalesOrgId,
              shippingLocationType: shippingLocationType as
                | AwardShippingValuesPhase2
                | AwardShippingValuesPhase1,
            });
          }
        });
      }
    });

    return modifiedData;
  };

  const resetState = () => {
    // ? State reset
    setCheckDates(false);
    setSubmitted(true);
    setHasName(true);
    setHasCountry(true);
    setGenericError(false);
    setSuccessMessage(false);
  };

  const handleSubmit = (e: MouseEvent) => {
    e.preventDefault();
    resetState();

    const modifiedPointBankSalesOrges = modifySalesOrgData();
    const bankNameLength = newBankData.pointBankName.length;
    const salesOrgCount = modifiedPointBankSalesOrges.length;
    const pointsExpiryType =
      newBankData?.pointExpiryConfiguration?.pointExpiryType;
    const startDate =
      newBankData.pointExpiryConfiguration.fixedDates?.[0]?.startDateTsz ?? '';
    const endDate =
      newBankData.pointExpiryConfiguration.fixedDates?.[0]?.endDateTsz ?? '';
    const expirationDate =
      newBankData.pointExpiryConfiguration.fixedDates?.[0]?.expirationDateTsz ??
      '';
    const {
      invoiceFieldEntity1,
      invoiceFieldEntity2,
      invoiceFieldEntity3,
      invoiceFieldEntity4,
    } = newBankData?.invoiceFieldConfig || {};
    if (!bankNameLength) {
      setHasName(false);
    }
    if (!salesOrgCount) {
      setHasCountry(false);
    }

    if (pointsExpiryType === 'FIXED_DATE') {
      if (!startDate || !endDate || !expirationDate) {
        setCheckDates(true);
      }
    }
    if (bankNameLength && salesOrgCount && !checkDates) {
      // TODO: Fix those 2 ts-ignores the second phase 2 bank goes live, then fix the typings...
      const payload: BankPayload = {
        request: {
          customerUuid: customerId,
          pointBankUuid: bankId,
          defaultAccruingBank: newBankData.defaultAccruingBank,
          pointBankName: newBankData.pointBankName,
          pointBankSalesOrges: modifiedPointBankSalesOrges,
          poNumberType: newBankData.poNumberType,
          billingLocationUuid:
            newBankData.billingConfig?.billingLocation?.locationUuid,
          billingLocationType: newBankData.billingConfig?.billingLocationType,
          billingLocationRecipientType:
            newBankData.billingConfig?.billingLocationRecipientType,
          pointExpiryConfiguration: {
            pointExpiryType:
              newBankData.pointExpiryConfiguration.pointExpiryType,
            monthlyRollingExpirationNum:
              newBankData.pointExpiryConfiguration.monthlyRollingExpirationNum,
            fixedDates:
              newBankData?.pointExpiryConfiguration?.fixedDates?.map((x) => ({
                startDateTsz: x?.startDateTsz ?? null,
                endDateTsz: x?.endDateTsz ?? null,
                expirationDateTsz: x?.expirationDateTsz ?? null,
              })) || null,
          },
          invoiceFieldConfig: {
            invoiceField1: invoiceFieldEntity1?.code || null,
            invoiceField2: invoiceFieldEntity2?.code || null,
            invoiceField3: invoiceFieldEntity3?.code || null,
            invoiceField4: invoiceFieldEntity4?.code || null,
          },
        },
      };
      const pointsExpiryType =
        payload.request.pointExpiryConfiguration.pointExpiryType;
      if (pointsExpiryType === 'FIXED_DATE') {
        payload.request.pointExpiryConfiguration.monthlyRollingExpirationNum =
          null;
      } else if (pointsExpiryType === 'MONTHLY_ROLLING_EXPIRATION') {
        payload.request.pointExpiryConfiguration.fixedDates = null;
      } else {
        payload.request.pointExpiryConfiguration.pointExpiryType =
          'NO_EXPIRATION';
        payload.request.pointExpiryConfiguration.fixedDates = null;
        payload.request.pointExpiryConfiguration.monthlyRollingExpirationNum =
          null;
      }

      const pointBankSalesOrgesFilter = payload.request.pointBankSalesOrges;

      payload.request.pointBankSalesOrges = pointBankSalesOrgesFilter;
      window.scrollTo({ top: 0, behavior: 'smooth' });
      updateBank({ variables: payload })
        .then(() => {
          setSuccessMessage(true);
        })
        .catch((e) => setGenericError(true));
    }
  };

  if (pageLoading || loading || !dataLoaded) {
    return <LoadingWrapper />;
  }

  const handleCancel = () => {
    history.push(`/customer/${customerId}/banks`);
  };

  return (
    <BasicPadding padding="sm">
      <Button
        variant="text"
        data-test="new-bank:back"
        component={RouterLink}
        //@ts-ignore prism issue!
        to={`/customer/${customerId}/banks`}
        underline="none"
        startIcon={<ArrowLeft />}
      >
        View All Banks
      </Button>
      <NewBankTypography variant="h1">Edit Bank</NewBankTypography>
      {!hasName && (
        <MBAlert
          severity="error"
          action={<CloseSharp onClick={() => setHasName(true)} />}
        >
          Bank name is required information.
        </MBAlert>
      )}
      {!hasCountry && (
        <MBAlert
          severity="error"
          action={<CloseSharp onClick={() => setHasCountry(true)} />}
        >
          There are no countries included in this bank.
        </MBAlert>
      )}
      {genericError && (
        <MBAlert
          severity="error"
          action={<CloseSharp onClick={() => setGenericError(false)} />}
        >
          An error has occured. Please try again later.
        </MBAlert>
      )}

      {checkDates && (
        <MBAlert
          severity="error"
          action={<CloseSharp onClick={() => setCheckDates(false)} />}
        >
          There are no date ranges selected.
        </MBAlert>
      )}
      {(successMessage || createdBankSuccessMsg) && (
        <MBAlert
          severity="success"
          action={
            <CloseSharp
              onClick={() => {
                setSuccessMessage(false);
                setCreatedBankSuccessMsg(false);
              }}
            />
          }
        >
          Bank Saved Successfully!
        </MBAlert>
      )}

      {newBankData && (
        <GeneralBankInfo
          type="edit"
          bankData={newBankData}
          handleValueUpdate={handleValueUpdate}
          submitted={submitted}
          checkDates={checkDates}
        />
      )}
      {ccAdminInvoiceTextFields && (
        <InvoiceTextFieldsBankInfo
          type="edit"
          bankData={newBankData}
          handleValueUpdate={handleValueUpdate}
        />
      )}

      <SalesOrgBankInfo
        type="edit"
        bankData={newBankData}
        handleValueUpdate={handleValueUpdate}
        submitted={submitted}
        error={!hasCountry}
      />
      <SaveBankButton adminColor onClick={(event) => handleSubmit(event)}>
        Save Bank
      </SaveBankButton>
      <Button color="secondary" onClick={handleCancel}>
        Cancel
      </Button>
    </BasicPadding>
  );
};

export default CreateBank;
