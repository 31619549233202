import { useLazyQuery } from "@apollo/client";
import React, { createContext, PropsWithChildren, useState } from "react";
import { translationCategories } from "../utils/constants";
import { defaults } from "../utils/default";
import { FETCH_UNTRANSLATED_STRING_COUNT, FETCH_UNTRANSLATED_STRING_LIST } from "../utils/query";
import { AlertMessage, TranslationRequestContextType, unTranslatedLocale, UnTranslatedString, UntranslatedStringCountAllLocale, UntranslatedStringList } from "../utils/types";

const TranslationRequestContext = createContext<TranslationRequestContextType>({
  selectedLocale: null,
  setSelectedLocale: () => { },
  translationCategory: '',
  setTranslationCategory: () => { },
  searchLoading: false,
  fetchUntranslatedStringList: () => { },
  setUnTranslatedStringList: () => { },
  unTranslatedStringList: [],
  unTranslatedStringData: null,
  createdJobNumber: null,
  setCreatedJobNumber: () => { },
  setUnTranslatedStringData: () => { },
  messages: [],
  closeMessage: () => { },
  setMessageText: () => { },
  refetchUntranslatedStringList: () => { },
  pageNumber: 1,
  setPageNumber: () => { },
  refetchUntranslatedStringListData: () => { },
  searchId: '',
  setSearchId: () => { },
  selectAll: false,
  setSelectAll: () => { },
  fetchUntranslatedAllLocaleCount: () => { },
  unTranslatedLocaleDataLoading: false,
  allLocales: [],
  setAllLocales: () => { }
})

const TranslationRequestContextProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {

  const [selectedLocale, setSelectedLocale] = useState<unTranslatedLocale>(null);
  const [allLocales, setAllLocales] = useState<unTranslatedLocale[]>([]);
  const [translationCategory, setTranslationCategory] = useState<string>(translationCategories.LANGUAGE_STRING.value)
  const [unTranslatedStringData, setUnTranslatedStringData] = useState<UntranslatedStringList>(null);
  const [unTranslatedStringList, setUnTranslatedStringList] = useState<Partial<UnTranslatedString>[]>([]);
  const [createdJobNumber, setCreatedJobNumber] = useState<number>(0);
  const [searchId, setSearchId] = useState<string>('');
  const [selectAll, setSelectAll] = useState<boolean>(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [messages, setMessages] = useState<AlertMessage[]>([
    { key: 'infoMessage', text: defaults.select_request_info, severity: 'info', gridSize: 5 },
    { key: 'errorMessage', text: '', severity: 'error', gridSize: 8 },
    { key: 'successMessage', text: '', severity: 'success', gridSize: 8 },
    { key: 'releaseRequestInfo', text: '', severity: 'warning', gridSize: 8 },
    { key: 'selectOnHoldInfo', text: '', severity: 'warning', gridSize: 12 }
  ]);

  const [fetchUntranslatedStringList, { loading: searchLoading,
    refetch: refetchUntranslatedStringList, }] = useLazyQuery<UntranslatedStringList>(FETCH_UNTRANSLATED_STRING_LIST, {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (data?.lsFetchUntranslatedStringListForLocaleAndCategory?.untranslatedStringList) {
          setUnTranslatedStringList(data?.lsFetchUntranslatedStringListForLocaleAndCategory?.untranslatedStringList.map((item) => {
            item.selected = false;
            return item;
          }));
          if (searchId === '' && selectedLocale[translationCategories[translationCategory].countVariableName] !==
            data.lsFetchUntranslatedStringListForLocaleAndCategory.pageInfo.totalItemCount) {
            setSelectedLocale(prevLocale => {
              const newSelectedLocale = { ...prevLocale };
              newSelectedLocale[translationCategories[translationCategory].countVariableName] =
                data.lsFetchUntranslatedStringListForLocaleAndCategory.pageInfo.totalItemCount;
              return newSelectedLocale;
            });
            const newLocalesWithCount = allLocales.map((localeCountObj: unTranslatedLocale) => {
              if (localeCountObj.locale === selectedLocale.locale) {
                const newSelectedLocale = { ...selectedLocale };
                newSelectedLocale[translationCategories[translationCategory].countVariableName] =
                  data.lsFetchUntranslatedStringListForLocaleAndCategory.pageInfo.totalItemCount;
                return newSelectedLocale;
              }
              return localeCountObj;
            });
            setAllLocales(newLocalesWithCount);
          }
        }
        setUnTranslatedStringData(data);
      },
      onError: () => {
        setMessageText('errorMessage', defaults.fetch_translation_request_error);
        setUnTranslatedStringList([]);
        setUnTranslatedStringData(null);
      }
    });

  const [fetchUntranslatedAllLocaleCount, { loading: unTranslatedLocaleDataLoading, data: unTranslatedLocaleData }] =
    useLazyQuery<UntranslatedStringCountAllLocale>(FETCH_UNTRANSLATED_STRING_COUNT, {
      onCompleted: () => {
        setMessageText('successMessage', '');
        setMessageText('errorMessage', '');
        setAllLocales(unTranslatedLocaleData.lsFetchUntranslatedStringCountForAllLocale);
        setSelectedLocale((prev) => {
          const matchedLocale = unTranslatedLocaleData.lsFetchUntranslatedStringCountForAllLocale.
            find((untranslatedLocale) => untranslatedLocale.locale === prev.locale);
          return matchedLocale;
        });
      },
      onError: () => {
        setMessageText('errorMessage', defaults.fetch_translation_request_error);
      }
    });

  const closeMessage = (messageKey: string) => {
    setMessages(prevMessages =>
      prevMessages.map(message =>
        message.key === messageKey ? { ...message, text: '' } : message
      )
    );
  }

  const setMessageText = (messageKey: string, text: string) => {
    setMessages(prevMessages =>
      prevMessages.map(message =>
        message.key === messageKey ? { ...message, text: text } : message
      )
    );
  }

  const refetchUntranslatedStringListData = (statusUniqueIdList?: number[]) => {
    if (statusUniqueIdList && pageNumber > 1 &&
      unTranslatedStringData.lsFetchUntranslatedStringListForLocaleAndCategory.pageInfo.totalPageCount
      === pageNumber && statusUniqueIdList.length === unTranslatedStringList.length) {
      setPageNumber(pageNumber - 1);
    } else {
      refetchUntranslatedStringList();
    }
    if (selectAll) {
      setSelectAll(false);
    }
    closeMessage('releaseRequestInfo');
    closeMessage('selectOnHoldInfo');
  }


  return (
    <TranslationRequestContext.Provider value={{
      selectedLocale,
      setSelectedLocale,
      translationCategory,
      setTranslationCategory,
      fetchUntranslatedStringList,
      setUnTranslatedStringList,
      unTranslatedStringData,
      unTranslatedStringList,
      searchLoading,
      createdJobNumber,
      setCreatedJobNumber,
      setUnTranslatedStringData,
      messages,
      closeMessage,
      setMessageText,
      refetchUntranslatedStringList,
      pageNumber,
      setPageNumber,
      refetchUntranslatedStringListData,
      searchId,
      selectAll,
      setSelectAll,
      setSearchId,
      fetchUntranslatedAllLocaleCount,
      unTranslatedLocaleDataLoading,
      allLocales,
      setAllLocales
    }}>
      {children}
    </TranslationRequestContext.Provider>
  )
}

export { TranslationRequestContext, TranslationRequestContextProvider };
