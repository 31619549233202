import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@octanner/prism-core';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { BankSection } from '../../modifyBank/types';
import { ORG_COLUMNS_PHASE2, ORG_COLUMNS_PHASE1 } from './constants';

import OrgRow from './orgRow';
import { MBDiv, PLDiv, SalesOrgTitle } from './styles';

const SalesOrgBankInfo = ({
  type,
  submitted,
  error,
  bankData,
  handleValueUpdate,
}: BankSection) => {
  const { ccAdminSalesOrgAndCountries } = useFlags();
  return (
    <PLDiv>
      <SalesOrgTitle variant="h2">Sales orgs and countries</SalesOrgTitle>
      {submitted && error && (
        <Typography style={{ color: '#D10A12' }}>
          At least one country must be included in this bank.
        </Typography>
      )}
      <MBDiv />
      <Table>
        <TableHead>
          <TableRow>
            {ccAdminSalesOrgAndCountries
              ? ORG_COLUMNS_PHASE2.map(({ key, title, cellStyle }, i) => (
                  <TableCell style={cellStyle} key={key + i}>
                    <Typography variant="h3" gutterBottom>
                      {title}
                    </Typography>
                  </TableCell>
                ))
              : ORG_COLUMNS_PHASE1.map(({ key, title, cellStyle }, i) => (
                  <TableCell style={cellStyle} key={key + i}>
                    <Typography variant="h3" gutterBottom>
                      {title}
                    </Typography>
                  </TableCell>
                ))}
          </TableRow>
        </TableHead>
      </Table>
      {bankData.pointBankSalesOrgesByCountry.map((row, i) => (
        <OrgRow
          key={i}
          row={row}
          handleValueUpdate={handleValueUpdate}
          bankData={bankData}
          bankDataIndex={i}
        />
      ))}
      <MBDiv />
    </PLDiv>
  );
};

export default SalesOrgBankInfo;
