import { Dispatch, ReactElement, SetStateAction } from 'react';

export interface SortConfig<T = string> {
  key: T;
  direction: 'ASC' | 'DESC';
}

export interface TableRowHeader<T = string> {
  id: string;
  label: string;
  align: 'center' | 'left' | 'right';
  sortKey?: T;
}

export interface TableRows {
  key: string;
  test: string;
  align: 'center' | 'left' | 'right';
  customComponent?: (row: any) => ReactElement;
}

interface RoleType {
  key: string;
  displayName: string;
  description: string;
  variant: TagVariantColor;
}

export const roleTypeValues: Record<string, RoleType> = {
  GLOBAL: {
    key: 'GLOBAL',
    displayName: 'Global tools',
    description: 'Grant access to global tools for CC Admin',
    variant: 'info',
  },
  ADMIN: {
    key: 'ADMIN',
    displayName: 'Client admin',
    description: 'Grant access to administer clients in CC Admin',
    variant: 'archived',
  },
};

export type ButtonColor =
  | 'inherit'
  | 'primary'
  | 'secondary'
  | 'error'
  | 'white'
  | 'black'
  | 'success'
  | 'info'
  | 'warning'
  | 'blue';

export type AlertVariantColor = 'success' | 'info' | 'warning' | 'error';

export type TagVariantColor =
  | 'success'
  | 'info'
  | 'warning'
  | 'error'
  | 'archived';

export type Permission = {
  uuid: string;
  // scope: Scope;
  name: string;
  // code: string;
  // internalUse: Boolean
  // accessType: RoleType;
};

export type AdminRoleInformation = {
  name: string;
  role: RoleInformation;
  assignedOnDate?: string;
  removedOnDate?: string;
  permissionCount: number;
  adminCount: number;
  clientCount: number;
};

export type RdRoleInformation = {
  uuid: string;
  name: string;
  description: string;
  accessType: string;
  permissions: Permission[];
};

export type RoleInformation = {
  // id: Long!
  uuid: string;
  // customer: Customer!
  // parentRole: Role
  name: string;
  description?: string;
  accessType: string;
  // admin: Boolean
  // permissions: Permission[];
  createdTsz: string;
  deactivatedTsz?: string;
};

export interface RoleInformationWithPermissions extends RoleInformation {
  permissions: Permission[];
}

export type ErrorData = {
  error: boolean;
  message: string;
  fieldMessage?: string;
};

export type Errors = {
  key: string;
  errorData: ErrorData;
  setErrorData: Dispatch<SetStateAction<ErrorData>>;
};

export type SuccessData = { enabled: boolean; message: string };

export type Success = {
  successData: SuccessData;
  setSuccessData: Dispatch<SetStateAction<SuccessData>>;
  color?: AlertVariantColor;
};

export interface BaseModalProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onConfirm: () => void;
  color?: ButtonColor;
  adminColor?: boolean;
}
