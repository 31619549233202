import { createContext } from 'react';

//used React.createContext to make customerId available to ApproverSearch without drilling all the way down
interface CustomerIdContextType {
  customerId: string;
}

const CustomerIdContext = createContext<CustomerIdContextType>({
  customerId: '',
});

export default CustomerIdContext;
