import { Button, Typography } from '@octanner/prism-core';
import { Dialog, DialogTitle } from '@mui/material';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Close } from '@octanner/prism-icons';
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: 20,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    },
    titleSpacing: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
      width: '100%',
    },
    close: {
      cursor: 'pointer',
    },
    actions: {
      marginTop: 24,
      display: 'flex',
      gap: 4,
    },
  })
);

export interface BinaryDialogProps {
  title: string;
  description: string;
  primaryText: string;
  onPrimaryClick: () => void;
  secondaryText?: string;
  onSecondaryClick: () => void;
  open: boolean;
}

interface ActionDialogProps {
  actions: JSX.Element | JSX.Element[] | any;
  children: JSX.Element | JSX.Element[];
  open: boolean;
  title: string;
  onClose: () => any;
}

export const ActionDialog = ({
  actions,
  children,
  open,
  title,
  onClose,
  ...props
}: ActionDialogProps) => {
  const classes = useStyles();
  return (
    <Dialog {...props} fullWidth maxWidth="xs" open={open} onClose={onClose}>
      <div className={classes.root}>
        <div className={classes.titleSpacing}>
          <Close className={classes.close} onClick={onClose} />
        </div>
        <DialogTitle alignSelf={'center'}>
          <Typography variant={'h2'} fontWeight={'bold'}>
            {title}
          </Typography>
        </DialogTitle>
        {children}
        <div className={classes.actions}>{actions}</div>
      </div>
    </Dialog>
  );
};

const BinaryDialog = ({
  title,
  description,
  primaryText,
  secondaryText,
  onPrimaryClick,
  onSecondaryClick,
  open,
}: BinaryDialogProps) => {
  return (
    <ActionDialog
      actions={
        <>
          <Button onClick={onPrimaryClick} color="primary" adminColor>
            {primaryText}
          </Button>
          <Button onClick={onSecondaryClick} color="secondary">
            {secondaryText || 'Cancel'}
          </Button>
        </>
      }
      onClose={onSecondaryClick}
      open={open}
      title={title}
    >
      <Typography>{description}</Typography>
    </ActionDialog>
  );
};

export default BinaryDialog;
