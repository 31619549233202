import { styled } from '@material-ui/core';
import CircularProgress from './CircularProgress';

interface Props {
  height?: string | number;
  size?: string | number;
}

const LoadingWrapper = styled('div')<any, Props>(({ height = '25vh' }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height,
}));

const Loading = ({ size, ...props }: Props) => {
  return (
    <LoadingWrapper {...props} data-testid="loading-element">
      <CircularProgress size={size} />
    </LoadingWrapper>
  );
};

export default Loading;
