import { HeadCell, TableRows } from './types';
import StatusLabel from '../../common/statusLabel';
import { Tag } from '@octanner/prism-core';
import { DragHandleIcon, DurationWidth, NameColumn } from './styles';

export const headCells: HeadCell[] = [
  { id: 'name', label: 'Program name', align: 'left' },
  {
    id: 'feature',
    label: 'Program category',
    align: 'left',
  },
  {
    id: 'participants',
    label: 'Participants',
    align: 'right',
  },
  {
    id: 'startTsz',
    label: 'Duration',
    align: 'left',
  },
  { id: 'status', label: 'Status', align: 'left' },

  { id: 'uuid', label: 'Actions', align: 'center' },
];

export const tableRows: TableRows[] = [
  {
    key: 'name',
    test: 'name',
    align: 'right',
    customComponent: function nameLabel({ name }: { name: string }) {
      return (
        <NameColumn>
          <DragHandleIcon />
          {name || ''}
        </NameColumn>
      );
    },
  },
  {
    key: 'feature',
    test: 'feature-name',
    align: 'left',
  },
  {
    key: 'participants',
    test: 'count',
    align: 'right',
  },
  {
    key: 'startText',
    test: 'startTsz',
    align: 'left',
    customComponent: function startText({
      status,
      startText,
      endText,
    }: {
      status: string;
      startText: string;
      endText: string;
    }) {
      if (status === 'Active') {
        return (
          <DurationWidth>
            <Tag variant="success"> Started {startText}</Tag>{' '}
          </DurationWidth>
        );
      }

      if (status === 'Build' && startText !== '') {
        return (
          <DurationWidth>
            <Tag variant="info"> Scheduled {startText}</Tag>{' '}
          </DurationWidth>
        );
      }
      if (status === 'Completed') {
        return (
          <DurationWidth>
            <Tag variant="archived">
              {startText} - {endText}
            </Tag>
          </DurationWidth>
        );
      }
      return <DurationWidth> </DurationWidth>;
    },
  },
  {
    key: 'status',
    test: 'status',
    align: 'left',
    customComponent: function statusLabel({
      status,
      uuid,
    }: {
      status: string;
      uuid: string;
    }) {
      return (
        <StatusLabel status={status} dataTest={`programs:${uuid}:status`} />
      );
    },
  },
];

interface SegmentTypeMenu {
  key: string;
  value: string;
  test: string;
}

export const segmentTypeMenu: SegmentTypeMenu[] = [
  {
    key: 'all',
    value: 'All Participants',
    test: 'all',
  },
  {
    key: 'businessUnit',
    value: 'Business Unit',
    test: 'businessUnit',
  },
  { key: 'homeCountry', value: 'Home Country', test: 'homeCountry' },
  {
    key: 'workCountry',
    value: 'Work Country',
    test: 'workCountry',
  },
];
