import {
  Button,
  FormControlLabel,
  Switch,
  Typography,
} from '@octanner/prism-core';
import React, { useEffect, useState } from 'react';
import { useStyles } from './styles';
import { ContentVersionItemFormProps } from './types';
import BinaryDialog from '../../common/BinaryDialog';
import RichTextEditor from '../../common/slateEditor';
import { MyValue } from '../../common/slateEditor/plateTypes';

const ContentVersionItemForm = ({
  cloneVersion,
  onSave,
  onCancel,
  material,
  typeText,
  disableConfirmation,
}: ContentVersionItemFormProps) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [isMaterial, setMaterial] = useState(material || false);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState<MyValue>([
    { children: [{ text: '' }] },
  ] as MyValue);
  const handleChange = (val: MyValue) => {
    setValue(val);
  };

  useEffect(() => {
    setLoading(true);
    if (cloneVersion?.content) {
      setValue(
        (cloneVersion.content as MyValue) ||
          ([{ children: [{ text: '' }] }] as MyValue)
      );
    } else setValue([{ children: [{ text: '' }] }] as MyValue);
    // Same rerender issue inside slate, but for edit. TODO: Fix!!
    setTimeout(() => setLoading(false), 300);
  }, [cloneVersion]);

  const doSave = () => {
    if (value) {
      onSave({ content: value, isMaterial });
      setOpen(false);
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (isMaterial && !material && !disableConfirmation) {
      setOpen(true);
      return;
    }
    doSave();
  };

  const handleCancel = () => {
    setValue([{ children: [{ text: '' }] }] as MyValue);
    return onCancel();
  };

  return (
    <form
      onSubmit={(e) => handleSubmit(e)}
      className={classes.contentVersionItemFormRoot}
    >
      <BinaryDialog
        open={open}
        title="Material Version Confirmation"
        description={`This will enforce all users of this ${typeText} to re-accept. Are you sure you want this version to be 'Material'?`}
        primaryText="Yes"
        onPrimaryClick={doSave}
        secondaryText="No"
        onSecondaryClick={() => setOpen(false)}
      />
      <div>
        {!material ? (
          <FormControlLabel
            className={classes.materialSwitch}
            control={
              <Switch
                checked={material || isMaterial}
                onChange={(e) => setMaterial(e.target.checked)}
                name="isMaterial"
                data-test="version:isMaterial"
              />
            }
            label="Material"
          />
        ) : (
          <Typography>
            The first version for a given {typeText} and Locale must be
            Material.
          </Typography>
        )}
      </div>
      <div className={classes.editorContainer}>
        {!loading && (
          <RichTextEditor
            id="edit"
            disabled={['h4', 'h5', 'h6']}
            onChange={(val) => handleChange(val as MyValue)}
            value={value}
            initialValue={
              cloneVersion
                ? cloneVersion.content
                : ([{ children: [{ text: '' }] }] as MyValue)
            }
          />
        )}
      </div>
      <div className={classes.actions}>
        <div className={classes.leftAutoFlex}>
          <Button type="submit" adminColor>
            Save
          </Button>
          <Button
            color="secondary"
            onClick={handleCancel}
            className={classes.cancelButton}
          >
            Cancel
          </Button>
        </div>
      </div>
    </form>
  );
};

export default ContentVersionItemForm;
