import { useMutation } from '@apollo/client';
import {
  TURN_ON_PERSONAL_FEED,
  TURN_OFF_PERSONAL_FEED,
} from '../../graphql/socialWall/socialWall';
import {
  UpdatePersonalFeedOverrideInput,
  UpdatePersonalFeedOverrideResponse,
} from '../../models/socialWall';
import { GET_CUSTOMER } from '../../../../customer/graphql/customer';

export const useActivatePersonalFeed = (customerUuid: string) =>
  useMutation<
    UpdatePersonalFeedOverrideResponse,
    UpdatePersonalFeedOverrideInput
  >(TURN_ON_PERSONAL_FEED, {
    refetchQueries: [
      {
        query: GET_CUSTOMER,
        variables: { id: customerUuid, customerId: customerUuid },
      },
    ],
  });

export const useDeactivatePersonalFeed = (customerUuid: string) =>
  useMutation<
    UpdatePersonalFeedOverrideResponse,
    UpdatePersonalFeedOverrideInput
  >(TURN_OFF_PERSONAL_FEED, {
    refetchQueries: [
      {
        query: GET_CUSTOMER,
        variables: { id: customerUuid, customerId: customerUuid },
      },
    ],
  });
