import { useMutation } from '@apollo/client';
import styled from '@emotion/styled';
import { Button, Chip, Modal, Typography } from '@octanner/prism-core';
import React, { useContext, useEffect, useState } from 'react';
import { CustomTextContext } from '../../../Contexts/CustomTextContext';
import { LocaleContext } from '../../../Contexts/LocaleContext';
import { updateSuccessMessage } from '../../../utils/commonActions';
import { defaults } from '../../../utils/default';
import { CUSTOM_TEXT_RAISE_TRANSLATION_REQUEST } from '../../../utils/query';
import StyledTextField from '../../../utils/styledComponents';
import { Locale } from '../../../utils/types';
import SelectLanguage from '../../Common/SelectLanguage';

const Header = styled(Typography)`
  margin: 16px 0px;
`;

const Control = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
`;

const LanguageChip = styled(Chip)`
  margin-top: 12px;
  margin-right: 10px;
`;

const CustomTextRequestTranslationModal = ({
  showRequestTranslationModal,
  setShowRequestTranslationModal,
  note
}) => {

  const { setMessageText, targetLocales,
    customTextUniqueId, customTextNote, saveCustomTextNotes,
    setCustomTextRefetchInd, saveCustomTextNotesProcessing, setTargetLocale } = useContext(CustomTextContext);
  const { locales } = useContext(LocaleContext);
  const [localesToBeRequested, setLocalesToBeRequested] = useState<Locale[]>([]);
  const [localeList, setLocaleList] = useState<string[]>([]);
  const [notes, setNotes] = useState<string>(note || '');

  // localeWithDesc contains the selected locales along with their descriptions (not just the codes)
  const [localesWithDesc, setLocalesWithDesc] = useState<Locale[]>(null);
  const noNoteOrLocale = !(notes || localesWithDesc);
  const noteMatchesAndNoLocale = notes.trim() === customTextNote.trim() && !localesWithDesc;
  const [ctrUniqueId, setCtrUniqueId] = useState<string>(customTextUniqueId);
  const [sendCustomTextTranslationRequest, { loading: translationRequestProcessing }] = useMutation(CUSTOM_TEXT_RAISE_TRANSLATION_REQUEST, {
    onError: () => {
      setMessageText('errorMessage', defaults.update_error);
    },
    onCompleted: () => {
      setMessageText('createSuccessMessage', '');
      setMessageText('editSuccessMessage', '');
      setMessageText('errorMessage', '');
      updateSuccessMessage(localesWithDesc, defaults, setMessageText);
      setLocalesWithDesc(null);
      setCustomTextRefetchInd(true);
      setShowRequestTranslationModal(false);
    }
  });

  useEffect(() => {
    if (targetLocales && locales) {
      const filteredLocales = locales.filter((item: Locale) => !targetLocales.includes(item.code));
      setLocalesToBeRequested(filteredLocales);
    }
  }, [targetLocales, locales]);

  useEffect(() => {
    if (localesWithDesc) {
      setLocaleList(localesWithDesc.map((item: Locale) => item.code));
    }
    if (customTextUniqueId) {
      setCtrUniqueId(customTextUniqueId);
    }
  }, [localesWithDesc, customTextUniqueId])

  const closeModal = (): void => {
    if (translationRequestProcessing || saveCustomTextNotesProcessing) {
      return;
    }
    setLocalesWithDesc(null);
    setShowRequestTranslationModal(false);
  }

  const onRemoveLocale = (locale: Locale): void => {
    const updatedLocales = localesWithDesc.filter((previousLocale: Locale) => previousLocale.code !== locale.code);
    setLocalesWithDesc([...updatedLocales])
  }

  const handleRequest = (): void => {
    if (localeList?.length) {
      sendCustomTextTranslationRequest({
        variables: {
          input: {
            localeList,
            ctrUniqueId
          }
        }
      });
    }
    if (notes.trim() !== customTextNote.trim()) {
      saveCustomTextNotes({
        variables: {
          input: {
            notes: notes.trim(),
            customTextRequestUniqueId: customTextUniqueId
          },
        },
      }).then(() => {
        setShowRequestTranslationModal(false);
      });
    }
    setTargetLocale(null);
  }

  return (
    <Modal
      actions={<>
        <Button color="secondary" disabled={translationRequestProcessing || saveCustomTextNotesProcessing} onClick={() => closeModal()}>{
          defaults.cancel}
        </Button>
        <Button color="primary" disabled={noNoteOrLocale || noteMatchesAndNoLocale || (notes?.trim()?.length > 4000)}
          loading={translationRequestProcessing || saveCustomTextNotesProcessing} onClick={handleRequest}  >{defaults.send}</Button>
      </>}
      id="custom-text-request-translation-modal"
      title={defaults.send_translation}
      open={showRequestTranslationModal}
      onClose={() => closeModal()}>
      <Typography variant="body1">
        {defaults.select_languages_description}
      </Typography>
      {localesToBeRequested?.length > 0 && (
        <Control>
          <SelectLanguage locales={localesToBeRequested} selectedLocales={localesWithDesc} setSelectedLocales={setLocalesWithDesc} optionalInd={false}></SelectLanguage></Control>
      )}
      {!!(localesWithDesc?.length) &&
        localesWithDesc.map((locale: Locale) => (
          <LanguageChip key={locale.code} label={locale.languageDescription} onDelete={() => onRemoveLocale(locale)}></LanguageChip>
        ))
      }
      <Header variant="h4">{defaults.notes} :</Header>
      <StyledTextField
        placeholder={`${defaults.notes} :`}
        multiline
        fullWidth
        displayCount={true}
        maxLength={4000}
        rows={4}
        value={notes}
        onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => setNotes(event.target.value)}
      />
    </Modal>
  );
}

export default CustomTextRequestTranslationModal;
