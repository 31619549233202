import styled from '@emotion/styled';
import { Typography, Button, DualActionButton } from '@octanner/prism-core';

export const MainContainer = styled.div`
  width: 100%;
  padding: 0px 20px;
`;

export const ToolTitle = styled(Typography)`
  margin: 0px;
`;

export const StatusContainer = styled.div`
  padding: 0 20px;
`;

export const ActionButton = styled.div`
  display: flex;
  margin-top: 16px;
`;

export const ClientInfo = styled.div`
  display: flex;
  align-items: end;
  margin-top: 24px;
`;

export const EditClientInfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const WarningText = styled(Typography)`
  text-align: center;
  margin-top: 32px;
`;

export const ModalButtonContainer = styled.div`
  margin-top: 27px;
`;

export const ModalDiscardButton = styled(Button)`
  margin-right: 8px;
`;

export const StatusDualButton = styled(DualActionButton)`
  margin-left : 32px;
  .MuiButton-root {
    min-height: 38px;
  }
`;

export const ModalDescription = styled(Typography)`
  margin : 16px 0px;
`;

export const ClientDetailsContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
`;

export const CustomerContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const ClientUsageContainer = styled.div`
  margin-left: 48px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  border: 1px solid #C4C4C4;
  border-radius: 3px;
  gap: 8px;
  padding: 8px 8px 8px 16px;
  margin-top: 20px;
  height: 60px;
`;

export const ClientUsageEditContainer = styled.div`
  margin-top: 15px;
  margin-left: 48px;
`;

export const Container = styled.div`
  width: 100%;
`;

export const DisplayFlex = styled.div`
  display: flex;
`;

export const StpNumberContainer = styled.div`
  margin-top: 44px;
`;

export const ClientStatusContainer = styled.div`
  margin-top: 40px;
`;

export const AlertContainer = styled.div`
  margin-top: 18px;
`;

export const DualActionButtonContainer = styled.div`
  margin-top: 6px;
  display: flex;
  border: 1px solid #C4C4C4;
  border-radius: 3px;
  gap: 8px;
  padding: 8px 8px 8px 16px;
`;

export const DivStatusContainer = styled.div`
  width: 200px;
`;

export const PipelineContainer = styled.div`
  margin-left: 15px;
  bottom: 5px;
  display: flex;
  position: relative;
  color: #E1E1E1;
`;
