import SocialWall from './socialWall';
import Communications from './communications';
import UniversalSearch from './universalSearch';
import UserSettings from './userSettings';

interface TabComponents {
  key: string;
  element: JSX.Element;
  index: number;
}

export const TAB_COMPONENTS: TabComponents[] = [
  {
    key: 'user-settings',
    element: <UserSettings />,
    index: 0,
  },
  {
    key: 'communications',
    element: <Communications />,
    index: 1,
  },
  {
    key: 'universal-search',
    element: <UniversalSearch />,
    index: 2,
  },
  {
    key: 'social-wall',
    element: <SocialWall />,
    index: 3,
  },
];
