import { useMutation } from '@apollo/client';
import styled from '@emotion/styled';
import { Alert, Breadcrumbs, Chip, Grid, InputAdornment, Link, TextField, Typography } from '@octanner/prism-core';
import { ArrowLeft, Lock } from '@octanner/prism-icons';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { LanguageStringsContext } from '../../../Contexts/LanguageStringsContext';
import { LocaleContext } from '../../../Contexts/LocaleContext';
import { numRegex } from '../../../utils/constants';
import { defaults } from '../../../utils/default';
import { INSERT_LANGUAGE_US_ENGLISH, REQUEST_TRANSLATION } from '../../../utils/query';
import StyledTextField from '../../../utils/styledComponents';
import { AlertMessage, CreateStringResponse, Locale, Translation } from '../../../utils/types';
import SelectLanguage from '../../Common/SelectLanguage';
import Footer from '../../Common/Footer';
import SendTranslationRequestModal from './SendTranslationRequestModal';

const SelectLanguageContainer = styled.div`
  margin-top: 24px;
`;

const StringInfoContainer = styled.div`
  margin-top: 36px;
`;

const Container = styled.div`
  margin: 20px 35px;
  height: calc(100vh - 110px);
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
`;

const Control = styled.div`
  margin-top: 12px;
`;

const StpFieldContainer = styled(Grid)`
  margin-top: 24px;
`;

const StpField = styled(TextField)`
  margin-top: 12px;
`;

const MessageAlert = styled(Alert)`
  width: 60%;
  margin-bottom: 24px;
`;

const LanguageChip = styled(Chip)`
  margin-top: 12px;
  margin-right: 12px;
`;

const BackLink = styled(Breadcrumbs)`
  margin-bottom: 24px;
`;

const AddString = () => {

  const {
    setMessageText, messages
  } = useContext(LanguageStringsContext);
  const { localeLoading, locales } = useContext(LocaleContext);
  const history = useHistory();

  const [requestTranslation] = useMutation(REQUEST_TRANSLATION, {
    onError: () => {
      setMessageText('errorMessage', defaults.request_error);
    }
  });

  const [lsCreateLanguageStringInUSEnglishLocale] = useMutation(INSERT_LANGUAGE_US_ENGLISH, {
    onError: () => {
      setMessageText('errorMessage', defaults.create_error);
    }
  });

  const [clientStp, setClientStp] = useState<string>('');
  const [selectedLocales, setSelectedLocales] = useState<Locale[]>([]);
  const [createLoading, setCreateLoading] = useState<boolean>(false);
  const [stringId, setStringId] = useState<string>('');

  const [stringText, setStringText] = useState<string>('');
  const [existingLocales, setExistingLocales] = useState<Partial<Translation>[]>([]);
  const [openRequestTranslationModal, setOpenRequestTranslationModal] = useState<boolean>(true);

  const onRemoveLocale = (locale: Locale): void => {
    setSelectedLocales((previousLocales: Locale[]) => {
      const index = previousLocales.findIndex((previousLocale: Locale) => previousLocale.code === locale.code);
      if (index > -1) {
        previousLocales.splice(index, 1);
      }
      return [...previousLocales];
    });
  }

  const backToSearch = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setMessageText('createSuccessMessage', '');
    setMessageText('errorMessage', '');
    setMessageText('requestSuccessMessage', '');
    setMessageText('createWarningMessage', '');
    history.push('/language-string')
  }

  const onCancel = (): void => {
    setClientStp('');
    setStringText('');
    setSelectedLocales([]);
  }

  const handleCreateClick = () => {
    if (!stringText || !stringText.trim().length) {
      return;
    }
    setCreateLoading(true);
    const selectedLocalesCode = selectedLocales
      .filter((item: Locale) => item.languageDescription !== null && item.code !== 'en-US')
      .map((item: Locale) => item.code);
    lsCreateLanguageStringInUSEnglishLocale({
      variables: {
        input: {
          languageStringText: stringText,
          enableVoiceDescriptionInd: false,
          soldToParty: clientStp.length ? clientStp : undefined,
          localeList: selectedLocalesCode
        },
      },
    }).then((response: { data: CreateStringResponse }) => {
      if (response?.data?.lsCreateLanguageStringInUSEnglishLocale) {
        const translationList = response.data.lsCreateLanguageStringInUSEnglishLocale.languageString.translationList;
        setStringId(response.data.lsCreateLanguageStringInUSEnglishLocale.languageString.stringId);
        createExistingLocaleValueObj(translationList);
        setMessageText('createSuccessMessage', defaults.string_created_msg);
        if (!translationList || translationList.length === 0) {
          handleRaiseTranslationRequest(response.data.lsCreateLanguageStringInUSEnglishLocale.languageString.stringId, selectedLocalesCode);
        } else {
          setCreateLoading(false);
          setOpenRequestTranslationModal(true);
        }
      } else {
        setMessageText('errorMessage', defaults.create_error);
        setCreateLoading(false);
      }
    })
  };

  const createExistingLocaleValueObj = (existingLocales: Partial<Translation>[]) => {
    if (!existingLocales?.length) {
      return;
    }
    const commonLocalesWithValue = existingLocales
      .map(existingLocale => ({
        locale: existingLocale.locale,
        localeDescription: locales.find(locale => locale.code === existingLocale.locale)?.languageDescription || '',
        translationString: existingLocale.translationString
      }));
    setExistingLocales(commonLocalesWithValue);
  };

  const handleRaiseTranslationRequest = (stringId: string, selectedLocalesCode: string[]) => {
    if (stringId.length === 0) {
      return;
    }
    if (selectedLocalesCode.length) {
      requestTranslation({
        variables: {
          input: {
            stringId,
            localeList: selectedLocalesCode,
            useExistingTranslationInd: false
          }
        }
      }).then((response) => {
        if (!response || response.errors || response.data?.lsRaiseTranslationRequestForLanguageString === null) {
          setMessageText('errorMessage', defaults.request_error);
        } else {
          updateSuccessMessage();
        }
        commonActionsAfterRaiseTranslation(stringId);
        setCreateLoading(false);
      });
    } else {
      commonActionsAfterRaiseTranslation(stringId);
    }
  };

  const commonActionsAfterRaiseTranslation = (stringId: string) => {
    setStringId(stringId);
    history.push(`/language-string/edit/${stringId}?type=add`);
  };

  const clientStpChange = (event: React.ChangeEvent<{ value: string }>) => {
    const inputValue = event.target.value;
    if (inputValue === '' || numRegex.test(inputValue)) {
      setClientStp(event.target.value);
    }
  }


  const updateSuccessMessage = () => {
    let successMessage = defaults.translation_requested_msg_for_locales;
    if (selectedLocales.length > 0) {
      const localeDescriptions = selectedLocales
        .filter((locale: Locale) => locale.code !== 'en-US')
        .map((locale: Locale) => locale.languageDescription);
      if (localeDescriptions.length > 0) {
        const [lastLocale, ...otherLocales] = localeDescriptions.reverse();
        successMessage += ` for ${otherLocales.reverse().join(', ')}${otherLocales.length > 0 ? ' and ' : ''}${lastLocale}`;
      }
    } else {
      successMessage = '';
    }
    setMessageText('requestSuccessMessage', successMessage);
  }

  const handleStringTextChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    setStringText(event.target.value);
  }

  return (
    <>
      <BackLink>
        <Link href="#" color="inherit" onClick={(e) => backToSearch(e)}><ArrowLeft />
          {defaults.search_language_string}</Link>
        <Typography color="inherit">{defaults.add_string}</Typography>
      </BackLink>
      <>
        <Container>
          <Typography variant='h1'>
            {defaults.add_string}
          </Typography>
          <Typography>
            {defaults.add_string_desc}
          </Typography>
          {messages.map((message: AlertMessage) => (
            message?.text?.length > 0 && message.key === 'errorMessage' &&
            (<MessageAlert
              key={message.key}
              onClose={() => setMessageText(message.key, '')}
              severity={message.severity}>
              {message.text}
            </MessageAlert>)
          ))}
          <StringInfoContainer>
            <Typography variant='h3'>
              {defaults.string_information}
            </Typography>
            <Typography>
              {defaults.string_info_desc}
            </Typography>
          </StringInfoContainer>
          <Control>
            <Grid container spacing={4}>
              <Grid item xs={5}>
                <TextField
                  value={"English (US)"}
                  label={defaults.locale_desc}
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <Lock />
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={5}>
                <TextField
                  value={"en_US"}
                  label={defaults.locale_code}
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <Lock />
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container spacing={4}>
              <Grid item xs={10}>
                <Control>
                  <StyledTextField
                    label={defaults.enter_english_text_with_max_char_limit}
                    placeholder={defaults.enter_the_string}
                    fullWidth
                    value={stringText}
                    onChange={handleStringTextChange}
                    multiline
                    rows={4}
                    displayCount={true}
                    maxLength={4000}
                    countProps={{ className: 'display-count-class' }}
                    required
                  />
                </Control>
              </Grid>
            </Grid>
          </Control>
          <StpFieldContainer container spacing={4}>
            <Grid item xs={5}>
              <Typography variant='h3'>
                {defaults.add_stp}
              </Typography>
              <Typography color='textSecondary'>
                {defaults.add_stp_desc}
              </Typography>
              <StpField
                value={clientStp}
                onChange={clientStpChange}
                fullWidth
                label={defaults.add_stp}
                helperText={defaults.optional} />
            </Grid>
          </StpFieldContainer>
          <Grid container spacing={1}>
            <Grid item xs={5}>
              {!localeLoading && locales?.length > 0 && <SelectLanguageContainer>
                <Typography variant='h3'>
                  {defaults.translations}
                </Typography>
                <Typography color='textSecondary'>
                  {defaults.select_languages_description}
                </Typography>
                <Control>
                  <SelectLanguage locales={locales} selectedLocales={selectedLocales} setSelectedLocales={setSelectedLocales} optionalInd={true}></SelectLanguage></Control>
              </SelectLanguageContainer>}
            </Grid>
          </Grid>
          {!!(selectedLocales && selectedLocales.length) &&
            selectedLocales.map((locale: Locale) => (
              <LanguageChip key={locale.code} label={locale.languageDescription} onDelete={() => onRemoveLocale(locale)}></LanguageChip>
            ))
          }
          <Footer
            cancelActionText={defaults.cancel}
            cancelButtonDisabled={createLoading}
            saveActionText={defaults.create_strings}
            cancelAction={() => onCancel()}
            loading={createLoading}
            saveAction={() => handleCreateClick()} saveButtonDisabled={!stringText || !stringText.trim().length || stringText.trim().length>4000} />
          {openRequestTranslationModal && !createLoading && stringId && existingLocales.length &&
            <SendTranslationRequestModal
              existingLocales={existingLocales}
              selectedLocales={selectedLocales}
              onCancel={onCancel}
              stringId={stringId}
              openRequestTranslationModal={openRequestTranslationModal}
              setOpenRequestTranslationModal={setOpenRequestTranslationModal}
            ></SendTranslationRequestModal>}
        </Container>
      </>
    </>
  );
};

export default AddString;