import { Typography } from '@octanner/prism-core';
import MigrationSearch from './MigrationSearch';
import AllMigrations from './AllMigrations';
import { useStyles } from './styles';

const Migration = () => {
  const classes = useStyles();

  return (
    <div className={classes.page}>
      <Typography variant="h1" data-testid={'migration-tool-page-header'}>
        Client Migration Tool
      </Typography>
      <Typography
        className={classes.pageInstructions}
        data-testid={'migration-tool-page-instructions'}
      >
        Use this tool to migrate Victories data to Culture Cloud.
      </Typography>
      <div className={classes.clientSearch}>
        <MigrationSearch />
      </div>

      <div className={classes.currentMigrations}>
        <AllMigrations />
      </div>
    </div>
  );
};
export default Migration;
