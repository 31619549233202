import { useMutation,useQuery } from '@apollo/client';
import {
    GetCustomerInput,
  CoupleCustomerAndCoreFeatureInput,
  CoupleCustomerAndCoreFeatureResponse,
  CustomerWithFeatures,
} from '../Customer';
import {
  GET_CUSTOMER,
  COUPLE_CUSTOMER_AND_CORE_FEATURE,
} from '../graphql/customer';

export const useGetCustomer = (
    customerId: string,
    personalFeedOverride: boolean
  ) => {
    return useQuery<CustomerWithFeatures, GetCustomerInput>(GET_CUSTOMER, {
      variables: {
        id: customerId,
        customerId,
        social: { personalFeedOverride: personalFeedOverride },
      },
      skip: !customerId,
      onError: (error) => console.error('Error fetching customer data:', error),
    });
  };
  

export const useCoupleCustomerAndCoreFeature = (customerId: string) =>
  useMutation<
    CoupleCustomerAndCoreFeatureResponse,
    CoupleCustomerAndCoreFeatureInput
  >(COUPLE_CUSTOMER_AND_CORE_FEATURE, {
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: GET_CUSTOMER, variables: { id: customerId, customerId } },
    ],
  });
