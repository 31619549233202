import styled from '@emotion/styled';
import { Alert, Typography } from '@octanner/prism-core';
import React, { useContext } from 'react';
import { Route, Switch } from 'react-router-dom';
import { MaterialStringsContext } from '../../Contexts/MaterialStringsContext';
import { defaults } from '../../utils/default';
import { AlertMessage, MaterialStringContainerProps } from '../../utils/types';
import MaterialDescriptionTable from './MaterialDescriptionTable';
import RadioButtonContainer from './RadioButtonContainer';
import SearchMaterialDescriptionStrings from './SearchMaterialDescriptionStrings';

const Container = styled.div<MaterialStringContainerProps>`
  margin: 20px 40px;
  height: ${(props) => (props.showFooter ? 'calc(100vh - 110px)' : '100vh')};
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
`;

const MessageAlert = styled(Alert)`
  margin-top: 30px;
`;

const MaterialDescription = () => {

  const { showFooter, messages, setMessageText } = useContext(MaterialStringsContext);
  const {material_descriptions, material_descriptions_subtitle} = defaults;

  return (
    <Container showFooter={showFooter}>
      <Switch>
        <Route exact path='/material-description'>
          <>
            <Typography variant='h1'>
              {material_descriptions}
            </Typography>
            <Typography>
              {material_descriptions_subtitle}
            </Typography>
            {messages.map((message: AlertMessage) => (
              message?.text?.length > 0 && message.key === 'errorMessage' &&
              (<MessageAlert
                key={message.key}
                onClose={() => setMessageText(message.key, '')}
                severity={message.severity}>
                {message.text}
              </MessageAlert>)
            ))}
            <RadioButtonContainer />
            <SearchMaterialDescriptionStrings />
          </>
        </Route>
        <Route exact path={'/material-description/edit/:mmId'}>
          <><MaterialDescriptionTable /></>
        </Route>
      </Switch>
    </Container>
  );
}

export default MaterialDescription;