import React from 'react';
import {
  Typography,
  FormControl,
  FormLabel,
  FormGroup,
} from '@octanner/prism-core';
import { AllowedMediaEnum } from '../types';
import {
  CheckboxControl,
  indentedFormControlStyle,
  SwitchControl,
  topLevelFormControlStyle,
} from './editorControls';

const MediaOptions: React.FC<Props> = ({
  mediaOptionsUseMedia,
  setMediaOptionsUseMedia,
  media,
  setMedia,
  requireMedia,
  setRequireMedia,
}) => {
  const handleCheckboxChange = (mediaType) => (e, checked) => {
    setMedia((prevState) =>
      checked
        ? [...prevState, mediaType]
        : prevState.filter((m) => m !== mediaType),
    );
  };

  return (
    <FormControl sx={topLevelFormControlStyle}>
      <FormLabel component="legend" focused={false}>
        <Typography variant="h3">Media options</Typography>
      </FormLabel>
      <FormGroup>
        <SwitchControl
          checked={mediaOptionsUseMedia}
          onChange={(e, checked) => setMediaOptionsUseMedia(checked)}
        >
          Use media
        </SwitchControl>
      </FormGroup>
      {mediaOptionsUseMedia && (
        <>
          <FormControl sx={indentedFormControlStyle}>
            <FormGroup>
              <CheckboxControl
                checked={media.includes(AllowedMediaEnum.MEDIA_LIBRARY)}
                onChange={handleCheckboxChange(AllowedMediaEnum.MEDIA_LIBRARY)}
              >
                Media library
              </CheckboxControl>
              <CheckboxControl
                checked={media.includes(AllowedMediaEnum.GIF_LIBRARY)}
                onChange={handleCheckboxChange(AllowedMediaEnum.GIF_LIBRARY)}
              >
                GIF library
              </CheckboxControl>
              <CheckboxControl
                checked={media.includes(AllowedMediaEnum.UPLOAD_PHOTO)}
                onChange={handleCheckboxChange(AllowedMediaEnum.UPLOAD_PHOTO)}
              >
                Upload photo
              </CheckboxControl>
            </FormGroup>
          </FormControl>
          <FormGroup>
            <SwitchControl
              checked={mediaOptionsUseMedia && requireMedia}
              onChange={(e, checked) => setRequireMedia(checked)}
            >
              Require media
            </SwitchControl>
          </FormGroup>
        </>
      )}
    </FormControl>
  );
};

interface Props {
  mediaOptionsUseMedia: boolean;
  setMediaOptionsUseMedia: (checked: boolean) => void;
  media: AllowedMediaEnum[];
  setMedia;
  requireMedia: boolean;
  setRequireMedia: (checked: boolean) => void;
}

export default MediaOptions;
