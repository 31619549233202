import { ErrorData, Permission } from '../types';
import { Dispatch, SetStateAction } from 'react';
import { PermissionCategory, PermissionSubCategory } from './types';

const roleNameCharLimit: ErrorData = {
  error: false,
  message: 'The admin role name exceeds the character limit.',
  fieldMessage: 'Character limit exceeded.',
};
const roleNameEmpty: ErrorData = {
  error: false,
  message: 'The admin role is missing required information.',
  fieldMessage: 'This is a required field.',
};
const roleNameDuplicate: ErrorData = {
  error: false,
  message: 'The admin role name already exists.',
  fieldMessage: 'Please choose a new name.',
};
const roleDescCharLimit: ErrorData = {
  error: false,
  message: 'The admin role description exceeds the character limit.',
  fieldMessage: 'Character limit exceeded.',
};
const roleDescEmpty: ErrorData = {
  error: false,
  message: 'The admin role is missing required information.',
  fieldMessage: 'This is a required field.',
};
const roleTypeEmpty: ErrorData = {
  error: false,
  message: 'The admin role is missing required information.',
  fieldMessage: 'This is a required field.',
};
const permissionsEmpty: ErrorData = {
  error: false,
  message:
    'At least one permission must be active to save an active admin role. Add a permission below to continue.',
};

export const validateAdminRole = (
  roleName: string,
  roleDesc: string,
  roleType: string,
  setRoleNameEmpty: Dispatch<SetStateAction<ErrorData>>,
  setRoleNameCharLimit: Dispatch<SetStateAction<ErrorData>>,
  setRoleDescEmpty: Dispatch<SetStateAction<ErrorData>>,
  setRoleDescCharLimit: Dispatch<SetStateAction<ErrorData>>,
  setRoleTypeEmpty: Dispatch<SetStateAction<ErrorData>>
): boolean => {
  setRoleNameEmpty({ ...roleNameEmpty, error: roleName.length === 0 });
  setRoleNameCharLimit({ ...roleNameCharLimit, error: roleName.length > 60 });
  setRoleDescEmpty({ ...roleDescEmpty, error: roleDesc.length === 0 });
  setRoleDescCharLimit({ ...roleDescCharLimit, error: roleDesc.length > 160 });
  setRoleTypeEmpty({ ...roleTypeEmpty, error: roleType.length === 0 });
  return (
    roleName.length === 0 ||
    roleName.length > 60 ||
    roleDesc.length === 0 ||
    roleDesc.length > 160 ||
    roleType.length === 0
  );
};

export const validatePermissions = (
  permissions: Permission[],
  setHasPermission: Dispatch<SetStateAction<ErrorData>>
): boolean => {
  setHasPermission({ ...permissionsEmpty, error: permissions.length === 0 });
  return permissions.length === 0;
};

export const filterSubCategories = (
  category: PermissionCategory,
  search?: string
): PermissionSubCategory[] => {
  if (!search || search.length === 0) return category.subCategories;
  const lowerCaseSearch = search.toLowerCase();
  return category.name.toLowerCase().includes(lowerCaseSearch) ||
    category.description.toLowerCase().includes(lowerCaseSearch)
    ? category.subCategories
    : category.subCategories
        .filter((sub) => {
          return (
            sub.name.toLowerCase().includes(lowerCaseSearch) ||
            sub.permissionGroups.some((group) => {
              return (
                group.name.toLowerCase().includes(lowerCaseSearch) ||
                (group.readPermission &&
                  group.readPermission.name
                    .toLowerCase()
                    .includes(lowerCaseSearch)) ||
                (group.writePermission &&
                  group.writePermission.name
                    .toLowerCase()
                    .includes(lowerCaseSearch))
              );
            })
          );
        })
        .map((sub) => {
          return {
            ...sub,
            permissionGroups: sub.permissionGroups.filter(
              (group) =>
                sub.name.toLowerCase().includes(lowerCaseSearch) ||
                group.name.toLowerCase().includes(lowerCaseSearch)
            ),
          };
        });
};

export const filterCategories = (
  categories: PermissionCategory[],
  search?: string
): PermissionCategory[] => {
  if (!search || search.length === 0) return categories;
  const lowerCaseSearch = search.toLowerCase();
  return categories.filter(
    (category) =>
      category.name.toLowerCase().includes(lowerCaseSearch) ||
      category.description.toLowerCase().includes(lowerCaseSearch) ||
      category.subCategories.some(
        (sub) =>
          sub.name.toLowerCase().includes(lowerCaseSearch) ||
          sub.permissionGroups.some((group) =>
            group.name.toLowerCase().includes(lowerCaseSearch)
          )
      )
  );
};
