export const clientTypes = [
  {
    name: 'Real',
    value: 'real',
  },
  {
    name: 'Test',
    value: 'test',
  },
  {
    name: 'Demo',
    value: 'demo',
  }
];

export const regions = [
    {
      name: 'United States',
      value: 'usa',
    },
    {
      name: 'Australia',
      value: 'aus',
    },
    {
      name: 'Europe',
      value: 'gbr',
    },
];

export const RECENT_CLIENT_HEADERS = [
  {
    key: 'client-name',
    name: 'Client Name',
  },
  {
    key: 'stp-number',
    name: 'STP Number',
  },
  {
    key: 'core-id',
    name: 'Core ID',
  },
  {
    key: 'client-status',
    name: 'Client Status',
  },
  {
    key: 'client-usage-type',
    name: 'Client Usage Type',
  },
  {
    key: 'actions',
    name: 'Actions',
  },
];

export const CLIENT_CREATION_HEADERS = [
  { label: 'Client Information', id: "0"  },
  { label: 'Client Address Details' , id: "1"  },
  { label: 'Invoicing Details',  id: "2" },
]
