import ArrowTailLeftIcon from '@octanner/prism-icons/ArrowTailLeft';
import { Button, Typography } from '@octanner/prism-core';
import { useContext, useEffect } from 'react';
import {
  Link,
  Redirect,
  Route,
  Switch,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import Loading from '../../common/Loading';

import ProtectedRoute from '../../common/ProtectedRoute';
import { CustomerContext } from '../../utils/context/CustomerContext';

import { useGetCustomer } from './hooks/customer';
import { CustomerRoute } from './routes';
import { useStyles } from './styles';
import NotFound from '../notFoundPage';

export default function CustomerRoutes() {
  const classes = useStyles();
  const match = useRouteMatch();
  const params = useParams<{ customerId: string }>();
  const { social, isDefault, customer, setCustomer } =
    useContext(CustomerContext);

  const { data, loading } = useGetCustomer(
    params.customerId,
    social.socialConfig?.personalFeedOverride
  );

  useEffect(() => {
    if (!data?.customer) return;
    setCustomer(data);
  }, [data]); // eslint-disable-line

  if (isDefault && customer.id === params.customerId) {
    return (
      <Switch>
        <Redirect to="/" />
      </Switch>
    );
  }

  // ! Safeguard: This should never happen anymore. If it does, simply redirect. Possible issue handler page here?
  if (social.socialConfig === null) {
    return (
      <Switch>
        <Redirect to="/" />
      </Switch>
    );
  }

  if (loading) {
    return (
      <div className={classes.centeringMiddle}>
        <Loading size={68} />
      </div>
    );
  }

  if (isDefault) {
    return (
      <div className={classes.CustomerRoutesRoot}>
        <Typography variant="h4">
          Invalid permissions to view this Client.
        </Typography>
        <Link to="/" data-test="go-to-landing">
          <Button
            className={classes.backButton}
            variant="text"
            startIcon={<ArrowTailLeftIcon />}
          >
            <Typography variant="h6">Back </Typography>
          </Button>
        </Link>
      </div>
    );
  }

  return (
    <Switch>
      {CustomerRoute.map(({ authorizer, path, component, exact }) => {
        return (
          <ProtectedRoute
            key={path}
            authorizer={authorizer}
            path={[`${match.path}${path}`]}
            component={component}
            exact={exact}
          />
        );
      })}
      <Route path="/customer/not-found" component={NotFound} />
      <Route>
        <Redirect to="/customer/not-found" />
      </Route>

      <Route path={`${match.path}`} exact={true}>
        <Redirect to={`/customer/${params.customerId}/client-summary`} />
      </Route>
    </Switch>
  );
}
