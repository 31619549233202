import { useContext } from 'react';
import { Vibe, VibeProvider } from '@octanner/cc-vibes-ui';
import { CustomerContext } from '../../utils/context/CustomerContext';
import { useNavigate } from './hooks';
import VibesStylesProvider from '../../utils/VibesStylesProvider';

export default function VibeDetails() {
  const { customer } = useContext(CustomerContext);
  const { navigate, location, params } = useNavigate(customer.id);

  return (
    <VibesStylesProvider>
      <VibeProvider>
        <Vibe
          key={location.key}
          coreCustomerUuid={customer.id}
          stp={customer.stpNumber as string}
          vibeId={params.vibeId as string}
          onNavigation={navigate}
          location={location}
        />
      </VibeProvider>
    </VibesStylesProvider>
  );
}
