import { BusinessUnit } from '../../../graphql/business-unit/models';
import { CountryBase } from '../../../graphql/country/models';
import {
  ProgramEligibilityInput,
  EligibilitySet,
  ProgramEligibility,
} from './models';

export enum SegmentType {
  all = 1,
  workCountry = 2,
  homeCountry = 3,
  businessUnit = 4,
}

export const restrictedSegments = [
  SegmentType.homeCountry,
  SegmentType.workCountry,
];

export const allOptions = [
  {
    type: SegmentType.all,
    isInclude: true,
  },
  {
    type: SegmentType.businessUnit,
    isInclude: true,
  },
  {
    type: SegmentType.businessUnit,
    isInclude: false,
  },
  {
    type: SegmentType.homeCountry,
    isInclude: true,
  },
  {
    type: SegmentType.homeCountry,
    isInclude: false,
  },
  {
    type: SegmentType.workCountry,
    isInclude: true,
  },
  {
    type: SegmentType.workCountry,
    isInclude: false,
  },
];

interface BaseFormModel {
  id: number;
  isInclude: boolean;
}

export interface AllParticipantsModel extends BaseFormModel {
  segmentType: SegmentType.all;
}

export interface BusinessUnitModel extends BaseFormModel {
  segmentType: SegmentType.businessUnit;
  businessUnits: BusinessUnit[];
}

export interface HomeCountryModel extends BaseFormModel {
  segmentType: SegmentType.homeCountry;
  countries: CountryBase[];
}

export interface WorkCountryModel extends BaseFormModel {
  segmentType: SegmentType.workCountry;
  countries: CountryBase[];
}

export type FormModel =
  | AllParticipantsModel
  | BusinessUnitModel
  | HomeCountryModel
  | WorkCountryModel;

export function initializeModel(
  id: number,
  isInclude: boolean,
  segmentType: SegmentType
): FormModel {
  switch (segmentType) {
    case SegmentType.all:
      return { id, isInclude: true, segmentType: SegmentType.all };
    case SegmentType.workCountry:
      return {
        id,
        isInclude,
        segmentType: SegmentType.workCountry,
        countries: [],
      };
    case SegmentType.homeCountry:
      return {
        id,
        isInclude,
        segmentType: SegmentType.homeCountry,
        countries: [],
      };
    case SegmentType.businessUnit:
      return {
        id,
        isInclude,
        segmentType: SegmentType.businessUnit,
        businessUnits: [],
      };
  }
}

export const sortFormModel = (a: FormModel, b: FormModel) => {
  return a.id - b.id;
};

export const booleanToEligibilitySet = (isInclude: boolean) =>
  isInclude ? EligibilitySet.include : EligibilitySet.exclude;

export const modelToInput = (models: FormModel[]): ProgramEligibilityInput => {
  const input: ProgramEligibilityInput = {
    allParticipants: false,
    businessUnitEligibilities: [],
    workCountryEligibilities: [],
    homeCountryEligibilities: [],
  };

  for (const model of models) {
    switch (model.segmentType) {
      case SegmentType.all:
        input.allParticipants = true;
        break;
      case SegmentType.workCountry:
        const workCountryInputs = model.countries.map((country) => ({
          eligibilitySet: booleanToEligibilitySet(model.isInclude),
          countryIso2Code: country.iso2Code,
        }));
        input.workCountryEligibilities = [
          ...workCountryInputs,
          ...input.workCountryEligibilities,
        ];
        break;
      case SegmentType.homeCountry:
        const homeCountryInputs = model.countries.map((country) => ({
          eligibilitySet: booleanToEligibilitySet(model.isInclude),
          countryIso2Code: country.iso2Code,
        }));
        input.homeCountryEligibilities = [
          ...homeCountryInputs,
          ...input.homeCountryEligibilities,
        ];
        break;
      case SegmentType.businessUnit:
        const buInputs = model.businessUnits.map((bu) => ({
          eligibilitySet: booleanToEligibilitySet(model.isInclude),
          buUuid: bu.uuid,
        }));
        input.businessUnitEligibilities = [
          ...buInputs,
          ...input.businessUnitEligibilities,
        ];
        break;
    }
  }

  return input;
};

export const eligibilityToModel = (
  eligibility: ProgramEligibility
): FormModel[] => {
  let id = 0;
  const models: FormModel[] = [];

  const {
    allParticipants,
    homeCountryEligibilities,
    workCountryEligibilities,
    businessUnitEligibilities,
  } = eligibility;

  if (allParticipants) {
    models.push({
      segmentType: SegmentType.all,
      isInclude: true,
      id: id++,
    });
  }

  const includeHCs = homeCountryEligibilities
    .filter((e) => e.eligibilitySet === EligibilitySet.include)
    .map((e) => e.coreCountry);
  if (includeHCs.length) {
    models.push({
      isInclude: true,
      segmentType: SegmentType.homeCountry,
      id: id++,
      countries: includeHCs,
    });
  }

  const excludeHCs = homeCountryEligibilities
    .filter((e) => e.eligibilitySet === EligibilitySet.exclude)
    .map((e) => e.coreCountry);
  if (excludeHCs.length) {
    models.push({
      isInclude: false,
      segmentType: SegmentType.homeCountry,
      id: id++,
      countries: excludeHCs,
    });
  }

  const includeWCs = workCountryEligibilities
    .filter((e) => e.eligibilitySet === EligibilitySet.include)
    .map((e) => e.coreCountry);
  if (includeWCs.length) {
    models.push({
      isInclude: true,
      segmentType: SegmentType.workCountry,
      id: id++,
      countries: includeWCs,
    });
  }

  const excludeWCs = workCountryEligibilities
    .filter((e) => e.eligibilitySet === EligibilitySet.exclude)
    .map((e) => e.coreCountry);
  if (excludeWCs.length) {
    models.push({
      isInclude: false,
      segmentType: SegmentType.workCountry,
      id: id++,
      countries: excludeWCs,
    });
  }

  const includeBUs = businessUnitEligibilities
    .filter((e) => e.eligibilitySet === EligibilitySet.include)
    .map((e) => e.businessUnit);
  if (includeBUs.length) {
    models.push({
      isInclude: true,
      segmentType: SegmentType.businessUnit,
      id: id++,
      businessUnits: includeBUs,
    });
  }

  const excludeBUs = businessUnitEligibilities
    .filter((e) => e.eligibilitySet === EligibilitySet.exclude)
    .map((e) => e.businessUnit);
  if (excludeBUs.length) {
    models.push({
      isInclude: false,
      segmentType: SegmentType.businessUnit,
      id: id++,
      businessUnits: excludeBUs,
    });
  }

  return models;
};

export const includeExcludeText = (isInclude: boolean) =>
  isInclude ? 'Including' : 'Excluding';
