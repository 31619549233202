import styled from '@emotion/styled';
import { Alert, Breadcrumbs, FormControl, FormControlLabel, Grid, Link, Radio, RadioGroup, Typography } from '@octanner/prism-core';
import { ArrowLeft } from '@octanner/prism-icons';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CustomTextContext } from '../../../Contexts/CustomTextContext';
import { AddCustomTextBy } from '../../../utils/constants';
import { defaults } from '../../../utils/default';
import AddCustomText from './AddCustomText';

const RadioButtonContainer = styled.div`
margin-top: 30px;
margin-left: 8px;
`;

const RadioFormControlLabel = styled(FormControlLabel)`
margin-right: 36px;
`;

const BackLink = styled(Breadcrumbs)`
margin-bottom: 24px;
`;

const Container = styled.div`
margin: 20px 35px;
height: calc(100vh - 110px);
overflow-y: auto;
&::-webkit-scrollbar {
  width: 0;
  background: transparent;
}
`;

const MessageAlert = styled(Alert)`
  margin-top: 12px;
`;

const AddCustomTextContainer: React.FC = () => {

  const {
    addCustomTextBy, setAddCustomTextBy, searchLoading,
    setDataNotFound, setSearchData } = useContext(CustomTextContext);

  const history = useHistory();
  const [uploadSuccess, setUploadSuccess] = useState<boolean>(false);
  const [uploadError, setUploadError] = useState<boolean>(false);
  const [sampleDownloadError, setSampleDownloadError] = useState<boolean>(false);

  const searchByChange = (event: React.ChangeEvent<{ value: string }>) => {
    setAddCustomTextBy(event.target.value);
    setSearchData(null);
    setDataNotFound(false);
  }

  const backToSearch = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    history.push('/custom-text')
  }

  return (
    <>
      <BackLink>
        <Link href="#" color="inherit" onClick={(e) => backToSearch(e)}><ArrowLeft />
          {defaults.search_custom_text_string}</Link>
        <Typography color="inherit">{defaults.add_custom_text}</Typography>
      </BackLink>
      <Container>
        <Typography variant='h1'>
          {defaults.add_custom_text}
        </Typography>
        <Typography color='textSecondary'>
          {defaults.add_custom_text_desc}
        </Typography>
        <Grid container spacing={3}>
          {uploadSuccess && <Grid item xs={9}>
            <MessageAlert severity="success" onClose={() => setUploadSuccess(false)}>
              {defaults.custom_text_bulk_success}</MessageAlert>
          </Grid>}
          {uploadError && <Grid item xs={7}>
            <MessageAlert severity="error" onClose={() => setUploadError(false)}>
              {defaults.custom_text_bulk_error}</MessageAlert>
          </Grid>}
          {sampleDownloadError && <Grid item xs={6}>
            <MessageAlert severity="error" onClose={() => setSampleDownloadError(false)}>
              {defaults.sample_download_failed}</MessageAlert>
          </Grid>}
        </Grid>
        <RadioButtonContainer>
          <FormControl>
            <RadioGroup row aria-label='addCustomTextBy' name='addCustomTextBy'
              value={addCustomTextBy} onChange={searchByChange}>
              <RadioFormControlLabel value={AddCustomTextBy.CUSTOM_TEXT} control={<Radio />} label={defaults.add_a_custom_string} disabled={searchLoading} />
              <RadioFormControlLabel value={AddCustomTextBy.BULK_UPLOAD} control={<Radio />} label={defaults.bulk_upload_custom_text} disabled={searchLoading} />
            </RadioGroup>
          </FormControl>
        </RadioButtonContainer>
        <AddCustomText setUploadError={setUploadError} setSampleDownloadError={setSampleDownloadError} setUploadSuccess={setUploadSuccess} />
      </Container>
    </>
  )
}

export default AddCustomTextContainer;