import { useMutation } from "@apollo/client";
import styled from "@emotion/styled";
import { Button, Modal, Typography } from "@octanner/prism-core";
import React, { useContext } from "react";
import { useHistory } from 'react-router-dom';
import { CustomTextContext } from "../../../Contexts/CustomTextContext";
import { defaults } from "../../../utils/default";
import { DELETE_CUSTOM_TEXT } from "../../../utils/query";
import { DeleteCustomTextModalProps } from "../../../utils/types";

const DeleteButton = styled(Button)`
  background-color: #D10A12;
`;

const StyledTypography = styled(Typography)`
  text-align: center;
`;

const DeleteCustomTextModal: React.FC<DeleteCustomTextModalProps> = (
  { showDeleteCustomTextModal, setShowDeleteCustomTextModal, customTextRequest }) => {

  const history = useHistory();
  const { setMessageText, searchData, searchCustomTextHandler } = useContext(CustomTextContext);
  const [deleteCustomText, { loading: deletingCustomText }] = useMutation(DELETE_CUSTOM_TEXT,
    {
      onCompleted: (data) => {
        if (data && !data.errors) {
          if (searchData) {
            searchCustomTextHandler(1, 10);
          }
          setMessageText('deleteSuccessMessage', defaults.delete_custom_text_success);
          history.push('/custom-text');
        }
      },
      onError: (error) => {
        setMessageText('errorMessage', error.message);
        setShowDeleteCustomTextModal(false);
      }
    }
  );

  const closeModal = () => {
    if (deletingCustomText) {
      return;
    }
    setShowDeleteCustomTextModal(false);
  }

  const deleteCustomTextRequest = () => {
    if (deletingCustomText) {
      return;
    }
    deleteCustomText({
      variables: {
        input: {
          customTextRequestUniqueId: customTextRequest.uniqueId
        }
      }
    })
  }

  return (
    <Modal
      actions={
        <>
          <Button color='secondary' disabled={deletingCustomText} onClick={closeModal}>{defaults.cancel}</Button>
          <DeleteButton loading={deletingCustomText} onClick={deleteCustomTextRequest}> {defaults.delete}</DeleteButton>
        </>
      }
      id='delete-custom-text-modal'
      maxWidth='xs'
      title={defaults.delete_custom_text_modal_title}
      open={showDeleteCustomTextModal}
      onClose={closeModal}>
      <StyledTypography>{defaults.delete_custom_text_modal_desc}</StyledTypography>
    </Modal>
  );
}

export default DeleteCustomTextModal;