import { Identity } from '@octanner/prism-core/Header/interfaces';
import { AdjudicationConfiguration } from 'ApprovalPath/types';

export interface Award {
  recogConfigId: string;
  configName: string;
  awardDescription?: string;
  awardType: AwardTypes;
  emailCC: {
    allowForCC: boolean;
    prePopulateCc: boolean;
    nomineeManager: boolean;
    nominatorManager: boolean;
    allowUserToModifyAdditionalAddresses: boolean;
    requireSenderConfirmation: boolean;
  };
  groupRecognition: {
    allowGroupFileUpload: boolean;
    allowRecipientListViewableByEveryone: boolean;
  };
  programId: string;
  flexFields: FlexFieldQuestion[];
  relatedFlexFields?: ConfigRelatedFlexFieldDetail[];
  notifyNomineeManagerUponFirstNomination: boolean;
  notifyGiverAfterEachSubmission: boolean;
  notifyNomineeUponFirstNomination: boolean;
  active: boolean;
  mediaOptions: {
    useMedia: boolean;
    allowedMedia: AllowedMediaEnum[];
    requireMedia: boolean;
  };
  presentation: AwardPresentation;
  adjudicationConfiguration?: AdjudicationConfiguration;
  allowForScheduledDelivery: boolean;
}
export enum AwardTypes {
  ECARD = 'ECARD',
  NOMINATION = 'NOMINATION',
  TOURNAMENT = 'TOURNAMENT',
}

export enum DaysToPresentEnum {
  IMMEDIATELY = 'IMMEDIATELY',
  SEVEN = 'SEVEN',
  FOURTEEN = 'FOURTEEN',
  THIRTY = 'THIRTY',
}

export enum AwardPresentedByEnum {
  NOMINATOR = 'NOMINATOR',
  NOMINATOR_USER_SEARCH = 'NOMINATOR_USER_SEARCH',
  NOMINATORS_MANAGER = 'NOMINATORS_MANAGER',
  NOMINEES_MANAGER = 'NOMINEES_MANAGER',
  CONFIG_USER_SEARCH = 'CONFIG_USER_SEARCH',
}

export enum DaysToPresentEnumPrev {
  IMMEDIATELY = 'IMMEDIATELY',
  THREE = 'THREE',
  SEVEN = 'SEVEN',
  FIFTEEN = 'FIFTEEN',
  THIRTY = 'THIRTY',
  ONE_HUNDRED_EIGHTY = 'ONE_HUNDRED_EIGHTY',
}

export enum AwardPresentedByEnumPrev {
  GIVER = 'GIVER',
  GIVER_SELECTS = 'GIVER_SELECTS',
  GIVERS_MANAGER = 'GIVERS_MANAGER',
  RECIPIENTS_MANAGER = 'RECIPIENTS_MANAGER',
  SOMEONE_ELSE = 'SOMEONE_ELSE',
}

export type IdentityPresenter = Omit<Identity, 'dateOfBirth' | 'uniqueId'>;

export interface AwardPresentation {
  recogConfigId?: string;
  daysToPresent: DaysToPresentEnum;
  presentedBy: AwardPresentedByEnum;
  presenter?: IdentityPresenter;
}

export interface FlexFieldQuestion {
  id: string;
  fieldName: string;
  helperText: string;
  characterLimit: number;
  question: string;
  immutable: boolean;
}

export interface ConfigRelatedFlexFieldDetail {
  flexFieldQuestion: FlexFieldQuestion;
  required: boolean;
  flexfieldAnswerVisibility: 'RECIPIENT' | 'APPROVER' | 'EVERYONE';
  displayOrder: number;
}

export enum AllowedMediaEnum {
  GIF_LIBRARY = 'GIF_LIBRARY',
  UPLOAD_PHOTO = 'UPLOAD_PHOTO',
  MEDIA_LIBRARY = 'MEDIA_LIBRARY',
}

export interface Program {
  id: string;
  programName: string;
  promoBody: string;
  promoHeader: string;
  displayOnCCHome: boolean;
}

export enum HomepageWidgetHeaderEnum {
  USE_PROGRAM_NAME = 'Use Program Name',
  CUSTOM_TEXT = 'Custom Text',
}

export interface EmailCommunicationsTournament {
  notifyNomineeManagerUponFirstNomination: EmailCommunicationsData;
  notifyGiverAfterEachSubmission: EmailCommunicationsData;
  notifyNomineeUponFirstNomination: EmailCommunicationsData;
}

export interface EmailCommunicationsData {
  checked: boolean;
  label: string;
}

export enum EmailCommunicationsTournamentLabels {
  NOTIFY_CANDIDATE_MANAGER = 'Notify candidate manager upon first nomination',
  NOTIFY_CANDIDATE = 'Notify candidate upon first nomination',
  NOTIFY_GIVER = 'Notify giver after each submission',
}
