import ConfirmModal from './ConfirmModal';
import { BaseModalProps } from '../types';

interface ModalProps extends BaseModalProps {
  roleName: string;
  entityName: string;
}

const RemoveRoleModal = (modalProps: ModalProps) => {
  return (
    <ConfirmModal
      {...modalProps}
      title="Remove role"
      message={`Are you sure you want to remove ${modalProps.roleName} for ${modalProps.entityName}? This can’t be undone.`}
      confirmText="Remove"
      color="error"
      onConfirm={() => {
        modalProps.setOpen(false);
        modalProps.onConfirm();
      }}
    />
  );
};

export default RemoveRoleModal;
