import packageJson from '../../../package.json';
import {
  createHttpLink,
  InMemoryCache,
  ApolloClient,
  ApolloProvider,
} from '@apollo/client';
import React, { ReactNode } from 'react';
import { apolloAuthLink } from '../auth/Auth';
import uniqBy from 'lodash/uniqBy';

export interface ApolloProviderProps {
  children: ReactNode;
  uri: string;
}

const replaceWithIncoming = {
  merge(existing: any, incoming: any) {
    return incoming;
  },
};

const cache = new InMemoryCache({
  typePolicies: {
    CorePtMgmtBankInformationConnection: {
      keyFields: [],
      fields: {
        nodes: {
          keyArgs: (args, { variables }) => variables!.customerUuid,
          merge(existing, incoming) {
            if (!existing) {
              return incoming;
            }

            return [...existing, ...incoming];
          },
        },
        pageInfo: {
          keyArgs: (args, { variables }) => variables!.customerUuid,
        },
      },
    },
    CoreProgramListConnection: {
      keyFields: [],
      fields: {
        nodes: {
          keyArgs: (args, { variables }) => variables!.customerId,
          merge(existing, incoming) {
            if (!existing) return incoming;
            return [...existing, ...incoming];
          },
        },
        pageInfo: {
          keyArgs: (args, { variables }) => variables!.customerId,
        },
      },
    },
    Permission: {
      keyFields: ['uuid'],
    },
    PermissionSummary: {
      keyFields: ['permission', ['uuid']],
    },
    CoreProgramEligibility: {
      keyFields: ['programEligibilityUuid'],
      fields: {
        businessUnitEligibilities: replaceWithIncoming,
        homeCountryEligibilities: replaceWithIncoming,
        workCountryEligibilities: replaceWithIncoming,
      },
    },
    PagedPermissionSummary: {
      keyFields: [],
      fields: {
        last: {
          keyArgs: (args, { variables }) =>
            `${variables!.roleId}:${variables!.featureCode}`,
        },
        total: {
          keyArgs: (args, { variables }) =>
            `${variables!.roleId}:${variables!.featureCode}`,
        },
        totalPages: {
          keyArgs: (args, { variables }) =>
            `${variables!.roleId}:${variables!.featureCode}`,
        },
        content: {
          keyArgs: (args, { variables }) =>
            `${variables!.roleId}:${variables!.featureCode}`,
          merge(existing = [], incoming, { readField }) {
            return uniqBy(
              [...existing, ...incoming],
              //@ts-ignore
              (item) => readField('permission', item)?.uuid
            );
          },
        },
      },
    },
  },
});

export default function ApolloProviderComponent({
  children,
  uri,
}: ApolloProviderProps) {
  const client = new ApolloClient({
    link: apolloAuthLink.concat(createHttpLink({ uri })),
    cache,
    name: packageJson.name,
    version: packageJson.version,
  });
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
