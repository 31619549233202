import { SelectionButton } from '@octanner/prism-core';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect, useState } from 'react';
import Loading from '../../../../common/Loading';
import { ExpirationTypeProps } from '../../modifyBank/types';
import FixedDate from './fixedDate';
import {
  AddBankSubtitle,
  AddMR,
  ButtomMT,
  ButtonPadding,
  MoreDateContainer,
  RangeRequired,
  SingleSelectionButtonsMB,
  TextFieldWMB,
} from './styles';
const ExpirationTypes = ({
  bankData,
  handleValueUpdate,
  type,
  checkDates,
}: ExpirationTypeProps) => {
  const { ccAdminBankBillingLocation } = useFlags();
  const [pointExpirationTypeValue, setPointExpirationTypeValue] = useState<
    'NO_EXPIRATION' | 'MONTHLY_ROLLING_EXPIRATION' | 'FIXED_DATE'
  >('NO_EXPIRATION');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (
      type === 'edit' &&
      !Array.isArray(bankData?.pointExpiryConfiguration?.fixedDates)
    ) {
      const defaultFixedDate = {
        startDateTsz: null,
        endDateTsz: null,
        expirationDateTsz: null,
      };
      handleValueUpdate('pointExpiryConfiguration', {
        ...bankData.pointExpiryConfiguration,
        fixedDates: [defaultFixedDate],
      });
    } else if (bankData.pointExpiryConfiguration.pointExpiryType) {
      setPointExpirationTypeValue(
        bankData.pointExpiryConfiguration.pointExpiryType
      );
      setLoading(false);
    } else if (type === 'add') {
      handleValueUpdate('pointExpiryConfiguration', {
        ...bankData.pointExpiryConfiguration,
        pointExpiryType: 'NO_EXPIRATION',
      });
    } else if (
      bankData.pointExpiryConfiguration.pointExpiryType === undefined
    ) {
      setLoading(false);
    }
  }, [bankData, type, handleValueUpdate]);

  const handleExpiration = (value) => {
    handleValueUpdate('pointExpiryConfiguration', {
      ...bankData.pointExpiryConfiguration,
      pointExpiryType: value,
    });
  };
  const handleMonthlyRolling = (value) => {
    handleValueUpdate('pointExpiryConfiguration', {
      ...bankData.pointExpiryConfiguration,
      monthlyRollingExpirationNum: +value,
    });
  };
  const handleDatePickerChange = (key, value, index) => {
    if (!Array.isArray(bankData?.pointExpiryConfiguration?.fixedDates)) {
      bankData.pointExpiryConfiguration.fixedDates = [
        {
          startDateTsz: null,
          endDateTsz: null,
          expirationDateTsz: null,
        },
      ];
    }
    handleValueUpdate('pointExpiryConfiguration', {
      ...bankData.pointExpiryConfiguration,
      fixedDates: bankData.pointExpiryConfiguration.fixedDates.map(
        (dateObj, i) => {
          let updatedDateObj = { ...dateObj };
          if (key === 'startDateTsz' && i === index) {
            updatedDateObj.startDateTsz = value;
          } else if (key === 'endDateTsz' && i === index) {
            updatedDateObj.endDateTsz = value;
          } else if (key === 'expirationDateTsz' && i === index) {
            updatedDateObj.expirationDateTsz = value;
          }
          return updatedDateObj;
        }
      ),
    });
  };

  const handleAddFixedDate = () => {
    const defaultFixedDate = {
      startDateTsz: null,
      endDateTsz: null,
      expirationDateTsz: null,
    };

    const existingFixedDates =
      bankData.pointExpiryConfiguration.fixedDates || [];

    const updatedFixedDates = [...existingFixedDates, defaultFixedDate];

    handleValueUpdate('pointExpiryConfiguration', {
      ...bankData.pointExpiryConfiguration,
      fixedDates: updatedFixedDates,
    });
  };

  const handleRemoveFixedDate = (indexToRemove) => {
    const updatedFixedDates =
      bankData.pointExpiryConfiguration.fixedDates || [];
    const filteredFixedDates = updatedFixedDates.filter(
      (_, index) => index !== indexToRemove
    );
    handleValueUpdate('pointExpiryConfiguration', {
      ...bankData.pointExpiryConfiguration,
      fixedDates: filteredFixedDates,
    });
  };

  return (
    <div>
      <AddBankSubtitle variant="h4">Type of expiration</AddBankSubtitle>

      {checkDates && (
        <RangeRequired checkDates={checkDates}>
          At least one date range is required.
        </RangeRequired>
      )}

      {loading ? (
        <div>
          <Loading />
        </div>
      ) : (
        <SingleSelectionButtonsMB
          value={pointExpirationTypeValue}
          onChange={(value) => handleExpiration(value)}
        >
          <SelectionButton
            value="MONTHLY_ROLLING_EXPIRATION"
            disabled={!ccAdminBankBillingLocation}
          >
            <ButtonPadding>Monthly rolling expiration</ButtonPadding>
          </SelectionButton>
          <SelectionButton
            value="FIXED_DATE"
            disabled={!ccAdminBankBillingLocation}
          >
            <ButtonPadding>Fixed date</ButtonPadding>
          </SelectionButton>
          <SelectionButton value="NO_EXPIRATION">
            <ButtonPadding>No expiration</ButtonPadding>
          </SelectionButton>
        </SingleSelectionButtonsMB>
      )}

      {bankData?.pointExpiryConfiguration?.pointExpiryType ===
        'MONTHLY_ROLLING_EXPIRATION' && (
        <TextFieldWMB
          value={
            bankData.pointExpiryConfiguration.monthlyRollingExpirationNum || ''
          }
          id="standard-number"
          label="Months"
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => {
            const value = parseInt(e.target.value);
            if (isNaN(value) || value < 1) {
              handleMonthlyRolling('');
            } else {
              handleMonthlyRolling(value);
            }
          }}
        />
      )}
      {ccAdminBankBillingLocation &&
        bankData?.pointExpiryConfiguration?.pointExpiryType ===
          'FIXED_DATE' && (
          <>
            {bankData?.pointExpiryConfiguration?.fixedDates?.map(
              (fixedDateData, index) => (
                <div key={index}>
                  <FixedDate
                    type={type}
                    fixedData={fixedDateData}
                    index={index}
                    handleDatePickerChange={handleDatePickerChange}
                    handleRemoveFixedDate={handleRemoveFixedDate}
                    fixedDates={
                      bankData?.pointExpiryConfiguration?.fixedDates || []
                    }
                  />
                </div>
              )
            )}
            <ButtomMT variant="text" onClick={handleAddFixedDate}>
              <MoreDateContainer>
                <AddMR />
                Add more dates
              </MoreDateContainer>
            </ButtomMT>
          </>
        )}
    </div>
  );
};
export default ExpirationTypes;
