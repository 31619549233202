import React from 'react';
import Alert from '@octanner/prism-core/Alert';

const ErrorMsg: React.FC<Props> = ({ error, setError }) => {
  return error ? (
    <Alert
      severity="error"
      style={{ margin: '16px 0' }}
      onClose={() => {
        setError(null);
      }}
      data-test="program:error"
    >
      {error.toString()}
    </Alert>
  ) : null;
};

interface Props {
  error: string;
  setError: (string) => void;
}

export default ErrorMsg;
