import { Button, Typography } from '@octanner/prism-core'
import { styled } from '@mui/material/styles'
import { Fieldset } from 'library/src/common/components/Styled'
import React, { useMemo, useCallback } from 'react'
import {
  useGroupSettings,
  useGroupSettingsDispatch,
} from '../../../utilities/GroupSettingsContext'
import GridSelect, {
  buildOptions,
} from '../../../common/components/GridSelect/GridSelect'

const Root = styled('section')`
    display: grid;
    grid-gap: ${({ theme }) => theme.spacing(5)};
  `,
  Content = styled(Fieldset)`
    width: 388px;
  `,
  ClearContainer = styled('div')`
    display: flex;
    justify-content: end;
    margin-top: ${({ theme }) => theme.spacing(3)};
  `

export default function AnniversaryYears(): JSX.Element {
  const groupSettingsContext = useGroupSettings()
  const dispatch = useGroupSettingsDispatch()
  const options = useMemo(() => buildOptions(1, 70), [])
  const isDisabled = useCallback(
    (value: string) => groupSettingsContext?.usedYears?.includes(value),
    [groupSettingsContext?.usedYears]
  )

  return (
    <Root>
      <Typography variant="h3">Anniversary Years</Typography>
      <Content>
        <GridSelect
          dispatch={{ type: 'setSelectedYears' }}
          value={groupSettingsContext?.selectedYears}
          columns={10}
          options={options}
          multiSelect
          getDataTestId={(value) => `anniversary-years:${value}`}
          isDisabled={isDisabled}
        />
        <ClearContainer>
          <Button
            variant="text"
            onClick={() => dispatch({ type: 'setSelectedYears', year: [] })}
          >
            Clear
          </Button>
        </ClearContainer>
      </Content>
    </Root>
  )
}
