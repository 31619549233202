import {
  Button,
  ListItemText,
  MenuItem,
  Select,
  Skeleton,
  TextField,
  Typography,
} from '@octanner/prism-core';
import {
  FlexBetweenDiv,
  RoleFieldContainer,
  RoleNameTextField,
} from './styles';
import { Dispatch, SetStateAction } from 'react';
import SuccessHandler from '../../SuccessHandler';
import { ArrowLeft, Check, Copy, Trash } from '@octanner/prism-icons';
import {
  tannerBlack,
  tannerGray,
  tannerWhite,
} from '@octanner/prism-core/ThemeProvider/colors';
import { ErrorHandler } from '../../ErrorHandler';
import { Errors, roleTypeValues, Success } from '../../types';
import { AlertContainer, CopyAdminRoleButton } from '../styles';
import { useHistory } from 'react-router-dom';

interface AdminRoleHeaderProps {
  type: 'add' | 'edit' | 'duplicate';
  roleUuid?: string;
  headerText: string;
  headerDesc: string;
  roleNameValue: string;
  setRoleNameValue: Dispatch<SetStateAction<string>>;
  roleDescriptionValue: string;
  setRoleDescriptionValue: Dispatch<SetStateAction<string>>;
  roleTypeValue: string;
  setRoleTypeValue: Dispatch<SetStateAction<string>>;
  loading?: boolean;
  errors?: Errors[];
  createSuccess?: Success;
  editSuccess?: Success;
  onNavigateAway: () => void;
  setDeleteRoleModalOpen?: (open: boolean) => void;
}

const getErrorForField = (errors: Errors[], key: string) => {
  return errors.filter((errors) => {
    return errors.errorData.error && errors.key.startsWith(key);
  })[0];
};

const removeDuplicateErrors = (errors: Errors[]): Errors[] => {
  return errors.reduce((acc: Errors[], currentError: Errors) => {
    const isDuplicate = acc.find(
      (error: Errors) =>
        error.errorData.error &&
        error.errorData.message === currentError.errorData.message
    );
    if (!isDuplicate) {
      acc.push(currentError);
    }
    return acc;
  }, []);
};

const AdminRoleHeader = ({
  type,
  roleUuid,
  headerText = '',
  headerDesc = '',
  roleNameValue,
  setRoleNameValue,
  roleDescriptionValue,
  setRoleDescriptionValue,
  roleTypeValue,
  setRoleTypeValue,
  loading = false,
  errors,
  createSuccess,
  editSuccess,
  onNavigateAway,
  setDeleteRoleModalOpen,
}: AdminRoleHeaderProps) => {
  const history = useHistory();
  return (
    <>
      <FlexBetweenDiv>
        <div>
          <Button
            variant="text"
            data-test={`admin-roles:${type}:back`}
            onClick={onNavigateAway}
            startIcon={<ArrowLeft />}
          >
            Back to admin roles
          </Button>
          <Typography variant="h1" mt={4} mb={2}>
            {headerText}
          </Typography>
          <Typography mb={4}>{headerDesc}</Typography>
        </div>
        <div>
          {type === 'edit' && setDeleteRoleModalOpen && (
            <>
              <CopyAdminRoleButton
                data-test={`admin-roles:${type}:duplicate`}
                variant="text"
                startIcon={<Copy />}
                onClick={() => {
                  history.push(`/admin-roles/${roleUuid}/duplicate`);
                }}
              >
                Duplicate role
              </CopyAdminRoleButton>
              <Button
                data-test={`admin-roles:${type}:delete-role`}
                variant="text"
                startIcon={<Trash />}
                buttonType="danger"
                onClick={() => setDeleteRoleModalOpen(true)}
              >
                Delete role
              </Button>
            </>
          )}
        </div>
      </FlexBetweenDiv>
      <AlertContainer>
        {errors && <ErrorHandler errors={removeDuplicateErrors(errors)} />}
        {createSuccess && <SuccessHandler success={createSuccess} />}
        {editSuccess && <SuccessHandler success={editSuccess} />}
      </AlertContainer>
      <RoleFieldContainer>
        {loading ? (
          <Skeleton height={79} />
        ) : (
          <RoleNameTextField
            value={roleNameValue}
            error={
              errors && getErrorForField(errors, 'roleName')?.errorData.error
            }
            onChange={(e) => setRoleNameValue(e.target.value)}
            id={`admin-roles:textfield:${type}:role-name`}
            data-test={`admin-roles:textfield:${type}:role-name`}
            label="Role name"
            displayCount
            countProps={{ className: 'display-count-class' }}
            required
            maxLength={60}
            helperText={
              (errors &&
                getErrorForField(errors, 'roleName')?.errorData.fieldMessage) ||
              'Role name helps to understand what exactly the role is'
            }
          />
        )}

        {loading ? (
          <Skeleton height={162} />
        ) : (
          <TextField
            value={roleDescriptionValue}
            error={
              errors && getErrorForField(errors, 'roleDesc')?.errorData.error
            }
            onChange={(e) => setRoleDescriptionValue(e.target.value)}
            id={`admin-roles:textfield:${type}:description`}
            data-test={`admin-roles:textfield:${type}:description`}
            label="Description"
            multiline
            rows={4}
            displayCount
            countProps={{ className: 'display-count-class' }}
            required
            maxLength={160}
            helperText={
              (errors &&
                getErrorForField(errors, 'roleDesc')?.errorData.fieldMessage) ||
              ''
            }
          />
        )}
      </RoleFieldContainer>
      <RoleFieldContainer>
        <Select
          label="Role type"
          error={
            errors && getErrorForField(errors, 'roleType')?.errorData.error
          }
          renderValue={(selected) => {
            const roleType = roleTypeValues[selected as string];
            return `${roleType.displayName} (${
              roleType.description || 'warning'
            })`;
          }}
          inputProps={{ readOnly: type !== 'add' }}
          value={roleTypeValue}
          onChange={(e) => {
            setRoleTypeValue(e.target.value as string);
          }}
          helperText={
            (errors &&
              getErrorForField(errors, 'roleType')?.errorData.fieldMessage) ||
            'Select role type to assign permissions'
          }
        >
          {Object.values(roleTypeValues).map(
            ({ key, displayName, description }) => {
              return (
                <MenuItem
                  key={key}
                  value={key}
                  sx={{
                    '& .MuiListItemIcon-root': {
                      display: 'none',
                    },
                    '&.MuiMenuItem-root.Mui-selected': {
                      backgroundColor: tannerWhite,
                    },
                  }}
                >
                  <ListItemText
                    primary={displayName}
                    primaryTypographyProps={{
                      color:
                        roleTypeValue === key ? tannerGray[500] : tannerBlack,
                    }}
                    secondary={description}
                    secondaryTypographyProps={{
                      color:
                        roleTypeValue === key
                          ? tannerGray[300]
                          : tannerGray[600],
                    }}
                    sx={{
                      color: tannerGray[500],
                    }}
                  />
                  {roleTypeValue === key && (
                    <Check sx={{ color: tannerBlack }} />
                  )}
                </MenuItem>
              );
            }
          )}
        </Select>
      </RoleFieldContainer>
    </>
  );
};

export default AdminRoleHeader;
