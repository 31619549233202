(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("@emotion/styled"), require("@octanner/prism-core"), require("@emotion/react"), require("@emotion/cache"), require("@octanner/prism-core/Alert"), require("@octanner/prism-core/ThemeProvider/colors"), require("@apollo/client"), require("@octanner/prism-core/Button"), require("@octanner/prism-core/Loader"), require("@octanner/prism-core/Typography"), require("@octanner/prism-core/FormControlLabel"), require("@octanner/prism-core/hooks"));
	else if(typeof define === 'function' && define.amd)
		define(["react", "@emotion/styled", "@octanner/prism-core", "@emotion/react", "@emotion/cache", "@octanner/prism-core/Alert", "@octanner/prism-core/ThemeProvider/colors", "@apollo/client", "@octanner/prism-core/Button", "@octanner/prism-core/Loader", "@octanner/prism-core/Typography", "@octanner/prism-core/FormControlLabel", "@octanner/prism-core/hooks"], factory);
	else if(typeof exports === 'object')
		exports["Recognition"] = factory(require("react"), require("@emotion/styled"), require("@octanner/prism-core"), require("@emotion/react"), require("@emotion/cache"), require("@octanner/prism-core/Alert"), require("@octanner/prism-core/ThemeProvider/colors"), require("@apollo/client"), require("@octanner/prism-core/Button"), require("@octanner/prism-core/Loader"), require("@octanner/prism-core/Typography"), require("@octanner/prism-core/FormControlLabel"), require("@octanner/prism-core/hooks"));
	else
		root["Recognition"] = factory(root["react"], root["@emotion/styled"], root["@octanner/prism-core"], root["@emotion/react"], root["@emotion/cache"], root["@octanner/prism-core/Alert"], root["@octanner/prism-core/ThemeProvider/colors"], root["@apollo/client"], root["@octanner/prism-core/Button"], root["@octanner/prism-core/Loader"], root["@octanner/prism-core/Typography"], root["@octanner/prism-core/FormControlLabel"], root["@octanner/prism-core/hooks"]);
})(self, function(__WEBPACK_EXTERNAL_MODULE__9155__, __WEBPACK_EXTERNAL_MODULE__5729__, __WEBPACK_EXTERNAL_MODULE__1628__, __WEBPACK_EXTERNAL_MODULE__1869__, __WEBPACK_EXTERNAL_MODULE__8384__, __WEBPACK_EXTERNAL_MODULE__6301__, __WEBPACK_EXTERNAL_MODULE__5680__, __WEBPACK_EXTERNAL_MODULE__9971__, __WEBPACK_EXTERNAL_MODULE__8785__, __WEBPACK_EXTERNAL_MODULE__226__, __WEBPACK_EXTERNAL_MODULE__4260__, __WEBPACK_EXTERNAL_MODULE__6258__, __WEBPACK_EXTERNAL_MODULE__3463__) {
return 