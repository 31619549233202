import { useStyles } from './styles';
import { CSSProperties, useContext, useEffect, useState } from 'react';
import { List, Typography } from '@octanner/prism-core';
import { RouteComponentProps } from 'react-router-dom';
import { withPad } from '../../common/BasicPadding';
import Loading from '../../common/Loading';
import { CustomerContext } from '../../utils/context/CustomerContext';
import { useGetAllRoleInfo, useGetRoleIdentityInfo } from './hooks/hooks';
import { useUpdateRole } from './hooks/role';
import IdentityGroupSelect from './IdentityGroupSelect';
import IdentityMultiselect from './IdentityMultiselect';
import { IdentityBase, IdentityGroup } from './models/Identity';
import PermissionsList from './PermissionsList';
import RoleNameForm from './RoleNameForm';
import CircularProgress from '../../common/CircularProgress';
import { PagedResult } from '../../common/models/PagedResult';

const loadingStyles: CSSProperties = {
  width: 16,
  height: 16,
  position: 'absolute',
};

const RoleComponent = (props: RouteComponentProps<{ roleId: string }>) => {
  const { roleId: rawId } = props.match.params;
  const {
    customer: { id: customerId },
  } = useContext(CustomerContext);
  const roleId = Number(rawId);

  const classes = useStyles();
  const [error, setError] = useState('');

  const getAllRoleInfoQuery = useGetAllRoleInfo({ roleId, customerId });
  const { role, getPermissionTotalsByRoleId } = getAllRoleInfoQuery.data || {};
  let PAGE_NUMBER = 0;
  const getRoleIdentityInfoQuery = useGetRoleIdentityInfo({
    customerId,
    roleUuid: role?.uuid || '',
    size: 50,
    page: PAGE_NUMBER,
  });

  const [roleIdentities, setRoleIdentities] =
    useState<PagedResult<IdentityBase>>();

  const { identitiesByRole, identityGroupsByRole } =
    getRoleIdentityInfoQuery.data || {};

  useEffect(() => {
    setRoleIdentities(identitiesByRole);
    //eslint-disable-next-line
  }, [identitiesByRole]);

  useEffect(() => {
    if (roleIdentities?.last === false) {
      PAGE_NUMBER++;
      fetchMore();
    }
    //eslint-disable-next-line
  }, [roleIdentities]);

  const fetchMore = async () => {
    const { data, error } =
      await getRoleIdentityInfoQuery.actions.roleFetchMore(PAGE_NUMBER);
    if (error) return; // TODO: Catch error!
    if (data) {
      const {
        identitiesByRole: { content, last, total, totalPages },
      } = data;
      const old = roleIdentities;
      const newRoles: PagedResult<IdentityBase> = {
        content: [...(old?.content || []), ...content],
        last,
        total,
        totalPages,
      };

      setRoleIdentities(newRoles);
    }
  };

  const updateRole = useUpdateRole();

  if (!role) {
    return <Loading />;
  }

  const handleSave = async (roleName: string) => {
    if (roleName === role.name) return;
    try {
      setError('');
      await updateRole({
        variables: { roleName, customerId, roleId },
      });
    } catch {
      setError(
        'There was a error the role.  If this continues please contact the Core Team.'
      );
    }
  };

  const handleIdentityChange = (item: IdentityBase, isAdded: boolean) => {
    const variables = { roleId: role.id, customerId, identityId: item.id };
    if (isAdded) {
      getRoleIdentityInfoQuery.actions.addIdentity({ variables });
      return;
    }
    getRoleIdentityInfoQuery.actions.removeIdentity({ variables });
  };

  const handleIdentityGroupChange = (item: IdentityGroup, isAdded: boolean) => {
    const variables = {
      roleUuid: role.uuid,
      customerId,
      identityGroupId: item.id,
    };
    if (isAdded) {
      getRoleIdentityInfoQuery.actions.addIdentityGroup({ variables });
      return;
    }
    getRoleIdentityInfoQuery.actions.removeIdentityGroup({ variables });
  };

  return (
    <div>
      <RoleNameForm
        error={error}
        name={role.name}
        onSave={handleSave}
        title={`Permissions for ${role.name}`}
      />
      <div className={classes.selectContainers}>
        <Typography className={classes.selectText} variant="h3">
          Add users
        </Typography>
        {(getRoleIdentityInfoQuery.loading ||
          getRoleIdentityInfoQuery.identityLoading) && (
          <div className={classes.selectLoading}>
            <CircularProgress style={loadingStyles} />
          </div>
        )}
      </div>
      <IdentityMultiselect
        onAdd={(i) => handleIdentityChange(i, true)}
        onRemove={(i) => handleIdentityChange(i, false)}
        selectedIdentities={roleIdentities?.content || []}
      />
      <div className={classes.selectContainers}>
        <Typography className={classes.selectText} variant="h3">
          Add identity groups
        </Typography>
        {(getRoleIdentityInfoQuery.loading ||
          getRoleIdentityInfoQuery.groupLoading) && (
          <div className={classes.selectLoading}>
            <CircularProgress style={loadingStyles} />
          </div>
        )}
      </div>
      <IdentityGroupSelect
        onAdd={(ig) => handleIdentityGroupChange(ig, true)}
        onRemove={(ig) => handleIdentityGroupChange(ig, false)}
        selectedIdentities={identityGroupsByRole?.content || []}
      />
      <Typography className={classes.permissions} variant="h3">
        Permissions
      </Typography>
      <List>
        {getPermissionTotalsByRoleId?.map((pt) => (
          <PermissionsList
            key={pt.feature.code}
            role={role}
            permissionTotal={pt}
          ></PermissionsList>
        ))}
      </List>
    </div>
  );
};
//@ts-ignore
export default withPad()(RoleComponent);
