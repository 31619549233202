import styled from '@emotion/styled';
import { Button, Checkbox, InputAdornment, Popover, TextField, Typography } from '@octanner/prism-core';
import { ArrowDown, Search } from '@octanner/prism-icons';
import React, { useEffect, useState } from 'react';
import { defaults } from '../../utils/default';
import { Locale, SelectLanguageProps } from '../../utils/types';

const Container = styled.div`
  margin-top: 10px;
`

const ButtonContainer = styled.div`
  padding: 8px 12px 8px 12px;
  box-shadow: 0px -1px 1px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: space-between;
`

const OptionContainer = styled.div`
  height: 250px;
  overflow: auto;
`

const SearchField = styled(TextField)`
  .MuiInputBase-formControl {
    padding: 0px 10px;
  }
`

const Option = styled.ul`
  listStyleType: 'none';
  padding: 5px;
  & li {
    display: flex;
    flex-direction: row;
  }
  .MuiCheckbox-root {
    margin-bottom: 3px;
  }
`

const SearchBox = styled.div`
  padding: 16px;
  min-width: 450px;
`

const LocaleSearchBox = styled(TextField)`
  .MuiInputBase-formControl {
    padding: 0px 10px;
    background: #F8F8F8;
  }
`;

const SelectLanguage: React.FC<SelectLanguageProps> = ({ locales, selectedLocales, setSelectedLocales, optionalInd, addButtonName = defaults.add, placeholderName = defaults.select_languages_title }) => {

  const [popoverAnchor, setPopoverAnchor] = useState<HTMLDivElement>(null);
  const [searchText, setSearchText] = useState<string>('');
  const [checkboxLocales, setCheckBoxLocales] = useState<Locale[]>([]);
  const [filteredLocales, setFilteredLocales] = useState<Locale[]>(locales);

  useEffect(() => {
    setFilteredLocales(locales)
  }, [locales]);

  const handleSelectAll = (): void => {
    setCheckBoxLocales(locales);
  };

  const handleClearAll = (): void => {
    setCheckBoxLocales([]);
  };

  const handleAdd = (): void => {
    setSelectedLocales(checkboxLocales);
    handlePopoverClose();
  };

  const handleCheckboxChange = (locale: Locale) => (event: React.ChangeEvent<{ checked: boolean }>) => {
    const isChecked = event.target.checked;
    setCheckBoxLocales((prevSelected) => {
      if (isChecked) {
        if (!prevSelected) {
          return [locale];
        } else {
          return [...prevSelected, locale];
        }
      } else {
        return prevSelected.filter((selected) => selected !== locale);
      }
    });
  };

  const handleSearchChange = (event: React.ChangeEvent<{ value: string }>) => {
    const newSearchText = event.target.value;
    setSearchText(event.target.value);
    const newFilteredLocales = locales.filter((locale: Locale) =>
      locale.languageDescription.toLowerCase().includes(newSearchText.toLowerCase())
    );
    setFilteredLocales(newFilteredLocales);
  };

  const handleButtonClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setCheckBoxLocales(selectedLocales)
    setPopoverAnchor(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setPopoverAnchor(null);
    setSearchText('');
    const newFilteredLocales = locales;
    setFilteredLocales(newFilteredLocales);
  };

  return (
    <Container>
      <SearchField
        fullWidth
        variant="outlined"
        placeholder={placeholderName}
        helperText={optionalInd ? defaults.optional : ''}
        onClick={handleButtonClick}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <ArrowDown />
            </InputAdornment>
          ),
        }} />
      <Popover
        open={Boolean(popoverAnchor)}
        anchorEl={popoverAnchor}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}>
        <SearchBox>
          <LocaleSearchBox
            fullWidth
            variant="outlined"
            color="primary"
            placeholder={defaults.search}
            value={searchText}
            size="small"
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: <InputAdornment position="start"><Search /></InputAdornment>
            }}
          />
          <OptionContainer>
            <Option>
              {filteredLocales?.map((locale: Locale) => (
                <Typography key={locale.code}>
                  <li>
                    <Checkbox
                      checked={!!checkboxLocales?.includes(locale)}
                      onChange={handleCheckboxChange(locale)}
                    />
                    {locale.languageDescription}
                  </li>
                </Typography>
              ))}
            </Option>
          </OptionContainer>
        </SearchBox>
        <ButtonContainer>
          <div>
            <Button variant="text" color="primary" onClick={handleSelectAll}>
              {defaults.select_all}
            </Button>
            <Button variant="text" color="secondary" buttonType="danger" onClick={handleClearAll}>
              {defaults.clear_all}
            </Button>
          </div>
          <Button size="small" variant="contained" color="primary" onClick={handleAdd}>
            {addButtonName}
          </Button>
        </ButtonContainer>
      </Popover>
    </Container>
  );
};

export default SelectLanguage;
