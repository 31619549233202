import { DateTime } from 'luxon';

export const convertNativeDateStringToDate = (
  value: string | null,
  zone?: string,
): DateTime | undefined => {
  if (!value) {
    return;
  }
  const dateTimeIso = DateTime.fromISO(value);
  const { year, month, day } = dateTimeIso;
  const dateTime = DateTime.fromObject(
    {
      year,
      month,
      day,
    },
    { zone },
  );
  return dateTime;
};
