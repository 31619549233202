import React, { useState, useContext } from 'react';
import { Search, SearchOptionCard } from '@octanner/prism-core';
import useIdentitySearch from '../utils/useIdentitySearch';
import getFullName from '../utils/getFullName';
import { IdentityPresenter } from '../types';
import CustomerIdContext from '../utils/CustomerIdContext';

interface Props {
  approver: IdentityPresenter;
  setApprovers: (approver: IdentityPresenter, id?: number) => void;
  loadingApprovers: boolean;
}

const MIN_CHARACTERS = 3;

const ApproverSearch: React.FC<Props> = ({
  approver,
  setApprovers,
  loadingApprovers,
}) => {
  const [searchText, setSearchText] = useState('');
  const { customerId } = useContext(CustomerIdContext);
  const { loading, data: users } = useIdentitySearch(searchText, customerId);
  const [errorSearchEmpty, setErrorSearchEmpty] = useState(false);

  const searchEmptyCheck = (identity) => {
    setErrorSearchEmpty(!(identity || approver?.id));
  };
  const hasMinChars = searchText && searchText.length >= MIN_CHARACTERS;

  const onChange = (e, selection) => {
    if (errorSearchEmpty) {
      searchEmptyCheck(selection);
    }
    setApprovers(selection);
  };
  return (
    <Search
      autoHighlight
      noOptionsText={
        hasMinChars
          ? `No one found matching ${searchText}`
          : 'Please enter at least three (3) characters to search.'
      }
      loading={loading}
      clearOnBlur
      fullWidth
      value={approver || null}
      onChange={onChange}
      inputValue={searchText}
      onInputChange={(e, text) => setSearchText(text)}
      options={users?.identitySearch?.identities || []}
      multiple={false}
      id="people-single-search"
      data-testid={'approver-search'}
      disabled={loadingApprovers}
      onBlur={() => searchEmptyCheck(null)}
      error={errorSearchEmpty}
      helperText={errorSearchEmpty ? 'This field is required.' : null}
      getOptionLabel={(option) =>
        option.length !== 0 ? getFullName(option) : ''
      }
      placeholder={'Search for an approver'}
      renderOption={(props, option) => (
        <SearchOptionCard
          {...props}
          showAvatar
          avatarUrl={option.profile?.avatar}
          title={getFullName(option)}
          subTitle={option.businessUnit?.buName ?? ''}
        />
      )}
    />
  );
};

export default ApproverSearch;
