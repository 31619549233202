import { AppContextData } from '../context/AppContext';

const permissionListAuthorizer =
  (suppliedPermissions: string[]) =>
  (
    { permissions }: AppContextData,
    customerId?: string
  ): { [key: string]: boolean } => {
    let allPermissions = {};
    suppliedPermissions.forEach((permission) => {
      if (!customerId) return false;
      const customerPerms = permissions.find(
        (p) => p.customerId === customerId
      );
      if (!customerPerms) return false;
      const allowedPermissions = customerPerms.permissions.some(
        (perm) => perm === permission
      );
      allPermissions[permission] = allowedPermissions;
    });
    return allPermissions;
  };

export default permissionListAuthorizer;
