import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  GET_MAPPING_BY_CUSTOMER,
  GET_ROLES,
  MAP_USER,
  UNMAP_USER,
  UPDATE_ROLE,
  UNMAP_USER_ROLES,
} from '../graphql/roles';
import {
  ClientMappingByCustomerInput,
  ClientMappingByCustomerResponse,
  MapUserInput,
  MapUserResponse,
  RequireCustomerId,
  RolesResponse,
  UnMapUserResponse,
  UpdateRoleInput,
  UpdateRoleResponse,
  UnMapUserInput,
} from '../models/Role';

export const useGetRoles = (customerId: string) => {
  let page = 0;
  const roleQuery = useQuery<RolesResponse, RequireCustomerId>(GET_ROLES, {
    variables: { customerId, page },
    fetchPolicy: 'network-only',
  });
  const totalPages = roleQuery.data?.roles.totalPages || 0;
  const fetchMore = async () => {
    const roleArray: any = roleQuery.data?.roles.content;
    while (page < totalPages) {
      page++;
      const more = await roleQuery.fetchMore({
        variables: { page },
      });
      roleArray.push(more.data.roles.content);
    }
    return roleArray.flat();
  };
  return { ...roleQuery, fetchMore };
};

export const useUpdateRole = () => {
  const [updateRole] = useMutation<UpdateRoleResponse, UpdateRoleInput>(
    UPDATE_ROLE
  );
  return updateRole;
};

export const useMapUser = () => {
  const [mapUser] = useMutation<MapUserResponse, MapUserInput>(MAP_USER);
  return mapUser;
};

export const useUnMapUser = () => {
  const [unmapUser] = useMutation<UnMapUserResponse, UnMapUserInput>(
    UNMAP_USER
  );
  return unmapUser;
};

export const useUnMapUserRoles = () => {
  const [unMapUserRoles] = useMutation<UnMapUserResponse, UnMapUserInput>(
    UNMAP_USER_ROLES
  );
  return unMapUserRoles;
};

export const useGetMappingByCustomer = () => {
  return useLazyQuery<
    ClientMappingByCustomerResponse,
    ClientMappingByCustomerInput
  >(GET_MAPPING_BY_CUSTOMER, { fetchPolicy: 'network-only' });
};
