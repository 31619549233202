import React, { useState, useEffect } from 'react';
import SortAscending from '@octanner/prism-icons/SortAscending';
import SortDescending from '@octanner/prism-icons/SortDescending';
import { Radio, Typography, Tag, Table, TableHead, TableRow, TableBody, TableCell} from '@octanner/prism-core';
import { SearchCountry } from '../salesOrg/style';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import {
  EditCountryButton,
  SortContainer,
  FlexContainer,
  FooterContainer,
  FilterContainer,
  SaveDefaultButton,
  StyledTableContainer,
  TagContainer
} from './style';
import { COUPLE_LOCALE } from './query';
import { customerId, sortArray, langObjectTranfrom} from '../../utilities/utility';
import { EditLocalesLoadedProps } from './types';

const EditLocalesLoaded: React.FC<EditLocalesLoadedProps> = ({
  selectedLanguages = [],
  handleEdit,
  deafultLanguage,
  setDeafultLanguage,
  errorsDisplayNumber,
  onSave,
  refetchCustomerLocale,
  onSapUpdateError,
  currentDefaultLanguage,
  setUpdateSapError,
}) => {

  const [coupleLocale] = useMutation(COUPLE_LOCALE,{
    onCompleted: () => {
      refetchCustomerLocale(),
      setDisableEditBtn(false),
      onSapUpdateError(''),
      setSaveDeafultLoading(false)
    },
    onError: (error) => {
      setDisableEditBtn(false),
      onSapUpdateError(error?.message),
      setSaveDeafultLoading(false)
    }
  });
  const { t } = useTranslation();

  const [searchText, setSearchText] = useState('');
  const [isAscending, setIsAscending] = useState(true);
  const [checkedList, setCheckedList] = useState<any>([]);
  const [editLangaugeBtnError, setEditLangaugeBtnError] = useState(false);
  const [disableEditBtn, setDisableEditBtn] = useState(false);
  const [saveDeafultLoading, setSaveDeafultLoading] = useState(false);
  

  useEffect(() => {
    const localeList = sortArray(selectedLanguages?.map((item: any) => langObjectTranfrom(item)), 'language')
    setCheckedList([...localeList]);
  }, [selectedLanguages])


  const handleSorting = () => {
    setIsAscending(!isAscending);
    setCheckedList(checkedList.reverse());
  }

  const handleDefaultLanguage = (lang) => {
    {
      setDeafultLanguage(lang);
      setSaveDeafultLoading(true);
      coupleLocale({
        variables: {
          input: {
            customerId: customerId,
            localeCode: lang.languageCode,
            isDefault: true,
          },
        },
      });
    }
  }

  const handleEditLanguage = () => {   
    setDeafultLanguage(currentDefaultLanguage);
    setEditLangaugeBtnError(false);
    handleEdit(true);
  }

  const handleSaveDefault = () => {
    if(deafultLanguage){
      handleDefaultLanguage(deafultLanguage);
      setDisableEditBtn(true);
    }else{
      onSave({...errorsDisplayNumber,DefaultCountryAndLanguages: 1});
      setEditLangaugeBtnError(true);
    }
  }
  
  return (
      <>
        <FilterContainer>
          <div>
            <Typography variant='h3' gutterBottom>{t('set_deafult_language')}</Typography>
            <Typography
              color = {editLangaugeBtnError ? 'error' : 'textSecondary'}
              variant='body2'
            >
              {t('select_language')}
            </Typography>
          </div>
        </FilterContainer>
        <SearchCountry
            id="searchLanguage"
            onInputChange={(e: any) => {
              setSearchText(e.target.value || '');
              setUpdateSapError(false);
            }}
            placeholder={t('search_language')}
            style={{ margin : '16px 0px'}}
            size="small"
            multiple={false}
            getOptionLabel={() => searchText}
          />
      <StyledTableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <FlexContainer>
                  <Typography variant='h4'>{t('language')}</Typography>
                  <SortContainer onClick={() => handleSorting()}>
                    {isAscending ? <SortAscending /> : <SortDescending />}
                  </SortContainer>
                </FlexContainer>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              checkedList
                ?.filter((l) =>
                  l?.language?.toLowerCase()?.includes(searchText?.toLowerCase())
                  || l?.country?.toLowerCase()?.includes(searchText?.toLowerCase())
                )
                ?.map((item: any) => {
                  return (
                    <TableRow key={item.languageCode}>
                      <TableCell>
                        <FlexContainer>
                          <Radio
                            checked={item.languageCode === deafultLanguage?.languageCode}
                            onClick={() => { setDeafultLanguage(item); }}
                          />
                          <div>
                            <Typography>{item.language}</Typography>
                            <Typography variant='body2' color='textSecondary'>{item.country}</Typography>
                          </div>
                          {item?.languageCode === currentDefaultLanguage?.languageCode && 
                          <TagContainer>
                            <Tag variant="archived">{t('default')}</Tag>
                          </TagContainer>}
                        </FlexContainer>
                      </TableCell>
                    </TableRow>
                  );
                })
            }
          </TableBody>
        </Table>
      </StyledTableContainer>
        <FooterContainer>
          <SaveDefaultButton
            loading={saveDeafultLoading}
            adminColor 
            onClick={() => handleSaveDefault()}
          >
          {t('save_default_language')}
          </SaveDefaultButton>
          <EditCountryButton
            disabled={disableEditBtn}
            onClick={() => handleEditLanguage()}
            color='secondary'
          >
            {t('edit_language_list')}
          </EditCountryButton>
        </FooterContainer>
      </>
  );
};

export default EditLocalesLoaded;
