import PageWrapper from '../../components/PageWrapper';
import BasicPadding from '../../../../../common/BasicPadding';
import { useContext, useEffect, useState } from 'react';
import { AdminRoleInformation, ErrorData, Errors } from '../../types';
import AdminHeader from '../components/AdminHeader';
import AdminDetails from '../components/AdminDetails';
import { useHistory } from 'react-router';
import UnsavedModal from '../../components/UnsavedModal';
import { ErrorHandler } from '../../ErrorHandler';
import Typography from '@octanner/prism-core/Typography';
import AddAdminRoleSearch from '../../components/AddAdminRoleSearch';
import { Box } from '@octanner/prism-core';
import RoleInformationList from '../../components/RoleInformationList';
import { AlertContainer } from '../styles';
import { useCreateAdmin } from '../hooks/createAdmin';
import { FetchResult } from '@apollo/client';
import { AppContext } from '../../../../../utils/context/AppContext';

const CreateAdmin = () => {
  const TYPE = 'add';
  const history = useHistory();
  const [identityUuid, setIdentityUuid] = useState<string>();
  const [adminRoles, setAdminRoles] = useState<AdminRoleInformation[]>([]);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [unsavedModalOpen, setUnsavedModalOpen] = useState(false);
  const [createAdmin] = useCreateAdmin();
  const { config } = useContext(AppContext);

  const [hasIdentity, setHasIdentity] = useState<ErrorData>({
    error: false,
    message: 'An admin must be added to continue.',
  });
  const adminErrors: Errors[] = [
    {
      key: 'identityRequired',
      errorData: hasIdentity,
      setErrorData: setHasIdentity,
    },
  ];
  const [serverErrorData, setServerErrorData] = useState<ErrorData>({
    error: false,
    message: '',
  });
  const serverErrors: Errors[] = [
    {
      key: 'serverError',
      errorData: serverErrorData,
      setErrorData: setServerErrorData,
    },
  ];

  const resetErrors = () => {
    setHasIdentity({ ...hasIdentity, error: false });
  };

  const errorCheck = () => {
    let anyError = false;
    if (!identityUuid) {
      setHasIdentity({ ...hasIdentity, error: true });
      anyError = true;
    }
    return anyError;
  };

  const onSave = () => {
    resetErrors();
    let isError = errorCheck();

    if (config.useRoleDelegatorMockData) {
      history.push(`/admins/${identityUuid}/edit?isNew=true`);
      return;
    }

    if (!isError) {
      createAdmin({
        variables: {
          identityUuid: identityUuid!,
          roleUuids: adminRoles.map((r) => r.role.uuid),
        },
      }).then((result) => {
        const r = (result as FetchResult).data?.rdAddRolesToAdmin;
        if ('rdValidationErrors' in r) {
          const message = r.rdValidationErrors
            .map((e) => e.errorMessage)
            .join('; ');
          setServerErrorData({
            error: true,
            message: `Errors occurred while saving: [${message}]`,
          });
        } else {
          history.push(`/admins/${identityUuid}/edit?isNew=true`);
        }
      });
    }
  };

  const onDiscard = () => {
    history.push('/admins');
  };

  const onCancel = () => {
    if (hasUnsavedChanges) {
      setUnsavedModalOpen(true);
    } else {
      onDiscard();
    }
  };

  useEffect(() => {
    setHasUnsavedChanges(identityUuid !== null || adminRoles.length > 0);
  }, [identityUuid, adminRoles]);

  return (
    <PageWrapper
      type={TYPE}
      context="admins"
      saveAction={onSave}
      cancelAction={onCancel}
    >
      <BasicPadding padding="sm">
        <UnsavedModal
          open={unsavedModalOpen}
          setOpen={setUnsavedModalOpen}
          onConfirm={onDiscard}
        />
        <AdminHeader type={TYPE} onNavigateAway={onCancel} />
        <AlertContainer>
          <ErrorHandler errors={adminErrors} />
          <ErrorHandler errors={serverErrors} />
        </AlertContainer>
        <AdminDetails
          type={TYPE}
          identityUuid={identityUuid}
          onSelect={(identity) => setIdentityUuid(identity)}
          identityError={hasIdentity}
        />
        <Typography variant="h2" mt={6}>
          Add a role
        </Typography>
        <AddAdminRoleSearch
          rolesToAdd={adminRoles}
          setRolesToAdd={setAdminRoles}
        />
        <Box sx={{ pb: '64px' }}>
          <RoleInformationList
            type="active"
            changingRoles={adminRoles}
            pagedRoles={[]}
            onAction={(roleToRemove) => {
              setAdminRoles(adminRoles.filter((r) => r !== roleToRemove));
            }}
            isActionDisabled={(_) => false}
          />
        </Box>
      </BasicPadding>
    </PageWrapper>
  );
};

export default CreateAdmin;
