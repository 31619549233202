import { useMutation } from '@apollo/client';
import styled from '@emotion/styled';
import { Button, Chip, Modal, Typography } from '@octanner/prism-core';
import React, { useContext, useState } from 'react';
import { LanguageStringsContext } from '../../../../Contexts/LanguageStringsContext';
import { defaults } from '../../../../utils/default';
import { REQUEST_TRANSLATION, SAVE_NOTES } from '../../../../utils/query';
import StyledTextField from '../../../../utils/styledComponents';
import { RequestTranslationModalProps, Translation } from '../../../../utils/types';

const Header = styled(Typography)`
  margin : 16px 0px;
`;

const ChipContainer = styled.div`
  margin : 16px 0px 24px 0px;
  display : flex;
  flex-wrap : wrap; 
`;

const StyledChip = styled(Chip)`
  margin : 8px 8px 0px 0px;
`;

const RequestTranslationModal: React.FC<RequestTranslationModalProps> = ({
  showRequestTranslationModal,
  setShowRequestTranslationModal,
  requestedLocales,
  selectedString
}) => {

  const { setRefetchInd, setShowFooter, setMessageText } =
    useContext(LanguageStringsContext);

  const { stringId } = selectedString;
  const note = selectedString?.notes || '';
  const [notes, setNotes] = useState(note || '');
  const [requestTranslation] = useMutation(REQUEST_TRANSLATION, {
    onError: () => {
      setMessageText('errorMessage', defaults.update_error)
    }
  });
  const [sendLoading, setSendLoading] = useState(false);
  const [saveNotes] = useMutation(SAVE_NOTES);

  const handleSend = () => {
    if (sendLoading) {
      return;
    }
    setSendLoading(true);
    requestTranslation({
      variables: {
        input: {
          localeList: requestedLocales.map((localeTranslation: Translation) => localeTranslation.locale),
          stringId: stringId,
          useExistingTranslationInd: false
        }
      }
    }).then((response) => {
      if (response.errors) {
        setMessageText('errorMessage', defaults.update_error);
        setShowRequestTranslationModal(false);
        setSendLoading(false);
      } else {
        let successMessage = defaults.request_success;
        if (requestedLocales.length > 0) {
          const localeDescriptions = requestedLocales.map(locale => locale.localeDescription);
          if (localeDescriptions.length === 1) {
            successMessage += ` for ${localeDescriptions[0]}`;
          } else {
            const lastLocale = localeDescriptions.pop();
            successMessage += ` for ${localeDescriptions.join(', ')} and ${lastLocale}`;
          }
        }
        setMessageText('requestSuccessMessage', successMessage);
        if (note != null && notes.trim() !== note.trim()) {
          saveNotes({
            variables: {
              input: {
                languageStringNotes: notes ? notes.trim() : '',
                stringId: stringId,
              },
            },
          }).then(() => {
            closeModal();
          });
        } else {
          closeModal();
        }
      }
    })
  };

  const closeModal = () => {
    setShowRequestTranslationModal(false);
    setSendLoading(false);
    setShowFooter(false);
    setRefetchInd(true);
  }

  return (
    <Modal
      actions={
        <>
          <Button color='secondary' disabled={sendLoading} onClick={() => setShowRequestTranslationModal(false)}>{defaults.cancel}</Button>
          <Button adminColor onClick={() => handleSend()} loading={sendLoading} disabled={(notes?.trim()?.length > 4000)}>{defaults.send}</Button>
        </>
      }
      id='send-translation-request'
      title={defaults.send_request}
      open={showRequestTranslationModal}
      onClose={() => setShowRequestTranslationModal(false)}>
      <div>
        <Header variant='h3'>
          {defaults.send_translation_request}
        </Header>
        <ChipContainer>
          {requestedLocales.map((item) => (
            <StyledChip
              key={item.locale}
              label={item.localeDescription}
              variant='outlined'
            />
          ))}
        </ChipContainer>
      </div>
      <Header variant='h4'>{defaults.notes} :</Header>
      <StyledTextField
        placeholder={`${defaults.notes} :`}
        fullWidth
        multiline
        displayCount={true}
        maxLength={4000}
        rows={4}
        value={notes}
        onChange={(e) => setNotes(e.target.value)}
      />
    </Modal>
  );
};

export default RequestTranslationModal;
