import {
  AdminRoleSummary,
  BusinessUnit,
  IdentityWithAdminRoles,
  PagedIdentitiesWithAdminRoles,
} from './types';
import { TableRowHeader, TableRows } from '../types';
import React from 'react';
import { Tag, Typography } from '@octanner/prism-core';
import { BUNameTypography } from './styles';

const TEST_ADMINS_DATA: IdentityWithAdminRoles[] = [
  {
    identityUuid: '1',
    preferredName: 'Deb',
    firstName: 'Deb',
    lastName: 'Walters',
    email: 'deb.walters@octanner.com',
    rdAdminRoleSummary: {
      activeRoleCount: 2,
      activeClientCount: 3,
      inactiveRoleCount: 0,
    },
    uniqueId: 'E1',
    businessUnit: {
      buName: 'Information Technology',
    },
    jobTitle: 'Director',
  },
  {
    identityUuid: '2',
    preferredName: 'Rebecca',
    firstName: 'Rebecca',
    lastName: 'Clayton',
    email: 'rebecca.clayton@octanner.com',
    rdAdminRoleSummary: {
      activeRoleCount: 4,
      activeClientCount: 10,
      inactiveRoleCount: 0,
    },
    uniqueId: 'E2',
    businessUnit: {
      buName: 'Information Technology',
    },
    jobTitle: 'Manager',
  },
  {
    identityUuid: '3',
    preferredName: 'Lisa',
    firstName: 'Lisa',
    lastName: 'Ngu',
    email: 'lisa.ngu@octanner.com',
    rdAdminRoleSummary: {
      activeRoleCount: 2,
      activeClientCount: 7,
      inactiveRoleCount: 0,
    },
    terminationDate: '2021-08-01',
    uniqueId: 'E3',
    businessUnit: {
      buName: 'Information Technology',
    },
    jobTitle: 'Sr Software Engineer',
  },
  {
    identityUuid: '4',
    preferredName: 'Rachel',
    firstName: 'Rachel',
    lastName: 'Sturm',
    email: 'rachel.sturm@octanner.com',
    rdAdminRoleSummary: {
      activeRoleCount: 1,
      activeClientCount: 2,
      inactiveRoleCount: 0,
    },
    uniqueId: 'E4',
    businessUnit: {
      buName: 'Information Technology',
    },
    jobTitle: 'Software Engineer',
  },
  {
    identityUuid: '5',
    preferredName: 'Meg',
    firstName: 'Meg',
    lastName: 'Tuicker',
    email: 'meg.tucker@octanner.com',
    rdAdminRoleSummary: {
      activeRoleCount: 6,
      activeClientCount: 27,
      inactiveRoleCount: 0,
    },
    uniqueId: 'E5',
    businessUnit: {
      buName: 'Information Technology',
    },
    jobTitle: 'Sr Software Engineer',
  },
  {
    identityUuid: '6',
    preferredName: 'Kassidy',
    firstName: 'Kassidy',
    lastName: 'Knobel',
    email: 'kassidy.knobel@octanner.com',
    rdAdminRoleSummary: {
      activeRoleCount: 5,
      activeClientCount: 11,
      inactiveRoleCount: 0,
    },
    uniqueId: 'E6',
    businessUnit: {
      buName: 'Information Technology',
    },
    jobTitle: 'Software Engineer',
  },
  {
    identityUuid: '7',
    preferredName: 'Sufiyan',
    firstName: 'Sufiyan',
    lastName: 'Ansari',
    email: 'sufiyan.ansari@octanner.com',
    rdAdminRoleSummary: {
      activeRoleCount: 3,
      activeClientCount: 8,
      inactiveRoleCount: 0,
    },
    terminationDate: '2023-08-01',
    uniqueId: 'E7',
    businessUnit: {
      buName: 'Information Technology',
    },
    jobTitle: 'Software Engineer',
  },
  {
    identityUuid: '8',
    preferredName: 'Arielle',
    firstName: 'Arielle',
    lastName: 'Moore',
    email: 'arielle.moore@octanner.com',
    rdAdminRoleSummary: {
      activeRoleCount: 0,
      activeClientCount: 0,
      inactiveRoleCount: 0,
    },
    uniqueId: 'E8',
    businessUnit: {
      buName: 'Information Technology',
    },
    jobTitle: 'Associate Software Engineer',
  },
  {
    identityUuid: '9',
    preferredName: 'Matt',
    firstName: 'Matthew',
    lastName: 'Brewster',
    email: 'matthew.brewster@octanner.com',
    rdAdminRoleSummary: {
      activeRoleCount: 1,
      activeClientCount: 3,
      inactiveRoleCount: 0,
    },
    uniqueId: 'E9',
    businessUnit: {
      buName: 'HR',
    },
    jobTitle: 'Director',
  },
  {
    identityUuid: '10',
    preferredName: 'Indira',
    firstName: 'Indira',
    lastName: 'Kurmanova',
    email: 'indira.kurmanova@octanner.com',
    rdAdminRoleSummary: {
      activeRoleCount: 2,
      activeClientCount: 4,
      inactiveRoleCount: 0,
    },
    uniqueId: 'E10',
    businessUnit: {
      buName: 'Sales',
    },
    jobTitle: 'Director',
  },
];

export const TEST_ADMINS_PAGED_DATA: PagedIdentitiesWithAdminRoles = {
  content: TEST_ADMINS_DATA,
  total: 234,
  totalPages: 37,
  last: true,
};

export const AdminsHeadCells: TableRowHeader[] = [
  { id: 'name', label: 'Name', align: 'left', sortKey: 'NAME' },
  { id: 'email', label: 'Email', align: 'left' },
  {
    id: 'activeRoles',
    label: 'Active Roles',
    align: 'center',
  },
  { id: 'clients', label: 'Clients', align: 'center' },
  {
    id: 'status',
    label: 'Employment Status',
    align: 'left',
  },
  { id: 'blank', label: '', align: 'center' },
];

export const tableRows: TableRows[] = [
  {
    key: 'name',
    test: 'name',
    align: 'left',
    customComponent: function nameLabel({
      identityUuid,
      preferredName,
      firstName,
      lastName,
      businessUnit,
    }: {
      identityUuid: string;
      preferredName?: string;
      firstName: string;
      lastName: string;
      businessUnit?: BusinessUnit;
    }) {
      return (
        <>
          <Typography data-test={`admins:${identityUuid}:name`}>
            {`${preferredName || firstName} ${lastName}`}
          </Typography>
          <BUNameTypography
            data-test={`admins:${identityUuid}:bu-name`}
            variant="body2"
          >
            {businessUnit?.buName}
          </BUNameTypography>
        </>
      );
    },
  },
  {
    key: 'email',
    test: 'email',
    align: 'left',
  },
  {
    key: 'activeRoles',
    test: 'activeRoles',
    align: 'center',
    customComponent: function activeRolesLabel({
      identityUuid,
      rdAdminRoleSummary,
    }: {
      identityUuid: string;
      rdAdminRoleSummary?: AdminRoleSummary;
    }) {
      return (
        <Typography data-test={`admins:${identityUuid}:activeRoles`}>
          {rdAdminRoleSummary?.activeRoleCount || 0}
        </Typography>
      );
    },
  },
  {
    key: 'clients',
    test: 'clients',
    align: 'center',
    customComponent: function activeRolesLabel({
      identityUuid,
      rdAdminRoleSummary,
    }: {
      identityUuid: string;
      rdAdminRoleSummary?: AdminRoleSummary;
    }) {
      return (
        <Typography data-test={`admins:${identityUuid}:clients`}>
          {rdAdminRoleSummary?.activeClientCount || 0}
        </Typography>
      );
    },
  },
  {
    key: 'status',
    test: 'status',
    align: 'left',
    customComponent: function statusLabel({
      identityUuid,
      terminationDate,
    }: {
      identityUuid: string;
      terminationDate: string | null;
    }) {
      return (
        <Tag
          data-test={`admins:${identityUuid}:status`}
          variant={terminationDate ? 'warning' : 'success'}
        >
          {terminationDate ? 'Terminated' : 'Active'}
        </Tag>
      );
    },
  },
];
