import React from 'react';
import styled from '@emotion/styled';

import { TextField, Select, MenuItem } from '@octanner/prism-core';
import { tannerGray } from '@octanner/prism-core/ThemeProvider/colors';

import { HomepageWidgetHeaderEnum } from '../types';

const Container = styled.div`
  padding-bottom: 16px;
  border-bottom: solid 1px ${tannerGray[200]};
`;

const WidgetHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 16px 0;
  gap: 10px;
`;

const HeaderTextField = styled(TextField)``;

const WidgetMessageText = styled(TextField)`
  display: block;
`;

const HomepageWidgetForm: React.FC<Props> = ({
  customHeaderText,
  setCustomHeaderText,
  widgetText,
  setWidgetText,
  homepageWidgetHeader,
  handleChange,
}) => {
  return (
    <Container>
      <WidgetHeaderContainer>
        <Select
          value={homepageWidgetHeader}
          label="Widget Header"
          fullWidth
          helperText="What do you want to use for the promotional widget header?"
          onChange={(event) => {
            setTimeout(() => {
              handleChange(event);
            });
          }}
        >
          <MenuItem value={'USE_PROGRAM_NAME' as HomepageWidgetHeaderEnum}>
            Use Program Name
          </MenuItem>
          <MenuItem value={'CUSTOM_TEXT' as HomepageWidgetHeaderEnum}>
            Custom Text
          </MenuItem>
        </Select>
        {homepageWidgetHeader ===
          ('CUSTOM_TEXT' as HomepageWidgetHeaderEnum) && (
          <HeaderTextField
            value={customHeaderText || ''}
            onChange={(event) => setCustomHeaderText(event.target.value)}
            label="Enter Custom Text"
            fullWidth
            multiline
            rows={1}
            displayCount
            maxLength={50}
          />
        )}
      </WidgetHeaderContainer>
      <WidgetMessageText
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
        value={widgetText || ''}
        onChange={(event) => setWidgetText(event.target.value)}
        label="Widget Message"
        helperText="What do you want to use for the promotional widget message?"
        fullWidth
        multiline
        rows={5}
        displayCount
        maxLength={1000}
        InputLabelProps={{ shrink: !!widgetText }}
      />
    </Container>
  );
};

interface Props {
  customHeaderText: string;
  setCustomHeaderText: (text: string) => void;
  widgetText: string;
  setWidgetText: (text: string) => void;
  homepageWidgetHeader: string;
  handleChange: (e) => void;
}
export default HomepageWidgetForm;
