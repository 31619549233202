import React, { useEffect } from 'react'
import { Alert } from '@octanner/prism-core'
import { useTranslation } from 'react-i18next';


export const AlertWarning = ({
    showWarning,
    clientAddressWarning,
    setClientAddressWarning,
    clientSalesOrgWarning,
    setClientSalesOrgWarning,
    clientLocaleWarning,
    setClientLocaleWarning,
    onSave,
    errorsDisplayNumber,
    checkedSalesOrg,
    customerInfo,
    calledRefetch
}) => {
    const { t } = useTranslation();
    useEffect(() => {
        if(showWarning && calledRefetch) {
            const isSalesOrgEmpty = checkedSalesOrg?.length === 0;
            const isCustomerInfoUndefined = customerInfo === undefined;
            setClientSalesOrgWarning(isSalesOrgEmpty);
            setClientAddressWarning(isCustomerInfoUndefined);
            onSave({
            ...errorsDisplayNumber,
             CountryAndSalesOrgSetup: isSalesOrgEmpty || isCustomerInfoUndefined ? 1 : 0,
              ClientFinancialInfo: isCustomerInfoUndefined ? 4 : 0,
            });
        }
    }, [calledRefetch])

    return (
        <>
           {clientAddressWarning && (
                <Alert
                  onClose={() => setClientAddressWarning(false)}
                  severity="error"
                  style={{marginTop: '8px'}}
                  test-id="client-financial-warning"
                >
                  {t('client_financial_warning_alert')}
                </Alert>
            )}
            {clientSalesOrgWarning && (
                    <Alert
                    onClose={() => setClientSalesOrgWarning(false)}
                    severity="error"
                    style={{marginTop: '8px'}}
                    test-id="sales-org-warning"
                    >
                    {t('sales_org_warning_alert')}
                    </Alert>
            )}
            {clientLocaleWarning && (
                    <Alert
                    onClose={() => setClientLocaleWarning(false)}
                    severity="error"
                    style={{marginTop: '8px'}}
                    test-id="language-warning"
                    >
                    {t('language_warning_alert')}
                    </Alert>
            )}
        </>
    )
}
