import { gql } from '@apollo/client';

export const LOCALES_LIST = gql`
  query GetCoreLocales {
    getCoreLocales {
      coreLocales {
        nodes {
          code
          underscoreCode
          languageDescription
          countryDescription
        }
      }
    }
  }
`;

export const CHECKED_LOCALES_LIST = gql`
  query CustomerCoreLocaleMapsByCustomer(
    $input: CustomerCoreLocaleMapsByCustomerInput!
  ) {
    customerCoreLocaleMapsByCustomer(input: $input) {
      nodes {
        isDefaultLocale
        locale {
          code
          languageDescription
        }
      }
    }
  }
`;

export const COUPLE_LOCALE = gql`
  mutation CoupleCustomerAndLocale($input: CustomerLocaleMapInsertRequest!) {
    coupleCustomerAndLocale(input: $input) {
      customerLocale {
        locale {
          code
        }
      }
    }
  }
`;

export const DECOUPLE_LOCALE = gql`
  mutation DecoupleCustomerAndLocale(
    $input: DecoupleCustomerLocaleMapRequest!
  ) {
    decoupleCustomerAndLocale(input: $input) {
      customerLocale {
        locale {
          code
        }
      }
    }
  }
`;

