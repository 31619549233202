import styled from "@emotion/styled";
import { Checkbox, TableCell, TableRow, Tag, TextField, Typography } from "@octanner/prism-core";
import React, { useContext } from 'react';
import { TranslationRequestContext } from "../../Contexts/TranslationRequestContext";
import { formatDate } from "../../utils/commonActions";
import { translationCategories } from "../../utils/constants";
import { defaults } from "../../utils/default";
import { TranslationRequestTableRowProps, UnTranslatedString } from "../../utils/types";

const CheckboxCellDiv = styled.div`
  display: flex;
  align-items: center;
`

const TextFieldCell = styled(TableCell) <{ customTextIndicator: boolean }>`
  width: ${props => (props.customTextIndicator ? '35%' : '40%')};
  .MuiFilledInput-input {
    font-size: 12px;
  }
`;

const TranslationRequestTableRow: React.FC<TranslationRequestTableRowProps> = ({
  data,
  createJobLoading,
  holdRequestLoading
}) => {

  const { translationCategory, setUnTranslatedStringList, messages,
    closeMessage, setMessageText, setSelectAll } = useContext(TranslationRequestContext);

  const handleCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (messages[0]?.text?.length > 0) {
      closeMessage('infoMessage');
    }
    const isChecked = e.target.checked;
    setUnTranslatedStringList((prevList: UnTranslatedString[]) => {
      const updatedList = prevList.map((item: UnTranslatedString) => {
        if (item.requestStatus?.uniqueId === data.requestStatus?.uniqueId) {
          if (isChecked && item.onHoldInd) {
            setMessageText('selectOnHoldInfo', defaults.on_hold_selected_info_message);
          }
          return { ...item, selected: isChecked };
        }
        return item;
      });
      setSelectAll(updatedList.every((item) => item.selected || item.onHoldInd));
      return updatedList;
    });
  }

  return (
    <TableRow>
      <TableCell>
        <CheckboxCellDiv>
          <Checkbox disabled={createJobLoading || holdRequestLoading}
            checked={data.selected}
            onChange={(e) => handleCheckbox(e)}
          />
          &nbsp;{data.onHoldInd && <Tag variant="warning">{defaults.on_Hold}</Tag>}
        </CheckboxCellDiv>
      </TableCell>
      {translationCategory === translationCategories.MATERIAL_DESCRIPTION.value && <TableCell>
        <Typography variant='body2'>
          {data.sapPartNumber}
        </Typography>
      </TableCell>}
      <TableCell>
        <Typography variant="body2">
          {data.requestedByName || "-"}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{formatDate(data.requestStatus?.createdDate)}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">
          {translationCategory === translationCategories.MATERIAL_DESCRIPTION.value ? data.fieldType : data.stringId}
        </Typography>
      </TableCell>
      <TextFieldCell customTextIndicator={translationCategory === translationCategories.CUSTOM_TEXT.value}>
        <TextField multiline
          minRows={1} fullWidth value={(translationCategory === translationCategories.LANGUAGE_STRING.value ? data.text : data.sourceText)} InputProps={{ readOnly: true }} />
      </TextFieldCell>
      {translationCategory === translationCategories.CUSTOM_TEXT.value && <TableCell>
        <Typography variant="body2">{data.sourceLocale === 'id-ID' ? 'in-ID' : data.sourceLocale}</Typography>
      </TableCell>}
    </TableRow>
  );
}

export default TranslationRequestTableRow;