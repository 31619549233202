/* eslint-disable react/no-children-prop */
import React from 'react';
import ClientConfig from './ClientConfig/components/HomePage/ClientConfig';
import ClientCreation from './ClientCreation/ClientCreation';

const App: React.FC<Props> = ({ customerUUID ,ccAdminEnableComboModel , historyLocationProp}) => (
  <>
  <ClientConfig customerUUID={customerUUID} ccAdminEnableComboModel={ccAdminEnableComboModel} historyLocationProp={historyLocationProp} enableApplicationsTab={true}/>

  </>
);

export interface Props {
  customerUUID: string;
  ccAdminEnableComboModel: boolean;
  historyLocationProp: {
    location:any;
    history:any;
  }
}

export default App;
export { ClientConfig, ClientCreation };
