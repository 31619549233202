import { useState, useContext, useEffect } from 'react';
import { withPad } from '../../../common/BasicPadding';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { CustomerContext } from '../../../utils/context/CustomerContext';
import {
  Search,
  DualActionButton,
  MenuItem,
  Button,
} from '@octanner/prism-core';
import { useSearchCustomers } from '../hooks/customer';
import { useGetCustomer } from '../../customer/hooks/customer';
import { authFetch } from '../../../utils/auth/Auth';
import { useCurrentIdentity } from '../../user-auth/hooks/identity';

import { useStyles } from '../styles';
import { CustomerBase } from '../../customer/models/Customer';
import RecentClients from './RecentClients';

const GlobalClientList = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const [searchText, setSearchText] = useState('');
  const { setCurrentImpersonateClient, recent, setRecent } =
    useContext(CustomerContext);
  const [invalidPermissions, setInvalidPermissions] = useState(false);
  const { data: currentIdentityData } = useCurrentIdentity();

  const [selectedCustomer, setSelectedCustomer] = useState<CustomerBase | null>(
    null
  );

  const { data, loading } = useSearchCustomers(
    { pageSize: 10, pageNumber: 0 },
    searchText
  );
  const options = data?.searchCustomers?.nodes;

  const { data: selectedCustomerData, loading: selectedCustomerDataLoading } =
    useGetCustomer(selectedCustomer?.id || '', true);

  const setInvalidPermissionsValue = (value: boolean) => {
    if (invalidPermissions !== value) {
      setInvalidPermissions(value);
    }
  };

  const updateRecentClientsList = (selectedCustomer: CustomerBase) => {
    const currentRowIndex = recent.findIndex(
      (it) => it.id === selectedCustomer?.id
    );
    let currentRecent = recent;
    currentRecent = [
      currentRowIndex > -1 ? recent[currentRowIndex] : selectedCustomer,
      ...recent.filter((it) => it.id !== selectedCustomer?.id),
    ];
    setRecent(currentRecent);
    const { PUBLIC_URL: publicUrl } = process.env;
    authFetch(
      `${publicUrl}/recent/clients/${currentIdentityData?.currentIdentity?.id}`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(currentRecent),
      }
    );
  };

  useEffect(() => {
    if (searchText && selectedCustomerDataLoading === false) {
      const hasInvalidPermissions = typeof selectedCustomerData === 'undefined';
      setInvalidPermissionsValue(hasInvalidPermissions);
      if (!hasInvalidPermissions) {
        if (selectedCustomer) {
          updateRecentClientsList(selectedCustomer);
        }
      }
    }
    //eslint-disable-next-line
  }, [selectedCustomerDataLoading, selectedCustomer]);

  return (
    <div>
      <div className={classes.clientListTitle}>
        {t('client-list', 'Client List')}
      </div>
      <div className={classes.clientListSubTitle}>
        {t(
          'search-clients-list',
          'Search clients or select from your Recents list'
        )}
      </div>
      <div className={classes.clientListSearchWrapper}>
        <div className={classes.clientListSearch}>
          <Search
            error={invalidPermissions}
            helperText={
              invalidPermissions
                ? t('no-client-access', "you don't have access to this client")
                : ''
            }
            placeholder={t(
              'search-client-list',
              'Search client name, STP, or Core ID'
            )}
            id="people-single-search"
            multiple={false}
            loading={loading || selectedCustomerDataLoading}
            className={classes.action}
            onInputChange={(e: any) => {
              if (typeof e.target.value === 'string') {
                setSearchText(e.target.value);
              }
            }}
            onChange={(event, value) => {
              if (!value) {
                setInvalidPermissions(false);
              } else if (typeof value !== 'string') {
                setSelectedCustomer(value);
              }
            }}
            getOptionLabel={(option) => {
              if (typeof option === 'string') return option;
              return `${option.name} (${option.id})`;
            }}
            // @ts-ignore strange bug with prism
            type="single"
            options={options}
            renderOption={(props, option) => {
              return (
                <MenuItem
                  data-option-index={options?.findIndex(
                    (it) => it.id === option.id
                  )}
                  onClick={(a) => {
                    if (props.onClick) props.onClick(a);
                  }}
                >
                  <div>
                    <div className={classes.searchListTitle}>{option.name}</div>
                    <div className={classes.searchListSubTitle}>{`${t(
                      'stp',
                      'STP:'
                    )} ${option.stpNumber || 'N/A'}`}</div>
                    <div className={classes.searchListSubTitle}>{`${t(
                      'core-id',
                      'Core ID:'
                    )} ${option.id}`}</div>
                  </div>
                </MenuItem>
              );
            }}
          />
        </div>
        <div className={classes.clientListSearchButtonsWrapper}>
          <div className={classes.clientListSearchButtons}>
            <DualActionButton
              disabled={!selectedCustomerData}
              data-test-id="go-to-client-admin-button"
              label={t('go-to-client-admin', 'Go to client admin')}
              onClick={() => {
                history.push(
                  `/customer/${selectedCustomer?.id}/${
                    selectedCustomer?.customerStatus?.toLowerCase() === 'active'
                      ? 'client-summary'
                      : 'client-summary/edit'
                  }`
                );
              }}
              adminColor
            >
              <MenuItem
                onClick={() => {
                  history.push(
                    `/customer/${selectedCustomer?.id}/client-summary`
                  );
                }}
              >
                {t('client-summary', 'Client Summary')}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  history.push(
                    `/customer/${selectedCustomer?.id}/client-config`
                  );
                }}
              >
                {t('client-setup', 'Client Setup')}
              </MenuItem>
            </DualActionButton>
            <div className={classes.impersonateButton}>
              <Button
                disabled={!selectedCustomerData}
                onClick={() => {
                  setCurrentImpersonateClient(selectedCustomer);
                  history.push('/impersonate');
                }}
                color="secondary"
              >
                {t('impersonate', 'Impersonate')}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div>
        <RecentClients
          setCurrentImpersonateClient={setCurrentImpersonateClient}
          id={selectedCustomer?.id}
          updateRecentClientsList={(id) => updateRecentClientsList(id)}
        />
      </div>
    </div>
  );
};

export default withPad()(GlobalClientList);
