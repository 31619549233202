import { TEST_INACTIVE_ADMIN_ROLES_PAGED_DATA } from '../../constants';
import { AppContext } from '../../../../../utils/context/AppContext';
import { useContext } from 'react';
import { GET_ADMIN_INACTIVE_ROLES } from '../graphql/gql';
import { useQuery } from '@apollo/client';
import {
  AdminPagedInactiveRolesResponse,
  GetAdminPagedRolesInput,
} from '../graphql/types';

const mockData = {
  data: TEST_INACTIVE_ADMIN_ROLES_PAGED_DATA.content,
  loading: false,
  refetch: () => {},
};

export const useGetAdminInactiveRoles = (identityUuid: string) => {
  const { config } = useContext(AppContext);
  const query = useQuery<
    AdminPagedInactiveRolesResponse,
    GetAdminPagedRolesInput
  >(GET_ADMIN_INACTIVE_ROLES, {
    variables: {
      identityUuid: identityUuid,
      pageNumber: 0,
      pageSize: 250,
    },
    context: {
      clientName: 'roleDelegator',
    },
    skip: config.useRoleDelegatorMockData,
  });
  return config.useRoleDelegatorMockData
    ? mockData
    : {
        ...query,
        data: query.data?.rdFindInactiveRolesByAdmin.content,
      };
};
