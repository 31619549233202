import { PermissionCategory } from './types';
import {
  RoleInformation,
  RoleInformationWithPermissions,
  roleTypeValues,
  TableRowHeader,
  TableRows,
} from '../types';
import { Tag, Tooltip, Typography } from '@octanner/prism-core';
import React from 'react';
import { InformationCircleGray, NameGap } from './styles';
import { formatDate } from '../dateUtils';
import {
  PERMISSION_1_READ,
  PERMISSION_1_WRITE,
  PERMISSION_2_WRITE,
  PERMISSION_3_READ,
  PERMISSION_4_READ,
  PERMISSION_4_WRITE,
  PERMISSION_5_READ,
  PERMISSION_5_WRITE,
  PERMISSION_6_READ,
  PERMISSION_6_WRITE,
  PERMISSION_7_READ,
  PERMISSION_7_WRITE,
  ROLE_1,
  ROLE_2,
  ROLE_3,
  ROLE_4,
  ROLE_5,
  ROLE_6,
  ROLE_7,
  ROLE_8,
  ROLE_9,
} from '../constants';
import { AdminRoleSortInput } from './graphql/types';

export const TEST_CATEGORIES: PermissionCategory[] = [
  {
    uuid: '1123',
    name: 'Category 1',
    description: 'Short description of the Category',
    permissionGroupTotal: 1,
    subCategories: [
      {
        uuid: '1123',
        name: 'Sub-Category 1',
        permissionGroups: [
          {
            uuid: '1123',
            name: 'Permission Group 1',
            description: 'Short description of Permission Group',
            readPermission: PERMISSION_1_READ,
            writePermission: PERMISSION_1_WRITE,
          },
        ],
      },
    ],
  },
  {
    uuid: '2234',
    name: 'Category 2',
    description:
      'Longer really cool awesome very great description of the Category',
    permissionGroupTotal: 6,
    subCategories: [
      {
        uuid: '2234',
        name: 'Sub-Category 2',
        permissionGroups: [
          {
            uuid: '2234',
            name: 'Permission Group 2',
            description:
              'Longer and very arduous description of Permission Group',
            writePermission: PERMISSION_2_WRITE,
          },
          {
            uuid: '3345',
            name: 'Permission Group 2',
            description:
              'Longer and very arduous description of Permission Group',
            readPermission: PERMISSION_3_READ,
          },
          {
            uuid: '4456',
            name: 'Permission Grouphjkkhjklhjlkhjjklh 2',
            description:
              'Longer and very arduous description of Permission Group',
            readPermission: PERMISSION_4_READ,
            writePermission: PERMISSION_4_WRITE,
          },
        ],
      },
      {
        uuid: '3345',
        name: 'Sub-Category 3',
        permissionGroups: [
          {
            uuid: '5567',
            name: 'Permission Group 3',
            description:
              'Longer and very arduous description of Permission Group',
            readPermission: PERMISSION_5_READ,
            writePermission: PERMISSION_5_WRITE,
          },
          {
            uuid: '6678',
            name: 'Permission Group 3',
            description:
              'Longer and very arduous description of Permission Group',
            readPermission: PERMISSION_6_READ,
            writePermission: PERMISSION_6_WRITE,
          },
          {
            uuid: '7789',
            name: 'Permission Grouphjkkhjklhjlkhjjklh 3',
            description:
              'Longer and very arduous description of Permission Group',
            readPermission: PERMISSION_7_READ,
            writePermission: PERMISSION_7_WRITE,
          },
        ],
      },
    ],
  },
  {
    uuid: '3234',
    name: 'Category 3',
    description:
      'Longer really cool awesome very great description of the Category',
    permissionGroupTotal: 2,
    subCategories: [
      {
        uuid: '4123',
        name: 'Sub-Category 4',
        permissionGroups: [
          {
            uuid: '5123',
            name: 'Permission Group 1',
            description: 'Short description of Permission Group',
            readPermission: PERMISSION_1_READ,
            writePermission: PERMISSION_1_WRITE,
          },
          {
            uuid: '5456',
            name: 'Permission Grouphjkkhjklhjlkhjjklh 2',
            description:
              'Longer and very arduous description of Permission Group',
            readPermission: PERMISSION_4_READ,
            writePermission: PERMISSION_4_WRITE,
          },
        ],
      },
    ],
  },
  {
    uuid: '4234',
    name: 'Category 4',
    description:
      'Longer really cool awesome very great description of the Category',
    permissionGroupTotal: 2,
    subCategories: [
      {
        uuid: '5123',
        name: 'Sub-Category 5',
        permissionGroups: [
          {
            uuid: '6123',
            name: 'Permission Group 1',
            description: 'Short description of Permission Group',
            readPermission: PERMISSION_1_READ,
            writePermission: PERMISSION_1_WRITE,
          },
          {
            uuid: '6456',
            name: 'Permission Grouphjkkhjklhjlkhjjklh 2',
            description:
              'Longer and very arduous description of Permission Group',
            readPermission: PERMISSION_4_READ,
            writePermission: PERMISSION_4_WRITE,
          },
        ],
      },
    ],
  },
  {
    uuid: '5234',
    name: 'Category 5',
    description:
      'Longer really cool awesome very great description of the Category',
    permissionGroupTotal: 2,
    subCategories: [
      {
        uuid: '6123',
        name: 'Sub-Category 6',
        permissionGroups: [
          {
            uuid: '7123',
            name: 'Permission Group 1',
            description: 'Short description of Permission Group',
            readPermission: PERMISSION_1_READ,
            writePermission: PERMISSION_1_WRITE,
          },
          {
            uuid: '7456',
            name: 'Permission Grouphjkkhjklhjlkhjjklh 2',
            description:
              'Longer and very arduous description of Permission Group',
            readPermission: PERMISSION_4_READ,
            writePermission: PERMISSION_4_WRITE,
          },
        ],
      },
    ],
  },
  {
    uuid: '6234',
    name: 'Category 6',
    description:
      'Longer really cool awesome very great description of the Category',
    permissionGroupTotal: 2,
    subCategories: [
      {
        uuid: '7123',
        name: 'Sub-Category 7',
        permissionGroups: [
          {
            uuid: '8123',
            name: 'Permission Group 1',
            description: 'Short description of Permission Group',
            readPermission: PERMISSION_1_READ,
            writePermission: PERMISSION_1_WRITE,
          },
          {
            uuid: '8456',
            name: 'Permission Grouphjkkhjklhjlkhjjklh 2',
            description:
              'Longer and very arduous description of Permission Group',
            readPermission: PERMISSION_4_READ,
            writePermission: PERMISSION_4_WRITE,
          },
        ],
      },
    ],
  },
  {
    uuid: '7234',
    name: 'Category 7',
    description:
      'Longer really cool awesome very great description of the Category',
    permissionGroupTotal: 2,
    subCategories: [
      {
        uuid: '8123',
        name: 'Sub-Category 8',
        permissionGroups: [
          {
            uuid: '9123',
            name: 'Permission Group 1',
            description: 'Short description of Permission Group',
            readPermission: PERMISSION_1_READ,
            writePermission: PERMISSION_1_WRITE,
          },
          {
            uuid: '9456',
            name: 'Permission Grouphjkkhjklhjlkhjjklh 2',
            description:
              'Longer and very arduous description of Permission Group',
            readPermission: PERMISSION_4_READ,
            writePermission: PERMISSION_4_WRITE,
          },
        ],
      },
    ],
  },
  {
    uuid: '8234',
    name: 'Category 8',
    description:
      'Longer really cool awesome very great description of the Category',
    permissionGroupTotal: 2,
    subCategories: [
      {
        uuid: '9123',
        name: 'Sub-Category 9',
        permissionGroups: [
          {
            uuid: '10123',
            name: 'Permission Group 1',
            description: 'Short description of Permission Group',
            readPermission: PERMISSION_1_READ,
            writePermission: PERMISSION_1_WRITE,
          },
          {
            uuid: '10456',
            name: 'Permission Grouphjkkhjklhjlkhjjklh 2',
            description:
              'Longer and very arduous description of Permission Group',
            readPermission: PERMISSION_4_READ,
            writePermission: PERMISSION_4_WRITE,
          },
        ],
      },
    ],
  },
  {
    uuid: '9234',
    name: 'Category 9',
    description:
      'Longer really cool awesome very great description of the Category',
    permissionGroupTotal: 2,
    subCategories: [
      {
        uuid: '10123',
        name: 'Sub-Category 10',
        permissionGroups: [
          {
            uuid: '11123',
            name: 'Permission Group 1',
            description: 'Short description of Permission Group',
            readPermission: PERMISSION_1_READ,
            writePermission: PERMISSION_1_WRITE,
          },
          {
            uuid: '11456',
            name: 'Permission Grouphjkkhjklhjlkhjjklh 2',
            description:
              'Longer and very arduous description of Permission Group',
            readPermission: PERMISSION_4_READ,
            writePermission: PERMISSION_4_WRITE,
          },
        ],
      },
    ],
  },
  {
    uuid: '10234',
    name: 'Category 10',
    description:
      'Longer really cool awesome very great description of the Category',
    permissionGroupTotal: 2,
    subCategories: [
      {
        uuid: '11123',
        name: 'Sub-Category 11',
        permissionGroups: [
          {
            uuid: '12123',
            name: 'Permission Group 1',
            description: 'Short description of Permission Group',
            readPermission: PERMISSION_1_READ,
            writePermission: PERMISSION_1_WRITE,
          },
          {
            uuid: '12456',
            name: 'Permission Grouphjkkhjklhjlkhjjklh 2',
            description:
              'Longer and very arduous description of Permission Group',
            readPermission: PERMISSION_4_READ,
            writePermission: PERMISSION_4_WRITE,
          },
        ],
      },
    ],
  },
];

export const TEST_ROLE_DATA: RoleInformationWithPermissions[] = [
  ROLE_1,
  ROLE_2,
  ROLE_3,
  ROLE_4,
  ROLE_5,
  ROLE_6,
  ROLE_7,
  ROLE_8,
  ROLE_9,
];

export const RolesHeadCells: TableRowHeader<AdminRoleSortInput>[] = [
  { id: 'name', label: 'Role name', align: 'left', sortKey: 'NAME' },
  { id: 'type', label: 'Role type', align: 'right', sortKey: 'ACCESS_TYPE' },
  {
    id: 'date',
    label: 'Date created',
    align: 'right',
    sortKey: 'CREATED_DATE',
  },
  {
    id: 'permissionCount',
    label: 'Permissions',
    align: 'center',
    sortKey: 'PERMISSION_COUNT',
  },
  {
    id: 'adminCount',
    label: 'Admins',
    align: 'center',
    sortKey: 'ADMIN_COUNT',
  },
  {
    id: 'clientCount',
    label: 'Clients',
    align: 'center',
    sortKey: 'CLIENT_COUNT',
  },
  { id: 'blank', label: '', align: 'center' },
];
export const tableRows: TableRows[] = [
  {
    key: 'name',
    test: 'name',
    align: 'left',
    customComponent: function nameLabel({
      name,
      role,
    }: {
      name: string;
      role: RoleInformation;
    }) {
      return (
        <NameGap>
          {name || ''}
          {role.description && (
            <Tooltip title={role.description} placement="top">
              <InformationCircleGray />
            </Tooltip>
          )}
        </NameGap>
      );
    },
  },
  {
    key: 'roleType',
    test: 'role-type',
    align: 'right',
    customComponent: function roleTypeLabel({
      role,
    }: {
      role: RoleInformation;
    }) {
      const roleType = roleTypeValues[role.accessType];
      return (
        <Tag
          data-test={`adminRoles:${role.uuid}:role-type`}
          variant={roleType.variant || 'warning'}
        >
          {roleType.displayName || ''}
        </Tag>
      );
    },
  },
  {
    key: 'createdDate',
    test: 'created-date',
    align: 'right',
    customComponent: function clientCountLabel({
      role,
    }: {
      role: RoleInformation;
    }) {
      return (
        <Typography data-test={`adminRoles:${role.uuid}:created-date`}>
          {formatDate(role.createdTsz)}
        </Typography>
      );
    },
  },
  {
    key: 'permissionCount',
    test: 'permission-count',
    align: 'center',
    customComponent: function permissionCountLabel({
      role,
      permissionCount,
    }: {
      role: RoleInformation;
      permissionCount: number;
    }) {
      return (
        <Typography data-test={`adminRoles:${role.uuid}:permission-count`}>
          {permissionCount}
        </Typography>
      );
    },
  },
  {
    key: 'adminCount',
    test: 'admin-count',
    align: 'center',
    customComponent: function adminCountLabel({
      role,
      adminCount,
    }: {
      role: RoleInformation;
      adminCount: number;
    }) {
      return (
        <Typography data-test={`adminRoles:${role.uuid}:admin-count`}>
          {adminCount}
        </Typography>
      );
    },
  },
  {
    key: 'clientCount',
    test: 'client-count',
    align: 'center',
    customComponent: function clientCountLabel({
      role,
      clientCount,
    }: {
      role: RoleInformation;
      clientCount: number;
    }) {
      return (
        <Typography data-test={`adminRoles:${role.uuid}:client-count`}>
          {role.accessType === 'GLOBAL' ? '-' : clientCount}
        </Typography>
      );
    },
  },
];
