import React, { useState } from "react";
import { TextField, Button, Switch, Divider, Menu, MenuItem } from "@octanner/prism-core";
import { useTranslation } from 'react-i18next';
import {
  ListItemContainer, SwitchContainer, SwitchLabel, TextFieldContainer, SaveContainer,
  SaveButton, StyledMenuDots, StyledPencil, StyledTrash, EditModeContainer, DisplayModeContainer
} from './style';
import { useMutation } from '@apollo/client';
import { UPDATE_CORE_VALUE, DELETE_CORE_VALUE, CHANGE_STATUS_OF_CORE_VALUE, CREATE_STRING_ID } from './query';
import { customerId } from '../../utilities/utility';
import ConfirmationModal from './ConfirmatioModal';


const CoreValue = ({
  index,
  item,
  setCoreValuesList,
  setAlertData,
  CoreValuesList,
  refetchAllCoreValues,
  onPermissionError
}) => {
    const { t } = useTranslation();
    const [updateCoreValue , {loading:updateCoreValueLoading}] = useMutation(UPDATE_CORE_VALUE);
    const [deleteCoreValue, { loading: deleteCoreValueLoading }] = useMutation(DELETE_CORE_VALUE);
    const [updateCoreValueStatus , {loading:updateCoreValueStatusLoading}] = useMutation(CHANGE_STATUS_OF_CORE_VALUE);
    const [createStringId , {loading:createStringIdLoading}] = useMutation(CREATE_STRING_ID);
    const [currentEditCoreValue, setCurrentEditCoreValue] = useState('');
    const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
    const [updateCoreValueError, setUpdateCoreValueError] = useState(false);
    const [modalEntity, setModalEntity] = useState({
      desc : '',
      confirmAction : () => {},
      color : '',
      loading : false,
      actionText : 'Delete'
    });

    const [edit, setEdit] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const handleUpdateCoreValueName = () => {
        const index = CoreValuesList.findIndex((item1) => item1!==item && item1.name === currentEditCoreValue);
        if(index !== -1){
          setUpdateCoreValueError(true);
          return;
        }
        createStringId({
          variables : {
            input : {
              languageStringText : currentEditCoreValue,
              languageStringNotes : null,
              enableVoiceDescriptionInd : false
            }
          }
        }).then((data)=>{
          const { stringId } = data.data.lsCreateLanguageStringInUSEnglishLocale.languageString;
          updateCoreValue({
            variables : {
              input : {
                customerUuid: customerId,
                customerCoreValueId: item.id,
                coreValueName: currentEditCoreValue,
                languageStringId: stringId
              }
            }
          }).then((data) => {
            refetchAllCoreValues().then(() => {
              const { coreValueName } = data.data.updateCustomerCoreValue
              item.name = coreValueName;
              setEdit(false);
              setCoreValuesList([...CoreValuesList]);        
              setCurrentEditCoreValue('');
              setUpdateCoreValueError(false);
              setAlertData({
                name: t('core_value_updated_sucessfully',{ name: currentEditCoreValue}),
                severity: 'success',
              });
            });
          }).catch((error) => {
            onPermissionError(error?.message);
          })
        })

      };

      const handleCancelUpdateCoreValueName = () => {
        setEdit(false);
        setUpdateCoreValueError(false);
        setCoreValuesList([...CoreValuesList]);
      };

      const handleStatusChange = (status) => {
        setLoading(true);
        updateCoreValueStatus(
            {
                variables : {
                    input : {
                        customerUuid : customerId,
                        customerCoreValueId : [item.id],
                        status: status
                    }
                }
            }
        ).then(()=>{
            refetchAllCoreValues().then(() => {
              item.isActive = status==="active";
              setCoreValuesList([...CoreValuesList]);
              setAlertData({
                  name: t('core_value_status_updated',{ status : status==='active' ? 'activated' : 'deactivated', name : item.name}),
                  severity:'success',
              });
              setLoading(false);
              setOpenConfirmationModal(false);
            });
          }).catch((error)=>{
            if(error?.message?.includes('OCT Permission not match')) {
              onPermissionError(error?.message);
            }
            else{
            setAlertData({
                name: `An error occurred when trying to ${status==='active' ? 'activate' : 'deactivate'} the core value. Please try again.`,
                severity:'error',
            })};
            setOpenConfirmationModal(false);
          })

    };

      const handleSwitchChange = () => {
        item.isActive ? setModalEntity({
          desc : t('deactivate_confirm'),
          confirmAction : () => handleStatusChange('inactive'),
          color : 'error',
          loading : updateCoreValueStatusLoading,
          actionText : t('deactivate')
        }) : setModalEntity({
          desc : t('activate_confirm'),
          confirmAction : () => handleStatusChange('active'),
          color : 'primary',
          loading : updateCoreValueStatusLoading,
          actionText : t('activate')
        })
        setOpenConfirmationModal(true);
      };
      const handleMenuOpen = (index, event) => {
        const updatedCoreValuesList = [...CoreValuesList];
        updatedCoreValuesList[index].anchorEls = event.currentTarget;
        setCoreValuesList(updatedCoreValuesList);
    
    
  };

      const handleDelete = () => {
        setLoading(true);
        deleteCoreValue({
          variables: {
              input: {
                  customerUuid: customerId,
                  customerCoreValueId: item.id
              }
          }
      }).then(() => {
          refetchAllCoreValues().then(() => {
            let tempCoreValues = CoreValuesList.filter((item1)=>{
              return item1.id !== item.id
          })
          setCoreValuesList(tempCoreValues);              
          setAlertData({
              name: t('removed_core_value',{ name : item.name}),
              severity: 'success',
          });
          setLoading(false);
          setOpenConfirmationModal(false);
          });
      }).catch((error) => {
        if(error?.message?.includes('OCT Permission not match')){
          onPermissionError(error?.message);
        }
        else{
          setAlertData({
              name: t('remove_core_value_error'),
              severity: 'error'
          })};
          setOpenConfirmationModal(false);
      })
      };


      const handleDeleteCoreValue = () => {
        setModalEntity({
          desc : t('remove_core_value'),
          confirmAction : handleDelete,
          color : 'error',
          loading : deleteCoreValueLoading,
          actionText : 'Delete'
        })
        setOpenConfirmationModal(true);
       
      }

      const handleEditMode = (name) => {
        setEdit(true);
        setCurrentEditCoreValue(name);
        const index = CoreValuesList.findIndex((item) => item.name === name);
        if(index!= -1)
        {
        const updatedCoreValuesList = [...CoreValuesList];
        updatedCoreValuesList[index].anchorEls = null;
        setCoreValuesList(updatedCoreValuesList);
      }
    };  
    return (
      <>
      <ConfirmationModal
      data-testid="test-confirmation-modal"
        openConfirmationModal={openConfirmationModal}
        modalEntity={modalEntity}
        setOpenConfirmationModal={setOpenConfirmationModal}
        sumbitLoading={loading}
      />
      <div id={`List-${index}`}>
          <ListItemContainer>
              <SwitchContainer>

                  {!edit ? (
                <DisplayModeContainer>
                  <div style={{ marginTop: "8px" }}>
                    <Switch
                      data-testid='test-display-active'
                      checked={item.isActive}
                      color="primary"
                      onChange={() => handleSwitchChange()}
                    />
                  </div>
                  <SwitchLabel data-testid="switch-name"variant="h4">{item.name}</SwitchLabel>
                </DisplayModeContainer>
                   
                    ) : (
                    <EditModeContainer>                     
                        <Switch
                          data-testid="test-active"
                          checked={item.isActive}
                          color="primary"
                          onChange={() => handleSwitchChange()}
                        />                    
                        <TextFieldContainer>
                          <TextField 
                           inputProps={{ "data-testid": "test-name-value" }}
                            label={t('name_of_value')}
                            fullWidth
                            disabled={updateCoreValueLoading || createStringIdLoading}
                            value={currentEditCoreValue}
                            onChange={(e) => {
                              setCurrentEditCoreValue(e.target.value);
                              setUpdateCoreValueError(false);
                            }}
                            error={updateCoreValueError}
                          />
                        </TextFieldContainer>
                          <SaveContainer>
                            <SaveButton
                              disabled={updateCoreValueLoading}
                              loading={updateCoreValueLoading || createStringIdLoading}
                              onClick={() => handleUpdateCoreValueName()}
                              adminColor
                              data-testid="save-button"
                            >{t('save')}</SaveButton>
                            <Button
                              data-testid="cancel"
                              color="secondary"
                              onClick={() => handleCancelUpdateCoreValueName()}
                            >
                              {t('cancel')}
                            </Button>
                          </SaveContainer>
                      </EditModeContainer>
                    )}
                </SwitchContainer>
                <StyledMenuDots onClick={(event) => { handleMenuOpen(index, event) }} data-testid='test-menu-dots'/>
                <Menu
                    data-testid='test-menu'
                    anchorEl={item.anchorEls}
                    open={Boolean(item.anchorEls)}
                    id={`simple-menu-${index}`}
                    keepMounted
                    onClose={() => {
                        item.anchorEls = null;
                        setCoreValuesList([...CoreValuesList]);
                    }}
                >
                    <MenuItem onClick={() => handleEditMode(item.name)} data-testid="edit-button">
                        <StyledPencil />{t('edit')}
                    </MenuItem>
                    <MenuItem onClick={() => handleDeleteCoreValue()} data-testid="test-delete">
                        <StyledTrash />{t('remove')}
                    </MenuItem>
              </Menu>
          </ListItemContainer>
          <Divider />
      </div>
        </>
    );
};


export default CoreValue;
