import styled from '@emotion/styled';
import { Button, Table, Typography } from '@octanner/prism-core';
import { InformationCircle } from '@octanner/prism-icons';
import colors from '../../../../common/constants/Colors';
import { tannerGray } from '@octanner/prism-core/ThemeProvider/colors';

export const AdminRoleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 744px) {
    /* width: 100%;
    flex-wrap: wrap; */
  }
`;
export const AlertContainer = styled.div`
  margin-bottom: 24px;
`;
export const BoxMTB = styled.div`
  margin-top: 12px;
  margin-bottom: 80px;
`;
export const InformationCircleGray = styled(InformationCircle)`
  color: ${colors.gray400};
`;
export const NameGap = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
export const AdminRolesContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
export const NoAdminRolesContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: ${tannerGray[50]};
  padding: 32px;
  flex-direction: column;
  margin-top: 16px;
  gap: 3;
`;
export const NoAdminRolesButton = styled(Button)`
  margin-top: 32px;
`;
export const CopyAdminRoleButton = styled(Button)`
  margin-right: 16px;
`;
export const NoPermCheckbox = styled.div`
  width: 36px;
  height: 36px;
`;
export const PaginationContainer = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  margin-top: 12px;
`;
export const TableMT = styled(Table)`
  margin-top: 24px;
`;
export const TypographyPZero = styled(Typography)`
  padding: 0;
`;
export const TypographyMB = styled(Typography)`
  margin-bottom: 24px;
`;
export const AddRoleButton = styled(Button)`
  margin: 8px 0;
`;
export const DAButtonContainer = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
`;
