export const validLength = {
  addressLine1: 35,
  addressLine2: 35,
  addressLine3: 35,
  city: 35,
  postalCode: 10,
  primaryContactName: 35,
  emailAddress: 130,
  faxNumber: 31,
  phoneNumber: 16,
};

export const requiredKeys = [
  'salesTerritoryErr',
  'countryErr',
  'addressLine1Err',
  'cityErr',
  'stateErr',
  'primaryContactNameErr'
];
