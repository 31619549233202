import {
  TextField,
  Typography,
  Switch,
  Tag,
  Divider,
} from '@octanner/prism-core';
import { ClientDetailProps } from './types';
import { useStyles } from './styles';
import { format } from 'date-fns';

import { regions } from '../global/constants';

const ClientDetail = ({
  label,
  value,
  isEditable = false,
  isEditing,
  onChange,
  dataTest,
  trimmedLabel,
}: ClientDetailProps) => {
  const classes = useStyles();
  type CustomColor = 'warning' | 'info' | 'success' | 'archived';
  const check = value === 'true';
  const labelConstants = [
    'clientname',
    'clientstatus',
    'clientusagetype',
    'clientlaunchdate',
    'socialwall',
    'piidataregion',
  ];
  const getTagData = (): { variant: CustomColor; value: string } => {
    const tagMappings = {
      sold: { variant: 'warning', value: 'Sold' },
      impl: { variant: 'info', value: 'Implementation' },
      active: { variant: 'success', value: 'Active' },
      lost: { variant: 'archived', value: 'Lost' },
    };
    return tagMappings[value];
  };
  const displayContent = () => {
    switch (trimmedLabel) {
      case labelConstants[1]:
        const tagData = getTagData();
        return <Tag variant={tagData.variant}>{tagData.value}</Tag>;
      case labelConstants[2]:
        return (
          <Typography>
            {value.charAt(0).toUpperCase() + value.slice(1)}
          </Typography>
        );
      case labelConstants[3]:
        return value ? (
          <Typography>{format(new Date(value), 'dd MMMM yyyy')}</Typography>
        ) : (
          <Typography color="textSecondary">N/A</Typography>
        );
      default:
        return <Typography data-test={dataTest}>{displayValue()}</Typography>;
    }
  };
  if (isEditing) {
    if (
      label?.trim()?.toLowerCase()?.replace(/\s+/g, '') === labelConstants[4]
    ) {
      return (
        <>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant="h4" style={{ marginRight: 8 }}>
              {label}
            </Typography>
            <Switch
              aria-label={label}
              checked={check}
              onChange={(e) => onChange && onChange(check.toString())}
              value={check}
            />
          </div>
          <Typography>
            If the social wall is turned off, the social feed will only show a
            user's personal recognition.
          </Typography>
        </>
      );
    }
    return (
      <TextField
        id={dataTest}
        fullWidth
        label={label}
        placeholder="Role name"
        value={value}
        onChange={(e) => onChange && onChange(e.target.value)}
        disabled={!isEditable}
        inputProps={{ 'data-test': dataTest }}
      />
    );
  }
  const displayValue = () => {
    if (
      label?.trim()?.toLowerCase()?.replace(/\s+/g, '') === labelConstants[4]
    ) {
      return value === 'true'
        ? 'On (showing all recognition)'
        : 'Off (showing personal recognition only)';
    } else if (
      label?.trim()?.toLowerCase()?.replace(/\s+/g, '') === labelConstants[5]
    ) {
      return regions.find((region) => region.value === value)?.name;
    } else {
      return value;
    }
  };

  return (
    <div className={classes.clientDetailRootMain}>
      <div className={classes.clientDetailRoot}>
        <div className={classes.label}>
          <Typography color="textSecondary">{label}</Typography>
        </div>
        <div>{displayContent()}</div>
      </div>
      <Divider className={classes.divider} />
    </div>
  );
};

export default ClientDetail;
