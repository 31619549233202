import { createMyPlugins } from './plateTypes';
import { basicElementsPlugins } from './plugins/basicElementPlugins';
import { basicMarksPlugins } from './plugins/basicMarkPlugins';
import { myLinkPlugin } from './plugins/linkPlugin';
import { listPlugins } from './plugins/listPlugins';
import { plateUI } from './plugins/plateUI';
import { tablePlugins } from './plugins/tablePlugin';

const plugins = createMyPlugins(
  [
    ...basicElementsPlugins,
    ...basicMarksPlugins,
    ...listPlugins,
    ...myLinkPlugin,
    ...tablePlugins,
  ],
  {
    components: plateUI,
  }
);

export default plugins;
