import ConfirmModal from './ConfirmModal';
import { BaseModalProps } from '../types';

interface ModalProps extends BaseModalProps {
  onCancel: () => void;
}

const DeleteAdminModal = (modalProps: ModalProps) => {
  return (
    <ConfirmModal
      {...modalProps}
      title="Delete admin"
      message="Are you sure you want to delete this admin? This can’t be undone."
      confirmText="Delete"
      color="error"
    />
  );
};

export default DeleteAdminModal;
