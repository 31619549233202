import { useMutation } from '@apollo/client';
import styled from '@emotion/styled';
import { Chip, Grid, MenuItem, Select, TextField, Typography } from '@octanner/prism-core';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CustomTextContext } from '../../../Contexts/CustomTextContext';
import { LocaleContext } from '../../../Contexts/LocaleContext';
import { updateSuccessMessage } from '../../../utils/commonActions';
import { AddCustomTextBy, emailregex } from '../../../utils/constants';
import { defaults } from '../../../utils/default';
import { INSERT_CUSTOM_TEXT } from '../../../utils/query';
import StyledTextField from '../../../utils/styledComponents';
import { CreateCustomTextResponse, CustomTextInput, Locale } from '../../../utils/types';
import Footer from '../../Common/Footer';
import SelectLanguage from '../../Common/SelectLanguage';
import CustomTextBulkUpload from './CustomTextBulkUpload';
import CustomTextInfo from './CustomTextInfo';

const SelectLanguageContainer = styled.div`
  margin-top: 24px;
`;

const StringInfoContainer = styled.div`
  margin-top: 24px;
`;

const LocaleInfoContainer = styled.div`
  margin-bottom: 10px;
  margin-top: 36px;
`;

const Control = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
`;

const LocaleControl = styled.div`
  margin-bottom: 10px;
`;

const LanguageChip = styled(Chip)`
  margin-top: 12px;
  margin-right: 10px;
`;


const AddCustomText = ({ setUploadSuccess, setUploadError, setSampleDownloadError }) => {

  const { addCustomTextBy, setMessageText, setCustomTextUniqueId, fetch,
    customerEmail, customerName
  } = useContext(CustomTextContext);

  const history = useHistory();

  const { localeLoading, locales } = useContext(LocaleContext);
  const [selectedLocales, setSelectedLocales] = useState<Locale[]>([]);
  const [createLoading, setCreateLoading] = useState<boolean>(false);
  const [programId, setProgramId] = useState<string>('');
  const [requesterName, setRequesterName] = useState<string>(customerName);
  const [requesterEmail, setRequesterEmail] = useState<string>(customerEmail);
  const [soldToPartyNumber, setSoldToPartyNumber] = useState<string>('');
  const [customTextNotes, setCustomTextNotes] = useState<string>('');
  const [briefIdentifierText, setBriefIdentifierText] = useState<string>('');
  const [customTextToTranslate, setCustomTextToTranslate] = useState<string>('');
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [sourceLocale, setSourceLocale] = useState<string>('');
  const [existingLocales, setExistingLocales] = useState<Locale[]>([]);

  const [createCustomText] = useMutation(INSERT_CUSTOM_TEXT,
    {
      onCompleted: (response: CreateCustomTextResponse) => {
        if (response?.lsCreateCustomText) {
          setCustomTextUniqueId(response.lsCreateCustomText.customTextRequestUniqueId);
          setMessageText('editSuccessMessage', '');
          setMessageText('createSuccessMessage', defaults.custom_text_creation_success);
          setMessageText('errorMessage', '');
          updateSuccessMessage(selectedLocales, defaults, setMessageText);
          setCreateLoading(false);
          resetValues();
          history.push(`/custom-text/edit/${response.lsCreateCustomText.customTextStringIdList[0]}?type=edit`);
        }
      },
      onError: () => {
        setMessageText('errorMessage', defaults.add_error);
        setCreateLoading(false);
      }
    });

  useEffect(() => {
    setExistingLocales([...locales]);
  }, [locales]);

  useEffect(() => {
    resetValues();
  }, [addCustomTextBy])

  const onRemoveLocale = (locale: Locale): void => {
    setSelectedLocales((previousLocales: Locale[]) =>
      previousLocales.filter((previousLocale: Locale) => previousLocale.code !== locale.code)
    );
  }

  const resetValues = (): void => {
    setProgramId('');
    setRequesterName(customerName);
    setRequesterEmail(customerEmail);
    setSoldToPartyNumber('');
    setSourceLocale('');
    setCustomTextNotes('');
    setSelectedLocales([]);
    setSelectedFile(null);
    setBriefIdentifierText('');
    setCustomTextToTranslate('');
  }

  const handleSelectedFile = (fileSelected: File | null): void => {
    setSelectedFile(fileSelected);
  }

  const createCustomTextBulkUpload: () => Promise<void> = async () => {
    setCreateLoading(true);
    handleControlChange();
    const formData = new FormData();
    formData.append('file', selectedFile);
    formData.append('localeList', selectedLocales.map((locale: Locale) => locale.code).toString());
    formData.append('sourceLocale', sourceLocale);
    formData.append('soldToParty', soldToPartyNumber);
    formData.append('requesterName', requesterName);
    formData.append('requesterEmail', requesterEmail);
    formData.append('customTextNotes', customTextNotes);
    formData.append('programId', programId.toString());
    try {
      const response = await fetch('/api/language/bulk-upload/custom-text', {
        method: 'POST',
        body: formData,
      });
      if (response.ok) {
        setUploadSuccess(true);
        setSelectedFile(null);
        setUploadError(false);
        setProgramId('');
        setRequesterName(customerName);
        setRequesterEmail(customerEmail);
        setSoldToPartyNumber('');
        setSourceLocale('');
        setCustomTextNotes('');
        setSelectedLocales([]);
      } else {
        setUploadError(true);
      }
      setCreateLoading(false);
    } catch (error) {
      setCreateLoading(false);
      setUploadError(true);
    }
  }

  const handleCreateClick = (): void => {
    if (addCustomTextBy === AddCustomTextBy.BULK_UPLOAD) {
      createCustomTextBulkUpload();
      return;
    }
    setCreateLoading(true);
    const localeList = selectedLocales.map((item: Locale) => item.code);
    const customTextInput = new CustomTextInput();
    customTextInput.briefIdentifierText = briefIdentifierText;
    customTextInput.customTextToTranslate = customTextToTranslate;
    const customTextList: CustomTextInput[] = [customTextInput];
    createCustomText({
      variables: {
        input: {
          sourceLocale: sourceLocale,
          requesterName,
          requesterEmail,
          soldToPartyNumber,
          programId: parseInt(programId),
          customTextNotes,
          customTextList,
          localeList
        }
      }
    })
  };

  const handleControlChange = (): void => {
    setUploadSuccess(false);
    setUploadError(false);
    setSampleDownloadError(false);
  };

  const onLocaleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const selectedLocaleCode = event.target.value;
    const existingLocales = [...locales];
    const filteredLocales = existingLocales.filter(locale => locale.code !== selectedLocaleCode);
    setExistingLocales(filteredLocales);
    setSourceLocale(selectedLocaleCode);
  };

  const handleBriefIdentifierTextChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    setBriefIdentifierText(event.target.value);
  }

  const handleCustomTextToTranslateChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    setCustomTextToTranslate(event.target.value);
  }

  return (
    <>
      <StringInfoContainer>
        <Typography variant='h3'>
          {defaults.custom_text_information}
        </Typography>
        <Typography color='textSecondary'>
          {defaults.custom_text_info}
        </Typography>
      </StringInfoContainer>
      <Control>
        <CustomTextInfo
          programId={programId}
          onProgramIdChange={(value: string) => setProgramId(value)}
          onRequesterNameChange={(value: string) => setRequesterName(value)}
          requesterName={requesterName}
          onRequesterEmailChange={(value: string) => setRequesterEmail(value)}
          requesterEmail={requesterEmail}
          onSoldToPartyNumberChange={(value: string) => setSoldToPartyNumber(value)}
          soldToPartyNumber={soldToPartyNumber}
        />
        <LocaleControl>
          {!localeLoading && <LocaleInfoContainer>
            <Typography variant='h3'>
              {defaults.source_language}
            </Typography>
            <Typography color='textSecondary'>
              {defaults.select_source_language_description}
            </Typography>
          </LocaleInfoContainer>}
          <Grid container spacing={4}>
            {locales?.length > 0 && <Grid item xs={5}>
              <Select
                label={defaults.select_source_locale}
                fullWidth
                helperText={defaults.select_language_desc}
                onChange={onLocaleChange}
                value={locales.some(locale => locale.code === sourceLocale) ? sourceLocale : ''}>
                {locales.map((locale: Locale) => (
                  <MenuItem key={locale.code} value={locale.code}>
                    {locale.languageDescription}
                  </MenuItem>
                ))}
              </Select>
            </Grid>}

            {addCustomTextBy === AddCustomTextBy.CUSTOM_TEXT &&
              <Grid item xs={5}>
                <TextField
                  value={briefIdentifierText}
                  label={defaults.brief_identifier_text}
                  displayCount={true}
                  maxLength={50}
                  onChange={handleBriefIdentifierTextChange}
                  fullWidth
                  countProps={{ className: 'display-count-class' }}
                />
              </Grid>}
          </Grid>

          {addCustomTextBy === AddCustomTextBy.BULK_UPLOAD &&
            <CustomTextBulkUpload
              onFileChange={handleSelectedFile}
              selectedFile={selectedFile}
              setSampleDownloadError={setSampleDownloadError}
              setSelectedFile={setSelectedFile}></CustomTextBulkUpload>
          }
        </LocaleControl>

        {addCustomTextBy === AddCustomTextBy.CUSTOM_TEXT &&
          <Grid container spacing={4}>
            <Grid item xs={10}>
              <Control>
                <StyledTextField
                  label={defaults.text_to_translate}
                  placeholder={defaults.enter_source_custom_text}
                  fullWidth
                  value={customTextToTranslate}
                  onChange={handleCustomTextToTranslateChange}
                  multiline
                  displayCount={true}
                  rows={4}
                  maxLength={4000}
                  countProps={{ className: 'display-count-class' }}
                  required
                />
              </Control>
            </Grid>
          </Grid>
        }
      </Control>
      <Grid container spacing={1}>
        <Grid item xs={5}>
          {!localeLoading && <SelectLanguageContainer>
            <Typography variant='h3'>
              {defaults.select_translations_languages}
            </Typography>
            <Typography color='textSecondary'>
              {defaults.select_languages_description}
            </Typography>
            <Control>
              <SelectLanguage locales={existingLocales} selectedLocales={selectedLocales} setSelectedLocales={setSelectedLocales} optionalInd={true}></SelectLanguage></Control>
          </SelectLanguageContainer>}
        </Grid>
      </Grid>
      {!!(selectedLocales?.length) &&
        selectedLocales.map((locale: Locale) => (
          <LanguageChip key={locale.code} label={locale.languageDescription} onDelete={() => onRemoveLocale(locale)}></LanguageChip>
        ))
      }
      <Control>
        <Grid container spacing={4}>
          <Grid item xs={10}>
            <StyledTextField
              label={defaults.notes}
              placeholder={defaults.enter_notes}
              fullWidth
              displayCount={true}
              maxLength={4000}
              value={customTextNotes}
              onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setCustomTextNotes(e.target.value.slice(0, 4000))}
              multiline
              helperText={defaults.optional}
              rows={4}
              inputProps={{
                maxLength: 4000
              }}
              countProps={{ className: 'display-count-class' }}
              required
            />
          </Grid>
        </Grid>
      </Control>
      <Footer
        cancelActionText={defaults.cancel}
        saveActionText={defaults.create_custom_text}
        cancelAction={() => resetValues()}
        loading={createLoading}
        saveAction={() => handleCreateClick()} saveButtonDisabled={(!requesterEmail || !emailregex.test(requesterEmail) || customTextToTranslate?.trim().length > 4000
          || briefIdentifierText?.trim().length > 50)
          || (!requesterName || !requesterName.length || !sourceLocale) || (addCustomTextBy === AddCustomTextBy.BULK_UPLOAD && !selectedFile) ||
          (addCustomTextBy === AddCustomTextBy.CUSTOM_TEXT && (!briefIdentifierText || !customTextToTranslate)) || customTextNotes?.trim()?.length > 4000} />
    </>
  );
};

export default AddCustomText;