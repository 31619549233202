import xcaAuthorizer from '../../utils/auth/XcaAuthorizer';
import CurrentMigration from './CurrentMigration';
import MigrationsPage from './MigrationsPage';

export const MigrationRoute = [
  {
    authorizer: xcaAuthorizer,
    path: '/:coreCustomerUuid',
    component: CurrentMigration,
    exact: true,
  },
  {
    authorizer: xcaAuthorizer,
    path: '/',
    component: MigrationsPage,
    exact: true,
  },
];
