import { useQuery } from '@apollo/client';
import { SEARCH_BUSINESS_UNITS } from './graphql';
import {
  SearchBusinessUnitsInput,
  SearchBusinessUnitsResponse,
} from './models';

export const useSearchBusinessUnits = (input: {
  customerId: string;
  searchContent: string;
}) => {
  const buQuery = useQuery<
    SearchBusinessUnitsResponse,
    SearchBusinessUnitsInput
  >(SEARCH_BUSINESS_UNITS, {
    variables: { input },
    skip: !input.searchContent,
  });
  return {
    ...buQuery,
    data: buQuery.data?.searchBusinessUnits.businessUnits || [],
  };
};
