import { AdapterLuxon, Button, DatePicker } from '@octanner/prism-core';
import { DateTime } from 'luxon';
import { FixedDateProps } from '../../modifyBank/types';
import { DatePickerContainer, DatesContainer } from './styles';
const FixedDate = ({
  type,
  fixedData,
  index,
  handleDatePickerChange,
  handleRemoveFixedDate,
  fixedDates,
}: FixedDateProps) => {
  const nextStartDate =
    index < fixedDates.length - 1
      ? DateTime.fromISO(fixedDates[index + 1]?.startDateTsz) || null
      : null;

  const previousEndDate =
    index > 0
      ? DateTime.fromISO(fixedDates[index - 1]?.endDateTsz) || null
      : null;
  const endDateTime = fixedData?.endDateTsz
    ? DateTime.fromISO(fixedData.endDateTsz)
    : null;
  const startDateTime = fixedData?.startDateTsz
    ? DateTime.fromISO(fixedData.startDateTsz)
    : null;
  const expirationDate = fixedData?.expirationDateTsz
    ? DateTime.fromISO(fixedData.expirationDateTsz)
    : null;

  const currentDate = DateTime.local();
  const todaysDate = currentDate.startOf('day');

  return (
    <div>
      <DatesContainer>
        <DatePickerContainer>
          <DatePicker
            disableMaskedInput
            label="Start Date"
            value={fixedData?.startDateTsz || null}
            dateAdapter={AdapterLuxon}
            onChange={(date: DateTime | null) =>
              handleDatePickerChange('startDateTsz', date, index)
            }
            maxDate={
              endDateTime && endDateTime.isValid
                ? endDateTime
                : nextStartDate || undefined
            }
            minDate={
              type === 'add'
                ? previousEndDate || todaysDate || undefined
                : previousEndDate || startDateTime || todaysDate || undefined
            }
          />
        </DatePickerContainer>
        <DatePickerContainer>
          <DatePicker
            disableMaskedInput
            label="End Date"
            value={fixedData?.endDateTsz || null}
            dateAdapter={AdapterLuxon}
            onChange={(date: DateTime | null) =>
              handleDatePickerChange('endDateTsz', date, index)
            }
            maxDate={
              expirationDate && expirationDate.isValid
                ? expirationDate
                : nextStartDate || undefined
            }
            minDate={
              startDateTime || previousEndDate || todaysDate || undefined
            }
          />
        </DatePickerContainer>
        <DatePickerContainer>
          <DatePicker
            disableMaskedInput
            label="Expiration Date"
            value={fixedData?.expirationDateTsz || null}
            dateAdapter={AdapterLuxon}
            onChange={(date: DateTime | null) =>
              handleDatePickerChange('expirationDateTsz', date, index)
            }
            minDate={
              endDateTime
                ? endDateTime || previousEndDate || todaysDate
                : DateTime.local(9999, 12, 31)
            }
          />
        </DatePickerContainer>

        <Button variant="text" onClick={() => handleRemoveFixedDate(index)}>
          Remove Date
        </Button>
      </DatesContainer>
    </div>
  );
};

export default FixedDate;
