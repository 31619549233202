import { createContext, useEffect, useState } from 'react';
import {
  CustomerBase,
  CustomerRegion,
  CustomerWithFeatures,
  SocialConfigurationItems,
  SocialFeedSearchView,
} from '../../pages/customer/models/Customer';
import { authFetch } from '../auth/Auth';
import { useCurrentIdentity } from '../../pages/user-auth/hooks/identity';

import {
  CustomerContextData,
  CustomerContextProps,
} from '../../pages/customer/types';

const defaultCustomer: CustomerWithFeatures['customer'] = {
  id: 'default',
  name: 'default',
  region: CustomerRegion.usa,
  modifiedBy: 'default',
  modifiedOn: 'default',
  customerStatus: 'sold',
  customerUsage: 'test',
  launchedAtTsz: 'default',
  settings: {
    emailEditingDisabledTsz: '',
  },
};

const defaultSocialItems: SocialConfigurationItems = {
  displayComments: false,
  displayLikes: false,
  displayAwardLevel: false,
  // displayBusinessUnit: false,
  displayFollowingTab: false,
  viewType: SocialFeedSearchView.CORPORATE,
  personalFeedOverride: false,
  // productFilters: [],
};

const defaultSocial: CustomerWithFeatures['social'] = {
  socialConfig: defaultSocialItems,
};

const Context = createContext<CustomerContextData>({
  customer: defaultCustomer,
  welcomeMessage: { enabled: false },
  features: [],
  social: defaultSocial,
  identitySearch: {
    totalRecordsCount: 0,
  },
  setCustomer: () => {},
  unset: () => {},
  isDefault: true,
  recent: [],
  setRecent: () => {},
  currentImpersonateClient: null,
  setCurrentImpersonateClient: () => {},
});

const { Provider, Consumer } = Context;

export default function CustomerContextComponent({
  children,
}: CustomerContextProps) {
  const [initialized, setInitialized] = useState(false);
  const [recent, setRecent] = useState<CustomerBase[]>([]);
  const [customer, setCustomer] =
    useState<CustomerWithFeatures['customer']>(defaultCustomer);
  const [social, setSocial] =
    useState<CustomerWithFeatures['social']>(defaultSocial);
  const [features, setFeatures] = useState<
    CustomerWithFeatures['features']['nodes']
  >([]);
  const [identitySearch, setIdentitySearch] = useState<
    CustomerWithFeatures['identitySearch']
  >({ totalRecordsCount: 0 });
  const [isDefault, setIsDefault] = useState<boolean>(true);
  const [welcomeMessage, setWelcomeMessage] = useState<{ enabled: boolean }>({
    enabled: false,
  });
  const [currentImpersonateClient, setCurrentImpersonateClient] =
    useState<CustomerBase | null>(null);
  const { data: currentIdentityData } = useCurrentIdentity();
  const { PUBLIC_URL: publicUrl } = process.env;

  useEffect(() => {
    if (initialized) return;
    authFetch(
      `${publicUrl}/recent/clients/${currentIdentityData?.currentIdentity?.id}`
    )
      .then((res) => {
        if (res.ok) return res.json();
        throw res;
      })
      .then((data) => {
        const storage = JSON.parse(data.value);
        const recent = storage || [];
        setRecent(recent);
        setInitialized(true);
        setCurrentImpersonateClient(null);
      })
      .catch((err) => {
        console.log(err);
        const recent = [];
        setRecent(recent);
        setInitialized(true);
        setCurrentImpersonateClient(null);
      });
    //eslint-disable-next-line
  }, [initialized]);

  const handleSetCustomer = (data: CustomerWithFeatures) => {
    const { customer, social, features, identitySearch, welcomeMessage } = data;
    const base = {
      id: customer.id,
      name: customer.name,
      stpNumber: customer.stpNumber,
      customerStatus: customer.customerStatus,
    };
    const updatedRecent = [...recent, base].filter(
      (item, index, arr) =>
        arr.findIndex((i) => i.id === item.id) === index &&
        item.id !== defaultCustomer.id
    );
    if (updatedRecent.length > 0) {
      const currentUpdateItemIndex = updatedRecent.findIndex(
        (it) => it.id === customer.id
      );
      if (currentUpdateItemIndex > -1) {
        let currentUpdateItem = updatedRecent[currentUpdateItemIndex];
        const {
          name: customerName,
          stpNumber: customerStpNumber,
          customerStatus: currentCustomerStatus,
        } = customer;
        const {
          name: currentName,
          stpNumber: currentStpNumber,
          customerStatus: currentStatus,
        } = currentUpdateItem;
        updatedRecent[currentUpdateItemIndex] = {
          ...currentUpdateItem,
          name: customerName || currentName,
          stpNumber: customerStpNumber || currentStpNumber,
          customerStatus: currentCustomerStatus || currentStatus,
        };
      }
    }
    const storage = JSON.stringify(updatedRecent);
    authFetch(
      `${publicUrl}/recent/clients/${currentIdentityData?.currentIdentity?.id}`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: storage,
      }
    );
    setRecent(updatedRecent);
    setCustomer(customer);
    // ? Something here seems to be weird, looks like a new client will always return a null, which will render incorrectly.
    // ? It looks like the Client Information's social wall toggle is the only way to actually control this feature...
    // ? but cant be viewed unless social has an object value. Seems kind of odd, so I make sure it has at least the defaultSocial.
    setSocial(social.socialConfig ? social : defaultSocial);
    setFeatures(features.nodes);
    setIdentitySearch(identitySearch);
    setIsDefault(customer.id === defaultCustomer.id);
    setWelcomeMessage(welcomeMessage);
  };

  return (
    <Provider
      value={{
        customer,
        features,
        welcomeMessage,
        identitySearch,
        social,
        setCustomer: handleSetCustomer,
        isDefault,
        unset: () =>
          handleSetCustomer({
            customer: defaultCustomer,
            features: { nodes: [] },
            social: defaultSocial,
            welcomeMessage: { enabled: false },
            identitySearch: { totalRecordsCount: 0 },
          }),
        recent,
        setRecent,
        currentImpersonateClient,
        setCurrentImpersonateClient,
      }}
    >
      {children}
    </Provider>
  );
}

export const CustomerConsumer = Consumer;
export const CustomerContext = Context;
