import { EligibilityInput } from './graphql';
import { CreateProgramPayload } from './types';

export const INITIAL_PROGRAM_STATE: CreateProgramPayload = {
  coreProgramTypeId: null, // ? recognition, anniversaries, birthdays
  programCategoryId: null, // ? recognition, anniversaries, birthdays
  customerId: '',
  displayTz: 'America/Denver',
  startDate: null,
  endDate: null,
  programFinanceTypeCode: '',
  pointBankUuid: '',
  programName: '',
  programDscr: '',
  //? will need this when we get the GQL query for PO number config
  // poNumberConfig: {
  //   standardPOType: 'STANDARDPO'
  //   standardPOBNumnberType: 'PO1'
  //   customerPOType: ''
  //   customerPONumber: {
  //     id: ''
  //   }
  // }
  //?Do not need this for now
  // managerDashboardEnabled: true,
  //? This is not needed for now, but will be needed in the future
  // programSalesOrgs: [
  //   //{
  //   //  pointBankSalesOrgId: '',
  //   //},
  // ],
};

export const INITIAL_ELIGIBILITY_STATE: EligibilityInput = {
  programUuid: '',
  allParticipants: true,
  businessUnitEligibilities: [],
  homeCountryEligibilities: [],
  workCountryEligibilities: [],
};
