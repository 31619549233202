import { gql } from '@apollo/client';

export const GET_INVOICE_CONFIGURATION = gql`
query invoiceConfiguration {
  allCoreInvoiceMediums {
    coreInvoiceMediums {
      nodes {
        isDefault
        invoiceMediumCode
        invoiceMediumDescription
      }
    }
    defaultCoreInvoiceMedium {
      invoiceMediumDescription
      invoiceMediumCode
      isDefault
    }
  }
  allCoreBillingSchedules {
    coreBillingSchedule {
      nodes {
        isDefault
        billingScheduleCode
        billingScheduleDescription
      }
    }
    defaultCoreBillingSchedule {
      billingScheduleCode
      billingScheduleDescription
      isDefault
    }
  }
  allCoreBillingTypes {
    coreBillingTypes {
      nodes {
        billingTypeCode
        isDefault
        billingTypeDescription
      }
    }
    defaultCoreBillingType {
      billingTypeCode
      billingTypeDescription
      isDefault
    }
  }
}
`;

export const GET_SALES_TERRITORY = gql`
  query searchSalesTerritory($searchText: String!) {
    searchSalesTerritory(searchText: $searchText) {
      nodes {
        salesTerritoryCode
        salesTerritoryDescription
      }
      pageInfo {
        pageNumber
        totalPages
      }
    }
  }
`;

export const SEARCH_COUNTRY = gql`
  query SearchCountries($clause: String!) {
    searchCountries(clause: $clause) {
      content {
        iso2Code
        countryName
        countryNum
      }
    }
  }
`;

export const CREATE_CUSTOMER_V2 = gql`
  mutation CreateCustomerV2($input: CreateCustomerInputV2!) {
    createCustomerV2(input: $input) {
      name
      customerUuid
      stpNumber
    }
  }
`;
