import Loading from '../../common/Loading';
import ContentItemComponent from './ContentItem';
import { usePrivacyNotices } from './hooks';

const PrivacyPolicyComponent = () => {
  const { data, loading, error, actions } = usePrivacyNotices();

  if (loading) {
    return <Loading />;
  }

  return (
    <ContentItemComponent
      contentVersions={data}
      createVersion={actions.createPrivacyNoticeVersion}
      error={error}
      typeText="Privacy Notice"
    />
  );
};

export default PrivacyPolicyComponent;
