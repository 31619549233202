import React, { useEffect, useRef, useState } from 'react';

const observerOptions = {
  root: null,
  rootMargin: '0px',
  threshold: 1.0,
};

interface Props {
  load: () => void;
}

export default function ScrollLoader({ load }: Props) {
  const loadingRef = useRef<HTMLDivElement>(null);
  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    if (!trigger) return;
    load();
    setTrigger(false);
  }, [trigger, load]);

  const handleObserver = (
    entries: IntersectionObserverEntry[],
    _observer: IntersectionObserver
  ) => {
    if (entries[0].intersectionRatio <= 0) return;
    setTrigger(true);
  };

  const [observer] = useState(
    () => new IntersectionObserver(handleObserver, observerOptions)
  );

  useEffect(() => {
    if (!observer || !loadingRef?.current || observer.root) return;
    observer.observe(loadingRef.current);
  }, [observer, loadingRef]);

  return <div ref={loadingRef}></div>;
}
