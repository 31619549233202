import { ArrowBack as ArrowBackIcon } from '@material-ui/icons';
import {
  Alert,
  Button,
  FormControl,
  FormHelperText,
  ListItem,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@octanner/prism-core';

import React, { useContext, useState } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import BasicPadding from '../../../common/BasicPadding';
import { updateFormValue } from '../../../common/models';
import { CustomerContext } from '../../../utils/context/CustomerContext';
import { useCreateProgram, useGetProgramTypes } from '../hooks';
import { ProgramType, PROGRAM_NAME_LENGTH } from '../models/models';
import { useStyles } from '../styles';
import { Grouping, State } from '../types';

const NewProgram = () => {
  const classes = useStyles();
  const history = useHistory();
  const {
    customer: { id: customerId },
    features,
  } = useContext(CustomerContext);
  const [state, setState] = useState<State>({
    programName: updateFormValue('', '', false),
    programTypeId: updateFormValue('', '', false),
  });
  const [error, setError] = useState('');
  const [groupings, setGroupings] = useState<Grouping[]>([]);
  const [programTypes, setProgramTypes] = useState<ProgramType[]>([]);
  const createProgram = useCreateProgram(customerId);
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const renderMenuItemsList = () => {
    const renderArray: JSX.Element[] = [];
    groupings.forEach(({ feature, programTypes }) => {
      renderArray.push(
        <ListItem className={classes.listStyle}>{feature.name}</ListItem>
      );
      programTypes.forEach(({ id, programTypeName }) => {
        renderArray.push(
          <MenuItem
            key={id}
            value={id}
            data-test={`program:new:programType:${id}`}
          >
            {programTypeName}
          </MenuItem>
        );
      });
    });

    return renderArray;
  };

  useGetProgramTypes((data) => {
    const { nodes } = data.coreProgramTypes.coreProgramTypes;
    const availableCodes = features.map((f) => f.feature.featureCode);
    const filtered = nodes.filter((pt) =>
      availableCodes.includes(pt.feature.code)
    );
    const codes = filtered
      .map((pt) => pt.feature.code)
      .filter((code, index, arr) => arr.indexOf(code) === index);
    const groups = codes.map((code) => {
      const programTypes = filtered.filter((pt) => pt.feature.code === code);
      const feature = programTypes[0].feature;
      return {
        feature,
        programTypes,
      };
    });
    setProgramTypes(filtered);
    setGroupings(groups);
  });

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    // clear out errors
    setError('');
    setState({
      programName: updateFormValue(state.programName.value),
      programTypeId: updateFormValue(state.programTypeId.value),
    });
    event.preventDefault();

    // validate data
    const trimmedName = state.programName.value.trim();
    if (!trimmedName) {
      setState({
        ...state,
        programName: updateFormValue(
          state.programName.value,
          'Enter a Program Name'
        ),
      });
      return;
    }

    if (trimmedName.length > PROGRAM_NAME_LENGTH) {
      setState({
        ...state,
        programName: updateFormValue(
          state.programName.value,
          `Program name cannot be longer than ${PROGRAM_NAME_LENGTH} characters`
        ),
      });
      return;
    }

    const coreProgramTypeId = parseInt(state.programTypeId.value);
    if (isNaN(coreProgramTypeId)) {
      setState({
        ...state,
        programTypeId: updateFormValue(
          state.programTypeId.value,
          `Select a Program Category`
        ),
      });
      return;
    }

    try {
      const { data } = await createProgram({
        variables: {
          customerId,
          programName: trimmedName,
          coreProgramTypeId,
        },
      });

      if (!data?.createNewCoreProgram) {
        setError(
          'There was an error while saving, if this continues please contact the Core Team.'
        );
        return;
      }
      history.push(
        `/customer/${customerId}/programs/${data.createNewCoreProgram.programUuid}/edit`
      );
    } catch {
      setError(
        'There was an error while saving, if this continues please contact the Core Team. Please note program names must be unique within a client.'
      );
    }
  };

  const handleChange = (programTypeId: number) => {
    setState({
      ...state,
      programTypeId: updateFormValue('' + programTypeId),
    });
  };

  return (
    <BasicPadding>
      <Button
        variant="text"
        data-test="new-program:back"
        component={RouterLink}
        // @ts-ignore prism issue
        to={`/customer/${customerId}/programs`}
        underline="none"
        startIcon={<ArrowBackIcon />}
      >
        Back to Programs
      </Button>
      {error && (
        <Alert severity="error" className={classes.errorAlert}>
          <Typography data-test="program:error">{error}</Typography>
        </Alert>
      )}
      <Typography variant="h1" className={classes.title}>
        Create a New Program
      </Typography>
      <form className={classes.newProgramForm} onSubmit={handleSubmit}>
        <div>
          <TextField
            id="program-name"
            label="Program Name"
            error={Boolean(state.programName.error)}
            helperText={state.programName.error}
            fullWidth
            value={state.programName.value}
            onChange={(e) =>
              setState({
                ...state,
                programName: updateFormValue(e.target.value),
              })
            }
            autoFocus
            inputProps={{ 'data-test': 'program:name' }}
          />
        </div>
        <div>
          <FormControl
            style={{ width: '100%' }}
            error={Boolean(state.programTypeId.error)}
          >
            {/* <InputLabel>Program Category</InputLabel> */}
            <Select
              open={open}
              onClose={handleClose}
              onOpen={handleOpen}
              value={state.programTypeId.value}
              onChange={(event: any) => handleChange(event.target.value)}
              label="Program Category"
              data-test="program:new:programType"
              name="programType"
              renderValue={(v) =>
                programTypes.find((pt) => pt.id === Number(v))?.programTypeName
              }
            >
              {/* you must render menu items and listing in the same Select, so I moved it into its own array function that generates this for you. */}
              {renderMenuItemsList()}
            </Select>
            <FormHelperText>{state.programTypeId.error}</FormHelperText>
          </FormControl>
        </div>
        <div>
          <Button type="submit" data-test="program:new:submit" adminColor>
            Create
          </Button>
        </div>
      </form>
    </BasicPadding>
  );
};

export default NewProgram;
