import styled from "@emotion/styled";
import {
  Button,
  Checkbox,
  TableCell,
  TableRow,
  Tag,
  TextField,
  Typography
} from '@octanner/prism-core';
import React, { useContext } from "react";
import { LanguageStringsContext } from "../../../Contexts/LanguageStringsContext";
import { TagVariant } from "../../../utils/constants";
import { defaults } from "../../../utils/default";
import { TranslationRowProps } from '../../../utils/types';

const Row = styled(TableRow) <Partial<TranslationRowProps>>`
  background: ${(props) => (props.englishlocaleind === 'true' ? '#FBFBFB' : '')};
`

const CheckBoxCell = styled(TableCell)`
  width: 1%;
`

const LocaleDescCell = styled(TableCell)`
  width: 15%;
`

const LocaleCodeCell = styled(TableCell)`
  width: 13%;
`

const ActionCell = styled(TableCell)`
  width: 12%;
`

const StatusCell = styled(TableCell)`
  width: 14%;
`

const TextCell = styled(TableCell)`
  width: 40%;
`

const ActionButtons = styled.div`
  display: flex;
`

const TranslationRow: React.FC<Partial<TranslationRowProps>> = ({
  locale,
  localeDescription,
  text,
  originalText,
  editMode,
  checked,
  translationStatus,
  loading,
  saveAllLoading,
  setFilteredStringTranslationList,
  setRequestedLocales,
  setRequestAll,
  allUnTranslatedLocales,
  setStringTranslationList,
  setOpenEditEnglishTranslationModal,
  setEnglishRequestedString,
  stringId,
  filteredStringTranslationList,
  stringTranslationList,
  upsertTranslation,
  upsertTranslationLoading
}) => {

  const { setShowFooter, setMessageText } = useContext(LanguageStringsContext);

  const handleCheckBox = (locale: string, e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    setFilteredStringTranslationList(prevList => {
      const updatedList = prevList.map(item => {
        if (item.locale === locale) {
          item.checked = isChecked;
        }
        return item;
      });
      const updatedLocales = updatedList.filter(item => item.checked).map(item => ({
        locale: item.locale,
        localeDescription: item.localeDescription
      }));
      setRequestedLocales(updatedLocales);
      setRequestAll(updatedLocales.length === allUnTranslatedLocales.length);
      setShowFooter(updatedLocales.length > 0);
      return updatedList;
    });
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, locale: string) => {
    setFilteredStringTranslationList(prevList => {
      const updatedList = prevList.map(item => {
        if (item.locale === locale) {
          return {
            ...item,
            text: e.target.value
          };
        }
        return item;
      });
      return updatedList;
    });
  };

  const toggleEdit = (locale: string, editInd: boolean) => {
    setFilteredStringTranslationList(prevList => {
      const updatedList = prevList.map(item => {
        if (item.locale === locale) {
          return {
            ...item,
            editMode: !item.editMode,
            text: editInd ? item.text : (item.originalText ? item.originalText : '')
          };
        }
        return item;
      });
      return updatedList;
    });
  };

  const handleSave = (locale: string) => {
    const index = filteredStringTranslationList.findIndex(item => item.locale === locale);
    if (locale === 'en-US') {
      setOpenEditEnglishTranslationModal(true);
      const englishTranslationString = filteredStringTranslationList[index].text;
      setEnglishRequestedString(englishTranslationString);
      return;
    }
    setFilteredStringTranslationList(prevList => {
      const updatedList = [...prevList];
      const localeIndex = updatedList.findIndex(item => item.locale === locale);
      if (localeIndex !== -1) {
        updatedList[localeIndex].loading = true;
      }
      return updatedList;
    });
    upsertTranslation({
      variables: {
        input: {
          deleteExistingDataInd: false,
          stringId: parseInt(stringId),
          translationStringInput: {
            locale: locale,
            stringText: filteredStringTranslationList[index].text
          }
        }
      }
    }).then(response => {
      if (response.errors) {
        setMessageText('errorMessage', defaults.update_error);
      } else {
        setFilteredStringTranslationList(prevList => {
          setMessageText('editSuccessMessage', defaults.update_success);
          const updatedList = [...prevList];
          const localeIndex = updatedList.findIndex(item => item.locale === locale);
          if (localeIndex !== -1) {
            updatedList[localeIndex].loading = false;
            updatedList[localeIndex].editMode = false;
            updatedList[localeIndex].originalText = filteredStringTranslationList[index].text;
            if (filteredStringTranslationList[index].translationStatus === 'UNTRANSLATED') {
              updatedList[localeIndex].translationStatus = 'TRANSLATED';
            }
          }
          const updatedTranslationList = stringTranslationList.map(item => {
            if (updatedList[localeIndex].locale === item.locale) {
              return {
                ...item,
                text: updatedList[localeIndex].text,
                originalText: updatedList[localeIndex].originalText,
                translationStatus: updatedList[localeIndex].translationStatus
              };
            }
            return item;
          });
          setStringTranslationList([...updatedTranslationList]);
          return updatedList;
        });
      }
    });
  };

  return (
    <Row key={locale} englishlocaleind={locale === 'en-US' ? 'true' : 'false'}>
      <CheckBoxCell>
        {locale !== 'en-US' &&
          <Checkbox
            checked={checked}
            disabled={translationStatus !== 'UNTRANSLATED'}
            onChange={(e) => handleCheckBox(locale, e)} />}
      </CheckBoxCell>
      <LocaleDescCell>
        <Typography>{localeDescription}</Typography>
      </LocaleDescCell>
      <LocaleCodeCell>
        <Typography>{locale === 'id-ID' ? 'in-ID' : locale}</Typography>
      </LocaleCodeCell>
      <StatusCell>
        {locale === 'en-US' ?
          <Typography>{defaults.default}</Typography> :
          <Tag variant={TagVariant[translationStatus].variant}>{TagVariant[translationStatus].text}</Tag>}
      </StatusCell>
      <TextCell>
        <TextField fullWidth value={text} InputProps={{ readOnly: !editMode }} displayCount={editMode} maxLength={4000}
          onChange={(e) => handleTextChange(e, locale)} />
      </TextCell>
      <ActionCell>
        {!editMode ? <Button variant='text' onClick={() => toggleEdit(locale, true)}>{defaults.edit}</Button> :
          <ActionButtons>
            <Button variant='text' color='primary' loading={upsertTranslationLoading && loading}
              disabled={(text.trim() === originalText.trim()) || saveAllLoading || !(text && text.trim().length && text.trim().length <= 4000)}
              onClick={() => handleSave(locale)}>{defaults.save}</Button>
            <Button variant='text' color="secondary" buttonType="danger" disabled={saveAllLoading || loading}
              onClick={() => toggleEdit(locale, false)}>{defaults.cancel}</Button>
          </ActionButtons>}
      </ActionCell>
    </Row>
  );
}

export default TranslationRow;