import { gql } from '@apollo/client';

export const TURN_ON_PERSONAL_FEED = gql`
  mutation SocialTurnOnPersonalFeedOverride($customerUuid: UUID!) {
    socialTurnOnPersonalFeedOverride(customerUuid: $customerUuid) {
      enabled
    }
  }
`;

export const TURN_OFF_PERSONAL_FEED = gql`
  mutation SocialTurnOffPersonalFeedOverride($customerUuid: UUID!) {
    socialTurnOffPersonalFeedOverride(customerUuid: $customerUuid) {
      enabled
    }
  }
`;
