import { useEffect, useMemo, useState } from 'react';
import BasicPadding from '../../common/BasicPadding';
import { Tabs, Box } from '@octanner/prism-core';
import { TabPanel, a11yProps } from './tabs';
import { BlackTab, SystemSettingHeader } from './styles';
import { TAB_COMPONENTS } from './constants';
import { useFlags } from 'launchdarkly-react-client-sdk';
import UserSettings from './userSettings';
import { useHistory, useLocation } from 'react-router';
interface ReadOnlyURLSearchParams extends URLSearchParams {
  append: never;
  set: never;
  delete: never;
  sort: never;
}

const SystemSettings = () => {
  const [value, setValue] = useState(0);
  const { search } = useLocation();
  const history = useHistory();

  const { ccAdminSystemSettings } = useFlags<{
    ccAdminSystemSettings: boolean;
  }>();

  const searchParams = useMemo(
    () => new URLSearchParams(search) as ReadOnlyURLSearchParams,
    [search]
  );

  useEffect(() => {
    const tabName = searchParams.get('tab');

    if (tabName) {
      setValue(getTabNumber(tabName));
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTabNumber = (tabName: string) => {
    switch (tabName) {
      case 'systemSettings':
        return 0;
      case 'communications':
        return 1;
      case 'universalSearch':
        return 2;
      case 'socialWall':
        return 3;
      default:
        return 0;
    }
  };

  const handleSearchParamChange = (key: string, value: string) => {
    // @ts-expect-error above is set to never not sure why though.
    searchParams.set(key, value);
    history.replace({ search: searchParams.toString() });
  };

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);

    switch (newValue) {
      case 0:
        handleSearchParamChange('tab', 'systemSettings');
        break;
      case 1:
        handleSearchParamChange('tab', 'communications');
        break;
      case 2:
        handleSearchParamChange('tab', 'universalSearch');
        break;
      case 3:
        handleSearchParamChange('tab', 'socialWall');
        break;
    }
  };
  if (!ccAdminSystemSettings) {
    return (
      <BasicPadding>
        <SystemSettingHeader variant="h1">System Settings</SystemSettingHeader>
        <UserSettings />
      </BasicPadding>
    );
  }

  return (
    <BasicPadding>
      <SystemSettingHeader variant="h1">System Settings</SystemSettingHeader>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            TabIndicatorProps={{
              style: {
                backgroundColor: '#0060E0',
              },
            }}
          >
            <BlackTab
              label="User Settings"
              {...a11yProps(0)}
              data-testid="tab:user-settings"
            />
            <BlackTab
              label="Communications"
              {...a11yProps(1)}
              data-testid="tab:communications"
            />
            <BlackTab
              label="Universal Search"
              {...a11yProps(2)}
              data-testid="tab:universal-search"
            />
            <BlackTab
              label="Social Wall"
              {...a11yProps(3)}
              data-testid="tab:social-wall"
            />
          </Tabs>
        </Box>
        <div style={{ marginTop: 8 }}>
          {TAB_COMPONENTS.map(({ key, element, index }) => (
            <TabPanel key={key} value={value} index={index}>
              {element}
            </TabPanel>
          ))}
        </div>
      </Box>
    </BasicPadding>
  );
};

export default SystemSettings;
