import { PagedResult } from '../../../common/models/PagedResult';
import { IdentityBase } from './Identity';
import { CustomerBase } from '../../customer/models/Customer';

export interface Role {
  id: number;
  uuid: string;
  name: string;
  selected?: any;
}

export interface RequireCustomerId {
  customerId: string;
  page?: number;
}

export interface CreateNewRoleInput extends RequireCustomerId {
  roleName: string;
}

export interface UpdateRoleInput extends RequireCustomerId {
  roleName: string;
  roleId: number;
}

export interface RoleInput extends RequireCustomerId {
  roleId: number;
}

export interface DeleteRoleInput extends RequireCustomerId {
  id: number;
}

export interface RolesResponse {
  roles: PagedResult<Role>;
}

export interface RoleResponse {
  role: Role;
}

export interface DeleteRoleResponse {
  deleteOneRole: Role;
}

export interface CreateNewRoleResponse {
  createNewRole: Role;
}

export interface UpdateRoleResponse {
  updateOneRole: Role;
}

export interface DuplicateRoleInput extends RequireCustomerId {
  existingRoleId: number;
  name: string;
}

export interface DuplicateRoleResponse {
  createDuplicateRole: Role;
}

export interface NonDefaultIdentityRoles {
  identity: IdentityBase;
  roles: Role[];
}

export interface MapUserInput {
  customerId: string;
  identityId: string;
  roleId: number;
}

export interface UnMapUserInput {
  customerId: string;
  identityId: string;
  roleIds: number[];
}

export interface MapUserResponse {
  coupleIdentityRoleAndCustomerAsmt: {
    id: number;
  };
}

export interface UnMapUserResponse {
  decoupleIdentityRoleAndCustomerAsmt: {
    id: number;
  };
}

export class ClientMappingByCustomerInput {
  customerId: string;
  customerUuid: string;
  identityId: string;

  constructor(customerId: string, identityId: string) {
    this.customerId = customerId;
    this.customerUuid = customerId;
    this.identityId = identityId;
  }
}

export interface ClientMappingByCustomerResponse {
  customer: CustomerBase;
  getNonDefaultIdentitiesRolesForCustomerId: NonDefaultIdentityRoles[];
}

export function isDeleteRoleResponse(
  object: any
): object is DeleteRoleResponse {
  return 'deleteOneRole' in object;
}
