import { gql } from '@apollo/client';
import { CUSTOMER_FEATURE_BASE_FRAGMENT } from './graphql';

export const CUSTOMER_BASE_FRAGMENT = gql`
  fragment CustomerBaseFragment on Customer {
    id
    name
  }
`;

export const CUSTOMER_CORE_FRAGMENT = gql`
  fragment CustomerCoreFragment on Customer {
    ...CustomerBaseFragment
    region
    modifiedOn
    modifiedBy
    stpNumber
    customerStatus
    customerUsage
    launchedAtTsz
    settings {
      emailEditingDisabledTsz
    }
  }
  ${CUSTOMER_BASE_FRAGMENT}
`;

export const CUSTOMER_WITH_FEATURES_FRAGMENT = gql`
  fragment CustomerWithFeaturesFragment on Customer {
    ...CustomerCoreFragment
    features {
      ...CustomerFeatureBaseFragment
    }
  }
  ${CUSTOMER_CORE_FRAGMENT}
  ${CUSTOMER_FEATURE_BASE_FRAGMENT}
`;

export const MUTATE_WELCOME_MESSAGE_CONFIG = gql`
  mutation CcWebUpdateWelcomeMessageConfig(
    $customerUuid: UUID!
    $enabled: Boolean!
  ) {
    ccWebUpdateWelcomeMessageConfig(
      input: { customerUuid: $customerUuid, enabled: $enabled }
    ) {
      enabled
    }
  }
`;

export const GET_WELCOME_MESSAGE = gql`
  query CcWebWelcomeMessage($input: WelcomeMessageInput!) {
    ccWebWelcomeMessage(input: $input) {
      locale
      content
    }
  }
`;

export const MUTATE_WELCOME_MESSAGE = gql`
  mutation CcWebUpdateWelcomeMessage(
    $customerUuid: UUID!
    $locale: Locale!
    $content: String!
  ) {
    ccWebUpdateWelcomeMessage(
      input: { locale: $locale, content: $content, customerUuid: $customerUuid }
    ) {
      locale
      content
    }
  }
`;

export const GET_CUSTOMER = gql`
  query getCustomer($id: String!, $customerId: UUID!) {
    customer(id: $id) {
      ...CustomerCoreFragment
    }
    social: socialConfigByCustomerUuid(customerUuid: $id) {
      socialConfig {
        viewType
        displayComments
        displayLikes
        displayAwardLevel
        personalFeedOverride
        displayFollowingTab
      }
    }
    welcomeMessage: ccWebWelcomeMessageConfig(
      input: { customerUuid: $customerId }
    ) {
      enabled
    }
    features: customerCoreFeatureMapsByCustomer(
      input: { customerId: $customerId }
    ) {
      nodes {
        id
        feature {
          featureName
          featureCode
        }
        effectiveTsz
        expirationTsz
      }
    }
    identitySearch(condition: { customerId: $id, active: true }) {
      totalRecordsCount
    }
  }
  ${CUSTOMER_CORE_FRAGMENT}
`;
// TODO: ccAdminHideSocialWallD230425 remove this mutation when deleting LD flag
export const UPDATE_CUSTOMER_SOCIAL = gql`
  mutation UpdateSocialConfig($input: SocialConfigInput!) {
    updateSocialConfig(input: $input) {
      socialConfig {
        personalFeedOverride
      }
    }
  }
`;
// TODO: ccAdminHideSocialWallD230425 remove the social when deleting LD flag
export const UPDATE_CUSTOMER = gql`
  ${CUSTOMER_CORE_FRAGMENT}
  mutation updateCustomer(
    $name: String!
    $region: CustomerRegion!
    $id: String!
    $stpNumber: String
    $input: SocialConfigInput!
  ) {
    editCustomer(
      input: { name: $name, region: $region, id: $id, stpNumber: $stpNumber }
    ) {
      ...CustomerCoreFragment
    }
    social: updateSocialConfig(input: $input) {
      socialConfig {
        personalFeedOverride
      }
    }
  }
`;

export const COUPLE_CUSTOMER_AND_CORE_FEATURE = gql`
  ${CUSTOMER_WITH_FEATURES_FRAGMENT}
  mutation coupleCustomerAndCoreFeature(
    $customerId: UUID!
    $coreFeatureCode: String!
    $effective: String!
    $expiration: String
  ) {
    coupleCustomerAndCoreFeature(
      customerId: $customerId
      effective: $effective
      coreFeatureCode: $coreFeatureCode
      expiration: $expiration
    ) {
      customer {
        ...CustomerWithFeaturesFragment
      }
    }
  }
`;
