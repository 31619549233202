import styled from '@emotion/styled';
import { Button } from '@octanner/prism-core';
import React from 'react';
import { FooterContainerProps, FooterProps } from './../../utils/types';

const FooterContainer = styled.div<FooterContainerProps>`
  left: 0;
  right: 0;
  bottom: 0;
  text-align: right;
  width: 100%;
  display: ${(props) => (props.insideGridInd ? 'flex' : 'block')};
  align-items: center;
  justify-content: space-between;
  position: ${(props) => (props.insideGridInd ? 'sticky' : 'fixed')};
  padding: 16px 24px 16px 24px;
  background: white;
  z-index: 7;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.25);
`

const FooterButton = styled(Button)`
margin-right: 15px;
`

const Footer: React.FC<Partial<FooterProps>> = ({
  cancelAction,
  saveAction,
  cancelActionText,
  saveActionText,
  loading,
  cancelButtonDisabled,
  saveButtonDisabled,
  children,
  insideGridInd
}) => {
  return (<FooterContainer insideGridInd={insideGridInd}>
    {!children && (
      <>
        <FooterButton color="secondary" disabled={cancelButtonDisabled} onClick={cancelAction}>
          {cancelActionText}
        </FooterButton>
        <FooterButton color="primary" adminColor disabled={saveButtonDisabled} loading={loading} onClick={saveAction}>
          {saveActionText}
        </FooterButton>
      </>
    )}
    {children}
  </FooterContainer>);
}

export default Footer;