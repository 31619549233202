import styled from "@emotion/styled";
import { Button, TableCell, TableRow, Tag, TextField, Typography } from "@octanner/prism-core";
import React, { useContext, useEffect, useState } from "react";
import { CustomTextContext } from "../../Contexts/CustomTextContext";
import { TagVariant } from "../../utils/constants";
import { defaults } from "../../utils/default";
import { CustomText, CustomTextRowProps } from "../../utils/types";

const Row = styled(TableRow)`
  background: #FBFBFB;
`;

const StringIdCell = styled(TableCell)`
  width: 10%;
`;

const ActionCell = styled(TableCell)`
  width: 10%;
  vertical-align: top;
`;

const BriefIdentifierCell = styled(TableCell)`
  width: 17%;
  vertical-align: top;
`;

const TextCell = styled(TableCell)`
  width: 25%;
`;

const StatusCell = styled(TableCell)`
  width: 15%;
`;

const ActionButtons = styled.div`
  display: flex;
`;

const CustomTextRow: React.FC<Partial<CustomTextRowProps>> = (
  {
    briefIdentifierText,
    customTextList,
    setCustomTextList,
    sourceText,
    stringId,
    text,
    prevBriefIdentifierText,
    prevSourceText,
    prevText,
    editMode,
    showRequestModal,
    setShowRequestModal,
    closeModalInd,
    sourceLocale,
    selectedLocale,
    selectedLocaleRef,
    translationStatus
  }) => {

  const { customTextUniqueId, customTextNote,
    saveCustomTextNotes, saveCustomTextNotesProcessing, setTargetLocale,
    targetLocales } = useContext(CustomTextContext);
  const [translationRequestProcessing, setTranslationRequestProcessing] = useState<boolean>(false);
  const [originalBriefText, setOriginalBriefText] = useState<string>();
  const [originalSourceText, setOriginalSourceText] = useState<string>();
  const [originalText, setOriginalText] = useState<string>();
  const [disableButton, setDisableButton] = useState<boolean>(false);

  useEffect(() => {
    setOriginalBriefText(prevBriefIdentifierText);
    setOriginalSourceText(prevSourceText);
    setOriginalText(prevText);
  }, [prevBriefIdentifierText, prevSourceText, prevText])

  useEffect(() => {
    if (!showRequestModal && translationRequestProcessing && !closeModalInd) {
      updateCustomText();
      setTranslationRequestProcessing(false);
    }
  }, [showRequestModal, translationRequestProcessing]);

  const handleEdit = (stringId: string, editInd: boolean): void => {
    setCustomTextList((prevList: CustomText[]) => {
      const updatedList = prevList.map((item: CustomText) => {
        if (item.stringId === stringId) {
          return {
            ...item,
            editMode: !item.editMode,
            sourceText: editInd ? item.sourceText : originalSourceText,
            briefIdentifierText: editInd ? item.briefIdentifierText : originalBriefText,
            text: editInd ? item.text : originalText
          };
        }
        return item;
      });
      return updatedList;
    });
  };

  const handleTextChange = (field: 'briefIdentifierText' | 'sourceText' | 'text') =>
    (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
      if (field === 'briefIdentifierText' && event.target.value?.length > 50) {
        setDisableButton(true);
        return;
      }
      if (field === 'sourceText' && event.target.value?.length > 4000) {
        setDisableButton(true);
        return;
      }
      setDisableButton(false);
      setCustomTextList((prevList: CustomText[]) => {
        const updatedList = prevList.map((item: CustomText) => {
          if (item.stringId === stringId) {
            return {
              ...item,
              [field]: event.target.value
            };
          }
          return item;
        });
        return updatedList;
      });
    }

  const updateCustomText = (): void => {
    saveCustomTextNotes({
      variables: {
        input: {
          customTextStringList: customTextList.map(item => ({
            stringId: item.stringId,
            briefIdentifierText: item.briefIdentifierText,
            sourceText: item.sourceText,
            targetText: item.text
          })),
          customTextRequestUniqueId: customTextUniqueId,
          targetLocale: selectedLocale,
          notes: customTextNote?.trim()
        }
      }
    })
    setTargetLocale(selectedLocale);
    selectedLocaleRef.current = null;
  }

  const handleSave = (stringId: string): void => {
    customTextList.forEach((customText: CustomText) => {
      if (customText.stringId === stringId) {
        if (customText.sourceText !== originalSourceText && targetLocales.length > 1) {
          setTranslationRequestProcessing(true);
          setShowRequestModal(true);
        } else {
          updateCustomText();
          setShowRequestModal(false);
        }
      }
    });
  };

  return (
    <Row>
      <StringIdCell>
        <Typography>{stringId}</Typography>
      </StringIdCell>
      <BriefIdentifierCell>
        <TextField multiline fullWidth value={briefIdentifierText || ''} displayCount={editMode && briefIdentifierText.length <= 50}
          maxLength={50} onChange={handleTextChange('briefIdentifierText')} InputProps={{ readOnly: !editMode }} />
      </BriefIdentifierCell>
      <StatusCell>
        <Tag variant={TagVariant[translationStatus].variant}>{TagVariant[translationStatus].text}</Tag>
      </StatusCell>
      <TextCell>
        <TextField multiline fullWidth value={sourceText || ''} displayCount={editMode && sourceText.length <= 4000} maxLength={4000}
          onChange={handleTextChange('sourceText')} InputProps={{ readOnly: !editMode }} />
      </TextCell>
      <TextCell>
        <TextField multiline fullWidth value={text || ''} onChange={handleTextChange('text')} InputProps={{ readOnly: !editMode || (sourceLocale === selectedLocale) }} />
      </TextCell>
      <ActionCell>
        {!editMode ? <Button variant='text' onClick={() => handleEdit(stringId, true)}>{defaults.edit}</Button> :
          <ActionButtons>
            <Button variant='text' color="secondary" buttonType="danger" disabled={saveCustomTextNotesProcessing}
              onClick={() => handleEdit(stringId, false)}>{defaults.cancel}</Button>
            <Button variant='text' color='primary'
              disabled={!sourceText || !briefIdentifierText || disableButton} loading={saveCustomTextNotesProcessing}
              onClick={() => handleSave(stringId)}>{defaults.save}</Button>
          </ActionButtons>}
      </ActionCell>
    </Row>
  )
}

export default CustomTextRow;