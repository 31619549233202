import { styled } from '@mui/material/styles'
import { Card, Button, Tag, Typography } from '@octanner/prism-core'
import {
  ActionSection,
  StyledCardContent,
} from 'library/src/common/components/Styled'
import Check from '@octanner/prism-icons/Check'
import Close from '@octanner/prism-icons/Close'
import React, { useMemo } from 'react'
import Colors from '../../../common/colors'
import { CelebrationLevelGroupConfig } from '../../../common/models/CelebrationLevelGroupConfig'
import { DetailViewModel, rootFactories } from './model'

const StyledTag = styled(Tag)`
  margin-top: ${({ theme }) => theme.spacing(3)};
`
const StyledCheck = styled(Check)`
  height: 10px;
  color: ${Colors.green};
`
const StyledClose = styled(Close)`
  height: 10px;
  color: ${Colors.red};
`
const Filler = styled('div')`
  width: 10px;
  display: block;
`
const ChildrenDetails = styled('div')`
  margin-left: ${({ theme }) => theme.spacing(5)};
`
const ConfigTextContainer = styled('div')`
  align-items: center;
  display: flex;
`
const ConfigText = styled(Typography)`
  margin-left: 4px;
`

interface Props {
  celebrationGroup: CelebrationLevelGroupConfig
  onEditClick: (groupId?: string) => void
}

interface ConfigDetailProps {
  model: DetailViewModel
}

export default function CelebrationCard({
  celebrationGroup,
  onEditClick,
}: Props): JSX.Element {
  const details = rootFactories.map((factory) => factory(celebrationGroup))
  const yearsText = useMemo(
    () =>
      genYearsText(celebrationGroup.celebrationLevelConfigs.map((c) => c.rank)),
    [celebrationGroup.celebrationLevelConfigs]
  )

  return (
    <Card>
      <StyledCardContent>
        <section datat-testid={`celebration-group:${celebrationGroup.id}`}>
          <Typography variant="h5" data-testid="name">
            {celebrationGroup.name}
          </Typography>
          <StyledTag
            variant={celebrationGroup.milestone ? 'success' : 'info'}
            data-testid="milestone-type"
          >
            {!celebrationGroup.milestone && 'Non-'}Milestone
          </StyledTag>
        </section>
        <section>
          <Typography>Anniversary Years:</Typography>
          <Typography data-testid="anniversary-years">{yearsText}</Typography>
        </section>
        <section>
          {details.map((vm) => vm && <ConfigDetail key={vm.text} model={vm} />)}
        </section>
        <ActionSection>
          <Button
            data-testid="edit-group"
            variant="text"
            onClick={() => onEditClick(celebrationGroup.id)}
          >
            Edit Group
          </Button>
        </ActionSection>
      </StyledCardContent>
    </Card>
  )
}

function genYearsText(years: number[]): string {
  return years
    .sort((a, b) => a - b)
    .reduce((text, year, index, sorted) => {
      if (!index) return year.toString()

      const previousYear = sorted[index - 1]
      const isDash = text.slice(-1) === '-'
      const diff = Math.abs(year - previousYear)
      const isLast = index + 1 === sorted.length

      if (isDash && diff === 1)
        return text.concat(isLast ? year.toString() : '')
      if (diff === 1) return text.concat(isLast ? `, ${year}` : '-')
      if (isDash) return text.concat(`${previousYear}, ${year}`)
      return text.concat(`, ${year}`)
    }, '')
}

function ConfigDetail({ model }: ConfigDetailProps) {
  return (
    <>
      <ConfigTextContainer>
        {model.checked && <StyledCheck style={{ height: 10 }} />}
        {model.checked === false && <StyledClose style={{ height: 10 }} />}
        {typeof model.checked === 'undefined' && <Filler />}
        <ConfigText>{model.text}</ConfigText>
      </ConfigTextContainer>
      {model.children && (
        <ChildrenDetails>
          {model.children.map(
            (vm) => vm && <ConfigDetail key={vm.text} model={vm} />
          )}
        </ChildrenDetails>
      )}
    </>
  )
}
