import { InitialBankData } from './types';

export const INITIAL_BANK_STATE: InitialBankData = {
  pointBankName: '',
  //TODO: Leaving it as default 'ONE_TIME_BANK' so it works with LaunchDarkly - (change to empty string later)
  pointBankType: 'ONE_TIME_BANK',
  defaultAccruingBank: false,
  billingConfig: {
    //TODO: Leaving it as default 'RECIPIENT' so it works with LaunchDarkly - (change to empty string later)
    billingLocationType: 'RECIPIENT',
    //TODO: Leaving it as default 'BILL1' so it works with LaunchDarkly - (change to empty string later)
    billingLocationRecipientType: 'BILL1',
    billingType: '',
    billingLocation: {
      locationUuid: '',
    },
  },
  pointExpiryConfiguration: {
    pointExpiryType: null,
    monthlyRollingExpirationNum: 24,
    fixedDates: [
      {
        startDateTsz: null,
        endDateTsz: null,
        expirationDateTsz: null,
      },
    ],
  },
  poNumberType: 'PO_1',
  //labelFields: {
  //  labelLine1: null,
  //  labelLine2: null,
  //},
  invoiceFieldConfig: {
    invoiceFieldEntity1: null,
    invoiceFieldEntity2: null,
    invoiceFieldEntity3: null,
    invoiceFieldEntity4: null,
  },
  pointBankSalesOrges: [],
  pointBankSalesOrgesByCountry: [],
};
