import { TEST_ADMINS_PAGED_DATA } from '../constants';
import { useContext } from 'react';
import { AppContext } from '../../../../../utils/context/AppContext';
import { useQuery } from '@apollo/client';
import { AdminCardSummaryResponse } from '../graphql/types';
import { GET_ADMIN_SUMMARY } from '../graphql/gql';

const mockData = (identityUuid: string) => ({
  data: TEST_ADMINS_PAGED_DATA.content.find(
    (i) => i.identityUuid === identityUuid
  ),
  loading: false,
});

export const useGetAdminSummary = (
  identityUuid: string,
  shouldSkip: boolean = false
) => {
  const { config } = useContext(AppContext);
  const query = useQuery<AdminCardSummaryResponse>(GET_ADMIN_SUMMARY, {
    variables: {
      identityUuid: identityUuid,
    },
    context: {
      clientName: 'roleDelegator',
    },
    skip: shouldSkip || config.useRoleDelegatorMockData,
  });

  return config.useRoleDelegatorMockData
    ? mockData(identityUuid)
    : {
        ...query,
        data: query.data?.identity,
      };
};
