import { gql, useQuery } from '@apollo/client';
import { Award } from 'types';
import { useSetError } from '../utils/SetErrorProvider';
import { AWARD_FRAGMENT } from '../fragments';

export interface RoundData {
  ccrcGetAwardLevelByUuid: {
    error: {
      code: string;
      messge: string;
    };
    data: Award;
  };
}

export const GET_ROUNDS = gql`
  ${AWARD_FRAGMENT}
  query CcrcGetAwardLevelByUuid($awardLevelUuid: UUID!) {
    ccrcGetAwardLevelByUuid(awardLevelUuid: $awardLevelUuid) {
      error {
        message
        code
      }
      data {
        ...AwardFragment
      }
    }
  }
`;

export const useRounds = (awardLevelUuid: string, skip: boolean) => {
  const setError = useSetError();
  const { data, error, ...rest } = useQuery<RoundData>(GET_ROUNDS, {
    variables: { awardLevelUuid },
    skip: !awardLevelUuid || skip,
    onError: () => {
      setError(
        'There was a problem getting the list of available awards. Please try again.',
      );
    },
  });

  const nestedError = !!data?.ccrcGetAwardLevelByUuid?.error;

  return {
    awardLevel: data?.ccrcGetAwardLevelByUuid?.data,
    error: error || nestedError,
    ...rest,
  } as const;
};
