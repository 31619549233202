import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { FC, ReactNode } from 'react';
import { THEME_SPACING } from './constants/themeSpacing';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    xs: {
      padding: theme.spacing(THEME_SPACING.xs),
    },
    sm: {
      padding: theme.spacing(THEME_SPACING.small),
    },
    md: {
      padding: theme.spacing(THEME_SPACING.medium),
    },
    lg: {
      padding: theme.spacing(THEME_SPACING.large),
    },
  })
);

type Padding = 'xs' | 'sm' | 'md' | 'lg';

interface BasicPaddingProps {
  children: ReactNode;
  padding?: Padding;
  className?: string;
}

export default function BasicPadding({
  children,
  padding = 'lg',
  className,
}: BasicPaddingProps) {
  const classes = useStyles();
  return <div className={clsx(classes[padding], className)}>{children}</div>;
}

export const withPad =
  (padding: Padding = 'lg') =>
  <P extends object>(Component: FC): React.FC<P> =>
  (props: P) =>
    (
      <BasicPadding padding={padding}>
        <Component {...(props as P)} />
      </BasicPadding>
    );
