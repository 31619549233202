import { HorizontalLine } from '../../../../styles';
import { SectionProps } from '../../types';
import AwardLevelRow from './AwardLevelRow';
import { ProgramSubTitle, ProgramTitle } from './styles';

const AwardLevelSection = ({ data }: SectionProps) => {
  return (
    <div>
      <div>
        <ProgramTitle
          variant="h2"
          data-testid="social-wall:programs-and-award-levels"
        >
          Programs and Award Levels
        </ProgramTitle>
        <ProgramSubTitle>
          Configure what programs and awards display on the social feed.
        </ProgramSubTitle>
      </div>
      <HorizontalLine />
      {data?.programAwards.map((programData, i) => (
        <AwardLevelRow
          key={programData.name}
          data={programData}
          index={i}
          customerUuid={data.customerUuid}
          // disabled={!data.enabled}
          // disabling until it actually works
          disabled
        />
      ))}
    </div>
  );
};

export default AwardLevelSection;
