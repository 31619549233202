import PageWrapper from '../../components/PageWrapper';
import BasicPadding from '../../../../../common/BasicPadding';
import { useContext, useEffect, useState } from 'react';
import {
  AdminRoleInformation,
  ErrorData,
  Errors,
  Success,
  SuccessData,
} from '../../types';
import AdminHeader from '../components/AdminHeader';
import AdminDetails from '../components/AdminDetails';
import { useHistory, useLocation } from 'react-router';
import { useParams } from 'react-router-dom';
import UnsavedModal from '../../components/UnsavedModal';
import DeleteAdminModal from '../../components/DeleteAdminModal';
import { ErrorHandler } from '../../ErrorHandler';
import SuccessHandler from '../../SuccessHandler';
import Typography from '@octanner/prism-core/Typography';
import AddAdminRoleSearch from '../../components/AddAdminRoleSearch';
import RoleTabs from '../../components/RoleTabs';
import { Box } from '@octanner/prism-core';
import RemoveAllRolesModal from '../../components/RemoveAllRolesModal';
import { AlertContainer } from '../styles';
import { useGetAdminSummary } from '../hooks/getAdminSummary';
import { useUpdateAdmin } from '../hooks/updateAdmin';
import { AppContext } from '../../../../../utils/context/AppContext';
import { FetchResult } from '@apollo/client';
import AdminPagination from '../../components/AdminPagination';
import { useGetAdminActiveRoles } from '../hooks/getAdminActiveRoles';
import { useGetAdminInactiveRoles } from '../hooks/getAdminInactiveRoles';
import { paginate } from '../../paginationUtils';
import { PagedAdminRoles } from '../../adminRoles/graphql/types';
import { compareDates } from '../../dateUtils';

const EditAdmin = () => {
  const TYPE = 'edit';
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { identityUuid } = useParams<{ identityUuid: string }>();
  const { data: identity, loading } = useGetAdminSummary(identityUuid);
  const [activeRolesPageNumber, setActiveRolesPageNumber] = useState(1);
  const [activeRolesPageSize, setActiveRolesPageSize] = useState(10);
  const {
    data: allActiveRoles,
    loading: loadingAllActiveRoles,
    refetch: refetchAllActiveRoles,
  } = useGetAdminActiveRoles(identityUuid);
  const [pagedActiveRoles, setPagedActiveRoles] = useState<PagedAdminRoles>();
  const [inactiveRolesPageNumber, setInactiveRolesPageNumber] = useState(1);
  const [inactiveRolesPageSize, setInactiveRolesPageSize] = useState(10);
  const {
    data: allInactiveRoles,
    loading: loadingAllInactiveRoles,
    refetch: refetchAllInactiveRoles,
  } = useGetAdminInactiveRoles(identityUuid);
  const [pagedInactiveRoles, setPagedInactiveRoles] =
    useState<PagedAdminRoles>();
  const [rolesToAdd, setRolesToAdd] = useState<AdminRoleInformation[]>([]);
  const [rolesToRemove, setRolesToRemove] = useState<AdminRoleInformation[]>(
    []
  );
  const [updateAdmin] = useUpdateAdmin();
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [unsavedModalOpen, setUnsavedModalOpen] = useState(false);
  const [deleteAdminModalOpen, setDeleteAdminModalOpen] = useState(false);
  const [removeAllModalOpen, setRemoveAllModalOpen] = useState(false);
  const [removeAllRoles, setRemoveAllRoles] = useState(false);
  const { config } = useContext(AppContext);

  const [createSaveSuccess, setCreateSaveSuccess] = useState<SuccessData>({
    enabled: false,
    message: `${identity?.preferredName || identity?.firstName} ${
      identity?.lastName
    } was successfully added.`,
  });
  const createSuccess: Success = {
    successData: createSaveSuccess,
    setSuccessData: setCreateSaveSuccess,
  };
  const [removeAllRolesServerErrorData, setRemoveAllRolesServerErrorData] =
    useState<ErrorData>({
      error: false,
      message: '',
    });
  const [removeRolesServerErrorData, setRemoveRolesServerErrorData] =
    useState<ErrorData>({
      error: false,
      message: '',
    });
  const [addRolesServerErrorData, setAddRolesServerErrorData] =
    useState<ErrorData>({
      error: false,
      message: '',
    });
  const serverErrors: Errors[] = [
    {
      key: 'removeAllRolesServerError',
      errorData: removeAllRolesServerErrorData,
      setErrorData: setRemoveAllRolesServerErrorData,
    },
    {
      key: 'removeRolesServerError',
      errorData: removeRolesServerErrorData,
      setErrorData: setRemoveRolesServerErrorData,
    },
    {
      key: 'addRolesServerError',
      errorData: addRolesServerErrorData,
      setErrorData: setAddRolesServerErrorData,
    },
  ];

  const [editSaveSuccess, setEditSaveSuccess] = useState<SuccessData>({
    enabled: false,
    message: `Updates to ${identity?.preferredName || identity?.firstName} ${
      identity?.lastName
    } were successfully saved.`,
  });
  const editSuccess: Success = {
    successData: editSaveSuccess,
    setSuccessData: setEditSaveSuccess,
  };

  const onSave = () => {
    if (config.useRoleDelegatorMockData) {
      setEditSaveSuccess({
        ...editSaveSuccess,
        enabled: true,
      });
      return;
    }

    updateAdmin({
      variables: {
        identityUuid,
        includeRemoveAllRoles: removeAllRoles,
        includeRemoveRoles: !removeAllRoles && rolesToRemove.length > 0,
        removeRoleUuids: rolesToRemove.map((adminRole) => adminRole.role.uuid),
        includeAddRoles: rolesToAdd.length > 0,
        addRoleUuids: rolesToAdd.map((adminRole) => adminRole.role.uuid),
      },
    }).then((result) => {
      refetchAllInactiveRoles();
      refetchAllActiveRoles();

      const removeAllResult = (result as FetchResult).data
        ?.rdRemoveAllAdminRolesFromAdmin;
      if (removeAllResult && 'rdValidationErrors' in removeAllResult) {
        const message = removeAllResult.validationErrors
          .map((e) => e.message)
          .join('; ');
        setRemoveAllRolesServerErrorData({
          error: true,
          message: `Errors occurred while removing all roles: [${message}]`,
        });
      } else if (removeAllResult) {
        setRemoveAllRoles(false);
      }

      const removeResult = (result as FetchResult).data?.rdRemoveRolesFromAdmin;
      if (removeResult && 'rdValidationErrors' in removeResult) {
        const message = removeResult.validationErrors
          .map((e) => e.message)
          .join('; ');
        setRemoveRolesServerErrorData({
          error: true,
          message: `Errors occurred while removing roles: [${message}]`,
        });
      } else if (removeResult) {
        setRolesToRemove([]);
      }

      const addResult = (result as FetchResult).data?.rdAddRolesToAdmin;
      if (addResult && 'rdValidationErrors' in addResult) {
        const message = addResult.validationErrors
          .map((e) => e.message)
          .join('; ');
        setAddRolesServerErrorData({
          error: true,
          message: `Errors occurred while adding roles: [${message}]`,
        });
      } else if (addResult) {
        setRolesToAdd([]);
      }
    });
  };

  const onDiscard = () => {
    history.push('/admins');
  };

  const onCancel = () => {
    if (hasUnsavedChanges) {
      setUnsavedModalOpen(true);
    } else {
      onDiscard();
    }
  };

  const onDeleteAdmin = () => {
    history.push(`/admins?deleted=${identityUuid}`);
  };

  useEffect(() => {
    setPagedActiveRoles(
      paginate(
        allActiveRoles || [],
        (role) => !rolesToRemove.some((r) => r.role.uuid === role.role.uuid),
        (role1, role2) =>
          compareDates(role1.assignedOnDate, role2.assignedOnDate),
        activeRolesPageNumber,
        activeRolesPageSize,
        (arr, total, totalPages) => ({
          content: arr,
          total,
          totalPages,
        })
      )
    );
  }, [
    allActiveRoles,
    activeRolesPageNumber,
    activeRolesPageSize,
    rolesToRemove,
  ]);

  useEffect(() => {
    setPagedInactiveRoles(
      paginate(
        allInactiveRoles || [],
        (_role) => true,
        (role1, role2) =>
          compareDates(role1.assignedOnDate, role2.assignedOnDate),
        inactiveRolesPageNumber,
        inactiveRolesPageSize,
        (arr, total, totalPages) => ({
          content: arr,
          total,
          totalPages,
        })
      )
    );
  }, [allInactiveRoles, inactiveRolesPageNumber, inactiveRolesPageSize]);

  useEffect(() => {
    if (removeAllRoles && !loadingAllActiveRoles && allActiveRoles) {
      setRolesToRemove(allActiveRoles);
    }
  }, [removeAllRoles, loadingAllActiveRoles, allActiveRoles]);

  useEffect(() => {
    setHasUnsavedChanges(!!rolesToAdd.length || !!rolesToRemove.length);
  }, [rolesToAdd, rolesToRemove]);

  useEffect(() => {
    if (!loading && !!identity) {
      setCreateSaveSuccess({
        enabled: queryParams.get('isNew') === 'true',
        message: `${identity.preferredName || identity.firstName} ${
          identity.lastName
        } was successfully added.`,
      });
    }
  }, [loading, identity]);

  return (
    <PageWrapper
      type={TYPE}
      context="admins"
      saveAction={onSave}
      cancelAction={onCancel}
    >
      <BasicPadding padding="sm">
        <DeleteAdminModal
          open={deleteAdminModalOpen}
          setOpen={setDeleteAdminModalOpen}
          onConfirm={onDeleteAdmin}
          onCancel={() => setDeleteAdminModalOpen(false)}
        />
        <RemoveAllRolesModal
          open={removeAllModalOpen}
          setOpen={setRemoveAllModalOpen}
          onConfirm={() => setRemoveAllRoles(true)}
          entityName={`${identity?.preferredName || identity?.firstName} ${
            identity?.lastName
          }`}
        />
        <UnsavedModal
          open={unsavedModalOpen}
          setOpen={setUnsavedModalOpen}
          onConfirm={onDiscard}
        />
        <AdminHeader
          type={TYPE}
          setDeleteAdminModalOpen={setDeleteAdminModalOpen}
          onNavigateAway={onCancel}
        />
        <AlertContainer>
          <ErrorHandler errors={serverErrors} />
          <SuccessHandler success={createSuccess} />
          <SuccessHandler success={editSuccess} />
        </AlertContainer>
        <AdminDetails
          type={TYPE}
          identityUuid={identityUuid}
          onSelect={(_) => null}
        />
        <Typography variant="h2" mt={6}>
          Add a role
        </Typography>
        <AddAdminRoleSearch
          rolesToAdd={rolesToAdd}
          setRolesToAdd={setRolesToAdd}
        />
        <Box sx={{ pb: '64px' }}>
          <RoleTabs
            objectName={
              (identity &&
                `${identity.preferredName || identity.firstName} ${
                  identity.lastName
                }`) ||
              ''
            }
            setRemoveAllModalOpen={setRemoveAllModalOpen}
            allActiveRoles={allActiveRoles}
            pagedActiveRoles={pagedActiveRoles}
            loadingActiveRoles={loadingAllActiveRoles}
            pagedInactiveRoles={pagedInactiveRoles}
            loadingInactiveRoles={loadingAllInactiveRoles}
            rolesToAdd={rolesToAdd}
            setRolesToAdd={setRolesToAdd}
            rolesToRemove={rolesToRemove}
            setRolesToRemove={setRolesToRemove}
            activeRolesPagination={
              <AdminPagination
                totalCount={pagedActiveRoles?.total || 0}
                pageNumber={activeRolesPageNumber}
                setPageNumber={setActiveRolesPageNumber}
                pageSize={activeRolesPageSize}
                setPageSize={setActiveRolesPageSize}
              />
            }
            inactiveRolesPagination={
              <AdminPagination
                totalCount={pagedInactiveRoles?.total || 0}
                pageNumber={inactiveRolesPageNumber}
                setPageNumber={setInactiveRolesPageNumber}
                pageSize={inactiveRolesPageSize}
                setPageSize={setInactiveRolesPageSize}
              />
            }
            setRemoveAllRoles={setRemoveAllRoles}
          />
        </Box>
      </BasicPadding>
    </PageWrapper>
  );
};

export default EditAdmin;
