import { gql } from '@apollo/client';

export const COUNTRY_TIME_ZONE_FRAGMENT = gql`
  fragment CountryTimeZoneFragment on CountryTimeZone {
    id
    timeZoneName
    countryIso2Code
  }
`;

export const GET_TIME_ZONES = gql`
  ${COUNTRY_TIME_ZONE_FRAGMENT}
  query getTimezones {
    timeZones(filter: {}) {
      timeZones {
        nodes {
          ...CountryTimeZoneFragment
        }
      }
    }
  }
`;
