import React from 'react';
import {
  SaveandContinueFlexContainer,
  SaveandContinue,
  CancelButton,
} from './style';
import { FooterProps } from './types';
import { useTranslation } from 'react-i18next';


const Footer: React.FC<FooterProps> = ({ onSave, onCancel, loading ,setLanguageDataChanged,selectedlocale, setDeafultLanguage, currentDefaultLanguage}) => {
  const { t } = useTranslation();
  const { nodes:customerLocalList = [] } = selectedlocale?.customerCoreLocaleMapsByCustomer;
  return (
    <div>
      <SaveandContinueFlexContainer>
        <SaveandContinue
          loading={loading}
          onClick={() => {onSave();setLanguageDataChanged(false)}}
          adminColor
        >
          {t('save_continue')}
        </SaveandContinue>
        {customerLocalList?.length !== 0 && (
            <CancelButton
            color='secondary'
            onClick={() => {onCancel(true) ;setDeafultLanguage(currentDefaultLanguage); setLanguageDataChanged(false)}}
          >
            {t('cancel')}
          </CancelButton>
          )}
      </SaveandContinueFlexContainer>
    </div>
  );
};

export default Footer;
