import { useMutation } from '@apollo/client'
import { UPDATE_CORE_AWARD_LEVELS } from '.'
import { GET_PROGRAM_OFFSETS_BY_PROGRAM_ID } from './index'

export interface UpdateCoreAwardLevel {
  uuid: string
  minPoints: number
  maxPoints: number
}

interface UpdateCoreAwardLevelsRequest {
  customerUuid: string
  updateCoreAwardLevelList: UpdateCoreAwardLevel[]
}

export const useUpdateCoreAwardLevels = (
  setAlert: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const [updateCoreAwardLevels] = useMutation(UPDATE_CORE_AWARD_LEVELS)

  const handleUpdateCoreAwardLevels = async ({
    customerUuid,
    updateCoreAwardLevelList,
  }: UpdateCoreAwardLevelsRequest) => {
    const res = await updateCoreAwardLevels({
      variables: {
        input: {
          customerUuid,
          updateCoreAwardLevelList,
        },
      },
      awaitRefetchQueries: true,
      refetchQueries: [GET_PROGRAM_OFFSETS_BY_PROGRAM_ID],
    })
    if (res?.data?.updateCoreAwardLevels) setAlert(true)
    return res
  }

  return { handleUpdateCoreAwardLevels }
}
