import {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@octanner/prism-core';
import { Add } from '@octanner/prism-icons';
import { useContext, useEffect, useState } from 'react';
import BasicPadding from '../../../../common/BasicPadding';
import {
  AddRoleButton,
  AdminRoleContainer,
  AdminRolesContainer,
  AlertContainer,
  DAButtonContainer,
  NoAdminRolesButton,
  NoAdminRolesContainer,
  TableMT,
  TypographyMB,
  TypographyPZero,
} from './styles';
import { RolesHeadCells, tableRows } from './constants';
import { ErrorHandler } from '../ErrorHandler';
import { useHistory, useLocation } from 'react-router';
import SingleRow from '../components/SingleRow';
import {
  AdminRoleInformation,
  Errors,
  RoleInformation,
  Success,
  SuccessData,
} from '../types';
import {
  SearchContainer,
  SortAscFCM,
  SortDescFCM,
  SortFCM,
  TypographyResultCount,
} from '../styles';
import DeleteRoleModal from '../components/DeleteRoleModal';
import SuccessHandler from '../SuccessHandler';
import { useSearchAdminRoles } from './hooks/searchAdminRoles';
import { AdminRoleSortInput } from './graphql/types';
import AdminRoleSearch from './components/AdminRoleSearch';
import Loading from '../../../../common/Loading';
import { useGetAdminRole } from './hooks/getAdminRole';
import { useDeleteRole } from './hooks/deleteRole';
import { AppContext } from '../../../../utils/context/AppContext';
import AdminPagination from '../components/AdminPagination';

const AdminRoles = () => {
  // eslint-disable-next-line
  const [roleToDelete, setRoleToDelete] = useState<RoleInformation>();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  // eslint-disable-next-line
  const [_options, setOptions] = useState<AdminRoleInformation[]>([]);
  const [sortBy, setSortBy] = useState<AdminRoleSortInput>('NAME');
  const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC'>('ASC');
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchTerms, setSearchTerms] = useState<string[]>([]);
  const { data, loading, refetch } = useSearchAdminRoles({
    page: pageNumber - 1,
    pageSize: pageSize,
    sortBy: sortBy,
    sortDirection: sortDirection,
    searchTerms: searchTerms,
  });
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const deletedUuid = queryParams.get('deleted');
  const { data: deletedRole } = useGetAdminRole(
    deletedUuid || '',
    !deletedUuid
  );
  const [deleteRole] = useDeleteRole();
  const { config } = useContext(AppContext);

  const [deletedSuccessData, setDeletedSuccessData] = useState<SuccessData>({
    enabled: !!deletedRole,
    message: `${deletedRole?.name} was successfully deleted.`,
  });
  const deletedSuccess: Success = {
    successData: deletedSuccessData,
    setSuccessData: setDeletedSuccessData,
    color: 'info',
  };

  // ? Keeping example here for handling errors

  //const [genericError, setError] = useState({
  //  error: true,
  //  message: 'Error message',
  //});
  //const [otherError, setOtherError] = useState({
  //  error: true,
  //  message: 'Another error@!',
  //});

  //let adminRoleErrors: Errors[] = [
  //  { errorData: genericError, handleErrorClose: setError },
  //  { errorData: otherError, handleErrorClose: setOtherError },
  //];

  let adminRoleErrors: Errors[] = [];

  const onDelete = () => {
    if (roleToDelete) {
      if (config.useRoleDelegatorMockData) {
        setDeletedSuccessData({
          enabled: true,
          message: `${roleToDelete.name} was successfully deleted.`,
        });
        setRoleToDelete(undefined);
        return;
      }
      deleteRole({ variables: { roleUuid: roleToDelete.uuid } }).then(() => {
        refetch();
        setDeletedSuccessData({
          enabled: true,
          message: `${roleToDelete.name} was successfully deleted.`,
        });
        setRoleToDelete(undefined);
      });
    }
  };

  useEffect(() => {
    setDeleteModalOpen(!!roleToDelete);
  }, [roleToDelete]);

  return (
    <BasicPadding padding="md">
      <DeleteRoleModal
        open={deleteModalOpen}
        setOpen={setDeleteModalOpen}
        onConfirm={onDelete}
        onCancel={() => {
          setDeleteModalOpen(false);
          setRoleToDelete(undefined);
        }}
        roleName={roleToDelete?.name || ''}
      />
      <AdminRolesContainer>
        <AdminRoleContainer>
          <Typography variant="h1">Admin Roles</Typography>
          <DAButtonContainer>
            <AddRoleButton
              adminColor
              onClick={() => history.push('/admin-roles/new')}
              startIcon={<Add />}
            >
              Add a role
            </AddRoleButton>
          </DAButtonContainer>
        </AdminRoleContainer>
        <TypographyMB>
          Admin roles can be used to grant access to Culture Cloud global tools
          and Culture Cloud client admin services.
        </TypographyMB>
        <AlertContainer>
          <ErrorHandler errors={adminRoleErrors} />
          <SuccessHandler success={deletedSuccess} />
        </AlertContainer>
        <SearchContainer>
          <AdminRoleSearch setSearchTerms={setSearchTerms} />
        </SearchContainer>
        {searchTerms.length > 0 && (
          <TypographyResultCount>
            Showing {data?.total} {data?.total === 1 ? 'result' : 'results'}
          </TypographyResultCount>
        )}
        {(loading && <Loading />) || (
          <TableMT>
            <TableHead>
              <TableRow>
                {RolesHeadCells.map(({ id, label, align, sortKey }) => (
                  <TableCell align={align} key={id}>
                    <TypographyPZero variant="h5">
                      {label}
                      {!!sortKey &&
                        (sortBy === sortKey ? (
                          sortDirection === 'ASC' ? (
                            <SortAscFCM
                              onClick={() => setSortDirection('DESC')}
                            />
                          ) : (
                            <SortDescFCM
                              onClick={() => setSortDirection('ASC')}
                            />
                          )
                        ) : (
                          <SortFCM
                            onClick={() => {
                              setSortBy(sortKey);
                              setSortDirection('ASC');
                            }}
                          />
                        ))}
                    </TypographyPZero>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.content &&
                data.content.map((rowData) => (
                  <>
                    <TableRow key={rowData.role.uuid}>
                      <SingleRow
                        testKey="adminRoles"
                        rowData={rowData}
                        getId={(rowData) => rowData.role.uuid}
                        columnDisplayDefs={tableRows}
                        showDuplicate
                        onDelete={() => setRoleToDelete(rowData.role)}
                      />
                    </TableRow>
                  </>
                ))}
            </TableBody>
          </TableMT>
        )}
        {!loading && (data?.content?.length || 0) < 1 && (
          <NoAdminRolesContainer>
            <Typography variant="h3">No admin roles yet</Typography>
            <Typography>
              When you add an admin role it will show up here.
            </Typography>
            <NoAdminRolesButton
              variant="text"
              onClick={() => history.push('/admin-roles/new')}
            >
              Add a role
            </NoAdminRolesButton>
          </NoAdminRolesContainer>
        )}
        <AdminPagination
          totalCount={data?.total || 0}
          pageNumber={pageNumber}
          pageSize={pageSize}
          setPageNumber={setPageNumber}
          setPageSize={setPageSize}
        />
      </AdminRolesContainer>
    </BasicPadding>
  );
};

export default AdminRoles;
