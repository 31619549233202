import { useMutation, FetchResult, MutationResult } from '@apollo/client'
import {
  GET_PROGRAM_OFFSETS_BY_PROGRAM_ID,
  YB_DELETE_ACCRUAL_POINTS_REGION,
} from '.'

type Data = { ybDeleteAccrualPointsRegion: { id: string } }
type Variables = { input: { accrualPointsRegionId: string } }
type CustomMutate = (
  budgetGroupId: string,
  programId: string
) => Promise<FetchResult<Data>>

export default function useDeleteAccrualPointsConfig(): [
  CustomMutate,
  MutationResult<Data>
] {
  // Pass mutation to useMutation
  const [mutate, result] = useMutation<Data, Variables>(
      YB_DELETE_ACCRUAL_POINTS_REGION
    ),
    customMutate: CustomMutate = (budgetGroupId: string, programId: string) => {
      return mutate({
        variables: { input: { accrualPointsRegionId: budgetGroupId } },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: GET_PROGRAM_OFFSETS_BY_PROGRAM_ID,
            variables: { programId },
          },
        ],
      })
    }
  return [customMutate, result]
}
