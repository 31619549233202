import React, { useState, useContext } from 'react';
import { Chip, Search, SearchOptionCard } from '@octanner/prism-core';
import useBusinessUnitSearch from '../utils/useBusinessUnitSearch';
import { BusinessUnit } from '@octanner/prism-core/Header/interfaces';
import CustomerIdContext from '../utils/CustomerIdContext';

interface BusinessUnitSearchProps {
  defaultApprover?: boolean;
  businessUnit?: BusinessUnit;
  setBusinessUnit?: (businessUnit: BusinessUnit) => void;
  businessUnitUuids?: string[];
  setBusinessUnitUuids?: (businessUnitUuid: string) => void;
}

const MIN_CHARACTERS = 3;

const BusinessUnitSearch: React.FC<BusinessUnitSearchProps> = ({
  defaultApprover = false,
  businessUnit,
  setBusinessUnit,
  businessUnitUuids,
  setBusinessUnitUuids,
}) => {
  const defaultBusinessUnit = defaultApprover && 'Any without assignment';
  const { customerId } = useContext(CustomerIdContext);
  const [searchText, setSearchText] = useState('');
  const { loading, data } = useBusinessUnitSearch({
    customerId: customerId,
    searchContent: searchText,
  });
  const [errorSearchEmpty, setErrorSearchEmpty] = useState(false);
  const [duplicateBusinessError, setDuplicateBusinessError] = useState(false);
  const hasMinChars = searchText && searchText.length >= MIN_CHARACTERS;

  const searchEmptyCheck = (identity) => {
    setErrorSearchEmpty(!(identity || businessUnit?.buName));
  };

  const onChange = (e, selection) => {
    if (errorSearchEmpty) {
      searchEmptyCheck(selection);
    }
    if (selection) {
      setBusinessUnitUuids(selection?.buUuid);
      businessUnitUuids?.includes(selection.buUuid)
        ? setDuplicateBusinessError(true)
        : setDuplicateBusinessError(false);
    }

    setBusinessUnit(selection);
  };

  const inputValue = (() => {
    if (defaultApprover) {
      return [defaultBusinessUnit];
    }
    return businessUnit?.buName || null;
  })();

  const helperText = (() => {
    if (defaultApprover) {
      return 'An approver has been assigned for any without an assigned approver.';
    }
    if (duplicateBusinessError) {
      return 'This business unit is already in use.';
    }
    return errorSearchEmpty ? 'This field is required.' : null;
  })();

  return (
    <Search
      disabled={defaultApprover}
      noOptionsText={
        hasMinChars
          ? `No one found matching ${searchText}`
          : 'Please enter at least three (3) characters to search.'
      }
      autoHighlight
      fullWidth
      multiple={defaultApprover}
      placeholder={
        !defaultApprover ? 'Search for a business unit to assign' : ''
      }
      options={data?.searchBusinessUnits?.businessUnits || []}
      data-testid={'businessUnit-search'}
      loading={loading}
      inputValue={searchText}
      onInputChange={(e, text) => setSearchText(text)}
      getOptionLabel={(option) => (option.length ? option : '')}
      onBlur={() => searchEmptyCheck(null)}
      error={errorSearchEmpty || duplicateBusinessError}
      helperText={helperText}
      renderTags={() =>
        defaultApprover && (
          <Chip
            sx={{ m: '3px', backgroundColor: 'white' }}
            label={defaultBusinessUnit}
          ></Chip>
        )
      }
      renderOption={(props, option: BusinessUnit) => (
        <SearchOptionCard {...props} title={option.buName} />
      )}
      onChange={onChange}
      value={inputValue}
    />
  );
};

export default BusinessUnitSearch;
