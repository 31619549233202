import CreateRoleBody from '../components/CreateRoleBody';

const CreateAdminRole = () => {
  return (
    <CreateRoleBody
      type="add"
      headerText="Create Admin Role"
      headerDescription="Create a new admin role."
    />
  );
};

export default CreateAdminRole;
