import { Button } from '@octanner/prism-core';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Link as RouterLink, useRouteMatch } from 'react-router-dom';
import BasicPadding from '../../../common/BasicPadding';
import ScrollLoader from '../../../common/ScrollLoader';
import { CustomerContext } from '../../../utils/context/CustomerContext';
import { useGetProgramsByCustomer } from '../hooks';
import { ProgramListViewModel, toProgramListVM } from '../models/models';
import { ProgramHeader, YourProgamsMargin } from '../styles';
import ProgramListRow from './ProgramListRow';

const ProgramsList = () => {
  const match = useRouteMatch();
  const [allVMs, setAllVMs] = useState<ProgramListViewModel[]>([]);

  const {
    customer: { id: customerId },
  } = useContext(CustomerContext);
  const { data, loading, fetchMore, refetch } =
    useGetProgramsByCustomer(customerId);
  const programs = useMemo(
    () => data?.coreProgramsByCustomer.nodes || [],
    [data]
  );
  useEffect(() => {
    refetch();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    const vms = programs.map(toProgramListVM);
    setAllVMs(vms);
  }, [programs]);

  return (
    <BasicPadding>
      <ProgramHeader>
        <div>
          <YourProgamsMargin variant="h1">Your Programs</YourProgamsMargin>
        </div>

        {allVMs?.length > 0 ? (
          <div>
            <Button
              disableRipple
              component={RouterLink}
              // @ts-ignore
              to={`${match.url}/new`}
              data-test="programs:new"
            >
              Add New Program
            </Button>
          </div>
        ) : (
          ''
        )}
      </ProgramHeader>

      {!data && loading ? (
        <ProgramListRow match={match} allVMs={[]} loading={loading} />
      ) : (
        <div>
          <ProgramListRow match={match} allVMs={allVMs} loading={loading} />
          <ScrollLoader load={loading ? () => {} : fetchMore} />
        </div>
      )}
    </BasicPadding>
  );
};

export default ProgramsList;
