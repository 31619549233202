import { useState, useEffect } from 'react';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

export default (translation) => {
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    i18n
      .use(initReactI18next) // passes i18n down to react-i18next
      .init({
        // For simplicity the translation key is set to 'en' regardless
        // of what language is returned from the translations-api.
        resources: {
          en: {
            translation
          }
        },
        lng: 'en',
        keySeparator: false,
        interpolation: {
          prefix: '{',
          suffix: '}',
          escapeValue: false
        }
      });
    setLoaded(true);
  }, [translation]);

  return loaded;
};
