import { FlexBetweenDiv } from '../styles';
import { Button } from '@octanner/prism-core';
import Typography from '@octanner/prism-core/Typography';
import { Errors, Success } from '../../types';
import SuccessHandler from '../../SuccessHandler';
import { ErrorHandler } from '../../ErrorHandler';
import { ArrowLeft, Trash } from '@octanner/prism-icons';

const headerTitleMap: { [key in string]?: string } = {
  add: 'Add Admin',
  edit: 'Edit Admin',
};

const headerDescriptionMap: { [key in string]?: string } = {
  add: 'Create the permissions for a given admin role.',
  edit: 'Edit the permissions for a given admin role.',
};

interface AdminHeaderProps {
  type: 'add' | 'edit';
  setDeleteAdminModalOpen?: (open: boolean) => void;
  onNavigateAway: () => void;
}

const AdminHeader = ({
  type,
  setDeleteAdminModalOpen,
  onNavigateAway,
}: AdminHeaderProps) => {
  return (
    <>
      <FlexBetweenDiv>
        <div>
          <Button
            variant="text"
            data-test={`admins:${type}:back`}
            onClick={onNavigateAway}
            startIcon={<ArrowLeft />}
          >
            Back to admins
          </Button>
          <Typography variant="h1" mt={4} mb={2}>
            {headerTitleMap[type] || ''}
          </Typography>
          <Typography>{headerDescriptionMap[type] || ''}</Typography>
        </div>
        <div>
          {type === 'edit' && setDeleteAdminModalOpen && (
            <>
              <Button
                variant="text"
                startIcon={<Trash />}
                buttonType="danger"
                onClick={() => setDeleteAdminModalOpen(true)}
              >
                Delete admin
              </Button>
            </>
          )}
        </div>
      </FlexBetweenDiv>
    </>
  );
};

export default AdminHeader;
