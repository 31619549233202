import styled from '@emotion/styled';
import {
  IconButton,
  Select,
  TableCell,
  Typography,
} from '@octanner/prism-core';
import colors from '../../../../common/constants/Colors';
import { marginB } from './types';

export const AlignedTextBox = styled.div`
  height: 64px;
  display: flex;
  align-items: center;
  padding-left: 60px;
  width: 400px;
`;

export const FlexCenterDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const FlexCenterEvenlyDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: 0;
`;

export const FlexColumnDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  white-space: nowrap;
`;

export const SpecificWidthSelect = styled(Select)`
  width: 288px;
  margin: 4px;
  white-space: nowrap;
  padding: 0px;
`;

export const VerticalAlignedIconButton = styled(IconButton)`
  vertical-align: top;
`;

export const SpecificWidthCell = styled(TableCell)<marginB>`
  padding-right: 10px;
  border-bottom: ${(props) => (props.open ? 'none' : '')};
  padding-left: 8px;
`;

export const MainText = styled(Typography)`
  font-weight: bold;
  width: '125px';
`;

export const SubText = styled(Typography)`
  color: ${colors.gray400};
  white-space: nowrap;
`;

export const SalesOrgWidth = styled.div`
  width: 130px;
`;

export const SalesOrgTitle = styled(Typography)`
  margin-top: 40px;
`;

export const SwitchMargin = styled.div`
  margin-right: 4px;
`;

export const PaddingZero = styled(TableCell)<marginB>`
  padding: 0px;
  border-bottom: ${(props) => (props.open ? 'none' : '')};
  width: 100px;
`;

export const HideBorder = styled(TableCell)<marginB>`
  border-bottom: ${(props) => (props.open ? 'none' : '')};
  padding: 0px;
`;

export const PLDiv = styled.div`
  padding-left: 8px;
`;

export const MBDiv = styled.div`
  margin-bottom: 24px;
`;

export const SLContainer = styled.div`
  display: flex;
`;

export const RecommendedSalesOrg = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: #ababae;
`;

export const TypographyWP = styled(Typography)`
  width: 319px;
  padding: 10px;
`;

export const TableCellW = styled(TableCell)`
  width: 100%;
`;

export const SelectW = styled(Select)`
  width: 319px;
`;
