import { useQuery } from '@apollo/client'
import BudgetGroupCountry from '../common/models/BugetGroupCountry'
import { YbAccrualPointsRegion } from '../common/models/YbAccrualPointsRegion'
import { useMemo } from 'react'
import { GET_BUDGET_GROUP_REGIONS } from '.'

interface Response {
  ybProgramOffsetsByProgramId: {
    program: { accrualPointsRegions: YbAccrualPointsRegion[] }
  }
}

const useGetBudgetGroupCountries = (
  programId: string
): BudgetGroupCountry[] => {
  const { data } = useQuery<Response | undefined>(GET_BUDGET_GROUP_REGIONS, {
      variables: { input: { programId: programId } },
    }),
    result = useMemo(
      () =>
        (data?.ybProgramOffsetsByProgramId.program.accrualPointsRegions || [])
          .map((group) =>
            group.countries.map((country) => ({
              country,
              group: {
                id: group.id,
                defaultRegion: group.defaultRegion,
                name: group.name,
              },
            }))
          )
          .reduce(
            (aggregate, arrayBudgetGroupCountries) => [
              ...aggregate,
              ...arrayBudgetGroupCountries,
            ],
            []
          )
          .sort((a, b) =>
            a.country.countryName.localeCompare(b.country.countryName)
          ),
      [data?.ybProgramOffsetsByProgramId.program.accrualPointsRegions]
    )

  return result
}

export default useGetBudgetGroupCountries
