import { useHistory, useLocation, useParams } from 'react-router';
import { VibeDetails, VibeParams } from './models';

export const useNavigate = (customerId: string | undefined) => {
  const history = useHistory();
  const params = useParams<VibeParams>();
  const location = useLocation();

  const navigate = ({
    brandId,
    vibeId,
    parentVibeId,
    filteredSegments,
    name,
    isDeleted,
    loadTree,
    vibeNames,
    usedUnits,
  }: VibeDetails) => {
    if (isDeleted || loadTree) {
      const stateObj = loadTree ? { loadTree } : { isDeleted, name };
      history.push({
        pathname: `/customer/${customerId}/themes`,
        state: stateObj,
      });
    } else if (vibeId) {
      history.push({
        pathname: `/customer/${customerId}/themes/${vibeId}`,
        state: {
          brandId,
          parentVibeId,
          filteredSegments,
          vibeNames,
          usedUnits,
        },
      });
    } else {
      history.push({
        pathname: `/customer/${customerId}/themes/new`,
        state: {
          brandId,
          parentVibeId,
          filteredSegments,
          vibeNames,
          usedUnits,
        },
      });
    }
  };

  return {
    navigate,
    location,
    params,
    history,
  };
};
