import { Tag, Typography } from '@octanner/prism-core';

import {
  findStatus,
  programToViewModel,
  ProgramViewModel,
  Status,
} from '../../pages/programs/models/models';

import { ProgramProps, StatusProps } from './types';

const isProgramProps = (object: any): object is ProgramProps =>
  'program' in object;

const getStatus = (props: ProgramProps | StatusProps): Status => {
  if (isProgramProps(props)) {
    const vm = programToViewModel(props.program);
    return findStatus(vm);
  }
  const status: Status = {
    text: props.status,
    severity: 'success',
    isStatus: (vm: ProgramViewModel) => true,
  };

  if (props.status === 'Inactive') {
    status.severity = 'warning';
    return status;
  } else {
    return findStatus(props.status);
  }
};

export default function StatusLabel(props: ProgramProps | StatusProps) {
  const { dataTest, className } = props;
  const status = getStatus(props);
  if (!status) {
    return <Typography data-test={dataTest}>Error</Typography>;
  }
  return (
    <Tag data-test={dataTest} className={className} variant={status.severity}>
      {status.text}
    </Tag>
  );
}
