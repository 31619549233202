import styled from '@emotion/styled';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@octanner/prism-core';
import React, { useContext, useState } from 'react';
import { CustomTextContext } from '../../Contexts/CustomTextContext';
import { defaults } from '../../utils/default';


const RadioButtonContainerBox = styled.div`
margin-top: 30px;
margin-left: 8px;
`;

const RadioFormControlLabel = styled(FormControlLabel)`
margin-right: 36px;
`;

const RadioButtonContainer: React.FC = () => {

  const {
    searchRequestObject, setSearchRequestObject, searchLoading, setDataNotFound, 
    setSearchData } = useContext(CustomTextContext);

  const searchByChange = (event: React.ChangeEvent<{ value: string }>) => {
    setSearchRequestObject({
      ...searchRequestObject,
      type: event.target.value,
      value: ''
    });
    setSearchData(null);
    setDataNotFound(false);
  }

  return (
    <RadioButtonContainerBox>
      <FormControl>
        <RadioGroup row aria-label='searchBy' name='searchBy'
          value={searchRequestObject.type} onChange={searchByChange}>
          <RadioFormControlLabel value='stringId' control={<Radio />} label={defaults.search_id} disabled={searchLoading} />
          <RadioFormControlLabel value='stp' control={<Radio />} label={defaults.search_by_stp} disabled={searchLoading} />
          <RadioFormControlLabel value='requestedBy' control={<Radio />} label={defaults.search_by_requested_by} disabled={searchLoading} />
          <RadioFormControlLabel value='requesterEmail' control={<Radio />} label={defaults.search_by_requested_by_email} disabled={searchLoading} />
        </RadioGroup>
      </FormControl>
    </RadioButtonContainerBox>
  )
}

export default RadioButtonContainer;