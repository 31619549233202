import { Box } from '@octanner/prism-core';
import { ProductCategoryProps } from '../../types';
import { MainSettings } from '@octanner/yearbook-program-settings';
import { useHistory } from 'react-router-dom';

const ProductCategorySettings = ({
  programId = '',
  customerId = '',
  type,
}: ProductCategoryProps) => {
  const history = useHistory();

  //? Yearbook handlers

  const baseCustomerUrl = `/customer/${customerId}/programs/${programId}`;

  const handleEditGroupClick = (groupId?: string) => {
      groupId
        ? history.push(`${baseCustomerUrl}/celebrations/${groupId}`)
        : history.push(`${baseCustomerUrl}/celebrations/new`);
    },
    onEditAccrualPointRegion = (regionId: string) => {
      history.push(`${baseCustomerUrl}/budget-groups/${regionId}`);
    },
    onAddRegion = () => {
      history.push(`${baseCustomerUrl}/budget-groups/new`);
    };
  return (
    <>
      {/* later Phase here */}
      {type === 'edit' && (
        <Box sx={{ my: 3 }}>
          <MainSettings
            programId={programId}
            onEditGroupClick={handleEditGroupClick}
            onEditAccrualPointRegion={onEditAccrualPointRegion}
            onAddRegion={onAddRegion}
          />
        </Box>
      )}
    </>
  );
};

export default ProductCategorySettings;
