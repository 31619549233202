import { gql, useMutation, useQuery } from '@apollo/client';
import { BaseItem } from './universalSearch/types';

export const GET_SEARCH_CONFIG = gql`
  query CcWebUserSearchConfig($input: CCWebUserSearchConfigInput!) {
    ccWebUserSearchConfig(input: $input) {
      fields {
        enabled
        fieldName
        order
        translationStringId
      }
    }
  }
`;
type SearchConfigInput = { customerUuid: string };
type SearchConfigResponse = {
  ccWebUserSearchConfig: {
    fields: BaseItem[];
  };
};

export const useGetSearchConfig = (input: SearchConfigInput) => {
  const searchConfig = useQuery<
    SearchConfigResponse,
    { input: SearchConfigInput }
  >(GET_SEARCH_CONFIG, {
    variables: { input },
  });

  const refetch = () => searchConfig.fetchMore({ variables: { input } });

  return { ...searchConfig, refetch };
};
export const UPDATE_SEARCH_CONFG_ORDER = gql`
  mutation CcWebUpdateSearchFieldOrder(
    $input: CCWebUpdateSearchFieldOrderInput!
  ) {
    ccWebUpdateSearchFieldOrder(input: $input) {
      fields {
        translationStringId
        order
        fieldType
        fieldName
        enabled
      }
    }
  }
`;

export type FieldTypes =
  | 'EMAIL_ADDRESS'
  | 'BUSINESS_UNIT'
  | 'LEADER_NAME'
  | 'EMPLOYEE_ID'
  | 'PARENT_BUSINESS_UNIT'
  | 'JOB_TITLE'
  | 'WORK_LOCATION'
  | 'WORK_LOCATION_CONDENSED';
interface UpdateSearchOrderInput {
  input: {
    fieldTypes: FieldTypes[];
    customerUuid: string;
  };
}
interface UpdateSearchOrderResponse {
  ccWebUpdateSearchFieldOrder: {
    fields: BaseItem[];
  };
}

export const useUpdateSearchOrder = () =>
  useMutation<UpdateSearchOrderResponse, UpdateSearchOrderInput>(
    UPDATE_SEARCH_CONFG_ORDER
  );

interface UpdateSearchOrderToggleInput {
  input: {
    fieldType: FieldTypes;
    customerUuid: string;
  };
}

interface UpdateSearchOrderEnableResponse {
  ccWebEnableUserSearchFieldType: {
    fields: BaseItem[];
  };
}

interface UpdateSearchOrderDisableResponse {
  ccWebDisableUserSearchFieldType: {
    fields: BaseItem[];
  };
}

export const UPDATE_SEARCH_CONFIG_ENABLE = gql`
  mutation CcWebEnableUserSearchFieldType(
    $input: CCWebUpdateEnableUserSearchFieldTypeInput!
  ) {
    ccWebEnableUserSearchFieldType(input: $input) {
      fields {
        translationStringId
        order
        fieldType
        fieldName
        enabled
      }
    }
  }
`;

export const UPDATE_SEARCH_CONFIG_DISABLE = gql`
  mutation CcWebDisableUserSearchFieldType(
    $input: CCWebUpdateEnableUserSearchFieldTypeInput!
  ) {
    ccWebDisableUserSearchFieldType(input: $input) {
      fields {
        translationStringId
        order
        fieldType
        fieldName
        enabled
      }
    }
  }
`;
export const useUpdateSearchEnable = () =>
  useMutation<UpdateSearchOrderEnableResponse, UpdateSearchOrderToggleInput>(
    UPDATE_SEARCH_CONFIG_ENABLE
  );
export const useUpdateSearchDisable = () =>
  useMutation<UpdateSearchOrderDisableResponse, UpdateSearchOrderToggleInput>(
    UPDATE_SEARCH_CONFIG_DISABLE
  );

export const UPDATE_SEARCH_CONFIG_DISABLE_ALL = gql`
  mutation CcWebDisableUserSearchFieldTypes($customerUuid: UUID!) {
    ccWebDisableUserSearchFieldTypes(customerUuid: $customerUuid) {
      fields {
        translationStringId
        order
        fieldType
        fieldName
        enabled
      }
    }
  }
`;
interface UpdateSearchOrderDisableAllResponse {
  ccWebDisableUserSearchFieldTypes: {
    fields: BaseItem[];
  };
}
export const useUpdateSearchDisableAll = () =>
  useMutation<UpdateSearchOrderDisableAllResponse, { customerUuid: string }>(
    UPDATE_SEARCH_CONFIG_DISABLE_ALL
  );
