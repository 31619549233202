export const defaults = {
  client_configuration: 'Client Configuration',
  client_financial_details: 'Client and Financial Details',
  country_sales_org_setup: 'Country and Sales Org Setup',
  languages: 'Languages',
  language: 'Language',
  edit: 'Edit',
  code: 'Code',
  error: 'Error',
  default: 'Default',
  sales_org: 'Sales Org',
  currency: 'Currency',
  client_address_details: 'Client Address Details',
  search_country: 'Search Country',
  search_countries: 'Search countries',
  save_client_name: 'Save client name',
  client_name: 'Client Name',
  activate_client: 'Activate Client',
  not_active: 'Inactive',
  inactive: 'Not Active',
  recommended: 'recommended',
  stp: 'Client STP',
  customerUuid: 'Core Id',
  search_sales_territory: 'Search Sales Territory',
  client_contact_information: 'Client Contact Information',
  primary_contact_name: 'Primary Contact Name',
  primary_contact_name_with_colon: 'Primary Contact Name:',
  email: 'Email',
  phone: 'Phone',
  fax: 'Fax',
  fax_1: 'Fax:',
  phone_1: 'Phone:',
  email_1: 'Email:',
  optional: 'Optional',
  save_contact_details: 'Save contact details',
  invoicing_details: 'Invoicing Details',
  client_information: 'Client Information',
  client_launch_date: 'Client launch date',
  edit_section: 'Edit section',
  country: 'Country',
  country_1: 'Country:',
  payment_terms: 'Payment Terms',
  payment_terms_1: 'Payment Terms:',
  issue_resolution_number: 'Issue Resolution Number',
  issue_resolution_number_1: 'Issue Resolution Number:',
  invoice_medium: 'Invoice Medium',
  invoice_medium_1: 'Invoice Medium:',
  billing_schedule: 'Billing Schedule',
  billing_schedule_1: 'Billing Schedule:',
  billing_type: 'Billing Type',
  billing_type_1: 'Billing Type:',
  save_invoice: 'Save invoice details',
  address: 'Address:',
  address_1: 'Address 1/PO Box',
  address_2: 'Address 2',
  address_3: 'Address 3',
  city: 'City',
  state_province_region: 'State/Province/Region',
  postal_zip_code: 'Postal/Zip Code',
  city_state_province: 'City, State/Province/Region, Postal/Zip Code',
  sales_territory: 'Sales Territory',
  fulfillments: 'Fulfillment Countries and sales orgs',
  cancel: 'Cancel',
  save_continue: 'Save and continue',
  deafult_language: 'Set Default Language and Languages to be Translated',
  selected: 'Selected',
  unselected: 'Unselected',
  loading: 'Loading...',
  set_deafult_language: 'Set Default Language',
  set_default_country: 'Set Default Country',
  select_language: 'Select a default language from your selected languages',
  select_country: 'Select a default country from your selected countries.',
  atleast_one_language: 'At least one language needs to be selected and set as a default',
  search_language: 'Search language',
  edit_language_list: 'Edit language list',
  edit_country_list: 'Edit country list',
  client_status: 'Client status',
  required_error: 'This is a required field.',
  many_characters: 'Too many characters.',
  filter_countries: 'You’ve selected no countries to filter.',
  filter_languages: 'You’ve selected no languages to filter.',
  selected_countries: 'Check ‘Selected’, ‘Unselected’ or both to view countries',
  selected_languages: 'Check ‘Selected’, ‘Unselected’ or both to view languages.',
  required_ir: 'An IR number is required for this change.',
  email_error: 'Please enter valid email address.',
  country_sales_org: 'Select countries and sales orgs to define how orders are invoiced.',
  warn_change_payment_terms: 'Change payment terms',
  warn_change_payment_terms_title: 'Changing Payment Terms?',
  warn_change_payment_msg: 'If you are changing payment terms, you will need a new issue resolution number. The current number will not be applicable.',
  warn_sales_org: 'At least one country needs to be selected and set as a default.',
  warn_empty_sales_org: 'At least one country needs to be selected',
  discard : 'Discard',
  warning_text :'Going away from this tab will discard any unsaved changes.',
  client_financial_warning_alert :'Client and Financial Details is missing required information.',
  sales_org_warning_alert :'Country and Sales Org Setup is missing required information.',
  language_warning_alert :'Languages Tab is missing required information.',
  cant_undo_warning_text:'This can’t be undone. Are you sure?',
  failed_warning_text:'Activation failed. Try to activate again.',
  change_status:'Change status',
  implementation_failed_text:'Move to Implementation failed, Try again',
  postal_code_error:'You have not entered a postal code or postal code for the post box',
  select_atleast_one_language:'At least one language needs to be selected and set as a default.',
  sold : 'Sold',
  implementation : 'Implementation',
  active : 'Active',
  unsaved_changes : 'You have unsaved changes.',
  unsaved_changes_text : 'You have unsaved changes. What would you like to do?',
  continue_implement : 'Continue Move to Implementation?',
  unauthorized_error : 'You do not have permission to make changes.',
  apiErrorMessage: 'Something went wrong',
  save_default_country: 'Save default country',
  save_default_language: 'Save default language',
  moveToActive: 'Move to active',
  moveToImplementation: 'Move to implementation',
  moveToLost: 'Move to lost',
  changeStatusToLost :'Change status to lost?',
  lostDescription: 'This client will be removed from any banks or programs that it is associated with. Are you sure you would like to continue?',
  lost : 'Lost',
  yes : 'Yes',
  client_usage_type : 'Client usage type',
  client_usage_type_modal_title : 'Change client usage type to {clientUsageType}?',
  client_usage_type_modal_description : 'Are you sure that you want to change the client usage type from {clientUsageType} to {clientUsageType1}?',
  client_status_type_modal_description : 'Change status to {customerStatus}',
  add_core_values : 'Add Core Values',
  enter_name:'Enter name of value',
  char_limit_warning: 'Text length exceeds the maximum allowed character limit',
  duplicate_name_error: 'This name already exists. Please enter a unique name for the value.',
  add_value : 'Add Value',
  core_values:'Core Values',
  no_core_values : 'No core values available. Please add a new value.',
  activate_all : 'Activate all',
  deactivate_all : 'Deactivate all',
  save : 'Save',
  remove : 'Remove',
  core_value_added_sucessfully : 'Core value added successfully.',
  core_value_updated_sucessfully : 'Core value renamed to {name} successfully.',
  activate : 'Activate',
  deactivate : 'Deactivate',
  activate_all_confirm : 'Are you sure you want to activate all core values?',
  deactivate_all_confirm : 'Are you sure you want to deactivate all core values?',
  activate_confirm : 'Are you sure you want to activate this core value?',
  deactivate_confirm : 'Are you sure you want to deactivate this core value?',
  remove_core_value:'Are you sure you want to remove the core value?',
  remove_core_value_error : 'An error occurred when trying to remove value. Please try again.',
  name_of_value : 'Name of value',
  modal_confirmation : 'Are you sure?',
  removed_core_value : '{name} removed successfully.',
  core_value_status_updated : '{name} {status} successfully.',
  add_another : 'Add another',
};
