(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("react-router-dom"), require("@emotion/styled"), require("@octanner/prism-core"), require("@octanner/prism-icons"), require("@apollo/client"), require("@octanner/prism-icons/ArrowDown"));
	else if(typeof define === 'function' && define.amd)
		define(["react", "react-router-dom", "@emotion/styled", "@octanner/prism-core", "@octanner/prism-icons", "@apollo/client", "@octanner/prism-icons/ArrowDown"], factory);
	else if(typeof exports === 'object')
		exports["@octanner/cc-lang-ui"] = factory(require("react"), require("react-router-dom"), require("@emotion/styled"), require("@octanner/prism-core"), require("@octanner/prism-icons"), require("@apollo/client"), require("@octanner/prism-icons/ArrowDown"));
	else
		root["@octanner/cc-lang-ui"] = factory(root["react"], root["react-router-dom"], root["@emotion/styled"], root["@octanner/prism-core"], root["@octanner/prism-icons"], root["@apollo/client"], root["@octanner/prism-icons/ArrowDown"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__155__, __WEBPACK_EXTERNAL_MODULE__442__, __WEBPACK_EXTERNAL_MODULE__729__, __WEBPACK_EXTERNAL_MODULE__628__, __WEBPACK_EXTERNAL_MODULE__163__, __WEBPACK_EXTERNAL_MODULE__971__, __WEBPACK_EXTERNAL_MODULE__249__) => {
return 