import styled from '@emotion/styled';
import { Alert, Avatar, Breadcrumbs, Button, Grid, Link, Typography } from '@octanner/prism-core';
import { ArrowLeft } from '@octanner/prism-icons';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { MaterialStringsContext } from '../../Contexts/MaterialStringsContext';
import { backLinks } from '../../utils/constants';
import { defaults } from '../../utils/default';
import StyledTextField from '../../utils/styledComponents';
import { AlertMessage, MaterialDescriptionDetailsProps, SearchVariables } from '../../utils/types';

const Remarks = styled(Typography)`
  display: flex;
  align-items: center;
`;

const NotesHeader = styled(Typography)`
  display: flex;
  align-items: center;
  margin-right: 16px;
`;

const FlexContainerColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const NotesHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const MessageAlert = styled(Alert)`
  margin-bottom: 12px;
`;

const NotesTextField = styled.div`
  margin-top: 10px;
  width: 50%;
`;

const DetailsContainer = styled.div`
  margin-left: 12px;
  width: 70%;
`;

const ButtonContainer = styled.div`
  margin : 8px 0px;
`;

const NotesContainer = styled.div`
  background: #f8f8f8;
  padding: 8px 16px;
  max-width: 50%;
  margin-top: 14px;
`;

const StringDetailsContainer = styled.div`
  margin-bottom: 24px;
`;

const BackLink = styled(Breadcrumbs)`
  margin-bottom: 24px;
`;

const FlexContainer = styled.div`
  display: flex;
  margin-top: 12px;
`;

const MaterialDescriptionDetails: React.FC<MaterialDescriptionDetailsProps> = ({
  getMaterialDescriptionByFilters,
  updateMaterialDescription,
  setSaveAllLoading,
  updatingMaterialDescription,
  notes,
  setNotes,
  changedNotes,
  setChangedNotes }) => {

  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const backLinkType = queryParams.get('type');
  const [backLink, setBackLink] = useState<{ title: string, path: string }>(null);
  const [disableBackLink, setDisableBackLink] = useState(false);
  const { mmId } = useParams();
  const {
    searchData, setSearchData, messages, setMessageText, setShowFooter, openEditEnglishDescriptionModal,
    setMmId } = useContext(MaterialStringsContext);

  const [showNoteEdit, setShowNoteEdit] = useState<boolean>(false);

  useEffect(() => {
    if (backLinkType) {
      setBackLink(backLinks[backLinkType])
    }
  }, [backLinkType]);

  const handleNoteCancel = () => {
    setShowNoteEdit(false);
    setChangedNotes(notes);
  }

  const backToSearch = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    if (disableBackLink) {
      return;
    }
    setDisableBackLink(true);
    setMessageText('errorMessage', '');
    setMessageText('editSuccessMessage', '');
    setMessageText('requestSuccessMessage', '');
    setShowFooter(false);
    if (backLinkType) {
      setDisableBackLink(false);
      history.push(backLink.path);
      return;
    }
    if (!searchData) {
      const variables: Partial<SearchVariables> = {
        mmId: parseInt(mmId),
        pageInfo: {
          pageNumber: 1,
          pageSize: 1
        }
      }
      setMmId(mmId)
      getMaterialDescriptionByFilters({
        variables: variables
      }).then((response) => {
        setSearchData(response.data);
      })
    }
    setDisableBackLink(false);
    history.push('/material-description');
  }

  const handleNoteSave = () => {
    if (notes.trim() === changedNotes.trim()) {
      toggleNoteEdit();
      return;
    }
    updateMaterialDescription({
      variables: {
        input: {
          mmId: parseInt(mmId),
          notes: changedNotes
        }
      }
    }).then((response) => {
      if (response.errors) {
        setMessageText('errorMessage', defaults.update_error);
        setSaveAllLoading(false);
      } else {
        toggleNoteEdit();
        setNotes(response.data.lsUpsertMaterialDescription.materialDescription.notes);
        setMessageText('editSuccessMessage', defaults.update_success);
        setChangedNotes(response.data.lsUpsertMaterialDescription.materialDescription.notes);
      }
    })
  };

  const toggleNoteEdit = () => {
    setShowNoteEdit(!showNoteEdit);
  };

  return (
    <StringDetailsContainer>
      <BackLink>
        <Link href="#" color="inherit" onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => backToSearch(e)}><ArrowLeft />
          {(backLink ? backLink.title : defaults.search_material_desc)}
        </Link>
        <Typography color="inherit">{mmId}</Typography>
      </BackLink>
      {messages.map((message: AlertMessage) => (
        message?.text?.length > 0 &&
        (<Grid key={message.key} container>
          <Grid item xs={message.gridSize}><MessageAlert
            onClose={() => setMessageText(message.key, '')}
            severity={message.severity}>
            {message.text}
          </MessageAlert></Grid></Grid>)
      ))}
      <FlexContainer>
        <Avatar
          size='sm'
          src='https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png'
          alt='Avatar'
        />
        <DetailsContainer>
          <Typography variant='h4'>{mmId}</Typography>
          {!showNoteEdit ? (
            <>
              <NotesContainer>
                <FlexContainerColumn>
                  <NotesHeaderContainer>
                    <NotesHeader color='textSecondary' variant='body1'>
                      {defaults.notes} :
                    </NotesHeader>
                    <Button variant='text' disabled={updatingMaterialDescription} onClick={toggleNoteEdit}>
                      {defaults.edit}
                    </Button>
                  </NotesHeaderContainer>
                  <Remarks variant='body2' color={notes ? 'textPrimary' : 'textSecondary'}>
                    {notes ? notes : defaults.no_notes}
                  </Remarks>
                </FlexContainerColumn>
              </NotesContainer>
            </>
          ) : (
            <>
              <NotesTextField>
                <StyledTextField multiline rows={2} label={defaults.add_note} fullWidth value={changedNotes} displayCount={true}
                  maxLength={4000}
                  onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setChangedNotes(e.target.value)}
                />
              </NotesTextField>
              <ButtonContainer>
                <Button disabled={notes?.trim() === changedNotes?.trim() || (changedNotes?.trim()?.length > 4000)} variant='text' color='primary'
                  loading={updatingMaterialDescription} onClick={handleNoteSave}>
                  {defaults.save}
                </Button>
                <Button variant='text' color="secondary" buttonType="danger" disabled={updatingMaterialDescription} onClick={handleNoteCancel}>
                  {defaults.cancel}
                </Button>
              </ButtonContainer>
            </>
          )}
        </DetailsContainer>
      </FlexContainer>
    </StringDetailsContainer>
  )
}

export default MaterialDescriptionDetails;