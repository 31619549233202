import { useContext } from 'react';
import { NewSegment } from '@octanner/cc-vibes-ui';
import { CustomerContext } from '../../utils/context/CustomerContext';
import { useNavigate } from './hooks';
import VibesStylesProvider from '../../utils/VibesStylesProvider';

export default function NewVibe() {
  const { customer } = useContext(CustomerContext);
  const { navigate, location } = useNavigate(customer.id);

  return (
    <VibesStylesProvider>
      <NewSegment
        key={location.key}
        isNewSegment={true}
        coreCustomerUuid={customer.id}
        stp={customer.stpNumber as string}
        onNavigation={navigate}
        location={location}
      />
    </VibesStylesProvider>
  );
}
