import React from "react";
import { Modal, Button, Typography } from "@octanner/prism-core";
import { useTranslation } from 'react-i18next';

const ConfirmationModal = ({
    openConfirmationModal,
    modalEntity,
    setOpenConfirmationModal,
    sumbitLoading
}) =>{
    const { t } = useTranslation();
    return(
    <Modal
    id="confirmation-modal"
    maxWidth="xs"
    title= {t('modal_confirmation')}
    open={openConfirmationModal}
    onClose={() => setOpenConfirmationModal(false)}
    actions={
        <>
            <Button
                data-testid="confirmation-cancel-button"
                color="secondary"
                onClick={() => setOpenConfirmationModal(false)}
            >
                {t('cancel')}
            </Button>
            <Button 
                data-testid="modal-entity"
                onClick={modalEntity.confirmAction}
                loading={sumbitLoading}
                adminColor
                color={modalEntity.color}
            >
                {modalEntity.actionText}
            </Button>
        </>
    }
>
    <Typography align="center" data-testid="modal-entity-desc">
        {modalEntity.desc}
    </Typography>
</Modal>
    )
};

export default ConfirmationModal;