import { gql, useMutation } from '@apollo/client';
import React, { useContext, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { withPad } from '../../common/BasicPadding';
import SnackbarPopup from '../../common/snackbar';
import { CustomerContext } from '../../utils/context/CustomerContext';
import { CreateNewRoleInput, CreateNewRoleResponse } from './models/Role';
import RoleNameForm from './RoleNameForm';

const CREATE_NEW_ROLE = gql`
  mutation createNewRole($customerId: UUID!, $roleName: String!) {
    createNewRole(request: { customerId: $customerId, name: $roleName }) {
      id
      uuid
      name
    }
  }
`;

const CreateRoleComponent = (props: RouteComponentProps) => {
  const {
    customer: { id: customerId },
  } = useContext(CustomerContext);
  const [createNewRole] = useMutation<
    CreateNewRoleResponse,
    CreateNewRoleInput
  >(CREATE_NEW_ROLE);
  const [error, setError] = useState(false);
  const handleSave = async (roleName: string) => {
    setError(false);
    try {
      const result = await createNewRole({
        variables: { roleName, customerId },
      });
      const role = result.data?.createNewRole;
      if (!role) {
        return;
      }
      props.history.push(`../roles-and-permissions/${role.id}`);
    } catch (e) {
      setError(true);
    }
  };
  return (
    <>
      <SnackbarPopup
        text="Error: Something went wrong. Please Try Again!"
        open={error}
        handleClose={() => setError(false)}
        severity="error"
      />
      <RoleNameForm onSave={handleSave} title="New role" isNew={true} />
    </>
  );
};
// @ts-ignore
export default withPad()(CreateRoleComponent);
