import { Switch } from '@octanner/prism-core';
import { FlexBetweenDiv, SubtitleTypography, TitleTypography } from './styles';
import StrengthsRadio from './StrengthsRadio';
import { StrengthsTabKeys, userSettings, UserSettingKeys } from './constants';
import {
  useDisableCustomerAvatar,
  useEnableCustomerAvatar,
  useGetCustomerAvatar,
  useGetStrengthsTab,
  useHideFollowingList,
  useHideStrengthsTab,
  useShowFollowingList,
  useShowPrivateStrengths,
  useShowPublicStrengths,
  useShowStrengthsTab,
  useGetPreferredPronoun,
  useDisablePreferredPronouns,
  useEnablePreferredPronouns,
  useUpdateCustomerSettings,
} from './graphql/userSettings';
import { CustomerContext } from '../../../utils/context/CustomerContext';
import { useContext, useEffect, useState } from 'react';
import Loading from '../../../common/Loading';
import { AppContext } from '../../../utils/context/AppContext';
import permissionListAuthorizer from '../../../utils/auth/PermissionListAuthorizer';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { PermissionsTooltipWrapper } from '../../../common/PermissionTooltipWrapper';
import { useGetCustomer } from '../../customer/hooks/customer';

const UserSettings = () => {
  const {
    customer: { id: customerId },
    social: {
      socialConfig: { displayFollowingTab: displayFollowTabData },
    },
  } = useContext(CustomerContext);

  let { ccAdminPronounsD230710 } = useFlags();
  const permissionList = userSettings.map(({ permission }) => permission || '');

  const context = useContext(AppContext);
  const authorizationer = permissionListAuthorizer(permissionList);
  const allowedPermissions = authorizationer(context, customerId);

  const customerUuidVariable = {
    variables: {
      customerUuid: customerId,
    },
  };

  const { data: customAvatarData, loading: customLoading } =
    useGetCustomerAvatar(customerId);

  const { data: strengthTabData, loading: strengthLoading } =
    useGetStrengthsTab(customerId);

  const { data: preferredPronounData, loading: preferredPronounLoading } =
    useGetPreferredPronoun(customerId);

  const { data: customerData, loading: customerLoading } = useGetCustomer(
    customerId,
    false
  );

  const [enableCustomerAvatar] = useEnableCustomerAvatar(customerId);
  const [disableCustomerAvatar] = useDisableCustomerAvatar(customerId);

  const [enableFollowingTab] = useShowFollowingList(customerId);
  const [disableFollowingTab] = useHideFollowingList(customerId);

  const [enabledStrengthsTab] = useShowStrengthsTab(customerId);
  const [disableStrengthsTab] = useHideStrengthsTab(customerId);
  const [enablePublicStrengthsTab] = useShowPublicStrengths(customerId);
  const [enablePrivateStrengthsTab] = useShowPrivateStrengths(customerId);

  const [enablePreferredPronouns] = useEnablePreferredPronouns(customerId);
  const [disablePreferredPronouns] = useDisablePreferredPronouns(customerId);

  const loading =
    customLoading ||
    strengthLoading ||
    preferredPronounLoading ||
    customerLoading;

  const [settingEnabled, setSettingEnabled] = useState({
    [UserSettingKeys.CustomAvatar]: true,
    [UserSettingKeys.FollowingTab]: true,
    [UserSettingKeys.StrengthsTab]: true,
    [UserSettingKeys.PreferredPronouns]: false,
    [UserSettingKeys.AllowEmailEdit]: true,
  });

  const [enableEmailEditing] = useUpdateCustomerSettings(
    customerId,
    customAvatarData?.ccWebAvatarUpload?.enabled,
    settingEnabled[UserSettingKeys.AllowEmailEdit]
  );

  const [strengthValue, setStrengthValue] = useState(StrengthsTabKeys.Public);
  const handleStrengthChange = (event) => {
    setStrengthValue(event.target.value);
    if (event.target.value === StrengthsTabKeys.Public) {
      enablePublicStrengthsTab(customerUuidVariable);
    } else {
      enablePrivateStrengthsTab(customerUuidVariable);
    }
  };

  useEffect(() => {
    if (
      customAvatarData &&
      customAvatarData.ccWebAvatarUpload.enabled !==
        settingEnabled[UserSettingKeys.CustomAvatar]
    ) {
      setSettingEnabled((previous) => ({
        ...previous,
        [UserSettingKeys.CustomAvatar]:
          customAvatarData.ccWebAvatarUpload.enabled,
      }));
    }
    if (displayFollowTabData !== null) {
      setSettingEnabled((previous) => ({
        ...previous,
        [UserSettingKeys.FollowingTab]: displayFollowTabData,
      }));
    }
    if (
      strengthTabData &&
      strengthTabData.ccWebRecognizedStrengths.enabled !==
        settingEnabled[UserSettingKeys.StrengthsTab]
    ) {
      setSettingEnabled((previous) => ({
        ...previous,
        [UserSettingKeys.StrengthsTab]:
          strengthTabData.ccWebRecognizedStrengths.enabled,
      }));
    }
    if (strengthTabData) {
      setStrengthValue(
        strengthTabData.ccWebRecognizedStrengths.visibility.toLowerCase()
      );
    }
    if (
      preferredPronounData &&
      preferredPronounData.ccWebPreferredPronouns.enabled !==
        settingEnabled[UserSettingKeys.PreferredPronouns]
    ) {
      setSettingEnabled((previous) => ({
        ...previous,
        [UserSettingKeys.PreferredPronouns]:
          preferredPronounData.ccWebPreferredPronouns.enabled,
      }));
    }
    if (
      customerData &&
      customerData.customer.settings.emailEditingDisabledTsz
    ) {
      setSettingEnabled((previous) => ({
        ...previous,
        [UserSettingKeys.AllowEmailEdit]:
          customerData.customer.settings.emailEditingDisabledTsz === null,
      }));
    }
    //eslint-disable-next-line
  }, [
    customAvatarData,
    displayFollowTabData,
    strengthTabData,
    preferredPronounData,
    customerData,
  ]);

  const updateItems = (value: boolean, key: string) => {
    if (settingEnabled[key] !== value) {
      switch (key) {
        case UserSettingKeys.CustomAvatar:
          if (value) {
            enableCustomerAvatar(customerUuidVariable);
          } else {
            disableCustomerAvatar(customerUuidVariable);
          }
          break;
        case UserSettingKeys.FollowingTab:
          if (value) {
            enableFollowingTab(customerUuidVariable);
          } else {
            disableFollowingTab(customerUuidVariable);
          }
          break;
        case UserSettingKeys.StrengthsTab:
          if (value) {
            enabledStrengthsTab(customerUuidVariable);
          } else {
            disableStrengthsTab(customerUuidVariable);
          }
          break;
        case UserSettingKeys.PreferredPronouns:
          if (value) {
            enablePreferredPronouns(customerUuidVariable);
          } else {
            disablePreferredPronouns(customerUuidVariable);
          }
          break;
        case UserSettingKeys.AllowEmailEdit:
          enableEmailEditing({
            variables: {
              request: {
                customerUuid: customerId,
                showImageAvatar: customAvatarData.ccWebAvatarUpload.enabled,
                emailEditingEnabled:
                  !settingEnabled[UserSettingKeys.AllowEmailEdit],
              },
            },
          });
          break;
      }
    }
  };

  if (loading) return <Loading />;

  return (
    <>
      {userSettings.map(
        (settings) =>
          (settings.key !== UserSettingKeys.PreferredPronouns ||
            ccAdminPronounsD230710) && (
            <div key={settings.key}>
              <FlexBetweenDiv
                data-testid={`user-setting-switch:${settings.key}`}
              >
                <div>
                  <TitleTypography
                    data-testid="user-setting-switch:title"
                    variant="h3"
                  >
                    {settings.title}
                  </TitleTypography>
                  <SubtitleTypography
                    data-testid="user-setting-switch:desc"
                    variant="subtitle2"
                  >
                    {settings.description}
                  </SubtitleTypography>
                </div>
                <div>
                  {settings.type === 'boolean' && (
                    <PermissionsTooltipWrapper
                      text="You do not have permission to edit this section"
                      enabled={
                        settings.permission
                          ? !allowedPermissions[settings.permission]
                          : false
                      }
                      placement="left"
                    >
                      <Switch
                        data-testid={'user-setting-toggle-' + [settings.key]}
                        checked={settingEnabled[settings.key]}
                        disabled={
                          // If there is no permission set, then it is enabled, otherwise default the inverse of the permission
                          settings.permission
                            ? !allowedPermissions[settings.permission]
                            : false
                        }
                        onChange={() => {
                          settings.onChange(
                            settings.key,
                            !settingEnabled[settings.key],
                            setSettingEnabled
                          );
                          updateItems(
                            !settingEnabled[settings.key],
                            settings.key
                          );
                        }}
                      />
                    </PermissionsTooltipWrapper>
                  )}
                </div>
              </FlexBetweenDiv>
              {settings.key === UserSettingKeys.StrengthsTab &&
                settingEnabled[settings.key] && (
                  <StrengthsRadio
                    data-testid={'strengths-radio-' + settings.key}
                    value={strengthValue}
                    onChange={(event) => {
                      handleStrengthChange(event);
                    }}
                    disabled={
                      settings.permission
                        ? !allowedPermissions[settings.permission]
                        : false
                    }
                  />
                )}
            </div>
          )
      )}
    </>
  );
};

export default UserSettings;
