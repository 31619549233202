import styled from "@emotion/styled";
import { Button } from "@octanner/prism-core";
import React, { useContext, useState } from 'react';
import Footer from "../../Components/Common/Footer";
import { TranslationRequestContext } from "../../Contexts/TranslationRequestContext";
import { defaults } from "../../utils/default";
import { TranslationRequestFooterProps, UnTranslatedString } from "../../utils/types";

const CreateJobButton = styled(Button)`
  margin-left: 24px;
`

const TranslationRequestFooter: React.FC<TranslationRequestFooterProps> = ({
  setShowDeleteTranslationRequestModal,
  createJob,
  createJobLoading,
  toggleHoldRequest
}) => {

  const { selectedLocale, translationCategory, unTranslatedStringList,
    refetchUntranslatedStringListData, setMessageText, setCreatedJobNumber } =
    useContext(TranslationRequestContext);

  const [holdRequestLoading, setHoldRequestLoading] = useState<boolean>(false);
  const [releaseRequestLoading, setReleaseRequestLoading] = useState<boolean>(false);

  const createJobForSelectedStrings = () => {
    if (createJobLoading || holdRequestLoading || releaseRequestLoading) {
      return;
    }
    const statusUniqueIdList = unTranslatedStringList
      .filter((item: UnTranslatedString) => item.selected && !item.onHoldInd)
      .map((item: UnTranslatedString) => item.requestStatus.uniqueId);
    createJob({
      variables: {
        input: {
          translationCategory,
          locale: selectedLocale.locale,
          statusUniqueIdList
        }
      }
    }).then((response) => {
      if (!response.errors) {
        setCreatedJobNumber(response?.data?.lsRushTranslation?.jobNumber);
        setMessageText('successMessage', defaults.create_job_success_message);
        refetchUntranslatedStringListData(statusUniqueIdList);
      }
    });
  }

  const toggleHoldTranslationRequest = (holdTranslationInd: boolean) => {
    if (holdRequestLoading || releaseRequestLoading || createJobLoading) {
      return;
    }
    let statusUniqueIdList: number[];
    if (holdTranslationInd) {
      statusUniqueIdList = unTranslatedStringList
        .filter((item: UnTranslatedString) => item.selected && !item.onHoldInd)
        .map((item: UnTranslatedString) => item.requestStatus.uniqueId);
    } else {
      statusUniqueIdList = unTranslatedStringList
        .filter((item: UnTranslatedString) => item.selected && item.onHoldInd)
        .map((item: UnTranslatedString) => item.requestStatus.uniqueId);
    }
    setHoldRequestLoading(holdTranslationInd);
    setReleaseRequestLoading(!holdTranslationInd);
    toggleHoldRequest({
      variables: {
        input: {
          translationCategory,
          holdTranslationInd: holdTranslationInd,
          locale: selectedLocale.locale,
          statusUniqueIdList
        }
      }
    }).then((response) => {
      setHoldRequestLoading(false);
      setReleaseRequestLoading(false);
      if (!response.errors) {
        refetchUntranslatedStringListData();
      }
    });
  }

  return (<Footer insideGridInd={true}>
    <div>
      <Button
        disabled={holdRequestLoading || releaseRequestLoading || createJobLoading ||
          !unTranslatedStringList.some(item => item.selected)}
        variant="text" buttonType="danger"
        onClick={() => setShowDeleteTranslationRequestModal(true)}>{defaults.delete_requests}
      </Button>
    </div>
    <div>
      {unTranslatedStringList.some(item => item.onHoldInd) && <Button
        loading={releaseRequestLoading}
        onClick={() => toggleHoldTranslationRequest(false)}
        disabled={holdRequestLoading || createJobLoading ||
          !unTranslatedStringList.some(item => item.selected && item.onHoldInd)}
        variant="text">{defaults.release_requests}
      </Button>}
      <Button
        loading={holdRequestLoading}
        onClick={() => toggleHoldTranslationRequest(true)}
        disabled={releaseRequestLoading || createJobLoading ||
          !unTranslatedStringList.some(item => item.selected) ||
          unTranslatedStringList.some(item => item.selected && item.onHoldInd)}
        variant="text">{defaults.hold_requests}
      </Button>
      <CreateJobButton
        onClick={createJobForSelectedStrings}
        loading={createJobLoading}
        disabled={releaseRequestLoading || holdRequestLoading ||
          !unTranslatedStringList.some(item => item.selected) ||
          unTranslatedStringList.some(item => item.selected && item.onHoldInd)}
        adminColor>{defaults.create_job}
      </CreateJobButton>
    </div>
  </Footer>);
}

export default TranslationRequestFooter;