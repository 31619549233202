import ConfirmModal from './ConfirmModal';
import { BaseModalProps } from '../types';

interface ModalProps extends BaseModalProps {
  roleName: string;
  onCancel: () => void;
}

const DeleteRoleModal = (modalProps: ModalProps) => {
  return (
    <ConfirmModal
      {...modalProps}
      title="Delete role"
      message={`Are you sure you want to delete ${modalProps.roleName}? This can’t be undone.`}
      confirmText="Delete"
      color="error"
    />
  );
};

export default DeleteRoleModal;
