import { setContext } from '@apollo/client/link/context';
import { Identity } from '../../pages/user-auth/models/Identity';
const { PUBLIC_URL: publicUrl = '' } = process.env;

export type DecodedToken = {
  cus: string;
  imp: string;
  rls: string[];
  xca?: number;
  exp: number;
};

export interface PublicState {
  decodedToken?: DecodedToken;
  identity?: Identity;
  permissions: CustomerPermissions[];
}

export interface CustomerPermissions {
  customerId: string;
  permissions: string[];
}

interface TokenResponse {
  accessToken: string;
  decodedAccessToken: DecodedToken;
  identity: Identity;
  permissions: CustomerPermissions[];
}

interface State extends PublicState {
  token: string;
}
const state: State = {
  token: '',
  permissions: [],
};

export const isAuthenticated = () => Boolean(state.token);

const isExpired = (token?: DecodedToken) => {
  if (!token?.exp) return true;
  const expires = new Date(token.exp * 1000);
  return expires < new Date();
};

export const apolloAuthLink = setContext(async (request, previousContext) => {
  if (isExpired(state.decodedToken)) {
    await fetchInfo();
  }
  return {
    headers: {
      authorization: `Bearer ${state.token}`,
    },
  };
});

export const fetchInfo = async (): Promise<PublicState | undefined> => {
  try {
    const r = await fetch(`${publicUrl}/user-info`, {
      credentials: 'include',
    });
    if (!r.ok) {
      if (r.status === 401 || r.status === 403) {
        window.location.assign(
          `${publicUrl}/login/initiate?location=${window.location.href}`
        );
      }
      return;
    }
    const { accessToken, decodedAccessToken, identity, permissions } =
      (await r.json()) as TokenResponse;
    state.token = accessToken;
    state.decodedToken = decodedAccessToken;
    state.identity = identity;
    state.permissions = permissions;
    const { token, ...rest } = state;
    return rest;
  } catch {
    return;
  }
};

export const authFetch = async (input: RequestInfo, init?: RequestInit) => {
  if (isExpired(state.decodedToken)) {
    await fetchInfo();
  }
  return fetch(input, {
    ...init,
    headers: {
      ...init?.headers,
      ...(state.token ? { Authorization: `Bearer ${state.token}` } : {}),
    },
  });
};

export const startImpersonating = async (
  identityId: string
): Promise<boolean> => {
  if (!state.token) return false;

  const res = await authFetch(`${publicUrl}/impersonation`, {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify({ identityId }),
  });

  if (!res.ok) {
    return false;
  }

  window.location.reload();
  return true;
};

export const endImpersonating = async (): Promise<boolean> => {
  if (!state.token) return false;
  if (isExpired(state.decodedToken)) {
    await fetchInfo();
  }

  await authFetch(`${publicUrl}/impersonation`, {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'DELETE',
  });

  window.location.reload();
  return true;
};

export const getDecodedToken = (): DecodedToken | undefined =>
  state.decodedToken;

export const isImpersonating = () => state.decodedToken?.imp || false;
