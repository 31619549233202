import { useMutation } from '@apollo/client';
import styled from '@emotion/styled';
import { Alert, Avatar, Breadcrumbs, Button, Grid, Link, Typography } from '@octanner/prism-core';
import { ArrowLeft } from '@octanner/prism-icons';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { LanguageStringsContext } from '../../../Contexts/LanguageStringsContext';
import { backLinks } from '../../../utils/constants';
import { defaults } from '../../../utils/default';
import { SAVE_NOTES } from '../../../utils/query';
import StyledTextField from '../../../utils/styledComponents';
import { AlertMessage, StringDetailsProps } from '../../../utils/types';

const StringDetailsContainer = styled.div`
  margin-bottom: 24px;
`

const BackLink = styled(Breadcrumbs)`
  margin-bottom: 24px;
`
const FlexContainer = styled.div`
  display: flex;
  margin-top: 12px;
`

const DetailsContainer = styled.div`
  margin-left: 12px;
  width: 70%;
`

const StpContainer = styled.div`
  margin-top: 10px;
  display: flex;
`

const ButtonContainer = styled.div`
  margin : 8px 0px;
`;

const NotesContainer = styled.div`
  background: #f8f8f8;
  padding: 8px 16px;
  max-width: 50%;
  margin-top: 14px;
`;

const FlexContainerColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const NotesHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Remarks = styled(Typography)`
  display: flex;
  align-items: center;
`;

const NotesHeader = styled(Typography)`
  display: flex;
  align-items: center;
  margin-right: 16px;
`;

const NotesTextField = styled.div`
  margin-top: 10px;
  width: 50%;
`;

const MessageAlert = styled(Alert)`
  margin-bottom: 12px;
`;

const StringDetails: React.FC<StringDetailsProps> = ({
  selectedString,
  refetchById,
  openEditEnglishTranslationModal,
  showRequesTranslationtModal
}) => {
  const {
    setRefetchInd, setShowFooter, stringText,
    searchStringByTextOrId, setStringId,
    messages, setMessageText
  } = useContext(LanguageStringsContext);

  const history = useHistory();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const backLinkType = queryParams.get('type');

  const [saveNotes, { loading: saveNotesLoading }] = useMutation(SAVE_NOTES, {
    onCompleted: () => {
      setShowNoteEdit(false);
      setRefetchInd(true);
      refetchById();
    }
  });
  const { text = '', stringId = '' } = selectedString;
  const note = selectedString?.notes || '';
  const [notes, setNotes] = useState<string>(note || '');
  const [showNoteEdit, setShowNoteEdit] = useState<boolean>(false);
  const [backLink, setBackLink] = useState<{ title: string, path: string }>(null);

  const [disableBackLink, setDisableBackLink] = useState(false);

  useEffect(() => {
    if (backLinkType) {
      setBackLink(backLinks[backLinkType])
    }
  }, [backLinkType]);

  const backToSearch = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    if (disableBackLink) {
      return;
    }
    setDisableBackLink(true);
    setMessageText('requestSuccessMessage', '');
    setMessageText('editSuccessMessage', '');
    setShowFooter(false);
    setMessageText('errorMessage', '');
    setMessageText('createSuccessMessage', '');
    setMessageText('createWarningMessage', '');
    if (backLinkType) {
      setDisableBackLink(false);
      history.push(backLink.path);
      return;
    }
    if (!stringText || !stringText.length) {
      setStringId(stringId);
      searchStringByTextOrId({
        variables: {
          stringId: stringId
        }
      });
    }
    setDisableBackLink(false);
    history.push('/language-string');
  }

  const handleNoteCancel = () => {
    setShowNoteEdit(false);
    setNotes(note);
  }

  const handleNoteChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNotes(e.target.value);
  };

  const handleNoteSave = () => {
    if (note != null && notes.trim() !== note.trim()) {
      saveNotes({
        variables: {
          input: {
            languageStringNotes: notes,
            stringId: stringId,
          },
        },
      });
    } else {
      setShowNoteEdit(false);
    }
  };

  const toggleNoteEdit = () => {
    setShowNoteEdit(!showNoteEdit);
  };

  return (
    <div>
      <StringDetailsContainer>
        <BackLink>
          <Link href="#" color="inherit" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => backToSearch(e)}><ArrowLeft />
            {(backLink ? backLink.title : defaults.search_language_string)}</Link>
          <Typography color="inherit">{stringId}</Typography>
        </BackLink>
        {!showRequesTranslationtModal && !openEditEnglishTranslationModal
          && messages.map((message: AlertMessage) => (
            message?.text?.length > 0 &&
            (<Grid key={message.key} container>
              <Grid item xs={message.gridSize}><MessageAlert
                onClose={() => setMessageText(message.key, '')}
                severity={message.severity}>
                {message.text}
              </MessageAlert></Grid></Grid>)
          ))}
        <FlexContainer>
          <Avatar
            size='sm'
            src='https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png'
            alt='Avatar'
          />
          <DetailsContainer>
            <Typography variant='h4'>{stringId}</Typography>
            <Typography>{text}</Typography>
            <StpContainer>
              <Typography color='textSecondary' variant='body2'>{defaults.stp} : &nbsp;</Typography>
              <Typography variant='body2'>{selectedString.soldToParty ? selectedString.soldToParty : defaults.not_announced}</Typography>
            </StpContainer>
            {!showNoteEdit ? (
              <>
                <NotesContainer>
                  <FlexContainerColumn>
                    <NotesHeaderContainer>
                      <NotesHeader color='textSecondary' variant='body1'>
                        {defaults.notes} :
                      </NotesHeader>
                      <Button variant='text' onClick={toggleNoteEdit}>
                        {defaults.edit}
                      </Button>
                    </NotesHeaderContainer>
                    <Remarks variant='body2' color={notes ? 'textPrimary' : 'textSecondary'}>
                      {notes ? notes : defaults.no_notes}
                    </Remarks>
                  </FlexContainerColumn>
                </NotesContainer>
              </>
            ) : (
              <>
                <NotesTextField>
                  <StyledTextField multiline rows={2} label={`${defaults.notes} :`} fullWidth value={notes} onChange={handleNoteChange} displayCount={true}
                    maxLength={4000} />
                </NotesTextField>
                <ButtonContainer>
                  <Button disabled={notes === note || (notes?.trim().length && notes.trim().length > 4000)} variant='text' color='primary' loading={saveNotesLoading} onClick={handleNoteSave}>
                    {defaults.save}
                  </Button>
                  <Button variant='text' color="secondary" buttonType="danger" disabled={saveNotesLoading} onClick={handleNoteCancel}>
                    {defaults.cancel}
                  </Button>
                </ButtonContainer>
              </>
            )}
          </DetailsContainer>
        </FlexContainer>
      </StringDetailsContainer>
    </div>
  );
};
export default StringDetails;
