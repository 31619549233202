import { Button, Typography } from '@octanner/prism-core';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import {
  eligibilityToModel,
  FormModel,
  SegmentType,
  includeExcludeText,
} from '../../models/eligibility.models';
import { ProgramEligibilityProps } from '../../types';
import { useStyles } from '../../styles';

const EligibilityText = ({
  model,
  dataTest,
}: {
  model: FormModel;
  dataTest: string;
}) => {
  switch (model.segmentType) {
    case SegmentType.all:
      return (
        <Typography data-test={dataTest}>
          Including all participants.
        </Typography>
      );
    case SegmentType.homeCountry:
      const homeCountryNames = model.countries.map((c) => c.countryName);
      return (
        <Typography data-test={dataTest}>
          {`${includeExcludeText(
            model.isInclude
          )} participants who live in ${homeCountryNames.join(', ')}`}
        </Typography>
      );
    case SegmentType.workCountry:
      const workCountryNames = model.countries.map((c) => c.countryName);
      return (
        <Typography data-test={dataTest}>
          {`${includeExcludeText(
            model.isInclude
          )} participants who work in ${workCountryNames.join(', ')}`}
        </Typography>
      );
    case SegmentType.businessUnit:
      const businessUnitNames = model.businessUnits.map((bu) => bu.name);
      return (
        <Typography data-test={dataTest}>
          {`${includeExcludeText(
            model.isInclude
          )} participants who belong to ${businessUnitNames.join(', ')}`}
        </Typography>
      );
  }
};

const ProgramEligibilityComp = ({
  participantCount,
  eligibility,
  onEdit,
  canEdit,
  className,
}: ProgramEligibilityProps) => {
  const classes = useStyles();
  const [model, setModel] = useState<FormModel[]>([]);

  useEffect(() => {
    if (!eligibility) {
      setModel([]);
      return;
    }

    setModel(eligibilityToModel(eligibility));
  }, [eligibility]);

  return (
    <div className={clsx(classes.root, className)}>
      <Typography variant="h3">Program Participation Eligibility</Typography>
      <Typography>
        <span data-test="program:eligibility:count">
          {participantCount ? participantCount : 'No'}
        </span>{' '}
        participant(s)
      </Typography>
      {model.map((model) => (
        <EligibilityText
          key={model.id}
          model={model}
          dataTest={`program:eligibility:${
            model.segmentType
          }:${includeExcludeText(model.isInclude)}`}
        />
      ))}
      {canEdit && (
        <Button
          style={{ display: 'inline-flex' }}
          variant="text"
          className={classes.updateLink}
          onClick={onEdit}
          data-test="program:editEligibility"
        >
          Update eligibility settings
        </Button>
      )}
    </div>
  );
};

export default ProgramEligibilityComp;
