import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@octanner/prism-core';
import { ImpersonateBase, ImpersonateHistoryProps } from './types';
import { impersonateHeaders } from './constants';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useStyles } from './styles';
import { authFetch } from '../../utils/auth/Auth';
import { IdentityWithCustomerBase } from '../user-auth/models/Identity';

const ImpersonateHistory = ({
  onRowClick,
  identity,
  impersonateClient,
}: ImpersonateHistoryProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { PUBLIC_URL: publicUrl } = process.env;
  const [initialized, setInitialized] = useState(false);
  const [recentimpersonations, setRecentimpersonations] = useState<
    ImpersonateBase[]
  >([]);

  useEffect(() => {
    if (initialized) return;
    authFetch(`${publicUrl}/recent/impersonate/${identity?.id}?`)
      .then((res) => {
        if (res.ok) return res.json();
        throw res;
      })
      .then((data) => {
        const storage = JSON.parse(data.value);
        const recent = storage || [];
        if (impersonateClient) {
          setRecentimpersonations(recent[impersonateClient?.id]);
        }
        setInitialized(true);
      })
      .catch((err) => {
        const recent = [];
        setRecentimpersonations(recent);
        setInitialized(true);
      });
    //eslint-disable-next-line
  }, [initialized]);

  const handleOnclick = (recent) => {
    let selection = window.getSelection();
    if (selection?.toString().length === 0) {
      onRowClick(recent);
    }
  };

  return (
    <div>
      <div className={classes.recentImpersonateTitle}>
        {t('recent-impersonations', 'Recent Impersonations')}
      </div>
      {recentimpersonations?.length > 0 ? (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {impersonateHeaders.map(({ label }) => (
                  <TableCell>
                    <Typography variant="h5">{label}</Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {recentimpersonations.map((recent, index) => (
                <TableRow
                  data-test={`impersonate:recentimpersonations:${recent?.id}`}
                  key={`${index}_${recent?.id}`}
                  className={classes.row}
                  hover
                  onClick={() =>
                    handleOnclick(recent as IdentityWithCustomerBase)
                  }
                >
                  <TableCell>{recent?.firstName}</TableCell>
                  <TableCell>{recent?.lastName}</TableCell>
                  <TableCell>{recent?.email}</TableCell>
                  <TableCell>{recent?.uniqueId}</TableCell>
                  <TableCell>{recent?.customer.name}</TableCell>
                  <TableCell>{recent?.employeeSecurityRole}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography>
          {t(
            'no-recent-impersonations',
            'No recent impersonations to view. Impersonate to view'
          )}
        </Typography>
      )}
    </div>
  );
};
export default ImpersonateHistory;
