import ArrowRightIcon from '@octanner/prism-icons/ArrowRight';
import CircularProgress from './CircularProgress';

interface Props {
  className?: string;
  isOpen: boolean;
  isLoading?: boolean;
  onClick?: () => void;
}

export default function CollapseIcon({ isOpen, isLoading, ...rest }: Props) {
  if (isLoading) {
    return (
      <CircularProgress
        className={rest.className}
        style={{ height: 20, width: 20 }}
      />
    );
  }
  return (
    <ArrowRightIcon
      {...rest}
      style={{
        transition: 'all 200ms',
        transform: `rotateZ(${isOpen ? '90deg' : '0deg'})`,
      }}
    />
  );
}
