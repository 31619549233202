import React, { useEffect, useState } from 'react';
import { debounce } from 'lodash';
import styled from '@emotion/styled';
import FormControlLabel from '@octanner/prism-core/FormControlLabel';
import {
  Button,
  FormLabel,
  Loader,
  Switch,
  TextField,
  Typography,
} from '@octanner/prism-core';
import { useFeatureFlag } from '@octanner/prism-utilities';
import {
  Award,
  ConfigRelatedFlexFieldDetail as FieldType,
  FlexFieldQuestion,
  AllowedMediaEnum,
  AwardTypes,
  EmailCommunicationsTournament,
  EmailCommunicationsTournamentLabels,
  DaysToPresentEnum,
  AwardPresentedByEnum,
  DaysToPresentEnumPrev,
  AwardPresentedByEnumPrev,
} from '../types';
import FieldList from '../Fields/FieldList';
import FieldEditor from '../Fields/FieldEditor';
import { useFieldList } from '../Fields/useFieldList';
import { useSetError } from '../utils/SetErrorProvider';
import AwardPresentation, { isPresentationValid } from './AwardPresentation';
import AwardPresentationPrev, {
  isPresentationValid as isPresentationValidPrev,
} from './AwardPresentationPrev';
import MediaOptions from './MediaOptions';
import ApprovalPath from '../ApprovalPath';
import { createAdjudicationConfig } from '../ApprovalPath/ApprovalPath';
import AwardCommunications from './AwardCommunications';
import { Screeners } from '../ApprovalPath/types';
import AwardType from './AwardType';
import { EmailCcOptions } from './EmailCcOptions';
import { GroupRecognitionOptions } from './GroupRecognitionOptions';
import { Divider, SwitchControl } from './editorControls';

const Container = styled.div`
  padding: 0 16px 16px;
`;

const AddFieldButton = styled(Button)`
  && {
    margin: 16px 0px 0px 16px;
    display: block;
  }
`;

const SaveAwardButton = styled(Button)`
  && {
    margin: 32px 8px 16px 0px;
  }
`;

const CancelButton = styled(Button)`
  && {
    margin: 32px 8px 16px 0px;
  }
`;
const AwardNameContainer = styled.div`
  margin-bottom: 20px;
  padding-top: 20px;
`;

const AwardNameRowOne = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

const AwardNameRowTwo = styled.div`
  display: flex;
`;

const AwardName = styled(TextField)`
  && {
  }
`;

const AwardActiveSwitch = styled(FormControlLabel)`
  && {
    flex: 1;
    margin-bottom: 20px;
    margin-left: -4px;
  }
`;

const { NOTIFY_CANDIDATE, NOTIFY_GIVER, NOTIFY_CANDIDATE_MANAGER } =
  EmailCommunicationsTournamentLabels;

const AwardEditor: React.FC<Props> = ({
  awardToEdit,
  isDuplicateName,
  loading,
  isNew,
  onCancel,
  onSave,
  programId,
}) => {
  const [configName, setConfigName] = useState(awardToEdit.configName || '');

  const [configNameDuplicate, setConfigNameDuplicate] = useState(false);
  const configNameIsDuplicateAndSetErrorState = (val: string) => {
    const isDuplicate = isDuplicateName(val, configName);
    setConfigNameDuplicate(isDuplicate);
    return isDuplicate;
  };
  const configNameIsDuplicateAndSetErrorStateDebounced = debounce(
    (val: string) => configNameIsDuplicateAndSetErrorState(val),
    250,
  );

  const [awardType, setAwardType] = useState(awardToEdit.awardType || null);

  const [active, setActive] = useState(awardToEdit.active || false);
  const [media, setMedia] = useState<AllowedMediaEnum[]>(
    awardToEdit.mediaOptions?.allowedMedia
      ? awardToEdit.mediaOptions.allowedMedia
      : Object.values(AllowedMediaEnum),
  );
  const [mediaOptionsUseMedia, setMediaOptionsUseMedia] = useState(
    awardToEdit.mediaOptions?.useMedia ?? true,
  );
  const [requireMedia, setRequireMedia] = useState(
    awardToEdit.mediaOptions?.requireMedia ?? false,
  );
  const [fields, setFields] = useState<FieldType[]>(
    awardToEdit.relatedFlexFields || [],
  );
  const recogConfigId = awardToEdit.recogConfigId;
  const [newFieldToEdit, setNewFieldToEdit] = React.useState(false);
  const [disableNewField, setDisableNewField] = React.useState(false);
  const [adjudicationConfig, setAdjudication] = useState(
    awardToEdit.adjudicationConfiguration ?? createAdjudicationConfig(),
  );
  const { saveFlexFieldQuestion, loading: loadingFieldList } = useFieldList();
  const [daysToPresentState, setDaysToPresentState] = useState(
    awardToEdit.presentation?.daysToPresent || DaysToPresentEnum.SEVEN,
  );
  const [presentedByState, setPresentedByState] = useState(
    awardToEdit.presentation?.presentedBy || AwardPresentedByEnum.NOMINATOR,
  );
  const [daysToPresentStatePrev, setDaysToPresentStatePrev] = useState(
    DaysToPresentEnumPrev.IMMEDIATELY,
  );
  const [presentedByStatePrev, setPresentedByStatePrev] = useState(
    AwardPresentedByEnumPrev.GIVER,
  );
  const [presenterState, setPresenterState] = useState(
    awardToEdit.presentation?.presenter,
  );
  const setError = useSetError();
  const [awardDescription, setAwardDescription] = useState(
    awardToEdit.awardDescription || '',
  );
  const [emailCommunicationsTournament, setEmailCommunicationsTournament] =
    useState<EmailCommunicationsTournament>({
      notifyNomineeManagerUponFirstNomination: {
        checked: awardToEdit.notifyNomineeManagerUponFirstNomination ?? true,
        label: NOTIFY_CANDIDATE_MANAGER,
      },
      notifyNomineeUponFirstNomination: {
        checked: awardToEdit.notifyNomineeUponFirstNomination ?? false,
        label: NOTIFY_CANDIDATE,
      },
      notifyGiverAfterEachSubmission: {
        checked: awardToEdit.notifyGiverAfterEachSubmission ?? false,
        label: NOTIFY_GIVER,
      },
    });

  const [flagLoaded, enablePresentations] = useFeatureFlag(
    'ccr-presentations-p-240503',
  );
  const validAwardDescriptionLength = 500;
  const isAwardDescriptionLengthValid =
    !awardDescription || awardDescription.length <= validAwardDescriptionLength;

  const [invalidDefinedApprovers, setInvalidDefinedApprovers] = useState(false);
  const isTournamentAward = awardType === AwardTypes.TOURNAMENT;
  const isEcard = awardType === AwardTypes.ECARD;
  const isPresentationsFlow = flagLoaded && enablePresentations;

  const [enableScheduledDelivery, setEnableScheduledDelivery] = useState(
    awardToEdit.allowForScheduledDelivery ?? true,
  );

  const [enableEmailCC, setEnableEmailCC] = useState(
    awardToEdit.emailCC?.allowForCC ?? true,
  );
  const [prePopulateCc, setPrePopulateCc] = useState(
    awardToEdit.emailCC?.prePopulateCc ?? true,
  );
  const [prepopulatedCCNominee, setPrepopulatedCCNominee] = useState(
    awardToEdit.emailCC?.nomineeManager ?? true,
  );
  const [prepopulatedCCNominator, setPrepopulatedCCNominator] = useState(
    awardToEdit.emailCC?.nominatorManager ?? false,
  );
  const [requireSenderConfirmation, setRequireSenderConfirmation] = useState(
    awardToEdit.emailCC?.requireSenderConfirmation ?? true,
  );
  const [
    allowUserToModifyAdditionalAddresses,
    setAllowUserToModifyAdditionalAddresses,
  ] = useState(
    awardToEdit.emailCC?.allowUserToModifyAdditionalAddresses ?? false,
  );

  const [allowGroupFileUpload, setAllowGroupFileUpload] = useState(
    awardToEdit.groupRecognition?.allowGroupFileUpload ?? true,
  );
  const [
    allowRecipientListViewableByEveryone,
    setAllowRecipientListViewableByEveryone,
  ] = useState(
    awardToEdit.groupRecognition?.allowRecipientListViewableByEveryone ?? true,
  );

  // Error handling for defined approvers
  useEffect(() => {
    if (!isEcard && adjudicationConfig?.adjudicationRounds) {
      for (const roundData of adjudicationConfig.adjudicationRounds) {
        if (roundData.screener.screener === Screeners.DEFINED_APPROVER) {
          if (roundData.definedApprovers?.defaultDefinedApprover?.approver) {
            if (
              roundData.definedApprovers?.definedApproversForBusinessUnits?.some(
                (approver) => !approver.approver || !approver.businessUnit,
              )
            ) {
              setInvalidDefinedApprovers(true);
              break;
            } else {
              setInvalidDefinedApprovers(false);
            }
          } else {
            setInvalidDefinedApprovers(true);
            break;
          }
        } else {
          setInvalidDefinedApprovers(false);
        }
      }
    }
  }, [adjudicationConfig?.adjudicationRounds, isEcard]);

  const presentationValidCheck =
    isEcard || isPresentationValid(presentedByState, presenterState);

  const presentationValidCheckPrev =
    isEcard ||
    isPresentationValidPrev(
      daysToPresentStatePrev,
      presentedByStatePrev,
      presenterState,
    );

  const isValidMediaOptions =
    mediaOptionsUseMedia && media.length > 0 ? true : false;

  const mediaOptionsValidCheck = isValidMediaOptions || !mediaOptionsUseMedia;

  const enableSavePrev =
    configName.trim().length &&
    !configNameDuplicate &&
    presentationValidCheckPrev &&
    !invalidDefinedApprovers &&
    !disableNewField &&
    !newFieldToEdit &&
    isAwardDescriptionLengthValid &&
    mediaOptionsValidCheck &&
    fields.length;

  const enableSave =
    configName.trim().length &&
    !configNameDuplicate &&
    presentationValidCheck &&
    !invalidDefinedApprovers &&
    !disableNewField &&
    !newFieldToEdit &&
    isAwardDescriptionLengthValid &&
    mediaOptionsValidCheck &&
    fields.length;

  const deleteFields = (fieldId) => {
    setFields((prevFields) =>
      prevFields.filter((field) => field.flexFieldQuestion.id != fieldId),
    );
  };

  const [wasAwardNameFocusLost, setWasAwardNameFocusLost] = useState(false);

  const onAwardNameFocusLost = () => setWasAwardNameFocusLost(true);
  const onAwardNameFocus = () => setWasAwardNameFocusLost(false);

  const shiftFieldDirection = (fieldIndex, direction) => {
    if (direction === 'up') {
      setFields((prevFields) =>
        prevFields.map((field, index) => {
          if (index === fieldIndex - 1) {
            return prevFields[fieldIndex];
          }
          if (index === fieldIndex) {
            return prevFields[fieldIndex - 1];
          }
          return field;
        }),
      );
    }
    if (direction === 'down') {
      setFields((prevFields) =>
        prevFields.map((field, index) => {
          if (index === fieldIndex) {
            return prevFields[fieldIndex + 1];
          }
          if (index === fieldIndex + 1) {
            return prevFields[fieldIndex];
          }
          return field;
        }),
      );
    }
  };

  const handleEmailCommunicationsChange = (key, value) => {
    if (isTournamentAward) {
      setEmailCommunicationsTournament({
        ...emailCommunicationsTournament,
        [key]: {
          ...emailCommunicationsTournament[key],
          checked: value,
        },
      });
    }
  };

  const getEmailCommunicationsValue = (key) => {
    if (isTournamentAward) {
      return emailCommunicationsTournament[key].checked;
    }
    return false;
  };

  const save = () => {
    onSave({
      programId,
      recogConfigId,
      configName,
      awardType,
      awardDescription,
      active,
      emailCC: {
        allowForCC: enableEmailCC,
        prePopulateCc,
        nomineeManager: prepopulatedCCNominee,
        nominatorManager: prepopulatedCCNominator,
        allowUserToModifyAdditionalAddresses,
        requireSenderConfirmation,
      },
      groupRecognition: {
        allowGroupFileUpload,
        allowRecipientListViewableByEveryone,
      },
      notifyNomineeManagerUponFirstNomination: getEmailCommunicationsValue(
        'notifyNomineeManagerUponFirstNomination',
      ),
      notifyGiverAfterEachSubmission: getEmailCommunicationsValue(
        'notifyGiverAfterEachSubmission',
      ),
      notifyNomineeUponFirstNomination: getEmailCommunicationsValue(
        'notifyNomineeUponFirstNomination',
      ),
      adjudicationConfiguration: isEcard ? null : adjudicationConfig,
      relatedFlexFields: fields,
      flexFields: null, // format doesn't match `fields` and isn't used during saving
      mediaOptions: {
        useMedia: mediaOptionsUseMedia,
        allowedMedia: media,
        requireMedia,
      },
      presentation: {
        daysToPresent: daysToPresentState,
        presentedBy: presentedByState,
        presenter: presenterState,
      },
      allowForScheduledDelivery: enableScheduledDelivery,
    });
    onCancel();
  };

  const updateField = (fieldId, newValue: FieldType) => {
    setFields((prevFields) =>
      prevFields.map((field) =>
        field.flexFieldQuestion.id === fieldId ? newValue : field,
      ),
    );
  };

  const cancelEditing = () => {
    setNewFieldToEdit(false);
  };

  const createField = () => {
    setNewFieldToEdit(true);
  };

  const saveField = (input: FlexFieldQuestion) => {
    saveFlexFieldQuestion({
      ...input,
    })
      .then((newField) => {
        setFields((existingFields) => [
          ...existingFields,
          {
            displayOrder: existingFields.length,
            flexfieldAnswerVisibility: 'EVERYONE',
            required: false,
            flexFieldQuestion: newField.data.saveFlexFieldQuestion,
          },
        ]);
      })
      .catch((err) => {
        setError(err.toString());
      });
  };

  const editField = () => {
    return (
      <FieldEditor
        field={{} as FlexFieldQuestion}
        onClose={cancelEditing}
        onSave={saveField}
      />
    );
  };

  const handleAwardTypeSelection = (value: AwardTypes) => {
    if (value === AwardTypes.TOURNAMENT) {
      const noAdjucationRounds = !adjudicationConfig?.adjudicationRounds.length;

      if (noAdjucationRounds) {
        setAdjudication(createAdjudicationConfig());
      }
    }
    setAwardType(value);
  };
  if (loading) {
    return (
      <Container>
        <Loader type="dots" loading />
      </Container>
    );
  }

  return (
    <Container>
      <AwardType
        handleAwardTypeSelection={handleAwardTypeSelection}
        awardType={awardType}
        isNew={isNew}
      />
      <Divider />
      {(awardType || !isNew) && (
        <>
          <FormLabel component="legend">
            <Typography variant="h3">General Settings</Typography>
          </FormLabel>
          <AwardNameContainer>
            <AwardActiveSwitch
              control={
                <Switch
                  checked={active}
                  color="primary"
                  onChange={(event) => {
                    setActive(event.target.checked);
                  }}
                  name="AwardActive"
                />
              }
              label="Award is active"
            />
            <AwardNameRowOne>
              <AwardName
                defaultValue={configName}
                onBlur={onAwardNameFocusLost}
                onFocus={onAwardNameFocus}
                label="Award name"
                maxLength={80}
                displayCount
                onChange={(event) => {
                  setConfigName(event.target.value);
                  configNameIsDuplicateAndSetErrorStateDebounced(
                    event.target.value,
                  );
                }}
                required
                error={
                  (wasAwardNameFocusLost && configName.length < 1) ||
                  configNameDuplicate
                }
                helperText={configNameDuplicate && 'Award name already used'}
                sx={{ flex: 1 }}
              />
            </AwardNameRowOne>
            <AwardNameRowTwo>
              <TextField
                value={awardDescription}
                label="Describe the award and the criteria for someone to receive this award."
                helperText="Optional"
                multiline
                rows={5}
                fullWidth
                displayCount
                maxLength={validAwardDescriptionLength}
                onChange={(event) => {
                  setAwardDescription(event.target.value);
                }}
              />
            </AwardNameRowTwo>
            <SwitchControl
              checked={enableScheduledDelivery}
              onChange={(e, checked) => setEnableScheduledDelivery(checked)}
            >
              Allow for scheduled delivery
            </SwitchControl>
          </AwardNameContainer>
          {isTournamentAward && (
            <AwardCommunications
              emailCommunications={emailCommunicationsTournament}
              handleEmailCommunicationsChange={handleEmailCommunicationsChange}
            />
          )}
          {!isEcard && (
            <>
              <ApprovalPath
                adjudicationProcess={adjudicationConfig}
                setAdjudicationProcess={setAdjudication}
                awardType={awardType}
              />
              {isPresentationsFlow ? (
                <AwardPresentation
                  daysToPresent={daysToPresentState}
                  setDaysToPresent={setDaysToPresentState}
                  presentedBy={presentedByState}
                  setPresentedBy={setPresentedByState}
                  presenter={presenterState}
                  setPresenter={setPresenterState}
                  recogConfigId={recogConfigId}
                />
              ) : (
                <AwardPresentationPrev
                  daysToPresent={daysToPresentStatePrev}
                  setDaysToPresent={setDaysToPresentStatePrev}
                  presentedBy={presentedByStatePrev}
                  setPresentedBy={setPresentedByStatePrev}
                  presenter={presenterState}
                  setPresenter={setPresenterState}
                  recogConfigId={recogConfigId}
                />
              )}
            </>
          )}
          {isEcard && (
            <>
              <EmailCcOptions
                enableEmailCC={enableEmailCC}
                setEnableEmailCC={setEnableEmailCC}
                prePopulateCc={prePopulateCc}
                setPrePopulateCc={setPrePopulateCc}
                prepopulatedCCNominee={prepopulatedCCNominee}
                setPrepopulatedCCNominee={setPrepopulatedCCNominee}
                prepopulatedCCNominator={prepopulatedCCNominator}
                setPrepopulatedCCNominator={setPrepopulatedCCNominator}
                allowUserToModifyAdditionalAddresses={
                  allowUserToModifyAdditionalAddresses
                }
                setAllowUserToModifyAdditionalAddresses={
                  setAllowUserToModifyAdditionalAddresses
                }
                requireSenderConfirmation={requireSenderConfirmation}
                setRequireSenderConfirmation={setRequireSenderConfirmation}
              />

              <Divider />

              <GroupRecognitionOptions
                allowGroupFileUpload={allowGroupFileUpload}
                setAllowGroupFileUpload={setAllowGroupFileUpload}
                allowRecipientListViewableByEveryone={
                  allowRecipientListViewableByEveryone
                }
                setAllowRecipientListViewableByEveryone={
                  setAllowRecipientListViewableByEveryone
                }
              />
            </>
          )}
          <Divider />
          <MediaOptions
            mediaOptionsUseMedia={mediaOptionsUseMedia}
            setMediaOptionsUseMedia={setMediaOptionsUseMedia}
            media={media}
            setMedia={setMedia}
            requireMedia={requireMedia}
            setRequireMedia={setRequireMedia}
          />
          {fields && (
            <FieldList
              fields={fields}
              onDelete={deleteFields}
              setDisableNewField={setDisableNewField}
              updateField={updateField}
              orderField={shiftFieldDirection}
            />
          )}
          {newFieldToEdit ? (
            editField()
          ) : (
            <AddFieldButton variant="text" onClick={createField}>
              Add Field
            </AddFieldButton>
          )}
          <SaveAwardButton
            loading={loadingFieldList}
            variant="contained"
            onClick={save}
            disabled={enablePresentations ? !enableSave : !enableSavePrev}
            data-testid="save"
          >
            Save Award
          </SaveAwardButton>
          <CancelButton
            variant="contained"
            color="secondary"
            onClick={onCancel}
          >
            Cancel
          </CancelButton>
        </>
      )}
    </Container>
  );
};

interface Props {
  awardToEdit: Award;
  isDuplicateName: (val: string, configName: string) => boolean;
  loading?: boolean;
  isNew?: boolean;
  programId: string;
  onCancel: () => void;
  onSave: (request: Award) => void;
}

export default AwardEditor;
