import { Route, Switch } from 'react-router-dom';
import Admins from './index';
import CreateAdmin from './createAdmin';
import EditAdmin from './editAdmin';

const AdminsRoutes = () => {
  return (
    <Switch>
      <Route path={`/admins`} component={Admins} exact />
      <Route path={`/admins/new`} component={CreateAdmin} exact />
      <Route path={`/admins/:identityUuid/edit`} component={EditAdmin} exact />
    </Switch>
  );
};

export default AdminsRoutes;
