import { createMyPlugins, MyPlatePlugin } from '../plateTypes';
import {
  createLinkPlugin,
  LinkPlugin,
  PlateFloatingLink,
} from '@udecode/plate';
import { plateUI } from './plateUI';

export const linkPlugin: Partial<MyPlatePlugin<LinkPlugin>> = {
  renderAfterEditable: PlateFloatingLink,
};

export const myLinkPlugin = createMyPlugins([createLinkPlugin(linkPlugin)], {
  components: plateUI,
});
