import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Pagination,
} from '@octanner/prism-core';

import { useContext, useEffect, useState } from 'react';
import { useCurrentIdentity } from '../user-auth/hooks/identity';
import {
  useGetMappingByCustomer,
  useGetRoles,
  useMapUser,
  useUnMapUserRoles,
} from '../user-auth/hooks/role';
import {
  ClientMappingByCustomerInput,
  RolesResponse,
} from '../user-auth/models/Role';
import ClientListItem from './ClientListItem';
import { ClientListRows } from './constants';
import { ClientListProps, MappingState, FormSubmission } from './types';
import { useStyles } from './styles';
import Loading from '../../common/Loading';
import { AppContext } from '../../utils/context/AppContext';

const ClientList = ({
  loading,
  clients,
  searchText,
  currentPage,
  handleChange,
  totalPages,
  ...menuProps
}: ClientListProps) => {
  const classes = useStyles();
  const [openIds, setOpen] = useState<string[]>([]);
  const [loadingIds, setLoadingIds] = useState<string[]>([]);
  const [mappings, setMappings] = useState<MappingState>({});
  const [getMappingByCustomer, { data }] = useGetMappingByCustomer();
  const mapUser = useMapUser();
  const unmapUser = useUnMapUserRoles();
  const { data: { currentIdentity } = {} } = useCurrentIdentity();
  const { decodedToken } = useContext(AppContext);
  const { data: queryRoles, fetchMore } = useGetRoles(decodedToken.cus);
  const [roles, setRoles] = useState<RolesResponse>();

  const getAll = async () => {
    if (queryRoles !== undefined) {
      setRoles(queryRoles);
      if (queryRoles.roles.last === false) {
        const newRoleList: RolesResponse = {
          ...queryRoles,
          roles: {
            ...queryRoles.roles,
            content: await fetchMore(),
          },
        };
        setRoles(newRoleList);
      }
    }
  };
  useEffect(() => {
    getAll();
    //eslint-disable-next-line
  }, [queryRoles]);

  useEffect(() => {
    if (!data) return;
    const clientId = data.customer.id;
    if (!openIds.includes(clientId)) return;
    if (mappings[clientId] === data.getNonDefaultIdentitiesRolesForCustomerId)
      return;
    setMappings({
      ...mappings,
      [clientId]: data.getNonDefaultIdentitiesRolesForCustomerId,
    });
    setLoadingIds(loadingIds.filter((id) => id !== clientId));
  }, [data, mappings, openIds, loadingIds]);

  if (!currentIdentity) return null;

  const toggleCollapse = (clientId: string) => {
    if (openIds.includes(clientId)) {
      setOpen(openIds.filter((id) => id !== clientId));
      const { [clientId]: _, ...rest } = mappings;
      setMappings(rest);
      return;
    }
    setOpen([...openIds, clientId]);
    setLoadingIds([...loadingIds, clientId]);
    getMappingByCustomer({
      variables: new ClientMappingByCustomerInput(clientId, currentIdentity.id),
    });
  };

  const handleSubmit = async (models: FormSubmission) => {
    let clientId = '';
    if (models.added.length) {
      clientId = models.added[0].customerId;
    }
    if (models.removed.length) {
      clientId = models.removed[0].customerId;
    }
    if (!clientId) {
      return;
    }
    const added = models.added.map((model) =>
      mapUser({
        variables: model,
      })
    );
    const removed = models.removed.map(
      ({ customerId, identityId, roleIds }) => {
        const variables = { customerId, identityId, roleIds };
        return unmapUser({
          variables,
        });
      }
    );
    await Promise.all(added);
    await Promise.all(removed);
    if (mappings[clientId]) {
      getMappingByCustomer({
        variables: new ClientMappingByCustomerInput(
          clientId,
          currentIdentity.id
        ),
      });
    }
  };

  if (loading) return <Loading />;
  if (!searchText) return null;
  if (!loading && clients.length < 1) {
    return <Typography>No Client or ID found under "{searchText}".</Typography>;
  }
  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            {ClientListRows.map(({ key, name, align }) => (
              <TableCell key={key}>
                <Typography
                  className={classes.headerCell}
                  style={{ textAlign: align }}
                  variant="h5"
                >
                  {name}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {roles &&
            clients.map((client) => (
              <ClientListItem
                roles={roles}
                client={client}
                key={client.id}
                onSubmit={handleSubmit}
                loading={loadingIds.includes(client.id)}
                toggled={openIds.indexOf(client.id) !== -1}
                onToggleCollapse={toggleCollapse}
                mappings={mappings[client.id]}
                refetch={() =>
                  getMappingByCustomer({
                    variables: new ClientMappingByCustomerInput(
                      client.id,
                      currentIdentity.id
                    ),
                  })
                }
                {...menuProps}
              />
            ))}
        </TableBody>
      </Table>
      <Pagination
        style={{ marginTop: 5 }}
        count={totalPages}
        page={currentPage + 1}
        onChange={(_, page) => handleChange(page)}
      />
    </>
  );
};
export default ClientList;
