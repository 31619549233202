export const THEME_SPACING = {
  xs: 1,
  sm: 2,
  md: 3,
  lg: 4,

  extraSmall: 1,
  small: 2,
  medium: 3,
  large: 4,
};
