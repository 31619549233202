import { useState } from 'react';
import { Modal, Typography, Stepper, Button, Alert } from '@octanner/prism-core';
import styled from '@emotion/styled';
import  InvoiceDetails  from './InvoiceDetails';
import  ClientAddressDetails  from './ClientAddressDetails';
import { useGetInvoiceConfiguration, useGetStates, useGetSalesOrgList, useCreateCustomerV2 } from './hooks/customer';
import { CustomerAddressInfoType, AlertContentProps } from './types';
import ClientInfo from './ClientInfo';
import { CLIENT_CREATION_HEADERS } from './constants';
import React from 'react';
import { requiredKeys } from '../utils/commonConstants';
import { validLength } from '../utils/commonConstants';
import { defaults } from './utilities/defaults';
import { tannerBlue } from '@octanner/prism-core/ThemeProvider/colors';

export const FooterContainer = styled.div`
  display: flex;
  position: fixed;
  bottom: 0;
  width: 86%;
  background-color: #fff;
  padding: 16px 24px 16px ;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.25);
  z-index: 11;
  margin-left: -40px;
`;

export const MainContainer = styled.div`
  margin-top: 32px;
`;

export const ModalContent = styled(Typography)`
  margin: 24px 0px;
`;

const AlertContainer = styled.div`
  margin-top: 16px;
`;

const StepperContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledStepper = styled(Stepper)`
  width: 450px;
  .MuiStepLabel-iconContainer div:first-child : {
    color : ${tannerBlue[500]},
    border: 2px solid ${tannerBlue[500]}
  };
  .MuiStepLabel-iconContainer div:first-child div : {
    border : 'none'
  };
  .Mui-completed div:first-child : {
    color:'#fff',
    background : ${tannerBlue[500]}
  }
`;

const StyledAlert = styled(Alert)`
  width: 600px;
`;

const defaultCustomerAddressInfo: CustomerAddressInfoType = {
  addressLine1: '',
  addressLine2: '',
  addressLine3: '',
  city: '',
  country: {},
  emailAddress: '',
  faxNumber: '',
  phoneNumber: '',
  postalCode: '',
  primaryContactName: '',
  salesTerritory: {},
  state: {},
};

const clientInfoRequiredKeys = [
  'clientNameErr',
  'piiRegionErr',
  'clientTypeErr',
  'salesOrgErr',
  'salesOrgCountryErr',

];

const defaultSalesOrg = {
  coreCountry:{
    countryName : '',
    iso2Code : '',
  },
      eligibleSalesOrgs: [
        {
          currency : {},
          salesOrgCode : '',
          salesOrgCompanyCode : '',
          salesOrgDistribution: '',
          salesOrgName : '',
          salesOrgDivision : '',
        }
      ] || '',
      recommendedSalesOrg: {}
}

const ClientCreation : React.FC = () => {
  const invoiceConfigData = useGetInvoiceConfiguration();
  const createCustomerV2 = useCreateCustomerV2();
  const {data : salesOrgList,loading: salesOrgListLoading} = useGetSalesOrgList();
  const { countryWithEligibleSalesOrgs = []} = salesOrgList?.allCountriesWithEligibleSalesOrgs || {};
  const [customerAddressInfo, setCustomerAddressInfo] = useState(defaultCustomerAddressInfo);
  const [getStates, {data: stateData}] = useGetStates('');
  const [activeStep, setActiveStep] = useState(0);
  const [invoiceConfig, setInvoiceConfig] = useState([
    {
      id :'invoiceMedium',
      label: defaults.invoice_medium,
      options: [],
      defaultValue: ''
    },
    {
      id :'billingSchedule',
      label: defaults.billing_schedule,
      options: [],
      defaultValue: ''
    },
    {
      id :'billingType',
      label: defaults.billing_type,
      options: [],
      defaultValue: ''
    }
]);
  const [invoiceSelecedDetails, setInvoiceSelecedDetails] = useState({
    invoiceMedium:'',
    billingSchedule:'',
    billingType:'',
  });
  const [statesOptions, setStatesOptions] = useState([]);
  const [addressDetailsError, setAddressDetailsError] = useState({
    salesTerritoryErr : false,
    countryErr : false,
    addressLine1Err : false,
    cityErr : false,
    stateErr : false,
    primaryContactNameErr : false,
  })
  const [clientInfoError, setClientInfoError] = useState({
    clientNameErr : false,
    piiRegionErr : false,
    clientTypeErr : false,
    salesOrgErr : false,
    salesOrgCountryErr : false,
  })
  const [searchSalesOrgList, setSearchSalesOrgList] = useState(countryWithEligibleSalesOrgs);
  const [clientInfo, setClientInfo] = useState({
    clientName: '',
    piiRegion: '',
    clientType: '',
    salesOrgCountry: defaultSalesOrg,
    salesOrg: '',
  });
  const [confirmClientCreation, setConfirmClientCreation] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertContent, setAlertContent] = useState<AlertContentProps>({
    severity : 'success',
    message : 'Client has been successfully created',
  });
  const [buttonLoading, setButtonLoading] = useState(false);
  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
  const [isEmailvalid, setIsEmailvalid] = useState(false);

  const checkClientInfoError = () => {
    let error = false;
    for (const key in clientInfo) {
      const errorKey = key + 'Err';
      if(errorKey==='salesOrgErr'){
        continue;
      }
      if(clientInfoRequiredKeys.includes(errorKey)) {
        if(clientInfo[key]===''){
          clientInfoError[errorKey] = true;
          error = true;
        }else{
          if(errorKey === 'salesOrgCountryErr') {
            if(clientInfo[key]===null || clientInfo[key]?.eligibleSalesOrgs[0]?.salesOrgCode === '') {
              clientInfoError[errorKey] = true;
              error = true;
            }
          }
        }
        if(errorKey === 'clientNameErr' && clientInfo[key].length > 35){
          clientInfoError[errorKey] = true;
          error = true;
        }
      }
    }
    const emailAddress = customerAddressInfo['emailAddress'];
    setClientInfoError({...clientInfoError});
    return error;
  };
  const checkAddressDetailsError = () => {
    let error = false;
    for (const key in customerAddressInfo) {
      const errorKey = key + 'Err';
      if(requiredKeys.includes(errorKey)) {
        if(errorKey==='stateErr' && stateData?.getStatesByCountry?.length === 0 ){
          continue;
        }
        if(!customerAddressInfo[key]) {
          addressDetailsError[errorKey] = true;
          error = true;
        }else{
          if(errorKey === 'countryErr' || errorKey === 'stateErr' || errorKey === 'salesTerritoryErr') {
            if(Object.keys(customerAddressInfo[key]).length === 0) {
              addressDetailsError[errorKey] = true;
              error = true;
            }
          }
        }
      }
    }
    setAddressDetailsError({...addressDetailsError});
    for(const value in customerAddressInfo){
      if(customerAddressInfo[value]?.length > validLength[value]){
        error=true;
      }
    }
    return error;
  }

  const setDataToDefault = () => {
    setActiveStep(0);
    setClientInfo({
      clientName: '',
      piiRegion: '',
      clientType: '',
      salesOrgCountry: defaultSalesOrg,
      salesOrg: '',
    });
    setCustomerAddressInfo(defaultCustomerAddressInfo);
    setInvoiceSelecedDetails({
      invoiceMedium: '',
      billingSchedule: '',
      billingType: '',
    });
    setInvoiceConfig([
      {
        id: 'invoiceMedium',
        label: "Invoice Medium",
        options: [],
        defaultValue: ''
      },
      {
        id: 'billingSchedule',
        label: "Billing schedule",
        options: [],
        defaultValue: ''
      },
      {
        id: 'billingType',
        label: "Billing type",
        options: [],
        defaultValue: ''
      }
    ]);
  }

  const handleClientCreation = () => {
    setButtonLoading(true);
    const {
      clientName, piiRegion,
      clientType, salesOrg
    } = clientInfo;
    const {
      country, primaryContactName, addressLine1, addressLine2,
      addressLine3, city, state, postalCode,
      phoneNumber, faxNumber, emailAddress, salesTerritory
    } = customerAddressInfo;
    createCustomerV2({
      variables: {
        input: {
          customerName : clientName,
          customerRegion : piiRegion,
          customerUsage : clientType,
          customerAddressAndContactInput : {
            countryIso2Code : country?.iso2Code,
            primaryContactName : primaryContactName,
            addressLine1 : addressLine1,
            addressLine2 : addressLine2,
            addressLine3 : addressLine3,
            city : city,
            stateCode : state?.stateCode || null,
            postalCode : postalCode,
            phoneNumber : phoneNumber,
            faxNumber : faxNumber,
            emailAddress : emailAddress,
            salesTerritoryCode : salesTerritory?.salesTerritoryCode
          },
          customerSalesAndInvoiceInput : {
            countryIso2Code : clientInfo?.salesOrgCountry?.coreCountry?.iso2Code,
            salesOrgCode : salesOrg,
            billingScheduleCode : invoiceSelecedDetails?.billingSchedule,
            billingTypeCode : invoiceSelecedDetails?.billingType,
            invoiceMediumCode : invoiceSelecedDetails?.invoiceMedium,
          }
        }
      }
    }).then((res) => {
      const {name, stpNumber } = res.data.createCustomerV2;
      setConfirmClientCreation(false);
      setShowAlert(true);
      setAlertContent({
        severity : 'success',
        message : `The client ${name} was created successfully with STP : ${stpNumber}`
      })
      setButtonLoading(false);
      setDataToDefault();
    }).catch((err) => {
      setConfirmClientCreation(false);
      setShowAlert(true);
      if(err.message){
        setAlertContent({
          severity : 'error',
          message : err.message.substring(err.message?.indexOf('CustomerData API:')+17, err.message?.length)
        });
      }else{
        setAlertContent({
          severity : 'error',
          message : defaults.client_creation_error
        });
      }
      setButtonLoading(false);
    });
  }

  return (
    <div>

      <Modal
        open={confirmClientCreation}
        id="confirmClientCreation"
        onClose={() => setConfirmClientCreation(false)}
        aria-labelledby="simple-modal-title"
        actions={
          <>
          <Button
            onClick={()=> handleClientCreation()}
            loading={buttonLoading}
            adminColor
          >
            {defaults.create_client}
          </Button>
          <Button color='secondary' onClick={() => setConfirmClientCreation(false)} >
            {defaults.cancel}
          </Button>
          </>
        }
        title='Create new client?'
        >
          <ModalContent align="center">
          Are you sure you want to create the new client <b>{clientInfo?.clientName}</b>?
          </ModalContent>

      </Modal>
      <Modal
        open={showCancelConfirmation}
        id="showCancelConfirmation"
        onClose={() => setShowCancelConfirmation(false)}
        aria-labelledby="simple-modal-title"
        actions={
          <>
          <Button
          onClick={() => {
            setShowCancelConfirmation(false);
            setDataToDefault();
            setClientInfoError({
              clientNameErr: false,
              piiRegionErr: false,
              clientTypeErr: false,
              salesOrgCountryErr: false,
              salesOrgErr: false,
            });
            setAddressDetailsError({
              salesTerritoryErr : false,
              countryErr : false,
              addressLine1Err : false,
              cityErr : false,
              stateErr : false,
              primaryContactNameErr : false,
            });
            setShowAlert(false);
            setIsEmailvalid(false);
          }}
            adminColor
          >
            {defaults.reset}
          </Button>
          <Button color='secondary' onClick={() => setShowCancelConfirmation(false)}>
          {defaults.cancel}
          </Button>
          </>
        }
        title={defaults.reset_title}
        >
          <ModalContent align="center">
          {defaults.reset_description}
          </ModalContent>
      </Modal>
      <Typography variant="h1">{defaults.client_creation}</Typography>
      <AlertContainer>
      {
        showAlert &&
        <StyledAlert
        severity={alertContent.severity}
        onClose={() => setShowAlert(false)}
        >
          {alertContent.message}
        </StyledAlert>
      }
      </AlertContainer>
      <MainContainer>
      <StepperContainer>
      <StyledStepper
        steps={CLIENT_CREATION_HEADERS}
        activeStep={activeStep}
        onStepClick={(step) => setActiveStep(Number(step.id))}
      />
      </StepperContainer>
      {
        (activeStep === 0 && !salesOrgListLoading) && (
          <ClientInfo
            salesOrgList={salesOrgList}
            searchSalesOrgList={searchSalesOrgList}
            setSearchSalesOrgList={setSearchSalesOrgList}
            clientInfo={clientInfo}
            setClientInfo={setClientInfo}
            clientInfoError={clientInfoError}
            setClientInfoError={setClientInfoError}
            setShowAlert={setShowAlert}
          />

        )
      }
      {
        activeStep === 1 && (
          <ClientAddressDetails
          customerAddressInfo={customerAddressInfo}
          setCustomerAddressInfo={setCustomerAddressInfo}
          statesOptions={statesOptions}
          setStatesOptions={setStatesOptions}
          getStates = {getStates}
          stateData = {stateData}
          addressDetailsError = {addressDetailsError}
          setAddressDetailsError = {setAddressDetailsError}
          isEmailvalid = {isEmailvalid}
          setIsEmailvalid = {setIsEmailvalid}
          setShowAlert={setShowAlert}
          />
        )
      }
      {
        (activeStep === 2 && invoiceConfigData?.data) && (
            <InvoiceDetails
            invoiceSelecedDetails={invoiceSelecedDetails}
            setInvoiceSelecedDetails={setInvoiceSelecedDetails}
            invoiceConfigData={invoiceConfigData}
            invoiceConfig={invoiceConfig}
            setInvoiceConfig={setInvoiceConfig}
            setShowAlert={setShowAlert}
            />
        )
      }

      <FooterContainer>
          <Button
            adminColor
            onClick={() => {
              let error = false;
               if(activeStep === 0) { error = checkClientInfoError() }
              if(activeStep === 1) { error = checkAddressDetailsError()}
              if(error) { return; }
              if(isEmailvalid) { return; }
              else {
                if(activeStep === 2) {
                  setConfirmClientCreation(true);
                }
                else { setActiveStep(activeStep + 1) }
               }
            }}
            test-id='continue-button'
          >
          {activeStep === 2 ? defaults.create_client : defaults.continue}
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button
            color='secondary'
            onClick={() => setActiveStep(activeStep - 1)}
            disabled={activeStep === 0}
          >
            {defaults.back}
          </Button>
          &nbsp;&nbsp;&nbsp;
          {
             ((clientInfo.clientName || clientInfo.piiRegion || clientInfo.clientType)
             ) && (
            <Button
              color="white"
              onClick={() => setShowCancelConfirmation(true)}
            >
              {defaults.cancel}
            </Button>
            )
          }
      </FooterContainer>
    </MainContainer>
  </div>
  );
};

export default ClientCreation;

