import { UserSetting } from './types';
import { Dispatch, SetStateAction } from 'react';

export enum UserSettingKeys {
  CustomAvatar = 'customAvatar',
  FollowingTab = 'followingTab',
  StrengthsTab = 'strengthsTab',
  PreferredPronouns = 'preferredPronouns',
  AllowEmailEdit = 'allowEmailEdit',
}

export enum StrengthsTabKeys {
  Private = 'private',
  Public = 'public',
}

type EditableStateTypes = {
  [UserSettingKeys.CustomAvatar]: boolean;
  [UserSettingKeys.FollowingTab]: boolean;
  [UserSettingKeys.StrengthsTab]: boolean;
  [UserSettingKeys.PreferredPronouns]: boolean;
  [UserSettingKeys.AllowEmailEdit]: boolean;
};

const onChange = (
  key: string,
  val: boolean,
  setSettingEnabled: Dispatch<SetStateAction<EditableStateTypes>>
) => {
  setSettingEnabled((previous) => ({
    ...previous,
    [key]: val,
  }));
};

export const userSettings: UserSetting[] = [
  {
    key: UserSettingKeys.CustomAvatar, // ? Used to edit state.
    title: 'Allow Custom Avatars',
    description:
      'When turned on, users will be able to upload their own images to edit their avatar.',
    type: 'boolean',
    permission: 'CC_WEBCONFIG_WRITE',
    onChange,
  },
  {
    key: UserSettingKeys.FollowingTab,
    title: 'Following List in Public Profile',
    description:
      'When turned on, a list of the people a user is following will be visible in their public profile.',
    type: 'boolean',
    permission: 'CC_SOCIAL_CONFIG_WRITE',
    onChange,
  },
  {
    key: UserSettingKeys.StrengthsTab,
    title: 'Strengths Page Enabled',
    description:
      "When turned on, a user's recognized strengths will be visible in their profile.",
    type: 'boolean',
    permission: 'CC_SKILLS_CONFIG_WRITE',
    onChange,
  },
  {
    key: UserSettingKeys.PreferredPronouns,
    title: 'Preferred Pronouns',
    description:
      'When turned on, a user will be able to choose their preferred pronouns that will be visible in their public profile.',
    type: 'boolean',
    permission: 'CC_WEBCONFIG_WRITE',
    onChange,
  },
  {
    key: UserSettingKeys.AllowEmailEdit,
    title: 'Allow Email Address Edits',
    description:
      'When turned on, a user will be able to edit their email address in their profile.',
    type: 'boolean',
    permission: 'CORE_CUSTOMER_WRITE',
    onChange,
  },
];
