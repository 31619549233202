import styled from '@emotion/styled';
import { Alert, Button, Typography } from '@octanner/prism-core';
import Loading from '../../../common/Loading';

export const SaveBankButton = styled(Button)`
  margin-right: 8px;
  margin-left: 8px;
`;

export const NewBankTypography = styled(Typography)`
  padding-top: 16px;
  padding-bottom: 8px;
  padding-left: 8px;
`;

export const LoadingWrapper = styled(Loading)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MBAlert = styled(Alert)`
  margin-bottom: 8px;
`;

export const ModalSubTitle = styled(Typography)`
  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: 24px;
  margin-bottom: 12px;
`;
