import BasicPadding from '../../../../common/BasicPadding';
import { ClientContainer, ClientListContainer, TypographyMB } from './styles';
import Typography from '@octanner/prism-core/Typography';
import {
  Box,
  Chip,
  MenuItem,
  Search,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@octanner/prism-core';
import { tannerGray } from '@octanner/prism-core/ThemeProvider/colors';
import { useState } from 'react';
import { ClientsHeadCells, tableRows } from './constants';
import { useDebouncedCallback } from 'use-debounce';
import SingleRow from '../components/SingleRow';
import {
  SearchContainer,
  SortAscFCM,
  SortDescFCM,
  SortFCM,
  TableMT,
  TypographyPZero,
  TypographySearchSubTitle,
} from '../styles';
import { CustomerWithAdminRoles } from './types';
import { SortConfig } from '../types';
import AdminPagination from '../components/AdminPagination';
import { useSearchCustomers } from './hooks/searchCustomers';
import Loading from '../../../../common/Loading';
import { CustomerSearchSortBy } from './graphql/types';

const ClientAccess = () => {
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchText, setSearchText] = useState('');
  const [searchTerms, setSearchTerms] = useState<
    (string | CustomerWithAdminRoles)[]
  >([]);
  const SetDebouncedText = useDebouncedCallback(setSearchText, 300);
  const [sortConfig, setSortConfig] = useState<
    SortConfig<CustomerSearchSortBy>
  >({
    key: 'NAME',
    direction: 'ASC',
  });
  const {
    data: { content: searchOptions },
    loading: suggestionsLoading,
  } = useSearchCustomers({
    input: {
      searchTerms: [searchText],
      sortBy: 'NAME',
      sortDirection: 'ASC',
      page: 0,
      pageSize: 10,
    },
    skip: searchText.length < 3,
  });
  const {
    data: { content: tableData, total: totalCount },
    loading,
  } = useSearchCustomers({
    input: {
      searchTerms: searchTerms.map((term) =>
        typeof term === 'string' ? term : term.customerUuid
      ),
      sortBy: sortConfig.key,
      sortDirection: sortConfig.direction,
      page: pageNumber - 1,
      pageSize,
    },
  });

  return (
    <>
      <BasicPadding padding="md">
        <ClientListContainer>
          <ClientContainer>
            <Typography variant="h1">Client Access</Typography>
          </ClientContainer>
          <TypographyMB>Assign admin roles to clients.</TypographyMB>
          <SearchContainer>
            <Box>
              <Search<CustomerWithAdminRoles>
                id="client-search"
                noOptionsText="We couldn’t find any clients who matched your search"
                loading={suggestionsLoading}
                options={searchOptions}
                multiple
                freeSolo
                value={searchTerms}
                placeholder="Search Client Name, STP, or Core ID"
                onInputChange={(_, e) => SetDebouncedText(e)}
                onChange={(e, value) => {
                  setSearchTerms(value);
                }}
                getOptionLabel={(option) => {
                  if (typeof option === 'string') {
                    return option;
                  }
                  return option.name;
                }}
                renderOption={(props, option) => {
                  const { className, ...optionProps } = props;
                  return (
                    <MenuItem {...optionProps} key={option.customerUuid}>
                      <div>
                        <Typography>{option.name}</Typography>
                        <TypographySearchSubTitle>{`STP: ${
                          option.stpNumber || 'N/A'
                        }`}</TypographySearchSubTitle>
                        <TypographySearchSubTitle>{`Core ID: ${option.customerUuid}`}</TypographySearchSubTitle>
                      </div>
                    </MenuItem>
                  );
                }}
                renderTags={(value, getTagProps) => {
                  return value.map(
                    (option: string | CustomerWithAdminRoles, index) => (
                      <Chip
                        variant="outlined"
                        label={
                          typeof option === 'string' ? option : option.name
                        }
                        {...getTagProps({ index })}
                      />
                    )
                  );
                }}
                sx={{
                  width: 540,
                  '& .MuiSvgIcon-root': {
                    color: tannerGray[400],
                  },
                }}
              />
            </Box>
          </SearchContainer>
          {searchTerms.length > 0 && (
            <Typography
              variant="caption"
              sx={{ pl: 5, color: tannerGray[600] }}
            >
              {`Showing ${totalCount} results`}
            </Typography>
          )}
          <TableMT>
            <TableHead>
              <TableRow>
                {ClientsHeadCells.map(({ id, label, align, sortKey }) => (
                  <TableCell align={align} key={id}>
                    <TypographyPZero variant="h5">
                      {label}
                      {!!sortKey &&
                        (sortConfig.key === sortKey ? (
                          sortConfig.direction === 'ASC' ? (
                            <SortAscFCM
                              onClick={() =>
                                setSortConfig({
                                  key: sortKey,
                                  direction: 'DESC',
                                })
                              }
                            />
                          ) : (
                            <SortDescFCM
                              onClick={() =>
                                setSortConfig({
                                  key: sortKey,
                                  direction: 'ASC',
                                })
                              }
                            />
                          )
                        ) : (
                          <SortFCM
                            onClick={() =>
                              setSortConfig({ key: sortKey, direction: 'ASC' })
                            }
                          />
                        ))}
                    </TypographyPZero>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {loading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={ClientsHeadCells.length} align="center">
                    <Loading />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {tableData.map((rowData) => (
                  <TableRow key={rowData.customerUuid}>
                    <SingleRow
                      testKey="clients"
                      rowData={rowData}
                      getId={(rowData) => rowData.customerUuid}
                      columnDisplayDefs={tableRows}
                      hideDelete
                    />
                  </TableRow>
                ))}
              </TableBody>
            )}
          </TableMT>
          <AdminPagination
            totalCount={totalCount}
            pageNumber={pageNumber}
            pageSize={pageSize}
            setPageNumber={setPageNumber}
            setPageSize={setPageSize}
          />
        </ClientListContainer>
      </BasicPadding>
    </>
  );
};

export default ClientAccess;
