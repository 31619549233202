import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import MigrationsRoutes from '../../pages/migrations/MigrationsRoutes';
import Terms from '../consent/Terms';
import Clients from './Clients';
import PrivacyPolicy from '../consent/PrivacyPolicy';
import GlobalClientList from './client/GlobalClientList';
import Impersonate from '../impersonate/Impersonate';
import NotFound from '../notFoundPage';
import Inprogress from '../inProgress/Inprogress';
import OrdersPage from './Orders';
import CreateClient from './CreateClient';
import Admins from './adminAccess/admins';
import ClientAccess from './adminAccess/clientAccess';
import { RequiredFields } from '@octanner/core-data-ui';
import LanguageLanding from '../Language/LanguageLanding';
import { LanguageRoutes } from './constants';
import Campaigns from './Campaigns';

const GlobalRoutes = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`/client-list`} component={GlobalClientList} exact />
      <Route path={`/impersonate`} component={Impersonate} exact />
      <Route path={`/client-creation`} component={CreateClient} exact />
      <Route path={`/client-mapping`} component={Clients} exact />
      <Route path={`/migration`} component={MigrationsRoutes} />
      <Route path={`/orders`} component={OrdersPage} exact />
      <Route path={`/approval-center`} component={Inprogress} exact />
      <Route component={Inprogress} exact path={`/design-management`} />
      <Route path={`/price-agreements`} component={Inprogress} exact />
      <Route path={`/price-models`} component={Inprogress} exact />
      <Route path={`/products`} component={Inprogress} exact />
      <Route path={`/services`} component={Inprogress} exact />
      <Route path={`/store-admin`} component={Inprogress} exact />
      <Route path={`/terms-of-use`} component={Terms} exact />
      <Route path={`/privacy-notice`} component={PrivacyPolicy} exact />
      <Route path={`/admins`} component={Admins} exact />
      <Route path={`/client-access`} component={ClientAccess} exact />
      <Route path={'/required-fields'} component={RequiredFields} exact />
      <Route path={'/campaigns'} component={Campaigns} exact />
      <Route path={LanguageRoutes}>
        <LanguageLanding />
      </Route>
      <Route path={`${match.path}`} exact>
        <Redirect to={`/client-list`} />
      </Route>
      <Route path="/not-found" component={NotFound} />
      <Route>
        <Redirect to="/not-found" />
      </Route>
    </Switch>
  );
};

export default GlobalRoutes;
