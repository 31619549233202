import styled from '@emotion/styled';
import { Box, TextField, Typography } from '@octanner/prism-core';
import {
  tannerGray,
  tannerSemantic,
  tannerWhite,
} from '@octanner/prism-core/ThemeProvider/colors';

export const FlexBetweenDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const RoleNameTextField = styled(TextField)`
  margin-bottom: 16px;
`;

export const RoleFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-top: 24px;
`;

export const AssignPermContainer = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
`;

export const ContainerA = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid ${tannerGray[200]};
  border-style: solid none solid solid;
  overflow-y: auto;
  overflow-x: hidden;
`;
export const ContainerB = styled.div`
  flex: 3;
  border: 1px solid ${tannerGray[200]};
  padding: 24px;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const FlexBox = styled(Box)`
  display: flex;
  max-height: 800px;
`;

export const CategoryContainer = styled.div<{
  active: boolean;
}>`
  width: 100%;
  padding: 16px 8px;
  border-bottom: 1px solid ${tannerGray[200]};
  cursor: pointer;
  :hover {
    background-color: ${({ active }) =>
      active ? tannerSemantic['info'] : tannerGray[50]};
  }
  background-color: ${({ active }) =>
    active ? tannerSemantic['info'] : tannerWhite};
`;

export const SearchBox = styled.div`
  padding: 16px;
  position: sticky;
  top: 0;
  background-color: ${tannerWhite};
  z-index: 1;
  width: 100%;
  border-bottom: 1px solid ${tannerGray[200]};
`;

export const FlexGroupB = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const NoActivePermContainer = styled.div`
  background-color: ${tannerGray[50]};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 16px;
  gap: 24px;
`;

export const TextContainer = styled.div`
  text-align: center;
`;

export const ActionText = styled(Typography)`
  text-align: center;
`;

export const RightFlexSpace = styled.div`
  flex: 3;
  display: flex;
  justify-content: space-around;
`;

export const SubCategoryContainer = styled.div`
  margin-top: 24px;
`;

export const SubCategoryHeader = styled(FlexBetweenDiv)`
  margin-bottom: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid ${tannerGray[200]};
`;

export const PermissionContainer = styled.div`
  margin-bottom: 8px;
  border-bottom: 1px solid ${tannerGray[200]};
`;

export const PermissionHeader = styled(FlexBetweenDiv)`
  margin-bottom: 8px;
`;

export const PermissionName = styled.div`
  flex: 8;
  display: flex;
  gap: 4px;
`;

export const FlexTypo8 = styled(Typography)`
  flex: 8;
`;

export const CenteredText = styled(Typography)`
  text-align: center;
  padding: 32px;
`;

export const SpacedGroups = styled.div<{ noPad?: boolean }>`
  padding-top: ${({ noPad = false }) => (noPad ? '0px' : '32px')};
`;
