import { Button, MenuItem, Select, Typography } from '@octanner/prism-core';
import clsx from 'clsx';
import React, { useEffect, useMemo, useState } from 'react';
import { BusinessUnit } from '../../../../graphql/business-unit/models';
import { useStyles } from '../../styles';
import { CountryBase } from '../../../../graphql/country/models';
import BusinessUnitInput from '../../programEligibilityInputs/BusinessUnitInput';
import CountryInput from '../../programEligibilityInputs/CountryInput';
import {
  allOptions,
  eligibilityToModel,
  FormModel,
  initializeModel,
  modelToInput,
  restrictedSegments,
  SegmentType,
  sortFormModel,
} from '../../models/eligibility.models';
import { RestrictedProgramTypes } from '../../models/models';
import { ProgramEligibilityFormProps } from '../../types';
import { segmentTypeMenu } from '../../constants';

const defaultState: FormModel[] = [
  { id: 0, isInclude: true, segmentType: SegmentType.all },
];

const ProgramEligibilityForm = ({
  program,
  onCancel,
  className,
  eligibility,
  onSave,
}: ProgramEligibilityFormProps) => {
  const classes = useStyles();
  const [nextId, setNextId] = useState(defaultState.length);
  const [state, setState] = useState<FormModel[]>(defaultState);
  const [options, setOptions] = useState(allOptions);

  useEffect(() => {
    if (!eligibility) {
      setState(defaultState);
      setNextId(defaultState.length);
      return;
    }

    const model = eligibilityToModel(eligibility);
    setState(model);
    setNextId(model.length);
  }, [eligibility]);

  const hasModifier = useMemo(
    () => (model: FormModel, isInclude: boolean) => {
      if (model.isInclude === isInclude) return true;
      const option = options.find(
        (o) => o.type === model.segmentType && o.isInclude === isInclude
      );
      return Boolean(option);
    },
    [options]
  );

  const hasType = useMemo(
    () => (model: FormModel, segmentType: SegmentType) => {
      if (model.segmentType === segmentType) return true;
      const option = options.find((o) => o.type === segmentType);
      return Boolean(option);
    },
    [options]
  );

  useEffect(() => {
    const usedOptions = state.map(
      ({ isInclude, segmentType }) => `${segmentType}:${isInclude}`
    );
    const filterdOptions = allOptions.filter(
      ({ isInclude, type }) => !usedOptions.includes(`${type}:${isInclude}`)
    );
    const uniqueSegment = state.find(({ segmentType }) =>
      restrictedSegments.includes(segmentType)
    );
    if (
      RestrictedProgramTypes.includes(
        program.coreProgramType.programTypeCode
      ) &&
      uniqueSegment
    ) {
      const restrictedOptions = filterdOptions.filter(
        ({ type }) =>
          !restrictedSegments.includes(type) ||
          type === uniqueSegment.segmentType
      );
      setOptions(restrictedOptions);
      return;
    }
    setOptions(filterdOptions);
  }, [state, program]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSave(modelToInput(state));
  };

  const updateIncludeExclude = (id: number, isInclude: boolean) => {
    const model = state.find((m) => m.id === id);
    if (!model) {
      return;
    }
    const others = state.filter((m) => m.id !== id);
    setState([...others, { ...model, isInclude }].sort(sortFormModel));
  };

  const updateSegment = (id: number, value: unknown) => {
    const model = state.find((m) => m.id === id);
    if (!model) {
      return;
    }
    const segmentType = value as SegmentType;
    const similarType = state.find((m) => m.segmentType === segmentType);
    const isInclude = similarType ? !similarType.isInclude : model.isInclude;
    const others = state.filter((m) => m.id !== id);
    const updated = initializeModel(id, isInclude, segmentType);
    setState([...others, updated].sort(sortFormModel));
  };

  const updateBusinessUnits =
    (id: number) => (businessUnits: BusinessUnit[]) => {
      const model = state.find((m) => m.id === id);
      if (!model) {
        return;
      }
      const others = state.filter((m) => m.id !== id);
      setState([...others, { ...model, businessUnits }].sort(sortFormModel));
    };

  const updateCountries = (id: number) => (countries: CountryBase[]) => {
    const model = state.find((m) => m.id === id);
    if (!model) {
      return;
    }
    const others = state.filter((m) => m.id !== id);
    setState([...others, { ...model, countries }].sort(sortFormModel));
  };

  const addEligibilityRule = (e: React.MouseEvent) => {
    e.preventDefault();
    if (!options.length) {
      return;
    }
    const [option] = options;
    const newModel = initializeModel(nextId, option.isInclude, option.type);
    setNextId(nextId + 1);

    setState([...state, newModel].sort(sortFormModel));
  };

  const removeRule = (id: number) =>
    setState(state.filter((m) => m.id !== id).sort(sortFormModel));

  return (
    <div className={clsx(classes.PEFormRoot, className)}>
      <Typography variant="h3">Program Participation Eligibility</Typography>
      <form className={clsx(classes.form)} onSubmit={handleSubmit}>
        {state.map((model) => (
          <div key={model.id} className={classes.PERow}>
            <div className={classes.select}>
              <Select
                value={model.isInclude ? 1 : 0}
                onChange={(e) =>
                  updateIncludeExclude(model.id, Boolean(e.target.value))
                }
                label="Include/Exclude"
                data-test={`program:eligibility:edit:${model.id}:include-exclude`}
                inputProps={{
                  name: 'isInclude',
                }}
              >
                {hasModifier(model, true) && (
                  <MenuItem value={1} data-test={`program:eligibility:include`}>
                    Include
                  </MenuItem>
                )}
                {hasModifier(model, false) && (
                  <MenuItem value={0} data-test="program:eligibility:exclude">
                    Exclude
                  </MenuItem>
                )}
              </Select>
            </div>
            <div className={classes.select}>
              <Select
                value={model.segmentType}
                onChange={(e) => updateSegment(model.id, e.target.value)}
                label="Segment Type"
                data-test={`program:eligibility:${model.id}:segmentType`}
                inputProps={{
                  name: 'isInclude',
                }}
              >
                {segmentTypeMenu.map(({ key, value, test }) => {
                  // @ts-ignore enum type issue
                  if (hasType(model, SegmentType[key])) {
                    return (
                      <MenuItem //@ts-ignore
                        value={SegmentType[key]}
                        key={key}
                        data-test={`program:eligibility:segmentType:${test}`}
                      >
                        {value}
                      </MenuItem>
                    );
                  } else return null;
                })}
              </Select>
            </div>
            <div style={{ flex: '2 1 1px' }}>
              {model.segmentType === SegmentType.businessUnit && (
                <BusinessUnitInput
                  dataTestId={`program:eligibility:${model.id}`}
                  selectedBusinessUnits={model.businessUnits || []}
                  onChange={updateBusinessUnits(model.id)}
                />
              )}
              {model.segmentType === SegmentType.workCountry && (
                <CountryInput
                  dataTestId={`program:eligibility:${model.id}:work`}
                  selectedCountries={model.countries}
                  onChange={updateCountries(model.id)}
                />
              )}
              {model.segmentType === SegmentType.homeCountry && (
                <CountryInput
                  dataTestId={`program:eligibility:${model.id}:home`}
                  selectedCountries={model.countries}
                  onChange={updateCountries(model.id)}
                />
              )}
            </div>
            <div style={{ flex: '0 0 auto' }} className={classes.vertCenter}>
              <Button
                variant="text"
                data-test={`program:eligibility:${model.id}:remove`}
                className={classes.updateLink}
                onClick={() => removeRule(model.id)}
              >
                {state.length > 1 ? 'Remove' : 'Clear'}
              </Button>
            </div>
          </div>
        ))}
        <div>
          {Boolean(options.length) && (
            <Button
              variant="text"
              data-test="program:eligibility:add"
              className={classes.updateLink}
              onClick={addEligibilityRule}
            >
              Add eligibility rule
            </Button>
          )}
        </div>
        <div className={clsx(classes.row, classes.mdTop)}>
          <div>
            <Button type="submit" data-test="program:eligibility:submit">
              Save Eligibility Rules
            </Button>
            <Button
              color="secondary"
              onClick={onCancel}
              data-test="program:eligibility:cancel"
            >
              Cancel
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ProgramEligibilityForm;
