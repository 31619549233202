import { format } from 'date-fns';
import { Locale } from './types';

export const getCookie = (name) =>
  document.cookie
    .split('; ')
    .filter((cookie) => cookie.split('=')[0] === name)
    .map((cookie) => cookie.match(new RegExp(`${name}=(.*)`))[1])[0];


export const formatDate = (dateString: string) => {
  if (dateString) {
    const dateObject = new Date(dateString);
    return format(dateObject, 'dd-MMM-yyyy');
  }
}

export const updateSuccessMessage = (localesWithDesc: Locale[], defaults: any, setMessageText: Function): void => {
  let successMessage = '';
  if (localesWithDesc?.length > 0) {
    const localeDescriptions = localesWithDesc
      .map((locale: Locale) => locale.languageDescription);
    if (localeDescriptions.length > 0) {
      const [lastLocale, ...otherLocales] = localeDescriptions.reverse();
      successMessage += defaults.translation_requested_msg_for_locales;
      successMessage += ` for ${otherLocales.reverse().join(', ')}${otherLocales.length > 0 ? ' and ' : ''}${lastLocale}`;
    }
  }
  setMessageText('requestSuccessMessage', successMessage);
}
