import { THEME_SPACING } from '../../common/constants/themeSpacing';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import styled from '@emotion/styled';
import colors from '../../common/constants/Colors';
import {
  MenuItem,
  Skeleton,
  TableCell,
  TableRow,
  Typography,
} from '@octanner/prism-core';
import { DragHandle } from '@material-ui/icons';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      display: 'flex',
      flexDirection: 'column',
    },
    row: {
      display: 'flex',
      flex: '1 1 1px',
      '& + div': {
        marginTop: theme.spacing(THEME_SPACING.xs),
      },
      '& > div': {
        flex: '1 1 1px',
        '& + div': {
          marginLeft: theme.spacing(THEME_SPACING.small),
        },
      },
    },
    smLeft: {
      marginLeft: theme.spacing(THEME_SPACING.small),
    },
    mdBottom: {
      marginBottom: theme.spacing(THEME_SPACING.md),
    },
    title: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    errorAlert: {
      marginBottom: theme.spacing(THEME_SPACING.small),
    },
    newProgramForm: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& > div': {
        flex: '1 1 1px',
        '& + div': {
          marginLeft: theme.spacing(THEME_SPACING.small),
        },
      },
    },
    root: {
      '& > *': {
        '& + *': {
          marginTop: theme.spacing(THEME_SPACING.sm),
        },
      },
    },
    updateLink: {
      cursor: 'pointer',
      display: 'block',
    },
    PEFormRoot: {
      '& > *': {
        '& + *': {
          marginTop: theme.spacing(THEME_SPACING.sm),
        },
      },
    },
    select: { '& .MuiFormControl-root': { width: '100%' } },
    vertCenter: {
      marginTop: 'auto',
      marginBottom: 'auto',
    },
    PERow: {
      display: 'flex',
      flex: '1 1 1px',
      '& + div': {
        marginTop: theme.spacing(THEME_SPACING.sm),
      },
      '& > div': {
        flex: '1 1 1px',
        '& + div': {
          marginLeft: theme.spacing(THEME_SPACING.small),
        },
      },
    },
    mdTop: {
      marginTop: theme.spacing(THEME_SPACING.md),
    },
    header: {
      marginBottom: theme.spacing(THEME_SPACING.medium),
    },
    addButtonContainer: {
      display: 'flex',
      marginBottom: theme.spacing(THEME_SPACING.md),
    },
    addButton: {
      marginLeft: 'auto',
    },
    loading: {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    smTop: {
      marginTop: theme.spacing(THEME_SPACING.small),
    },
    xsTop: {
      marginTop: theme.spacing(THEME_SPACING.xs),
    },
    xsBottom: {
      marginBottom: theme.spacing(THEME_SPACING.xs),
    },
    listStyle: {
      fontSize: 12,
      paddingLeft: 16,
      paddingRight: 16,
      color: '#707070',
    },
  })
);

export const ProgramContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  background-color: ${colors.gray300};
  padding: 24px;
`;

export const NoProgramTitle = styled(Typography)`
  padding-bottom: 8px;
`;

export const NoProgramText = styled(Typography)`
  padding-bottom: 32px;
  color: black;
`;

export const ProgramSkeleton = styled(Skeleton)`
  border-radius: 3px;
  margin: 24.5px 0px 24.5px 0px;
  display: flex;
  flex-direction: row;
`;

export const ProgramHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const YourProgamsMargin = styled(Typography)`
  margin-left: 4px;
  margin-bottom: 24px;
`;
export const ProgramSubTitle = styled(Typography)`
  margin-top: -10px;
  margin-bottom: 24px;
  color: ${colors.black};
`;

export const DurationWidth = styled.div`
  min-width: 190px;
`;

export const DragHandleIcon = styled(DragHandle)`
  margin-right: 16px;
  cursor: pointer;
`;

export const NameColumn = styled.div`
  display: flex;
  padding-right: 24px;
`;

export const MenuDotsContainer = styled.div`
  display: flex;
  cursor: pointer;
  justify-content: center;
`;

export const MenuItemColor = styled(MenuItem)`
  color: black;
`;

export const StatusCellMinWidth = styled(TableCell)`
  min-width: 200px;
`;

export const HeaderCellContainer = styled(TableRow)`
  padding: 60px;
`;

export const TypographyWS = styled(Typography)`
  white-space: nowrap;
`;
