import React, { useState , useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { Table, TableHead, TableRow, TableBody, Typography } from '@octanner/prism-core';
import { SortDescending, SortAscending} from '@octanner/prism-icons';
import {
  Header,
  SortContainer,
  FilterContainer,
  SubHeader,
  FilterHeader,
  StyledTableContainer,
  StyledTableCell
} from './style';
import { LocalesLoadedProps } from './types';
import SelectAndUnselect from './SelectAndUnselect';
import DisplayCountries from './DisplayCountries';
import { useTranslation } from 'react-i18next';
import Footer from './Footer';
import { SearchCountry } from '../salesOrg/style';
import { COUPLE_LOCALE, DECOUPLE_LOCALE } from './query';
import { customerId, sortArray } from '../../utilities/utility';

const LocalesLoaded: React.FC<LocalesLoadedProps> = ({
  localeList, selectedlocale, refetchCustomerLocale,
  setEditLanguage, deafultLanguage , setLanguageDataChanged , 
  onSapUpdateError, onSave,errorsDisplayNumber ,        
  tabsEditModeStatus,setTabsEditModeStatus,
  setDeafultLanguage, currentDefaultLanguage, setUpdateSapError,
}) => {
  const [selectAtleastOneLocaleError, setSelectAtleastOneLocaleError] = useState(false);
  const [coupleLocale] = useMutation(COUPLE_LOCALE);
  const [decoupleLocale] = useMutation(DECOUPLE_LOCALE);
  const { t } = useTranslation();
  

  const defaultLocaleCode = deafultLanguage.languageCode;
  const { nodes:customerLocalList = [] } = selectedlocale?.customerCoreLocaleMapsByCustomer;
  const { nodes:locales = [] } = localeList?.getCoreLocales?.coreLocales;

  const checkedCountriesandLanguages = customerLocalList?.map(
    (item) => {
      const {locale, isDefaultLocale = false} = item;
      const { languageDescription = '', code = '' } = locale || {};

      const isLanguageAndCountryNotSame = languageDescription.indexOf('-');
      
      if (isLanguageAndCountryNotSame !== -1) {
        return {
          language: languageDescription.substring(0, languageDescription.indexOf('-') - 1),
          country: languageDescription.substring(languageDescription.indexOf('-') + 3, languageDescription.length - 1 ),
          languageCode: code,
          defaultLocale: isDefaultLocale,
          checked: true
        };
      }

      return {
        language: languageDescription,
        country: languageDescription,
        languageCode: code,
        defaultLocale: isDefaultLocale,
        checked: true
      };
    }
  );


  const allCountriesandLanguages = sortArray(locales?.map(
    (item) => {
      const {languageDescription = '', code = '' } = item;
      const isLanguageAndCountryNotSame = languageDescription.indexOf('-');
      if (isLanguageAndCountryNotSame !== -1) {
        return {
          language: languageDescription.substring(0, languageDescription.indexOf('-') - 1),
          country: languageDescription.substring( languageDescription.indexOf('-') + 3,
            languageDescription.length - 1),
          languageCode: item?.code,
          defaultLocale: defaultLocaleCode == code ? true : false,
          checked: checkedCountriesandLanguages.some(
            ({languageCode, checked}) => languageCode === code && checked === true)
        };
      }

      return {
        language: languageDescription,
        country: languageDescription,
        languageCode: code,
        defaultLocale: defaultLocaleCode == code ? true : false,
        checked: checkedCountriesandLanguages.some(
          (checkedItem: any) =>
            checkedItem.languageCode === item?.code &&
            checkedItem.checked === true,
        ),
      };
    },
  ), 'language');

  
  const [searchText, setSearchText] = useState('');
  const [saveLocalPrefrenceProgress, setSaveLocalPrefrenceProgress] = useState(false);

  const [checkedLocales, setCheckedLocales] = useState(true);
  const [uncheckedLocales, setUncheckedLocales] = useState(true);
  
  const [isAscending, setIsAscending] = useState(true);

  //combine checked and unchecked countries with no duplicates
  const [allCountries, setAllCountries] = useState(
    allCountriesandLanguages?.filter(
      (item: any) =>
        !checkedCountriesandLanguages
          .map((items: any) => items.code)
          .includes(item?.languageCode)
    ),
  );

  const handleSorting = () => {
    setIsAscending(!isAscending);
    setAllCountries(allCountries.reverse());
  }


  //make changes to status
  const handleLocaleSelection = (languageCode, selection) => {
   const selectionOptionIndex = allCountries.findIndex(
      (locale) => locale.languageCode === languageCode
    );
    allCountries[selectionOptionIndex].checked = selection;
    setAllCountries(allCountries);
  }

  useEffect(() => {
    if(tabsEditModeStatus.DefaultCountryAndLanguages){
      saveLocalePrefrence();
      setTabsEditModeStatus({ ...tabsEditModeStatus, DefaultCountryAndLanguages: false });
    }
  },[tabsEditModeStatus]);

  // Save Client Prefrences 
  const saveLocalePrefrence = () => {
    setSaveLocalPrefrenceProgress(true);
   

    const promisesList : any[] = [];
    const checkedCountries = allCountries?.filter((locale) => locale.checked);
    allCountries?.forEach(locale => {

      const { languageCode, defaultLocale, checked } = locale;

      if(!defaultLocale) {
        if(checked){
          const apiCall = coupleLocale({
            variables: {
              input: {
                customerId,
                localeCode: languageCode,
                isDefault: false,
              },
            },
          });
          promisesList.push(apiCall);
        } else {
          const isEligibleDecouple = customerLocalList.some(l => l?.locale?.code === languageCode);
          if(isEligibleDecouple) {
            const apiCall = decoupleLocale({
              variables: {
                input: {
                  customerId,
                  localeCode: languageCode,
                },
              },
            });
            promisesList.push(apiCall);
          }
        }
      }
    });
    
    Promise.all(promisesList).then(() => {
      refetchCustomerLocale().then(() => {
          checkedCountries.length > 0 ? (
            setLanguageDataChanged(false),
            setEditLanguage(false),
            setSaveLocalPrefrenceProgress(false),
            (onSave({...errorsDisplayNumber,DefaultCountryAndLanguages: 0}))
          ):(
            setSaveLocalPrefrenceProgress(false),
            setSelectAtleastOneLocaleError(true),
            (onSave({...errorsDisplayNumber,DefaultCountryAndLanguages: 1}))
          )}
      );
    }).catch((error) => {
      setSaveLocalPrefrenceProgress(false);
      onSapUpdateError(error?.message);
    });
  }

  const filterLanguage = allCountries?.filter(
    l => (l?.language?.toLowerCase()?.includes(searchText?.toLowerCase())
    || l?.country?.toLowerCase()?.includes(searchText?.toLowerCase())
    )
  )?.filter(f => {
    const { checked } = f;
    if(uncheckedLocales && checkedLocales){
      return (checked === !uncheckedLocales || checked === checkedLocales)
    }

    if(uncheckedLocales){
      return checked === !uncheckedLocales
    }

    if(checkedLocales){
      return checked === checkedLocales
    }
  });

  return (
    <>
      <div>
        <Header variant='h3'>
          {t('deafult_language')}
        </Header>
        {
          selectAtleastOneLocaleError && (
          <SubHeader color="error" variant="body2">
            {t('select_atleast_one_language')}
          </SubHeader>
          )
        }

        <FilterContainer>
          <SelectAndUnselect
            setChecked={setCheckedLocales}
            setUnchecked={setUncheckedLocales}
          />
          <SearchCountry
            id="searchLanguage"
            onInputChange={(e: any) => {
              setSearchText(e.target.value || '');
              setUpdateSapError(false);
            }}
            placeholder={t('search_language')}
            size="small"
            multiple={false}
            getOptionLabel={() => searchText}
          />
        </FilterContainer>
        {filterLanguage?.length > 0 ? 
        (
            <StyledTableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>
                      <Typography variant='h4'>{t('language')}</Typography>
                      <SortContainer onClick={() => handleSorting()}>
                        {isAscending ? <SortAscending /> : <SortDescending />}
                      </SortContainer>
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filterLanguage?.map((item: any) =>
                    <DisplayCountries
                      locale={item}
                      handlelocaleSelection={handleLocaleSelection}
                      key={item?.languageCode}
                      setLanguageDataChanged={setLanguageDataChanged}
                      setUpdateSapError={setUpdateSapError}
                    />
                  )}
                </TableBody>
              </Table>
            </StyledTableContainer>
          )
          : (
          <>
            <FilterHeader color="textSecondary" variant='h4' gutterBottom> 
              {t('filter_languages')}
            </FilterHeader>
            <Typography color="textSecondary" variant='body2'>
              {t('selected_languages')}
            </Typography>
          </>
        )}
        {(checkedLocales || uncheckedLocales) && (
            <Footer
              onSave={saveLocalePrefrence}
              onCancel={() => setEditLanguage(false)}
              loading={saveLocalPrefrenceProgress}
              setLanguageDataChanged={setLanguageDataChanged}
              selectedlocale={selectedlocale}
              setDeafultLanguage={setDeafultLanguage}
              currentDefaultLanguage={currentDefaultLanguage}
            />
        )}
      </div> 
    </>
  );
};

export default LocalesLoaded;
