import { AppContextData } from '../context/AppContext';
import Authorizer from './Authorizer';

const roleAuthorizer: Authorizer = ({
  decodedToken,
  config,
}: AppContextData): boolean => {
  const matchingRole = decodedToken.rls.find((r) =>
    config.impersonationRoles.includes(r)
  );
  return Boolean(matchingRole || decodedToken.imp);
};

export default roleAuthorizer;
