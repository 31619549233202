import styled from "@emotion/styled";
import { TextField } from "@octanner/prism-core";

const StyledTextField = styled(TextField)({
  '&.MuiFormControl-root.MuiTextField-root:focus-within .MuiFormLabel-root.MuiInputLabel-root': {
    transform: 'translate(4px, -4px) scale(0.8) !important'
  },
});

export default StyledTextField;
