import styled from '@emotion/styled';
import { Autocomplete, Typography } from '@octanner/prism-core';

export const LabelLinesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 8;
  padding-left: 8px;
`;

export const LabelLinesSubTitleMargin = styled(Typography)`
  margin-top: 32px;
  margin-bottom: 13px;
`;

export const AutoCompleteWidthHeight = styled(Autocomplete)`
  width: 364px;
  height: 56px;
  margin-bottom: 24px;
`;

export const OptionalTypography = styled(Typography)`
  font-size: 12px;
  color: #707070;
  font-weight: 400;
  margin-left: 16px;
  margin-bottom: 8px;
`;
