import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { THEME_SPACING } from '../constants/themeSpacing';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      marginBottom: theme.spacing(THEME_SPACING.medium),
    },
    addButtonContainer: {
      display: 'flex',
      marginBottom: theme.spacing(THEME_SPACING.md),
    },
    addButton: {
      marginLeft: 'auto',
    },
    loading: {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    root: {
      flexGrow: 1,
    },
    formRow: {
      display: 'flex',
      '& > div + div': {
        marginLeft: theme.spacing(1),
      },
      '& > div': {
        flex: '1 1 1px',
      },
    },
    alignRight: {
      display: 'flex',
      marginLeft: 'auto',
      marginTop: 'auto',
      marginBottom: 'auto',
      justifyContent: 'center',
      alignItems: 'center',
    },
    pagination: {
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
  })
);
