import { gql } from '@apollo/client';

export const CORE_FEATURE_BASE_FRAGMENT = gql`
  fragment CoreFeatureBaseFragment on Feature {
    code: featureCode
    name: featureName
  }
`;

export const CUSTOMER_FEATURE_BASE_FRAGMENT = gql`
  fragment CustomerFeatureBaseFragment on CustomerFeature {
    code: featureCode
    name: featureName
    effectiveDate: effectiveDate
    expirationDate: expirationDate
  }
`;

export const GET_ALL_FEATURES = gql`
  ${CORE_FEATURE_BASE_FRAGMENT}
  query allFeatures {
    allFeatures {
      nodes {
        ...CoreFeatureBaseFragment
      }
    }
  }
`;
