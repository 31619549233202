import { Button, Typography } from '@octanner/prism-core'
import { styled } from '@mui/material/styles'
import { BasicForm, Fieldset } from 'library/src/common/components/Styled'
import { CelebrationLevelGroupConfig } from 'library/src/common/models/CelebrationLevelGroupConfig'
import { CoreAwardLevelInput } from 'library/src/common/models/YbCelebrationLevelGroupConfigInput'
import { useUpdateCoreAwardLevels } from 'library/src/graphql/useUpdateCoreAwardLevels'
import React, { useEffect, useState } from 'react'
import PointLevelInput from './PointLevelInput'
import { Box } from '@mui/system'
import colors from 'library/src/common/colors'

const FormHeader = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.spacing(5)};
  border-bottom: 1px solid ${colors.gray400};
  padding-bottom: ${({ theme }) => theme.spacing(3)};
`

const FooterContainer = styled('section')<{ alignment: string }>`
  display: flex;
  justify-content: ${(props) => props.alignment};
`

const FooterButton = styled(Button)`
  margin-left: ${({ theme }) => theme.spacing(3)};
`

const PointsContainer = styled(Fieldset)`
  display: grid;
  grid-template-columns: repeat(3, 0.15fr);
  grid-gap: ${({ theme }) => theme.spacing(3)};
`
interface Props {
  setAlert: React.Dispatch<React.SetStateAction<boolean>>
  customerUuid: string
  celebrationLevelGroupConfigs: CelebrationLevelGroupConfig[]
  onCancel: () => void
}

const PointLevelConfigurationEdit = ({
  setAlert,
  customerUuid,
  celebrationLevelGroupConfigs,
  onCancel,
}: Props) => {
  const { handleUpdateCoreAwardLevels } = useUpdateCoreAwardLevels(setAlert)
  const [coreAwardLevelList, setCoreAwardLevelList] = useState<
    CoreAwardLevelInput[]
  >([])

  useEffect(() => {
    const sortedGroupConfigs = []
    celebrationLevelGroupConfigs?.map((group) =>
      sortedGroupConfigs.push(
        ...group.celebrationLevelConfigs
          .filter((c) => c.userSelectedAwardCoreAwardLevel !== null)
          .sort((a, b) => (a.rank > b.rank ? 1 : -1))
      )
    )

    if (sortedGroupConfigs.length > 0) {
      const formattedConfigs = sortedGroupConfigs.map((config) => {
        return {
          rank: config.rank,
          id: config.id,
          userSelectedCoreAwardLevel: {
            minPoints: config.userSelectedAwardCoreAwardLevel.minPoints,
            maxPoints: config.userSelectedAwardCoreAwardLevel.maxPoints,
            awardLevelUuid:
              config.userSelectedAwardCoreAwardLevel.awardLevelUuid,
          },
          modified: false,
        }
      })

      setCoreAwardLevelList(formattedConfigs)
    }
  }, [celebrationLevelGroupConfigs])

  const handleLevelPointsChange = (value: CoreAwardLevelInput) => {
    setCoreAwardLevelList((prevState) =>
      prevState.map((item) => {
        if (item.id === value.id)
          return {
            ...item,
            userSelectedCoreAwardLevel: {
              awardLevelUuid: value.userSelectedCoreAwardLevel.awardLevelUuid,
              minPoints: value.userSelectedCoreAwardLevel.minPoints,
              maxPoints: value.userSelectedCoreAwardLevel.maxPoints,
            },
            modified: true,
          }
        return item
      })
    )
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const updatedList = coreAwardLevelList
      .filter((c) => c.modified)
      .map((c) => ({
        uuid: c.userSelectedCoreAwardLevel.awardLevelUuid,
        minPoints: c.userSelectedCoreAwardLevel.minPoints,
        maxPoints: c.userSelectedCoreAwardLevel.maxPoints,
      }))

    await handleUpdateCoreAwardLevels({
      customerUuid,
      updateCoreAwardLevelList: updatedList,
    })
    onCancel()
  }

  const handleCancel = () => {
    onCancel()
  }

  return (
    <BasicForm data-testid="pointLevelConfig:form" onSubmit={handleSubmit}>
      <Box sx={{ backgroundColor: colors.gray300, padding: 6 }}>
        <FormHeader variant="h2"> Edit point level configuration </FormHeader>
        <PointsContainer>
          {coreAwardLevelList.length > 0 &&
            coreAwardLevelList.map((clc) => (
              <PointLevelInput
                key={clc.id}
                config={clc}
                handleLevelPointsChange={handleLevelPointsChange}
              />
            ))}
        </PointsContainer>
        <FooterContainer alignment={'flex-end'}>
          <div>
            <Button
              data-testid="pointLevelConfig:form:cancel"
              type="button"
              color="secondary"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <FooterButton
              data-testid="pointLevelConfig:form:submit"
              type="submit"
            >
              Update
            </FooterButton>
          </div>
        </FooterContainer>
      </Box>
    </BasicForm>
  )
}

export default PointLevelConfigurationEdit
