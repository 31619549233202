import { gql, useMutation, useQuery } from '@apollo/client';
import type {
  GetCustomerWelcomeEmailToggleInput,
  GetCustomerWelcomeEmailToggleResponse,
  UpdateCustomerWelcomeEmailToggleInput,
  UpdateCustomerWelcomeEmailToggleResponse,
} from './types';

export const GET_CUSTOMER_WELCOME_EMAIL_ENABLED = gql`
  query CosCustomerNotificationType($input: CosCustomerNotificationTypeInput!) {
    cosCustomerNotificationType(input: $input) {
      customerNotificationType {
        customer {
          customerUuid
        }
        notificationType {
          name
        }
        enabledTsz
      }
    }
  }
`;

export const UPDATE_CUSTOMER_WELCOME_EMAIL_ENABLED = gql`
  mutation CosToggleCustomerNotificationType(
    $input: CosToggleCustomerNotificationTypeInput!
  ) {
    cosToggleCustomerNotificationType(input: $input) {
      customerNotificationType {
        enabledTsz
        customer {
          customerUuid
        }
        notificationType {
          name
        }
      }
    }
  }
`;

export const useGetCustomerWelcomeEmailToggle = (
  customerId: string,
  notificationType: string
) =>
  useQuery<
    GetCustomerWelcomeEmailToggleResponse,
    { input: GetCustomerWelcomeEmailToggleInput }
  >(GET_CUSTOMER_WELCOME_EMAIL_ENABLED, {
    variables: { input: { customerUuid: customerId, notificationType } },
  });

export const useUpdateCustomerWelcomeEmailToggle = (
  customerUuid: string,
  isEnabled: boolean,
  notificationType: string
) =>
  useMutation<
    UpdateCustomerWelcomeEmailToggleResponse,
    { input: UpdateCustomerWelcomeEmailToggleInput }
  >(UPDATE_CUSTOMER_WELCOME_EMAIL_ENABLED, {
    variables: { input: { customerUuid, isEnabled, notificationType } },
  });
