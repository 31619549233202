import styled from '@emotion/styled';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@octanner/prism-core';
import { LanguageStringsContext } from '../../Contexts/LanguageStringsContext';
import React, { useContext } from 'react';
import { defaults } from '../../utils/default';
import { useHistory } from 'react-router-dom';

const StringIdCell = styled(TableCell)`
  width: 15%;
`;

const StringTextCell = styled(TableCell)`
  width: 75%;
`;

const ActionsCell = styled(TableCell)`
  width: 10%;
`;

const StringTextTypography = styled(Typography)`
  white-space: pre;
`;

const SearchStringsTable = () => {

  const history = useHistory();

  const { searchData } = useContext(LanguageStringsContext);

  const handleEdit = (id: string) => {
    history.push('/language-string/edit/' + id);
  };
  return (

    <Table>
      <TableHead>
        <TableRow>
          <TableCell>
            <Typography variant='h4'>{defaults.string_id}</Typography>
          </TableCell>
          <TableCell>
            <Typography variant='h4'>{defaults.string}</Typography>
          </TableCell>
          <TableCell>
            <Typography variant='h4'>{defaults.actions}</Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {searchData.lsFetchLanguageStringListUsingSearchFilters.languageStringList
          .map(({ stringId, text }: { stringId: string, text: string }) => (
            <TableRow key={stringId}>
              <StringIdCell>
                <Typography>{stringId}</Typography>
              </StringIdCell>
              <StringTextCell>
                <StringTextTypography>{text}</StringTextTypography>
              </StringTextCell>
              <ActionsCell>
                <Button variant='text' onClick={() => handleEdit(stringId)}>
                  {defaults.edit}
                </Button>
              </ActionsCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
}

export default SearchStringsTable;