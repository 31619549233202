import { Errors } from './types';
import { MBAlert } from './styles';

export const ErrorHandler = ({ errors }: { errors?: Errors[] }) => {
  if (!errors) return null;
  return (
    <>
      {errors.map(({ errorData, setErrorData, key }) => {
        if (errorData.error)
          return (
            <MBAlert
              key={key}
              color="error"
              onClose={() => setErrorData({ ...errorData, error: false })}
            >
              {errorData.message || ''}
            </MBAlert>
          );
        else return null;
      })}
    </>
  );
};
