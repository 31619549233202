import { IdentityPresenter } from 'types';

const getFullName = ({
  preferredName,
  firstName,
  lastName,
}: Partial<IdentityPresenter>): string =>
  `${preferredName || firstName} ${lastName}`;

export default getFullName;
