import { styled } from '@mui/material/styles'
import { Alert, Button, Typography } from '@octanner/prism-core'
import React, { useEffect, useState } from 'react'
import colors from '../../common/colors'
import Collapse from '../../common/components/Collapse/Collapse'
import { YbCelebrationLevelGroupConfigInput } from '../../common/models/YbCelebrationLevelGroupConfigInput'
import {
  useGroupSettings,
  useGroupSettingsDispatch,
} from '../../utilities/GroupSettingsContext'
import Loading from '../Loading'
import ShippingInfo from '../ShippingInfo'
import AnniversaryGift from './AnniversaryGift/AnniversaryGift'
import AnniversaryYears from './AnniversaryYears/AnniversaryYears'
import EmailControls from './EmailControls/EmailControls'
import NonMilestoneEmailControls from './EmailControls/NonMilestoneEmailControls'
import NameAndType from './NameAndType/NameAndType'
import Survey from './Survey/Survey'
import Yearbooks from './Yearbooks/Yearbooks'
import { stateToInput } from './models'

interface Props {
  customerId: string
  onSubmit: (input: YbCelebrationLevelGroupConfigInput) => void
  onCancel: () => void
  noRecogId: boolean
  loading: boolean
  programId: string
}

const Root = styled('form')`
    display: grid;
    grid-gap: ${({ theme }) => theme.spacing(5)};
    > section + section {
      padding-top: ${({ theme }) => theme.spacing(5)};
      border-top: 1px solid ${colors.gray400};
    }
  `,
  SaveButton = styled(Button)`
    margin-left: ${({ theme }) => theme.spacing(5)};
  `,
  GroupSettingsTitle = styled(Typography)`
    padding: ${({ theme }) => theme.spacing(5)} 0;
    border-top: 1px solid ${colors.gray400};
    border-bottom: 1px solid ${colors.gray400};
  `

const dataTestPrefix = 'yps:celebration-group-edit'

export default function CelebrationGroupEdit({
  customerId,
  onSubmit,
  onCancel,
  noRecogId,
  loading,
}: Props): JSX.Element {
  const dispatch = useGroupSettingsDispatch()
  const groupSettingsContext = useGroupSettings()
  const [showAlert, setShowAlert] = useState<boolean>(false)
  const [autoshipChecked, setAutoshipChecked] = useState(
    groupSettingsContext?.autoshipAwards
  )

  useEffect(() => {
    if (noRecogId) {
      setShowAlert(true)
    }
  }, [noRecogId])

  useEffect(() => {
    setAutoshipChecked(groupSettingsContext?.autoshipAwards)
  }, [groupSettingsContext?.autoshipAwards])

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (
      groupSettingsContext?.emptyYearbookEmailEnabled &&
      !groupSettingsContext?.emptyYearbookEmailRecipientIdentity
    ) {
      dispatch({
        type: 'setEmptyYearbookEmailRecipient',
        identity: { id: '', errors: ['Recipient name required'] },
      })
      return
    }

    if (!groupSettingsContext?.name?.trim().length) {
      dispatch({ type: 'setGroupName', name: '' })
      dispatch({
        type: 'setGroupNameErrors',
        groupNameErrors: ['Please enter a name'],
      })

      return
    }
    if (
      groupSettingsContext?.surveyEnabled == true &&
      !groupSettingsContext?.surveyUrl.trim().length
    ) {
      dispatch({
        type: 'setSurveyUrl',
        surveyUrl: '',
      })
      dispatch({
        type: 'setSurveyUrlErrors',
        surveyUrlErrors: ['Please enter a survey URL'],
      })

      return
    } else {
      onSubmit(stateToInput(groupSettingsContext))
    }
  }

  return (
    <Root onSubmit={handleSubmit}>
      {loading && !noRecogId ? (
        <Loading />
      ) : (
        <>
          <Typography variant="h2">Celebration Group</Typography>
          <NameAndType />
          <AnniversaryYears />
          <GroupSettingsTitle variant="h3">Group Settings</GroupSettingsTitle>
          {groupSettingsContext?.milestone ? (
            <>
              <AnniversaryGift />
              <Collapse
                isOpen={groupSettingsContext?.printCertificateEnabled}
                onChange={() => dispatch({ type: 'togglePrintCertificates' })}
                text="Printed Certificates"
                dataTestPrefix={dataTestPrefix}
                id="printed-certificates"
                hideIcon
                fixedHeight
              />
              <Collapse
                id="yearsbooks:autoship-order"
                isOpen={autoshipChecked}
                onChange={(switchActive) => {
                  setAutoshipChecked(switchActive)
                  dispatch({ type: 'toggleAutoshipAwards' })
                }}
                text="Autoship Order"
                hideIcon
              />
              <Yearbooks />
              <ShippingInfo />
              <EmailControls customerId={customerId} />
              <Survey />
            </>
          ) : (
            <NonMilestoneEmailControls />
          )}
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: '8px',
            }}
          >
            <div>
              <Button color="inherit" onClick={onCancel}>
                Cancel
              </Button>
              <SaveButton data-testid="anniversary-gift:save" type="submit">
                Save
              </SaveButton>
            </div>
            {showAlert && (
              <Alert onClose={() => setShowAlert(false)} color="error">
                You must add a Recognition Program ID in order to use points for
                service. Please go back to the main settings page to add an ID
                and try again.
              </Alert>
            )}
          </div>
        </>
      )}
    </Root>
  )
}
