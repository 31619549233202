import React, { Dispatch, SetStateAction } from 'react';
import { Box, Button, Grid } from '@octanner/prism-core';
import Round from './Round';
import { AwardTypes } from '../types';
import {
  AdjudicationConfiguration,
  AdvancementTypes,
  Round as RoundType,
  RoundTypes,
  Screeners,
} from './types';
import { Heading, BottomBorder } from '../styles';

export const createAdjudicationConfig = (): AdjudicationConfiguration => ({
  id: undefined,
  name: '',
  adjudicationRounds: [createRound()],
});

const createRound = (): RoundType => ({
  adjudicationType: RoundTypes.SCREENING,
  screener: { screener: Screeners.CANDIDATE_MANAGER },
  commentOnlyRound: false,
  advancement: { type: AdvancementTypes.MANUAL },
});

interface ApprovalPathProps {
  adjudicationProcess: AdjudicationConfiguration;
  awardType: AwardTypes;
  setAdjudicationProcess: Dispatch<SetStateAction<AdjudicationConfiguration>>;
}

const ApprovalPath: React.FC<ApprovalPathProps> = ({
  adjudicationProcess,
  awardType,
  setAdjudicationProcess,
}) => {
  const addRound = () => {
    setAdjudicationProcess(
      adjudicationProcess
        ? (prev: AdjudicationConfiguration) => ({
            ...prev,
            adjudicationRounds: [...prev.adjudicationRounds, createRound()],
          })
        : createAdjudicationConfig(),
    );
  };

  const removeRound = (roundIndex) => {
    setAdjudicationProcess((prevData: AdjudicationConfiguration) => ({
      ...prevData,
      adjudicationRounds: prevData.adjudicationRounds.filter(
        (_, i) => i !== roundIndex,
      ),
    }));
  };

  const updateRound = (round: RoundType, roundIndex: number) => {
    setAdjudicationProcess((prevData: AdjudicationConfiguration) => ({
      ...prevData,
      adjudicationRounds: prevData.adjudicationRounds.map((r, i) =>
        i === roundIndex ? round : r,
      ),
    }));
  };

  return (
    <>
      <Heading variant="h3">Approval Path</Heading>
      <BottomBorder />
      <Box sx={{ display: 'flex', flexFlow: 'column', width: '100%' }}>
        {adjudicationProcess?.adjudicationRounds.map((round, i) => {
          return (
            <Box
              sx={{ display: 'flex', flexFlow: 'column', width: '100%' }}
              key={i}
            >
              <Round
                roundIndex={i}
                roundData={round}
                modifyRound={updateRound}
                removeRound={removeRound}
                setAdjudicationProcess={setAdjudicationProcess}
                isTournamentAward={awardType === AwardTypes.TOURNAMENT}
              />
            </Box>
          );
        })}
        <Grid item xs={12}>
          <Button variant="text" onClick={addRound}>
            Add round
          </Button>
        </Grid>
      </Box>
    </>
  );
};

export default ApprovalPath;
