import styled from '@emotion/styled';
import { Alert, Button, MenuItem, Table, Tabs } from '@octanner/prism-core';
import { Link } from 'react-router-dom';
import {
  tannerBlue,
  tannerGray,
  tannerSemantic,
  tannerWhite,
} from '@octanner/prism-core/ThemeProvider/colors';
import Typography from '@octanner/prism-core/Typography';
import { Sort, SortAscending, SortDescending } from '@octanner/prism-icons';
import colors from '../../../common/constants/Colors';

export const AdminHeightTabs = styled(Tabs)`
  height: 60px;
`;

export const PendingChangesBlueCircle = styled.div`
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background-color: ${tannerBlue[500]};
  margin-right: 4px;
`;

export const MBAlert = styled(Alert)`
  margin-bottom: 8px;
`;

export const MeatballMenuCell = styled.div`
  display: flex;
  cursor: pointer;
  justify-content: center;
`;

export const MenuItemGap = styled(MenuItem)`
  align-self: center;
  gap: 8px;
`;

export const LinkBlack = styled(Link)`
  text-decoration: none;
  color: black;
`;

export const CardTransparency = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: ${tannerGray[25]}80; // 50% transparency
  z-index: 2;
  pointer-events: 'none';
`;

export const Chip = styled.div`
  background-color: ${tannerGray[600]};
  color: ${tannerWhite};
  border-radius: 4px;
  padding: 1px 4px;
  font-weight: bold;
  margin-left: 8px;
`;

export const UnsavedChangesChip = styled.div`
  background-color: ${tannerSemantic['error'].color};
  color: ${tannerWhite};
  border-radius: 4px;
  padding: 1px 4px;
  font-weight: bold;
  margin-left: 8px;
`;

export const SortFCM = styled(Sort)`
  font-size: small;
  color: ${colors.gray400};
  margin-left: 4px;
  cursor: pointer;
`;

export const SortAscFCM = styled(SortAscending)`
  font-size: medium;
  color: ${colors.black};
  margin-left: 4px;
  cursor: pointer;
  vertical-align: middle;
`;

export const SortDescFCM = styled(SortDescending)`
  font-size: medium;
  color: ${colors.black};
  margin-left: 4px;
  cursor: pointer;
  vertical-align: middle;
`;

export const TopLayerDiv = styled.div`
  position: relative;
  z-index: 100;
`;

export const PaginationContainer = styled.div`
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 12px;
`;

export const RemoveAllButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-right: 16px;
`;

export const SecondaryButton = styled(Button)`
  margin-right: 16px;
`;

export const SearchTitleAndTypeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 490px;
`;

export const TypographyResultCount = styled(Typography)`
  padding-left: 16px;
  color: ${tannerGray[600]};
`;

export const TypographySearchTitle = styled(Typography)`
  flex: 1;
`;

export const TypographySearchSubTitle = styled(Typography)`
  color: ${tannerGray[500]};
  white-space: normal;
`;

export const TypographySearchType = styled(Typography)`
  color: ${tannerGray[700]};
`;

export const SearchContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TableMT = styled(Table)`
  margin-top: 24px;
`;

export const TypographyPZero = styled(Typography)`
  padding: 0;
`;
