import React from 'react'
import { Modal, Typography, Button } from '@octanner/prism-core'
import { CenteredContent } from 'library/src/common/components/Styled'

// BudgetGroupContainer (budgetGroups) {do:onDelete}
// BudgetGroupForm (copy) {onDelete}
// ConfirmationDialog (yes/no) {text} {onDelete} {onCancel}

// goal
// display confirmation dialog
// region name

// props
// regionName
// onDelete
// onCancel

interface Props {
  regionName: string
  onDelete: () => void
  onCancel: () => void
}

const ConfirmationModal: React.FC<Props> = ({
  regionName,
  onDelete,
  onCancel,
}) => {
  return (
    <Modal
      id="deleteBudgetGroup"
      title="Are you sure?"
      open
      actions={
        <>
          <Button
            data-testid="yps:confirmation-modal:delete"
            onClick={() => {
              onDelete()
            }}
          >
            Delete group
          </Button>
          <Button
            data-testid="yps:confirmation-modal:cancel"
            onClick={onCancel}
            color="secondary"
          >
            Cancel
          </Button>
        </>
      }
    >
      <CenteredContent>
        <Typography>
          Would you like to delete the group `&quot;`{regionName}`&quot;`?
        </Typography>
      </CenteredContent>
    </Modal>
  )
}

export default ConfirmationModal
