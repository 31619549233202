import { useMutation } from '@apollo/client';
import styled from '@emotion/styled';
import { Alert, Button, Modal, Switch, TextField, Typography } from '@octanner/prism-core';
import { Attachment } from '@octanner/prism-icons';
import React, { useContext, useEffect, useState } from 'react';
import { formatDate } from '../../../utils/commonActions';
import { sendForTranslationFields } from '../../../utils/constants';
import { defaults } from '../../../utils/default';
import { SEND_FOR_TRANSLATION_VIA_MAIL } from '../../../utils/query';
import StyledTextField from '../../../utils/styledComponents';
import { JobManagementContext } from './../../../Contexts/JobManagementContext';

const MailField = styled.div`
  margin-top: 18px;
`;

const SwitchContainer = styled.div`
  display : flex;
  align-items : center;
  margin-top : 18px;
`;

const AttachmentBox = styled.div`
  padding: 8px 16px 8px 8px;
  border-radius: 3px;
  display: flex;
  background-color: #F8F8F8;
  margin-top: 12px;
  align-items: center;
  width: fit-content;
  margin-bottom: 12px;
`;

const AttachmentName = styled(Typography)`
  padding: 0px 10px;
`

const SendForTranslationModal = ({
  showSendForTranslationModal,
  setShowSendForTranslationModal,
  jobNumber,
  dateForSend,
  locale,
}) => {

  const [recipientEmail, setRecipientEmail] = useState<string>(sendForTranslationFields.recipientEmail);
  const [subject, setSubject] = useState<string>('');
  const [rushJobInd, setRushJobInd] = useState<boolean>(false);
  const [attachmentName, setAttachmentName] = useState<string>('');
  const [message, setMessage] = useState<string>(sendForTranslationFields.message);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const { send_mail_error, send_mail_success, cancel, send, subject: subjectLabel,
    send_for_translation, attachment, send_to, rush_job, message: messageLabel } = defaults;

  const { searchedTranslationCategory, refetchJobsByFilter, setMessageText } = useContext(JobManagementContext);

  const [sendForTranslationViaMail, { loading }] = useMutation(SEND_FOR_TRANSLATION_VIA_MAIL, {
    onError: () => {
      setErrorMessage(send_mail_error);
    }
  });

  useEffect(() => {
    if (showSendForTranslationModal) {
      const localeObj = locale === 'id-ID' ? 'in-ID': locale;
      setSubject(sendForTranslationFields.subject.replace('{jobNumber}', jobNumber).replace('{locale}', localeObj));
      const attachmentName = `${formatDate(dateForSend)}_${searchedTranslationCategory.toLowerCase()}_translation_${localeObj}_job_${jobNumber}.xlsx`;
      setAttachmentName(attachmentName);
      setMessage(sendForTranslationFields.message.replace('{fileName}', attachmentName).replace('{locale}', localeObj));
    }
  }, [showSendForTranslationModal])

  const closeModal = () => {
    if (loading) {
      return;
    }
    setShowSendForTranslationModal(false);
  }

  const sendForTranslation = () => {
    if (loading) {
      return;
    }
    sendForTranslationViaMail({
      variables: {
        input: {
          jobInfoInput: {
            jobNumber,
            translationCategory: searchedTranslationCategory
          },
          mailDetails: {
            recipientEmail,
            subject,
            message
          }
        }
      }
    }).then((response) => {
      if (!response.errors) {
        refetchJobsByFilter();
        setMessageText('deleteSuccessMessage', '');
        setMessageText('successMessage', send_mail_success);
        closeModal();
      }
    })
  }

  const setRushJob = () => {
    setRushJobInd(prevRushJobInd => {
      const newRushJobInd = !prevRushJobInd;
      setSubject(prevSubject => {
        if (newRushJobInd) {
          return sendForTranslationFields.priority + ' ' + prevSubject;
        } else {
          return prevSubject.replace(sendForTranslationFields.priority, '').trim();
        }
      });
      return newRushJobInd;
    });
  }

  return (
    <Modal
      actions={
        <>
          <Button disabled={loading} onClick={closeModal} color='secondary'>{cancel}</Button>
          <Button disabled={loading || !recipientEmail?.trim().length || !subject?.trim().length} loading={loading}
            adminColor onClick={sendForTranslation}>{send}</Button>
        </>
      }
      id='send-for-translation-modal'
      title={send_for_translation}
      open={showSendForTranslationModal}
      onClose={closeModal}>
      <MailField>
        {errorMessage?.length > 0 && <Alert
          onClose={() => setErrorMessage('')}
          severity="error">
          {errorMessage}
        </Alert>}
      </MailField>
      <MailField>
        <TextField
          fullWidth
          value={recipientEmail}
          disabled
          onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => setRecipientEmail(event.target.value)}
          label={send_to} />
      </MailField>
      <MailField>
        <TextField
          fullWidth
          disabled
          value={subject}
          onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => setSubject(event.target.value)}
          label={subjectLabel} />
      </MailField>
      <SwitchContainer>
        <Switch
          checked={rushJobInd}
          onChange={setRushJob} />
        <Typography variant="body1">
          {rush_job}
        </Typography>
      </SwitchContainer>
      <MailField>
        <StyledTextField
          fullWidth
          multiline
          value={message}
          rows={5}
          onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => setMessage(event.target.value)}
          label={messageLabel} />
      </MailField>
      <MailField>
        <Typography variant='body2' color='textSecondary'>
          {attachment}
        </Typography>
        <AttachmentBox>
          <Attachment />
          <AttachmentName variant='body1'>
            {attachmentName}
          </AttachmentName>
        </AttachmentBox>
      </MailField>
    </Modal>);
}

export default SendForTranslationModal;