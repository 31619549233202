export enum FeatureCode {
  Core = 'CORE',
  Victories = 'VIC',
  Align = 'ALIGN',
  CoreIDP = 'IDP',
  Yearbook = 'YB',
  Wellness = 'WELLNESS',
  CultureCloud = 'CC',
  Initiatives = 'INITIATIVE',
  Recognition = 'REC',
  Empty = '',
}

export interface BaseFeature {
  code: FeatureCode;
  name: string;
}

export interface FeatureListConnection {
  allFeatures: {
    nodes: BaseFeature[];
  };
}

export interface CustomerFeature extends BaseFeature {
  effectiveDate: string | null;
  expirationDate: string | null;
}

export interface CoreFeature {
  id: number;
  effectiveTsz: string | null;
  expirationTsz: string | null;
  feature: {
    featureCode: FeatureCode;
    featureName: string;
  };
}
export interface CoreFeaturesResponse {
  nodes: CoreFeature[];
}

export default interface Config {
  CORE_ADMIN_URL: string;
  graphqlUri: string;
  termsUri: string;
  vibesUri: string;
  cultureCloudUrl: string;
  impersonationRoles: string[];
  translationsUrl: string;
  launchDarklyClientId?: string;
}
