import React, { createContext, ReactNode } from 'react';
import { DecodedToken, CustomerPermissions } from '../auth/Auth';
import Config from '../../common/models/Config';

export interface AppContextProps {
  config: Config;
  children: ReactNode;
  decodedToken: DecodedToken;
  permissions: CustomerPermissions[];
}

export interface AppContextData {
  permissions: CustomerPermissions[];
  config: Config;
  decodedToken: DecodedToken;
}

const Context = createContext<AppContextData>({
  config: {
    CORE_ADMIN_URL: '',
    graphqlUri: '',
    termsUri: '',
    vibesUri: '',
    cultureCloudUrl: '',
    impersonationRoles: [],
    translationsUrl: '',
    recognitionLaunchDarklyClientId: '',
  },
  decodedToken: { cus: '', imp: '', rls: [], exp: 0 },
  permissions: [],
});
const { Provider, Consumer } = Context;

export default function AppContextComponent({
  children,
  config,
  decodedToken,
  permissions,
}: AppContextProps) {
  return (
    <Provider value={{ config, decodedToken, permissions }}>
      {children}
    </Provider>
  );
}

export const AppConsumer = Consumer;
export const AppContext = Context;
