import { BusinessUnit } from '../../../graphql/business-unit/models';
import { BaseFeature } from '../../../graphql/core-feature/models';
import { formatDate, isEndOfTime } from '../../../common/models';
import { CountryBase } from '../../../graphql/country/models';

export enum EligibilitySet {
  include = 'INCLUDE',
  exclude = 'EXCLUDE',
}

export enum ProgramTypeCodeEnum {
  BIRTHDAY = 'BIRTHDAY',
  CAREER_ANNIVERSARY = 'CAREER_ANNIVERSARY',
  RETIREMENT = 'RETIREMENT',
}

export const RestrictedProgramTypes = [
  ProgramTypeCodeEnum.BIRTHDAY,
  ProgramTypeCodeEnum.RETIREMENT,
  ProgramTypeCodeEnum.CAREER_ANNIVERSARY,
];

export const PROGRAM_NAME_LENGTH = 100;

export interface ProgramType {
  id: number;
  programTypeName: string;
  programTypeCode: ProgramTypeCodeEnum;
}

export interface ProgramTypeWithFeature extends ProgramType {
  feature: BaseFeature;
}

export interface Program {
  id: number;
  programUuid: string;
  programName: string;
  startTsz: string;
  endTsz: string;
  displayTz?: string;
  eligibleCount: {
    eligibleIdentityCount: number;
  };
}

export interface BusinessUnitEligibility {
  businessUnit: BusinessUnit;
  eligibilitySet: EligibilitySet;
}

export interface CountryEligibility {
  coreCountry: CountryBase;
  eligibilitySet: EligibilitySet;
}

export interface ProgramEligibility {
  programEligibilityUuid: string;
  allParticipants: boolean;
  businessUnitEligibilities: BusinessUnitEligibility[];
  homeCountryEligibilities: CountryEligibility[];
  workCountryEligibilities: CountryEligibility[];
}

interface BusinessUnitEligibilityInput {
  buUuid: string;
  eligibilitySet: EligibilitySet;
}

interface CountryEligibilityInput {
  countryIso2Code: string;
  eligibilitySet: EligibilitySet;
}

export interface ProgramEligibilityInput {
  allParticipants: boolean;
  businessUnitEligibilities: BusinessUnitEligibilityInput[];
  homeCountryEligibilities: CountryEligibilityInput[];
  workCountryEligibilities: CountryEligibilityInput[];
}

export interface CreateProgramEligibilityInput extends ProgramEligibilityInput {
  programUuid: string;
}

export interface UpdateProgramEligibilityInput
  extends CreateProgramEligibilityInput {
  programEligibilityUuid: string;
}

export interface CreateProgramEligibilityResponse {
  createNewCoreProgramEligibility: ProgramEligibility;
}

export interface UpdateProgramEligibilityResponse {
  updateOneCoreProgramEligibility: ProgramEligibility;
}

export interface ProgramViewModel extends Program {
  startDate?: Date;
  endDate?: Date;
  startText: string;
  endText?: string;
  isFuture: boolean;
  feature: string;
  type: string;
}

export interface ProgramListViewModel {
  uuid: string;
  name: string;
  feature: string;
  status: string;
  startTsz: number;
  participants: number;
  startText: string;
  endText: string;
}

export interface ProgramWithType extends Program {
  coreProgramType: ProgramTypeWithFeature;
}

export interface GetProgramTypesResponse {
  coreProgramTypes: {
    coreProgramTypes: {
      nodes: ProgramTypeWithFeature[];
    };
  };
}

export interface CreateProgramInput {
  coreProgramTypeId: number;
  programName: string;
  customerId: string;
}

export interface CreateProgramResponse {
  createNewCoreProgram: {
    programUuid: string;
  };
}

export interface GetProgramResponse {
  coreProgramByProgramUuid: {
    coreProgram: ProgramWithType;
  };
  coreProgramEligibilityByProgram: {
    programEligibility: ProgramEligibility;
  };
}

export interface UpdateProgramForm {
  programName: string;
  startTsz?: number;
  endTsz?: number;
  displayTz?: string;
}

export interface UpdateProgramFormIdRequired extends UpdateProgramForm {
  id: number;
}

export interface UpdateProgramRequest {
  request: UpdateProgramFormIdRequired;
}

export interface UpdateProgramResponse {
  updateOneCoreProgram: ProgramWithType;
}

export interface GetProgramsByCustomerResponse {
  coreProgramsByCustomer: {
    nodes: ProgramWithType[];
    pageInfo: {
      pageNumber: number;
      totalPages: number;
    };
  };
}

export interface GetProgramsByCustomerInput {
  customerId: string;
  pageNumber: number;
  pageSize: number;
}

export interface Status {
  text: string;
  severity: 'success' | 'warning' | 'error' | 'info' | 'archived';
  isStatus: (vm: ProgramViewModel) => boolean;
}

const buildStatus: Status = {
  text: 'Build',
  severity: 'warning',
  isStatus: (program: ProgramViewModel) =>
    !program.startDate || program.startDate.getTime() > Date.now(),
};

const activeStatus: Status = {
  text: 'Active',
  severity: 'success',
  isStatus: (program: ProgramViewModel) =>
    Boolean(
      program.startDate &&
        program.startDate.getTime() < Date.now() &&
        (!program.endDate || program.endDate.getTime() > Date.now())
    ),
};

export const archiveStatus: Status = {
  text: 'Completed',
  severity: 'archived',
  isStatus: (program: ProgramViewModel) =>
    Boolean(program.endDate && program.endDate.getTime() < Date.now()),
};

export const statuses: Status[] = [buildStatus, activeStatus, archiveStatus];

export const findStatus = (input: ProgramViewModel | string): Status => {
  let status: Status | undefined;
  if (typeof input === 'string') {
    status = statuses.find((s) => s.text === input);
  } else {
    status = statuses.find((s) => s.isStatus(input));
  }
  if (!status) {
    throw new Error('invalid status');
  }
  return status;
};

export function programToViewModel(program: ProgramWithType): ProgramViewModel {
  const startDate = !isEndOfTime(program.startTsz)
    ? new Date(program.startTsz)
    : undefined;
  const endDate = !isEndOfTime(program.endTsz)
    ? new Date(program.endTsz)
    : undefined;
  return {
    ...program,
    startDate,
    endDate,
    startText: startDate ? formatDate(startDate) : 'No start date',
    endText: !isEndOfTime(program.endTsz)
      ? formatDate(new Date(program.endTsz))
      : undefined,
    isFuture: !startDate || startDate > new Date(),
    feature: program.coreProgramType.feature.name,
    type: program.coreProgramType.programTypeName,
  };
}

export function toProgramListVM(
  program: ProgramWithType
): ProgramListViewModel {
  const pvm = programToViewModel(program);
  const status = findStatus(pvm);
  const { startDate, endDate } = pvm;
  return {
    uuid: program.programUuid,
    name: program.programName,
    feature: program.coreProgramType.feature.name,
    status: status.text,
    startTsz: pvm.startDate?.getTime() || Number.POSITIVE_INFINITY,
    participants: program.eligibleCount.eligibleIdentityCount,
    startText: startDate ? formatDate(startDate) : '',
    endText: endDate ? formatDate(endDate) : '',
  };
}
