import React from 'react';
import styled from '@emotion/styled';
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  Box,
} from '@octanner/prism-core';
import { AwardTypes } from '../types';

const Heading = styled(Typography)`
  margin-top: 10px;
  margin-bottom: 10px;
`;

const RadioGroupContainer = styled(RadioGroup)`
  && {
    display: flex;
    flex-flow: row;
    gap: 10px;
    padding: 10px;
  }
`;

const AwardType: React.FC<Props> = ({
  awardType,
  isNew,
  handleAwardTypeSelection,
}) => {
  return (
    <Box>
      <Heading variant="h3">Award type</Heading>
      <RadioGroupContainer
        aria-label="award type"
        name="award-type"
        value={awardType || ''}
        onChange={(event) => handleAwardTypeSelection(event.target.value)}
      >
        <FormControlLabel
          disabled={!isNew}
          value={AwardTypes.ECARD}
          control={<Radio />}
          label={<Typography variant="h4">eCard</Typography>}
        />
        <FormControlLabel
          disabled={!isNew}
          value={AwardTypes.NOMINATION}
          control={<Radio />}
          label={<Typography variant="h4">Nomination award</Typography>}
        />
        <FormControlLabel
          disabled={!isNew}
          value={AwardTypes.TOURNAMENT}
          control={<Radio />}
          label={<Typography variant="h4">Tournament-style award</Typography>}
        />
      </RadioGroupContainer>
    </Box>
  );
};

interface Props {
  awardType: AwardTypes;
  handleAwardTypeSelection: (awardType: string) => void;
  isNew: boolean;
}

export default AwardType;
