import React, { useState } from 'react';
import {
  Divider,
  Typography,
  MenuItem,
  Checkbox
} from '@octanner/prism-core';
import {
    SalesOrgSelect,
    StyledDiv,
    SelectDiv,
    AddButtonDiv,
    AddIconDiv,
    SalesOrgDiv,
    DisplayFlex, SingleRecommendedDiv, StyledButton, DropDownDivButton
} from './style';
import { useTranslation } from 'react-i18next';
import { DisplaySalesOrgProps } from './types';
import {
  DisplaySalesOrgFlexContainer,
  DisplayCountryContainer,
  DisplayCountryCodeContainer,
  RecommendedSalesOrg,
  SingleRecommendedSalesOrg,
} from './style';

import styled from "@emotion/styled";
import {Add} from "@octanner/prism-icons";

const AddIcon = styled(Add)`
  margin-right: 4px;
`;

const DisplaySalesOrg: React.FC<DisplaySalesOrgProps> = ({
    countryWithSalesOrg,
    handleSalesOrgSelection,
    setSalesOrgDataChanged,
    ccAdminEnableComboModel
    }) => {

  const {
    coreCountry, checked,
    eligibleSalesOrgs, recommendedSalesOrg,
    salesOrgCodes
  } = countryWithSalesOrg;

  const [selectedSalesOrg, setSelectedSalesOrg] = useState(checked);
  const [salesOrgSelections, setSalesOrgSelections] = useState<string[]>(salesOrgCodes);
  const [isCheckBoxSelected, setIsCheckBoxSelected] = useState(false);
  const addDropdown = () => {
    const newSalesOrgCode = eligibleSalesOrgs.find(eligibleSalesOrg =>
        !salesOrgSelections.some(selection => selection === eligibleSalesOrg.salesOrgCode)
    )?.salesOrgCode;

    if (newSalesOrgCode) {
      setSalesOrgSelections([...salesOrgSelections, newSalesOrgCode]);
      handleSalesOrgSelection({ ...countryWithSalesOrg, salesOrgCodes: [...salesOrgSelections, newSalesOrgCode] }, selectedSalesOrg);
      setSalesOrgDataChanged(true);
    }
  };
  const removeDropdown = (indexToRemove) => {
    const updatedSelections = salesOrgSelections.filter((_, index) => index !== indexToRemove);
    setSalesOrgSelections(updatedSelections);
    setSalesOrgDataChanged(true);
    handleSalesOrgSelection({ ...countryWithSalesOrg, salesOrgCodes: updatedSelections }, selectedSalesOrg);
  };
  const handleCheck = () => {
    setSalesOrgDataChanged(true);
    handleSalesOrgSelection(countryWithSalesOrg, !selectedSalesOrg);
    setSelectedSalesOrg(!selectedSalesOrg);
  };
  const handleOnChange = (value, index) => {
    const updatedSelections = salesOrgSelections.map((selection, idx) =>
        idx === index ?  value : selection
    );
    setSalesOrgSelections(updatedSelections);
    handleSalesOrgSelection({...countryWithSalesOrg, salesOrgCodes: updatedSelections}, selectedSalesOrg);
  };



  const { t } = useTranslation();

  return (
      <>
        <DisplaySalesOrgFlexContainer>
          <DisplayCountryContainer>
            <div>
            <Checkbox
                defaultChecked={selectedSalesOrg}
                onChange={() => {handleCheck()}}
                test-id="sales-org-checkbox"
            />
            </div>
            <Typography test-id="sales-org-country-name">{coreCountry?.countryName}</Typography>
          </DisplayCountryContainer>
          <DisplayCountryCodeContainer test-id="sales-org-country-code">
            {coreCountry?.iso2Code}
          </DisplayCountryCodeContainer>
          <AddButtonDiv>
          <StyledDiv>
            {eligibleSalesOrgs.length >= 2 ? (
                <SelectDiv>
                  {salesOrgSelections.map((selection, index) => (
                      <SalesOrgDiv key={index}>
                        <DisplayFlex>
                        <SalesOrgSelect
                            id={`salesOrg${index}`}
                            value={selection}
                            label={t('sales_org')}
                            onChange={(event) => handleOnChange(event.target.value, index)}
                            test-id={`sales-org-select-${index}`}
                            disabled={!checked && !isCheckBoxSelected}
                        >
                          {eligibleSalesOrgs.map((eligibleSalesOrg) => (
                              <MenuItem
                                  value={eligibleSalesOrg.salesOrgCode}
                                  key={eligibleSalesOrg.salesOrgCode}
                                  test-id={`sales-org-menu-item-${index}`}
                                  disabled={
                                      salesOrgSelections.some((selection, idx) => idx !== index && selection === eligibleSalesOrg.salesOrgCode)
                                  }
                              >
                                {recommendedSalesOrg?.salesOrgCode === eligibleSalesOrg.salesOrgCode ? (
                                    <>
                                      {`${eligibleSalesOrg.salesOrgCode} - ${eligibleSalesOrg.salesOrgName}`}
                                      <RecommendedSalesOrg>
                                        {`\u00A0`}{t('recommended')}
                                      </RecommendedSalesOrg>
                                    </>
                                ) : (
                                    `${eligibleSalesOrg.salesOrgCode} - ${eligibleSalesOrg.salesOrgName}`
                                )}
                              </MenuItem>
                          ))}
                        </SalesOrgSelect>
                          {salesOrgSelections.length > 1 && (
                                <StyledButton disabled={!checked && !isCheckBoxSelected} onClick={() => removeDropdown(index)} variant="text" color="secondary" buttonType="danger">{t('remove')}</StyledButton>
                          )}
                        </DisplayFlex>
                      </SalesOrgDiv>
                  ))}
                </SelectDiv>): (
                <SingleRecommendedDiv>
                  <SingleRecommendedSalesOrg>
                  {`${eligibleSalesOrgs[0]?.salesOrgCode} - ${eligibleSalesOrgs[0]?.salesOrgName}`}
                </SingleRecommendedSalesOrg>
                </SingleRecommendedDiv>
            )}
          </StyledDiv>
            {ccAdminEnableComboModel && (
                <DropDownDivButton onClick={addDropdown} disabled={eligibleSalesOrgs.length === 1 || salesOrgSelections.length === eligibleSalesOrgs.length || !checked && !isCheckBoxSelected} variant="text">
                  <AddIconDiv>
                    <AddIcon/>
                    {t('add_another')}
                  </AddIconDiv>
                </DropDownDivButton>
            )}
          </AddButtonDiv>
        </DisplaySalesOrgFlexContainer>
        <Divider style={{ marginTop: '-7px' }} />
      </>
  );
};

export default DisplaySalesOrg;
