import { gql } from '@apollo/client';

export const PERMISSION_FRAGMENT = gql`
  fragment PermissionFragment on Permission {
    uuid
    name
  }
`;

export const ROLE_FRAGMENT = gql`
  fragment RoleFragment on Role {
    uuid
    name
    description
    accessType
    createdTsz
    deactivatedTsz
  }
`;

export const ROLE_PERMISSIONS_FRAGMENT = gql`
  ${PERMISSION_FRAGMENT}

  ${ROLE_FRAGMENT}

  fragment RolePermissionsFragment on Role {
    ...RoleFragment
    permissions {
      ...PermissionFragment
    }
  }
`;

export const ADMIN_ROLE_FRAGMENT = gql`
  ${ROLE_FRAGMENT}

  fragment AdminRoleFragment on RdAdminRole {
    name
    role {
      ...RoleFragment
    }
    assignedOnDate
    removedOnDate
    permissionCount
    adminCount
    clientCount
  }
`;

export const VALIDATION_ERROR_FRAGMENT = gql`
  fragment ValidationErrorFragment on RdValidationError {
    field
    errorMessage
  }
`;
