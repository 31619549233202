import { gql, useQuery } from '@apollo/client'

const GET_LANGUAGES = gql`
  query {
    ybSupportedLocales {
      supportedLocales
    }
  }
`

interface YbSupportedLocalesPayload {
  ybSupportedLocales: { supportedLocales: string[] }
}

export function useGetLanguages() {
  const { data } = useQuery<YbSupportedLocalesPayload>(GET_LANGUAGES)
  return data?.ybSupportedLocales.supportedLocales || []
}
