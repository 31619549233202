import React from 'react';
import { Typography, Grid } from '@octanner/prism-core';
import styled from '@emotion/styled';

interface ExtendedTypographyProps
  extends React.ComponentProps<typeof Typography> {
  marginTopIncrease?: boolean;
}

const ExtendedTypography: React.FC<ExtendedTypographyProps> = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  marginTopIncrease,
  ...rest
}) => <Typography {...rest} />;

export const Heading = styled(ExtendedTypography)<{
  marginTopIncrease?: boolean;
}>`
  && {
    margin-bottom: 24px;
    ${(props) => (props.marginTopIncrease ? 'margin-top: 32px;' : '')}
  }
`;

export const PaddedTypography = styled(Typography)`
  padding: 16px;
`;
export const CenterDiv = styled(Grid)`
  display: flex;
  align-items: center;
`;
export const StartDiv = styled(Grid)`
  display: flex;
  align-items: start;
`;
export const FlexyBetweenGrid = styled(Grid)`
  display: flex;
  justify-content: space-between;
  align-content: center;
`;

export const FlexyGrid = styled(Grid)`
  display: flex;
  width: 100%;
`;

export const FlexEndGrid = styled(Grid)`
  display: flex;
  justify-content: flex-end;
`;

export const BottomBorder = styled.div`
  border-bottom: solid 1px #e1e1e1;
  margin-top: 16px;
  margin-bottom: 0;
  padding: 0;
`;
