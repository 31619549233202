import { Card, Button, Tag, Typography } from '@octanner/prism-core'
import { styled } from '@mui/material/styles'
import colors from 'library/src/common/colors'
import {
  ActionSection,
  StyledCardContent,
} from 'library/src/common/components/Styled'
import { YbAccrualPointsRegion } from 'library/src/common/models/YbAccrualPointsRegion'
import React from 'react'

const TagPlaceholder = styled('article')`
  width: 65px;
  height: 25px;
`
export const PointContainer = styled('section')`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: start;
`
export const RankPoints = styled('article')`
  background: ${colors.gray100};
  border-radius: 3px;
  padding: ${({ theme }) => theme.spacing(3)};
  min-width: 42px;
`
type Props = {
  pointsRegion: YbAccrualPointsRegion
  allCountries: boolean
  onEdit: (regionId: string) => void
}

export default function GroupPointsCard({
  pointsRegion,
  allCountries,
  onEdit,
}: Props): JSX.Element {
  const sortedValues = [...pointsRegion.values].sort(
    (first, second) =>
      first.celebrationLevel.rank - second.celebrationLevel.rank
  )
  return (
    <Card data-testid={`groupPointCard:${pointsRegion.id}`}>
      <StyledCardContent
        style={{ gridTemplateRows: 'auto auto auto auto 1fr' }}
      >
        <Typography
          variant="subtitle2"
          data-testid={`groupPointsCard:regionName`}
        >
          {pointsRegion.name}
        </Typography>
        {pointsRegion.defaultRegion ? (
          <Tag
            style={{ width: 'max-content' }}
            variant="archived"
            data-testid="groupPointsCard:defaultTag"
          >
            Default
          </Tag>
        ) : (
          <TagPlaceholder />
        )}

        <Typography>Amount employees should receive per year level:</Typography>

        <PointContainer>
          {sortedValues.map((value) => (
            <RankPoints
              key={value.celebrationLevel.id}
              data-testid={`groupPointsCard:rankpoints:${value.celebrationLevel.rank}`}
            >
              <Typography variant="body2" data-testid={`groupPointsCard:rank`}>
                {value.celebrationLevel.rank}
              </Typography>
              <Typography data-testid={`groupPointsCard:points`}>
                {value.pointsAmount}
              </Typography>
            </RankPoints>
          ))}
        </PointContainer>
        <section>
          <Typography
            variant="body2"
            data-testid={`groupPointsCard:countryFooter`}
          >
            Includes {allCountries ? 'all' : pointsRegion.countries.length}
            {allCountries || pointsRegion.countries.length > 1
              ? ' countries'
              : ' country'}
          </Typography>
        </section>
        <ActionSection>
          <Button
            data-testid="groupPointsCard:editLink"
            variant="text"
            onClick={() => onEdit(pointsRegion.id)}
          >
            Edit Group
          </Button>
        </ActionSection>
      </StyledCardContent>
    </Card>
  )
}
