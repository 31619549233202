import { useMutation } from '@apollo/client';

import {
  UpdateSocialFilterInput,
  UpdateSocialFilterResponse,
} from '../../models/socialWall';
import {
  ACTIVATE_SOCIAL_FILTER,
  DEACTIVATE_SOCIAL_FILTER,
  SELECT_SOCIAL_FILTER,
  DESELECT_SOCIAL_FILTER,
} from '../../graphql/awardLevels/awardLevel';

// TODO: Update these mutations with correct input
export const useActivateSocialFilter = (customerUuid: string) =>
  useMutation<UpdateSocialFilterResponse, UpdateSocialFilterInput>(
    ACTIVATE_SOCIAL_FILTER
  );

export const useDeactivateSocialFilter = (customerUuid: string) =>
  useMutation<UpdateSocialFilterResponse, UpdateSocialFilterInput>(
    DEACTIVATE_SOCIAL_FILTER
  );

export const useSelectSocialFilter = (customerUuid: string) =>
  useMutation<UpdateSocialFilterResponse, UpdateSocialFilterInput>(
    SELECT_SOCIAL_FILTER
  );

export const useDeselectSocialFilter = (customerUuid: string) =>
  useMutation<UpdateSocialFilterResponse, UpdateSocialFilterInput>(
    DESELECT_SOCIAL_FILTER
  );
