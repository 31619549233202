import styled from '@emotion/styled';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@octanner/prism-core';
import React, { useContext } from 'react';
import { MaterialStringsContext } from '../../Contexts/MaterialStringsContext';
import { defaults } from '../../utils/default';

const RadioButtonContainerBody = styled.div`
  margin-top: 24px;
  margin-left: 8px;
`;

const RadioFormControlLabel = styled(FormControlLabel)`
  margin-right: 36px;
`;

const RadioButtonContainer: React.FC = () => {

  const {
    searchBy, setSearchBy, setMessageText,
    setSearchText, setDataNotFound,
    setSearchLocale, setMmId, materialDescriptionLoading,
    setSearchData } = useContext(MaterialStringsContext);

  const searchByChange = (event: React.ChangeEvent<{ value: string }>) => {
    setSearchBy(event.target.value);
    setMessageText('errorMessage', '');
    setSearchData(null);
    setSearchText('');
    setSearchLocale('en-US');
    setMmId('');
    setDataNotFound(false);
  }

  return (
    <RadioButtonContainerBody>
      <FormControl>
        <RadioGroup row aria-label='searchBy' name='searchBy'
          value={searchBy} onChange={searchByChange}>
          <RadioFormControlLabel value='mmId' control={<Radio />} label={defaults.search_material_master_id} disabled={materialDescriptionLoading} />
          <RadioFormControlLabel value='string' control={<Radio />} label={defaults.search_by_description} disabled={materialDescriptionLoading} />
        </RadioGroup>
      </FormControl>
    </RadioButtonContainerBody>
  )
}

export default RadioButtonContainer;