import {
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from '@octanner/prism-core';
import MenuDotsIcon from '@octanner/prism-icons/MenuDots';
import React from 'react';
import { CustomerBase } from '../customer/models/Customer';
import BinaryDialog from '../../common/BinaryDialog';
import { ClientMenuProps, CustomerAction } from './types';
import { useStyles } from './styles';

const ClientMenu = (props: ClientMenuProps & { client: CustomerBase }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = React.useState(false);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (callback?: CustomerAction) => () => {
    setAnchorEl(null);
    callback && callback(props.client);
  };

  return (
    <div className={classes.root}>
      <BinaryDialog
        description="Are you sure you want to delete this client? It cannot be undone."
        primaryText="Delete"
        title="Delete Client"
        onPrimaryClick={handleClose(props.onDeleteClick)}
        onSecondaryClick={() => setDeleteModalIsOpen(false)}
        open={deleteModalIsOpen}
      />
      <IconButton
        data-test={`clients:menu:${props.client.id}`}
        aria-label="client options"
        aria-controls="client-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MenuDotsIcon />
      </IconButton>
      <Menu
        id="client-menu"
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={handleClose()}
      >
        <MenuItem
          data-test="clients:menu:edit"
          onClick={handleClose(props.onEditClick)}
        >
          <Typography variant="subtitle2">Edit</Typography>
        </MenuItem>
        <MenuItem
          data-test="clients:menu:go-to"
          onClick={handleClose(props.onGoToClick)}
        >
          <Typography variant="subtitle2">Go to client page</Typography>
        </MenuItem>
        <Divider />
        <MenuItem
          data-test="clients:menu:delete"
          onClick={handleClose(() => setDeleteModalIsOpen(true))}
        >
          <Typography className={classes.deleteAction} variant="subtitle2">
            Delete
          </Typography>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default ClientMenu;
