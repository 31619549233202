import { gql } from '@apollo/client';

// Initiative Migration
export const INITIATIVE_CREATE_MIGRATION = gql`
  mutation ccCreateInitiativeMigration($coreCustomerUuid: UUID!) {
    ccCreateInitiativeMigration(coreCustomerUuid: $coreCustomerUuid) {
      initiativeMigrationReport {
        initiativeMigrationUuid
      }
    }
  }
`;

export const INITIATVES_RUN_PREMIGRATION_CHECK = gql`
  mutation ccRunPreInitiativeMigrationStatusCheck($migrationUuid: UUID!) {
    ccRunPreInitiativeMigrationStatusCheck(migrationUuid: $migrationUuid) {
      initiativeMigrationReport {
        initiativeMigrationUuid
        migrationCompletedTsz
        migrationFailedTsz
        classicToCCInitiativesMigration {
          preInitiativeMigrationStatus {
            initiativesEnabled
            initiativesMigratedCustomer
            coreMigrationCompleted
          }
        }
      }
    }
  }
`;

export const INITIATIVES_TRIGGER_MIGRATION = gql`
  mutation ccInitiativeMigrationTriggerProc($migrationUuid: UUID!) {
    ccInitiativeMigrationTriggerProc(migrationUuid: $migrationUuid) {
      accepted
    }
  }
`;
