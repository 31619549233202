import React, { Dispatch, SetStateAction } from 'react';
import {
  Button,
  MenuItem,
  Switch,
  Grid,
  FormControlLabel,
  Select,
  Box,
} from '@octanner/prism-core';
import {
  FlexyBetweenGrid,
  FlexyGrid,
  FlexEndGrid,
  CenterDiv,
  BottomBorder,
  PaddedTypography,
} from '../styles';
import { Round as RoundType, RoundTypes, Screeners } from './types';
import Approvers from './Approvers';
import { AdjudicationConfiguration } from './types';
interface RoundProps {
  roundIndex: number;
  roundData: RoundType;
  modifyRound: (round: RoundType, roundIndex: number) => void;
  removeRound: (roundIndex: number) => void;
  setAdjudicationProcess: Dispatch<SetStateAction<AdjudicationConfiguration>>;
  isTournamentAward: boolean;
}

const Round: React.FC<RoundProps> = ({
  roundData,
  roundIndex,
  modifyRound,
  removeRound,
  isTournamentAward,
  setAdjudicationProcess,
}) => {
  const handleChange = ({ target: { value, name } }) => {
    if (name === 'screener') {
      modifyRound(
        {
          ...roundData,
          screener: {
            ...roundData.screener,
            screener: value,
          },
        },
        roundIndex,
      );
    } else {
      modifyRound({ ...roundData, [name]: value }, roundIndex);
    }
  };

  const roundTypes = [{ value: RoundTypes.SCREENING, name: 'Review' }];
  const screeners = [
    {
      value: Screeners.CANDIDATE_MANAGER,
      name: 'Management line of the candidate',
    },
    {
      value: Screeners.NOMINATOR_MANAGER,
      name: 'Management line of the nominator',
    },
    {
      value: Screeners.DEFINED_APPROVER,
      name: 'Assign by business unit',
    },
  ];

  const showRemoveRoundOption = !(isTournamentAward && roundIndex === 0);

  return (
    <>
      <FlexyBetweenGrid container>
        <Grid item xs={3}>
          <PaddedTypography>Round {roundIndex + 1}</PaddedTypography>
        </Grid>
        <FlexEndGrid item xs={3}>
          {showRemoveRoundOption && (
            <Button
              variant="text"
              onClick={() => removeRound(roundIndex)}
              data-testid="Round:remove"
            >
              Remove
            </Button>
          )}
        </FlexEndGrid>
      </FlexyBetweenGrid>
      <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: 3 }}>
        <FlexyGrid item xs={4}>
          <Select
            label="Round Type"
            name="adjudicationType"
            fullWidth
            value={roundData.adjudicationType}
            onChange={handleChange}
            disabled
          >
            {roundTypes.map(({ name, value }) => (
              <MenuItem value={value} key={value}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FlexyGrid>
        <Grid item xs={4}>
          <Select
            id={`approvers-for-round`}
            label="Approvers for round"
            name="screener"
            fullWidth
            value={roundData.screener.screener}
            onChange={(event) => handleChange(event)}
          >
            {screeners.map(({ name, value }) => (
              <MenuItem value={value} key={value}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        {isTournamentAward && (
          <CenterDiv item xs={3}>
            <FormControlLabel
              sx={{ ml: 0 }}
              control={
                <Switch
                  checked={roundData.commentOnlyRound}
                  name="commentOnlyRound"
                  onChange={({ target: { name, checked } }) =>
                    modifyRound({ ...roundData, [name]: checked }, roundIndex)
                  }
                />
              }
              label="Comment-only round"
            />
          </CenterDiv>
        )}
      </Box>
      {roundData.screener.screener === Screeners.DEFINED_APPROVER && (
        <Approvers
          loadingApprovers={false}
          roundIndex={roundIndex}
          setAdjudicationProcess={setAdjudicationProcess}
          roundData={roundData}
        />
      )}
      <BottomBorder />
    </>
  );
};

export default Round;
