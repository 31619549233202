import { Typography } from '@octanner/prism-core'
import { styled } from '@mui/material/styles'
import {
  AddNewButton,
  HeaderContainer,
} from 'library/src/common/components/Styled'
import React from 'react'
import { ProgramRepresentation } from '../../common/models/ProgramRepresentation'
import GroupPointsCard from './GroupPointsCard/GroupPointsCard'

export const Header = styled(Typography)`
    margin-bottom: ${({ theme }) => theme.spacing(5)};
  `,
  CardRow = styled('div')`
    display: grid;
    grid-gap: ${({ theme }) => theme.spacing(5)};
    grid-template-columns: repeat(3, 1fr [col-start]);
  `

type Props = {
  program?: ProgramRepresentation
  onEditAccrualPointRegion: (regionId: string) => void
  onAddRegion: () => void
  hideEdit?: boolean
}

export default function CelebrationGroupPointsView({
  program,
  onEditAccrualPointRegion,
  onAddRegion,
  hideEdit,
}: Props): JSX.Element {
  const pointsRegions = program?.accrualPointsRegions ?? []
  return (
    <section data-testid="mainSettings:celebrationGroupPoints:container">
      <HeaderContainer>
        <Header variant="h3">Celebration Group Points</Header>
        {!hideEdit && (
          <AddNewButton
            data-testid="mainSettings:celebrationGroupPoints:container:addNew"
            variant="text"
            onClick={() => onAddRegion()}
          >
            Add New
          </AddNewButton>
        )}
      </HeaderContainer>

      <CardRow>
        {pointsRegions.map((region) => (
          <GroupPointsCard
            key={region.id}
            pointsRegion={region}
            allCountries={pointsRegions.length === 1}
            onEdit={onEditAccrualPointRegion}
          />
        ))}
      </CardRow>
    </section>
  )
}
