import { ApolloError } from '@apollo/client';
import React from 'react';

const SetErrorContext =
  React.createContext<React.Dispatch<React.SetStateAction<string>>>(null);

const useSetError = () => {
  const setError = React.useContext(SetErrorContext);
  return React.useCallback(
    (error: ApolloError | string) => setError(error && error.toString()),
    [setError],
  );
};

export { useSetError, SetErrorContext };
