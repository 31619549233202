import { TextField, Autocomplete, MenuItem } from '@octanner/prism-core';
import { useContext, useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { AppContext } from '../../utils/context/AppContext';
import { useIdentitySearch } from '../user-auth/hooks/identity';
import { formatName, IdentityBase } from '../user-auth/models/Identity';
import { IdentitySelectorProps } from './types';

const IdentitySelector = ({
  selected,
  onChange,
  className,
  hideIdentities = [],
}: IdentitySelectorProps) => {
  const { decodedToken } = useContext(AppContext);
  const [searchText, setSearchText] = useState<string>('');
  const { data, loading } = useIdentitySearch({
    name: searchText,
    customerId: searchText ? decodedToken.cus : '',
    active: true,
    internalUser: true,
  });
  const [options, setOptions] = useState<IdentityBase[]>([]);

  useEffect(() => {
    const identityOptions = data?.identitySearch.identities || [];
    setOptions(identityOptions);
  }, [data]);

  const handleTextFieldChange = useDebouncedCallback(setSearchText, 500);

  const hideIds = hideIdentities.map((i) => i.id);
  const filterOptions = (options: IdentityBase[]) =>
    options.filter((o) => !hideIds.includes(o.id));

  const isReadonly = !Boolean(onChange);
  const handleChange = (_: any, o: IdentityBase | null) => {
    if (!onChange) {
      return;
    }
    onChange(o || undefined);
  };

  return (
    <Autocomplete
      className={className}
      loading={loading}
      onChange={handleChange}
      options={options}
      getOptionLabel={(c) => formatName(c)}
      filterOptions={filterOptions}
      filterSelectedOptions
      value={selected || null}
      disabled={isReadonly}
      renderOption={(props: any, options: any) => (
        <MenuItem {...props}>
          <div data-test={`identity-selector:identity:${options.id}`}>
            {formatName(options)}
          </div>
        </MenuItem>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          label="User"
          placeholder="Search by name"
          value={searchText}
          onChange={(e) => handleTextFieldChange(e.target.value)}
          inputProps={{
            ...params.inputProps,
            'data-test': 'identity-selector',
          }}
        />
      )}
    />
  );
};

export default IdentitySelector;
