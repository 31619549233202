import React, { Dispatch, SetStateAction } from 'react';
import { Typography } from '@octanner/prism-core';
import { FlexyBetweenGrid, StartDiv } from '../styles';
import styled from '@emotion/styled';
import ApproverSearch from './ApproverSearch';
import BusinessUnitSearch from './BusinessUnitSearch';
import AddApprover from './AddApprover';
import { AdjudicationConfiguration, Round as RoundType } from './types';

const PaddedHeader = styled(Typography)`
  padding: 16px 0;
`;

interface ApproversProps {
  loadingApprovers: boolean;
  roundIndex: number;
  roundData: RoundType;
  setAdjudicationProcess: Dispatch<SetStateAction<AdjudicationConfiguration>>;
}

const Approvers: React.FC<ApproversProps> = ({
  loadingApprovers,
  roundData,
  roundIndex,
  setAdjudicationProcess,
}) => {
  const handleDefaultApproverChange = (value) => {
    setAdjudicationProcess((prevData: AdjudicationConfiguration) => ({
      ...prevData,
      adjudicationRounds: prevData.adjudicationRounds.map((r, i) =>
        i === roundIndex
          ? {
              ...r,
              definedApprovers: {
                ...r?.definedApprovers,
                defaultDefinedApprover: value
                  ? {
                      definedApproverUuid: value?.id,
                      approver: value,
                    }
                  : {
                      definedApproverUuid: null,
                      approver: null,
                    },
              },
            }
          : r,
      ),
    }));
  };

  const addApproverRow = () => {
    setAdjudicationProcess((prevData: AdjudicationConfiguration) => ({
      ...prevData,
      adjudicationRounds: prevData.adjudicationRounds.map((r, i) => {
        const definedApproversForBusinessUnits = r.definedApprovers
          ?.definedApproversForBusinessUnits
          ? [...r.definedApprovers.definedApproversForBusinessUnits]
          : [];
        definedApproversForBusinessUnits.push({
          definedApproverUuid: Date.now().toString(),
          approver: null,
          businessUnit: null,
        });
        return i === roundIndex
          ? {
              ...r,
              definedApprovers: {
                ...r.definedApprovers,
                definedApproversForBusinessUnits,
              },
            }
          : r;
      }),
    }));
  };

  const handleAddApproversUpdate = (approver, id) => {
    setAdjudicationProcess((prevData: AdjudicationConfiguration) => ({
      ...prevData,
      adjudicationRounds: prevData.adjudicationRounds.map((r, i) =>
        i === roundIndex
          ? {
              ...r,
              definedApprovers: {
                ...r.definedApprovers,
                definedApproversForBusinessUnits:
                  r.definedApprovers.definedApproversForBusinessUnits.map(
                    (item) => {
                      if (id === item.definedApproverUuid) {
                        return {
                          ...item,
                          approver: approver,
                        };
                      }
                      return item;
                    },
                  ),
              },
            }
          : r,
      ),
    }));
  };

  const handleAddApproversBuUpdate = (businessUnit, id) => {
    setAdjudicationProcess((prevData: AdjudicationConfiguration) => ({
      ...prevData,
      adjudicationRounds: prevData.adjudicationRounds.map((r, i) =>
        i === roundIndex
          ? {
              ...r,
              definedApprovers: {
                ...r.definedApprovers,
                definedApproversForBusinessUnits:
                  r.definedApprovers.definedApproversForBusinessUnits.map(
                    (item) => {
                      if (id === item.definedApproverUuid) {
                        return {
                          ...item,
                          businessUnit: businessUnit,
                        };
                      }
                      return item;
                    },
                  ),
              },
            }
          : r,
      ),
    }));
  };

  const handleRemoveAddApproverRow = (id) => {
    setAdjudicationProcess((prevData: AdjudicationConfiguration) => ({
      ...prevData,
      adjudicationRounds: prevData.adjudicationRounds.map((r, i) =>
        i === roundIndex
          ? {
              ...r,
              definedApprovers: {
                ...r.definedApprovers,
                definedApproversForBusinessUnits:
                  r.definedApprovers.definedApproversForBusinessUnits.filter(
                    (item) => id !== item.definedApproverUuid,
                  ),
              },
            }
          : r,
      ),
    }));
  };

  const hasDefaultApprover =
    !!roundData?.definedApprovers?.defaultDefinedApprover?.approver;
  const hasDefinedApproversWithBusinessUnits =
    !!roundData?.definedApprovers?.definedApproversForBusinessUnits?.[0]
      ?.approver ||
    !!roundData?.definedApprovers?.definedApproversForBusinessUnits?.[0]
      ?.businessUnit;

  return (
    <div data-testid="Approvers">
      <PaddedHeader variant="h4">Approvers</PaddedHeader>
      <StartDiv container>
        <FlexyBetweenGrid item xs={6}>
          <ApproverSearch
            approver={
              roundData?.definedApprovers?.defaultDefinedApprover?.approver
            }
            setApprovers={handleDefaultApproverChange}
            loadingApprovers={loadingApprovers}
          />
        </FlexyBetweenGrid>
        <FlexyBetweenGrid item xs={6}>
          <BusinessUnitSearch defaultApprover={true}></BusinessUnitSearch>
        </FlexyBetweenGrid>
      </StartDiv>
      {(hasDefaultApprover || hasDefinedApproversWithBusinessUnits) && (
        <AddApprover
          additionalApprovers={
            roundData?.definedApprovers.definedApproversForBusinessUnits || []
          }
          setAdditionalApprovers={handleAddApproversUpdate}
          setBuForApprover={handleAddApproversBuUpdate}
          addApproverRow={addApproverRow}
          removeApproverRow={handleRemoveAddApproverRow}
        />
      )}
    </div>
  );
};

export default Approvers;
