import { TableRowHeader, TableRows } from '../types';
import React from 'react';
import { IconButton, Tag } from '@octanner/prism-core';
import {
  CustomerStatus,
  CustomerStatusInfo,
  CustomerWithAdminRoles,
  PaginatedCustomers,
} from './types';
import Typography from '@octanner/prism-core/Typography';
import CopyToClipboardButton from '../../../../common/CopyToClipboard';
import { CoreIdCopyDiv } from './styles';
import { CustomerSearchSortBy } from './graphql/types';

export const CUSTOMER_1: CustomerWithAdminRoles = {
  customerUuid: '5464be5f-b704-44c7-907f-8cf6103d3f84',
  name: 'O.C. Tanner',
  stpNumber: '1543530',
  customerStatus: 'active',
  activeRoleCount: 2,
  adminCount: 3,
};

export const CUSTOMER_2: CustomerWithAdminRoles = {
  customerUuid: '3631d77d-01f0-4ad6-b907-c0407e378eda',
  name: 'American Airlines',
  stpNumber: '1234567',
  customerStatus: 'active',
  activeRoleCount: 0,
  adminCount: 0,
};

export const CUSTOMER_3: CustomerWithAdminRoles = {
  customerUuid: 'bd82374e-8ca8-438f-85ed-2994bfc1a790',
  name: 'Delta',
  stpNumber: '2345678',
  customerStatus: 'active',
  activeRoleCount: 10,
  adminCount: 14,
};

export const CUSTOMER_4: CustomerWithAdminRoles = {
  customerUuid: 'c19ec809-4e53-4053-9882-35fbc1c4813a',
  name: 'Bank of America',
  stpNumber: '3456789',
  customerStatus: 'active',
  activeRoleCount: 1,
  adminCount: 8,
};

export const CUSTOMER_5: CustomerWithAdminRoles = {
  customerUuid: '4b371922-01d4-492e-99c0-37c0328de980',
  name: 'Client name',
  stpNumber: '4567890',
  customerStatus: 'active',
  activeRoleCount: 10,
  adminCount: 14,
};

export const CUSTOMER_6: CustomerWithAdminRoles = {
  customerUuid: 'a4270162-505d-4995-8282-74df987b2c94',
  name: 'Client name',
  stpNumber: '0987654',
  customerStatus: 'active',
  activeRoleCount: 10,
  adminCount: 14,
};

export const CUSTOMER_7: CustomerWithAdminRoles = {
  customerUuid: '46f94bab-54f8-47b3-b9df-987174c67a89',
  name: 'Client name',
  stpNumber: '9876543',
  customerStatus: 'active',
  activeRoleCount: 10,
  adminCount: 14,
};

export const CUSTOMER_8: CustomerWithAdminRoles = {
  customerUuid: '3eb00bca-0692-472e-9f67-931b16c3e2d3',
  name: 'Client name',
  stpNumber: '8765432',
  customerStatus: 'active',
  activeRoleCount: 10,
  adminCount: 14,
};

export const CUSTOMER_9: CustomerWithAdminRoles = {
  customerUuid: '031bbfb2-ca26-430c-99e0-f1501a4f770f',
  name: 'Client name',
  stpNumber: '7654321',
  customerStatus: 'impl',
  activeRoleCount: 10,
  adminCount: 14,
};

export const CUSTOMER_10: CustomerWithAdminRoles = {
  customerUuid: '351c6773-3fab-410e-a10a-1a3be02068df',
  name: 'Client name',
  stpNumber: '6543210',
  customerStatus: 'lost',
  activeRoleCount: 10,
  adminCount: 14,
};

export const TEST_CLIENTS: CustomerWithAdminRoles[] = [
  CUSTOMER_1,
  CUSTOMER_2,
  CUSTOMER_3,
  CUSTOMER_4,
  CUSTOMER_5,
  CUSTOMER_6,
  CUSTOMER_7,
  CUSTOMER_8,
  CUSTOMER_9,
  CUSTOMER_10,
];

export const TEST_CLIENTS_PAGED_DATA: PaginatedCustomers = {
  content: TEST_CLIENTS,
  total: 234,
  totalPages: 37,
};

export const statusVariantMap: Record<CustomerStatus, CustomerStatusInfo> = {
  active: {
    displayName: 'Active',
    variant: 'success',
  },
  impl: {
    displayName: 'Implementation',
    variant: 'warning',
  },
  lost: {
    displayName: 'Lost',
    variant: 'archived',
  },
};

export const ClientsHeadCells: TableRowHeader<CustomerSearchSortBy>[] = [
  { id: 'name', label: 'Client Name', align: 'left', sortKey: 'NAME' },
  { id: 'stp', label: 'STP', align: 'right' },
  {
    id: 'customerUuid',
    label: 'Client Core ID',
    align: 'left',
  },
  {
    id: 'roles',
    label: 'Active Roles',
    align: 'center',
    sortKey: 'ROLE_COUNT',
  },
  {
    id: 'admins',
    label: 'Admins',
    align: 'center',
    sortKey: 'ADMIN_COUNT',
  },
  {
    id: 'status',
    label: 'Status',
    align: 'left',
    sortKey: 'STATUS',
  },
  { id: 'blank', label: '', align: 'center' },
];

export const tableRows: TableRows[] = [
  {
    key: 'name',
    test: 'name',
    align: 'left',
  },
  {
    key: 'stpNumber',
    test: 'stpNumber',
    align: 'right',
  },
  {
    key: 'customerUuid',
    test: 'customerUuid',
    align: 'left',
    customComponent: function activeRoleCount({
      customerUuid,
    }: {
      customerUuid: string;
      name: string;
    }) {
      return (
        <CoreIdCopyDiv>
          <IconButton>
            <CopyToClipboardButton textValue={customerUuid} />
          </IconButton>
          <Typography data-test={`clients:${customerUuid}:customer-uuid`}>
            {customerUuid}
          </Typography>
        </CoreIdCopyDiv>
      );
    },
  },
  {
    key: 'roles',
    test: 'roles',
    align: 'center',
    customComponent: function activeRoleCount({
      customerUuid,
      activeRoleCount,
    }: {
      customerUuid: string;
      activeRoleCount: number;
    }) {
      return (
        <Typography data-test={`clients:${customerUuid}:roles`}>
          {activeRoleCount}
        </Typography>
      );
    },
  },
  {
    key: 'admins',
    test: 'admins',
    align: 'center',
    customComponent: function activeRoleCount({
      customerUuid,
      adminCount,
    }: {
      customerUuid: string;
      adminCount: number;
    }) {
      return (
        <Typography data-test={`clients:${customerUuid}:admins`}>
          {adminCount}
        </Typography>
      );
    },
  },
  {
    key: 'status',
    test: 'status',
    align: 'left',
    customComponent: function statusLabel({
      customerUuid,
      customerStatus,
    }: {
      customerUuid: string;
      customerStatus: CustomerStatus;
    }) {
      const statusInfo = statusVariantMap[customerStatus];
      return (
        <Tag
          data-test={`clients:${customerUuid}:status`}
          variant={statusInfo.variant || 'error'}
        >
          {statusInfo.displayName}
        </Tag>
      );
    },
  },
];
