import { BankSection, invoiceFieldEntity } from '../../modifyBank/types';
import {
  AutoCompleteWidthHeight,
  LabelLinesContainer,
  LabelLinesSubTitleMargin,
} from './styles';
import {
  InvoiceFieldTypeNode,
  useCorePtMgmtGetInvoiceFieldType,
} from '../../modifyBank/graphql';
import { useEffect, useMemo, useState } from 'react';

const InvoiceTextFieldsBankInfo = ({
  type,
  bankData,
  handleValueUpdate,
}: BankSection) => {
  const [invoiceTextFields, setInvoiceTextFields] = useState<
    InvoiceFieldTypeNode[]
  >([]);

  const { data: invoiceFieldData } = useCorePtMgmtGetInvoiceFieldType();
  const sortedInvoiceFields = useMemo(() => {
    if (invoiceFieldData) {
      return [
        ...(invoiceFieldData?.corePtMgmtGetInvoiceFieldType?.invoiceFieldTypes
          ?.nodes || []),
      ].sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
        return 0;
      });
    }
    return [];
  }, [invoiceFieldData]);

  useEffect(() => {
    if (sortedInvoiceFields.length) {
      setInvoiceTextFields(sortedInvoiceFields);
    }
  }, [sortedInvoiceFields]);

  const usedFields = Object.values(bankData.invoiceFieldConfig || {}).reduce<
    string[]
  >((acc, field) => {
    if (field && field.code) {
      acc.push(field.code);
    }
    return acc;
  }, []);

  const filteredInvoiceTextFields = invoiceTextFields.filter(
    (field) => !usedFields.includes(field.code)
  );

  const handleChange = (
    _: React.ChangeEvent<{}>,
    newValue: invoiceFieldEntity | null,
    optionKey: string
  ) => {
    const field = { ...bankData['invoiceFieldConfig'] };
    if (newValue) {
      field[optionKey] = newValue;
    } else {
      field[optionKey] = null;
    }
    handleValueUpdate('invoiceFieldConfig', field);
  };

  return (
    <LabelLinesContainer>
      <LabelLinesSubTitleMargin variant="h2">
        Invoice text fields
      </LabelLinesSubTitleMargin>
      <AutoCompleteWidthHeight
        disablePortal
        id="combo-box-demo1"
        value={bankData.invoiceFieldConfig?.invoiceFieldEntity1 || null}
        options={filteredInvoiceTextFields || []}
        label="Text field 1"
        getOptionLabel={(option: { name: string }) => option.name}
        onChange={(event, newValue) =>
          handleChange(event, newValue, 'invoiceFieldEntity1')
        }
        helperText="Optional"
      />
      <AutoCompleteWidthHeight
        disablePortal
        id="combo-box-demo1"
        value={bankData.invoiceFieldConfig?.invoiceFieldEntity2 || null}
        options={filteredInvoiceTextFields || []}
        label="Text field 2"
        getOptionLabel={(option: { name: string }) => option.name}
        onChange={(event, newValue) =>
          handleChange(event, newValue, 'invoiceFieldEntity2')
        }
        helperText="Optional"
      />
      <AutoCompleteWidthHeight
        disablePortal
        id="combo-box-demo1"
        value={bankData.invoiceFieldConfig?.invoiceFieldEntity3 || null}
        options={filteredInvoiceTextFields || []}
        label="Text field 3"
        getOptionLabel={(option: { name: string }) => option.name}
        onChange={(event, newValue) =>
          handleChange(event, newValue, 'invoiceFieldEntity3')
        }
        helperText="Optional"
      />
      <AutoCompleteWidthHeight
        disablePortal
        id="combo-box-demo1"
        value={bankData.invoiceFieldConfig?.invoiceFieldEntity4 || null}
        options={filteredInvoiceTextFields || []}
        label="Text field 4"
        getOptionLabel={(option: { name: string }) => option.name}
        onChange={(event, newValue) =>
          handleChange(event, newValue, 'invoiceFieldEntity4')
        }
        helperText="Optional"
      />
    </LabelLinesContainer>
  );
};

export default InvoiceTextFieldsBankInfo;
