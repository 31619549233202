import { ClientInformationForm, FeatureRow } from './types';
export const clientInfoForm: ClientInformationForm[] = [
  {
    key: 'name',
    label: 'Client Name',
    isEditable: false,
    trimmedLabel: 'clientname',
  },
  {
    key: 'stpNumber',
    label: 'Client STP',
    isEditable: false,
    trimmedLabel: 'clientstp',
  },
  {
    key: 'id',
    label: 'Core ID',
    isEditable: false,
    trimmedLabel: 'coreid',
  },
  {
    key: 'customerStatus',
    label: 'Client Status',
    isEditable: false,
    trimmedLabel: 'clientstatus',
  },
  {
    key: 'customerUsage',
    label: 'Client Usage Type',
    isEditable: false,
    trimmedLabel: 'clientusagetype',
  },
  {
    key: 'launchedAtTsz',
    label: 'Client Launch Date',
    isEditable: false,
    trimmedLabel: 'clientlaunchdate',
  },
  {
    key: 'region',
    label: 'PII Data Region',
    isEditable: false,
    trimmedLabel: 'piidataregion',
  },
];

export const featuresRows: FeatureRow[] = [
  {
    dataTest: 'featuresFormName',
    name: 'Name',
    key: 'name',
  },
  {
    dataTest: 'featuresFormStartDate',
    name: 'Start Date',
    key: 'startDate',
  },
  {
    dataTest: 'featuresFormEndDate',
    name: 'End Date',
    key: 'endDate',
  },
  {
    dataTest: 'featuresFormStatus',
    name: 'Status',
    key: 'status',
  },
];

export const LOCALES = [
  { locale: 'en-US', name: 'English' },
  { locale: 'zh-CN', name: '中文(简体) - Chinese (Simple)' },
  { locale: 'zh-HK', name: '中文(繁體) - Chinese (Trad)' },
  { locale: 'cs-CZ', name: 'Czech (Czech Republic)' },
  { locale: 'de-DE', name: 'Deutsch - (Europe)' },
  { locale: 'en-GB', name: 'English - (Canada/UK)' },
  { locale: 'es-ES', name: 'Español - (España)' },
  { locale: 'es-MX', name: 'Español - (Americas)' },
  { locale: 'fr-CA', name: 'Français - (Canada)' },
  { locale: 'fr-FR', name: 'Français - (Europe)' },
  { locale: 'el-GR', name: 'Greek' },
  { locale: 'hu-HU', name: 'Hungarian' },
  { locale: 'in-ID', name: 'Indonesian' },
  { locale: 'it-IT', name: 'Italiano - (Europe)' },
  { locale: 'ja-JP', name: '日本語 - (Japanese)' },
  { locale: 'ko-KR', name: 'Korean' },
  { locale: 'mk-MK', name: 'Macedonian' },
  { locale: 'nl-NL', name: 'Nederland - (Netherlands)' },
  { locale: 'pl-PL', name: 'Polish' },
  { locale: 'pt-BR', name: 'Português' },
  { locale: 'pt-PT', name: 'Português - (Portugal)' },
  { locale: 'ru-RU', name: 'Russian' },
  { locale: 'sl-SI', name: 'Slovenian' },
  { locale: 'th-TH', name: 'Thai' },
  { locale: 'tr-TR', name: 'Turkish' },
  { locale: 'vi-VN', name: 'Vietnamese' },
];
