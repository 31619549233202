import { OrderExperienceComponent } from '@octanner/cc-admin-ucat-config-ui';
import { useContext } from 'react';
import { withPad } from '../../common/BasicPadding';
import { CustomerContext } from '../../utils/context/CustomerContext';

const StoreExperience = () => {
  const { customer } = useContext(CustomerContext);
  return (
    <div>
      <OrderExperienceComponent customerUuid={customer?.id} />
    </div>
  );
};

export default withPad()(StoreExperience);
