import React from 'react'
import { Button, Modal } from '@octanner/prism-core'
import { useTranslation } from 'react-i18next';
import { WarningText,ModalButtonContainer , ModalDiscardButton } from './styles'

const Confirmation = ({
  confirmationData,
  onClose,
  errorsDisplayNumber,
  setErrorsDisplayNumber,
  setUpdateSapError
}) => {
  const { t } = useTranslation();

  const handleDiscard = () => {
    onClose({ ...confirmationData, showDialog: false, switchTab: true, editMode: false });
    const previousIndexNumber = confirmationData.activeTabIndex;
    previousIndexNumber === 0 ? 
    setErrorsDisplayNumber({...errorsDisplayNumber,ClientFinancialInfo: 0}) : 
    previousIndexNumber === 1 ? 
    setErrorsDisplayNumber({...errorsDisplayNumber, CountryAndSalesOrgSetup: 0}) : 
    setErrorsDisplayNumber({...errorsDisplayNumber,DefaultCountryAndLanguages: 0});
    setUpdateSapError(false);
  }
  return (
    <>
      <Modal
        actions={<ModalButtonContainer>
          <ModalDiscardButton
            adminColor
            style={{
              marginRight: '8px',
            }}
            test-id="discard"
            onClick={() => handleDiscard()}
          >
            {t('discard')}
          </ModalDiscardButton>
          <Button color="secondary"
            onClick={() => { onClose({ ...confirmationData, showDialog: false, switchTab: false }) }}
            test-id="cancel"
          >
            {t('cancel')}
          </Button>
        </ModalButtonContainer>}
        onClose={() => { onClose({ ...confirmationData, showDialog: false, switchTab: false }) }}
        open={true}
        title={t('unsaved_changes')} id={''}
        >
        <WarningText>{t('warning_text')}</WarningText>
      </Modal>
    </>
  )
}

export default Confirmation;
