import { useHistory } from 'react-router';
import { useEffect, useState } from 'react';
import { ErrorData, Errors, Permission } from '../../types';
import { useCreateRole } from '../hooks/createRole';
import { validateAdminRole, validatePermissions } from '../utils';
import { FetchResult } from '@apollo/client';
import { CreateRoleResponse } from '../graphql/types';
import PageWrapper from '../../components/PageWrapper';
import BasicPadding from '../../../../../common/BasicPadding';
import UnsavedModal from '../../components/UnsavedModal';
import AdminRoleHeader from './adminRoleHeader';
import { AssignPermContainer } from './styles';
import { Typography } from '@octanner/prism-core';
import { AlertContainer } from '../styles';
import { ErrorHandler } from '../../ErrorHandler';
import RolePermissions from './rolePermissions';

interface CreateRoleBodyProps {
  type: 'add' | 'edit' | 'duplicate';
  headerText: string;
  headerDescription: string;
  initialRoleType?: string;
  initialPermissions?: Permission[];
}

const CreateRoleBody = ({
  type,
  headerText,
  headerDescription,
  initialRoleType = 'ADMIN',
  initialPermissions = [],
}: CreateRoleBodyProps) => {
  const history = useHistory();
  const [roleName, setRoleName] = useState('');
  const [roleDesc, setRoleDesc] = useState('');
  const [roleType, setRoleType] = useState(initialRoleType);
  const [selectedPermissions, setSelectedPermissions] =
    useState<Permission[]>(initialPermissions);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [unsavedModalOpen, setUnsavedModalOpen] = useState(false);
  const [createRole] = useCreateRole();
  const [roleNameCharLimit, setRoleNameCharLimit] = useState<ErrorData>({
    error: false,
    message: '',
  });
  const [roleNameEmpty, setRoleNameEmpty] = useState<ErrorData>({
    error: false,
    message: '',
  });
  const [roleNameDuplicate, setRoleNameDuplicate] = useState<ErrorData>({
    error: false,
    message: '',
  });
  const [roleDescCharLimit, setRoleDescCharLimit] = useState<ErrorData>({
    error: false,
    message: '',
  });
  const [roleDescEmpty, setRoleDescEmpty] = useState<ErrorData>({
    error: false,
    message: '',
  });
  const [roleTypeEmpty, setRoleTypeEmpty] = useState<ErrorData>({
    error: false,
    message: '',
  });

  const [hasPermission, setHasPermission] = useState<ErrorData>({
    error: false,
    message:
      'At least one permission must be active to save an active admin role. Add a permission below to continue.',
  });

  const permissionErrors: Errors[] = [
    {
      key: 'permissionCount',
      errorData: hasPermission,
      setErrorData: setHasPermission,
    },
  ];
  const errors: Errors[] = [
    {
      key: 'roleNameCharLimit',
      errorData: roleNameCharLimit,
      setErrorData: setRoleNameCharLimit,
    },
    {
      key: 'roleNameEmpty',
      errorData: roleNameEmpty,
      setErrorData: setRoleNameEmpty,
    },
    {
      key: 'roleNameDuplicate',
      errorData: roleNameDuplicate,
      setErrorData: setRoleNameDuplicate,
    },
    {
      key: 'roleDescCharLimit',
      errorData: roleDescCharLimit,
      setErrorData: setRoleDescCharLimit,
    },
    {
      key: 'roleDescEmpty',
      errorData: roleDescEmpty,
      setErrorData: setRoleDescEmpty,
    },
    {
      key: 'roleTypeEmpty',
      errorData: roleTypeEmpty,
      setErrorData: setRoleTypeEmpty,
    },
  ];

  const resetErrors = () => {
    setRoleNameEmpty((prev) => ({ ...prev, error: false }));
    setRoleNameEmpty((prev) => ({ ...prev, error: false }));
    setRoleNameCharLimit((prev) => ({ ...prev, error: false }));
    setRoleNameDuplicate((prev) => ({ ...prev, error: false }));
    setRoleDescEmpty((prev) => ({ ...prev, error: false }));
    setRoleDescCharLimit((prev) => ({ ...prev, error: false }));
    setRoleTypeEmpty((prev) => ({ ...prev, error: false }));
    setHasPermission((prev) => ({ ...prev, error: false }));
  };

  const errorCheck = () => {
    const adminRoleError = validateAdminRole(
      roleName,
      roleDesc,
      roleType,
      setRoleNameEmpty,
      setRoleNameCharLimit,
      setRoleDescEmpty,
      setRoleDescCharLimit,
      setRoleTypeEmpty
    );
    const permissionsError = validatePermissions(
      selectedPermissions,
      setHasPermission
    );
    return adminRoleError || permissionsError;
  };

  const onSave = () => {
    resetErrors();
    let isError = errorCheck();

    if (!isError) {
      createRole({
        variables: {
          name: roleName,
          description: roleDesc,
          accessType: roleType,
          permissionUuids: selectedPermissions.map((perm) => perm.uuid),
        },
      }).then((result) => {
        const createRoleResponse = (result as FetchResult<CreateRoleResponse>)
          .data?.rdCreateRole;
        if (createRoleResponse) {
          history.push(
            `/admin-roles/${createRoleResponse.uuid}/edit?isNew=true`
          );
        }
      });
    }
  };

  const onCancel = () => {
    if (hasUnsavedChanges) {
      setUnsavedModalOpen(true);
    } else {
      onDiscard();
    }
  };

  const onDiscard = () => {
    history.push('/admin-roles');
  };

  useEffect(() => {
    setHasUnsavedChanges(selectedPermissions.length > 0);
  }, [selectedPermissions]);

  return (
    <PageWrapper
      type={type}
      context="admin-roles"
      saveAction={onSave}
      cancelAction={onCancel}
    >
      <BasicPadding padding="sm">
        <UnsavedModal
          open={unsavedModalOpen}
          setOpen={setUnsavedModalOpen}
          onConfirm={onDiscard}
        />
        <AdminRoleHeader
          type={type}
          headerText={headerText}
          headerDesc={headerDescription}
          roleNameValue={roleName}
          setRoleNameValue={setRoleName}
          roleDescriptionValue={roleDesc}
          setRoleDescriptionValue={setRoleDesc}
          roleTypeValue={roleType}
          setRoleTypeValue={setRoleType}
          errors={errors}
          onNavigateAway={onCancel}
        />
        <AssignPermContainer>
          <Typography variant="h2">Assign permissions</Typography>
          <Typography>
            Select permission categories below to view and add them to your
            custom role. You can also search to find specific permissions.
          </Typography>
        </AssignPermContainer>
        <AlertContainer>
          <ErrorHandler errors={permissionErrors} />
        </AlertContainer>
        <RolePermissions
          selectedPermissions={selectedPermissions}
          setSelectedPermissions={setSelectedPermissions}
        />
      </BasicPadding>
    </PageWrapper>
  );
};

export default CreateRoleBody;
