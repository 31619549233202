import { gql, useMutation } from '@apollo/client';
import { FlexFieldQuestion } from '../types';
import { useSetError } from '../utils/SetErrorProvider';

export const SAVE_FIELD = gql`
  mutation saveFlexFieldQuestion($input: FlexFieldQuestionInput!) {
    saveFlexFieldQuestion(input: $input) {
      fieldName
      question
      id
      helperText
      characterLimit
      immutable
    }
  }
`;

export const useFieldList = () => {
  const setError = useSetError();
  const [saveFlexFieldQuestion, { loading }] = useMutation(SAVE_FIELD, {
    onError: () => {
      setError(
        'Oops! There was a problem saving. Please review and try again.',
      );
    },
  });

  return {
    loading,
    saveFlexFieldQuestion: (field: FlexFieldQuestion) =>
      saveFlexFieldQuestion({
        variables: {
          input: {
            id: field.id,
            fieldName: field.fieldName,
            characterLimit: field.characterLimit,
            helperText: field.helperText,
            question: field.question,
          },
        },
      }),
  } as const;
};
