import { Search, Typography } from '@octanner/prism-core';
import { ContainerA, CategoryContainer, SearchBox } from './styles';
import { PermissionCategory } from '../types';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Permission } from '../../types';
import { tannerGray } from '@octanner/prism-core/ThemeProvider/colors';
import { filterCategories } from '../utils';

type GroupContainerProps = {
  categories: PermissionCategory[];
  selectedPermissions: Permission[];
  activeContainer?: PermissionCategory;
  setActiveContainer: Dispatch<SetStateAction<PermissionCategory | undefined>>;
  search: string;
  setSearch: Dispatch<SetStateAction<string>>;
};

type CategoryCardProps = {
  category: PermissionCategory;
  index: number;
  arr: PermissionCategory[];
};

const CategoriesContainer = ({
  categories,
  selectedPermissions,
  activeContainer,
  setActiveContainer,
  search,
  setSearch,
}: GroupContainerProps) => {
  const handleClick = (category: PermissionCategory) => {
    if (activeContainer && activeContainer.uuid === category.uuid)
      setActiveContainer(undefined);
    else setActiveContainer(category);
  };

  const isActive = (
    category: PermissionCategory,
    activeContainer?: PermissionCategory
  ) => !!(activeContainer && category.uuid === activeContainer.uuid);

  const [selectedCountMap, setSelectedCountMap] = useState<Map<string, number>>(
    new Map()
  );
  useEffect(() => {
    const newMap = new Map<string, number>();
    categories.forEach((category) => {
      const selectedCount = category.subCategories
        .flatMap((sub) => sub.permissionGroups)
        .filter(
          (group) =>
            (group.readPermission &&
              selectedPermissions.some(
                (perm) => perm.uuid === group.readPermission?.uuid
              )) ||
            (group.writePermission &&
              selectedPermissions.some(
                (perm) => perm.uuid === group.writePermission?.uuid
              ))
        ).length;
      newMap.set(category.uuid, selectedCount);
    });
    setSelectedCountMap(newMap);
  }, [selectedPermissions, categories]);

  const GroupCard = ({ category }: CategoryCardProps) => {
    return (
      <CategoryContainer
        key={category.uuid + '_active'}
        active={isActive(category, activeContainer)}
        onClick={() => {
          handleClick(category);
        }}
        data-test={`category:${category.uuid}`}
      >
        <Typography variant="h4">{category.name}</Typography>
        <Typography variant="body2" color={tannerGray[600]}>
          {selectedCountMap.get(category.uuid)}/{category.permissionGroupTotal}{' '}
          active
        </Typography>
        <Typography>{category.description}</Typography>
      </CategoryContainer>
    );
  };

  const [filteredCategories, setFilteredCategories] = useState(categories);
  useEffect(() => {
    if (!search || search.length === 0) {
      setFilteredCategories(categories);
      return;
    }
    const newCategories = filterCategories(categories, search);
    setFilteredCategories(newCategories);
    setActiveContainer(newCategories[0]);
  }, [search, categories]);

  return (
    <ContainerA>
      <SearchBox>
        <Search
          id="unique-id"
          data-test="search"
          // @ts-ignore
          onInputChange={(e) => setSearch(e.target.value)}
          placeholder="Search"
          multiple={false}
          getOptionLabel={(option) => option as string}
        />
      </SearchBox>
      {filteredCategories.map((category, index, arr) => (
        <GroupCard
          category={category}
          index={index}
          arr={arr}
          key={category.uuid}
        />
      ))}
    </ContainerA>
  );
};

export default CategoriesContainer;
