import styled from '@emotion/styled';
import { Typography } from '@octanner/prism-core';
import colors from '../../../../../common/constants/Colors';

export const FeedsTitle = styled(Typography)`
  margin-bottom: 4px;
  margin-top: 12px;
  /* opacity: 0.4; */
`;

export const FeedsSubtitle = styled(Typography)`
  margin-bottom: 15px;

  color: ${colors.black};
`;
