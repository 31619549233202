import { useMutation } from '@apollo/client';
import styled from '@emotion/styled';
import { Button, Modal, Typography } from '@octanner/prism-core';
import React, { useContext, useEffect, useState } from 'react';
import { TranslationRequestContext } from '../../Contexts/TranslationRequestContext';
import { defaults } from '../../utils/default';
import { DELETE_TRANSLATION_REQUEST } from '../../utils/query';
import { DeleteTranslationRequestModalProps, UnTranslatedString } from '../../utils/types';


const DeleteButton = styled(Button)`
  background-color: #D10A12;
`

const ModalContainer = styled(Modal)`
  .MuiPaper-root {
    max-width: 480px;
  }
  #delete-translation-request-modal {
    span {
      max-width: 370px;
      margin: 0 auto;
      display: flex;
      justify-content: center; 
    }
  }
`

const DeleteTranslationRequestModal: React.FC<DeleteTranslationRequestModalProps> = ({
  showDeleteTranslationRequestModal,
  setShowDeleteTranslationRequestModal
}) => {

  const { selectedLocale, translationCategory, unTranslatedStringList,
    setMessageText, setCreatedJobNumber, refetchUntranslatedStringListData } =
    useContext(TranslationRequestContext);

  const [deleteTranslationRequest, { loading }] = useMutation(DELETE_TRANSLATION_REQUEST, {
    onError: () => {
      setMessageText('errorMessage', defaults.delete_request_error_message);
    }
  });
  const [statusUniqueIdList, setStatusUniqueIdList] = useState<number[]>([]);

  useEffect(() => {
    if (showDeleteTranslationRequestModal) {
      const statusUniqueIdListObj: number[] = unTranslatedStringList
        .filter((item: UnTranslatedString) => item.selected)
        .map((item: UnTranslatedString) => item.requestStatus.uniqueId);
      setStatusUniqueIdList(statusUniqueIdListObj);
    }
  }, [showDeleteTranslationRequestModal])

  const closeModal = () => {
    if (loading) {
      return;
    }
    setShowDeleteTranslationRequestModal(false);
  }

  const deleteJob = () => {
    if (loading) {
      return;
    }
    deleteTranslationRequest({
      variables: {
        input: {
          translationCategory,
          locale: selectedLocale.locale,
          statusUniqueIdList
        }
      }
    }).then((response) => {
      if (!response.errors) {
        setMessageText('successMessage', defaults.delete_request_success_message);
        setCreatedJobNumber(0);
        refetchUntranslatedStringListData(statusUniqueIdList);
      }
      closeModal();
    });
  }


  return (
    <ModalContainer
      actions={
        <>
          <Button disabled={loading} onClick={closeModal} color='secondary'>{defaults.cancel}</Button>
          <DeleteButton loading={loading} onClick={deleteJob}>{defaults.delete}</DeleteButton>
        </>
      }
      id='delete-translation-request-modal'
      title={defaults.are_you_sure}
      open={showDeleteTranslationRequestModal}
      onClose={closeModal}>
      <Typography>{defaults.delete_translation_requests_sub_title.replace('{count}', statusUniqueIdList.length.toString())}</Typography>
    </ModalContainer>);
}

export default DeleteTranslationRequestModal;