import styled from '@emotion/styled';
import {
  Select,
  Table,
  TableCell,
  TextField,
  Typography,
} from '@octanner/prism-core';
import colors from '../../../../../common/constants/Colors';

export const FlexColumnDiv = styled.div`
  display: flex;
  flex-direction: column;
`;
export const M16Div = styled.div`
  margin: 16px;
`;

export const MileStoneTitle = styled(Typography)`
  margin-top: 42px;
`;

export const MileStoneSubTitle = styled(Typography)`
  margin-bottom: 15px;
  margin-top: 4px;
  color: ${colors.black};
`;

export const AutomCompleteTM = styled(TextField)`
  margin-top: 12px;
  width: 473px;
`;

export const SelectWidth = styled(Select)`
  width: 473px;
`;

export const TableWidth = styled(Table)`
  width: 85%;
`;

export const BorderBottomNone = styled(TableCell)`
  border-bottom: none;
`;

export const TypographyMT = styled(Typography)`
  margin-top: 13px;
`;
