import React from 'react';
import {
  FormControl,
  FormGroup,
  FormLabel,
  Typography,
} from '@octanner/prism-core';
import {
  CheckboxControl,
  indentedFormControlStyle,
  SwitchControl,
  topLevelFormControlStyle,
} from './editorControls';

interface Props {
  enableEmailCC: boolean;
  setEnableEmailCC: (checked: boolean) => void;
  prePopulateCc: boolean;
  setPrePopulateCc: (checked: boolean) => void;
  prepopulatedCCNominee: boolean;
  setPrepopulatedCCNominee: (checked: boolean) => void;
  prepopulatedCCNominator: boolean;
  setPrepopulatedCCNominator: (checked: boolean) => void;
  allowUserToModifyAdditionalAddresses: boolean;
  setAllowUserToModifyAdditionalAddresses: (checked: boolean) => void;
  requireSenderConfirmation: boolean;
  setRequireSenderConfirmation: (checked: boolean) => void;
}

export const EmailCcOptions: React.FC<Props> = ({
  enableEmailCC,
  setEnableEmailCC,
  prePopulateCc,
  setPrePopulateCc,
  prepopulatedCCNominee,
  setPrepopulatedCCNominee,
  prepopulatedCCNominator,
  setPrepopulatedCCNominator,
  allowUserToModifyAdditionalAddresses,
  setAllowUserToModifyAdditionalAddresses,
  requireSenderConfirmation,
  setRequireSenderConfirmation,
}) => {
  return (
    <FormControl sx={topLevelFormControlStyle}>
      <FormLabel component="legend">
        <Typography variant="h3">Email CC</Typography>
      </FormLabel>
      <FormGroup>
        <SwitchControl
          checked={enableEmailCC}
          onChange={(e, checked) => setEnableEmailCC(checked)}
        >
          Allow for CC
        </SwitchControl>
      </FormGroup>
      {enableEmailCC && (
        <FormControl sx={indentedFormControlStyle}>
          <FormGroup>
            <CheckboxControl
              checked={prePopulateCc}
              onChange={(e, checked) => setPrePopulateCc(checked)}
            >
              Pre-populated CC
            </CheckboxControl>
            {prePopulateCc && (
              <FormControl sx={indentedFormControlStyle}>
                <FormLabel component="legend">
                  <Typography variant="h4" sx={{ lineHeight: '36px' }}>
                    Select manager heirarchy to notify:
                  </Typography>
                </FormLabel>
                <FormGroup>
                  <CheckboxControl
                    checked={prepopulatedCCNominee}
                    onChange={(e, checked) => setPrepopulatedCCNominee(checked)}
                  >
                    Nominee
                  </CheckboxControl>
                  <CheckboxControl
                    checked={prepopulatedCCNominator}
                    onChange={(e, checked) =>
                      setPrepopulatedCCNominator(checked)
                    }
                  >
                    Nominator
                  </CheckboxControl>
                </FormGroup>
              </FormControl>
            )}
            <CheckboxControl
              checked={allowUserToModifyAdditionalAddresses}
              onChange={(e, checked) =>
                setAllowUserToModifyAdditionalAddresses(checked)
              }
            >
              Allow user to modify &quot;Additional address&quot;
            </CheckboxControl>
          </FormGroup>
        </FormControl>
      )}
      <SwitchControl
        checked={requireSenderConfirmation}
        onChange={(e, checked) => setRequireSenderConfirmation(checked)}
      >
        Require sender confirmation
      </SwitchControl>
    </FormControl>
  );
};
