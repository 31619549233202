import React from 'react';
import {
  SaveandContinueFlexContainer,
  SaveandContinue,
  CancelButton,
} from './style';
import { FooterProps } from './types';
import { useTranslation } from 'react-i18next';


const Footer: React.FC<FooterProps> = ({ onSave, onCancel, loading , setSalesOrgDataChanged ,clientSalesOrg}) => {
  const { t } = useTranslation();
  return (
    <div>
      <SaveandContinueFlexContainer>
        <SaveandContinue
          loading={loading}
          onClick={() => onSave()}
          test-id="save-and-continue"
          adminColor
        >
          {t('save_continue')}
        </SaveandContinue>
        {
          clientSalesOrg?.customerCountrySalesOrgMapsByCustomer?.customerCountrySalesOrgMap?.length > 0 && (
            <CancelButton
            color='secondary'
            onClick={() => {onCancel();setSalesOrgDataChanged(false);}}
            test-id="cancel"
          >
            {t('cancel')}
          </CancelButton>
          )
        }

      </SaveandContinueFlexContainer>
    </div>
  );
};

export default Footer;
