import { gql } from '@apollo/client';

export const GET_ALL_CORE_VALUES_BY_CUSTOMER = gql`
  query customerCoreValues($customerId: UUID!) {
    customerCoreValues(customerId: $customerId) {
      coreValues {
        nodes {
          coreValueName
          customerCoreValueId
          languageStringId
          status
        }
      }
    }
  }
`;

export const UPDATE_CORE_VALUE = gql`
mutation UpdateCustomerCoreValue($input: CustomerCoreValueUpdateRequest!) {
  updateCustomerCoreValue(input: $input) {
    customerCoreValueId
    coreValueName
    status
    languageStringId
  }
}
`;

export const CREATE_CORE_VALUE = gql`
mutation CreateCustomerCoreValue($input: CustomerCoreValueInsertRequest!) {
  createCustomerCoreValue(input: $input) {
    customerCoreValueId
    coreValueName
    status
    languageStringId
  }
}
`;

export const DELETE_CORE_VALUE = gql`
mutation ArchiveCustomerCoreValue($input: CustomerCoreValueArchiveRequest!) {
  archiveCustomerCoreValue(input: $input) {
    customerCoreValueId
    coreValueName
    languageStringId
  }
}
`;

export const CHANGE_STATUS_OF_CORE_VALUE = gql`
mutation updateCustomerCoreValuesStatus($input: CustomerCoreValueStatusUpdateRequest!) {
  updateCustomerCoreValuesStatus(input: $input) {
    coreValues {
        nodes {
            coreValueName
            customerCoreValueId
            languageStringId
            status
        }
    }
  }
}
`;

export const CREATE_STRING_ID = gql`
  mutation LsCreateLanguageStringInUSEnglishLocale($input: CreateLanguageStringInput!) {
    lsCreateLanguageStringInUSEnglishLocale(input: $input) {
      languageString {
        text
        stringId
      }
    }
  }
`;
