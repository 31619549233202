import styled from '@emotion/styled';
import { Button, Typography, TableContainer } from '@octanner/prism-core';
import TableCell from '@octanner/prism-core/TableCell';

export const SubHead = styled(Typography)`
  height: 21px;
  width: 1200px;
  margin-bottom: 11px;
`;

export const CancelButton = styled(Button)`
  margin-top: 16px;
  margin-left: 8px;
  z-index: 7;
`;

export const SaveandContinue = styled(Button)`
  text-transform: none;
  margin-top: 16px;
  z-index: 8;
`;

export const Header = styled(Typography)`
  margin-top: 26px;
`;

export const FlexContainer = styled.div`
  display: flex;
`;
export const SaveandContinueFlexContainer = styled.div`
  display: flex;
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  padding: 12px 12px 40px 40px;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.25);
  z-index: 7;
  left: 256px;
`;

export const DisplayFlex = styled.div`
  display: flex;
  margin-top: 8px;
  align-items: center;
`;

export const FlexBox2 = styled.div`
  display: flex;
`;

export const LanguageText = styled(Typography)`
  margin-top: 20px;
  margin-left: 16px;
  margin-bottom: 14px;
  font-weight: 800;
  width: 80px;
`;

export const LanguageHeader = styled(Typography)`
  margin-top: 8px;
`;

export const EditCountryButton = styled(Button)`
  margin-top: 16px;
`;

export const SortContainer = styled.div`
  margin-left: 8px;
  &:hover {
    cursor: pointer;
  }
`;

export const FooterContainer = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  padding: 12px 12px 40px 40px;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.25);
  z-index: 11;
  left: 256px;
`;

export const FilterContainer = styled.div`
  display: flex;
  margin-top: 16px;
  justify-content: space-between;
  align-items: center;
`;

export const SaveDefaultButton = styled(Button)`
  margin-right: 8px;
  margin-top: 16px;
`;

export const SubHeader = styled(Typography)`
  margin-top: 4px;
`;

export const FilterHeader = styled(Typography)`
  margin-top: 24px;
`;

export const StyledTableCell = styled(TableCell)`
  display: flex;
`;

export const TagContainer = styled.div`
  margin-top: 8px;
  margin-left: 48px;
`;

export const StyledTableContainer = styled(TableContainer)`
  margin-bottom: 128px;
`;
