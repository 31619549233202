import { AlertColor } from "@mui/material";
import { Dispatch, ReactNode, SetStateAction } from "react";

export interface Translation {
  locale: string;
  localeDescription: string;
  translationString: string | null;
  translationStatus: string;
  text: string
  originalText: string
  editMode: boolean,
  checked: boolean,
  loading: boolean
}

export interface FetchLanguageString {
  text: string;
  stringId: string;
  translationList: Partial<Translation>[];
  enableVoiceDescriptionInd: boolean;
  notes: string;
  requestedByName: string | null;
  soldToParty: string;
}

export interface Locale {
  code: string;
  languageDescription: string;
  countryDescription?: string;
}

export interface CoreLocale {
  getCoreLocales: {
    coreLocales: {
      nodes: Locale[];
    }
  }
}

export interface LocaleContextType {
  localeLoading: boolean;
  locales: Locale[];
  localeMap: { [key: string]: string };
}

export interface SelectLanguageProps {
  locales: Locale[];
  selectedLocales: Locale[];
  setSelectedLocales: (selectedLocales: Locale[]) => void;
  optionalInd: boolean;
  addButtonName?: string;
  placeholderName?: string;
}

export interface SearchVariables {
  mmId: number;
  stringId: number;
  searchText: string;
  searchLocale: string;
  searchConstraint: string;
  searchMdField: string
  ignoreCaseCheckInd: boolean;
  pageInfo: {
    pageNumber: number;
    pageSize: number;
  }
}

export interface MaterialDescriptionTranslationProps {
  description: MaterialDescription;
  descriptionLocale: string;
  filteredMaterialDescriptionList: MaterialDescription[];
  materialDescriptionList: MaterialDescription[];
  editMode: boolean;
  saveAllLoading: boolean;
  updateMaterialDescription: ({ variables }) => any;
  descriptionLocalesAvailable: Locale[];
  setDescriptionLocalesAvailable: React.Dispatch<React.SetStateAction<Partial<Locale[]>>>;
  descriptionLocalesAvailableInd: boolean;
  setDescriptionLocalesAvailableInd: React.Dispatch<React.SetStateAction<boolean>>;
  setEditedTextField: React.Dispatch<React.SetStateAction<Partial<MaterialDescription[]>>>
  updatingMaterialDescription: boolean;
  setMaterialDescriptionList: React.Dispatch<React.SetStateAction<Partial<MaterialDescription>[]>>;
  materialDescriptionLoadingByMmId: boolean;
  setFilteredMaterialDescriptionList: React.Dispatch<React.SetStateAction<Partial<MaterialDescription[]>>>;
}

export interface MaterialDescription {
  key?: number;
  text: string;
  sapPartNumber?: string;
  fieldType: string;
  onHoldInd?: boolean;
  translationStatus: string;
  checked: boolean;
  editMode: boolean;
  loading: boolean;
  originalText: string;
  requestedByName?: string;
}

export interface MaterialStringsContextType {
  searchBy: string;
  setSearchBy: Dispatch<SetStateAction<string>>;
  searchText: string;
  setSearchText: Dispatch<SetStateAction<string>>;
  searchLocale: string;
  setSearchLocale: Dispatch<SetStateAction<string>>;
  mmId: string;
  setMmId: Dispatch<SetStateAction<string>>;
  searchData: MaterialDescriptionListPayload;
  setSearchData: Dispatch<SetStateAction<MaterialDescriptionListPayload>>;
  showFooter: boolean;
  setShowFooter: Dispatch<SetStateAction<boolean>>;
  dataNotFound: boolean;
  setDataNotFound: Dispatch<SetStateAction<boolean>>;
  messages: Partial<AlertMessage>[];
  setMessageText: (messageKey: string, text: string) => void;
  openEditEnglishDescriptionModal: boolean;
  setOpenEditEnglishDescriptionModal: Dispatch<SetStateAction<boolean>>;
  materialDescriptionLoading: boolean;
  getMaterialDescriptionByFilters: ({ variables }) => any;
}

export interface EditAndRequestDescriptionModalProps {
  setDescriptionLocalesAvailableInd: Dispatch<SetStateAction<boolean>>;
  editedTextField: MaterialDescription[];
  mmdLocales: Locale[];
  note: string;
  getMaterialDescBymmID?: (variables) => any;
  handleAfterGetMaterialDescBymmID?: (variables) => any;
  descriptionLocalesAvailable: Locale[];
  showRequesDescriptionModal: boolean;
  filteredMaterialDescriptionList: MaterialDescription[];
  setShowRequesDescriptionModal: Dispatch<SetStateAction<boolean>>;
  refetchMaterialDescByMmId: () => any;
  setMaterialDescriptionList: any;
}

export interface EditCustomTextRequestModalProps {
  localesToRequest: string[];
  note: string;
  setNote: Dispatch<SetStateAction<string>>;
  showRequestModal: boolean;
  setShowRequestModal: Dispatch<SetStateAction<boolean>>;
  setCloseModalInd: Dispatch<SetStateAction<boolean>>;
  sourceLocale: string;
}

export interface MaterialMasterDescription {
  lsFetchMaterialDescriptionDetailUsingMmId: {
    mmId: number;
    descriptionList: MaterialDescription[];
    descriptionLocale: string;
    descriptionLocalesValid: Locale[];
    descriptionLocalesAvailable: Locale[];
    notes: string;
  }
}

export interface MaterialDescriptionPayload {
  materialDescription: MaterialMasterDescription;
  successMessage: string;
  updatedDescriptionTypes: Locale[];
}

export interface MaterialDescriptionRaiseTranslationRequestPayload {
  mmId: number;
  successMessage: string;
}

export interface MaterialDescriptionListPayload {
  lsFetchMaterialDescriptionListUsingSearchFilter: {
    materialDescriptionList: {
      mmId: number;
      descriptionList: MaterialDescription[];
      descriptionLocale: string;
      descriptionLocalesValid: Locale[];
      descriptionLocalesAvailable: Locale[];
      notes: string;
    }[];
    pageInfo: {
      totalPageCount: number;
      totalItemCount: number;
    }
  }
}

export interface CustomTextListPayload {
  customTextList: CustomText[];
  customTextRequest: CustomTextRequestType;
  targetLocaleList: string[];
}

export interface RadioButtonContainerProps {
  materialDescriptionLoading: boolean;
}

export interface SearchMaterialDescriptionStringsTableProps {
  descriptionType: string;
}

export interface SearchData {
  lsFetchLanguageStringListUsingSearchFilters: {
    languageStringList: FetchLanguageString[];
    pageInfo: {
      totalPageCount: number;
      totalItemCount: number;
    }
  };
}

export interface SearchStringsProps {
  localeLoading: boolean;
  locales: Locale[];
}

export interface EditTranslationsProps {
  locales: Locale[];
}

export interface EditEnglishTranslationModalProps {
  openEditEnglishTranslationModal: boolean;
  setOpenEditEnglishTranslationModal: (openEditEnglishTranslationModal: boolean) => void
  selectedString: FetchLanguageString
  englishRequestedString: string
}

export interface RequestTranslationModalProps {
  showRequestTranslationModal: boolean;
  setShowRequestTranslationModal: (showRequestTranslationModal: boolean) => void;
  requestedLocales: Partial<Translation>[];
  selectedString: FetchLanguageString;
}

export interface LanguageStringContainerProps {
  showFooter: boolean;
}

export interface StringDetailsProps {
  selectedString: FetchLanguageString;
  refetchById: () => any;
  openEditEnglishTranslationModal: boolean;
  showRequesTranslationtModal: boolean;
}

export interface TranslationRowProps {
  englishlocaleind: string;
  locale: string;
  localeDescription: string;
  text: string;
  originalText: string;
  editMode: boolean;
  checked: boolean;
  translationStatus: string;
  loading: boolean;
  saveAllLoading: boolean;
  setFilteredStringTranslationList: React.Dispatch<React.SetStateAction<Partial<Translation>[]>>;
  setRequestedLocales: React.Dispatch<React.SetStateAction<Partial<Translation>[]>>;
  setRequestAll: React.Dispatch<React.SetStateAction<boolean>>;
  allUnTranslatedLocales: Partial<Translation>[];
  setStringTranslationList: React.Dispatch<React.SetStateAction<Partial<Translation>[]>>;
  setOpenEditEnglishTranslationModal: React.Dispatch<React.SetStateAction<boolean>>;
  setEnglishRequestedString: React.Dispatch<React.SetStateAction<string>>;
  stringId: string;
  filteredStringTranslationList: Partial<Translation>[];
  stringTranslationList: Partial<Translation>[];
  upsertTranslation: any;
  upsertTranslationLoading: boolean;
}

export interface LanguageStringsContextType {
  searchBy: string;
  setSearchBy: Dispatch<SetStateAction<string>>;
  refetchInd: boolean;
  setRefetchInd: Dispatch<SetStateAction<boolean>>;
  stringText: string;
  setStringText: Dispatch<SetStateAction<string>>;
  searchLocale: string;
  setSearchLocale: Dispatch<SetStateAction<string>>;
  stringId: string;
  setStringId: Dispatch<SetStateAction<string>>;
  searchData: SearchData;
  setSearchData: Dispatch<SetStateAction<SearchData>>;
  showFooter: boolean;
  setShowFooter: Dispatch<SetStateAction<boolean>>;
  dataNotFound: boolean;
  setDataNotFound: Dispatch<SetStateAction<boolean>>;
  searchStringByTextOrId: any
  searchLoading: boolean;
  searchConstraint: string;
  setSearchConstraint: Dispatch<SetStateAction<string>>;
  ignoreCaseInd: boolean;
  setIgnoreCaseInd: Dispatch<SetStateAction<boolean>>;
  messages: AlertMessage[];
  setMessageText: (messageKey: string, text: string) => void;
}

export interface FooterProps {
  cancelAction: () => void;
  saveAction: () => void;
  cancelActionText: string
  saveActionText: string;
  loading: boolean;
  saveButtonDisabled: boolean;
  children: ReactNode;
  insideGridInd: boolean;
  cancelButtonDisabled: boolean;
}

export interface FooterContainerProps {
  insideGridInd: boolean;
}

export interface CustomTextContextType {
  stringId: string;
  setStringId: Dispatch<SetStateAction<string>>;
  searchInDuration: string;
  SetSearchInDuration: Dispatch<SetStateAction<string>>;
  searchRequestObject: any;
  setSearchRequestObject: Dispatch<SetStateAction<any>>;
  searchData: any;
  setSearchData: Dispatch<SetStateAction<any>>;
  showFooter: boolean;
  setShowFooter: Dispatch<SetStateAction<boolean>>;
  dataNotFound: boolean;
  setDataNotFound: Dispatch<SetStateAction<boolean>>;
  addCustomTextBy: string;
  setAddCustomTextBy: Dispatch<SetStateAction<string>>;
  searchLoading: boolean;
  searchCustomTextHandler: (pageNumber: number, itemsPerPage: number) => void;
  searchCustomText: any;
  messages: Partial<AlertMessage>[];
  setMessageText: (messageKey: string, text: string) => void;
  customTextData: any;
  setCustomTextData: Dispatch<SetStateAction<any>>
  targetLocales: string[];
  setTargetLocales: Dispatch<SetStateAction<string[]>>;
  customTextUniqueId: string;
  setCustomTextUniqueId: Dispatch<SetStateAction<string>>;
  customTextRefetchInd: boolean;
  setCustomTextRefetchInd: Dispatch<SetStateAction<boolean>>;
  saveCustomTextNotes: any;
  saveCustomTextNotesProcessing: boolean;
  customTextNote: string;
  setCustomTextNote: Dispatch<SetStateAction<string>>;
  fetch: (input: RequestInfo, init?: RequestInit) => Promise<Response>;
  targetLocale: string;
  setTargetLocale: Dispatch<SetStateAction<string>>;
  customerEmail: string;
  customerName: string;
}

export interface SendTranslationRequestModalProps {
  existingLocales: Partial<Translation>[];
  selectedLocales: Locale[];
  stringId: string;
  openRequestTranslationModal: boolean;
  onCancel: () => void;
  setOpenRequestTranslationModal: Dispatch<SetStateAction<boolean>>;
}

export interface CreateStringResponse {
  lsCreateLanguageStringInUSEnglishLocale: {
    languageString: Partial<FetchLanguageString>;
  }
}

export interface SelectedChipProps {
  chipselected: string;
}

export interface UntranslatedStringCountAllLocale {
  lsFetchUntranslatedStringCountForAllLocale: unTranslatedLocale[];
}

export interface unTranslatedLocale {
  locale: string;
  materialDescriptionCount: number;
  customTextCount: number;
  languageStringCount: number;
}

export interface UntranslatedStringList {
  lsFetchUntranslatedStringListForLocaleAndCategory: {
    untranslatedStringList: Partial<UnTranslatedString>[];
    pageInfo: {
      totalPageCount: number;
      totalItemCount: number;
    }
  }
}

export interface CreateCustomTextResponse {
  lsCreateCustomText: {
    customTextRequestUniqueId: string;
    customTextStringIdList: string[];
  }
}

export interface CustomTextList {
  lsFetchCustomTextListUsingSearchFilters: {
    customTextList: Partial<CustomText>[];
    pageInfo: {
      totalPageCount: number;
      totalItemCount: number;
    }
  }
}

export interface CustomText {
  stringId: string;
  text: string;
  locale: Locale;
  onHoldInd: boolean;
  briefIdentifierText: string;
  sourceText: string;
  sourceLocale: Locale;
  requestedByName: string;
  requestedByEmail: string;
  soldToPartyNumber: string;
  prevBriefIdentifierText: string;
  prevSourceText: string;
  prevText: string;
  editMode: boolean;
  translationStatus: string;
  programId: number;
  requestRaisedDate: string;
}

export interface UnTranslatedString {
  stringId: number;
  text: string;
  requestedByName: string;
  sourceText: number;
  sapPartNumber: number;
  fieldType: string;
  onHoldInd: boolean;
  date: string;
  selected: boolean;
  sourceLocale: string;
  requestStatus: {
    uniqueId: number;
    createdDate: string;
  }
}

export interface LocaleContextType {
  localeLoading: boolean;
  locales: Locale[];
  localeMap: { [key: string]: string };
}

export interface TranslationRequestContextType {
  selectedLocale: unTranslatedLocale;
  setSelectedLocale: Dispatch<SetStateAction<unTranslatedLocale>>;
  translationCategory: string;
  setTranslationCategory: Dispatch<SetStateAction<string>>;
  searchLoading: boolean;
  unTranslatedLocaleDataLoading: boolean;
  fetchUntranslatedStringList: any;
  refetchUntranslatedStringList: any;
  setUnTranslatedStringList: Dispatch<SetStateAction<Partial<UnTranslatedString>[]>>;
  unTranslatedStringData: UntranslatedStringList;
  unTranslatedStringList: Partial<UnTranslatedString>[];
  createdJobNumber: number;
  setCreatedJobNumber: Dispatch<SetStateAction<number>>;
  setUnTranslatedStringData: Dispatch<SetStateAction<UntranslatedStringList>>;
  messages: AlertMessage[];
  closeMessage: (messageKey: string) => void;
  setMessageText: (messageKey: string, text: string) => void;
  pageNumber: number;
  setPageNumber: Dispatch<SetStateAction<number>>;
  refetchUntranslatedStringListData: (statusUniqueIdList?: number[]) => void
  searchId: string;
  selectAll: boolean;
  setSelectAll: Dispatch<SetStateAction<boolean>>;
  setSearchId: Dispatch<SetStateAction<string>>;
  fetchUntranslatedAllLocaleCount: any;
  allLocales: unTranslatedLocale[];
  setAllLocales: Dispatch<SetStateAction<unTranslatedLocale[]>>;
}

export interface SelectLocaleProps {
  locales: Locale[];
}

export interface TranslationRequestTableRowProps {
  data: Partial<UnTranslatedString>;
  createJobLoading: boolean;
  holdRequestLoading: boolean;
}

export interface DeleteTranslationRequestModalProps {
  showDeleteTranslationRequestModal: boolean;
  setShowDeleteTranslationRequestModal: Dispatch<SetStateAction<boolean>>;
}

export interface TranslationRequestFooterProps {
  setShowDeleteTranslationRequestModal: Dispatch<SetStateAction<boolean>>;
  createJob: any;
  createJobLoading: boolean;
  toggleHoldRequest: any;
}

export interface AlertMessage {
  key: string;
  text: string;
  severity: AlertColor;
  gridSize?: number;
}

export interface JobManagementContextType {
  searchJobsByFilter: any;
  searchLoading: boolean;
  dataNotFound: boolean;
  setDataNotFound: Dispatch<SetStateAction<boolean>>;
  jobSearchDataList: TranslationJob[];
  setJobSearchDataList: Dispatch<SetStateAction<TranslationJob[]>>;
  messages: Partial<AlertMessage>[];
  setMessageText: (messageKey: string, text: string) => void;
  translationStatus: string;
  setTranslationStatus: Dispatch<SetStateAction<string>>;
  pageConfig: PageConfig;
  setPageConfig: Dispatch<SetStateAction<PageConfig>>;
  searchedTranslationCategory: string;
  setSearchedTranslationCategory: Dispatch<SetStateAction<string>>;
  filterParams: JobManagementFilterParams;
  updateFilterParams: (paramName: string, value: string | number) => void;
  refetchJobsByFilter: any;
  jobSearchData: JobSearchData;
}

export interface ViewJobModalProps {
  showViewJobModal: boolean;
  setShowViewJobModal: Dispatch<SetStateAction<boolean>>;
  translationCategory: string;
  jobNumber: number
}

export interface DeleteJobModalProps {
  showDeleteJobModal: boolean;
  setShowDeleteJobModal: Dispatch<SetStateAction<boolean>>;
  jobNumber: number
}

export interface UploadTranslationModalProps {
  showUploadTranslationModal: boolean;
  setShowUploadTranslationModal: Dispatch<SetStateAction<boolean>>;
  uploadFile: (selectedFile: File) => unknown;
}

export interface JobSearchData {
  lsFetchJobList: {
    translationJobList: TranslationJob[];
    pageInfo: {
      totalPageCount: number;
      totalItemCount: number;
    }
  }
}

export interface JobManagementRowProps {
  translationJob: TranslationJob
  performJobAction: (jobNumber: number, actionType: string, locale?: string, downloadedDate?: string) => void
  disabled: boolean;
}

export interface TranslationJob {
  jobNumber: number;
  locale: string;
  downloadedDate: string;
  sentToTranslationDate: string;
  backFromTranslationDate: string;
  uploading?: boolean;
}

export interface MaterialStringContainerProps {
  showFooter: boolean;
}

export interface MaterialDescriptionDetailsProps {
  getMaterialDescriptionByFilters: ({ variables }) => any;
  updateMaterialDescription: ({ variables }) => any;
  setSaveAllLoading: Dispatch<SetStateAction<boolean>>;
  updatingMaterialDescription: boolean;
  notes: string;
  setNotes: Dispatch<SetStateAction<string>>;
  changedNotes: string;
  setChangedNotes: Dispatch<SetStateAction<string>>;
}

export class CustomTextInput {
  briefIdentifierText: string;
  customTextToTranslate: string;
}
export interface BulkUploadProps {
  fetch?: (input: RequestInfo, init?: RequestInit) => Promise<Response>;
}

export interface CustomTextDetailsProps {
  customTextDetails: CustomTextRequestType;
}

export interface CustomTextRowProps {
  customTextData: CustomTextListType;
  briefIdentifierText: string;
  onHoldInd: boolean;
  requestRaisedDate: string;
  requestedByEmail: string;
  requestedByName: string;
  soldToPartyNumber: string;
  sourceText: string;
  stringId: string;
  text: string;
  editMode: boolean;
  loading: boolean;
  prevBriefIdentifierText: string;
  prevSourceText: string;
  prevText: string;
  customTextList: CustomText[];
  setCustomTextList: Dispatch<SetStateAction<any>>
  showRequestModal: boolean;
  setShowRequestModal: Dispatch<SetStateAction<boolean>>;
  closeModalInd: boolean;
  sourceLocale: string;
  selectedLocale: string;
  selectedLocaleRef: any;
  translationStatus: string;
  targetLocales: string[];
}

export interface DeleteCustomTextModalProps {
  showDeleteCustomTextModal: boolean;
  setShowDeleteCustomTextModal: Dispatch<SetStateAction<boolean>>;
  customTextRequest: CustomTextRequestType;
}

export interface CustomTextRequestType {
  uniqueId: number;
  requesterName: string;
  requesterEmail: string;
  requestRaisedDate: string;
  sourceLocale: string;
  soldToPartyNumber: string;
  programId: number;
  notes: string;
}

export interface CustomTextListType {
  stringId: number;
  text: string;
  locale: Locale;
  onHoldInd: boolean;
  briefIdentifierText: string;
  sourceText: string;
  sourceLocale: string;
  requestedByName: string;
  requestedByEmail: string;
  soldToPartyNumber: string;
  requestRaisedDate: string;
}

export interface PageConfig {
  pageNumber: number;
  itemsPerPage: number;
}

export interface JobManagementFilterParams {
  translationCategory: string;
  durationType: string;
  stringId: number;
  mmId: number;
  searchLocale: string;
  jobNumber: number;
}

export interface LocaleSelectionContainerProps {
  fetchCustomTextByStringId: ({ variables }) => any;
  selectedLocale: string;
  setSelectedLocale: Dispatch<SetStateAction<string>>;
}

export interface JobManagementProps {
  fetch?: (input: RequestInfo, init?: RequestInit) => Promise<Response>;
}

export interface CustomTextContextProps {
  fetch?: (input: RequestInfo, init?: RequestInit) => Promise<Response>;
  children: ReactNode;
  customerEmail: string;
  customerName: string;
}

export interface LanguageControlProps {
  fetch: (input: RequestInfo, init?: RequestInit) => Promise<Response>;
  customerName: string;
  customerEmail: string;
}