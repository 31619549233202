import React from "react";
import { Tag } from "@octanner/prism-core";
import { useTranslation } from 'react-i18next';
import { CLIENT_STATUS } from './constants';


export const StatusTag = ({
    customerStatus
}) => {
    const { t } = useTranslation();
    const status = CLIENT_STATUS.find(({ key }) => key === customerStatus);
    const { key, name : statusType, variant } = status;
    return (
        <Tag
        key={key}
        variant={variant}
        test-id={key}
      >
        {t(statusType.toLowerCase())}
      </Tag>
    );
};

export default StatusTag;
