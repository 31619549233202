import { Communication } from './types';

export const communications: Communication[] = [
  {
    key: 'sendWelcomeEmails', // ? Used to edit state.
    title: 'Welcome Email',
    description: 'Send welcome emails to all new users.',
    type: 'boolean',
    editable: true,
  },
];
