import { useMutation } from '@apollo/client';
import styled from '@emotion/styled';
import { Alert, Button, Chip, Modal, Switch, Typography } from '@octanner/prism-core';
import { GraphQLError } from 'graphql';
import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { MaterialStringsContext } from '../../Contexts/MaterialStringsContext';
import { defaults } from '../../utils/default';
import { REQUEST_TRANSLATION_MATERIAL_DESCRIPTION } from '../../utils/query';
import StyledTextField from '../../utils/styledComponents';
import { EditAndRequestDescriptionModalProps, Locale, MaterialDescription, MaterialDescriptionRaiseTranslationRequestPayload, SelectedChipProps } from '../../utils/types';

const StyledChip = styled(Chip) <SelectedChipProps>`
  margin: 8px 8px 0px 0px;
  background-color: #FFFFFF;
  color: ${(props) => (props.chipselected === 'true' ? '#0060E0' : '#000000')};
  border: ${(props) => (props.chipselected === 'true' ? '1px solid #0060E0' : '1px solid black')};
`;

const Header = styled(Typography)`
  margin: 16px 0px;
`;

const StyledTypography = styled(Typography)`
  padding-right: 24px;
`;

const MessageAlert = styled(Alert)`
  width: 70%;
  margin-bottom: 24px;
`;

const ChipContainer = styled.div`
  margin: 16px 0px 24px 0px;
  display: flex;
  flex-wrap: wrap; 
`;

const EditContentContainer = styled.div`
  padding-left: 12px;
  padding-right: 12px;
`;

const ModalContentContainer = styled.div`
  margin-bottom: 24px;
`;

const SwitchContainer = styled.div`
  display : flex;
  margin-top : 24px;
  padding-right: 24px;
`;

const EditAndRequestDescriptionModal: React.FC<EditAndRequestDescriptionModalProps> = ({
  setDescriptionLocalesAvailableInd,
  refetchMaterialDescByMmId,
  mmdLocales,
  setMaterialDescriptionList,
  note,
  descriptionLocalesAvailable,
  editedTextField,
  showRequesDescriptionModal,
  filteredMaterialDescriptionList,
  setShowRequesDescriptionModal
}) => {

  const { setShowFooter, setMessageText, openEditEnglishDescriptionModal, setOpenEditEnglishDescriptionModal } = useContext(MaterialStringsContext);

  const validMmdLocales = mmdLocales.filter((locale: Locale) => locale.code !== 'en-US');
  const { mmId } = useParams();
  const [requestedLocales, setRequestedLocales] = useState(validMmdLocales);
  const [notes, setNotes] = useState<string>(note);
  const [requestExistingTranslation, setRequestExistingTranslation] = useState<boolean>(true);
  const [errorMessageForEditEnglishModal, setErrorMessageForEditEnglishModal] = useState<string>('');

  const [requestTranslation, { loading }] = useMutation(REQUEST_TRANSLATION_MATERIAL_DESCRIPTION, {
    onError: () => {
      setErrorMessageForEditEnglishModal(defaults.update_error);
    }
  });

  const requestTranslations = () => {
    if ((descriptionLocalesAvailable?.length > 0) || showRequesDescriptionModal) {
      requestTranslation({
        variables: {
          input: {
            mmId: parseInt(mmId),
            ...(showRequesDescriptionModal ? { localeList: requestedLocales.map((locale: Locale) => locale.code) } : {}),
            descriptionTypes: showRequesDescriptionModal ? (filteredMaterialDescriptionList
              .filter(description => description.checked === true)
              .map(description => description.fieldType)) : editedTextField.map((field: MaterialDescription) => field.fieldType),
            notes: notes,
            ...(showRequesDescriptionModal ? { useExistingTranslationsInd: requestExistingTranslation } : {})
          }
        }
      }).then((response: { data: MaterialDescriptionRaiseTranslationRequestPayload; errors?: GraphQLError[] }) => {
        if (response.errors) {
          setErrorMessageForEditEnglishModal(defaults.update_error);
          if (!showRequesDescriptionModal) {
            setMessageText('errorMessage', defaults.update_error);
            closeModal();
          }
        } else {
          if (showRequesDescriptionModal) {
            setMessageText('editSuccessMessage', '');
          }
          let successMessage = defaults.request_success;
          if ((descriptionLocalesAvailable && descriptionLocalesAvailable.length > 0) || (showRequesDescriptionModal && requestedLocales)) {
            const localeDescriptions = (showRequesDescriptionModal && requestedLocales) ?
              requestedLocales.map((translation: Locale) => translation.languageDescription)
              : descriptionLocalesAvailable.map((translation: Locale) => translation.languageDescription);
            if (localeDescriptions.length > 0) {
              const lastLocale = localeDescriptions.pop();
              successMessage += ` for ${localeDescriptions.join(', ')}${localeDescriptions.length > 0 ? ' and ' : ''}${lastLocale}`;
            }
          }
          setMessageText('requestSuccessMessage', successMessage);
          closeModal();
        }
      })
    }
  }

  const closeModal = () => {
    setDescriptionLocalesAvailableInd(false)
    setShowRequesDescriptionModal(false);
    setShowFooter(false);
    setErrorMessageForEditEnglishModal('');
    setOpenEditEnglishDescriptionModal(false);
    setMaterialDescriptionList([]);
    refetchMaterialDescByMmId();
  }

  const resetModal = () => {
    if (!loading) {
      setRequestExistingTranslation(true);
      setOpenEditEnglishDescriptionModal(false);
      setDescriptionLocalesAvailableInd(false);
      setShowRequesDescriptionModal(false);
    }
  }

  const toggleLocaleSelection = (locale: Locale) => {
    if (!requestedLocales.includes(locale)) {
      setErrorMessageForEditEnglishModal('');
      setRequestedLocales([...requestedLocales, locale]);
    }
  };

  const removeLocale = (locale: Locale) => {
    setErrorMessageForEditEnglishModal('');
    setRequestedLocales(requestedLocales.filter((item) => item !== locale));
    (requestedLocales.length < 2) ? setErrorMessageForEditEnglishModal(defaults.at_least_one_locale) : setErrorMessageForEditEnglishModal('');
  };

  return (
    <Modal
      maxWidth="sm"
      actions={<>
        <Button color="secondary" disabled={loading} onClick={() => resetModal()}>{
          defaults.cancel}
        </Button>
        <Button color="primary" disabled={!requestedLocales.length || (notes?.trim().length && notes.trim().length > 4000)} onClick={() => requestTranslations()}
          loading={loading}>{showRequesDescriptionModal ? defaults.submit_request : defaults.send_requests}</Button>
      </>}
      id="confirm-english-description"
      title={showRequesDescriptionModal ? defaults.request_translations : defaults.send_translation}
      open={openEditEnglishDescriptionModal}
      onClose={() => resetModal()}>
      {errorMessageForEditEnglishModal && errorMessageForEditEnglishModal.length > 0 && <MessageAlert
        onClose={() => setErrorMessageForEditEnglishModal('')}
        severity="error">
        {errorMessageForEditEnglishModal}
      </MessageAlert>}
      <ModalContentContainer>
        {showRequesDescriptionModal ? (
          <>
            <SwitchContainer>
              <Switch
                checked={requestExistingTranslation}
                onChange={() => setRequestExistingTranslation(!requestExistingTranslation)} />
              <div>
                <Typography>
                  {defaults.send_req_for_existing_translation}
                </Typography>
                <Typography variant='body2' color='textSecondary'>
                  {defaults.toggle_btn_description}
                </Typography>
              </div>
            </SwitchContainer>
            {validMmdLocales?.length > 0 && (
              <>
                <Header variant="h4">{defaults.select_lang_to_send_translation}</Header>
                <ChipContainer>
                  {validMmdLocales.map((item) => (
                    <StyledChip
                      key={item.code}
                      label={item.languageDescription}
                      variant='outlined'
                      chipselected={requestedLocales.includes(item).toString()}
                      onClick={() => toggleLocaleSelection(item)}
                      onDelete={requestedLocales.includes(item) ? () => removeLocale(item) : undefined}
                    />
                  ))}
                </ChipContainer>
              </>
            )}
          </>) : (
          <EditContentContainer>
            <StyledTypography variant="body1">
              {defaults.english_locale_warning_for_mmd}
            </StyledTypography>
            {descriptionLocalesAvailable?.length > 0 && (
              <>
                <Header variant="h4">{defaults.send_translation_request}</Header>
                <ChipContainer>
                  {descriptionLocalesAvailable.map((item: Locale) => (
                    <StyledChip
                      key={item.code}
                      label={item.languageDescription}
                      variant='outlined' chipselected='true' />
                  ))}
                </ChipContainer>
              </>
            )}
          </EditContentContainer>)}
        <Header variant="h4">{defaults.notes}</Header>
        <StyledTextField
          label={defaults.add_note}
          multiline
          fullWidth
          displayCount={true}
          maxLength={4000}
          rows={4}
          value={notes}
          onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setNotes(e.target.value)}
        />
      </ModalContentContainer>
    </Modal>
  );
}

export default EditAndRequestDescriptionModal;
