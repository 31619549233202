import { useParams } from 'react-router-dom';
import { useGetAdminRole } from '../hooks/getAdminRole';
import Loading from '../../../../../common/Loading';
import CreateRoleBody from '../components/CreateRoleBody';

const DuplicateAdminRole = () => {
  const { roleUuid } = useParams<{ roleUuid: string }>();
  const { data: role, loading } = useGetAdminRole(roleUuid);

  return loading ? (
    <Loading />
  ) : (
    <CreateRoleBody
      type="duplicate"
      headerText={`Duplicate ${role?.name}`}
      headerDescription={`Permissions from ${role?.name} have been duplicated below.`}
      initialRoleType={role?.accessType}
      initialPermissions={role?.permissions}
    />
  );
};

export default DuplicateAdminRole;
