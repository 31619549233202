import { gql } from '@apollo/client';

export const GET_STATES = gql`
  query GetStatesByCountry($countryIso2Code: String!) {
    getStatesByCountry(countryIso2Code: $countryIso2Code) {
      stateName
      stateCode
      coreCountry {
        countryName
        iso2Code
        continent {
          continentCode
        }
      }
    }
  }
`;

export const SALES_ORG_LIST = gql`
  query allCountriesWithEligibleSalesOrgs {
    allCountriesWithEligibleSalesOrgs {
      countryWithEligibleSalesOrgs {
        coreCountry {
          countryName
          iso2Code
        }
        recommendedSalesOrg {
          salesOrgCode
          salesOrgName
          currency {
            currencyCode
            currencyName
            currencySymbol
          }
        }
        eligibleSalesOrgs {
          currency {
            currencyCode
            currencyName
            currencyNum
            currencySymbol
          }
          salesOrgCode
          salesOrgCompanyCode
          salesOrgDistribution
          salesOrgDivision
          salesOrgName
        }
      }
    }
  }
`;

export const GET_INVOICE_CONFIGURATION = gql`
query invoiceConfiguration {
  allCoreInvoiceMediums {
    coreInvoiceMediums {
      nodes {
        isDefault
        invoiceMediumCode
        invoiceMediumDescription
      }
    }
    defaultCoreInvoiceMedium {
      invoiceMediumDescription
      invoiceMediumCode
      isDefault
    }
  }
  allCoreBillingSchedules {
    coreBillingSchedule {
      nodes {
        isDefault
        billingScheduleCode
        billingScheduleDescription
      }
    }
    defaultCoreBillingSchedule {
      billingScheduleCode
      billingScheduleDescription
      isDefault
    }
  }
  allCoreBillingTypes {
    coreBillingTypes {
      nodes {
        billingTypeCode
        isDefault
        billingTypeDescription
      }
    }
    defaultCoreBillingType {
      billingTypeCode
      billingTypeDescription
      isDefault
    }
  }
}
`;
