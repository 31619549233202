import PageWrapper from '../../components/PageWrapper';
import BasicPadding from '../../../../../common/BasicPadding';
import ClientHeader from '../components/ClientHeader';
import ClientDetails from '../components/ClientDetails';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { TEST_CLIENTS } from '../constants';
import { AdminRoleInformation, Success, SuccessData } from '../../types';
import AddAdminRoleSearch from '../../components/AddAdminRoleSearch';
import Typography from '@octanner/prism-core/Typography';
import { Box } from '@octanner/prism-core';
import RoleTabs from '../../components/RoleTabs';
import { useHistory } from 'react-router';
import SuccessHandler from '../../SuccessHandler';
import UnsavedModal from '../../components/UnsavedModal';
import RemoveAllRolesModal from '../../components/RemoveAllRolesModal';
import {
  TEST_ACTIVE_ADMIN_ROLES_PAGED_DATA,
  TEST_INACTIVE_ADMIN_ROLES_PAGED_DATA,
} from '../../constants';
import AdminPagination from '../../components/AdminPagination';

const EditClientRoles = () => {
  const history = useHistory();
  const { customerUuid } = useParams<{ customerUuid: string }>();
  const [customer, setCustomer] = useState(
    TEST_CLIENTS.find((c) => c.customerUuid === customerUuid) || TEST_CLIENTS[0]
  );
  const [activeRolesPageNumber, setActiveRolesPageNumber] = useState(1);
  const [activeRolesPageSize, setActiveRolesPageSize] = useState(10);
  const [pagedActiveRoles, setPagedActiveRoles] = useState(
    TEST_ACTIVE_ADMIN_ROLES_PAGED_DATA
  );
  const [inactiveRolesPageNumber, setInactiveRolesPageNumber] = useState(1);
  const [inactiveRolesPageSize, setInactiveRolesPageSize] = useState(10);
  const [pagedInactiveRoles, setPagedInactiveRoles] = useState(
    TEST_INACTIVE_ADMIN_ROLES_PAGED_DATA
  );
  const [rolesToAdd, setRolesToAdd] = useState<AdminRoleInformation[]>([]);
  const [rolesToRemove, setRolesToRemove] = useState<AdminRoleInformation[]>(
    []
  );
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [unsavedModalOpen, setUnsavedModalOpen] = useState(false);
  const [removeAllModalOpen, setRemoveAllModalOpen] = useState(false);
  const [removeAllRoles, setRemoveAllRoles] = useState(false);

  const [editSuccessData, setEditSuccessData] = useState<SuccessData>({
    enabled: false,
    message: `Updates to ${customer.name} were successfully saved.`,
  });

  const editSuccess: Success = {
    successData: editSuccessData,
    setSuccessData: setEditSuccessData,
  };

  const onDiscard = () => {
    history.push('/client-access');
  };

  const onSave = () => {
    // Save action
    setEditSuccessData({
      ...editSuccessData,
      enabled: true,
    });
  };

  const onCancel = () => {
    // Cancel action
    if (hasUnsavedChanges) {
      setUnsavedModalOpen(true);
    } else {
      onDiscard();
    }
  };

  useEffect(() => {
    setHasUnsavedChanges(!!rolesToAdd.length || !!rolesToRemove.length);
  }, [rolesToAdd, rolesToRemove]);

  return (
    <PageWrapper
      type="edit"
      context="client-access"
      saveAction={onSave}
      cancelAction={onCancel}
    >
      <BasicPadding padding="sm">
        <RemoveAllRolesModal
          open={removeAllModalOpen}
          setOpen={setRemoveAllModalOpen}
          onConfirm={() => setRemoveAllRoles(true)}
          entityName={customer.name}
        />
        <UnsavedModal
          open={unsavedModalOpen}
          setOpen={setUnsavedModalOpen}
          onConfirm={onDiscard}
        />
        <ClientHeader onNavigateAway={onCancel} />
        <SuccessHandler success={editSuccess} />
        <ClientDetails customer={customer} />
        <Typography variant="h2" mt={6}>
          Add a role
        </Typography>
        <AddAdminRoleSearch
          rolesToAdd={rolesToAdd}
          setRolesToAdd={setRolesToAdd}
        />
        <Box sx={{ pb: '64px' }}>
          <RoleTabs
            objectName={customer.name}
            setRemoveAllModalOpen={setRemoveAllModalOpen}
            pagedActiveRoles={pagedActiveRoles}
            pagedInactiveRoles={pagedInactiveRoles}
            rolesToAdd={rolesToAdd}
            setRolesToAdd={setRolesToAdd}
            rolesToRemove={rolesToRemove}
            setRolesToRemove={setRolesToRemove}
            activeRolesPagination={
              <AdminPagination
                totalCount={pagedActiveRoles?.total || 0}
                pageNumber={activeRolesPageNumber}
                setPageNumber={setActiveRolesPageNumber}
                pageSize={activeRolesPageSize}
                setPageSize={setActiveRolesPageSize}
              />
            }
            inactiveRolesPagination={
              <AdminPagination
                totalCount={pagedInactiveRoles?.total || 0}
                pageNumber={inactiveRolesPageNumber}
                setPageNumber={setInactiveRolesPageNumber}
                pageSize={inactiveRolesPageSize}
                setPageSize={setInactiveRolesPageSize}
              />
            }
            setRemoveAllRoles={setRemoveAllRoles}
          />
        </Box>
      </BasicPadding>
    </PageWrapper>
  );
};

export default EditClientRoles;
