import { Alert, Button, Typography } from '@octanner/prism-core'
import { styled } from '@mui/material/styles'
import colors from 'library/src/common/colors'
import { CelebrationLevelConfig } from 'library/src/common/models/CelebrationLevelConfig'
import { CelebrationLevelGroupConfig } from 'library/src/common/models/CelebrationLevelGroupConfig'
import React, { useEffect, useState } from 'react'
import { HeaderContainer } from '../../common/components/Styled'
import { Header } from '../CelebrationGroupPointsView/CelebrationGroupPointsView'

const PointContainer = styled('section')`
  display: flex;
  width: calc(100% - 350px);
  justify-content: flex-start;
  gap: ${({ theme }) => theme.spacing(3)};
  flex-wrap: wrap;
`

const RankPoints = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: start;
  background: ${colors.gray100};
  border-radius: 3px;
  padding: 2.5px 8px;
  min-width: 71px;
  max-width: fit-content;
  height: fit-content;
`

interface Props {
  alert: boolean
  celebrationLevelGroupConfigs: CelebrationLevelGroupConfig[]
  onEditClick: () => void
  hideEdit?: boolean
}

const PointLevelConfigurationView = ({
  celebrationLevelGroupConfigs,
  alert,
  onEditClick,
  hideEdit,
}: Props): JSX.Element => {
  const [groups, setGroups] = useState<CelebrationLevelConfig[]>([])

  useEffect(() => {
    if (!groups?.length)
      celebrationLevelGroupConfigs?.map((group) =>
        setGroups((prevState) =>
          [...prevState, ...group.celebrationLevelConfigs].sort((a, b) =>
            a.rank > b.rank ? 1 : -1
          )
        )
      )
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [celebrationLevelGroupConfigs])

  return (
    <section
      data-testid="pointLevelConfig:container"
      style={{ marginBottom: 2 }}
    >
      <HeaderContainer>
        <Header variant="h3" data-testid="pointLevelConfig:title">
          Point Level Configuration
        </Header>
        {!hideEdit && (
          <Button
            data-testid="pointLevelConfig:view:edit"
            variant="text"
            onClick={onEditClick}
          >
            Edit
          </Button>
        )}
      </HeaderContainer>
      {alert && (
        <Alert
          sx={{ my: 4 }}
          severity="success"
          data-testid="updateCoreAwardLevel:alert"
        >
          Point Level Configurations have been edited
        </Alert>
      )}
      <PointContainer>
        {groups.map((group) => (
          <RankPoints
            key={group.id}
            data-testid={`userSelectedGiftRange:points:${group.name}`}
          >
            <Typography
              variant="body2"
              data-testid={'userSelectedGiftRange:rank'}
            >
              {group.name}
            </Typography>
            <Typography
              variant="body1"
              data-testid={`userSelectedGiftRange:points`}
            >
              {group?.userSelectedAwardCoreAwardLevel?.minPoints} -{' '}
              {group?.userSelectedAwardCoreAwardLevel?.maxPoints}
            </Typography>
          </RankPoints>
        ))}
      </PointContainer>
    </section>
  )
}

export default PointLevelConfigurationView
