import styled from '@emotion/styled';
import {
  Skeleton,
  Typography,
  Card,
  FormControl,
  Grid,
  TextField,
  Button,
  CardHeader,
  Tag,
} from '@octanner/prism-core';
import { Lock, ShoppingCart } from '@octanner/prism-icons';
import colors from '../../../common/constants/Colors';
import statusLabel from '../../../common/statusLabel';

export const MarginBottomButton = styled(Button)`
  margin-bottom: 24px;
`;

export const StatusLabelMarginTop = styled(statusLabel)`
  margin-top: 8px;
`;

export const BankMarginTop = styled(Typography)`
  margin-top: 16px;
`;

export const MaxWidthTextField = styled(TextField)`
  width: 100%;
`;

export const GridCenterAlign = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const NewBankTitle = styled(Typography)`
  margin-top: 16px;
  margin-bottom: 16px;
`;

export const CreateButton = styled.div`
  width: 100%;
  margin: 8px;
`;

export const FormControlContainer = styled(FormControl)`
  width: 100%;
  margin: 8px;
`;

export const LabelContainer = styled.div`
  display: flex;
  margin-top: 32px;
`;

export const IconAndTypography = styled.div`
  display: flex;
`;

export const TypographyText = styled(Typography)`
  margin-left: 13px;
  color: ${colors.black};
`;

export const DefaultStatus = styled(Tag)`
  padding: 2px 8px 2px 8px;
  margin-left: 8px;
  border-radius: 3px;
`;

export const CardSkeleton = styled(Skeleton)`
  @media (max-width: 880px) {
    min-width: 400px;
  }
  @media (max-width: 700px) {
    min-width: 256px;
  }
  width: 30%;
  margin: 8px;
  border-radius: 3px;
`;

export const CardContainer = styled.div`
  @media (max-width: 880px) {
    display: flex;
  }
  display: flex;
  flex-wrap: wrap;
`;

export const SingleCardContainer = styled(Card)`
  @media (max-width: 880px) {
    min-width: 400px;
  }
  @media (max-width: 700px) {
    min-width: 256px;
  }

  width: 30%;
  margin: 8px;
`;

export const NoCardContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  background-color: ${colors.gray300};
  padding: 24px;
`;

export const NoCardTitle = styled(Typography)`
  padding-bottom: 8px;
`;

export const NoCardText = styled(Typography)`
  color: #000;
  padding-bottom: 32px;
`;

export const BankHeader = styled.div`
  display: flex;
  margin-bottom: 16px;
  @media (max-width: 700px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const BankSettingMargin = styled(Typography)`
  margin-left: 8px;
`;

export const AlignRightContainer = styled(Button)`
  display: flex;
  margin-left: auto;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 700px) {
    margin-left: 8px;
    margin-top: 16px;
    margin-bottom: 10px;
  }
`;

export const CardHeaderColor = styled(CardHeader)`
  color: ${colors.cardTitleGray};
`;

export const LockMB = styled(Lock)`
  margin-bottom: 12px;
`;

export const ShoppingCartMB = styled(ShoppingCart)`
  margin-bottom: 12px;
`;
