import { useStyles } from './styles';
import { withPad } from '../../common/BasicPadding';
import ClientInformationComponent from './ClientInformation';

const Client = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <ClientInformationComponent />
    </div>
  );
};

export default withPad()(Client);
