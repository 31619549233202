import { useStyles } from './styles';
import { useContext, useState } from 'react';
import { NetworkStatus } from '@apollo/client';
import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Switch,
  Typography,
} from '@octanner/prism-core';
import CollapseIcon from '../../common/CollapseIcon';
import ScrollLoader from '../../common/ScrollLoader';
import { CustomerContext } from '../../utils/context/CustomerContext';
import { useGetPermissionSummary } from './hooks/permission';
import { PermissionsListProps } from './types';
import CircularProgress from '../../common/CircularProgress';

const PermissionsList = ({ permissionTotal, role }: PermissionsListProps) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [, setPage] = useState(1);
  const { feature, total, totalCoupled } = permissionTotal;
  const {
    customer: { id: customerId },
  } = useContext(CustomerContext);
  const permissionSummaryQuery = useGetPermissionSummary(
    customerId,
    feature,
    role,
    open
  );
  const pageInfo =
    permissionSummaryQuery.data?.getPermissionSummaryByFeatureAndRole;
  const permissions = pageInfo?.content || [];

  return (
    <div>
      <ListItem
        button
        className={classes.item}
        onClick={() => setOpen((open) => !open)}
        disableRipple
      >
        <ListItemIcon>
          <Switch
            color="primary"
            data-test={`feature:permission:${feature.code}`}
            className={classes.toggle}
            checked={totalCoupled > 0}
            onClick={(e) => e.stopPropagation()}
            onChange={(e) =>
              e.target.checked
                ? permissionSummaryQuery.actions.coupleFeature()
                : permissionSummaryQuery.actions.decoupleFeature()
            }
          />
        </ListItemIcon>
        <ListItemText>
          <div className={classes.textContainer}>
            <Typography variant="h3" className={classes.text}>
              {feature.name}
            </Typography>
            <CollapseIcon
              isLoading={permissionSummaryQuery.loading}
              isOpen={open}
              className={classes.textExpand}
            />
          </div>
        </ListItemText>
        <Typography>
          ({totalCoupled} of {total} active)
        </Typography>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <div>
          {permissions.map((ps) => (
            <List key={ps.permission.id} component="div" disablePadding>
              <ListItem className={classes.nested}>
                <ListItemIcon>
                  <Switch
                    data-test={`permission:${ps.permission.id}`}
                    className={classes.toggle}
                    checked={ps.isCoupled}
                    onChange={(e) =>
                      e.target.checked
                        ? permissionSummaryQuery.actions.couplePermissions({
                            variables: { permissionIds: [ps.permission.uuid] },
                          })
                        : permissionSummaryQuery.actions.decouplePermissions({
                            variables: { permissionIds: [ps.permission.uuid] },
                          })
                    }
                  />
                </ListItemIcon>
                <ListItemText primary={ps.permission.name} />
              </ListItem>
            </List>
          ))}
          {permissionSummaryQuery.networkStatus === NetworkStatus.fetchMore && (
            <div style={{ textAlign: 'center', padding: 16 }}>
              <CircularProgress size={32} />
            </div>
          )}
          {pageInfo && !pageInfo.last && (
            <ScrollLoader
              load={() => {
                if (
                  permissionSummaryQuery.networkStatus ===
                  NetworkStatus.fetchMore
                )
                  return;
                setPage((page) => {
                  permissionSummaryQuery.fetchMore({
                    variables: {
                      featureCode: feature.code,
                      page,
                    },
                  });
                  return page + 1;
                });
              }}
            />
          )}
        </div>
      </Collapse>
    </div>
  );
};
export default PermissionsList;
