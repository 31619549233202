import { FormatBold } from '@material-ui/icons';
import {
  FormatItalic,
  FormatListBulleted,
  FormatListNumbered,
  FormatUnderlined,
} from '@styled-icons/material';
import { Looks3 } from '@styled-icons/material/Looks3';
import { Looks4 } from '@styled-icons/material/Looks4';
import { Looks5 } from '@styled-icons/material/Looks5';
import { Looks6 } from '@styled-icons/material/Looks6';
import { LooksOne } from '@styled-icons/material/LooksOne';
import { LooksTwo } from '@styled-icons/material/LooksTwo';
import { Link } from '@styled-icons/material';
import {
  ELEMENT_H1,
  ELEMENT_H2,
  ELEMENT_H3,
  ELEMENT_H4,
  ELEMENT_H5,
  ELEMENT_H6,
  ELEMENT_LINK,
  ELEMENT_OL,
  ELEMENT_TABLE,
  ELEMENT_UL,
  MARK_BOLD,
  MARK_ITALIC,
  MARK_UNDERLINE,
} from '@udecode/plate';
interface ToolbarContent {
  element: string;
  icon: JSX.Element | null;
  type: 'MARK' | 'BLOCK' | 'LIST' | 'LINK' | 'TABLE';
}

export const ToolbarButton: ToolbarContent[] = [
  {
    element: ELEMENT_H1,
    icon: <LooksOne />,
    type: 'BLOCK',
  },
  {
    element: ELEMENT_H2,
    icon: <LooksTwo />,
    type: 'BLOCK',
  },
  {
    element: ELEMENT_H3,
    icon: <Looks3 />,
    type: 'BLOCK',
  },
  {
    element: ELEMENT_H4,
    icon: <Looks4 />,
    type: 'BLOCK',
  },
  {
    element: ELEMENT_H5,
    icon: <Looks5 />,
    type: 'BLOCK',
  },
  {
    element: ELEMENT_H6,
    icon: <Looks6 />,
    type: 'BLOCK',
  },
  {
    element: MARK_BOLD,
    icon: <FormatBold />,
    type: 'MARK',
  },
  {
    element: MARK_ITALIC,
    icon: <FormatItalic />,
    type: 'MARK',
  },
  {
    element: MARK_UNDERLINE,
    icon: <FormatUnderlined />,
    type: 'MARK',
  },
  {
    element: ELEMENT_UL,
    icon: <FormatListBulleted />,
    type: 'LIST',
  },
  {
    element: ELEMENT_OL,
    icon: <FormatListNumbered />,
    type: 'LIST',
  },
  {
    element: ELEMENT_LINK,
    icon: <Link />,
    type: 'LINK',
  },
  {
    element: ELEMENT_TABLE,
    icon: null,
    type: 'TABLE',
  },
];
