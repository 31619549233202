import styled from "@emotion/styled";
import { Grid, InputAdornment, MenuItem, Select, TextField, Typography } from '@octanner/prism-core';
import { Lock } from '@octanner/prism-icons';
import React, { useContext } from "react";
import { useParams } from 'react-router-dom';
import { CustomTextContext } from "../../Contexts/CustomTextContext";
import { LocaleContext } from "../../Contexts/LocaleContext";
import { defaults } from "../../utils/default";
import { LocaleSelectionContainerProps } from "../../utils/types";

const TranslationDescriptionContainer = styled.div`
  margin-top: 36px;
`;

const LocaleContainer = styled.div`
  margin-top: 18px;
  margin-bottom: 16px;
`;

const LocaleSelectionContainer: React.FC<LocaleSelectionContainerProps> = ({ fetchCustomTextByStringId, selectedLocale, setSelectedLocale }) => {

  const { customTextData, setMessageText, targetLocales, setTargetLocale } = useContext(CustomTextContext);
  const { localeMap } = useContext(LocaleContext);
  const { stringId } = useParams();

  const setSelectedDescriptionLocale = (event: string) => {
    setMessageText('errorMessage', '');
    setMessageText('deleteSuccessMessage', '');
    setMessageText('successMessage', '');
    setMessageText('requestSuccessMessage', '');
    setSelectedLocale(event);
    setTargetLocale(event);
    if (stringId) {
      fetchCustomTextByStringId({
        variables: {
          stringId,
          targetLocale: event
        }
      })
    }
  }

  return (
    <>
      <TranslationDescriptionContainer>
        <Typography variant="h3">{defaults.translations}</Typography>
        <Typography variant="body1">{defaults.string_info_desc}</Typography>
      </TranslationDescriptionContainer>
      <LocaleContainer>
        <Grid container spacing={4}>
          <Grid item xs={3.5}>
            <TextField
              value={customTextData.customTextRequest.sourceLocale === 'id-ID' ? 'in-ID' : customTextData.customTextRequest.sourceLocale}
              label={defaults.source_locale}
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <InputAdornment position="end">
                    <Lock />
                  </InputAdornment>
                )
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={3.5}>
            <Select
              label={defaults.select_languages_title}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSelectedDescriptionLocale(event.target.value)}
              value={selectedLocale}
              fullWidth>
              {targetLocales?.map((locale: string) => (
                <MenuItem key={locale} value={locale}>
                  {localeMap[locale]}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
      </LocaleContainer>
    </>
  )
}

export default LocaleSelectionContainer;