import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Styles from '../../common/constants/Styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      '& > div + div': {
        marginTop: theme.spacing(1),
      },
    },
    formRow: {
      display: 'flex',
      '& > div + div': {
        marginLeft: theme.spacing(1),
      },
      '& > div': {
        flex: '1 1 1px',
      },
    },
    search: {
      marginTop: theme.spacing(3),
    },
    alignRight: {
      display: 'flex',
      marginLeft: 'auto',
      marginTop: 'auto',
      marginBottom: 'auto',
    },
    headerCell: {
      fontSize: theme.typography.subtitle2.fontSize,
    },
    row: {
      cursor: 'pointer',
    },
    cancel: {
      marginLeft: theme.spacing(1),
    },
    endImpersonating: {
      boxShadow: Styles.boxShadowTopAndBottom,
      display: 'flex',
      padding: theme.spacing(1),
    },
    impersonateText: {
      marginTop: 'auto',
      marginBottom: 'auto',
    },
    topSpacing: {
      marginTop: theme.spacing(2),
    },
    impersonateBannerWrapper: {
      top: '0px',
      position: 'sticky',
      zIndex: 1,
    },
    impersonatePagination: {
      marginTop: '24px',
    },
    recentImpersonateTitle: {
      fontSize: '20px',
      fontWeight: 800,
      marginTop: '24px',
    },
  })
);
