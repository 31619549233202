import React, { useEffect } from 'react';
import 'whatwg-fetch';
import 'promise-polyfill';
import { useContext, useState } from 'react';
import { Details } from './Details';
import { useQuery } from '@apollo/client';
import { loadCustomerId } from '../../utilities/utility';
import { GET_CUSTOMER_NAME } from './query';
import { defaults } from '../../utilities/default';
import usei18 from '../../hooks/i18n';
import LayoutContext from '@octanner/prism-core/context/Layout';
import { Identity } from '@octanner/prism-core/Header/interfaces';

const ClientConfig: React.FC<ClientConfigProps> = ({ customerUUID, ccAdminEnableComboModel, historyLocationProp ,enableApplicationsTab}) => {
  const [refetchEditCustomer, setRefetchEditCustomer] = useState(false);

  const [currentIdentityQuery] = useContext(LayoutContext);

  const currentIdentity: Identity | undefined = currentIdentityQuery?.data?.currentIdentity;

  useEffect(() => {
    fetch(
      `https://vision.appreciatehub.com/api/translations/${
        currentIdentity?.customer?.stpNumber || 'locale'
      }/clientconfig`,
      {
        method: 'GET',
        headers: {
          'accept-language': currentIdentity?.language,
        },
      },
    )
      .then(
        (res) => {
          if (!res.ok && res.status !== 401) {
            return defaults;
          }
          return res.json();
        },
        () => defaults,
      )
      .then((data) => {
        // eslint-disable-next-line no-restricted-syntax
        for (const key in defaults) {
          if (!data[key.toString()]) {
            // eslint-disable-next-line no-param-reassign
            data[key.toString()] = defaults[key.toString()]
              .replace(/<br>/g, '\n')
              .replace(/%{/g, '{');
          } else {
            // eslint-disable-next-line no-param-reassign
            data[key.toString()] = data[key.toString()]
              .replace(/<br>/g, '\n')
              .replace(/%{/g, '{');
          }
        }
      });
  }, [currentIdentity]);
  usei18(defaults);
  const {
    loading: loadingCustomerName,
    data: dataCustomerName,
    refetch: refetchCustomerName,
  } = useQuery(GET_CUSTOMER_NAME, {
    variables: {
      customerId: customerUUID,
    },
  });
  loadCustomerId(customerUUID);
  useEffect(() => {
    if (refetchEditCustomer) {
      refetchCustomerName().then(() => {
        setRefetchEditCustomer(false);
      });
    }
  }, [refetchEditCustomer]);
  return (
    <>
      {dataCustomerName && !refetchEditCustomer && (
        <Details
          dataCustomerName={dataCustomerName}
          refetchCustomerName={refetchCustomerName}
          customerId={customerUUID}
          setRefetchEditCustomer={setRefetchEditCustomer}
          ccAdminEnableComboModel={ccAdminEnableComboModel}
          historyLocationProp={historyLocationProp}
          enableApplicationsTab={enableApplicationsTab}
        />
      )}
      {(loadingCustomerName || refetchEditCustomer) && (
        <div>{defaults['loading']}</div>
      )}
    </>
  );
};

export interface ClientConfigProps {
  customerUUID: string;
  ccAdminEnableComboModel: boolean;
  historyLocationProp: {
    location:any;
    history:any;
  }
  enableApplicationsTab: boolean;
}

export default ClientConfig;
