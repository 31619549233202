import { BankListViewModel, Bank } from './types';

export function toBankListVM({
  pointBankUuid,
  pointBankName,
  defaultAccruingBank,
  pointBankType,
  isActive,
  billingConfig,
  countPrograms,
  customer,
}: Bank): BankListViewModel {
  return {
    uuid: pointBankUuid,
    name: pointBankName,
    defaultBank: defaultAccruingBank,
    status: isActive ? 'Active' : 'Inactive',
    programCount: countPrograms,
    bankType: pointBankType,
    billConfig: {
      billType: billingConfig?.billingType || '',
    },
    cust: {
      defaultAccruingBank: {
        defaultAccruingBank: customer.defaultAccruingBank?.defaultAccruingBank,
      },
    },
  };
}
