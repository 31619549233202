import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import {
  Autocomplete,
  Checkbox,
  TextField,
  Typography,
  MenuItem,
  BadgeSecondary,
} from '@octanner/prism-core';
import { useEffect, useState } from 'react';
import { Role } from '../user-auth/models/Role';
import { RoleSelectorProps } from './types';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const truncate = (str: string, n: number) => {
  return str.length > n ? str.substring(0, n - 1) + '...' : str;
};

const RoleSelector = ({
  selected,
  onChange,
  className,
  dataTestId,
  data,
}: RoleSelectorProps) => {
  const [valueText, setValueText] = useState<string[]>([]);
  const portedRoles = data?.roles.content || [];
  const selectedUUID = selected.map((x) => x.uuid);
  // ? Below is a typescript bug. Even though I filter out ever null with the filter function, it still thinks a null would be possible...
  //@ts-ignore
  const selectedRoles: Role[] = portedRoles
    .map((x) => (selectedUUID.indexOf(x.uuid) !== -1 ? x : null))
    .filter((n) => n);
  //@ts-ignore
  const unselectedRoles: Role[] = portedRoles
    .map((x) => (selectedUUID.indexOf(x.uuid) === -1 ? x : null))
    .filter((n) => n);

  const orderedRoles = [selectedRoles, unselectedRoles].flat();

  useEffect(() => {
    const roleNames = selected.map((role) => role.name);
    setValueText(roleNames);
  }, [selected]);

  return (
    <Autocomplete
      limitTags={2}
      className={className}
      multiple
      options={orderedRoles.filter((n) => n)}
      disableCloseOnSelect
      isOptionEqualToValue={(a: Role, b: Role) => a.uuid === b.uuid}
      onChange={(_, option) => onChange(option)}
      value={selected}
      getOptionLabel={(option) => option.name}
      disableClearable
      renderOption={(props: any, option: any) => (
        <MenuItem {...props}>
          <Checkbox
            data-test={`role-selector:${dataTestId}:role:${option.uuid}`}
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selectedUUID.indexOf(option.uuid) !== -1}
          />
          {option.name}
        </MenuItem>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Role"
          inputProps={{
            ...params.inputProps,
            'data-test': `role-selector:${dataTestId}:input`,
          }}
          InputLabelProps={{ shrink: selected.length ? true : false }}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <>
                {valueText &&
                  valueText.map((badgeName, index) => {
                    if (index === 0 || index === 1) {
                      return (
                        <Typography
                          key={index}
                          style={{ height: 1, padding: 1 }}
                        >
                          <BadgeSecondary>
                            {truncate(badgeName, 10)}
                          </BadgeSecondary>
                        </Typography>
                      );
                    } else if (index > 2 && valueText.length - 1 === index) {
                      return (
                        <Typography
                          key={index}
                          style={{ height: 1, padding: 1 }}
                        >
                          <BadgeSecondary>{`+${
                            valueText.length - 2
                          }`}</BadgeSecondary>
                        </Typography>
                      );
                    }
                    return '';
                  })}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default RoleSelector;
