import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@octanner/prism-core';
import { StrengthsTabKeys } from './constants';
import { StrengthsRadioGroup } from './styles';
import { StrengthsRadioProps } from './types';
import { PermissionsTooltipWrapper as PermissionsWrapper } from '../../../common/PermissionTooltipWrapper';

const StrengthsRadio = ({ value, onChange, disabled }: StrengthsRadioProps) => {
  return (
    <StrengthsRadioGroup>
      <FormControl>
        <RadioGroup name="strength" value={value} onChange={onChange}>
          <PermissionsWrapper
            text="You do not have permission to edit this section"
            enabled={disabled}
            placement="right"
          >
            <FormControlLabel
              value={StrengthsTabKeys.Private}
              control={<Radio data-testid="user-setting-Private" />}
              label="Private"
              disabled={disabled}
            />
          </PermissionsWrapper>
          <PermissionsWrapper
            text="You do not have permission to edit this section"
            enabled={disabled}
            placement="right"
          >
            <FormControlLabel
              value={StrengthsTabKeys.Public}
              control={<Radio data-testid="user-setting-Public" />}
              label="Public"
              disabled={disabled}
            />
          </PermissionsWrapper>
        </RadioGroup>
      </FormControl>
    </StrengthsRadioGroup>
  );
};

export default StrengthsRadio;
