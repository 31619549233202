/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState, useEffect } from 'react';
import { Button, Typography } from '@octanner/prism-core';
import { useMutation, useQuery, useLazyQuery } from '@apollo/client';
import { SearchOptionCard } from '@octanner/prism-core/Search';
import {
  FlexContainer, PhoneFax, StateZipCode, TextFeildSmall,
  TextFeildLarge, SearchBox, CityTextFeild, Feild,
  FaxContainer, PhoneFaxContainer, StateSelect,
  Fax, CustomTextField, ActionButton, StateTextBoxContainer,
  Header, EditSectionButton
} from './style';
import { customerId } from '../../utilities/utility';
import {
  SEARCH_COUNTRY, GET_SALES_TERRITORY,
  UPDATE_CUSTOMER_CONTACT_INFO
} from './query';
import { GET_STATES } from '../../../utils/commonQueries';
import { ClientDetailsProps } from './types';
import { useTranslation } from 'react-i18next';
import validator from 'validator';
import { validLength } from '../../../utils/commonConstants';


const defaultAddress = {
  salesTerritory: '',
  country: '',
  addressLine1: '',
  addressLine2: '',
  addressLine3: '',
  city: '',
  state: {},
  postalCode: '',
  primaryContactName: '',
  emailAddress: '',
  faxNumber: '',
  phoneNumber: '',
};

const ContactDetails: React.FC<ClientDetailsProps> = ({
  customerAddress,
  refetchCustomerAddress,
  customerAddressError,
  stateListInfo,
  errorsDisplayNumber,
  onSave,
  setIsChangedValue,
  clientAddressWarning,
  onSapUpdateError,
  tabsEditModeStatus,
  setTabsEditModeStatus,
  setClientAddressWarning,
  setUpdateSapError
}) => {
  let statesOptions = stateListInfo || [];
  const { t } = useTranslation();
  const address =
    customerAddress?.customerContactInformationMapByCustomer
      ?.customerContactInfo || defaultAddress;

  const {
    data: salesTerritoryList,
    refetch: salesRefetch,
    loading: salesLoading,
  } = useQuery(GET_SALES_TERRITORY, { variables: { searchText: '' } });
  const salesTerritoryOptions = salesTerritoryList?.searchSalesTerritory?.nodes;

  const {
    data: countryList,
    refetch: countryRefetch,
    loading: countriesLoading,
  } = useQuery(SEARCH_COUNTRY, { variables: { clause: '' } });

  const countryOptions = countryList?.searchCountries?.content;

  const [getStates, { data: stateList }] = useLazyQuery(GET_STATES);

  const [updateCustomerContactInfo] = useMutation(UPDATE_CUSTOMER_CONTACT_INFO, {
    onCompleted: () => {
      refetchCustomerAddress().then(() => {
          onSave({ ...errorsDisplayNumber,ClientFinancialInfo : 0}),
          setEditAddress(false),
          setUpdateProgress(false),
          setFeildsDisabled(false),
          setIsChangedValue(false),
          onSapUpdateError(''),
          setClientAddressWarning(false);
          window.scrollTo(0, 0);
      });
    },
    onError: (error) => {
      setUpdateProgress(false);
      setFeildsDisabled(false);
      onSapUpdateError(error.message);
      window.scrollTo(0, 0);
    },
  });

  // states of component
  const [customerAddressInfo, setCustomerAddressInfo] = useState({
    ...address,
  });
  const [editAddress, setEditAddress] = useState(!!customerAddressError);
  const [updateProgress, setUpdateProgress] = useState(false);
  const [addressDetailsError, setAddressDetailsError] = useState<any>({});
  const [isEmailvalid, setIsEmailvalid] = useState(false);
  const [feildsDisabled, setFeildsDisabled] = useState(false);
  useEffect(()=>{
    if(!editAddress){
      onSave({ ...errorsDisplayNumber,ClientFinancialInfo : 0});
    }
  },[editAddress])

  useEffect(() => {
    if(tabsEditModeStatus.ClientFinancialInfo){
      saveClientDetails();
      setTabsEditModeStatus({...tabsEditModeStatus, ClientFinancialInfo : false});
    }
  },[tabsEditModeStatus.ClientFinancialInfo]);


  const requiredKeys = [
    'salesTerritoryErr',
    'countryErr',
    'addressLine1Err',
    'cityErr',
    'stateErr',
    'primaryContactNameErr',
  ];

  const {
    salesTerritory,
    country,
    addressLine1 = '',
    addressLine2 = '',
    addressLine3 = '',
    city = '',
    state = {},
    postalCode = '',
    primaryContactName = '',
    emailAddress = '',
    faxNumber = '',
    phoneNumber = '',
  } = customerAddressInfo;

  const {
    salesTerritoryErr = false,
    countryErr = false,
    addressLine1Err = false,
    cityErr = false,
    stateErr = false,
    primaryContactNameErr = false,
  } = addressDetailsError;

  useEffect(() => {
    const stateSelected = stateListInfo?.find(
      (s) => s?.stateCode === state?.stateCode,
    );
    if (stateSelected) {
      setCustomerAddressInfo({
        ...customerAddressInfo,
        state: stateSelected,
      });
    }
  }, [stateListInfo]);

  useEffect(() => {
    if(clientAddressWarning){
      let errorCnt = 0;
      getStates({ variables: { countryIso2Code: country?.iso2Code } }).then((stateListRefetched) => {
      for (const key in customerAddressInfo) {
        const errorKey = key + 'Err';
        if (!customerAddressInfo[key]) {
          const foundRequiredFieldEmpty =  requiredKeys.find((r) => r === errorKey);
          if(foundRequiredFieldEmpty){
          if(foundRequiredFieldEmpty==='stateErr' && stateListRefetched?.data?.getStatesByCountry?.length === 0){
              continue;
            }
            const errorsToCheckIfCountryIsEmpty = foundRequiredFieldEmpty!=='primaryContactNameErr'
                && foundRequiredFieldEmpty!=='salesTerritoryErr' && foundRequiredFieldEmpty!=='countryErr';
            if(country === '' && (errorsToCheckIfCountryIsEmpty)){
              continue;
            }
            errorCnt++;
          }
          addressDetailsError[errorKey] = true;
          setAddressDetailsError({ ...addressDetailsError });
        } else {
          delete addressDetailsError[errorKey];
          setAddressDetailsError({ ...addressDetailsError });
        }
      }
    const emailAddress = customerAddressInfo['emailAddress'];
    let emailvalid = false;

    if (emailAddress) {
      validator.isEmail(emailAddress) ? emailvalid = true : emailvalid = false;
      !emailvalid && errorCnt++;
      setIsEmailvalid(!emailvalid);
    }

    for(const value in customerAddressInfo){
      if(customerAddressInfo[value]?.length > validLength[value]){
        errorCnt++;
      }
    }
      onSave({ ...errorsDisplayNumber,ClientFinancialInfo : errorCnt});
    });
    }
  } ,[clientAddressWarning])


  const cancelClientDetails = () => {
    setIsChangedValue(false);
    setCustomerAddressInfo({...address})
    const savedState = stateListInfo?.find((s) => s?.stateCode === address?.state?.stateCode,);
    setCustomerAddressInfo({ ...address, state: savedState });
    getStates({ variables: { countryIso2Code: address?.country?.iso2Code } }).then(() => {
    stateList?.getStatesByCountry ? statesOptions = stateList?.getStatesByCountry : statesOptions = [];
    setEditAddress(false);
    setAddressDetailsError({});
    setIsEmailvalid(false);
    onSave({...errorsDisplayNumber,ClientFinancialInfo : 0 }); });
    onSapUpdateError('')
  }
  const errorCheckandSave = (stateListRefetched) => {
    let errorCnt = 0;
    for (const key in customerAddressInfo) {
      const errorKey = key + 'Err';
      if (!customerAddressInfo[key]) {
        const foundRequiredFieldEmpty =  requiredKeys.find((r) => r === errorKey);
        if(foundRequiredFieldEmpty){
          if(foundRequiredFieldEmpty==='stateErr' && stateListRefetched?.data?.getStatesByCountry?.length === 0 ){
            continue;
          }
          const errorsToCheckIfCountryIsEmpty = foundRequiredFieldEmpty!=='primaryContactNameErr'
          && foundRequiredFieldEmpty!=='salesTerritoryErr'
          && foundRequiredFieldEmpty!=='countryErr';
          if(country === '' && (errorsToCheckIfCountryIsEmpty)){
            continue;
         }
          errorCnt++;
        }
        addressDetailsError[errorKey] = true;
        setAddressDetailsError({ ...addressDetailsError });
      } else {
        delete addressDetailsError[errorKey];
        setAddressDetailsError({ ...addressDetailsError });
      }
    }

    const errorKeys = Object.keys(addressDetailsError) || [];

    if (stateList?.getStatesByCountry?.length === 0) {
      const stateErrIndex = errorKeys.findIndex((e) => e === 'stateErr');
      delete errorKeys[stateErrIndex];
    }

    const key = errorKeys?.find((e) => requiredKeys.find((r) => r === e));

    const emailAddress = customerAddressInfo['emailAddress'];
    let emailvalid = false;

    if (emailAddress) {
      validator.isEmail(emailAddress) ? emailvalid = true : emailvalid = false;
      !emailvalid && errorCnt++;
      setIsEmailvalid(!emailvalid);
    }
    else{
      emailvalid = true;
    }


    let lengthValid = true;
    for(const value in customerAddressInfo){
      if(customerAddressInfo[value]?.length > validLength[value]){
        errorCnt++;
        lengthValid = false;
      }
    }

    if (!key && emailvalid && lengthValid) {
      setUpdateProgress(true);
      setFeildsDisabled(true);
      updateCustomerContactInfo({
        variables: {
          input: {
            customerUuid: customerId,
            customerAddressAndContactInput : {
              salesTerritoryCode: salesTerritory?.salesTerritoryCode,
              countryIso2Code : country?.iso2Code,
              primaryContactName,
              addressLine1,
              addressLine2,
              addressLine3,
              city : city,
              stateCode : stateListRefetched?.data?.getStatesByCountry.length ?  state?.stateCode : null ,
              postalCode,
              phoneNumber,
              faxNumber,
              emailAddress,
            },
          }
        }
      })
    }
    onSave({ ...errorsDisplayNumber,ClientFinancialInfo : errorCnt});
  }

  const saveClientDetails = (e = {
    stopPropagation: () => {},
    preventDefault: () => {},
  }) => {
    e?.stopPropagation();
    e?.preventDefault();
    country ? (
    getStates({ variables: { countryIso2Code: country?.iso2Code }}).then((stateListRefetched) => {
      errorCheckandSave(stateListRefetched);
    })) : (errorCheckandSave({data:{getStatesByCountry:[]}}))
  };

  const getDetails = (heading, subHeading) => (
    <>
      <Typography color='textSecondary'>{heading}</Typography>
      <Typography>{subHeading}</Typography>
    </>
  );

  const handleInput = (value, key) => {
    if(key === 'city' || key === 'postalCode' || key === 'primaryContactName' || key === 'addressLine1'){
      const removeSpace = value?.trimStart();
      if(removeSpace?.length === 0 && value.length !== 0){
        return;
      }
    }
    setIsChangedValue(true);
    setUpdateSapError(false);
    delete customerAddressInfo[key];
    customerAddressInfo[key] = value || '';

    const errorKey = key + 'Err';
    delete addressDetailsError[errorKey];
    setAddressDetailsError((prevErrors) => ({
      ...prevErrors,
    }));
    let countryName = '';
    let savedState = ''

    switch (key) {
      case 'country':
         savedState = stateListInfo?.find(
          (s) => s?.stateCode === address?.state?.stateCode,
        );
        countryName = address?.country?.countryName || '';
        if (value) {
          getStates({ variables: { countryIso2Code: value.iso2Code } });
            setCustomerAddressInfo({
              ...customerAddressInfo,
              state: '',
              addressLine1: '',
              addressLine2: '',
              addressLine3: '',
              city: '',
              postalCode: '',
            });
            setAddressDetailsError({});
            onSave({ ...errorsDisplayNumber,ClientFinancialInfo : 0});
        }
        break;

      default:
        setCustomerAddressInfo({
          ...customerAddressInfo,
        });
        break;
    }
  };

  if (stateList?.getStatesByCountry) {
    statesOptions = stateList?.getStatesByCountry;
  }



  return (
    <>
      {!editAddress ? (
        <div>
          <FlexContainer>
            <Header variant="h3" test-id="address_header">
              {t('client_address_details')}
            </Header>
            <Button variant="text" onClick={() => setEditAddress(true)} test-id="edit_address_btn">
              {t('edit_section')}
            </Button>
          </FlexContainer>
          <Feild>
            {getDetails(
              t('sales_territory'),
              salesTerritory?.salesTerritoryDescription,
            )}
          </Feild>
          <Feild>{getDetails(t('country_1'), country?.countryName)}</Feild>
          <Feild>
            <Typography color='textSecondary'>{t('address')}</Typography>
            <Typography test-id = "address_line_1">{addressLine1} </Typography>
            <Typography test-id = "address_line_2">{addressLine2} </Typography>
            <Typography test-id = "address_line_3">{addressLine3} </Typography>
          </Feild>
          <Feild test-id="city">
            {getDetails(
              t('city_state_province'),
              `${city}, ${
                state?.stateName ? state?.stateName + ', ' : ''
              } ${postalCode}`,
            )}
          </Feild>
          <FlexContainer>
            <Typography variant="h4" test-id = "primary-name">
              {t('client_contact_information')}
            </Typography>
          </FlexContainer>
          <div>{getDetails(t('primary_contact_name_with_colon'), primaryContactName)}</div>
          <Feild test-id="email_id">{getDetails(t('email_1'), emailAddress)}</Feild>
          <PhoneFax>
            <div>{getDetails(t('phone_1'), phoneNumber)}</div>
            <Fax>{getDetails(t('fax_1'), faxNumber)}</Fax>
          </PhoneFax>
        </div>
      ) : (
        <form onSubmit={(e) => saveClientDetails(e)} noValidate>
          <FlexContainer>
            <Typography variant="h3">{t('client_address_details')}</Typography>
          </FlexContainer>

          <SearchBox
            test-id="sales_territory"
            placeholder={t('search_sales_territory')}
            options={salesTerritoryOptions}
            error={salesTerritoryErr}
            getOptionLabel={(option: any) =>
              option.salesTerritoryDescription || ''
            }
            onInputChange={(e, value) => salesRefetch({ searchText: value })}
            defaultValue={salesTerritory || []}
            multiple={false}
            onChange={(e, v) => handleInput(v, 'salesTerritory')}
            renderOption={(props, option: any) => (
              <SearchOptionCard
                {...props}
                title={option.salesTerritoryDescription}
              />
            )}
            helperText={salesTerritoryErr && t('required_error')}
            loading={salesLoading}
            disabled={feildsDisabled}
          />

          <SearchBox
            test-id="country"
            placeholder={t('search_country')}
            options={countryOptions}
            error={countryErr}
            defaultValue={country || []}
            getOptionLabel={(option: any) => option.countryName || ''}
            onInputChange={(e, value) => countryRefetch({ clause: value?.toLowerCase() })}
            multiple={false}
            onChange={(e, v) => {
              handleInput(v, 'country');
              addressDetailsError['stateErr'] = false;
            }}
            renderOption={(props, option: any) => (
              <SearchOptionCard {...props} title={option.countryName} />
            )}
            helperText={countryErr && t('required_error')}
            loading={countriesLoading}
            disabled={feildsDisabled}
          />

          {country && (
            <>
              {/** Address Block */}
              <CustomTextField
                label={t('address_1')}
                value={addressLine1}
                displayCount
                onChange={(e) => handleInput(e.target.value, 'addressLine1')}
                width="592px"
                error={addressLine1Err}
                maxLength={35}
                helperText = {addressLine1Err ? t('required_error') : addressLine1?.length > 35 &&  t('many_characters') }
                disabled={feildsDisabled}
                test-id="address_line_1_input"
              />
              <br />
              <CustomTextField
                label={t('address_2')}
                value={addressLine2}
                onChange={(e) => handleInput(e.target.value, 'addressLine2')}
                helperText = {addressLine2?.length > 35 ?  t('many_characters') : t('optional')}
                width="592px"
                displayCount
                maxLength={35}
                disabled={feildsDisabled}
                test-id="address_line_2_input"
              />
               <br />
              <CustomTextField
                label={t('address_3')}
                value={addressLine3}
                onChange={(e) => handleInput(e.target.value, 'addressLine3')}
                helperText = {addressLine3?.length > 35 ?  t('many_characters') : t('optional')}
                width="592px"
                displayCount
                maxLength={35}
                disabled={feildsDisabled}
                test-id="address_line_3_input"
              />
              <br />
              <CityTextFeild
                label={t('city')}
                value={city}
                onChange={(e) => handleInput(e.target.value, 'city')}
                error={cityErr}
                helperText={cityErr ? t('required_error') : city?.length > 35 &&  t('many_characters') }
                displayCount
                maxLength={35}
                disabled={feildsDisabled}
                test-id="city_input"
              />
              <br />
              <StateZipCode>
                {statesOptions?.length > 0 && (
                  <StateSelect
                    id="states"
                    options={statesOptions}
                    getOptionLabel={(option: any) => option.stateName || ''}
                    defaultValue={state || {}}
                    onChange={(e, v) => handleInput(v, 'state')}
                    renderInput={(props) => (
                      <StateTextBoxContainer
                        {...props}
                        label={t('state_province_region')}
                        autoComplete="off"
                        error={stateErr}
                        helperText={stateErr && t('required_error')}
                      />
                    )}
                    renderOption={(props, option: any) => (
                      <SearchOptionCard
                        {...props}
                        title={option?.stateName || ''}
                      />
                    )}
                    disabled={feildsDisabled}
                  />
                )}
                <CustomTextField
                  label={t('postal_zip_code')}
                  onChange={(e) => handleInput(e.target.value, 'postalCode')}
                  width="212px"
                  value={postalCode}
                  helperText={ postalCode?.length > 10 && t('many_characters') || t('optional')}
                  displayCount
                  maxLength={10}
                  disabled={feildsDisabled}
                />
              </StateZipCode>
            </>
          )}

          <>
            <Typography variant="h4" style={{ marginTop: '16px' }}>
              {t('client_contact_information')}
            </Typography>
            <TextFeildSmall
              label={t('primary_contact_name')}
              displayCount
              onChange={(e) =>
                handleInput(e.target.value, 'primaryContactName')
              }
              maxLength={35}
              value={primaryContactName}
              error={primaryContactNameErr}
              helperText={(primaryContactNameErr)  ?  t('required_error') : primaryContactName?.length>35 && t('many_characters')}
              disabled={feildsDisabled}
            />
            <br />
            <TextFeildLarge
              label={t('email')}
              onChange={(e) => handleInput(e.target.value, 'emailAddress')}
              value={emailAddress}
              error={(isEmailvalid)}
              type="email"
              helperText={
                 isEmailvalid ?
                t('email_error') : emailAddress?.length>130 && t('many_characters') || t('optional')
              }
              displayCount
              maxLength={130}
              disabled={feildsDisabled}
            />
            <PhoneFaxContainer>
              <div>
                <CustomTextField
                  label={t('phone')}
                  displayCount
                  onChange={(e) => handleInput(e.target.value, 'phoneNumber')}
                  maxLength={16}
                  value={phoneNumber}
                  error={phoneNumber?.length > validLength?.phoneNumber}
                  type="phone"
                  helperText={phoneNumber?.length>validLength?.phoneNumber ? t('many_characters') : t('optional')}
                  width="288px"
                  disabled={feildsDisabled}
                />
              </div>
              <FaxContainer>
                <CustomTextField
                  id="fax"
                  label={t('fax')}
                  onChange={(e) => handleInput(e.target.value, 'faxNumber')}
                  value={faxNumber}
                  width="288px"
                  displayCount
                  maxLength={31}
                  helperText = {faxNumber?.length > 31 ?  t('many_characters') : t('optional')}
                  disabled={feildsDisabled}
                />

              </FaxContainer>
            </PhoneFaxContainer>
          </>
          <ActionButton>
            <Button variant="contained" loading={updateProgress} type="submit" adminColor>
              {t('save_contact_details')}
            </Button>
            &nbsp;&nbsp;
            {!customerAddressError && (
              <Button
                variant="contained"
                color="secondary"
                onClick={() => cancelClientDetails()}
              >
                {t('cancel')}
              </Button>
            )}
          </ActionButton>
        </form>
      )}
    </>
  );
};

export default ContactDetails;
