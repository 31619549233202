import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { List, Typography, VerticalTab } from '@octanner/prism-core';
import React, { useContext } from 'react';
import { AppContext } from '../utils/context/AppContext';
import { CustomerContext } from '../utils/context/CustomerContext';
import {
  AppMenuItem,
  isExternalLinkItem,
  isInternalLinkItem,
  isParentLinkItem,
} from './types';
import { useHistory, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import Colors from '../common/constants/Colors';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(3),
      width: 256,
    },
    nested: {
      paddingLeft: theme.spacing(3),
    },
    header: {
      fontWeight: 800,
      fontSize: 20,
      paddingLeft: 24,
    },
    headerSTP: {
      fontSize: 14,
      paddingLeft: 24,
      paddingBottom: 11,
      paddingTop: 8,
      width: '100%',
      borderBottom: `1px solid ${Colors.gray200}`,
    },
    alignmentMain: {
      display: 'flex',
      justifyContent: 'start',
      alignItems: 'start',
      flexDirection: 'column',
    },
    globalMenuItem: {
      paddingTop: 4,
      paddingBottom: 4,
      paddingRight: theme.spacing(1),
      paddingLeft: theme.spacing(1),
      '& .MuiAccordion-root': {
        '& .MuiAccordionSummary-root': {
          '&:active': {
            backgroundColor: Colors.gray200,
          },
          padding: 0,
          '& .MuiAccordionSummary-content': {
            margin: 0,
            paddingLeft: 16,
          },
        },
      },
    },
    globalMenuItemDisabled: {
      pointerEvents: 'none',
      '& .MuiAccordionSummary-root': {
        '& .MuiAccordionSummary-content': {
          color: Colors.gray600,
        },
      },
    },
    globalMenuItemChild: {
      paddingTop: 4,
      paddingBottom: 4,
    },
    clientNav: {
      paddingTop: 0,
    },
    externalLinkItem: {
      '& .MuiAccordionSummary-content': {
        color: Colors.blue,
      },
    },
    globalMenuItemWithBorder: {
      borderBottom: `1px solid ${Colors.gray200}`,
    },
  })
);

interface Props {
  menuItems: AppMenuItem[];
  addCustomer: boolean;
  title: string;
  stpNumber?: string;
  disabled?: boolean;
}

const getItemPath = (item: AppMenuItem, customerId?: string) => {
  if (!isInternalLinkItem(item)) return '';
  return customerId ? `/customer/${customerId}${item.to}` : item.to;
};

const MenuItem = ({ addCustomer, item, expanded, onChange, disabled }) => {
  const classes = useStyles();
  const context = useContext(AppContext);
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const {
    customer: { id },
    isDefault,
  } = useContext(CustomerContext);
  const customerId = isDefault ? context?.decodedToken?.cus : id;
  if (item.authorizer && !item.authorizer(context, customerId)) {
    const isImpersonation = location.pathname.indexOf('/impersonate') > -1;
    if (!isImpersonation) {
      return null;
    }
  }
  const path = getItemPath(item, addCustomer ? customerId : undefined);

  return (
    <div
      data-testid={`navigation:${item.dataTest}`}
      className={`${classes.globalMenuItem} ${
        disabled ? classes.globalMenuItemDisabled : ''
      } ${item.dataTest === 'store' ? classes.globalMenuItemWithBorder : ''}`}
    >
      <VerticalTab
        onClick={() =>
          isParentLinkItem(item)
            ? null
            : isExternalLinkItem(item)
            ? window.open(item.href)
            : history.push(path)
        }
        expanded={expanded}
        onChange={onChange}
        selected={path ? location.pathname.indexOf(path) > -1 : false}
        label={t(item.translationId || '', item.text)}
        adminColor
        children={
          isParentLinkItem(item) &&
          item.children.map((item) => {
            if (item.visible === false) {
              return null;
            }

            if (item.authorizer && !item.authorizer(context, customerId)) {
              const isImpersonation =
                location.pathname.indexOf('/impersonate') > -1;
              if (!isImpersonation) {
                return null;
              }
            }
            const childPath = getItemPath(
              item,
              addCustomer ? customerId : undefined
            );
            if (isParentLinkItem(item)) {
              return (
                <MenuItem
                  key={item.dataTest}
                  item={item}
                  addCustomer={addCustomer}
                  onChange={onChange}
                  expanded={undefined}
                  disabled={disabled}
                />
              );
            }
            return (
              <div
                data-testid={`navigation:${item.dataTest}`}
                key={item.dataTest}
                className={`${classes.globalMenuItemChild} ${
                  isExternalLinkItem(item) ? classes.externalLinkItem : ''
                }`}
              >
                <VerticalTab
                  onClick={() =>
                    isExternalLinkItem(item)
                      ? window.open(item.href, item?.target)
                      : history.push(childPath)
                  }
                  selected={location.pathname === childPath}
                  label={t(item.translationId || '', item.text)}
                  adminColor
                />
              </div>
            );
          })
        }
      />
    </div>
  );
};

export default function AppMenu({
  menuItems,
  addCustomer,
  title,
  stpNumber,
  disabled,
}: Props) {
  const classes = useStyles();
  const location = useLocation();
  const currentSelectedItemIndex = menuItems.findIndex((it) => {
    const childPaths = isParentLinkItem(it)
      ? it.children.map((childItem) =>
          isInternalLinkItem(childItem) ? childItem.to : ''
        )
      : [];
    return (
      childPaths.findIndex((childPathItem) => {
        const locationPath = location.pathname.split('/').at(-1);
        return `/${locationPath}` === childPathItem;
      }) > -1
    );
  });

  const initialSelectedItem =
    currentSelectedItemIndex > -1
      ? menuItems[currentSelectedItemIndex].dataTest
      : '';
  const [expanded, setExpanded] = React.useState(initialSelectedItem);

  const handleChange = (panel: any) => (event, newExpanded: boolean) => {
    if (newExpanded) {
      if (expanded.indexOf(panel) === -1) {
        setExpanded(expanded + ',' + panel);
      }
    } else {
      if (expanded.indexOf(panel) > -1) {
        let ret = expanded.replace(panel, '');
        setExpanded(ret);
      }
    }
  };

  return (
    <div className={classes.alignmentMain}>
      {title !== 'default' ? (
        stpNumber ? (
          <>
            <Typography variant="h2" className={classes.header}>
              {title}
            </Typography>
            <div className={classes.headerSTP}>{stpNumber}</div>
          </>
        ) : (
          <Typography variant="h2" className={classes.header}>
            {title}
          </Typography>
        )
      ) : (
        ''
      )}
      <List
        component="nav"
        className={`${classes.root} ${stpNumber ? classes.clientNav : ''}`}
      >
        {menuItems.map((item) => {
          if (item.visible === false) return null;
          else
            return (
              <MenuItem
                key={item.dataTest}
                item={item}
                addCustomer={addCustomer}
                onChange={handleChange(item.dataTest)}
                expanded={
                  expanded.indexOf(item.dataTest) > -1 ? true : undefined
                }
                disabled={disabled}
              />
            );
        })}
      </List>
    </div>
  );
}
