import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@octanner/prism-core';
import { HorizontalLine } from '../../../../styles';
import { ColorBlack } from '../../styles';
import { SectionProps } from '../../types';
import { FeedsSubtitle, FeedsTitle } from './styles';
import { useState } from 'react';
import { SocialFeedSearchView } from '../../../../customer/models/Customer';
import { useUpdateFeedType } from '../../hooks/feeds/feeds';

const FeedSection = ({ data, setError }: SectionProps) => {
  const [feedValue, setFeedValue] = useState(data.viewType);
  const [updateFeedType] = useUpdateFeedType(data.customerUuid);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFeedValue(event.target.value as SocialFeedSearchView);
    updateFeedType({
      variables: {
        input: {
          customerUuid: data.customerUuid,
          defaultViewType: event.target.value as SocialFeedSearchView,
        },
      },
    });
  };

  return (
    <div>
      <FeedsTitle variant="h2" data-testid="social-wall:feeds">
        Feeds
      </FeedsTitle>
      <FeedsSubtitle>
        Configure what types of feeds are displayed on the social wall.
      </FeedsSubtitle>
      <div>
        <HorizontalLine />
        <ColorBlack>Feed Content</ColorBlack>
        <FormControl component="fieldset">
          <RadioGroup value={feedValue} onChange={handleChange}>
            <FormControlLabel
              value={SocialFeedSearchView.CORPORATE}
              control={<Radio />}
              label="Corporate (Entire Company)"
              disabled={!data.enabled}
              data-testid="social-wall:feeds:corporate"
            />
            <FormControlLabel
              value={SocialFeedSearchView.BUSINESS_UNIT}
              control={<Radio />}
              label="Department (Business Unit)"
              disabled={!data.enabled}
              data-testid="social-wall:feeds:business-unit"
            />
          </RadioGroup>
        </FormControl>
      </div>
      <HorizontalLine />
    </div>
  );
};

export default FeedSection;
