import React, { useState } from 'react';
import { FlexyBetweenGrid, StartDiv } from '../styles';
import styled from '@emotion/styled';
import ApproverSearch from './ApproverSearch';
import BusinessUnitSearch from './BusinessUnitSearch';
import { Button } from '@octanner/prism-core';
import { DefinedApproversForBusinessUnits } from './types';
import { IdentityPresenter } from '../types';
import { BusinessUnit } from '@octanner/prism-core/Header/interfaces';

const AddApproverRow = styled.div`
  margin: 10px 0;
`;

const RemoveApproverContainer = styled.div`
  display: flex;
  padding: 0 10px;
`;

interface AddApproversInterface {
  additionalApprovers: DefinedApproversForBusinessUnits[];
  setAdditionalApprovers: (approver: IdentityPresenter, id: string) => void;
  setBuForApprover: (businessUnit: BusinessUnit, id: string) => void;
  addApproverRow: () => void;
  removeApproverRow: (id: string) => void;
}

const AddApprover: React.FC<AddApproversInterface> = ({
  additionalApprovers,
  setAdditionalApprovers,
  setBuForApprover,
  addApproverRow,
  removeApproverRow,
}) => {
  const showAddApproverBtn = additionalApprovers.every(
    (item) => item.approver && item.businessUnit,
  );

  const extractedBuUuids = additionalApprovers?.map(
    (item) => item.businessUnit?.buUuid,
  );

  const [businessUnitUuids, setBusinessUnitUuids] = useState<string[]>(
    extractedBuUuids || [],
  );

  return (
    <div>
      {additionalApprovers.map((item) => (
        <AddApproverRow
          data-testid="AddApprover-row"
          key={item.definedApproverUuid}
        >
          <StartDiv container>
            <FlexyBetweenGrid item xs={6}>
              <ApproverSearch
                approver={item.approver}
                setApprovers={(approvers) =>
                  setAdditionalApprovers(approvers, item.definedApproverUuid)
                }
                loadingApprovers={false}
              />
            </FlexyBetweenGrid>
            <FlexyBetweenGrid item xs={6}>
              <BusinessUnitSearch
                businessUnit={item.businessUnit}
                setBusinessUnit={(businessUnit) =>
                  setBuForApprover(businessUnit, item.definedApproverUuid)
                }
                businessUnitUuids={businessUnitUuids}
                setBusinessUnitUuids={(businessUnitUuid) =>
                  setBusinessUnitUuids((prev) => [...prev, businessUnitUuid])
                }
              />
              <RemoveApproverContainer>
                <Button
                  variant="text"
                  onClick={() => removeApproverRow(item.definedApproverUuid)}
                >
                  Remove
                </Button>
              </RemoveApproverContainer>
            </FlexyBetweenGrid>
          </StartDiv>
        </AddApproverRow>
      ))}
      {showAddApproverBtn && (
        <Button variant="text" onClick={addApproverRow}>
          Add Approver
        </Button>
      )}
    </div>
  );
};

export default AddApprover;
