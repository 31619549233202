export const paginate = <T, P>(
  data: T[],
  filter: (item: T) => boolean,
  sort: (a: T, b: T) => number,
  pageNumber: number,
  pageSize: number,
  toPagedData: (arr: T[], total: number, totalPages: number) => P
): P => {
  const start = (pageNumber - 1) * pageSize;
  const end = pageNumber * pageSize;
  const filteredData = data.filter((item) => filter(item)).sort(sort);
  const total = filteredData.length;
  const totalPages = Math.ceil(total / pageSize);
  const pagedData = filteredData.slice(start, end);
  return toPagedData(pagedData, total, totalPages);
};
