import { gql } from '@apollo/client';
import {
  ADMIN_ROLE_FRAGMENT,
  PERMISSION_FRAGMENT,
  ROLE_FRAGMENT,
  ROLE_PERMISSIONS_FRAGMENT,
  VALIDATION_ERROR_FRAGMENT,
} from '../../graphql/gql';

const PERMISSION_GROUP_FRAGMENT = gql`
  fragment PermissionGroupFragment on RdPermissionGroup {
    uuid
    name
    description
    readPermission {
      ...PermissionFragment
    }
    writePermission {
      ...PermissionFragment
    }
  }

  ${PERMISSION_FRAGMENT}
`;

const SUB_CATEGORY_FRAGMENT = gql`
  fragment SubCategoryFragment on RdSubCategory {
    uuid
    name
    permissionGroups {
      ...PermissionGroupFragment
    }
  }

  ${PERMISSION_GROUP_FRAGMENT}
`;

const CATEGORY_FRAGMENT = gql`
  fragment CategoryFragment on RdCategory {
    uuid
    name
    description
    permissionGroupTotal
    subCategories {
      ...SubCategoryFragment
    }
  }

  ${SUB_CATEGORY_FRAGMENT}
`;

export const SEARCH_ADMIN_ROLES = gql`
  query searchAdminRoles(
    $searchTerms: [String!]!
    $page: NonNegativeInt!
    $pageSize: PositiveInt!
    $sortBy: RdAdminRoleSortInput
    $sortDirection: RdSortDirectionInput
  ) {
    rdFindPagedAdminRoles(
      pagedRequest: {
        searchTerms: $searchTerms
        page: $page
        pageSize: $pageSize
        sortBy: $sortBy
        sortDirection: $sortDirection
      }
    ) {
      content {
        ...AdminRoleFragment
      }
      total
      totalPages
      last
    }
  }

  ${ADMIN_ROLE_FRAGMENT}
`;

export const GET_ROLE_BY_UUID = gql`
  query findRoleByUuid($uuid: UUID!) {
    rdFindRoleByUuid(uuid: $uuid) {
      ...RolePermissionsFragment
    }
  }

  ${ROLE_PERMISSIONS_FRAGMENT}
`;

export const GET_CATEGORIES = gql`
  query RdCategories($page: Int!, $size: Int!) {
    rdCategories(page: $page, size: $size) {
      content {
        ...CategoryFragment
      }
    }
  }

  ${CATEGORY_FRAGMENT}
`;

export const CREATE_ADMIN_ROLE = gql`
  mutation RdCreateRole(
    $name: String!
    $description: String
    $accessType: RdAccessType
    $permissionUuids: [UUID!]
  ) {
    rdCreateRole(
      request: {
        name: $name
        description: $description
        accessType: $accessType
        permissionUuids: $permissionUuids
      }
    ) {
      ...RdRoleFragment
    }
  }

  ${ROLE_PERMISSIONS_FRAGMENT}
`;

export const UPDATE_ADMIN_ROLE = gql`
  mutation RdSaveRole(
    $roleUuid: UUID!
    $name: String!
    $description: String!
    $permissionUuids: [UUID!]!
  ) {
    rdUpdateRole(
      request: { uuid: $roleUuid, name: $name, description: $description }
    ) {
      ...RoleFragment
    }
    rdUpdateAdminRoleWithPermissions(
      input: { roleUuid: $roleUuid, permissionUuids: $permissionUuids }
    ) {
      ... on RdUpdateAdminRoleWithPermissionsFailure {
        rdValidationErrors {
          ...ValidationErrorFragment
        }
      }
      ... on RdUpdateAdminRoleWithPermissionsSuccess {
        role {
          ...RolePermissionsFragment
        }
      }
    }
  }

  ${VALIDATION_ERROR_FRAGMENT}
  ${ROLE_FRAGMENT}
  ${ROLE_PERMISSIONS_FRAGMENT}
`;

export const DELETE_ADMIN_ROLE = gql`
  mutation RdDeleteRole($roleUuid: UUID!) {
    rdDeleteRole(roleUuid: $roleUuid) {
      ...RolePermissionsFragment
    }
  }

  ${ROLE_PERMISSIONS_FRAGMENT}
`;
