import {
  createGenerateClassName,
  StylesProvider as Provider,
} from '@mui/styles';
import React from 'react';
import { useStyles } from '../pages/vibes/styles';

const VibesStylesProvider: React.FC = ({ children }) => {
  const classes = useStyles();
  return (
    <div id="jss-vibes-insertion-point">
      <Provider
        generateClassName={createGenerateClassName({
          productionPrefix: 'VIBES',
        })}
      >
        <div className={classes.conatiner}>{children}</div>
      </Provider>
    </div>
  );
};

export default VibesStylesProvider;
