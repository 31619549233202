import { useMutation } from '@apollo/client';
import {
  UpdateFeedViewTypeInput,
  UpdateFeedViewTypeResponse,
} from '../../models/socialWall';
import { GET_CUSTOMER } from '../../../../customer/graphql/customer';
import { UPDATE_FEED_TYPE } from '../../graphql/feeds/feeds';

export const useUpdateFeedType = (customerUuid: string) =>
  useMutation<UpdateFeedViewTypeResponse, { input: UpdateFeedViewTypeInput }>(
    UPDATE_FEED_TYPE,
    {
      refetchQueries: [
        {
          query: GET_CUSTOMER,
          variables: { id: customerUuid, customerId: customerUuid },
        },
      ],
    }
  );
