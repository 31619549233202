import { Button, Modal } from '@octanner/prism-core';
import { CenteredText } from '../adminRoles/components/styles';
import { BaseModalProps } from '../types';

interface ModalProps extends BaseModalProps {
  title: string;
  message: string;
  confirmText: string;
  onCancel?: () => void;
}

const ConfirmModal = ({
  title,
  message,
  confirmText,
  open,
  setOpen,
  onConfirm,
  onCancel = () => setOpen(false),
  adminColor = true,
  color = 'primary',
}: ModalProps) => {
  return (
    <Modal
      actions={
        <>
          <Button onClick={onCancel} color="secondary">
            Cancel
          </Button>
          <Button color={color} adminColor={adminColor} onClick={onConfirm}>
            {confirmText}
          </Button>
        </>
      }
      id="simple-Modal"
      onClose={onCancel}
      open={open}
      title={title}
    >
      <div>
        <CenteredText>{message}</CenteredText>
      </div>
    </Modal>
  );
};

export default ConfirmModal;
