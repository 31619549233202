import { Button } from '@octanner/prism-core';
import { BottomBar, SaveBar } from '../../../programs/modifyProgram/styles';
import { SecondaryButton, TopLayerDiv } from '../styles';

interface PageWrapperProps {
  type: 'add' | 'duplicate' | 'edit';
  context: string;
  children: JSX.Element;
  saveAction: () => void;
  cancelAction: () => void;
}

const PageWrapper = ({
  children,
  type,
  context,
  saveAction,
  cancelAction,
}: PageWrapperProps) => {
  return (
    <TopLayerDiv>
      {children}
      <BottomBar square variant="outlined">
        <SaveBar>
          <SecondaryButton
            data-test={`${context}:${type}:save`}
            adminColor
            onClick={saveAction}
          >
            Save
          </SecondaryButton>
          <Button
            data-test={`${context}:${type}:cancel`}
            color="secondary"
            onClick={cancelAction}
          >
            Cancel
          </Button>
        </SaveBar>
      </BottomBar>
    </TopLayerDiv>
  );
};

export default PageWrapper;
