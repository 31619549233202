import styled from '@emotion/styled';
import { Typography } from '@octanner/prism-core';

export const FlexBetweenDiv = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  align-items: center;
`;
export const LinedBorderDiv = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
  padding-top: 16px;
  padding-bottom: 16px;
`;
export const FlexDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BoldTypo = styled(Typography)`
  font-weight: bold;
  margin-left: 8px;
`;
