import Box from '@mui/material/Box'
import CircularProgress from '@octanner/prism-core/CircularProgress'
import React from 'react'

const Loading = () => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '25vh',
    }}
    data-testid="loading-element"
  >
    <CircularProgress />
  </Box>
)

export default Loading
