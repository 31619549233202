import { gql, useMutation } from '@apollo/client';

interface RecognitionProgramInput {
  id: string;
  promoBody: string;
  promoHeader: string;
  displayOnCCHome: boolean;
}

export const UPDATE_PROGRAM_CONFIGURATION = gql`
  mutation updateProgramConfiguration($input: RecognitionProgramInput!) {
    updateProgramConfiguration(input: $input) {
      id
      promoBody
      promoHeader
      displayOnCCHome
    }
  }
`;

export const useUpdateProgramConfiguration = () => {
  const [saveProgramConfiguration, { data, loading }] = useMutation(
    UPDATE_PROGRAM_CONFIGURATION,
  );

  return {
    data,
    loading,
    saveProgramConfiguration: (program: RecognitionProgramInput) =>
      saveProgramConfiguration({
        variables: {
          input: {
            id: program.id,
            promoBody: program.promoBody,
            promoHeader: program.promoHeader,
            displayOnCCHome: program.displayOnCCHome,
          },
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore // ts says strings aren't valid here but they are
        refetchQueries: 'GetAwardLists',
      }),
  } as const;
};
