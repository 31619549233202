import { Tooltip } from '@octanner/prism-core';
import { ReactNode } from 'react';
export interface PermissionsProps {
  text: string;
  enabled: boolean;
  placement:
    | 'top'
    | 'right'
    | 'bottom'
    | 'left'
    | 'bottom-end'
    | 'bottom-start'
    | 'left-end'
    | 'left-start'
    | 'right-end'
    | 'right-start'
    | 'top-end'
    | 'top-start';
  children: ReactNode;
}

export const PermissionsTooltipWrapper: React.FC<PermissionsProps> = ({
  text,
  enabled,
  placement,
  children,
}) => (
  <>
    {enabled ? (
      <Tooltip title={text} placement={placement}>
        <span>{children}</span>
      </Tooltip>
    ) : (
      children
    )}
  </>
);
