import React from 'react';
import { Heading, BottomBorder } from '../styles';
import { FormGroup, FormControlLabel, Switch } from '@octanner/prism-core';
import { EmailCommunicationsTournament } from '../types';
interface AwardCommunications {
  emailCommunications: EmailCommunicationsTournament;
  handleEmailCommunicationsChange: (target: string, value: boolean) => void;
}

const AwardCommunications: React.FC<AwardCommunications> = ({
  emailCommunications,
  handleEmailCommunicationsChange,
}) => {
  return (
    <div>
      <Heading variant="h3" marginTopIncrease>
        Email Communications
      </Heading>
      <BottomBorder />
      <FormGroup row sx={{ marginTop: '16px', gap: '15px' }}>
        {Object.keys(emailCommunications).map((key) => (
          <FormControlLabel
            key={key}
            control={
              <Switch
                name={key}
                checked={emailCommunications[key].checked}
                onChange={(event) =>
                  handleEmailCommunicationsChange(
                    event.target.name,
                    event.target.checked,
                  )
                }
                data-testid={`AwardCommunications:switch ${key}`}
              />
            }
            label={emailCommunications[key].label}
          />
        ))}
      </FormGroup>
    </div>
  );
};

export default AwardCommunications;
