import { gql } from '@apollo/client';

export const SEARCH_COUNTRY = gql`
  query SearchCountries($clause: String!) {
    searchCountries(clause: $clause) {
      content {
        iso2Code
        countryName
        countryNum
      }
    }
  }
`;

export const GET_SALES_TERRITORY = gql`
  query searchSalesTerritory($searchText: String!) {
    searchSalesTerritory(searchText: $searchText) {
      nodes {
        salesTerritoryCode
        salesTerritoryDescription
      }
      pageInfo {
        pageNumber
        totalPages
      }
    }
  }
`;

export const GET_CUSTOMER_INFO = gql`
  query customerContactInformationMapByCustomer($customerId: UUID!) {
    customerContactInformationMapByCustomer(customerId: $customerId) {
      customerContactInfo {
        salesTerritory {
          salesTerritoryCode
          salesTerritoryDescription
        }
        country {
          iso2Code
          countryName
        }
        addressLine1
        addressLine2
        addressLine3
        city
        state {
          stateCode
        }
        postalCode
        primaryContactName
        phoneNumber
        faxNumber
        emailAddress
      }
    }
  }
`;


export const GET_INVOICE_CUSTOMER_INFO = gql`
  query CustomerInvoiceConfigurationMapByCustomer($customerId: UUID!) {
    customerInvoiceConfigurationMapByCustomer(customerId: $customerId) {
      customerInvoiceConfiguration {
        paymentTerm {
          paymentTermCode
          paymentTermDescription
          isDefault
        }
        invoiceMedium {
          invoiceMediumCode
          invoiceMediumDescription
          isDefault
        }
        billingSchedule {
          billingScheduleCode
          billingScheduleDescription
          isDefault
        }
        billingType {
          billingTypeCode
          billingTypeDescription
          isDefault
        }
      }
    }
  }
`;

export const GET_INVOICE_CONFIGURATION = gql`
  query invoiceConfiguration {
    allCoreInvoiceMediums {
      coreInvoiceMediums {
        nodes {
          isDefault
          invoiceMediumCode
          invoiceMediumDescription
        }
      }
      defaultCoreInvoiceMedium {
        invoiceMediumDescription
        invoiceMediumCode
        isDefault
      }
    }
    allCoreBillingSchedules {
      coreBillingSchedule {
        nodes {
          isDefault
          billingScheduleCode
          billingScheduleDescription
        }
      }
      defaultCoreBillingSchedule {
        billingScheduleCode
        billingScheduleDescription
        isDefault
      }
    }
    allCoreBillingTypes {
      coreBillingTypes {
        nodes {
          billingTypeCode
          isDefault
          billingTypeDescription
        }
      }
      defaultCoreBillingType {
        billingTypeCode
        billingTypeDescription
        isDefault
      }
    }
  }
`;

export const UPDATE_CUSTOMER_CONTACT_INFO = gql`
mutation EditCustomerV2($input: EditCustomerInputV2!) {
  editCustomerV2(input: $input) {
    customerUuid
  }
}
`;

