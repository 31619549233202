import {
  PostDataNames,
  PostDataTerms,
  SocialCardConfigByCustomerUuid,
} from './models/socialWall';

export const MILESTONE_ROWS = ['Client Number', 'Program Name', 'Program ID'];

export const CREATE_POSTS = (
  // displayBusinessUnit: boolean,
  displayAwardLevel: boolean,
  socialCardConfig?: SocialCardConfigByCustomerUuid
) => [
  {
    name: PostDataNames.BUSINESSUNIT,
    enabled: socialCardConfig?.displayBusinessUnit || false,
  },
  {
    name: PostDataNames.RECOGNITIONAWARDLEVEL,
    enabled: displayAwardLevel,
    options: [
      {
        term: PostDataTerms.BANNER,
        selected:
          socialCardConfig?.recognitionAwardLevel?.bannerCheckBox || false,
      },
      {
        term: PostDataTerms.TITLE,
        selected:
          socialCardConfig?.recognitionAwardLevel?.titleCheckBox || false,
      },
    ],
  },
  {
    name: PostDataNames.MILESTONEAWARD,
    enabled: socialCardConfig?.displayMilestoneAward || false,
  },
  {
    name: PostDataNames.RECOGNITIONMESSAGE,
    enabled: socialCardConfig?.displayRecognitionMessage || false,
  },
];
// TODO: actually get the program information
export const PROGRAMS = [
  {
    name: 'Safety Excellence',
    enabled: true,
    options: [
      { term: 'e-card', selected: true },
      { term: 'gold', selected: true },
      { term: 'silver', selected: true },
      { term: 'wow!', selected: true },
    ],
  },
  {
    name: 'Appreciate Great',
    enabled: true,
    options: [
      { term: 'e-card', selected: true },
      { term: 'gold', selected: true },
      { term: 'silver', selected: true },
      { term: 'wow!', selected: true },
    ],
  },
  {
    name: 'Wellbeing',
    enabled: true,
    options: [
      { term: 'e-card', selected: true },
      { term: 'gold', selected: true },
      { term: 'silver', selected: true },
      { term: 'wow!', selected: true },
    ],
  },
];
