import { useMutation, MutationUpdaterFn, useQuery } from '@apollo/client';
import {
  GetCustomerInput,
  UpdateCustomerInput,
  UpdateCustomerResponse,
  CoupleCustomerAndCoreFeatureInput,
  CoupleCustomerAndCoreFeatureResponse,
  CustomerWithFeatures,
  UpdateWelcomeConfigResponse,
  UpdateWelcomeConfigInput,
  UpdateWelcomeResponse,
  UpdateWelcomeInput,
} from '../models/Customer';
import {
  GET_CUSTOMER,
  UPDATE_CUSTOMER,
  MUTATE_WELCOME_MESSAGE_CONFIG,
  COUPLE_CUSTOMER_AND_CORE_FEATURE,
  GET_WELCOME_MESSAGE,
  MUTATE_WELCOME_MESSAGE,
} from '../graphql/customer';

export const useGetCustomer = (
  customerId: string,
  personalFeedOverride: boolean
) =>
  useQuery<CustomerWithFeatures, GetCustomerInput>(GET_CUSTOMER, {
    variables: {
      id: customerId,
      customerId,
      social: { personalFeedOverride: personalFeedOverride },
    },
    skip: !customerId,
  });

export const useUpdateCustomer = () =>
  useMutation<UpdateCustomerResponse, UpdateCustomerInput>(UPDATE_CUSTOMER);

export const useUpdateMessageConfig = (
  customerUuid: string,
  enabled: boolean
) =>
  useMutation<UpdateWelcomeConfigResponse, UpdateWelcomeConfigInput>(
    MUTATE_WELCOME_MESSAGE_CONFIG,
    {
      variables: {
        customerUuid,
        enabled,
      },
    }
  );

export const useUpdateMessage = (customerUuid: string, locale: string) =>
  useMutation<UpdateWelcomeResponse, UpdateWelcomeInput>(
    MUTATE_WELCOME_MESSAGE,
    {
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GET_WELCOME_MESSAGE,
          variables: {
            input: {
              customerUuid,
              locale,
            },
          },
        },
      ],
    }
  );
export const useGetWelcomeMessage = (customerUuid: string, locale?: string) =>
  useQuery(GET_WELCOME_MESSAGE, {
    variables: {
      input: {
        customerUuid,
        locale,
      },
    },
  });

export const useCoupleCustomerAndCoreFeature = (customerId: string) =>
  useMutation<
    CoupleCustomerAndCoreFeatureResponse,
    CoupleCustomerAndCoreFeatureInput
  >(COUPLE_CUSTOMER_AND_CORE_FEATURE, {
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: GET_CUSTOMER, variables: { id: customerId, customerId } },
    ],
  });

export const updateCustomerFromCache =
  (
    queryVariables: GetCustomerInput
  ): MutationUpdaterFn<UpdateCustomerResponse> =>
  (cache, res) => {
    const data = res?.data;
    if (!data) {
      return;
    }
    const cachedQuery = cache.readQuery<CustomerWithFeatures, GetCustomerInput>(
      {
        query: GET_CUSTOMER,
        variables: queryVariables,
      }
    );
    if (!cachedQuery) {
      return;
    }
    //TODO: update features, identitySearch, and social
    cache.writeQuery({
      query: GET_CUSTOMER,
      variables: queryVariables,
      data: {
        customer: data.editCustomer,
        social: data.social.personalFeedOverride,
      },
    });
  };
