import { useQuery } from '@apollo/client';
import React, { createContext, PropsWithChildren, useEffect, useState } from 'react';
import { CORE_LOCALES } from '../utils/query';
import { CoreLocale, Locale, LocaleContextType } from '../utils/types';

const LocaleContext = createContext<LocaleContextType>({
  localeLoading: false,
  locales: [],
  localeMap: {}
})

const LocaleContextProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const { loading: localeLoading, data: localeData } = useQuery<CoreLocale>(CORE_LOCALES);
  const [localeMap, setLocaleMap] = useState<{ [key: string]: string }>({});
  const [locales, setLocales] = useState<Locale[]>([]);
  useEffect(() => {
    if (localeData?.getCoreLocales?.coreLocales?.nodes) {
      let localesObj = localeData.getCoreLocales.coreLocales.nodes;
      localesObj = localesObj.map((locale) => {
        if (locale.code === 'in-ID') {
          return {
            code: 'id-ID',
            languageDescription: locale.languageDescription,
            countryDescription: locale.countryDescription
          }
        }
        return locale;
      })
      setLocales(localesObj);
      const localeMapObj: { [key: string]: string } = localesObj.reduce((acc: { [key: string]: string }, curr: Locale) => {
        acc[curr.code] = curr.languageDescription;
        return acc;
      }, {});
      localeMapObj['ro-RO'] = 'Romanian - (Romania)';
      localeMapObj['da-DK'] = 'Danish';
      localeMapObj['fi-FI'] = 'Finnish';
      localeMapObj['no-NO'] = 'Norwegian';
      localeMapObj['sk-SK'] = 'Slovak';
      localeMapObj['sv-SE'] = 'Swedish';
      localeMapObj['zh-TW'] = 'Chinese (Traditional)';
      setLocaleMap(localeMapObj);
    }
  }, [localeData]);

  return (<LocaleContext.Provider value={{
    localeLoading,
    localeMap,
    locales
  }}>
    {children}
  </LocaleContext.Provider>)
};

export { LocaleContextProvider, LocaleContext };
