import React, { useState, useEffect, useContext } from 'react';
import {
  AwardPresentedByEnumPrev,
  DaysToPresentEnumPrev,
  IdentityPresenter,
} from '../types';
import {
  MenuItem,
  Search,
  SearchOptionCard,
  Select,
  Box,
} from '@octanner/prism-core';
import getFullName from '../utils/getFullName';
import useIdentitySearch from '../utils/useIdentitySearch';
import { Heading } from '../styles';
interface Props {
  recogConfigId: string;
  daysToPresent?: DaysToPresentEnumPrev;
  presentedBy?: AwardPresentedByEnumPrev;
  presenter?: IdentityPresenter;
  setDaysToPresent: (string: unknown) => void;
  setPresentedBy: (string: unknown) => void;
  setPresenter: (string: unknown) => void;
}
import CustomerIdContext from '../utils/CustomerIdContext';

export const isPresentationValid = (
  daysToPresent: DaysToPresentEnumPrev,
  presentedBy: AwardPresentedByEnumPrev,
  presenter: IdentityPresenter,
): boolean =>
  !(
    daysToPresent !== DaysToPresentEnumPrev.IMMEDIATELY &&
    presentedBy === AwardPresentedByEnumPrev.SOMEONE_ELSE &&
    !presenter
  );

export const i18n = {
  daysToPresent: {
    label: 'Days to present',
    beforeAutoAction: 'Number of days before automatic action',
    days: ['Immediately', '3 Days', '7 Days', '15 Days', '30 Days', '180 Days'],
  },
  presentedBy: {
    label: 'Award presented by',
    presenter: [
      'Giver',
      'Giver selects',
      "Giver's manager",
      "Recipient's manager",
      'Someone else',
    ],
  },
  search: {
    emptyText: "We couldn't find anyone who matched your search",
    forPresenter: 'Search for presenter',
    error: {
      empty: 'You must select someone',
    },
  },
  title: 'Award value and Presentation',
};

const daysToPresentEnumMenuItems = Object.keys(DaysToPresentEnumPrev).map(
  (key, i) => {
    return (
      <MenuItem value={DaysToPresentEnumPrev[key]} key={key}>
        {key === DaysToPresentEnumPrev.IMMEDIATELY ? (
          <em>{i18n.daysToPresent.days[i]}</em>
        ) : (
          i18n.daysToPresent.days[i]
        )}
      </MenuItem>
    );
  },
);

const awardPresentedByEnumMenuItems = Object.keys(AwardPresentedByEnumPrev).map(
  (key, i) => {
    return (
      <MenuItem value={AwardPresentedByEnumPrev[key]} key={key}>
        {i18n.presentedBy.presenter[i]}
      </MenuItem>
    );
  },
);

const AwardPresentation: React.FC<Props> = ({
  daysToPresent = DaysToPresentEnumPrev.IMMEDIATELY,
  presentedBy = AwardPresentedByEnumPrev.GIVER,
  presenter,
  setDaysToPresent,
  setPresenter,
  setPresentedBy,
  recogConfigId,
}) => {
  const [presenterSearchStr, setPresenterSearchStr] = useState('');
  const { customerId } = useContext(CustomerIdContext);
  const { data, loading } = useIdentitySearch(presenterSearchStr, customerId);

  const [errorSearchEmpty, setErrorSearchEmpty] = useState(false);
  const searchEmptyCheck = (identity) => {
    setErrorSearchEmpty(!(identity || presenter?.id));
  };
  // Reset search error if the other presentation fields change
  useEffect(() => {
    if (errorSearchEmpty) {
      setErrorSearchEmpty(false);
    }
  }, [daysToPresent, presentedBy, errorSearchEmpty]);

  return (
    <Box sx={{ mb: '20px' }}>
      <Heading variant="h3" marginTopIncrease>
        {i18n.title}
      </Heading>

      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: ' 1fr 1fr 1fr',
          gridGap: '8px',
          alignItems: 'start',
        }}
      >
        <Select
          id={`days-to-present-${recogConfigId}`}
          value={daysToPresent}
          label={i18n.daysToPresent.label}
          helperText={i18n.daysToPresent.beforeAutoAction}
          // setTimeout is only needed to prevent a focus/blur bug on the Search component
          onChange={(e) => setTimeout(() => setDaysToPresent(e.target.value))}
        >
          {daysToPresentEnumMenuItems}
        </Select>

        {daysToPresent !== DaysToPresentEnumPrev.IMMEDIATELY && (
          <Select
            id={`presented-by-${recogConfigId}`}
            value={presentedBy}
            label={i18n.presentedBy.label}
            // setTimeout is only needed to prevent a focus/blur bug on the Search component
            onChange={(e) => setTimeout(() => setPresentedBy(e.target.value))}
          >
            {awardPresentedByEnumMenuItems}
          </Select>
        )}

        {daysToPresent !== DaysToPresentEnumPrev.IMMEDIATELY &&
          presentedBy === AwardPresentedByEnumPrev.SOMEONE_ELSE && (
            <Search
              id={`someone-else-${recogConfigId}`}
              multiple={false}
              placeholder={i18n.search.forPresenter}
              noOptionsText={i18n.search.emptyText}
              loading={loading}
              onBlur={() => searchEmptyCheck(null)}
              error={errorSearchEmpty}
              helperText={errorSearchEmpty ? i18n.search.error.empty : ''}
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus={!presenter}
              onChange={(e, identitiesObj) => {
                const identity = identitiesObj as IdentityPresenter;
                setPresenter(identity);
                searchEmptyCheck(identity);
              }}
              getOptionLabel={(option: IdentityPresenter) =>
                getFullName(option)
              }
              value={presenter}
              onInputChange={(e, newInputValue) => {
                setPresenterSearchStr(newInputValue);
              }}
              options={data?.identitySearch?.identities || []}
              renderOption={(props, option) => (
                <SearchOptionCard
                  {...props}
                  key={option.id}
                  avatarUrl={option?.profile?.avatar}
                  showAvatar
                  title={getFullName(option)}
                />
              )}
            />
          )}
      </Box>
    </Box>
  );
};

export default AwardPresentation;
