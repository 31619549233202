import { FieldTypes } from '../graphql';

export const ORDER_VALUES: { [key: string]: FieldTypes } = {
  'email-address': 'EMAIL_ADDRESS',
  'business-unit': 'BUSINESS_UNIT',
  'leader-name': 'LEADER_NAME',
  'employee-id': 'EMPLOYEE_ID',
  'parent-business-unit': 'PARENT_BUSINESS_UNIT',
  'job-title': 'JOB_TITLE',
  'work-location': 'WORK_LOCATION',
  'work-location-condensed': 'WORK_LOCATION_CONDENSED',
};
