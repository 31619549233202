import Typography from '@octanner/prism-core/Typography';
import AdminCard from './AdminCard';
import { IdentityWithAdminRoles } from '../types';
import { useEffect, useState } from 'react';
import { TEST_ADMINS_PAGED_DATA } from '../constants';
import { useDebouncedValue } from '@octanner/prism-core/hooks';
import { Search, SearchOptionCard } from '@octanner/prism-core';
import { tannerGray } from '@octanner/prism-core/ThemeProvider/colors';
import { ErrorData } from '../../types';

interface AdminDetailsProps {
  type: 'add' | 'edit';
  identityUuid?: string;
  onSelect: (identity?: string) => void;
  identityError?: ErrorData;
}

const AdminDetails = ({
  type,
  identityUuid,
  onSelect = () => {},
  identityError,
}: AdminDetailsProps) => {
  const [{ content: allData }, _setPagedData] = useState(
    TEST_ADMINS_PAGED_DATA
  );
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<IdentityWithAdminRoles[]>([]);
  const [searchText, setSearchText] = useState('');
  const debouncedText = useDebouncedValue(searchText);

  useEffect(() => {
    if (searchText) {
      setLoading(true);
      const timeoutId = setTimeout(() => {
        setLoading(false);
        setOptions(
          allData.filter((option) =>
            new RegExp(searchText, 'ig').test(
              `${option.preferredName} ${option.email} ${option.businessUnit}`
            )
          )
        );
      }, 500 + Math.floor(Math.random() * 1000));
      return () => {
        clearTimeout(timeoutId);
        setLoading(false);
      };
    } else {
      setOptions([]);
    }
  }, [debouncedText, allData, searchText]);

  return (
    <>
      <Typography variant="h2" mt={4}>
        Admin details
      </Typography>
      {identityUuid ? (
        <AdminCard
          identityUuid={identityUuid!}
          onReplaceAdmin={
            type === 'add' ? () => onSelect(undefined) : undefined
          }
        />
      ) : (
        <>
          <Search
            id="admin-search"
            inputDataTestId="admin:search:input"
            noOptionsText="We couldn’t find anyone who matched your search"
            loading={loading}
            multiple={false}
            freeSolo={false}
            forcePopupIcon={false}
            onChange={(_e, value) => onSelect(value?.identityUuid)}
            onInputChange={(_, e) => setSearchText(e)}
            placeholder="Search by name, email or employee id"
            error={identityError?.error}
            options={options}
            renderOption={(props, option) => {
              // TODO: this gets it close to working, but the hover state doesn't work
              const { className, ...optionProps } = props;
              return (
                <SearchOptionCard
                  {...optionProps}
                  key={option.identityUuid}
                  showAvatar
                  avatarUrl={option.profile?.avatar}
                  title={`${option.preferredName || option.firstName} ${
                    option.lastName
                  }`}
                  subTitle={`${option.email} • ${option.businessUnit?.buName}`}
                />
              );
            }}
            sx={{
              width: 540,
              mt: 4,
              '& .MuiSvgIcon-root': {
                color: tannerGray[400],
              },
            }}
          />
          {identityError?.error && (
            <Typography variant="body1" color="error" mt={2} ml={5}>
              This is a required field
            </Typography>
          )}
        </>
      )}
    </>
  );
};

export default AdminDetails;
