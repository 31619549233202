import React, { ComponentProps } from 'react';
import {
  Checkbox,
  Divider as _Divider,
  FormControlLabel,
  Switch,
  Typography,
} from '@octanner/prism-core';

export const Divider = () => (
  <_Divider aria-hidden="true" sx={{ mt: 5, mb: 5 }} />
);

export const topLevelFormControlStyle = {
  width: '100%',
};

export const indentedFormControlStyle = {
  ml: 7,
};

export const SwitchControl = ({
  children,
  checked,
  onChange,
}: {
  children: React.ReactNode;
} & Pick<ComponentProps<typeof Switch>, 'checked' | 'onChange'>) => (
  <FormControlLabel
    control={<Switch checked={checked} onChange={onChange} />}
    label={<Typography variant="h4">{children}</Typography>}
    sx={{ mt: 4, mb: 4 }}
  />
);

export const CheckboxControl = ({
  children,
  checked,
  onChange,
}: {
  children: React.ReactNode;
} & Pick<ComponentProps<typeof Checkbox>, 'checked' | 'onChange'>) => (
  <FormControlLabel
    control={<Checkbox checked={checked} onChange={onChange} />}
    label={children}
  />
);
