import React, { useState, useEffect } from "react";
import { Typography, Button, DatePicker, MenuItem, Select } from "@octanner/prism-core";
import { useTranslation } from "react-i18next";
import { tannerGray } from "@octanner/prism-core/ThemeProvider/colors";
import { DisplayFlex, ActionButton } from "./styles";
import LuxonAdapter from '@date-io/luxon';
import { useMutation } from "@apollo/client";
import { clientTypes } from "./constants";
import { UPDATE_CUSTOMER_LAUNCH_DATE, UPDATE_CUSTOMER_CONTACT_INFO } from "./query";

const ClientUsageTypeSelect = ({
  dataCustomerName,
}) => {
  const { launchedAtTsz, customerUsage, customerStatus } = dataCustomerName?.customer;
  const { t } = useTranslation();
  const [clientType, setClientType] = useState(customerUsage);

  const launchedAtDate = launchedAtTsz ? new Date(launchedAtTsz) : null;
  const [isEditing, setIsEditing] = useState(false);
  const [selectedDate, setSelectedDate] = useState(launchedAtDate);
  const [launchDate, setLaunchDate] = useState(launchedAtDate);
  const [updateCustomerDetails] = useMutation(UPDATE_CUSTOMER_CONTACT_INFO);
  const capitalizedCustomerUsage = clientType.charAt(0).toUpperCase() + clientType.slice(1);

  const seelctedformatDate = (selectedDate: Date): string => {
    if (!selectedDate) return 'N/A';
    const date = selectedDate.getDate();
    const month = selectedDate.toLocaleString('default', { month: 'short' });
    const year = selectedDate.getFullYear();
    return `${date} ${month} ${year}`;
  };

  const [customerLaunchDateMutation] = useMutation(UPDATE_CUSTOMER_LAUNCH_DATE);

  const handleDateChange = (date) => {
    const jsDate = date ? date.toJSDate() : null;
    setSelectedDate(jsDate);
  };

  const formatDate = (date) => {
    if (!date) return null;
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

const handleSave = () => {
  customerLaunchDateMutation({
    variables: { 
      input: {
        customerUuid: dataCustomerName.customer.customerUuid,
        launchAt: formatDate(selectedDate)
      }
    },
  })
  .then(response => {
    if (response.data) {
      setLaunchDate(new Date(response.data.updateCustomerLaunchDate.launchDate));
      setIsEditing(false);
      setClientType(clientType);
    } else {
      console.error('Failed to update launch date');
    }
  })
  .catch(error => {
    console.error('Error:', error);
  });
  updateCustomerDetails({
    variables: {
      input: {
        customerUuid: dataCustomerName.customer.customerUuid,
        customerUsage: clientType,
      }
    }
  }).then(() => {
    setClientType(clientType);
    setLaunchDate(launchDate);
  });
};

  const handleCancel = () => {
    setSelectedDate(launchDate);
    setIsEditing(false);
  };

  return (
    <div>
      <DisplayFlex>
        <Typography
          variant="h3"
          style={{
            marginTop: "7px",
            marginBottom: "16px",
          }}
        >
          {t("client_information")}
        </Typography>
        { !isEditing && (
        <Button 
          style={{ marginBottom: '8px' }} 
          variant="text" 
          test-id="edit_address_btn"
          onClick={() => setIsEditing(!isEditing)}
        >
          {t('edit_section')}
        </Button>
        )}
      </DisplayFlex>
      <div>
        {!isEditing && (
        <Typography style={{ color: tannerGray[600] }}>
          {t("client_launch_date") + ':'}
        </Typography> 
        )}
        {isEditing ? (
          <><DatePicker
            disabled={((customerStatus === 'impl' && launchedAtTsz === null) || customerStatus === 'lost') ? true : false}
            label={t("client_launch_date")}
            value={selectedDate}
            onChange={handleDateChange}
            dateAdapter={LuxonAdapter} />
          <div style={{
            marginTop: '16px',
          }}>
          <Select
          style={{
            width: '204px' 
          }}
            label={t('client_usage_type')}
            value={clientType}
            onChange={(e) => {
             const selectedValue = e.target.value;
              setClientType(selectedValue);
            }}
            test-id="client-usage-type"
>
            {clientTypes.map(({ name, value }) => (
               <MenuItem key={value} value={value}>
            {name}
            </MenuItem>
         ))}
        </Select>
            </div></>
        ) : (
          <><Typography>{ launchedAtTsz === null ? 'N/A' : seelctedformatDate(selectedDate)}</Typography>
          <div style={{ marginTop: '16px', marginBottom: '60px' }}>
              <Typography style={{ color: tannerGray[600] }}>
                {t("client_usage_type") + ':'}
              </Typography>
              <Typography>{capitalizedCustomerUsage}</Typography>
            </div></>
        )}
      </div>
      {isEditing && (
        <ActionButton>
          <Button
            style={{
               marginRight: "16px",
              }}
             adminColor onClick={handleSave}>
            {t('save')}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleCancel}
          >
            {t('cancel')}
          </Button>
        </ActionButton>
      )}
    </div>
  );
};

export default ClientUsageTypeSelect;
