import { useLazyQuery } from "@apollo/client";
import styled from "@emotion/styled";
import { CircularProgress, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@octanner/prism-core";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from 'react-router-dom';
import { CustomTextContext } from "../../Contexts/CustomTextContext";
import { defaults } from "../../utils/default";
import { FETCH_CUSTOM_TEXT_BY_ID } from "../../utils/query";
import { CustomText } from "../../utils/types";
import CustomTextDetails from "./CustomTextDetails";
import CustomTextRow from "./CustomTextRow";
import EditCustomTextRequestModal from "./Modals/EditCustomTextRequestModal";
import LocaleSelectionContainer from "./LocaleSelectionContainer";

const StringIdCell = styled(TableCell)`
  width: 10px;
`;

const StyledCircularProgress = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  height: 40vh;
`;

const EditCustomText = () => {

  const { customTextData,
    targetLocales, customTextRefetchInd, setCustomTextRefetchInd, setCustomTextData, setCustomTextUniqueId, setCustomTextNote, setTargetLocales,
    setMessageText, targetLocale } = useContext(CustomTextContext);
  const { stringId } = useParams();
  const [selectedLocale, setSelectedLocale] = useState<string>('');
  const [sourceLocale, setSourceLocale] = useState<string>('');
  const [closeModalInd, setCloseModalInd] = useState<boolean>(false);
  const [customTextList, setCustomTextList] = useState<CustomText[]>([]);
  const [showRequestModal, setShowRequestModal] = useState<boolean>(false);
  const [note, setNote] = useState<string>(customTextData?.customTextRequest?.notes || '');

  const selectedLocaleRef = useRef<string>('');

  const history = useHistory();

  const [fetchCustomTextByStringId, { loading: customTextLoading }] = useLazyQuery(FETCH_CUSTOM_TEXT_BY_ID, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      if (data.lsFetchCustomTextStringUsingStringID !== null) {
        setCustomTextData(data.lsFetchCustomTextStringUsingStringID);
        setCustomTextUniqueId(data.lsFetchCustomTextStringUsingStringID.customTextRequest.uniqueId);
        if (data.lsFetchCustomTextStringUsingStringID.customTextRequest.notes) {
          setCustomTextNote(data.lsFetchCustomTextStringUsingStringID.customTextRequest.notes);
        }
        if (data.lsFetchCustomTextStringUsingStringID.targetLocaleList.length > 0) {
          setTargetLocales(data.lsFetchCustomTextStringUsingStringID.targetLocaleList);
        }
        const sourceLocale = data.lsFetchCustomTextStringUsingStringID?.customTextRequest?.sourceLocale;
        setSourceLocale(sourceLocale);
        if ((sourceLocale && sourceLocale !== selectedLocaleRef.current && !targetLocale) || data.lsFetchCustomTextStringUsingStringID.targetLocaleList.length > 0) {
          selectedLocaleRef.current = sourceLocale;
          setSelectedLocale(sourceLocale);
        }
      } else {
        setMessageText('errorMessage', defaults.fetch_custom_text_error);
        history.push('/custom-text');
      }
    },
    onError: () => {
      setCustomTextData(null);
      setMessageText('errorMessage', defaults.fetch_custom_text_error);
      history.push('/custom-text');
    }
  });

  useEffect(() => {
    if (stringId || customTextRefetchInd) {
      if (targetLocale === null) {
        selectedLocaleRef.current = null;
      }
      fetchCustomTextByStringId({
        variables: {
          stringId: stringId,
          targetLocale: targetLocale ? targetLocale : null
        }
      })
      setCustomTextRefetchInd(false);
    }
  }, [stringId, fetchCustomTextByStringId, customTextRefetchInd]);

  useEffect(() => {
    setNote(customTextData?.customTextRequest?.notes || '');
  }, [customTextData?.customTextRequest?.notes]);

  useEffect(() => {
    if (customTextData && customTextData.customTextList) {
      const customTextListData = customTextData.customTextList.map((item: CustomText) => ({
        ...item,
        prevBriefIdentifierText: item.briefIdentifierText,
        prevSourceText: item.sourceText,
        prevText: item.text,
        editMode: false,
        checked: false,
        loading: false
      }));
      setCustomTextList(customTextListData);
    }
  }, [customTextData]);

  return (
    <>
      {showRequestModal && <EditCustomTextRequestModal
        localesToRequest={targetLocales}
        note={note}
        setNote={setNote}
        showRequestModal={showRequestModal}
        setShowRequestModal={setShowRequestModal}
        setCloseModalInd={setCloseModalInd}
        sourceLocale={sourceLocale}
      />}
      {!customTextLoading ?
        (customTextData && <>
          <CustomTextDetails customTextDetails={customTextData.customTextRequest} />
          {customTextData.customTextRequest && <LocaleSelectionContainer selectedLocale={selectedLocale}
            setSelectedLocale={setSelectedLocale}
            fetchCustomTextByStringId={fetchCustomTextByStringId} />}
          <Table>
            <TableHead>
              <TableRow>
                <StringIdCell>
                  <Typography variant='h4'>{defaults.id}</Typography>
                </StringIdCell>
                <TableCell>
                  <Typography variant='h4'>{defaults.brief_identifier_text}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant='h4'>{defaults.status}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant='h4'>{defaults.source_custom_text}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant='h4'>{defaults.translation}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant='h4'>{defaults.actions}</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {customTextList.map(
                (customText) => {
                  return (
                    <CustomTextRow key={customText.stringId}
                      {...customText}
                      setCustomTextList={setCustomTextList}
                      customTextList={customTextList}
                      showRequestModal={showRequestModal}
                      setShowRequestModal={setShowRequestModal}
                      closeModalInd={closeModalInd}
                      sourceLocale={customTextData?.customTextRequest?.sourceLocale}
                      selectedLocale={selectedLocale}
                      selectedLocaleRef={selectedLocaleRef}
                      targetLocales={targetLocales}
                    ></CustomTextRow>
                  );
                }
              )}
            </TableBody>
          </Table>
        </>) : (
          <StyledCircularProgress>
            <CircularProgress />
          </StyledCircularProgress>
        )}
    </>
  )
}

export default EditCustomText;