import { useMutation } from '@apollo/client';
import styled from '@emotion/styled';
import { Button, Chip, Modal, Switch, Typography } from '@octanner/prism-core';
import React, { useContext, useEffect, useState } from 'react';
import { CustomTextContext } from '../../../Contexts/CustomTextContext';
import { LocaleContext } from '../../../Contexts/LocaleContext';
import { defaults } from '../../../utils/default';
import { CUSTOM_TEXT_RAISE_TRANSLATION_REQUEST } from '../../../utils/query';
import StyledTextField from '../../../utils/styledComponents';
import { EditCustomTextRequestModalProps } from '../../../utils/types';

const Header = styled(Typography)`
  margin: 16px 0px;
`;

const ChipContainer = styled.div`
  margin: 16px 0px 24px 0px;
  display: flex;
  flex-wrap: wrap; 
`;

const SwitchContainer = styled.div`
  display : flex;
  margin-top : 24px;
  padding-right: 48px;
  align-items: center;
`;

const StyledChip = styled(Chip)`
  margin: 8px 8px 0px 0px;
`;

const EditCustomTextRequestModal: React.FC<EditCustomTextRequestModalProps> = ({
  localesToRequest,
  note,
  setNote,
  showRequestModal,
  setShowRequestModal,
  setCloseModalInd,
  sourceLocale
}) => {

  const { setMessageText, customTextUniqueId, customTextNote,
    setCustomTextNote, saveCustomTextNotesProcessing } = useContext(CustomTextContext);

  const [requestedLocales, setRequestedLocales] = useState<string[]>(localesToRequest);
  const [requestExistingTranslation, setRequestExistingTranslation] = useState<boolean>(true);
  const { localeMap } = useContext(LocaleContext);
  const [sendCustomTextTranslationRequest, { loading: translationRequestProcessing }] = useMutation(CUSTOM_TEXT_RAISE_TRANSLATION_REQUEST, {
    onError: () => {
      setMessageText('errorMessage', defaults.update_error);
    }
  });

  const resetModal = (): void => {
    if (translationRequestProcessing) return;
    setRequestExistingTranslation(true);
    setShowRequestModal(false);
    setCloseModalInd(true);
  }

  useEffect(() => {
    if (localesToRequest?.length > 0) {
      setRequestedLocales(localesToRequest.filter((locale: string) => locale !== sourceLocale));
    }
  }, [localesToRequest])

  const handleRequest = (): void => {
    if (requestExistingTranslation) {
      sendCustomTextTranslationRequest({
        variables: {
          input: {
            localeList: requestedLocales,
            ctrUniqueId: customTextUniqueId
          }
        },
      }).then(() => {
        setMessageText('createSuccessMessage', '');
        setShowRequestModal(false);
        setCloseModalInd(false);
      });
    } else {
      setRequestExistingTranslation(true);
      setShowRequestModal(false);
      setCloseModalInd(false);
    }
    if (note.trim() !== customTextNote.trim()) {
      setCustomTextNote(note);
    }
  }

  return (
    <Modal
      actions={<>
        <Button color="secondary" disabled={saveCustomTextNotesProcessing || translationRequestProcessing} onClick={() => resetModal()}>{
          defaults.cancel}
        </Button>
        <Button color="primary" loading={saveCustomTextNotesProcessing || translationRequestProcessing} onClick={() => handleRequest()} disabled=
          {(note?.trim()?.length > 4000)}>{defaults.save}</Button>
      </>}
      id="edit-custom-text-request-modal"
      title={defaults.send_translation}
      open={showRequestModal}
      onClose={() => resetModal()}>
      <Typography variant="body1">
        {defaults.source_text_change_value}
      </Typography>
      {requestedLocales && requestedLocales.length > 0 && (
        <>
          <SwitchContainer>
            <Switch
              checked={requestExistingTranslation}
              onChange={() => setRequestExistingTranslation(!requestExistingTranslation)}
            />
            <Typography variant="body1">
              {defaults.send_req_for_existing_translations}
            </Typography>
          </SwitchContainer>
          {requestExistingTranslation && (
            <>
              <Header variant="h4">{defaults.send_translation_request}</Header>
              <ChipContainer>
                {requestedLocales.map((item, index) => (
                  <StyledChip
                    key={index}
                    label={localeMap[item]}
                    variant='outlined'
                  />
                ))}
              </ChipContainer>
            </>
          )}
        </>
      )}
      <Header variant="h4">{defaults.notes}</Header>
      <StyledTextField
        placeholder={`${defaults.notes} :`}
        multiline
        fullWidth
        displayCount={true}
        maxLength={4000}
        rows={4}
        value={note}
        onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => setNote(event.target.value)}
      />
    </Modal>
  );
}

export default EditCustomTextRequestModal;
