import { useContext, useEffect, useState } from 'react';
import BasicPadding from '../../../common/BasicPadding';
import { Button, Modal, Tabs, Tag, Typography } from '@octanner/prism-core';
import { Link as RouterLink, useLocation, useHistory } from 'react-router-dom';
import {
  AddProgramTitle,
  BYPSubTitle,
  ColoredTabs,
  ErrorChip,
  FlexGap48,
  MB32Div,
  MBAlert,
  MT16Div,
  ProgramIDSubTitle,
  SaveAndCancelFlexContainer,
  SolidLineAcross,
  SubmitButton,
  SubtitleTypo,
} from './styles';
import ProductCategorySettings from './sections/productCategorySettings';
import ProgramDetails from './sections/programDetails';
import {
  CreateProgramPayload,
  UpdateProgramPayload,
  EditProgramProps,
  ErrorObject,
  Participants,
  ProgramSettingValues,
} from './types';
import { CustomerContext } from '../../../utils/context/CustomerContext';
import { INITIAL_ELIGIBILITY_STATE, INITIAL_PROGRAM_STATE } from './constants';
import GeneralSettings from './sections/generalSettings';
import { TabPanel } from './tabPanel';
import {
  cleanProgramData,
  EligibilityInput,
  UpdateProgramRequest,
  //? This is not needed for now, but will be needed in the future
  // useBankSalesOrgsById,
  useCreateEligibility,
  useGetEligibilityById,
  useGetProgramById,
  useUpdateEligibility,
  useUpdateProgram,
} from './graphql';
import ErrorHandlers from './errorHandlers';
import { DateTime } from 'luxon';
import { CloseSharp } from '@material-ui/icons';
import ProgramSkeleton from '../ProgramSkeletons';
import { ArrowLeft } from '@octanner/prism-icons';
const EditProgram = ({ match }: EditProgramProps) => {
  const {
    customer: { id: customerId },
  } = useContext(CustomerContext);
  let { search } = useLocation();
  const history = useHistory();
  const query = new URLSearchParams(search);
  let eligibilityWarning = query.get('eligibilityWarning');
  const [programSettings, setProgramSettings] = useState<CreateProgramPayload>(
    INITIAL_PROGRAM_STATE
  );
  const defaultTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [tabValue, setTabValue] = useState(0);
  const [programDetailError, setProgramDetailError] = useState<ErrorObject>({
    enabled: false,
    count: 0,
  });
  const [productCategoryError, setProductCategoryError] = useState<ErrorObject>(
    {
      enabled: false,
      count: 0,
    }
  );
  const [startDateError, setStartDateError] = useState(false);
  const [endDateError, setEndDateError] = useState(false);
  const [eligibilityError, setEligibilityError] = useState<boolean>(false);
  const [bankError, setBankError] = useState(false);
  const [typeError, setTypeError] = useState(false);
  const [hasProgramName, setHasProgramName] = useState(true);
  const [programId, setProgramId] = useState<number>(-1);
  const [successMessage, setSuccessMessage] = useState(false);
  const [eligibilitySaveError, setEligibilitySaveError] = useState<{
    message: string;
    enabled: boolean;
  }>({ message: '', enabled: false });
  const [modifiableParticipants, setModifiableParticipants] = useState<
    Participants[]
  >([]);
  const [active, setActive] = useState<boolean>(false);
  const [participantCount, setParticipantCount] = useState(0);
  const [, setProgramEligibility] = useState<EligibilityInput>(
    INITIAL_ELIGIBILITY_STATE
  );
  const [modifiedProgramSettings, setModifiedProgramSettings] =
    useState<UpdateProgramPayload>({});
  const [isValueChanged, setIsValueChanged] = useState(false);
  const [includedSalesOrgError, setIncludedSalesOrgError] = useState(false);
  const {
    data: programData,
    loading: programLoading,
    error: programError,
  } = useGetProgramById({
    programUuid: match.params.programId,
  });
  const {
    data: eligibilityData,
    loading: eligibilityLoading,
    error: getEligibilityError,
  } = useGetEligibilityById({
    programUuid: match.params.programId,
  });
  //? This is not needed for now, but will be needed in the future
  // const { data: salesOrgs, loading: salesOrgsLoading } = useBankSalesOrgsById({
  //   request: {
  //     customerUuid: customerId,
  //     pointBankUuid: programSettings.pointBankUuid,
  //   },
  // });
  const [updateProgram] = useUpdateProgram();
  const [updateEligibility] = useUpdateEligibility();
  const [editProgramError, setEditProgramError] = useState<string>('');
  const dateConverter = (date: string) =>
    DateTime.fromISO(date).toISODate() || '';
  const [createEligibility] = useCreateEligibility();
  const [open, setOpen] = useState(false);
  useEffect(() => {
    const programEndDate = programSettings.endDate;
    const programStartDate = programSettings.startDate;
    if (!programSettings.displayTz) {
      setProgramSettings((prevSettings) => ({
        ...prevSettings,
        displayTz: defaultTimezone,
      }));
    }
    if (
      programEndDate &&
      dateConverter(programEndDate as unknown as string) <=
        (DateTime.now().toISODate() || '')
    ) {
      setActive(false);
    } else if (
      programStartDate &&
      dateConverter(programStartDate as unknown as string) >
        (DateTime.now().toISODate() || '')
    ) {
      setActive(false);
    } else {
      setActive(true);
    }
    // eslint-disable-next-line
  }, [programSettings, modifiedProgramSettings]);
  useEffect(() => {
    if (getEligibilityError || programError) {
      // ? Handle error here
    } else if (programData && eligibilityData) {
      const { eligibleCount, restructuredProgram, eligibilityList } =
        cleanProgramData(programData, eligibilityData);
      setProgramSettings(restructuredProgram);
      setModifiableParticipants(eligibilityList);
      setParticipantCount(eligibleCount.eligibleIdentityCount);
      setProgramId(programData.coreProgramByProgramUuid.coreProgram.id);
    }
    //eslint-disable-next-line
  }, [programData, eligibilityData, programLoading, eligibilityLoading]);
  useEffect(() => {
    if (customerId) handleUpdateProgramSettings('customerId', customerId);
  }, [customerId]);
  useEffect(() => {
    setEligibilityError(eligibilityWarning === 'true' ? true : false);
  }, [eligibilityWarning]);
  const handleTabValueChange = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    event.preventDefault();
    setTabValue(newValue);
  };
  const resetState = () => {
    // ? State reset
    setHasProgramName(true);
    setBankError(false);
    setTypeError(false);
    setStartDateError(false);
    setStartDateError(false);
    setSuccessMessage(false);
    setIncludedSalesOrgError(false);
    setProgramDetailError({
      enabled: false,
      count: 0,
    });
    setProductCategoryError({
      enabled: false,
      count: 0,
    });
  };
  const createParticipants = (programUuid: string) => {
    let participantEligibility: EligibilityInput = {
      programUuid,
      allParticipants: false,
      businessUnitEligibilities: [],
      workCountryEligibilities: [],
      homeCountryEligibilities: [],
    };
    const segmentTypeIsAll = modifiableParticipants[0].segmentType === 'all';
    if (modifiableParticipants.length === 0 || segmentTypeIsAll) {
      participantEligibility.allParticipants = true;
      return participantEligibility;
    } else {
      participantEligibility.allParticipants = false;
      modifiableParticipants.forEach((participant) => {
        const isIncluded = participant.isIncluded ? 'INCLUDE' : 'EXCLUDE';
        switch (participant.segmentType) {
          case 'businessUnit':
            if (participant.businessUnits) {
              participant.businessUnits.forEach(({ uuid: buUuid }) =>
                participantEligibility.businessUnitEligibilities.push({
                  eligibilitySet: isIncluded,
                  buUuid,
                })
              );
            }
            break;
          case 'homeCountry':
            if (participant.countries) {
              participant.countries.forEach(({ iso2Code: countryIso2Code }) =>
                participantEligibility.homeCountryEligibilities.push({
                  eligibilitySet: isIncluded,
                  countryIso2Code,
                })
              );
            }
            break;
          case 'workCountry':
            if (participant.countries) {
              participant.countries.forEach(({ iso2Code: countryIso2Code }) =>
                participantEligibility.workCountryEligibilities.push({
                  eligibilitySet: isIncluded,
                  countryIso2Code,
                })
              );
            }
            break;
        }
      });
    }
    setProgramEligibility(participantEligibility);
    return participantEligibility;
  };
  const errorChecking = () => {
    let isError = false;
    const currentDate = DateTime.now();
    if (
      modifiedProgramSettings.startDate &&
      DateTime.fromISO(modifiedProgramSettings.startDate).startOf('day') <
        currentDate.startOf('day')
    ) {
      isError = true;
      incrementProgramCount('DATE');
    }
    if (
      modifiedProgramSettings.startDate &&
      modifiedProgramSettings.endDate &&
      DateTime.fromISO(modifiedProgramSettings.endDate).startOf('day') <
        DateTime.fromISO(modifiedProgramSettings.startDate).startOf('day')
    ) {
      isError = true;
      incrementProgramCount('ENDDATE');
    }
    if (
      modifiedProgramSettings.programName &&
      !modifiedProgramSettings.programName
    ) {
      setHasProgramName(false);
      isError = true;
    }
    if (
      modifiedProgramSettings.pointBankUuid &&
      modifiedProgramSettings.pointBankUuid === ''
    ) {
      isError = true;
      incrementProgramCount('BANK');
    }
    //? This is not needed for now, but will be needed in the future
    // if (
    //   programSettings.programSalesOrgs.length === 0 ||
    //   !programSettings.programSalesOrgs.some((org) => org.disabledTsz === null)
    // ) {
    //   isError = true;
    //   incrementProgramCount('SALESORG');
    // }
    if (
      modifiedProgramSettings.programFinanceTypeCode &&
      modifiedProgramSettings.programFinanceTypeCode === ''
    ) {
      isError = true;
      setTypeError(true);
      setProductCategoryError((prev) => ({
        enabled: true,
        count: prev.count + 1,
      }));
    }
    return isError;
  };
  const handleSaveProgram = (e) => {
    e.preventDefault();
    resetState();
    const isError = errorChecking();
    if (!isError && Object.keys(modifiedProgramSettings).length > 1) {
      const payload = modifiedProgramSettings as UpdateProgramRequest;
      // ? Fixes date types
      if (payload.startDate) {
        payload.startDate = modifiedProgramSettings.startDate
          .startOf('day')
          .toISODate();
      }
      if (payload.endDate) {
        payload.endDate = modifiedProgramSettings.endDate
          .startOf('day')
          .toISODate();
      }
      payload.programId = programId;
      payload.customerId = customerId;
      payload.displayTz = programSettings.displayTz;
      const filteredSalesOrgs =
        modifiedProgramSettings.programSalesOrgs?.filter(
          (x) => x.disabledTsz === null || !x.disabledTsz
        );
      const updatedSelectedSalesOrg =
        filteredSalesOrgs &&
        filteredSalesOrgs.map(({ pointBankSalesOrgUuid }) => ({
          pointBankSalesOrgUuid,
        }));
      payload.programSalesOrgs = updatedSelectedSalesOrg;
      updateProgram({ variables: { input: { ...payload } } })
        .then(() => {
          setSuccessMessage(true);
        })
        .catch((err) => setEditProgramError(err.message));
    }
  };
  const handleUpdateProgramSettings = (
    key: keyof CreateProgramPayload,
    value: ProgramSettingValues,
    includeModified: boolean = true
  ) => {
    setProgramSettings((prev) => ({ ...prev, [key]: value }));
    if (includeModified) {
      setModifiedProgramSettings((prev) => ({ ...prev, [key]: value }));
    }
    if (key !== 'customerId') {
      setIsValueChanged(!!value);
    }
  };
  const incrementProgramCount = (
    key: 'BANK' | 'PARTICIPANTS' | 'DATE' | 'ENDDATE' | 'SALESORG'
  ) => {
    switch (key) {
      case 'BANK':
        setBankError(true);
        break;
      case 'DATE':
        setStartDateError(true);
        break;
      case 'ENDDATE':
        setEndDateError(true);
        break;
      case 'SALESORG':
        setIncludedSalesOrgError(true);
        break;
      case 'PARTICIPANTS':
        // ? participantError was an old feature, I think this can be left out for now.
        //setParticipantError(true);
        break;
    }
    setProgramDetailError((prev) => ({ enabled: true, count: prev.count + 1 }));
  };
  const cleanError = (e) => {
    const errorMessage = e.message;
    const splitMessage = errorMessage.split(
      /(?:"message)(?:"\s?:\s?")(.*)(?:")/
    );
    const results = splitMessage[1];
    return results;
  };
  const handleParticipationSave = () => {
    const payload = createParticipants(match.params.programId);
    const programEligibilityUuid =
      eligibilityData?.coreProgramEligibilityByProgram?.programEligibility
        ?.programEligibilityUuid || null;
    // ? Page load will always stop this but its a failsafe
    if (programEligibilityUuid) {
      updateEligibility({
        variables: {
          request: {
            ...payload,
            programEligibilityUuid,
          },
        },
      })
        .then(() => setSuccessMessage(true))
        .catch((e) => {
          setEligibilitySaveError({ message: cleanError(e), enabled: true });
        });
    } else {
      createEligibility({ variables: { request: payload } })
        .then(() => {
          setSuccessMessage(true);
        })
        .catch((e) => {
          setEligibilitySaveError({ message: cleanError(e), enabled: true });
        });
    }
  };
  const hasOriginalBank = () => {
    const bank =
      programData?.coreProgramByProgramUuid?.coreProgram?.bank?.pointBankUuid ||
      false;
    if (bank) {
      return bank.length > 0;
    } else return false;
  };
  const handleModalCancel = () => {
    setOpen(false);
  };
  const handleModalDiscard = () => {
    history.push(`/customer/${customerId}/programs`);
    setOpen(true);
  };
  const handlePageCancel = () => {
    if (isValueChanged) {
      setOpen(true);
    } else {
      history.push(`/customer/${customerId}/programs`);
    }
  };
  return (
    <>
      <BasicPadding padding="md">
        <Button
          variant="text"
          data-test="program:add"
          // @ts-ignore prism issue
          underline="none"
          startIcon={<ArrowLeft />}
          component={!successMessage && isValueChanged ? 'button' : RouterLink}
          to={`/customer/${customerId}/programs`}
          onClick={() => {
            if (!successMessage && isValueChanged) {
              setOpen(true);
            }
          }}
        >
          Back to Your Programs
        </Button>
        <Modal
          actions={
            <>
              <Button onClick={handleModalDiscard}>Discard</Button>
              <Button onClick={handleModalCancel} color="secondary">
                Cancel
              </Button>
            </>
          }
          id="collapse-Modal"
          onClose={handleModalCancel}
          open={open}
          title="You have unsaved changes."
        >
          <BYPSubTitle>
            Going away from this page will discard any unsaved changes.
          </BYPSubTitle>
        </Modal>
        <FlexGap48>
          <AddProgramTitle variant="h1">Edit Program</AddProgramTitle>
          <div>
            <SubtitleTypo variant="subtitle1">Status</SubtitleTypo>
            <Tag variant={active ? 'success' : 'archived'}>
              {active ? 'Active' : 'Inactive'}
            </Tag>
          </div>
          <div>
            <ProgramIDSubTitle variant="subtitle1">
              Program ID:
            </ProgramIDSubTitle>
            <Typography>{match.params.programId}</Typography>
          </div>
        </FlexGap48>
        <ErrorHandlers
          eligibilityWarning={eligibilityError}
          productCategoryError={productCategoryError}
          programDetailError={programDetailError}
          setEligibilityWarning={setEligibilityError}
          setProductCategoryError={setProductCategoryError}
          setProgramDetailError={setProgramDetailError}
          eligibilitySaveError={eligibilitySaveError}
          setEligibilitySaveError={setEligibilitySaveError}
          createProgramError={editProgramError} // ? Functionally, these 2 are identical, so they can take up the same spot.
          setCreateProgramError={setEditProgramError}
        />
        {successMessage && (
          <MBAlert
            severity="success"
            action={<CloseSharp onClick={() => setSuccessMessage(false)} />}
          >
            Program Saved Successfully!
          </MBAlert>
        )}
        {programLoading || eligibilityLoading ? (
          //? This is not needed for now, but will be needed in the future
          // ||
          // salesOrgsLoading
          <ProgramSkeleton name={'editProgram'} />
        ) : (
          <>
            <GeneralSettings
              type="edit"
              programName={programSettings.programName}
              hasProgramName={hasProgramName}
              programStartDate={programSettings.startDate}
              programEndDate={programSettings.endDate}
              handleProgramSettingChange={handleUpdateProgramSettings}
              startDateError={startDateError}
              endDateError={endDateError}
              customerUuid={customerId}
              programId={programId}
              programTimeZone={programSettings.displayTz}
            />
            <Tabs
              value={tabValue}
              onChange={handleTabValueChange}
              TabIndicatorProps={{
                style: {
                  backgroundColor: '#0060E0',
                },
              }}
            >
              <ColoredTabs
                icon={
                  <>
                    {programDetailError.enabled && (
                      <ErrorChip>{programDetailError.count}</ErrorChip>
                    )}
                  </>
                }
                label="Program details"
                iconPosition="end"
              />
              <ColoredTabs
                icon={
                  <>
                    {productCategoryError.enabled && (
                      <ErrorChip>{productCategoryError.count}</ErrorChip>
                    )}
                  </>
                }
                label="Product category settings"
                iconPosition="end"
              />
            </Tabs>
            <SolidLineAcross />
            <TabPanel value={tabValue} index={0}>
              <MT16Div>
                <ProgramDetails
                  type="edit"
                  customerId={customerId}
                  bankUuid={programSettings.pointBankUuid || ''}
                  hasOriginalBank={hasOriginalBank()}
                  //? These two are not needed for now, but will be needed in the future
                  // salesOrgs={
                  //   salesOrgs?.corePtMgmtGetBankInformation.information
                  //     .pointBankSalesOrges || []
                  // }
                  // selectedSalesOrgs={programSettings.programSalesOrgs}
                  handleProgramSettingChange={handleUpdateProgramSettings}
                  modifiableParticipants={modifiableParticipants}
                  setModifiableParticipants={setModifiableParticipants}
                  programSettings={programSettings}
                  bankError={bankError}
                  participantCount={participantCount}
                  handleParticipationSave={handleParticipationSave}
                  typeError={typeError}
                  includedSalesOrgError={includedSalesOrgError}
                />
              </MT16Div>
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <ProductCategorySettings
                type="edit"
                programId={match.params.programId || ''}
                customerId={customerId}
                handleProgramSettingChange={handleUpdateProgramSettings}
              />
            </TabPanel>
          </>
        )}
        <MB32Div />
      </BasicPadding>
      <SaveAndCancelFlexContainer>
        <SubmitButton adminColor onClick={handleSaveProgram}>
          Save Program
        </SubmitButton>
        <Button color="secondary" onClick={handlePageCancel}>
          Cancel
        </Button>
      </SaveAndCancelFlexContainer>
    </>
  );
};
export default EditProgram;
