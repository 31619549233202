import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  Pagination,
} from '@octanner/prism-core';
import { Order } from '../../common/models';
import { IdentityWithCustomerBase } from '../user-auth/models/Identity';
import { ImpersonateIdentityProps } from './types';
import { impersonateHeaders } from './constants';
import { useStyles } from './styles';
import { useCurrentIdentity } from '../user-auth/hooks/identity';
import { authFetch } from '../../utils/auth/Auth';

const ImpersonateIdentityList = ({
  identities,
  onRowClick,
  onSort,
  orderBy,
  count,
  currentPage,
  setPage,
}: ImpersonateIdentityProps) => {
  const classes = useStyles();
  const [columnName, direction] = orderBy;
  const { PUBLIC_URL: publicUrl } = process.env;
  const { data: identity } = useCurrentIdentity();

  const handlePostImpersonate = (row) => {
    authFetch(
      `${publicUrl}/recent/impersonate/${identity?.currentIdentity?.id}`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(row),
      }
    );
  };
  const handleOnclick = (identity) => {
    let selection = window.getSelection();
    if (selection?.toString().length === 0) {
      onRowClick(identity);
      handlePostImpersonate(identity);
    }
  };

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {impersonateHeaders.map(({ id, label }) =>
              id === 'employeeSecurityRole' ? (
                <TableCell key={id}>
                  <Typography variant="h5">{label}</Typography>
                </TableCell>
              ) : (
                <TableCell
                  key={id}
                  sortDirection={
                    columnName === id ? (direction as Order) : false
                  }
                >
                  <TableSortLabel
                    active={columnName === id}
                    direction={columnName === id ? (direction as Order) : 'asc'}
                    onClick={() => onSort(id)}
                    data-test={`programs:header:${id}`}
                    aria-label={
                      direction === 'desc'
                        ? 'sorted descending'
                        : 'sorted ascending'
                    }
                  >
                    <Typography variant="h5">{label}</Typography>
                  </TableSortLabel>
                </TableCell>
              )
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {identities.map((identity: IdentityWithCustomerBase) => (
            <TableRow
              data-test={`impersonate:identities:${identity.id}`}
              key={identity.id}
              className={classes.row}
              hover
              onClick={() => handleOnclick(identity)}
            >
              <TableCell>{identity.firstName}</TableCell>
              <TableCell>{identity.lastName}</TableCell>
              <TableCell>{identity.email}</TableCell>
              <TableCell>{identity.uniqueId}</TableCell>
              <TableCell>{identity.customer.name}</TableCell>
              <TableCell>{identity.employeeSecurityRole}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Pagination
        className={classes.impersonatePagination}
        count={count}
        page={currentPage}
        onChange={(_, page) => setPage(page)}
      ></Pagination>
    </TableContainer>
  );
};
export default ImpersonateIdentityList;
