import styled from "@emotion/styled";
import { FileUpload, Grid, Typography } from "@octanner/prism-core";
import { Check, Close } from "@octanner/prism-icons";
import React, { useContext, useState } from "react";
import { CustomTextContext } from "../../../Contexts/CustomTextContext";
import { defaults } from "../../../utils/default";

const ControlContainer = styled(Grid)`
  margin-top: 24px;
`;

const RoundedBox = styled.div`
  padding: 12px;
  border-radius: 10px;
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
  font-size: 12px;
`;

const FileName = styled.div`
  flex-direction: column;
  margin-left: 20px;
  flex-grow: 1;
`;

const UploadedTick = styled(Check)`
  color: #07702A;
`;

const CloseBtn = styled(Close)`
  cursor: pointer;
  color: rgba(0, 0, 0, 0.6);
  margin-right: 12px;
`;

const Control = styled.div`
  margin-top: 12px;
`;

const CustomTextBulkUpload = ({ onFileChange, selectedFile, setSelectedFile, setSampleDownloadError }) => {

  const [fileUploadError, setFileUploadError] = useState<string>('');
  const [sampleFileDownloading, setSampleFileDownloading] = useState<boolean>(false);

  const { fetch } = useContext(CustomTextContext);

  const handleFileChange = (files: FileList): void => {
    setFileUploadError('');
    onFileChange(files[0]);
    handleControlChange();
  };

  const handleControlChange = (): void => {
    setSampleDownloadError(false);
  };

  const downloadSampleFile: () => Promise<void> = async () => {
    if (sampleFileDownloading) {
      return;
    }
    setSampleFileDownloading(true);
    try {
      const response = await fetch('/api/language/bulk-upload/custom-text/sample', {
        method: 'GET',
        headers: {
          Accept: 'application/vnd.ms-excel',
        },
      });
      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `sampleBulkFile.xlsx`;
        a.click();
        window.URL.revokeObjectURL(url);
      } else {
        setSampleFileDownloading(false);
        setSampleDownloadError(true);
      }
    } catch (error) {
      setSampleFileDownloading(false);
      setSampleDownloadError(true);
    }
  };

  return (
    <>
    <Grid container>
      <ControlContainer item xs={5}>
        <Typography variant='h3'>
          {defaults.upload_excel_file_title}
        </Typography>
        <Typography color='textSecondary'>
          {defaults.upload_excel_file_custom_text_description}
        </Typography>
        <Control>
          {!selectedFile && <FileUpload
            onChange={handleFileChange}
            supportedExtensions={['xlsx']}
            sampleFileText={defaults.upload_excel_file_download_text}
            variant='md'
            onSampleFileClick={downloadSampleFile}
            data-testid='file-upload'
            onError={(error: string) => setFileUploadError(error)}
            error={!!fileUploadError}
            helperText={fileUploadError}
          />}
          {selectedFile && <RoundedBox>
            <UploadedTick />
            <FileName>
              <Typography variant='body2'>{selectedFile.name}</Typography>
              <Typography variant='body2' color='textSecondary'>
                {defaults.file_upload_success}
              </Typography>
            </FileName>
            <CloseBtn onClick={() => setSelectedFile(null)} />
          </RoundedBox>}
        </Control>
      </ControlContainer>
      </Grid>
    </>
  )
};

export default CustomTextBulkUpload;