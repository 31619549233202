import { useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_TIME_ZONES } from './graphql';
import { CountryTimeZone, GetTimeZonesResponse } from './models';

export const useGetTimeZones = () => {
  const [timeZones, setTimezones] = useState<CountryTimeZone[]>([]);

  const onCompleted = (res: GetTimeZonesResponse) => {
    setTimezones(
      [...res.timeZones.timeZones.nodes].sort((a, b) =>
        a.timeZoneName.localeCompare(b.timeZoneName)
      )
    );
  };

  const { loading } = useQuery<GetTimeZonesResponse>(GET_TIME_ZONES, {
    fetchPolicy: 'network-only',
    onCompleted,
  });

  return { timeZones, loading };
};
