import { Route, Switch } from 'react-router-dom';

import AdminRoles from './index';
import CreateAdminRole from './createAdminRole';
import EditAdminRole from './editAdminRole';
import DuplicateAdminRole from './duplicateAdminRole';

const AdminRoleRoutes = () => {
  return (
    <Switch>
      <Route path={`/admin-roles`} component={AdminRoles} exact />
      <Route path={`/admin-roles/new`} component={CreateAdminRole} exact />
      <Route
        path={`/admin-roles/:roleUuid/edit`}
        component={EditAdminRole}
        exact
      />
      <Route
        path={`/admin-roles/:roleUuid/duplicate`}
        component={DuplicateAdminRole}
        exact
      />
    </Switch>
  );
};

export default AdminRoleRoutes;
