import { useEffect, useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import {
  AllowedMediaEnum,
  Award,
  AwardPresentedByEnum,
  AwardTypes,
  DaysToPresentEnum,
  AwardPresentation,
} from '../types';
import {
  DefinedApprovers,
  Round,
  SubmitDefinedApprovers,
  Screeners,
} from '../ApprovalPath/types';
import { useSetError } from '../utils/SetErrorProvider';
import { AWARD_FRAGMENT, ERROR_FRAGMENT } from '../fragments';
import { GET_ROUNDS, RoundData } from '../ApprovalPath/useRounds';
interface PresentationInput {
  daysToPresent: DaysToPresentEnum;
  presentedBy: AwardPresentedByEnum;
  presenterUuid: string;
}

// The following two types are used only in the request to the API
type RoundWithoutDefinedApprovers = Omit<Round, 'definedApprovers'>;

interface SubmitRound extends RoundWithoutDefinedApprovers {
  definedApprovers?: SubmitDefinedApprovers[];
}

interface FlexField {
  flexFieldId: string;
  required: boolean;
  flexfieldAnswerVisibility: 'RECIPIENT' | 'APPROVER' | 'EVERYONE';
}

interface CreateRequest {
  request: {
    programId: string;
    configName: string;
    emailCC: {
      allowForCC: boolean;
      prePopulateCc: boolean;
      nomineeManager: boolean;
      nominatorManager: boolean;
      allowUserToModifyAdditionalAddresses: boolean;
      requireSenderConfirmation: boolean;
    };
    groupRecognition: {
      allowGroupFileUpload: boolean;
      allowRecipientListViewableByEveryone: boolean;
    };
    notifyNomineeManagerUponFirstNomination: boolean;
    notifyGiverAfterEachSubmission: boolean;
    notifyNomineeUponFirstNomination: boolean;
    awardDescription?: string;
    awardType: AwardTypes;
    active: boolean;
    adjudicationConfigurationInput?: AdjudicationConfiguration;
    flexFieldRecogConfigs: FlexField[];
    mediaOptions: {
      useMedia: boolean;
      allowedMedia?: AllowedMediaEnum[];
      requireMedia: boolean;
    };
    presentation: PresentationInput;
    allowForScheduledDelivery: boolean;
  };
}

interface UpdateRequest {
  request: {
    recogConfigId: string;
    configName: string;
    emailCC: {
      allowForCC: boolean;
      prePopulateCc: boolean;
      nomineeManager: boolean;
      nominatorManager: boolean;
      allowUserToModifyAdditionalAddresses: boolean;
      requireSenderConfirmation: boolean;
    };
    groupRecognition: {
      allowGroupFileUpload: boolean;
      allowRecipientListViewableByEveryone: boolean;
    };
    notifyNomineeManagerUponFirstNomination: boolean;
    notifyGiverAfterEachSubmission: boolean;
    notifyNomineeUponFirstNomination: boolean;
    awardDescription?: string;
    active: boolean;
    adjudicationConfigurationUpdate?: AdjudicationConfiguration;
    flexFieldRecogConfigs: FlexField[];
    mediaOptions: {
      useMedia: boolean;
      allowedMedia: AllowedMediaEnum[];
      requireMedia: boolean;
    };
    presentation: PresentationInput;
    allowForScheduledDelivery: boolean;
  };
}

interface AdjudicationConfiguration {
  adjudicationId: string;
  adjudicationRounds: SubmitRound[];
  adjudicationName: string;
}

export const GET_AWARD_LIST = gql`
  query GetAwardLists($programId: UUID!) {
    recognitionProgram(programId: $programId) {
      id
      programName
      promoBody
      displayOnCCHome
      promoHeader
      recogConfigList {
        recogConfigId
        configName
        active
        flexFields {
          id
        }
        relatedFlexFields {
          flexFieldQuestion {
            id
          }
        }
      }
    }
  }
`;

export const UPDATE_AWARD = gql`
  ${ERROR_FRAGMENT}
  ${AWARD_FRAGMENT}
  mutation CcrcUpdateAwardLevel($request: CcrcUpdateAwardLevelRequest) {
    ccrcUpdateAwardLevel(request: $request) {
      ...ErrorFragment
      data {
        ...AwardFragment
      }
    }
  }
`;

export const SAVE_NEW_AWARD = gql`
  ${ERROR_FRAGMENT}
  ${AWARD_FRAGMENT}
  mutation CreateRecogConfig($request: CcrcCreateAwardLevelRequest!) {
    ccrcCreateAwardLevel(request: $request) {
      ...ErrorFragment
      data {
        ...AwardFragment
      }
    }
  }
`;

interface Error {
  message: string;
  code: string;
}

interface CreateData {
  ccrcCreateAwardLevel: {
    error: Error;
    data: Award;
  };
}

interface UpdateData {
  ccrcUpdateAwardLevel: {
    error: Error;
    data: Award;
  };
}

const convertPresentation = (
  presentation: AwardPresentation,
  adjudicationConfig: Award['adjudicationConfiguration'],
) =>
  !adjudicationConfig
    ? {
        daysToPresent: DaysToPresentEnum.IMMEDIATELY,
        presentedBy: null,
        presenterUuid: undefined,
      }
    : presentation.presentedBy !== AwardPresentedByEnum.CONFIG_USER_SEARCH
      ? {
          daysToPresent: presentation.daysToPresent,
          presentedBy: presentation.presentedBy,
          presenterUuid: undefined,
        }
      : {
          daysToPresent: presentation.daysToPresent,
          presentedBy: presentation.presentedBy,
          presenterUuid: presentation.presenter?.id,
        };

const convertDefinedApprovers = (
  definedApprovers: DefinedApprovers,
  sendDefinedApprovers = true,
) => {
  if (!sendDefinedApprovers) return null;
  return (
    definedApprovers?.defaultDefinedApprover && [
      {
        approverIdentityUuid:
          definedApprovers.defaultDefinedApprover?.approver?.id,
      },
      ...(definedApprovers.definedApproversForBusinessUnits?.map(
        (approver) => ({
          approverIdentityUuid: approver.approver?.id,
          businessUnitUuid: approver.businessUnit?.buUuid,
        }),
      ) ?? []),
    ]
  );
};

export const useAwardList = (programId: string) => {
  const setError = useSetError();
  const { loading, data } = useQuery(GET_AWARD_LIST, {
    variables: { programId },
    onError: () => {
      setError(
        'There was a problem getting the list of available awards. Please try again.',
      );
    },
  });
  const [awardList, setAwardList] = useState([] as Award[]);
  useEffect(() => {
    if (data?.recognitionProgram) {
      setAwardList(data.recognitionProgram?.recogConfigList);
    }
  }, [data]);

  const [createRecogConfig] = useMutation<CreateData, CreateRequest>(
    SAVE_NEW_AWARD,
    {
      update(cache, { data: { ccrcCreateAwardLevel } }) {
        if (ccrcCreateAwardLevel.error) {
          setError(
            'Oops! There was a problem saving. Please review and try again.',
          );
          setAwardList((existingAwards) =>
            existingAwards.filter(
              (award) => award.recogConfigId !== award.configName,
            ),
          );
          return;
        }
        const newAward = ccrcCreateAwardLevel.data;
        setAwardList((existingAwards) => [
          ...existingAwards.filter(
            (award) => award.recogConfigId !== award.configName,
          ), // don't add twice
          newAward,
        ]);
      },
      onError: () => {
        setError(
          'Oops! There was a problem saving. Please review and try again.',
        );
      },
    },
  );

  const [updateRecogConfig] = useMutation<UpdateData, UpdateRequest>(
    UPDATE_AWARD,
    {
      update(cache, { data: { ccrcUpdateAwardLevel } }) {
        if (ccrcUpdateAwardLevel.error) {
          setError(
            'Oops! There was a problem saving. Please review and try again.',
          );
          return;
        }
        const updatedAward = ccrcUpdateAwardLevel.data;

        // Update cache
        const { ccrcGetAwardLevelByUuid } = cache.readQuery<RoundData>({
          query: GET_ROUNDS,
          variables: {
            awardLevelUuid: updatedAward.recogConfigId,
          },
        });
        cache.writeQuery<RoundData>({
          query: GET_ROUNDS,
          variables: {
            awardLevelUuid: updatedAward.recogConfigId,
          },
          data: {
            ccrcGetAwardLevelByUuid: {
              ...ccrcGetAwardLevelByUuid,
              data: { ...updatedAward },
            },
          },
        });

        setAwardList((existing = []) =>
          existing.map((award) =>
            award.recogConfigId === updatedAward.recogConfigId
              ? { ...award, ...updatedAward }
              : award,
          ),
        );
      },
      onError: () => {
        setError(
          'Oops! There was a problem saving. Please review and try again.',
        );
      },
    },
  );

  return {
    loading,
    program: data?.recognitionProgram,
    data: awardList,
    createRecogConfig: (request: Award) =>
      createRecogConfig({
        variables: {
          request: {
            programId: request.programId,
            configName: request.configName,
            awardDescription: request.awardDescription,
            awardType: request.awardType,
            active: request.active,
            emailCC: request.emailCC,
            groupRecognition: request.groupRecognition,
            notifyNomineeManagerUponFirstNomination:
              request.notifyNomineeManagerUponFirstNomination,
            notifyGiverAfterEachSubmission:
              request.notifyGiverAfterEachSubmission,
            notifyNomineeUponFirstNomination:
              request.notifyNomineeUponFirstNomination,
            adjudicationConfigurationInput: request.adjudicationConfiguration
              ? {
                  adjudicationId: request.adjudicationConfiguration.id,
                  adjudicationName: request.adjudicationConfiguration.name,
                  adjudicationRounds:
                    request.adjudicationConfiguration.adjudicationRounds.map(
                      (round) => ({
                        ...round,
                        definedApprovers: convertDefinedApprovers(
                          round.definedApprovers,
                          round.screener.screener ===
                            Screeners.DEFINED_APPROVER,
                        ),
                      }),
                    ),
                }
              : null,
            flexFieldRecogConfigs: request.relatedFlexFields.map(
              (relatedFlexField) => {
                return {
                  flexFieldId: relatedFlexField.flexFieldQuestion.id,
                  required: relatedFlexField.required,
                  flexfieldAnswerVisibility:
                    relatedFlexField.flexfieldAnswerVisibility,
                };
              },
            ),
            mediaOptions: request.mediaOptions,
            presentation: convertPresentation(
              request.presentation,
              request.adjudicationConfiguration,
            ),
            allowForScheduledDelivery: request.allowForScheduledDelivery,
          },
        },
        optimisticResponse: {
          ccrcCreateAwardLevel: {
            error: null,
            data: {
              ...request,
              recogConfigId: request.configName,
              adjudicationConfiguration: {
                ...request.adjudicationConfiguration,
                id: 'new',
              },
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              //@ts-ignore doesn't affect runtime, but unit tests need these for some reason
              __typename: 'CcrcAwardLevel',
            },
          },
        },
      }),

    updateRecogConfig: (award: Award) =>
      updateRecogConfig({
        variables: {
          request: {
            recogConfigId: award.recogConfigId,
            configName: award.configName,
            awardDescription: award.awardDescription,
            emailCC: award.emailCC,
            groupRecognition: award.groupRecognition,
            notifyNomineeManagerUponFirstNomination:
              award.notifyNomineeManagerUponFirstNomination,
            notifyGiverAfterEachSubmission:
              award.notifyGiverAfterEachSubmission,
            notifyNomineeUponFirstNomination:
              award.notifyNomineeUponFirstNomination,
            active: award.active,
            adjudicationConfigurationUpdate: award.adjudicationConfiguration
              ? {
                  adjudicationId: award.adjudicationConfiguration.id,
                  adjudicationName: award.adjudicationConfiguration.name,
                  adjudicationRounds:
                    award.adjudicationConfiguration.adjudicationRounds.map(
                      (round) => ({
                        ...round,
                        advancement: {
                          ...round.advancement,
                          __typename: undefined,
                        },
                        screener: {
                          ...round.screener,
                          __typename: undefined,
                          otherUser: undefined,
                        },
                        definedApprovers: convertDefinedApprovers(
                          round.definedApprovers,
                          round.screener.screener ===
                            Screeners.DEFINED_APPROVER,
                        ),
                        __typename: undefined,
                      }),
                    ),
                }
              : null,
            flexFieldRecogConfigs: award.relatedFlexFields.map(
              (relatedFlexField) => {
                return {
                  flexFieldId: relatedFlexField.flexFieldQuestion.id,
                  required: relatedFlexField.required,
                  flexfieldAnswerVisibility:
                    relatedFlexField.flexfieldAnswerVisibility,
                };
              },
            ),
            mediaOptions: award.mediaOptions,
            presentation: convertPresentation(
              award.presentation,
              award.adjudicationConfiguration,
            ),
            allowForScheduledDelivery: award.allowForScheduledDelivery,
          },
        },
        optimisticResponse: {
          ccrcUpdateAwardLevel: {
            error: null,
            data: {
              ...award,
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              //@ts-ignore doesn't affect runtime, but unit tests need these for some reason
              __typename: 'CcrcAwardLevel',
            },
          },
        },
      }),
  } as const;
};
