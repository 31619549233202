import { useQuery } from '@apollo/client';
import { CountriesByCustomerResponse } from './models';
import { GET_COUNTRIES_BY_CUSTOMER } from './graphql';

export const useGetCountriesByCustomer = (customerId: string) => {
  const countryQuery = useQuery<
    CountriesByCustomerResponse,
    { customerId: string }
  >(GET_COUNTRIES_BY_CUSTOMER, {
    variables: {
      customerId,
    },
  });

  return {
    ...countryQuery,
    data: countryQuery.data?.countriesByCustomer.countries.nodes || [],
  };
};
