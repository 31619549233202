import {
  Button,
  Link,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@octanner/prism-core';

import clsx from 'clsx';
import React, { useState } from 'react';
import CollapseIcon from '../../common/CollapseIcon';
import IdentitySelector from './IdentitySelector';
import { formatName, IdentityBase } from '../user-auth/models/Identity';
import { Role } from '../user-auth/models/Role';
import ClientMenu from './ClientMenu';
import RoleSelector from './RoleSelector';
import { ClientItemProps, FormState, ViewModel } from './types';
import CopyToClipboardButton from '../../common/CopyToClipboard';
import SnackbarPopup from '../../common/snackbar';
import { useStyles } from './styles';
import { useUnMapUserRoles } from '../user-auth/hooks/role';
import BinaryDialog from '../../common/BinaryDialog';

const ClientListItem = ({
  roles,
  client,
  toggled,
  loading = false,
  onToggleCollapse,
  mappings,
  onSubmit,
  refetch,
  ...menuProps
}: ClientItemProps) => {
  const classes = useStyles();
  const [formState, setFormState] = useState<FormState>();
  const [roleFormError, setRoleFormError] = useState({ err: false, msg: '' });
  const [newUser, setNewUser] = useState(false);
  const isOpen = Boolean(mappings);
  const [itemToDelete, setItemToDelete] = React.useState<{
    identity: IdentityBase;
    roles: Role[];
  }>();
  const unMapUserRoles = useUnMapUserRoles();
  const handleEditForm = () => {
    if (!toggled) onToggleCollapse(client.id);
    toggleEdit();
  };
  const toggleEdit = () => {
    setNewUser(false);
    if (formState) return setFormState(undefined);
    setFormState({ newModel: { roles: [] } });
  };

  const removeNewUser = () => {
    setNewUser(false);
    const newFormData = formState;
    //@ts-ignore
    delete newFormData.newModel;
    setFormState(newFormData);
  };

  const deleteUser = async () => {
    if (!itemToDelete) {
      return;
    }
    setItemToDelete(undefined);
    await unMapUserRoles({
      variables: {
        identityId: itemToDelete.identity.id,
        roleIds: itemToDelete.roles.map(({ id }) => Number(id)),
        customerId: client.id,
      },
    });
    refetch();
  };

  const handleIdentityChange = (key: string) => (identity?: IdentityBase) => {
    if (!formState) return;
    setFormState({
      ...formState,
      [key]: {
        ...formState[key],
        identity,
      },
    });
  };

  const handleRolesChange = (key: 'newModel' | string) => (roles: Role[]) => {
    if (!formState) return;
    setFormState({
      ...formState,
      [key]: {
        ...formState[key],
        roles,
      },
    });
  };

  const handleNewUser = () => {
    setNewUser(true);
    setFormState({
      ...formState,
      newModel: { roles: [] },
    });
  };

  const handleSubmit = () => {
    try {
      if (!formState) return;
      const { newModel, ...rest } = formState;
      const isValid = newModel?.roles.length && newModel?.identity;

      if (newUser && !isValid) {
        setRoleFormError({
          err: true,
          msg: 'Error: Missing role/user information. Please try again.',
        });
      } else {
        const validForms = isValid ? formState : rest;
        const models = Object.keys(validForms)
          .map((key) => ({ ...formState[key], id: key }))
          .map((model) => {
            const identityId = model.identity?.id || model.id;
            if (identityId === 'newModel') return { added: [], removed: [] };
            const originalRolesIds =
              mappings
                ?.find((mp) => mp.identity.id === identityId)
                ?.roles.map((r) => r.id) || [];
            const currentRoleIds = model.roles.map((r) => r.id);

            const removedIds = originalRolesIds.filter(
              (id) => !currentRoleIds.includes(id)
            );
            const addedIds = currentRoleIds.filter(
              (id) => !originalRolesIds.includes(id)
            );

            return {
              added: addedIds.map((id) => ({
                customerId: client.id,
                identityId,
                roleId: id,
              })),
              removed: removedIds.map((id) => ({
                customerId: client.id,
                identityId,
                roleIds: [id],
              })),
            };
          })
          .reduce(
            ({ added: oa, removed: or }, { added, removed }) => ({
              added: [...oa, ...added],
              removed: [...or, ...removed],
            }),
            { added: [], removed: [] }
          );
        onSubmit(models);
        toggleEdit();
      }
    } catch (e) {
      console.log('Error: ', e);
    }
  };

  const sortedMaps = [...(mappings || [])].sort((a, b) =>
    formatName(a.identity).localeCompare(formatName(b.identity))
  );
  const viewModels: ViewModel[] = sortedMaps
    .map((mapping) =>
      mapping.roles.map((role, index) => ({
        identity: mapping.identity,
        role,
        isFirst: !index,
      }))
    )
    .reduce((acc, arr) => [...acc, ...arr], []);

  const truncate = (str: string, n: number) => {
    return str.length > n ? str.substr(0, n - 1) + '...' : str;
  };

  return (
    <TableRow key={client.id} data-test={`clients:${client.id}`}>
      {itemToDelete && (
        <BinaryDialog
          description={`Are you sure you want to delete ${itemToDelete.identity.firstName} ${itemToDelete.identity.lastName}? It cannot be undone.`}
          primaryText="Delete"
          title="Delete User"
          onPrimaryClick={deleteUser}
          onSecondaryClick={() => setItemToDelete(undefined)}
          open={!!itemToDelete}
        />
      )}

      <TableCell className={classes.cell}>
        <div className={classes.clientName}>
          <CollapseIcon
            data-testid={`collapsed:${isOpen}`}
            isLoading={loading}
            isOpen={isOpen}
            className={classes.textExpand}
            onClick={() => onToggleCollapse(client.id)}
          />
          <Typography className={classes.clientNameText}>
            {truncate(client.name, 35)}
          </Typography>
        </div>
      </TableCell>
      <TableCell align="left" className={classes.cell4}>
        <Typography variant="body2" style={{ marginTop: 8 }}>
          {client.stpNumber || '-'}
        </Typography>
      </TableCell>
      <TableCell className={classes.cell}>
        <Tooltip title={client.id}>
          <div className={classes.centerAlign}>
            <CopyToClipboardButton textValue={client.id} />
            <Typography variant="body2">{client.id}</Typography>
          </div>
        </Tooltip>
      </TableCell>

      <TableCell className={formState ? classes.cell4 : classes.cell}>
        {!loading && !newUser && formState && (
          <Link
            onClick={handleNewUser}
            data-test={`client:${client.id}:mapping:add`}
          >
            <Typography className={classes.clickable}>Add user</Typography>
          </Link>
        )}
        {!formState && (
          <>
            {viewModels.map((vm) => (
              <div
                key={`${vm.identity.id}:${vm.role.uuid}`}
                className={classes.repRole}
              >
                {vm.isFirst && (
                  <div style={{ textAlign: 'right', height: 22 }}>
                    <div style={{ marginBottom: 10 }} />
                    {formatName(vm.identity)}
                  </div>
                )}
              </div>
            ))}
          </>
        )}
        {newUser && formState && (
          <div className={classes.containerFlex}>
            <div style={{ flex: 1 }}>
              <Button onClick={removeNewUser} adminColor>
                X
              </Button>
            </div>
            <div style={{ flex: 10, marginLeft: 5 }}>
              <IdentitySelector
                selected={formState?.newModel.identity}
                onChange={handleIdentityChange('newModel')}
                hideIdentities={viewModels.map((m) => m.identity)}
                className={classes.user}
              />
            </div>
          </div>
        )}
        {formState &&
          mappings?.map((map, i) => (
            <div className={classes.buttonContainerFlex} key={i}>
              <Button
                className={classes.deleteButton}
                adminColor
                onClick={() =>
                  setItemToDelete({
                    identity: map.identity,
                    roles: map.roles,
                  })
                }
              >
                <Typography style={{ color: 'red' }}>X</Typography>
              </Button>
              <div style={{ flex: 10, marginLeft: 5 }}>
                <IdentitySelector
                  key={`${map.identity.id}-${i}`}
                  selected={map.identity}
                  className={classes.user}
                />
              </div>
            </div>
          ))}
      </TableCell>

      <TableCell className={formState ? classes.cell2 : classes.cell4}>
        {!loading && !newUser && formState && <div style={{ margin: 20 }} />}
        {!formState &&
          viewModels.map((vm, i) => (
            <React.Fragment key={`${vm.identity.id}_${i}`}>
              {vm.isFirst && <div style={{ marginBottom: 10 }} />}
              <div
                key={`${vm.identity.id}:${vm.role.uuid}`}
                className={i % 2 ? classes.repRole : classes.repRoleAlt}
              >
                {truncate(vm.role.name, 30)}
              </div>
            </React.Fragment>
          ))}
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {formState?.newModel && newUser && (
            <RoleSelector
              className={classes.user}
              selected={formState.newModel.roles}
              onChange={handleRolesChange('newModel')}
              dataTestId="newModel"
              data={roles}
            />
          )}
          {formState &&
            mappings?.map((map) => (
              <RoleSelector
                className={classes.user}
                key={`${map.identity.id}+${map.identity.firstName}`}
                selected={formState[map.identity.id]?.roles || map.roles}
                onChange={handleRolesChange(map.identity.id)}
                dataTestId={map.identity.id}
                data={roles}
              />
            ))}
        </div>
      </TableCell>
      <TableCell
        className={clsx(
          formState ? classes.cell3 : classes.cell4,
          formState ? classes.editingCell : ''
        )}
      >
        {!formState && (
          <ClientMenu
            onEditClick={handleEditForm}
            client={client}
            {...menuProps}
          />
        )}
        {!loading && formState && (
          <div className={classes.actions}>
            <Link
              className={clsx(classes.clickable, classes.cancel)}
              onClick={toggleEdit}
              data-test={`client:${client.id}:mapping:cancel`}
            >
              Cancel
            </Link>
            <Button
              data-test={`client:${client.id}:mapping:save`}
              onClick={handleSubmit}
              adminColor
            >
              Save
            </Button>
          </div>
        )}
      </TableCell>
      <SnackbarPopup
        open={roleFormError.err}
        handleClose={() => setRoleFormError({ err: false, msg: '' })}
        severity="error"
        text={roleFormError.msg}
      />
    </TableRow>
  );
};

export default ClientListItem;
