import { useStyles } from './styles';
import { Divider, Link, Typography } from '@octanner/prism-core';
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { AppContext } from '../utils/context/AppContext';
import impersonationAuthorizer from '../utils/auth/ImpersonationAuthorizer';
import xcaAuthorizer from '../utils/auth/XcaAuthorizer';
import AppMenu from './AppMenu';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { globalMenuItems } from '../pages/global/constants';
import { useCurrentIdentity } from '../pages/user-auth/hooks/identity';
import { useTranslation } from 'react-i18next';
import { useCustomerMenuItems } from './MenuItems';
import { CustomerContext } from '../utils/context/CustomerContext';
import logo from '../utils/assets/logo.png';
import { isInternalLinkItem, isParentLinkItem } from './types';

const { PUBLIC_URL: publicUrl } = process.env;

const DrawerConfig = ({
  back,
  setBack,
}: {
  back: boolean;
  setBack: Dispatch<SetStateAction<boolean>>;
}) => {
  const classes = useStyles();
  const context = useContext(AppContext);
  const { config } = context;
  const { data } = useCurrentIdentity();
  const { cultureCloudUrl, awardStoreDesignManagementDisabled } = config;
  let {
    ccAdminHideGlobalStore,
    ccAdminCommerceRefundOrder,
    ccAdminCommerceStoreExperience,
    ccAdminHideAdminAccess,
    ccAdminHideRemediation,
  } = useFlags();

  const location = useLocation();
  let filteredGlobalMenuItems: any = globalMenuItems;
  if (ccAdminHideAdminAccess?.indexOf(data?.currentIdentity.id) === -1) {
    filteredGlobalMenuItems = filteredGlobalMenuItems.filter(
      (menuItem) => menuItem.dataTest !== 'adminAccess'
    );
  }

  if (
    !ccAdminHideRemediation ||
    !ccAdminHideRemediation.length ||
    ccAdminHideRemediation.includes(data?.currentIdentity.id)
  ) {
  } else {
    filteredGlobalMenuItems = globalMenuItems.filter(
      (menuItem) => menuItem.translationId !== 'remediation'
    );
  }

  if (ccAdminHideGlobalStore) {
    filteredGlobalMenuItems = globalMenuItems.filter(
      (item: any) => item.dataTest !== 'awardStore'
    );
  }

  if (awardStoreDesignManagementDisabled) {
    const awardStoreIndex = filteredGlobalMenuItems?.findIndex(
      (item: any) => item.dataTest === 'awardStore'
    );
    if (awardStoreIndex !== -1) {
      filteredGlobalMenuItems[awardStoreIndex].children =
        filteredGlobalMenuItems[awardStoreIndex]?.children.filter(
          (item: any) => item.dataTest !== 'designManagement'
        );
    }
  }

  if (!ccAdminCommerceRefundOrder) {
    // Find and update 'globalStore' item's children to remove 'orders' item
    const globalStoreItem: any = filteredGlobalMenuItems.find(
      (menuItem) => menuItem.dataTest === 'awardStore'
    );
    globalStoreItem.children = globalStoreItem.children.filter(
      (menuItem: any) => menuItem.dataTest !== 'orders'
    );
  }
  const globalPathnames = filteredGlobalMenuItems.map((item: any) => {
    return item.to;
  });
  globalPathnames.push('/impersonate');
  const childrenGlobalPathNames = filteredGlobalMenuItems
    .map((menuItem) => (isParentLinkItem(menuItem) ? menuItem.children : []))
    .reduce((acc, cur) => [...acc, ...cur], [])
    .map((x) => (isInternalLinkItem(x) ? x.to : ''));

  const allGlobalPathNames = [...globalPathnames, ...childrenGlobalPathNames];

  const isGlobal =
    !location.pathname.startsWith('/customer/') &&
    allGlobalPathNames.includes(location.pathname);
  const isRoles =
    location.pathname.startsWith('/admin-roles/') ||
    location.pathname.startsWith('/admins/');
  const isRemediation = location.pathname.startsWith('/remediation');
  const isNotFound = location.pathname?.indexOf('not-found') > -1;
  const isCustomer = location.pathname.startsWith('/customer');
  const isLanguage =
    location.pathname.startsWith('/language-string') ||
    location.pathname.startsWith('/material-description') ||
    location.pathname.startsWith('/custom-text');
  const hasXca = xcaAuthorizer(context);
  const canImpersonate = impersonationAuthorizer(context);
  let customerMenuItems = useCustomerMenuItems();
  const [margin, setMargin] = useState(0);
  const [ref, setRef] = useState<any>();
  const { customer, unset } = useContext(CustomerContext);

  const { t } = useTranslation();

  if (!ccAdminCommerceStoreExperience && isCustomer) {
    const storeMenuItem: any = customerMenuItems.find(
      (menuItem) => menuItem.dataTest === 'client-store'
    );
    if (
      storeMenuItem?.children?.length === 1 &&
      storeMenuItem.children[0].dataTest === 'storeExperience'
    ) {
      customerMenuItems = customerMenuItems.filter(
        (menuItem) => menuItem.dataTest !== 'client-store'
      );
    } else {
      storeMenuItem.children = storeMenuItem.children.filter(
        (menuItem: any) => menuItem.dataTest !== 'storeExperience'
      );
    }
  }

  useEffect(() => {
    if (isCustomer) setBack(true);
    // eslint-disable-next-line
  }, [isCustomer]);

  useEffect(() => {
    if (ref) {
      setMargin(ref.clientHeight);
    }
  }, [ref]);

  const isImpersonation = location.pathname?.indexOf('/impersonate') > -1;

  return (
    <>
      <div>
        <div className={classes.topNav} style={{ marginBottom: margin }}>
          <div className={classes.logo}>
            <img src={logo} alt="logo" width={208} />
          </div>
          <Divider />
          <Typography
            color="#707070"
            style={{ paddingLeft: 24, marginTop: 20 }}
          >
            {t('cc-admin', 'CC ADMIN')}
          </Typography>
          {(isGlobal ||
            isRoles ||
            isImpersonation ||
            isNotFound ||
            isRemediation ||
            isLanguage) &&
            !isCustomer && (
              <AppMenu
                title={t('global-tools', 'Global Tools')}
                menuItems={filteredGlobalMenuItems}
                addCustomer={false}
                disabled={!hasXca}
              />
            )}
          {isCustomer && !isGlobal && customer?.name !== 'default' && (
            <AppMenu
              title={customer?.name}
              stpNumber={customer?.stpNumber || 'N/A'}
              menuItems={customerMenuItems}
              addCustomer={true}
              disabled={customer?.name === 'default'}
            />
          )}
        </div>
        <div ref={setRef} className={`${classes.bottomNav} bottomNavigation`}>
          {!(isGlobal || isImpersonation || (!isCustomer && isNotFound)) &&
            back && (
              <div onClick={() => unset()}>
                <Divider />
                <RouterLink
                  className={classes.cultureCloudLink}
                  to="/client-list"
                  data-test="clientList"
                >
                  <Typography color="inherit">
                    {t('back-to-global-tools', 'Back to Global Tools')}
                  </Typography>
                </RouterLink>
              </div>
            )}
          {canImpersonate && !isCustomer && (
            <div>
              <Divider />
              <RouterLink
                className={classes.cultureCloudLink}
                to="/impersonate"
                data-test="impersonate"
              >
                <Typography color="inherit">
                  {t('impersonate-user', 'Impersonate User')}
                </Typography>
              </RouterLink>
            </div>
          )}
          {cultureCloudUrl && (
            <>
              <Divider />
              <Link
                className={classes.cultureCloudLink}
                underline="none"
                data-test="open-culture-cloud"
                href={cultureCloudUrl}
                target="_blank"
              >
                <Typography color="inherit">
                  {t('open-culture-cloud', 'Open Culture Cloud')}
                </Typography>
              </Link>
            </>
          )}
          <Divider />
          <div className={classes.profile}>
            {data && (
              <Typography data-test="current-identity">{`${data.currentIdentity.firstName} ${data.currentIdentity.lastName}`}</Typography>
            )}
            <Link
              href={`${publicUrl}/sign-out`}
              underline="none"
              data-test="sign-out"
            >
              <Typography className={classes.signOut}>
                {t('sign-out', 'Sign Out')}
              </Typography>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default DrawerConfig;
