import styled from '@emotion/styled';
import Typography from '@octanner/prism-core/Typography';

export const ClientContainer = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 744px) {
    /* width: 100%;
    flex-wrap: wrap; */
  }
`;

export const ClientListContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CoreIdCopyDiv = styled.div`
  display: flex;
  align-items: center;
`;

export const FlexBetweenDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 24px;
`;

export const TypographyMB = styled(Typography)`
  margin-bottom: 24px;
`;
