import { useQuery, ApolloError } from '@apollo/client';
import {
  CURRENT_IDENTITY,
  GET_IDENTITY_GROUPS_BY_ROLE,
  IDENTITIES_BY_ROLE,
  IDENTITY_SEARCH,
} from '../graphql/identity';
import {
  CurrentIdentityResponse,
  IdentitesByRoleInput,
  IdentitiesByRoleResponse,
  IdentityGroupsByRoleResponse,
  IdentitySearchInput,
  IdentitySearchResponse,
} from '../models/Identity';

export const useGetIdentitiesByRole = (
  variables: IdentitesByRoleInput
): [IdentitiesByRoleResponse | undefined, boolean, ApolloError | undefined] => {
  const { data, loading, error } = useQuery<
    IdentitiesByRoleResponse,
    IdentitesByRoleInput
  >(IDENTITIES_BY_ROLE, {
    variables,
  });

  return [data, loading, error];
};

export const useGetIdentityGroupsByRole = (
  variables: IdentitesByRoleInput
): [
  IdentityGroupsByRoleResponse | undefined,
  boolean,
  ApolloError | undefined
] => {
  const { data, loading, error } = useQuery<
    IdentityGroupsByRoleResponse,
    IdentitesByRoleInput
  >(GET_IDENTITY_GROUPS_BY_ROLE, {
    variables,
  });

  return [data, loading, error];
};

export const useIdentitySearch = (
  searchCondition: IdentitySearchInput['condition']
) =>
  useQuery<IdentitySearchResponse, IdentitySearchInput>(IDENTITY_SEARCH, {
    variables: {
      condition: {
        ...searchCondition,
        internalUser: true,
        size: 20,
      },
    },
    skip:
      !searchCondition.customerId &&
      !searchCondition.email &&
      !searchCondition.name &&
      !searchCondition.uniqueId &&
      !searchCondition.id,
  });

export const useCurrentIdentity = () =>
  useQuery<CurrentIdentityResponse>(CURRENT_IDENTITY);
