import { useContext, useEffect, useMemo, useState } from 'react';
import { Link as RouterLink, useRouteMatch } from 'react-router-dom';
import { EnhancedTableProps } from '../../../common/statusLabel/types';
import { useStyles } from '../../../common/statusLabel/styles';
import BasicPadding from '../../../common/BasicPadding';
import Loading from '../../../common/Loading';
import { Order } from '../../../common/models';
import ScrollLoader from '../../../common/ScrollLoader';
import { CustomerContext } from '../../../utils/context/CustomerContext';
import { BankListCard } from './BankListCard';
import { useGetBanksByCustomer } from './hooks';
import { toBankListVM } from './models';
import { BankListViewModel } from './types';
import { AlignRightContainer, BankHeader, BankSettingMargin } from './styles';

export default function BanksList() {
  const classes = useStyles();
  const match = useRouteMatch();
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof BankListViewModel>('name');
  const [allVMs, setAllVMs] = useState<BankListViewModel[]>([]);

  const {
    customer: { id: customerUuid },
  } = useContext(CustomerContext);
  const checkIfDefaultAccruingExists =
    allVMs[0]?.cust.defaultAccruingBank.defaultAccruingBank;

  const { data, loading, fetchMore, refetch } =
    useGetBanksByCustomer(customerUuid);
  useEffect(() => {
    refetch();
    //eslint-disable-next-line
  }, []);

  const banks = useMemo(() => {
    return data?.corePtMgmtGetBankInformationByCustomer.information.nodes || [];
  }, [data]);

  const handleRequestSort = (property: keyof BankListViewModel) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  useEffect(() => {
    const vms = banks.map(toBankListVM);
    setAllVMs(vms);
    //eslint-disable-next-line
  }, [banks]);

  const enhancedTableProps: EnhancedTableProps = {
    classes,
    onRequestSort: handleRequestSort,
    order,
    orderBy,
  };

  if (!data && loading) {
    return (
      <BasicPadding>
        <Loading />
      </BasicPadding>
    );
  }
  return (
    <BasicPadding>
      <BankHeader>
        <BankSettingMargin variant="h1">Your Banks</BankSettingMargin>
        {allVMs?.length > 0 ? (
          <AlignRightContainer
            color="primary"
            disableRipple
            component={RouterLink}
            // @ts-ignore prism issue
            to={`${match.url}/new${
              checkIfDefaultAccruingExists === undefined ||
              checkIfDefaultAccruingExists === null
                ? '?defaultBank=false'
                : ''
            }`}
            data-test="banks:new"
            adminColor
          >
            Add new bank
          </AlignRightContainer>
        ) : (
          ''
        )}
      </BankHeader>

      <BankListCard
        enhancedTableProps={enhancedTableProps}
        match={match}
        allVMs={allVMs}
        loading={loading}
        checkIfDefaultAccruingExists={checkIfDefaultAccruingExists}
      />
      <ScrollLoader load={loading ? () => {} : fetchMore} />
    </BasicPadding>
  );
}
