import {
  Loader,
  Table,
  TableBody,
  TableHead,
  TableContainer,
  TableCell,
  Button,
  Typography,
  Switch,
} from '@octanner/prism-core';
import { useContext, useEffect, useState } from 'react';
import BasicPadding from '../../common/BasicPadding';
import { CustomerContext } from '../../utils/context/CustomerContext';
import AddLocale from './addLocale';
import {
  useCoupleLocale,
  useDecoupleLocale,
  useGetAllLocales,
  useGetCustomerLocale,
} from './graphql';
import { CustomerLocale, NewLocaleState, Locale } from './types';
import { useStyles } from './styles';
import { LocaleColumns } from './constants';
import SnackbarPopup from '../../common/snackbar';
import { boolToEng, determineEmptyString } from '../../utils/commonFunctions';
import BinaryDialog from '../../common/BinaryDialog';

export const LocalePage = () => {
  const styles = useStyles();
  const { customer } = useContext(CustomerContext);
  const [coupleCustomerLocale] = useCoupleLocale();
  const [decoupleCustomerLocale] = useDecoupleLocale();
  const { data, loading, refetch } = useGetCustomerLocale({
    customerId: customer.id,
  });
  const { data: AllLocales } = useGetAllLocales();
  const [customerLocales, setCustomerLocales] = useState<CustomerLocale[]>([]);
  const [editing, setEditing] = useState<number | null>(null);
  const [editedLocale, setEditedLocale] = useState<CustomerLocale>({
    isActive: false,
    isDefaultLocale: false,
    locale: {
      code: 'DEFAULT_EDIT',
      languageDescription: 'DEFAULT_EDIT',
    },
  });
  const [error, setError] = useState(false);
  const [locales, setLocales] = useState<Locale[]>([]);
  const [addLocale, setAddLocale] = useState(false);
  const [newLocale, setNewLocale] = useState<NewLocaleState>({
    localeCode: '',
    isDefault: false,
  });
  const [disableActiveModal, setDisableActiveModal] = useState(false);
  const [saved, setSaved] = useState(false);
  const [warning, setWarning] = useState(false);
  useEffect(() => {
    if (data) {
      setCustomerLocales(data.customerCoreLocaleMapsByCustomer.nodes);
    }
    if (data && AllLocales) {
      // Query will filter currently inserted locales that are coupled to the user.
      const customerLocalesQuery: CustomerLocale[] =
        data.customerCoreLocaleMapsByCustomer.nodes;
      const preFilterLocales: Locale[] =
        AllLocales.getCoreLocales.coreLocales.nodes;
      const filteredLocales = preFilterLocales.filter(
        (v) =>
          customerLocalesQuery.map((x) => x.locale.code).indexOf(v.code) === -1
      );
      setLocales(filteredLocales);
    }
  }, [data, AllLocales]);

  if (loading) {
    return <Loader adminColor />;
  }

  const handleSaveNewLocale = async () => {
    const { localeCode, isDefault } = newLocale;
    if (!determineEmptyString(localeCode)) {
      setError(true);
      return;
    } else {
      await coupleCustomerLocale({
        variables: {
          input: { customerId: customer.id, localeCode, isDefault },
        },
      });
      setAddLocale(false);
      setNewLocale({
        localeCode: '',
        isDefault: false,
      });
    }
    await refetch();
  };

  const handleSaveEditedLocale = async () => {
    try {
      const {
        locale,
        isDefaultLocale: isDefault,
        isActive: isCoupled,
      } = editedLocale;
      if (!isCoupled && !isDefault) {
        decoupleCustomerLocale({
          variables: {
            input: {
              customerId: customer.id,
              localeCode: locale.code,
            },
          },
        }).then(() => refetch());
      } else {
        coupleCustomerLocale({
          variables: {
            input: {
              customerId: customer.id,
              localeCode: locale.code,
              isDefault,
            },
          },
        }).then(() => refetch());
      }
      setEditing(null);
      setSaved(true);
      setEditedLocale({
        isActive: false,
        isDefaultLocale: false,
        locale: {
          code: 'DEFAULT_EDIT',
          languageDescription: 'DEFAULT_EDIT',
        },
      });
    } catch (e) {
      setError(true);
    } finally {
      await refetch();
    }
  };
  const handleEditLocaleValueChange = (
    key: string,
    value: any,
    checked: boolean
  ) => {
    if (
      key === 'isActive' &&
      !value &&
      !checked &&
      !editedLocale.isDefaultLocale
    ) {
      setDisableActiveModal(true);
    } else if (key === 'isActive' && !value && editedLocale.isDefaultLocale) {
      setWarning(true);
    } else {
      setEditedLocale((currentLocale) => ({ ...currentLocale, [key]: value }));
    }
  };
  const enableEdit = (key: number) => {
    setEditing(key);
    setDisableActiveModal(false);
    setEditedLocale(customerLocales[key]);
  };

  const handleChangeActive = () => {
    handleEditLocaleValueChange('isActive', false, true);
    setDisableActiveModal(false);
  };

  return (
    <BasicPadding>
      <SnackbarPopup
        open={saved}
        handleClose={() => setSaved(false)}
        data-testid="locale:save:success"
        severity="success"
        text={'Saved Successfully!'}
      />
      <SnackbarPopup
        open={warning}
        data-testid="locale:warning"
        handleClose={() => setWarning(false)}
        severity="warning"
        text={
          'Warning! You can not disable an active locale that is also a default. Please change the default locale!'
        }
      />
      <BinaryDialog
        open={disableActiveModal && !editedLocale.isDefaultLocale}
        data-testid="locale:modal:isActive"
        title="Disable Active Locale?"
        description={`
        You are about to remove a supported language for ${customer.name}! 
        Doing so may cause future customer file loads to fail if the ${editedLocale.locale.languageDescription} 
        language is contained therein. Likewise, there may be users that have selected ${editedLocale.locale.languageDescription} 
        as their preferred language. Removing ${editedLocale.locale.languageDescription} as a supported language will not remove this as a 
        preferred language for those users.
        `}
        primaryText="Disable"
        onPrimaryClick={handleChangeActive}
        secondaryText="Cancel"
        onSecondaryClick={() => setDisableActiveModal(false)}
      />
      <Typography variant="h2">Locales</Typography>
      <TableContainer>
        <Table>
          <TableHead>
            {LocaleColumns.map((name, i) => (
              <TableCell align="center" key={name + i}>
                <Typography variant="h4">{name}</Typography>
              </TableCell>
            ))}
          </TableHead>
          {customerLocales.map(({ locale, isDefaultLocale, isActive }, key) => {
            return (
              <TableBody key={locale.languageDescription}>
                <TableCell align="center" data-testid="lang:description">
                  <Typography>{locale.languageDescription}</Typography>
                </TableCell>
                <TableCell align="center" data-testid="lang:isActive">
                  {key === editing ? (
                    <Switch
                      checked={editedLocale?.isActive}
                      onChange={(_, bool) =>
                        handleEditLocaleValueChange('isActive', bool, false)
                      }
                    />
                  ) : (
                    <Typography>{boolToEng(isActive)}</Typography>
                  )}
                </TableCell>
                <TableCell align="center" data-testid="lang:isDefaultLocale">
                  {key === editing ? (
                    <Switch
                      checked={editedLocale?.isDefaultLocale}
                      onChange={(_, bool) =>
                        handleEditLocaleValueChange(
                          'isDefaultLocale',
                          true,
                          true
                        )
                      }
                    />
                  ) : (
                    <Typography>{boolToEng(isDefaultLocale)}</Typography>
                  )}
                </TableCell>
                <TableCell align="right">
                  {key === editing ? (
                    <>
                      <Button
                        className={styles.buttonMR}
                        onClick={handleSaveEditedLocale}
                        data-testid="locale:save"
                        adminColor
                      >
                        Save
                      </Button>
                      <Button
                        variant="outlined"
                        onClick={() => setEditing(null)}
                        data-testid="locale:cancel"
                      >
                        Cancel
                      </Button>
                    </>
                  ) : (
                    <Button
                      data-testid="locale:edit"
                      onClick={() => enableEdit(key)}
                      adminColor
                    >
                      Edit
                    </Button>
                  )}
                </TableCell>
              </TableBody>
            );
          })}
          <AddLocale
            locales={locales}
            newLocale={newLocale}
            setNewLocale={setNewLocale}
            addLocale={addLocale}
            setAddLocale={setAddLocale}
            handleSave={handleSaveNewLocale}
          />
        </Table>
      </TableContainer>
      <SnackbarPopup
        open={error}
        handleClose={() => setError(false)}
        severity="error"
        text="Error! Please Try again."
      />
    </BasicPadding>
  );
};

export default LocalePage;
