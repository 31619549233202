import React from 'react';
import { MultiSelectionButtons, SelectionButton } from '@octanner/prism-core';
import { FlexBox2 } from './style';
import { SelectAndUnselectProps } from './types';
import { useTranslation } from 'react-i18next';

const SelectAndUnselect: React.FC<SelectAndUnselectProps> = ({
  setChecked,
  setUnchecked
}) => {
  const [value, setValue] = React.useState(['checked', 'unchecked']);
  const { t } = useTranslation();
 
  const onChange = (ev) => {
    setValue(ev);
    if(ev.find((value) => value === 'checked')) {
      setChecked(true);
    } else {
      setChecked(false);
    }

    if(ev.find((value) => value === 'unchecked')){
      setUnchecked(true);
    } else {
      setUnchecked(false);
    }

  }
  return (
      <FlexBox2>
        <MultiSelectionButtons
          value={value}
          onChange={(ev) => onChange(ev)}
        >
          <SelectionButton value="checked">{t('selected')}</SelectionButton>
          <SelectionButton value="unchecked">{t('unselected')}</SelectionButton>
        </MultiSelectionButtons>
      </FlexBox2>
  );
};

export default SelectAndUnselect;
