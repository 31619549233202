import { useMutation } from '@apollo/client';
import styled from '@emotion/styled';
import { Button, Modal } from '@octanner/prism-core';
import React, { useContext } from 'react';
import { JobManagementContext } from '../../../Contexts/JobManagementContext';
import { defaults } from '../../../utils/default';
import { DELETE_JOB } from '../../../utils/query';
import { DeleteJobModalProps } from '../../../utils/types';

const DeleteButton = styled(Button)`
background-color: #D10A12;
`

const DeleteJobModal: React.FC<DeleteJobModalProps> = ({
  showDeleteJobModal,
  setShowDeleteJobModal,
  jobNumber
}) => {

  const { searchedTranslationCategory, setMessageText, refetchJobsByFilter } = useContext(JobManagementContext);

  const [deleteJobByJobNumber, { loading }] = useMutation(DELETE_JOB, {
    onError: () => {
      setMessageText('errorMessage', defaults.update_error);
      setMessageText('deleteSuccessMessage', '');
    },
    onCompleted: () => {
      refetchJobsByFilter();
      setMessageText('errorMessage', '');
      setMessageText('deleteSuccessMessage', defaults.job_deleted_sucesss);
    }
  });

  const closeModal = () => {
    if (loading) {
      return;
    }
    setShowDeleteJobModal(false);
  }

  const deleteJob = () => {
    if (loading) {
      return;
    }
    deleteJobByJobNumber({
      variables: {
        input: {
          translationCategory: searchedTranslationCategory,
          jobNumber
        }
      }
    }).then(closeModal);
  }


  return (<Modal
    actions={
      <>
        <Button disabled={loading} onClick={closeModal} color='secondary'>{defaults.cancel}</Button>
        <DeleteButton loading={loading} onClick={deleteJob}>{defaults.delete}</DeleteButton>
      </>
    }
    id='delete-job-modal'
    maxWidth='xs'
    title={defaults.delete_job_title}
    open={showDeleteJobModal}
    onClose={closeModal}>
  </Modal>);
}

export default DeleteJobModal;