import React from 'react';
import styled from '@emotion/styled';
import { FormGroup, FormControlLabel, Switch } from '@octanner/prism-core';

const PrestigeFormGroup = styled(FormGroup)`
  display: flex;
  flex-direction: column;
  align-text: flex-start;
  margin: 0 0 16px 5px;
`;

const DisplayOnCCHome: React.FC<Props> = ({
  displayOnCCHome,
  setDisplayOnCCHome,
}) => {
  return (
    <PrestigeFormGroup>
      <FormControlLabel
        control={
          <Switch
            name="homepageWidgetChecked"
            checked={displayOnCCHome}
            onChange={(event) => setDisplayOnCCHome(event.target.checked)}
          />
        }
        label="Display on Culture Cloud Homepage"
      />
    </PrestigeFormGroup>
  );
};

interface Props {
  displayOnCCHome: boolean;
  setDisplayOnCCHome: (boolean) => void;
}

export default DisplayOnCCHome;
