import { Route, Switch } from 'react-router-dom';
import { Remediation, ErrorsPreview, ErrorDetails} from '@octanner/core-data-ui';

const RemediationRoutes = () => {
  return (
    <Switch>
      <Route path={`/remediation`} component={Remediation} exact />
      <Route path={`/remediation/:id/errors`} component={ErrorsPreview} exact />
      <Route
        path={`/remediation/:id/errors/:errorTypeId`}
        component={ErrorDetails}
        exact
      />
    </Switch>
  );
};

export default RemediationRoutes;
