import { useState, useEffect } from 'react';
import {
  Tab,
  List,
  ListItem,
  Typography,
  IconButton,
  LinearProgress,
  Button,
  Divider,
} from '@octanner/prism-core';
import { useQuery } from '@apollo/client';
import { SEARCH_INITIATIVE_MIGRATIONS } from './graphql/queries';
import CircularProgress from '../../common/CircularProgress';
import { DateTime } from 'luxon';
import { ArrowRight as ArrowRightIcon } from '@octanner/prism-icons';
import { Link as RouterLink } from 'react-router-dom';
import { useStyles } from './styles';
import { Edges, MigrationNode } from './types';
import withStyles from '@material-ui/styles/withStyles';
import {
  tannerBlue,
  tannerGray,
} from '@octanner/prism-core/ThemeProvider/colors';

import { MigrationTabs } from './styles';
import colors from '../../common/constants/Colors';

const MigrationProgress = withStyles((theme) => ({
  colorPrimary: {
    backgroundColor: tannerGray['100'],
  },
  barColorPrimary: {
    backgroundColor: tannerBlue['500'],
  },
}))(LinearProgress);

const AllMigrations = () => {
  const classes = useStyles();
  const [value, setValue] = useState<string>('ACTIVE');

  const {
    data: searchInitiativeData,
    loading: searchInitiativeLoading,
    refetch: searchInitiativeRefetch,
    fetchMore: fetchMoreInitiativeMigration,
    stopPolling,
  } = useQuery(SEARCH_INITIATIVE_MIGRATIONS, {
    variables: { searchInput: { state: value } },
    fetchPolicy: 'network-only',
    pollInterval: 1500,
  });
  const searchInitiativeMigrationsData =
    searchInitiativeData?.ccSearchInitiativeMigrations?.initiativeMigrations
      ?.edges;
  const migrationsData = searchInitiativeMigrationsData;

  const sortMigrationsDataByDate = () => {
    if (migrationsData) {
      migrationsData.sort((a, b) => {
        return (
          new Date(
            b?.node?.migrationCompletedTsz || b?.node?.migrationFailedTsz
          ).getTime() -
          new Date(
            a?.node?.migrationCompletedTsz || a?.node?.migrationFailedTsz
          ).getTime()
        );
      });
    }
  };
  sortMigrationsDataByDate();
  const [emptyText, setEmptyText] = useState<string>('');
  const stalledTime = 15;

  const handleLoadMore = () => {
    fetchMoreInitiativeMigration({
      variables: {
        paginationInput: {
          after:
            searchInitiativeData?.ccSearchInitiativeMigrations
              ?.initiativeMigrations?.pageInfo?.endCursor,
        },
      },
    });
  };

  useEffect(() => {
    if (value === 'ACTIVE') {
      setEmptyText('No migrations currently in progress');
    } else if (value === 'COMPLETE') {
      setEmptyText('No recent migrations found');
    } else if (value === 'FAILED') {
      setEmptyText('No failed migrations found');
    }
  }, [value]);

  const tabChange = (newValue: string) => {
    setValue(newValue);
    searchInitiativeRefetch({ searchInput: { state: newValue } });
    sortMigrationsDataByDate();
  };

  const migrationTimeStamp = (when: string) => {
    return DateTime.fromISO(when).toLocaleString(DateTime.DATETIME_MED);
  };

  const checkStalledMigration = (node: MigrationNode) => {
    if (
      node?.migrationCompletedTsz === null &&
      node?.migrationFailedTsz === null
    ) {
      const modifiedTsz = node?.classicToCCInitiativesMigration?.modifiedTsz;
      if (modifiedTsz) {
        const time1 = new Date(modifiedTsz);
        const time2 = new Date();
        const diff = time2.getTime() - time1.getTime();
        const minDifference = Math.floor(diff / 1000 / 60);
        if (minDifference >= stalledTime) {
          return true;
        }
      } else {
        return true;
      }
    }
    return false;
  };

  const amountComplete = (node: MigrationNode) => {
    const stepsCompleted =
      node?.classicToCCInitiativesMigration?.migrationProgress?.stepsCompleted;
    const totalSteps =
      node?.classicToCCInitiativesMigration?.migrationProgress?.totalSteps;
    if (stepsCompleted && totalSteps) {
      return Math.floor((stepsCompleted / totalSteps) * 100);
    }
    return 0;
  };

  return (
    <>
      <MigrationTabs
        value={value}
        onChange={(event, newValue) => {
          tabChange(newValue);
        }}
        TabIndicatorProps={{
          style: {
            backgroundColor: colors.blue,
          },
        }}
      >
        <Tab
          label={'In Progress'}
          value={'ACTIVE'}
          data-testid={'active-tab'}
        />
        <Tab
          label={'Completed'}
          value={'COMPLETE'}
          data-testid={'complete-tab'}
        />
        <Tab label={'Failed'} value={'FAILED'} data-testid={'failed-tab'} />
      </MigrationTabs>

      {searchInitiativeLoading ? (
        <div className={classes.emptyText}>
          <CircularProgress />
        </div>
      ) : migrationsData &&
        searchInitiativeData?.ccSearchInitiativeMigrations?.initiativeMigrations
          ?.edges.length ? (
        <>
          <List>
            {migrationsData.map((edge: Edges) => {
              if (edge?.node) {
                const node = edge?.node;
                const type =
                  node.__typename === 'MigrationReport'
                    ? 'Data Migration'
                    : 'Initiatives Migration';
                return (
                  <RouterLink
                    to={`/migration/${node.coreCustomer?.customerUuid}`}
                    onClick={stopPolling}
                    className={classes.migrationLink}
                    key={node.coreCustomer?.customerUuid}
                  >
                    <ListItem>
                      <div className={classes.listItemText}>
                        <Typography
                          variant="h5"
                          data-testid={'client-name'}
                          className={classes.listheader}
                        >
                          {node.coreCustomer?.name + ' (' + type + ')'}
                        </Typography>
                        {node.migrationCompletedTsz !== null ? (
                          <Typography data-testid={'completed-message'}>
                            {'Completed ' +
                              migrationTimeStamp(node.migrationCompletedTsz)}
                          </Typography>
                        ) : node.migrationFailedTsz !== null ? (
                          <Typography
                            className={classes.failed}
                            data-testid={'failed-message'}
                          >
                            {'Failed ' +
                              migrationTimeStamp(node.migrationFailedTsz)}
                          </Typography>
                        ) : checkStalledMigration(node) ? (
                          <Typography data-testid={'stalled-message'}>
                            {'Stalled ' + amountComplete(node) + '% complete'}
                          </Typography>
                        ) : (
                          <Typography data-testid={'in-progress-message'}>
                            {amountComplete(node) + '% complete'}
                          </Typography>
                        )}
                      </div>
                      <IconButton className={classes.listButton}>
                        <ArrowRightIcon />
                      </IconButton>
                    </ListItem>
                    <Divider />
                    {node.migrationCompletedTsz === null &&
                      node.migrationFailedTsz === null && (
                        //@ts-ignore
                        <MigrationProgress
                          variant="determinate"
                          value={amountComplete(edge?.node)}
                          style={{ height: '4px', borderRadius: '4px' }}
                        />
                      )}
                  </RouterLink>
                );
              } else {
                return null;
              }
            })}
          </List>
        </>
      ) : (
        <div className={classes.emptyText}>{emptyText}</div>
      )}
      {searchInitiativeData?.ccSearchInitiativeMigrations?.initiativeMigrations
        ?.pageInfo?.hasNextPage && (
        <div className={classes.loadMore} data-testid={'load-more'}>
          <Button onClick={handleLoadMore} adminColor>
            Load More
          </Button>
        </div>
      )}
    </>
  );
};

export default AllMigrations;
