import {
  TextField,
  Autocomplete,
  Typography,
  MenuItem,
} from '@octanner/prism-core';
import { CustomerBase } from '../customer/models/Customer';
import { stateKeys, FormRowsImpersonate, ImpersonateHeader } from './types';
export const defaultState: stateKeys = {
  uniqueId: undefined,
  id: undefined,
  name: undefined,
  email: undefined,
  hideTerminated: true,
};

export const ImpersonateFormRows: FormRowsImpersonate[] = [
  {
    key: 'id',
    label: 'UUID',
    dataTest: 'impersonate:search:uuid',
    size: 6,
  },
  {
    key: 'uniqueId',
    label: 'Client',
    dataTest: 'impersonate:search:name',
    size: 6,
    customComponent: function autocompleteUUID({
      handleCustomerSelection,
      loading,
      customers,
      customer,
      handleTextChange,
    }) {
      return (
        <Autocomplete
          loading={loading}
          options={customers}
          value={customer || null}
          isOptionEqualToValue={(a: CustomerBase, b: CustomerBase) =>
            a.id === b.id
          }
          onChange={handleCustomerSelection}
          getOptionLabel={(c: any) =>
            `${c.name} (${c.id}) ${c.stpNumber || ''}`
          }
          renderOption={(props: any, options: CustomerBase) => (
            <MenuItem
              key={options.stp + options.id}
              {...props}
              data-test={`impersonate:search:customer-selector:customer:${options.id}`}
            >
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography>{options.name}</Typography>
                <Typography color="textSecondary">
                  STP: {options.stpNumber || '-'}
                </Typography>
                <Typography color="textSecondary">
                  Core ID: {options.id}
                </Typography>
              </div>
            </MenuItem>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              label="Client"
              onChange={(e) => handleTextChange(e.target.value, customers)}
              placeholder="Search by Name, ID, or STP"
              InputProps={{
                ...params.InputProps,
                endAdornment: null,
              }}
              inputProps={{
                ...params.inputProps,
                'data-test': 'impersonate:search:customer-search',
              }}
            />
          )}
        />
      );
    },
  },
  {
    key: 'uniqueId',
    label: 'Employee ID',
    dataTest: 'impersonate:search:uniqueId',
    size: 6,
  },
  {
    key: 'email',
    label: 'Email Address',
    dataTest: 'impersonate:search:email',
    size: 6,
  },
  {
    key: 'name',
    label: 'Name',
    dataTest: 'impersonate:search:name',
    size: 12,
  },
];

export const impersonateHeaders: ImpersonateHeader[] = [
  { id: 'firstName', label: 'First name' },
  { id: 'lastName', label: 'Last name' },
  { id: 'email', label: 'Email' },
  { id: 'uniqueId', label: 'Employee ID' },
  { id: 'customerId', label: 'Client' },
  { id: 'employeeSecurityRole', label: 'Security Role' },
];
