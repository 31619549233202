import { MigrationItemProps } from './types';

export const PreInitiativeMigrationItems = (
  initiativesEnabled: boolean | undefined,
  coreMigrationCompleted: boolean | undefined
) => {
  const PreInitiativeMigrationCheck: MigrationItemProps[] = [
    {
      type: 'preMigration',
      status: initiativesEnabled,
      itemName: 'Check if Classic Initiatives is enabled',
      key: 'initiativesEnabled',
      errorMessage:
        'Something happened. Please try again, or reach out to Initiatives Team.',
    },
    {
      type: 'preMigration',
      status: initiativesEnabled ? coreMigrationCompleted : undefined,
      itemName: 'Core Migration Completed',
      key: 'coreMigrationCompleted',
      errorMessage:
        'Data Migration for core is not Completed wait till Data migration is done',
    },
  ];
  return PreInitiativeMigrationCheck;
};

export const InitiativeDataMigrationItems = (
  migrationCompleted: boolean | undefined
) => {
  const PreInitiativeMigrationCheck: MigrationItemProps[] = [
    {
      type: 'migration',
      status: migrationCompleted,
      itemName: 'Initiative Data',
      key: 'initiativesData',
    },
    {
      type: 'migration',
      status: migrationCompleted,
      itemName: 'Initiative Participant Data',
      key: 'initiativeParticipantsData',
    },
    {
      type: 'migration',
      status: migrationCompleted,
      itemName: 'Initiative Owner Data',
      key: 'initiativeOwnersData',
    },
    {
      type: 'migration',
      status: migrationCompleted,
      itemName: 'Initiative Request Data',
      key: 'initiativeRequestsData',
    },
    {
      type: 'migration',
      status: migrationCompleted,
      itemName: 'Communication config Data',
      key: 'communicationConfData',
    },
    {
      type: 'migration',
      status: migrationCompleted,
      itemName: 'Awards Data',
      key: 'awardsData',
    },
    {
      type: 'migration',
      status: migrationCompleted,
      itemName: 'Award Config Data',
      key: 'awardConfigData',
    },
  ];
  return PreInitiativeMigrationCheck;
};
