import { Checkbox, Tooltip, Typography } from '@octanner/prism-core';
import {
  ActionText,
  FlexTypo8,
  NoActivePermContainer,
  PermissionContainer,
  PermissionHeader,
  PermissionName,
  RightFlexSpace,
  SpacedGroups,
  SubCategoryContainer,
  SubCategoryHeader,
  TextContainer,
} from './styles';
import { PermissionSubCategory } from '../types';
import { InformationCircle } from '@octanner/prism-icons';
import { NoPermCheckbox } from '../styles';
import { tannerGray } from '@octanner/prism-core/ThemeProvider/colors';
import { ChangeEvent } from 'react';
import { Permission } from '../../types';

export const NoSelectionContainer = ({
  header,
  text,
}: {
  header: string;
  text: string;
}) => {
  return (
    <NoActivePermContainer>
      <TextContainer>
        <Typography variant="h3">{header}</Typography>
        <Typography>{text}</Typography>
      </TextContainer>
    </NoActivePermContainer>
  );
};

export const SubCategoriesView = ({
  subCategories,
  selectedPermissions,
  onPermissionSelect,
  onPermissionDeselect,
}: {
  subCategories: PermissionSubCategory[];
  selectedPermissions: Permission[];
  onPermissionSelect: (permission: Permission) => void;
  onPermissionDeselect: (permission: Permission) => void;
}) => {
  const handleCheckboxChange =
    (permission: Permission) => (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.checked) {
        onPermissionSelect(permission);
      } else {
        onPermissionDeselect(permission);
      }
    };
  return (
    <SubCategoryContainer>
      {subCategories.map((sub, index) => (
        <SpacedGroups key={`sub_${sub.uuid}`} noPad={index === 0}>
          <SubCategoryHeader>
            <FlexTypo8 variant="h3">{sub.name}</FlexTypo8>
            <RightFlexSpace>
              <ActionText variant="h3">Read-only</ActionText>
              <ActionText variant="h3">Modify</ActionText>
            </RightFlexSpace>
          </SubCategoryHeader>

          {sub.permissionGroups.map((group) => (
            <PermissionContainer key={`group_${group.uuid}`}>
              <PermissionHeader>
                <PermissionName>
                  <Typography>{group.name}</Typography>
                  <Tooltip title={group.description}>
                    <InformationCircle sx={{ color: tannerGray[400] }} />
                  </Tooltip>
                </PermissionName>
                <RightFlexSpace>
                  {group.readPermission ? (
                    <Checkbox
                      data-test={`group:${group.uuid}:read`}
                      checked={selectedPermissions.some(
                        (perm) => perm.uuid === group.readPermission!.uuid
                      )}
                      size="medium"
                      onChange={handleCheckboxChange(group.readPermission)}
                    />
                  ) : (
                    <NoPermCheckbox data-test={`group:${group.uuid}:noread`} />
                  )}
                  {group.writePermission ? (
                    <Checkbox
                      data-test={`group:${group.uuid}:write`}
                      checked={selectedPermissions.some(
                        (perm) => perm.uuid === group.writePermission!.uuid
                      )}
                      size="medium"
                      onChange={handleCheckboxChange(group.writePermission)}
                    />
                  ) : (
                    <NoPermCheckbox data-test={`group:${group.uuid}:nowrite`} />
                  )}
                </RightFlexSpace>
              </PermissionHeader>
            </PermissionContainer>
          ))}
        </SpacedGroups>
      ))}
    </SubCategoryContainer>
  );
};
