import { gql } from "@apollo/client";

export const FILTER_BY_STRING_ID = gql`
query LsFetchLanguageStringUsingStringID($stringId: PositiveInt!) {
  lsFetchLanguageStringUsingStringID(stringId: $stringId) {
    text
    stringId
    translationList {
      locale
      localeDescription
      translationString
      translationStatus
    }
    enableVoiceDescriptionInd
    notes
    requestedByName
    soldToParty
  }
}
`;

export const SEARCH_BY_STRING_OR_ID = gql`
query lsFetchLanguageStringListUsingSearchFilters($stringId: PositiveInt, $stringText: String, $searchLocale: Locale, $searchConstraint: StringComparisonConstraintType, $ignoreCaseCheckInd: Boolean, $pageInfo: PaginationInfoInput) {
  lsFetchLanguageStringListUsingSearchFilters(
    stringId: $stringId, 
    stringText: $stringText, 
    searchLocale: $searchLocale, 
    searchConstraint: $searchConstraint, 
    ignoreCaseCheckInd: $ignoreCaseCheckInd, 
    pageInfo: $pageInfo) {
    pageInfo {
        totalPageCount
        totalItemCount
    }
    languageStringList {
      text
      stringId
      translationList {
        localeDescription
        translationString
        translationStatus
      }
      enableVoiceDescriptionInd
      notes
      requestedByName
    }
  }
}
`;

export const SEARCH_CUSTOM_TEXT = gql`
query lsFetchCustomTextListUsingSearchFilters($stringId: Long, $requesterName: String, $requesterEmail: String, $soldToPartyNumber: String, $durationType: DurationType, $pageInfo: PaginationInfoInput = {}) {
  lsFetchCustomTextListUsingSearchFilters(
    stringId: $stringId,
    requesterName: $requesterName, 
    requesterEmail: $requesterEmail, 
    soldToPartyNumber: $soldToPartyNumber, 
    durationType: $durationType, 
    pageInfo: $pageInfo) {
      customTextList {
          stringId
          text
          locale
          soldToPartyNumber
          sourceText
          sourceLocale
          requestedByName
          requestedByEmail
          requestRaisedDate
          programId
          translationJob {
              jobNumber
              sentToTranslationDate
              backFromTranslationDate
          }
      }
      pageInfo {
          totalItemCount
          totalPageCount
      }
    }
}
`;

export const SAVE_NOTES = gql`
mutation LsUpsertLanguageStringNotes($input: UpsertLanguageStringNotesInput!) {
    lsUpsertLanguageStringNotes(input: $input) {
      successMessage    
    }
  }
`;

export const ENABLE_VOICE_DESCRIPTION = gql`
mutation LsToggleVoiceDescription($input: ToggleVoiceDescriptionInput!) {
  lsToggleVoiceDescription(input: $input) {
    successMessage
  }
}
`;

export const REQUEST_TRANSLATION = gql`
  mutation LsRaiseTranslationRequestForLanguageString($input: RaiseTranslationRequestForLanguageStringInput!) {
    lsRaiseTranslationRequestForLanguageString(input: $input) {
      successMessage
    }
  }
`;

export const INSERT_CORRECTION = gql`
mutation LsUpsertLanguageStringForLocale($input: UpsertLanguageStringForLocaleInput!) {
  lsUpsertLanguageStringForLocale(input: $input) {
    successMessage
  }
}
`;

export const INSERT_LANGUAGE_US_ENGLISH = gql`
mutation LsCreateLanguageStringInUSEnglishLocale($input : CreateLanguageStringInput!) {
  lsCreateLanguageStringInUSEnglishLocale(input: $input){
    successMessage
    languageString {
      stringId
      translationList {
        locale
        translationString
      }
    }
  }
}
`;

export const INSERT_CUSTOM_TEXT = gql`
mutation lsCreateCustomText($input: CreateCustomTextInput!) {
  lsCreateCustomText(input: $input) {
    customTextRequestUniqueId
    customTextStringIdList
  }
}
`;

export const CORE_LOCALES = gql`
query GetCoreLocales {
  getCoreLocales {
    coreLocales {
      nodes {
        code
        languageDescription
        countryDescription
      }
    }
  }
}`;

export const MATERIAL_DESC_USING_SEARCH_FILTER = gql`
  query LsFetchMaterialDescriptionListUsingSearchFilter($mmId: Long, $searchText: String, $searchMdField: MDField, $searchLocale: Locale,$pageInfo: PaginationInfoInput = {}, $searchConstraint: StringComparisonConstraintType, $ignoreCaseCheckInd: Boolean ){
    lsFetchMaterialDescriptionListUsingSearchFilter(searchText:$searchText,pageInfo: $pageInfo, searchMdField: $searchMdField, searchLocale: $searchLocale,  mmId: $mmId, searchConstraint: $searchConstraint, ignoreCaseCheckInd: $ignoreCaseCheckInd) {
      materialDescriptionList {
          mmId
          descriptionList {
            text
            fieldType
            translationStatus
          }
          descriptionLocale
          notes
      }
      pageInfo {
          totalPageCount
          totalItemCount
      }
    }
  }
`;

export const MATERIAL_DESC_USING_MMID = gql`
  query LsFetchMaterialDescriptionDetailUsingMmId($mmId: Long!, $descriptionLocale: Locale){
    lsFetchMaterialDescriptionDetailUsingMmId(mmId: $mmId, descriptionLocale:$descriptionLocale){
     mmId
     descriptionList {
      text
      fieldType
      translationStatus
     }
     descriptionLocale
     descriptionLocalesValid{
      code
     }
     notes
    }
  }
`;

export const UPSERT_MATERIAL_DESCRIPTION = gql`
  mutation LsUpsertMaterialDescription($input: UpsertMaterialDescriptionInput!){
    lsUpsertMaterialDescription(input: $input) {
      materialDescription {
        mmId
        descriptionList {
          text
          fieldType
          translationStatus
        }
        notes
        descriptionLocalesAvailable {
          code
        }
      }
      successMessage
      updatedDescriptionTypes
    }
  }
`;

export const FETCH_UNTRANSLATED_STRING_COUNT = gql`
  query lsFetchUntranslatedStringCountForAllLocale($locale: Locale) {
   lsFetchUntranslatedStringCountForAllLocale(locale: $locale) {
      locale
      languageStringCount
      customTextCount
      materialDescriptionCount
    }
  }
`;

export const FETCH_UNTRANSLATED_STRING_LIST = gql`
  query lsFetchUntranslatedStringListForLocaleAndCategory($locale: Locale!, $translationCategory: TranslationCategory!,$stringId: Long, $mmId: Long,
    $pageInfo: PaginationInfoInput = {}) {
    lsFetchUntranslatedStringListForLocaleAndCategory(locale: $locale, translationCategory: $translationCategory, pageInfo: $pageInfo,
    stringId: $stringId, mmId: $mmId
    ) {
      untranslatedStringList {
        ... on LanguageString {
          stringId
          text
          requestedByName
          onHoldInd
          requestStatus {
              uniqueId
              createdDate
          }
        } 
        ... on CustomText {
          stringId
          sourceText
          requestedByName
          onHoldInd
          sourceLocale
          requestStatus {
              uniqueId
              createdDate
          }
        }
        ... on MaterialDescription {
          sapPartNumber
          sourceText
          fieldType
          onHoldInd
          requestedByName
           requestStatus {
              uniqueId
              createdDate
          }
        }
      }
      pageInfo {
        totalPageCount
        totalItemCount
     }
    }
  } 
`;

export const DELETE_TRANSLATION_REQUEST = gql`
  mutation lsDeleteTranslation($input: RushOrDeleteTranslationInput!) {
    lsDeleteTranslation(input: $input) {
      successMessage
    }
  }
`;

export const REQUEST_TRANSLATION_MATERIAL_DESCRIPTION = gql`
  mutation LsRaiseTranslationRequestForMaterialDescription($input: RaiseTranslationRequestForMaterialDescriptionInput!){
    lsRaiseTranslationRequestForMaterialDescription(input: $input) {
      mmId
      successMessage
    }
  }
`;

export const CREATE_JOB = gql`
  mutation lsRushTranslation($input: RushOrDeleteTranslationInput!) {
    lsRushTranslation(input: $input) {
        successMessage
        jobNumber
    }
  }
`;

export const TOGGLE_HOLD_TRANSLATION = gql`
  mutation lsToggleHoldTranslation($input: ToggleHoldTranslationInput!) {
    lsToggleHoldTranslation(input: $input) {
        successMessage
    }
  }
`;

export const DELETE_CUSTOM_TEXT = gql`
  mutation LsDeleteCustomText($input: DeleteCustomTextInput!){
    lsDeleteCustomText(input : $input){
      customTextRequestUniqueId
      successMessage
    }
  }
`;

export const UPDATE_CUSTOM_TEXT = gql`
  mutation lsUpdateCustomText($input: CustomTextUpdateInput!){
    lsUpdateCustomText(input: $input){
      successMessage
    }
  }
`;

export const FETCH_CUSTOM_TEXT_BY_ID = gql`
  query LsFetchCustomTextStringUsingStringID($stringId: Long!, $targetLocale: Locale){
    lsFetchCustomTextStringUsingStringID(stringId : $stringId, targetLocale: $targetLocale) {
      customTextRequest {
        uniqueId
        requesterName
        requesterEmail
        requestRaisedDate
        soldToPartyNumber
        sourceLocale
        programId
        notes
      }
      customTextList {
        stringId
        text
        locale
        onHoldInd
        briefIdentifierText
        sourceText
        sourceLocale
        requestedByName
        requestedByEmail
        soldToPartyNumber
        requestRaisedDate,
        translationStatus
      }
      targetLocaleList
    }
  }
`;

export const JOB_LIST_BY_FILTER = gql`
  query lsFetchJobList($translationCategory: TranslationCategory!,
    $jobNumber: Long,
    $durationType: DurationType,
    $translationStatus: JobSearchType!,
    $locale: Locale,
    $stringId: Long,
    $mmId: Long,
    $pageInfo: PaginationInfoInput) {
      lsFetchJobList(translationCategory: $translationCategory,
      jobNumber: $jobNumber,
      durationType: $durationType,
      translationStatus: $translationStatus,
      locale: $locale,
      stringId: $stringId,
      mmId: $mmId,
      pageInfo: $pageInfo) {
        translationJobList {
          jobNumber
          locale
          downloadedDate
          sentToTranslationDate
          backFromTranslationDate
        }
        pageInfo {
          totalItemCount
          totalPageCount
        }
      }
  }
`;

export const FETCH_JOB_DETAILS = gql`
query lsFetchStringDetailsForJob(
  $translationCategory: TranslationCategory!,
  $jobNumber: Long!,
  $stringId: Long,
  $mmId: Long,
  $pageInfo: PaginationInfoInput = {}) {
    lsFetchStringDetailsForJob(
    translationCategory: $translationCategory,
    jobNumber: $jobNumber,
    stringId: $stringId,
    mmId: $mmId,
    pageInfo: $pageInfo) {
     untranslatedStringList {
       ... on LanguageString {
        stringId
        text
        requestedByName
      }
      ... on CustomText {
        stringId
        text
        requestedByName
      }
      ... on MaterialDescription {
        sapPartNumber
        sourceText
        requestedByName
      }
   }
   pageInfo {
      totalPageCount
      totalItemCount
   }
 }
}
`;

export const DELETE_JOB = gql`
  mutation lsCancelJob($input: JobInfoInput) {
    lsCancelJob(input: $input) {
      successMessage
    }
  }
`;

export const SEND_FOR_TRANSLATION_VIA_MAIL = gql`
  mutation lsSendTranslationFileViaMail($input: SendTranslationFileViaMailInput) {
    lsSendTranslationFileViaMail(input: $input) {
      successMessage
    }
  }
`;

export const CUSTOM_TEXT_RAISE_TRANSLATION_REQUEST = gql`
mutation lsRaiseTranslationRequestForCustomText($input: RaiseTranslationRequestForCustomTextsInput!) {
  lsRaiseTranslationRequestForCustomText(input: $input) {
    successMessage
  }
}`