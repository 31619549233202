import { useQuery, useMutation } from '@apollo/client';
import { useDebounce } from 'use-debounce';
import {
  CreateCustomerInput,
  CreateCustomerResponse,
  GetCustomersResponse,
  SearchCustomersInput,
} from '../../customer/models/Customer';
import { RequireCustomerId } from '../../user-auth/models/Role';
import {
  CREATE_CUSTOMER,
  DELETE_CUSTOMER,
  SEARCH_CUSTOMERS,
} from '../graphql/customer';

export const useCreateCustomer = () => {
  const [addCustomer] = useMutation<
    CreateCustomerResponse,
    CreateCustomerInput
  >(CREATE_CUSTOMER);
  return addCustomer;
};

export const useSearchCustomers = (
  pagination: {
    pageSize: number;
    pageNumber: number;
  } = { pageSize: 0, pageNumber: 0 },
  search?: string
) => {
  const [searchText] = useDebounce(search?.trim() || '', 250);
  const query = useQuery<GetCustomersResponse, SearchCustomersInput>(
    SEARCH_CUSTOMERS,
    {
      variables: { searchText, pagination },
      skip: !searchText,
    }
  );
  return query;
};

export const useDeleteCustomer = () => {
  const [deleteCustomer] = useMutation<
    { deleteCustomer: string },
    RequireCustomerId
  >(DELETE_CUSTOMER);
  return deleteCustomer;
};
