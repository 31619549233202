import React, { useState, useEffect } from 'react';
import { Tab, BadgeSecondary, Alert } from '@octanner/prism-core';
import { useTranslation } from 'react-i18next';
import { TabsHeader, MigrationTabs } from '../salesOrg/style';
import ClientFinancialInfos from '../clientFinancialInfo/ClientFinancialInfo';
import Languages from '../languages/Languages';
import SalesOrgLoading from '../salesOrg/SalesOrgLoading';
import { DetailsProps } from './types';
import Confirmation from './Confirmation';
import {
  MainContainer,
  ToolTitle,
  ClientDetailsContainer,
  Container,
  AlertContainer,
} from './styles';
import { DisplayClientInfo } from './DisplayClientInfo';
import EditClientInfo from './EditClientInfo';
import { AlertWarning } from './AlertWarning';
import CoreValuesLoading from '../coreValues/CoreValuesLoading';
import Applications from '../Applications/Applications';

export const Details: React.FC<DetailsProps> = ({
  dataCustomerName,
  refetchCustomerName,
  customerId,
  setRefetchEditCustomer,
  ccAdminEnableComboModel,
  historyLocationProp,
  enableApplicationsTab,
}) => {
  const{
    customerUsage
  } = dataCustomerName?.customer;
  const { t } = useTranslation();
  const [
    errorClientFinancialDetails,
    setErrorClientFinancialDetails,
  ] = useState(false);
  const [errorCountrySalesOrgSetup, setErrorCountrySalesOrgSetup] = useState(
    false,
  );
  const [errorLanguages, setErrorLanguages] = useState(false);
  const [clientAddressWarning, setClientAddressWarning] = useState(false);
  const [clientSalesOrgWarning, setClientSalesOrgWarning] = useState(false);
  const [clientLocaleWarning, setClientLocaleWarning] = useState(false);
  const [registerSapErrorDispaly, setRegisterSapErrorDispaly] = useState(false);
  const [showUpdateSapError, setUpdateSapError] = useState(false);
  const [updateSapErrorMessages, setUpdateSapErrorMessages] = useState('');
  const TabHeaderValue = [
    {
      label: t('client_financial_details'),
      value: 'ClientFinancialInfo',
      errorCnt: errorClientFinancialDetails,
    },
    {
      label: t('country_sales_org_setup'),
      value: 'CountryAndSalesOrgSetup',
      errorCnt: errorCountrySalesOrgSetup,
    },
    {
      label: t('languages'),
      value: 'DefaultCountryAndLanguages',
      errorCnt: errorLanguages,
    },
    {
      label: t('core_values'),
      value: 'CoreValues',
      errorCnt: false,
    },
  ];

  enableApplicationsTab && TabHeaderValue.push({
    label: t('Applications'),
    value: 'Applications',
    errorCnt: false,
  });

  const [companyDetail, setcompanyDetail] = useState(true);
  const [saveButtonLoading, setSaveButtonLoading] = useState(false);
  const [confirmationData, setConfirmationData] =
  useState({ showDialog: false, activeTabIndex: 0, switchTab: false, newTabIndex: 0, editMode: false });
  const [errorMessage , setErrorMessage] = useState('');
  const [errorsDisplayNumber, setErrorsDisplayNumber] = useState({
    ClientFinancialInfo: 0,
    CountryAndSalesOrgSetup: 0,
    DefaultCountryAndLanguages: 0,
    InvoiceDetails: 0,
  });

  const [checkedSalesOrg, setCheckedSalesOrg] = useState([]);
  const [customerInfo, setCustomerInfo] = useState(undefined);
  const [calledRefetch, setCalledRefetch] = useState(false);
  const [clientType, setClientType] = useState(customerUsage);

  const [isMoveToActive, setIsMoveToActive] = useState(false);
  const [statusText, setStatusText] = useState(t('moveToActive'));

  const [tabsEditModeStatus, setTabsEditModeStatus] = useState({
    ClientFinancialInfo: false,
    CountryAndSalesOrgSetup: false,
    DefaultCountryAndLanguages: false,
    InvoiceDetails: false,
  });

  useEffect(() => {
    errorsDisplayNumber.ClientFinancialInfo > 0 ||
    errorsDisplayNumber.InvoiceDetails > 0
      ? setErrorClientFinancialDetails(true)
      : setErrorClientFinancialDetails(false);
    errorsDisplayNumber.CountryAndSalesOrgSetup > 0
      ? setErrorCountrySalesOrgSetup(true)
      : setErrorCountrySalesOrgSetup(false);
    errorsDisplayNumber.DefaultCountryAndLanguages > 0
      ? setErrorLanguages(true)
      : setErrorLanguages(false);
  }, [errorsDisplayNumber, confirmationData.activeTabIndex]);
  useEffect(() => {
    if (!confirmationData.showDialog && confirmationData.switchTab) {
      setConfirmationData({
        ...confirmationData,
        activeTabIndex: confirmationData.newTabIndex,
        switchTab: false,
      });
    }
  }, [confirmationData.showDialog]);

  const handleUpdateSapError = (currentSapError : string) => {
    if(currentSapError === ''){
      setUpdateSapErrorMessages('');setUpdateSapError(false);
    }else{
      if(currentSapError?.includes('CustomerData API:')){
        setUpdateSapErrorMessages(currentSapError?.substring(currentSapError?.indexOf('CustomerData API:')+17, currentSapError?.length));
      }else if(currentSapError?.includes('OCT Permission not match')){
        setUpdateSapErrorMessages(t('unauthorized_error'));
      } else {
        setUpdateSapErrorMessages(t('apiErrorMessage'));
        setUpdateSapError(true);
      }
      setUpdateSapError(true);
    }
  }


  const handleOnChangeTabs = (tabIndex) => {
    if(!confirmationData.editMode){
      setConfirmationData({...confirmationData, activeTabIndex: tabIndex , showDialog : false});
    }else{
      setConfirmationData({...confirmationData, newTabIndex: tabIndex , showDialog : true});
    }

  }
  const BadgeSecondaryComponent = (props) => {
    return <BadgeSecondary variant="error">{props.errorCount}</BadgeSecondary>;
  };

  const TabLabel = ({ label }) => {
    return (
      <>
        {label === t('client_financial_details') && (clientAddressWarning || (errorsDisplayNumber.ClientFinancialInfo > 0 || errorsDisplayNumber.InvoiceDetails>0)) &&
          (<BadgeSecondaryComponent errorCount={errorsDisplayNumber.ClientFinancialInfo + errorsDisplayNumber.InvoiceDetails } />)}
        {label === t('country_sales_org_setup') && (clientSalesOrgWarning || errorsDisplayNumber.CountryAndSalesOrgSetup > 0) &&
          (<BadgeSecondaryComponent errorCount={errorsDisplayNumber.CountryAndSalesOrgSetup} />)}
        {label === t('languages') && (clientLocaleWarning || errorsDisplayNumber.DefaultCountryAndLanguages > 0) &&
          (<BadgeSecondaryComponent errorCount={errorsDisplayNumber.DefaultCountryAndLanguages} />)}
      </>
    );
  };
  const [showStatus, setShowStatus] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  return (
    <MainContainer>
      {confirmationData.showDialog && (
        <Confirmation
          confirmationData={confirmationData}
          onClose={(currentConfirmationData) =>
            setConfirmationData(currentConfirmationData)
          }
          errorsDisplayNumber={errorsDisplayNumber}
          setErrorsDisplayNumber={setErrorsDisplayNumber}
          setUpdateSapError={setUpdateSapError}
        />
      )}
      <div>
        <ToolTitle variant="h1">{t('client_configuration')}</ToolTitle>
        <ClientDetailsContainer>
          <Container>
            {companyDetail && (
              <DisplayClientInfo
                setcompanyDetail={setcompanyDetail}
                dataCustomerName={dataCustomerName}
                showStatus={showStatus}
                setShowStatus={setShowStatus}
                setShowWarning={setShowWarning}
                setSapErrorDispaly={setRegisterSapErrorDispaly}
                setRefetchEditCustomer={setRefetchEditCustomer}
                setErrorMessage={setErrorMessage}
                confirmationData={confirmationData}
                tabsEditModeStatus={tabsEditModeStatus}
                setTabsEditModeStatus={setTabsEditModeStatus}
                errorsDisplayNumber={errorsDisplayNumber}
                setCheckedSalesOrg={setCheckedSalesOrg}
                setCustomerInfo={setCustomerInfo}
                setCalledRefetch={setCalledRefetch}
                setIsMoveToActive={setIsMoveToActive}
                isMoveToActive={isMoveToActive}
                setStatusText={setStatusText}
                statusText={statusText}
                clientType={clientType}
                setClientType={setClientType}
                customerId={customerId}
              />
            )}
            {!companyDetail && (
              <EditClientInfo
                dataCustomerName={dataCustomerName}
                setcompanyDetail={setcompanyDetail}
                saveButtonLoading={saveButtonLoading}
                setSaveButtonLoading={setSaveButtonLoading}
                refetchCustomerName={refetchCustomerName}
                customerId={customerId}
                onSapUpdateError={handleUpdateSapError}
                setShowStatus={setShowStatus}
                setIsMoveToActive={setIsMoveToActive}
                statusText={statusText}
                clientType={clientType}
                setClientType={setClientType}
                setRefetchEditCustomer={setRefetchEditCustomer}
                setStatusText={setStatusText}
                showStatus={showStatus}
                setShowWarning={setShowWarning}
                setSapErrorDispaly={setRegisterSapErrorDispaly}
                setErrorMessage={setErrorMessage}
                confirmationData={confirmationData}
                tabsEditModeStatus={tabsEditModeStatus}
                setTabsEditModeStatus={setTabsEditModeStatus}
                errorsDisplayNumber={errorsDisplayNumber}
                setCustomerInfo={setCustomerInfo}
                setCalledRefetch={setCalledRefetch}
                isMoveToActive={isMoveToActive}
              />
            )}
          </Container>
        </ClientDetailsContainer>
      </div>
    {showWarning && (
      <AlertContainer>
        <AlertWarning
          showWarning={showWarning}
          clientAddressWarning={clientAddressWarning}
          setClientAddressWarning={setClientAddressWarning}
          clientSalesOrgWarning={clientSalesOrgWarning}
          setClientSalesOrgWarning={setClientSalesOrgWarning}
          clientLocaleWarning={clientLocaleWarning}
          setClientLocaleWarning={setClientLocaleWarning}
          onSave={(currentErrorDisplayNumber) => setErrorsDisplayNumber({ ...currentErrorDisplayNumber })}
          errorsDisplayNumber={errorsDisplayNumber}
          checkedSalesOrg={checkedSalesOrg}
          customerInfo={customerInfo}
          calledRefetch={calledRefetch}
        />
        </AlertContainer>
      )}
      {showUpdateSapError && (
        <AlertContainer>
          <Alert
            severity="error"
            onClose={() => setUpdateSapError(false)}
            style={{ marginTop: '8px' }}
          >
            {updateSapErrorMessages}
          </Alert>
        </AlertContainer>
      )}
      {registerSapErrorDispaly && (
        <AlertContainer>
          <Alert
            onClose={() => setRegisterSapErrorDispaly(false)}
            style={{ marginTop: '8px' }}
            severity="error"
            >
              {
              errorMessage?.includes('CustomerData API:') ? (
                errorMessage?.substring(errorMessage?.indexOf('CustomerData API:')+17, errorMessage?.length)
              ) : (errorMessage)
              }
            </Alert>
            </AlertContainer>
        )
      }
      <MigrationTabs
        value={confirmationData.activeTabIndex}
        onChange={(event, tabIndex) => handleOnChangeTabs(tabIndex)}
        TabIndicatorProps={{
          style: {
            backgroundColor: '#0066ED',
          },
        }}
      >
        {TabHeaderValue.map(({ label, errorCnt }, index) => (
          <Tab
            key={index}
            value={index}
            label={
              <TabsHeader>
                {label} {errorCnt && <TabLabel label={label} />}
              </TabsHeader>
            }
          />
        ))}
      </MigrationTabs>
      <div>
        {confirmationData.activeTabIndex === 0 && (
          <ClientFinancialInfos
            confirmationData={confirmationData}
            onTabSwitch={(currentConfirmationData) =>
              setConfirmationData({ ...currentConfirmationData })
            }
            errorsDisplayNumber={errorsDisplayNumber}
            onSave={(currentErrorDisplayNumber) =>
              setErrorsDisplayNumber({ ...currentErrorDisplayNumber })
            }
            clientAddressWarning={clientAddressWarning}
            onSapUpdateError={handleUpdateSapError}
            tabsEditModeStatus={tabsEditModeStatus}
            setTabsEditModeStatus={setTabsEditModeStatus}
            setClientAddressWarning={setClientAddressWarning}
            setUpdateSapError={setUpdateSapError}
            dataCustomerName={dataCustomerName}
          />
        )}
        {confirmationData.activeTabIndex === 1 && (
          <SalesOrgLoading
            confirmationData={confirmationData}
            onTabSwitch={(currentConfirmationData) =>
              setConfirmationData({ ...currentConfirmationData })
            }
            errorsDisplayNumber={errorsDisplayNumber}
            onSave={(currentErrorDisplayNumber) =>
              setErrorsDisplayNumber({ ...currentErrorDisplayNumber })
            }
            clientSalesOrgWarning={clientSalesOrgWarning}
            onSapUpdateError={handleUpdateSapError}
            showWarning={showWarning}
            tabsEditModeStatus={tabsEditModeStatus}
            setTabsEditModeStatus={setTabsEditModeStatus}
            setClientSalesOrgWarning={setClientSalesOrgWarning}
            ccAdminEnableComboModel={ccAdminEnableComboModel}
          />
        )}
        {confirmationData.activeTabIndex === 2 && (
          <Languages
            confirmationData={confirmationData}
            onTabSwitch={(currentConfirmationData) =>
              setConfirmationData({ ...currentConfirmationData })
            }
            errorsDisplayNumber={errorsDisplayNumber}
            onSave={(currentErrorDisplayNumber) =>
              setErrorsDisplayNumber({ ...currentErrorDisplayNumber })
            }
            onSapUpdateError={handleUpdateSapError}
            tabsEditModeStatus={tabsEditModeStatus}
            setTabsEditModeStatus={setTabsEditModeStatus}
            setUpdateSapError={setUpdateSapError}
          />
        )}
        {confirmationData.activeTabIndex === 3 && (
          <CoreValuesLoading
            onPermissionError={handleUpdateSapError}
            setUpdateSapError={setUpdateSapError}
          />
        )}
        {confirmationData.activeTabIndex == 4 && <Applications customerId={customerId} historyLocationProp={historyLocationProp}/>}
      </div>
    </MainContainer>
  );
};
