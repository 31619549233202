import { useLazyQuery, useMutation } from '@apollo/client';
import styled from '@emotion/styled';
import {
  Button, Checkbox, CircularProgress, Grid, MenuItem, Select, Table,
  TableBody, TableCell, TableHead, TableRow, Typography
} from '@octanner/prism-core';
import { GraphQLError } from 'graphql';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Footer from '../../Components/Common/Footer';
import { LocaleContext } from '../../Contexts/LocaleContext';
import { MaterialStringsContext } from '../../Contexts/MaterialStringsContext';
import { MmdCharLimitForeignLocales } from '../../utils/constants';
import { defaults } from '../../utils/default';
import { MATERIAL_DESC_USING_MMID, UPSERT_MATERIAL_DESCRIPTION } from '../../utils/query';
import { Locale, MaterialDescription, MaterialDescriptionPayload, MaterialMasterDescription } from '../../utils/types';
import EditAndRequestDescriptionModal from './EditAndRequestDescriptionModal';
import MaterialDescriptionDetails from './MaterialDescriptionDetails';
import MaterialDescriptionRow from './MaterialDescriptionRow';

const TableContainer = styled.div`
  margin-top: 24px;
`;

const ActionCell = styled(TableCell)`
  width: 10%;
	text-align: center;
`;

const StyledCircularProgress = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  height: 60vh;
`;

const ButtonAndStpGrid = styled(Grid)`
  display: flex;
  align-items: center;
`;

const MaterialDescriptionTable = () => {

  const history = useHistory();

  const { showFooter, setShowFooter, setMessageText, getMaterialDescriptionByFilters, setOpenEditEnglishDescriptionModal } = useContext(MaterialStringsContext);
  const { localeMap } = useContext(LocaleContext);
  const [updateMaterialDescription, { loading: updatingMaterialDescription }] = useMutation(UPSERT_MATERIAL_DESCRIPTION, {
    onError: () => {
      setMessageText('errorMessage', defaults.update_error);
    }
  });

  const { mmId } = useParams();
  const [requestAll, setRequestAll] = useState<boolean>(false);
  const [descriptionLocale, setDescriptionLocale] = useState<string>('en-US');
  const [selectedDescriptionLocale, setSelectedDescriptionLocale] = useState<string>('en-US');
  const [showRequesDescriptionModal, setShowRequesDescriptionModal] = useState<boolean>(false);
  const [notes, setNotes] = useState<string>('');
  const [materialDescriptionList, setMaterialDescriptionList] = useState<MaterialDescription[]>([]);
  const [filteredMaterialDescriptionList, setFilteredMaterialDescriptionList] = useState<MaterialDescription[]>([]);
  const [mmdLocales, setMmdLocales] = useState<Locale[]>([]);
  const [saveAllLoading, setSaveAllLoading] = useState<boolean>(false);
  const [changedNotes, setChangedNotes] = useState<string>(notes || '');
  const [descriptionLocalesAvailableInd, setDescriptionLocalesAvailableInd] = useState<boolean>(false);
  const [descriptionLocalesAvailable, setDescriptionLocalesAvailable] = useState<Locale[]>();
  const [editedTextField, setEditedTextField] = useState<MaterialDescription[]>();

  const [getMaterialDescBymmID, { refetch: refetchMaterialDescByMmId,
    loading: materialDescriptionLoadingByMmId }] = useLazyQuery(MATERIAL_DESC_USING_MMID, {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache',
      onCompleted: (data: MaterialMasterDescription) => {
        setDescriptionLocale(data.lsFetchMaterialDescriptionDetailUsingMmId.descriptionLocale);
        setMaterialDescriptionList(data.lsFetchMaterialDescriptionDetailUsingMmId.descriptionList);
        filterMaterialDescList(data.lsFetchMaterialDescriptionDetailUsingMmId.descriptionList);
        setNotes(data.lsFetchMaterialDescriptionDetailUsingMmId.notes || '');
        setMmdLocaleDescription(data.lsFetchMaterialDescriptionDetailUsingMmId.descriptionLocalesValid);
        setChangedNotes(data.lsFetchMaterialDescriptionDetailUsingMmId.notes);
      },
      onError: () => {
        setMessageText('errorMessage', defaults.fetch_material_desc_error);
        history.push('/material-description');
      }
    });

  useEffect(() => {
    if (mmId) {
      fetchMaterialDescByMmId();
    } else {
      history.push('/material-description');
    }
  }, [mmId, getMaterialDescBymmID]);

  useEffect(() => {
    const hasUncheckedItem = filteredMaterialDescriptionList.some((item: MaterialDescription) => {
      return !item.checked && item.translationStatus !== 'UNTRANSLATED';
    });
    setRequestAll(!hasUncheckedItem);
  }, [filteredMaterialDescriptionList]);

  const handleSaveAll = () => {
    if (saveAllLoading) {
      return;
    }
    setSaveAllLoading(true);
    const editModeFields = filteredMaterialDescriptionList.filter((item: MaterialDescription) => item.editMode === true);
    setEditedTextField(editModeFields);
    const descriptionList = editModeFields.map((item: MaterialDescription) => {
      return {
        fieldType: item.fieldType,
        text: item.text
      };
    });
    if (descriptionList && descriptionList.length > 0) {
      updateMaterialDescription({
        variables: {
          input: {
            mmId: parseInt(mmId),
            descriptionList,
            descriptionLocale
          }
        }
      }).then((response: { data: MaterialDescriptionPayload; errors?: GraphQLError[] }) => {
        setSaveAllLoading(false);
        if (response.errors) {
          setMessageText('errorMessage', defaults.update_error);
        } else {
          refetchMaterialDescByMmId();
          setMessageText('editSuccessMessage', defaults.update_success_for_material);
        }
      })
    }
  }

  const handleSearch = () => {
    setMessageText('editSuccessMessage', '');
    setMessageText('requestSuccessMessage', '');
    setMessageText('errorMessage', '');
    fetchMaterialDescByMmId();
  }

  const fetchMaterialDescByMmId = () => {
    getMaterialDescBymmID({
      variables: {
        mmId: parseInt(mmId),
        descriptionLocale: selectedDescriptionLocale
      }
    });
  }

  const filterMaterialDescList = (materialDescriptionList: MaterialDescription[]) => {
    if (materialDescriptionList.length) {
      const descriptionList = materialDescriptionList.map(
        ({ fieldType, text, translationStatus }, index: number) => {
          return {
            key: index,
            text: text ? text : '',
            originalText: text ? text : '',
            fieldType: fieldType,
            translationStatus,
            editMode: false,
            checked: false,
            loading: false
          };
        }
      );
      setMaterialDescriptionList(descriptionList);
      setFilteredMaterialDescriptionList(descriptionList);
    }
  }

  const handleAllCheckBox = (checked: boolean) => {
    setRequestAll(checked);
    setShowFooter(checked);
    setFilteredMaterialDescriptionList((prevList: MaterialDescription[]) => {
      return prevList.map((item: MaterialDescription, index: number) => {
        const newItem = { ...item };
        if (newItem.translationStatus !== 'UNTRANSLATED') {
          newItem.checked = checked;
        }
        return {
          ...newItem,
          key: index
        };
      });
    });
  }

  const setMmdLocaleDescription = (mmdLocales: Locale[]) => {
    const mmdLocalesWithDescription = mmdLocales.map((locale: Locale, index: number) => {
      return {
        key: index,
        code: locale.code,
        languageDescription: localeMap[locale.code]
      };
    });
    setMmdLocales(mmdLocalesWithDescription);
  }

  return (
    <div>
      {(!materialDescriptionLoadingByMmId || materialDescriptionList.length) ? (
        <>
          {(descriptionLocalesAvailableInd || showRequesDescriptionModal) && <EditAndRequestDescriptionModal
            setDescriptionLocalesAvailableInd={setDescriptionLocalesAvailableInd}
            descriptionLocalesAvailable={descriptionLocalesAvailable}
            mmdLocales={mmdLocales}
            setMaterialDescriptionList={setMaterialDescriptionList}
            note={notes}
            filteredMaterialDescriptionList={filteredMaterialDescriptionList}
            showRequesDescriptionModal={showRequesDescriptionModal}
            setShowRequesDescriptionModal={setShowRequesDescriptionModal}
            editedTextField={editedTextField} refetchMaterialDescByMmId={refetchMaterialDescByMmId} />}
          <MaterialDescriptionDetails getMaterialDescriptionByFilters={getMaterialDescriptionByFilters}
            updateMaterialDescription={updateMaterialDescription}
            setSaveAllLoading={setSaveAllLoading}
            notes={notes}
            setNotes={setNotes}
            changedNotes={changedNotes}
            setChangedNotes={setChangedNotes}
            updatingMaterialDescription={updatingMaterialDescription} />
          <Grid container alignItems="center" spacing={5}>
            <Grid item xs={4.5}>
              {mmdLocales && mmdLocales.length && (
                <Select
                  label={defaults.select_language}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSelectedDescriptionLocale(event.target.value)}
                  value={selectedDescriptionLocale}
                  fullWidth>
                  {mmdLocales.map((locale: Locale) => (
                    <MenuItem key={locale.code} value={locale.code}>
                      {locale.languageDescription}
                    </MenuItem>
                  ))}
                </Select>
              )}
            </Grid>
            <Grid item xs={3}>
              <ButtonAndStpGrid>
                <Button disabled={materialDescriptionLoadingByMmId || updatingMaterialDescription}
                  onClick={() => handleSearch()}
                  adminColor>
                  {defaults.search}
                </Button>
              </ButtonAndStpGrid>
            </Grid>
          </Grid>
          {!materialDescriptionLoadingByMmId ? (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {(descriptionLocale === 'en-US') && <TableCell>
                      <Checkbox
                        checked={requestAll}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleAllCheckBox(e.target.checked)} />
                    </TableCell>
                    }
                    <TableCell>
                      <Typography variant='h4'>{defaults.description}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant='h4'>{defaults.status}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant='h4'>{defaults.default_text}</Typography>
                    </TableCell>
                    {descriptionLocale !== 'en-US' ? (<ActionCell>
                      <Button loading={saveAllLoading} disabled={!filteredMaterialDescriptionList.some((item: MaterialDescription) => item.editMode) || !filteredMaterialDescriptionList
                        .filter((item: MaterialDescription) => item.editMode)
                        .every((item: MaterialDescription) => item.text && item.text.length > 0 && item.text.trim() !== item.originalText.trim() && item.text?.trim()?.length <= MmdCharLimitForeignLocales[item.fieldType].limit)}
                        onClick={() => handleSaveAll()}
                        variant='text'>{defaults.save_all}</Button>
                    </ActionCell>) : <TableCell></TableCell>}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredMaterialDescriptionList.map(
                    (description: MaterialDescription) => {
                      return (
                        <MaterialDescriptionRow
                          key={String(description.fieldType)}
                          description={description}
                          saveAllLoading={saveAllLoading}
                          descriptionLocale={descriptionLocale}
                          filteredMaterialDescriptionList={filteredMaterialDescriptionList}
                          materialDescriptionList={materialDescriptionList}
                          setDescriptionLocalesAvailable={setDescriptionLocalesAvailable}
                          setDescriptionLocalesAvailableInd={setDescriptionLocalesAvailableInd}
                          setEditedTextField={setEditedTextField}
                          setMaterialDescriptionList={setMaterialDescriptionList}
                          setFilteredMaterialDescriptionList={setFilteredMaterialDescriptionList}
                          editMode={description.editMode}
                          updateMaterialDescription={updateMaterialDescription}
                          updatingMaterialDescription={updatingMaterialDescription}
                          materialDescriptionLoadingByMmId={materialDescriptionLoadingByMmId}></MaterialDescriptionRow>
                      );
                    }
                  )}
                </TableBody>
              </Table>
              {showFooter && descriptionLocale === 'en-US' && <Footer
                cancelActionText={defaults.cancel}
                saveActionText={defaults.request_translation}
                cancelAction={() => handleAllCheckBox(false)}
                saveAction={() => {
                  setOpenEditEnglishDescriptionModal(true);
                  setShowRequesDescriptionModal(true)
                }} loading={false} saveButtonDisabled={updatingMaterialDescription} />
              }
            </TableContainer>) : (<StyledCircularProgress>
              <CircularProgress />
            </StyledCircularProgress>)}
        </>
      ) : (
        <StyledCircularProgress>
          <CircularProgress />
        </StyledCircularProgress>
      )}
    </div>
  )
}

export default MaterialDescriptionTable;