import React from 'react';
import { Typography, Button, Tag } from '@octanner/prism-core';
import { useStyles } from './styles';
import { DateTime } from 'luxon';
import { MigrationHeaderProps } from './types';

const MigrationHeader: React.FC<MigrationHeaderProps> = ({
  stalled,
  failedTsz,
  completeTsz,
  isCreated,
  percentComplete,
  buttonLoading,
  handleRun,
  handleReRun,
  preMigrationStatusLoading,
  migrationName,
  isReRunAvailable = true,
}) => {
  const classes = useStyles();

  const tagVariants = () => {
    if (stalled) return 'warning';
    if (completeTsz) return 'success';
    if (failedTsz) return 'error';
    if (preMigrationStatusLoading) return 'info';
    return 'info';
  };

  const tagHeader = () => {
    if (stalled) return `Stalled ...(${percentComplete}% Complete)`;
    if (completeTsz) return 'Complete';
    if (failedTsz) return 'Failed';
    if (preMigrationStatusLoading) return 'Preparing...';
    return `${percentComplete}% Complete`;
  };

  const failedOrComplete = failedTsz || completeTsz;

  return (
    <>
      <div className={classes.cardHeader}>
        <div className={classes.cardHeaderTitle}>
          <Typography variant="h3" data-testid={'data-migration-card-header'}>
            {migrationName}
          </Typography>
          {migrationName !== 'Data Migration' &&
            (failedOrComplete ||
              preMigrationStatusLoading ||
              percentComplete !== 0) && (
              <Tag
                className={classes.tag}
                open
                variant={tagVariants()}
                data-testid={'migration-status-button'}
              >
                {tagHeader()}
              </Tag>
            )}
          {migrationName !== 'Data Migration' &&
            (completeTsz !== null || failedTsz !== null) && (
              <Typography
                variant="caption"
                className={classes.dateTime}
                data-testid={'complete-time'}
              >
                {DateTime.fromISO(
                  completeTsz ? completeTsz! : failedTsz!
                ).toLocaleString(DateTime.DATETIME_MED)}
              </Typography>
            )}

          {migrationName !== 'Data Migration' &&
            failedOrComplete &&
            isReRunAvailable && (
              <Button
                className={classes.cardButton}
                color="secondary"
                onClick={handleReRun}
                data-testid={'run-again-button'}
                loading={buttonLoading}
                disabled={buttonLoading}
              >
                {'Run Again'}
              </Button>
            )}
          {migrationName !== 'Data Migration' &&
            !failedTsz &&
            !preMigrationStatusLoading &&
            !isCreated && (
              <Button
                className={classes.cardButton}
                color="primary"
                onClick={handleRun}
                data-testid={'run-button'}
                adminColor
                disabled={buttonLoading}
              >
                {'Run'}
              </Button>
            )}
        </div>
      </div>
    </>
  );
};

export default MigrationHeader;
