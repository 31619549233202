import React, { useEffect , useState } from 'react'
import { Button, Modal } from '@octanner/prism-core'
import { useTranslation } from 'react-i18next';
import { WarningText,ModalButtonContainer , ModalDiscardButton } from './styles'
import { GET_CUSTOMER_INFO , CHANGE_CUSTOMER_STATUS } from './query'
import { useMutation, useLazyQuery } from '@apollo/client';
import {customerId} from '../../utilities/utility';
import { CustomerStatus } from './constants'




export const ChangeStatusConfirmation = ({
    setShowStatus,
    setShowWarning,
    setSapErrorDispaly,
    dataCustomerName ,
    setRefetchEditCustomer,
    setErrorMessage,
    confirmationData,
    tabsEditModeStatus,
    setTabsEditModeStatus,
    errorsDisplayNumber,
    setCustomerInfo,
    setCalledRefetch,
    isMoveToActive,
}) => {
    const { customerStatus } = dataCustomerName?.customer || {};
    const [getCoustomerDetails] = useLazyQuery(GET_CUSTOMER_INFO);
    const [saveAndContinueLoading, setSaveAndContinueLoading] = useState(false);
    const [changeCustomerStatus] = useMutation(CHANGE_CUSTOMER_STATUS,{
        onCompleted: () => { setRefetchEditCustomer(true) },
        onError : () => {
            setSapErrorDispaly(true);
            setErrorMessage(t('unauthorized_error'));
            setShowStatus(false);
            setButtonLoading(false);
            setShowWarning(true);
            setSaveAndContinueLoading(false);
        }
    });
    const [isEditSaveDone, setIsEditSaveDone] = useState(false);
    const refetchedData = async (refetchCustomerAddressData) => {
        await setCustomerInfo(refetchCustomerAddressData);
        await setCalledRefetch(true);
    }
    const refetchingData = () => {
        setShowWarning(false);
        setButtonLoading(true);
        getCoustomerDetails({variables: { customerId: customerId }}).then((coustomerDetailsData)=>{
            const coustomerData = coustomerDetailsData?.data?.customerContactInformationMapByCustomer?.customerContactInfo;
            refetchedData(coustomerData);
            if(coustomerData){
                setCalledRefetch(false);
                if(customerStatus===CustomerStatus.IMPLEMENTATION ){
                    changeCustomerStatus({
                    variables: {
                        input: {
                        customerUuid : customerId,
                        toStatus : isMoveToActive ? CustomerStatus.ACTIVE : CustomerStatus.LOST
                        }
                    }})
                }else{
                    changeCustomerStatus({
                    variables: {
                        input: {
                            customerUuid : customerId,
                            toStatus : customerStatus===CustomerStatus.ACTIVE ? CustomerStatus.LOST : CustomerStatus.IMPLEMENTATION
                        }
                    }
                    })
                }
            }else{
                setCalledRefetch(false);
                setShowStatus(false);
                setButtonLoading(false);
                setShowWarning(true);
                setSaveAndContinueLoading(true);
            }})
        }
    useEffect(() => {
        if(isEditSaveDone === true){
            if(errorsDisplayNumber.ClientFinancialInfo > 0
                ||  errorsDisplayNumber.CountryAndSalesOrgSetup>0
                || errorsDisplayNumber.DefaultCountryAndLanguages>0){
                setShowStatus(false);
                setButtonLoading(false);
                setShowWarning(true);
                setSaveAndContinueLoading(false);
            }else{
                refetchingData();
            }
            setIsEditSaveDone(false);
        }
    },[errorsDisplayNumber , isEditSaveDone]);
    const [buttonLoading , setButtonLoading] = useState(false);
    const { t } = useTranslation();
    const saveCurrentPageDetais = () => {
        setButtonLoading(true);
        setSaveAndContinueLoading(true);
        if(confirmationData.activeTabIndex === 0){
            //call contact details api
            setTabsEditModeStatus({
                ...tabsEditModeStatus,
                ClientFinancialInfo: true,
                InvoiceDetails : true
            });
        }else if(confirmationData.activeTabIndex === 1){
            //call sales org api
            setTabsEditModeStatus({
                ...tabsEditModeStatus,
                CountryAndSalesOrgSetup: true
            })
        }else if(confirmationData.activeTabIndex === 2){
            //call locale api
            setTabsEditModeStatus({
                ...tabsEditModeStatus,
                DefaultCountryAndLanguages : true
            })
        }
        //wait for 5 sec check if any error in any tab
        setTimeout(() => {setIsEditSaveDone(true);}, 10000);
    }

    return (
        <>
    {!(confirmationData.editMode) ?
        <Modal
        id='customer-status-modal'
        actions={
        <ModalButtonContainer>
            <ModalDiscardButton
                loading={buttonLoading}
                adminColor
                style={{marginRight: '8px',}}
                onClick={() => {refetchingData()}}
                test-id="change-status"
            >
                {t('change_status')}
            </ModalDiscardButton>
            <Button color="secondary"
                onClick={() => {setShowStatus(false);}}
                test-id="cancel"
            >
                {t('cancel')}
            </Button>
            </ModalButtonContainer>}
            onClose={ () => {setShowStatus(false)}}
            open={true}
            title={t('client_status_type_modal_description', {
                customerStatus : customerStatus === CustomerStatus.SOLD || customerStatus === CustomerStatus.LOST ? 'Implementation' : customerStatus === CustomerStatus.IMPLEMENTATION && isMoveToActive ? 'Active' : 'Lost'}
    )}
            >
            <WarningText>{customerStatus === CustomerStatus.IMPLEMENTATION && !isMoveToActive || customerStatus === CustomerStatus.ACTIVE ? t('lostDescription') : t('cant_undo_warning_text')}</WarningText>
            </Modal>
            :
            <Modal
            actions={
            <ModalButtonContainer>
                <ModalDiscardButton
                    loading={saveAndContinueLoading}
                    adminColor
                    style={{ marginRight: '8px', }}
                    onClick={() => { saveCurrentPageDetais()  }}
                    test-id="change-status"
                >
                    {t('save_continue')}
                </ModalDiscardButton>
                <Button color="secondary"
                    onClick={() => { setShowStatus(false) }}
                    test-id="cancel"
                >
                    {t('cancel')}
                </Button>
            </ModalButtonContainer>
            }
                onClose={() => { setShowStatus(false); }}
                open={true}
                title={t('client_status_type_modal_description', {
                    customerStatus : customerStatus === CustomerStatus.SOLD || customerStatus === CustomerStatus.LOST ?
                    'Implementation' : customerStatus === CustomerStatus.IMPLEMENTATION && isMoveToActive ? 'Active' : 'Lost'})}
                    id='customer-status-modal'
                >
            <WarningText>{t('unsaved_changes_text')}</WarningText>
        </Modal>
        }
    </>
  )
}


