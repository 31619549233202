import { Button, Menu, TableCell, Typography } from '@octanner/prism-core';
import { Agenda, MenuDots, Pencil, Trash } from '@octanner/prism-icons';
import { useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { TableRows } from '../types';
import { LinkBlack, MeatballMenuCell, MenuItemGap } from '../styles';
import { tannerSemantic } from '@octanner/prism-core/ThemeProvider/colors';

export default function SingleRow({
  testKey,
  rowData,
  getId,
  columnDisplayDefs,
  showDuplicate,
  hideDelete,
  onDelete,
}: {
  testKey: string;
  rowData: any;
  getId: (rowData: any) => string;
  columnDisplayDefs: TableRows[];
  showDuplicate?: boolean;
  hideDelete?: boolean;
  onDelete?: () => void;
}) {
  const match = useRouteMatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleOnMouseEnter = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      {columnDisplayDefs.map(({ key, test, customComponent, align }) => {
        if (customComponent) {
          return (
            <TableCell align={align} key={key}>
              {customComponent(rowData)}
            </TableCell>
          );
        }

        return (
          <TableCell key={key} align={align}>
            <Typography data-test={`${testKey}:${getId(rowData)}:${test}`}>
              {rowData[key] || ''}
            </Typography>
          </TableCell>
        );
      })}

      <TableCell>
        <MeatballMenuCell>
          <MenuDots
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleOnMouseEnter}
          />
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <LinkBlack to={`${match.url}/${getId(rowData)}/edit`}>
              <MenuItemGap>
                <Pencil />
                Edit
              </MenuItemGap>
            </LinkBlack>

            {showDuplicate && (
              <LinkBlack to={`${match.url}/${getId(rowData)}/duplicate`}>
                <MenuItemGap>
                  <Agenda />
                  Duplicate
                </MenuItemGap>
              </LinkBlack>
            )}

            {!hideDelete && onDelete && (
              <Button
                variant="text"
                buttonType="danger"
                onClick={() => {
                  handleClose();
                  onDelete();
                }}
                startIcon={
                  <Trash sx={{ color: tannerSemantic['error'].color }} />
                }
                sx={{
                  width: '100%',
                  justifyContent: 'flex-start',
                }}
              >
                Delete
              </Button>
            )}
          </Menu>
        </MeatballMenuCell>
      </TableCell>
    </>
  );
}
