import RoleInformationCard from './RoleInformationCard';
import { NoResultsContainer } from '../admins/styles';
import Typography from '@octanner/prism-core/Typography';
import { AdminRoleInformation } from '../types';
import React from 'react';
import { PendingChangesSeparator } from '../../../programs/modifyProgram/styles';
import { Box } from '@octanner/prism-core';
import { tannerGray } from '@octanner/prism-core/ThemeProvider/colors';
import { PendingChangesBlueCircle } from '../styles';

interface RoleInformationListProps {
  type: 'active' | 'inactive';
  pagedRoles?: AdminRoleInformation[];
  changingRoles: AdminRoleInformation[];
  onAction: (adminRoles: AdminRoleInformation) => void;
  isActionDisabled: (adminRoles: AdminRoleInformation) => boolean;
  children?: React.ReactNode;
}

const RoleInformationList = ({
  type,
  pagedRoles,
  changingRoles,
  onAction,
  isActionDisabled,
  children,
}: RoleInformationListProps) => {
  return (
    <>
      {changingRoles.length > 0 && (
        <>
          <Typography variant="h3">
            Unsaved Changes ({changingRoles.length})
          </Typography>
          <Box display="flex" alignItems="center">
            <PendingChangesBlueCircle />
            <Typography variant="body1" color={tannerGray[600]}>
              The blue border indicates recent changes that have not been saved
              yet.
            </Typography>
          </Box>
          {changingRoles.length > 0 &&
            changingRoles.map((role) => (
              <RoleInformationCard
                cardState={role.role.deactivatedTsz ? 'deleted' : type}
                adminRole={role}
                isActionDisabled={isActionDisabled(role)}
                onAction={onAction}
                isPending
              />
            ))}
          <PendingChangesSeparator />
        </>
      )}
      {children}
      {pagedRoles &&
        pagedRoles.length > 0 &&
        pagedRoles.map((role) => (
          <RoleInformationCard
            cardState={role.role.deactivatedTsz ? 'deleted' : type}
            adminRole={role}
            isActionDisabled={isActionDisabled(role)}
            onAction={onAction}
          />
        ))}
      {pagedRoles && pagedRoles.length < 1 && changingRoles.length < 1 && (
        <NoResultsContainer>
          <Typography variant="h3">There are currently no roles</Typography>
          <Typography>Search above to assign a role for this admin.</Typography>
        </NoResultsContainer>
      )}
    </>
  );
};

export default RoleInformationList;
