import { gql } from '@apollo/client';

export const GET_SOCIAL_CARD_CONFIG_BY_UUID = gql`
  query SocialCardConfigByCustomerUuid($customerUuid: UUID!) {
    socialCardConfigByCustomerUuid(customerUuid: $customerUuid) {
      displayBusinessUnit
      displayAwardLevel
      displayMilestoneAward
      displayRecognitionMessage
      recognitionAwardLevel {
        recognitionAward
        bannerCheckBox
        titleCheckBox
      }
      displayRecognitionMessage
    }
  }
`;

export const GET_SOCIAL_CONFIG_BY_UUID = gql`
  query socialConfigByCustomerUuid($customerUuid: String) {
    socialConfigByCustomerUuid(customerUuid: $customerUuid) {
      socialConfig {
        displayComments
        displayFollowingTab
        displayLikes
        personalFeedOverride
        displayBusinessUnit
        displayAwardLevel
        displayMilestoneAward
        displayRecognitionMessage
        viewType
        recognitionAwardLevel {
          recognitionAward
          bannerCheckBox
          titleCheckBox
        }
      }
    }
  }
`;

export const SHOW_RECOGNITION_AWARD_LEVEL = gql`
  mutation SocialShowRecognitionAwardLevel($customerUuid: UUID!) {
    socialShowRecognitionAwardLevel(input: { customerUuid: $customerUuid }) {
      display
    }
  }
`;

export const HIDE_RECOGNITION_AWARD_LEVEL = gql`
  mutation SocialHideRecognitionAwardLevel($customerUuid: UUID!) {
    socialHideRecognitionAwardLevel(input: { customerUuid: $customerUuid }) {
      display
    }
  }
`;

export const SHOW_AWARD_LEVEL_IN_BANNER = gql`
  mutation SocialShowAwardLevelInBanner($customerUuid: UUID!) {
    socialShowAwardLevelInBanner(input: { customerUuid: $customerUuid }) {
      display
    }
  }
`;

export const HIDE_AWARD_LEVEL_IN_BANNER = gql`
  mutation SocialHideAwardLevelInBanner($customerUuid: UUID!) {
    socialHideAwardLevelInBanner(input: { customerUuid: $customerUuid }) {
      display
    }
  }
`;

export const SHOW_AWARD_LEVEL_IN_TITLE = gql`
  mutation SocialShowAwardLevelInTitle($customerUuid: UUID!) {
    socialShowAwardLevelInTitle(input: { customerUuid: $customerUuid }) {
      display
    }
  }
`;

export const HIDE_AWARD_LEVEL_IN_TITLE = gql`
  mutation SocialHideAwardLevelInTitle($customerUuid: UUID!) {
    socialHideAwardLevelInTitle(input: { customerUuid: $customerUuid }) {
      display
    }
  }
`;

export const SHOW_MILESTONE_AWARD_LEVEL = gql`
  mutation SocialShowMilestoneAwardLevel($customerUuid: UUID!) {
    socialShowMilestoneAwardLevel(input: { customerUuid: $customerUuid }) {
      display
    }
  }
`;

export const HIDE_MILESTONE_AWARD_LEVEL = gql`
  mutation SocialHideMilestoneAwardLevel($customerUuid: UUID!) {
    socialHideMilestoneAwardLevel(input: { customerUuid: $customerUuid }) {
      display
    }
  }
`;

export const TURN_ON_RECOGNITION_MESSAGE = gql`
  mutation SocialTurnOnRecognitionMessage($customerUuid: UUID!) {
    socialTurnOnRecognitionMessage(input: { customerUuid: $customerUuid }) {
      display
    }
  }
`;

export const TURN_OFF_RECOGNITION_MESSAGE = gql`
  mutation SocialTurnOffRecognitionMessage($customerUuid: UUID!) {
    socialTurnOffRecognitionMessage(input: { customerUuid: $customerUuid }) {
      display
    }
  }
`;

export const SHOW_BUSINESS_UNIT = gql`
  mutation SocialShowBusinessUnit($customerUuid: UUID!) {
    socialShowBusinessUnit(input: { customerUuid: $customerUuid }) {
      display
    }
  }
`;

export const HIDE_BUSINESS_UNIT = gql`
  mutation SocialHideBusinessUnit($customerUuid: UUID!) {
    socialHideBusinessUnit(input: { customerUuid: $customerUuid }) {
      display
    }
  }
`;
