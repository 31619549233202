import styled from "@emotion/styled";
import { FormHelperText, Grid, TextField } from "@octanner/prism-core";
import React from "react";
import { numRegex } from "../../../utils/constants";
import { defaults } from '../../../utils/default';

const FormHelperTextMessage = styled(FormHelperText)`
  color: #FF0000;
`;

const Control = styled.div`
  margin-top: 10px;
`;

const CustomTextInfo = ({ programId,
  onRequesterNameChange,
  requesterName,
  onRequesterEmailChange,
  requesterEmail,
  onSoldToPartyNumberChange,
  soldToPartyNumber,
  onProgramIdChange }) => {

  const handleProgramId = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    const inputValue = event.target.value.trim();
    if (inputValue === '' || numRegex.test(inputValue)) {
      onProgramIdChange(inputValue);
    }
  };

  const handleSoldToPartyNumber = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    const inputValue = event.target.value.trim();
    if (inputValue === '' || numRegex.test(inputValue)) {
      onSoldToPartyNumberChange(inputValue);
    }
  };

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={5}>
          <TextField
            value={requesterName}
            label={defaults.requested_by_name}
            onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => onRequesterNameChange(e.target.value.trim())}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={5}>
          <TextField
            value={requesterEmail}
            label={defaults.requested_by_email}
            onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => onRequesterEmailChange(e.target.value.trim())}
            fullWidth
            required
          />
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs={5}>
          <Control>
            <TextField
              value={programId}
              label={defaults.program_Id}
              onChange={handleProgramId}
              fullWidth
              helperText={defaults.optional}
            />
          </Control>
        </Grid>
        <Grid item xs={5}>
          <Control>
            <TextField
              value={soldToPartyNumber}
              label={defaults.sold_to_party}
              onChange={handleSoldToPartyNumber}
              fullWidth
              helperText={defaults.optional}
            />
          </Control>
        </Grid>
      </Grid>
    </>
  )
};

export default CustomTextInfo;