import { CelebrationLevelGroupConfig } from '../../../common/models/CelebrationLevelGroupConfig'

export interface DetailViewModel {
  text: string
  checked?: boolean
  children?: DetailViewModel[]
}

const factoryBuilder =
  (
    fn: (
      celebration: CelebrationLevelGroupConfig
    ) => DetailViewModel | undefined,
    condition?: (config: CelebrationLevelGroupConfig) => boolean
  ) =>
  (celebration: CelebrationLevelGroupConfig) => {
    if (condition && !condition(celebration)) return
    return fn(celebration)
  }

const getCoverType = (celebration: CelebrationLevelGroupConfig): string => {
  switch (celebration.printCoverType) {
    case 'HARD_BOUND':
      return 'Hardbound'
    case 'SOFT_BOUND':
      return 'Softbound'
  }
}

const genericFactory = (
  key: keyof CelebrationLevelGroupConfig,
  text: string,
  condition?: (config: CelebrationLevelGroupConfig) => boolean
) =>
  factoryBuilder((celebration) => {
    const value = celebration[key]
    if (value === 'undefined') return
    return {
      text,
      checked: Boolean(value),
    }
  }, condition)

const requiresMilestone = (config: CelebrationLevelGroupConfig) =>
  Boolean(config.milestone)

const leaderFactory = factoryBuilder((celebration) => {
  const text = 'Leader personal notes'
  if (!celebration.leaderNotesEnabled) return { text, checked: false }
  return {
    text,
    checked: true,
    children: [
      genericFactory('leaderPhotoEnabled', 'Leader photos')(celebration),
      genericFactory('leaderVideoEnabled', 'Leader video')(celebration),
    ],
  }
})

const peerFactory = factoryBuilder((celebration) => {
  const text = 'Peer personal notes'
  if (!celebration.peerNotesEnabled) return { text, checked: false }
  return {
    text,
    checked: true,
    children: [
      genericFactory('peerPhotoEnabled', 'Peer photos')(celebration),
      genericFactory('peerVideoEnabled', 'Peer video')(celebration),
      genericFactory('autoInviteEnabled', 'Auto Invite')(celebration),
    ],
  }
})

const yearbookFactory = factoryBuilder((celebration) => {
  if (!celebration.yearbookEnabled)
    return { text: 'No yearbooks', checked: false }

  const printType = celebration.printCoverType
    ? [{ text: getCoverType(celebration), checked: true }]
    : []
  return {
    text: celebration.printYearbookEnabled
      ? 'Digital & Print yearbooks'
      : 'Digital only yearbooks',
    checked: true,
    children: [
      ...printType,
      leaderFactory(celebration),
      peerFactory(celebration),
    ],
  }
}, requiresMilestone)

const giftFactory = factoryBuilder((celebration) => {
  if (
    !celebration.userSelectedOrder &&
    !celebration.accrualPointsForServiceEnabled
  )
    return
  if (celebration.userSelectedOrder) {
    return {
      text: 'User selected gift',
      checked: true,
    }
  }
  if (celebration.accrualPointsForServiceEnabled) {
    return {
      text: 'Points for service',
      checked: true,
    }
  }
}, requiresMilestone)

const emailFactory = factoryBuilder((c) => {
  if (c.milestone) return
  if (
    !c.milestone &&
    (c.celebrantCongratulatoryNotificationEnabled ||
      c.peersAnniversaryDayNotificationEnabled ||
      c.managerAnniversaryDayNotificationEnabled)
  ) {
    return { text: 'Anniversary Emails', checked: true }
  } else {
    return { text: 'Anniversary Emails', checked: false }
  }
})

const autoshipFactory = genericFactory(
  'autoshipAwards',
  'Autoship Order',
  requiresMilestone
)

const certificatesFactory = genericFactory(
  'printCertificateEnabled',
  'Printed Certificates',
  requiresMilestone
)

const presentationReminderFactory = genericFactory(
  'presentationReminderEmail',
  'Presentation Reminder Email',
  requiresMilestone
)

const surveyFactory = genericFactory(
  'surveyEnabled',
  'Survey',
  requiresMilestone
)
const emptyYearbookEmailFactory = genericFactory(
  'emptyYearbookEmailEnabled',
  'Email Admin About Empty Yearbook',
  requiresMilestone
)

const celebrantInvitePeersEmailFactory = genericFactory(
  'celebrantInvitePeersNotificationEnabled',
  'Celebrant Invite Peers Email',
  requiresMilestone
)

const celebrantCongratulatoryEmailFactory = genericFactory(
  'celebrantCongratulatoryNotificationEnabled',
  'Happy Anniversary Email - Day Of Email',
  requiresMilestone
)

const peersAnniversaryDayEmailFactory = genericFactory(
  'peersAnniversaryDayNotificationEnabled',
  'Notify Peers of Anniversary - Day Of Email',
  requiresMilestone
)

export const rootFactories = [
  giftFactory,
  yearbookFactory,
  autoshipFactory,
  certificatesFactory,
  emailFactory,
  celebrantInvitePeersEmailFactory,
  celebrantCongratulatoryEmailFactory,
  peersAnniversaryDayEmailFactory,
  emptyYearbookEmailFactory,
  presentationReminderFactory,
  surveyFactory,
]
