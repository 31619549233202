import { useEffect, useState } from 'react';
import { Search, Typography } from '@octanner/prism-core';
import URLSearchParams from '@ungap/url-search-params';
import { useHistory, useLocation } from 'react-router-dom';
import { withPad } from '../../common/BasicPadding';
import { CustomerBase } from '../customer/models/Customer';
import ClientList from './ClientList';
import { useSearchCustomers, useDeleteCustomer } from './hooks/customer';
import { useStyles } from './styles';

const Clients = () => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const params = new URLSearchParams(location.search);
  const [searchText, setSearchText] = useState(params.get('search') || '');
  const [pageNumber, setPageNumber] = useState(0);
  const [customers, setCustomers] = useState<CustomerBase[]>([]);
  const deleteCustomer = useDeleteCustomer();

  const { data, loading } = useSearchCustomers(
    { pageSize: 10, pageNumber: pageNumber },
    searchText
  );
  const totalPages = data?.searchCustomers?.pageInfo.totalPages;
  const nodes = data?.searchCustomers?.nodes;

  useEffect(() => {
    const users = nodes ? nodes : [];
    setCustomers(users);
  }, [data, nodes]);

  useEffect(() => {
    setPageNumber(0);
  }, [searchText]);

  const handlePageChange = (page: number) => {
    setPageNumber(page - 1);
  };

  useEffect(() => {
    if (searchText === undefined) return;
    const trimmedText = searchText.trim();
    const params = new URLSearchParams(location.search);
    if (params.get('search') === trimmedText) return;
    params.set('search', trimmedText);
    history.replace({ search: params.toString() });
  }, [history, location, searchText]);

  const newMode = Boolean(params.get('new'));

  const handleGoTo = (customer: CustomerBase) => {
    history.push(`/customer/${customer.id}/client-summary`);
  };

  const handleDelete = async (customer: CustomerBase) => {
    await deleteCustomer({
      variables: { customerId: customer.id },
      update(cache) {
        cache.evict({ id: `CustomerSearch:${customer.id}` });
      },
    });
  };

  return (
    <div>
      <Typography variant="h1">Client Mapping</Typography>
      {!newMode && (
        <div className={classes.actionsContainer}>
          <Search
            placeholder="Search Client name or ID"
            id="people-single-search"
            multiple={false}
            loading={loading}
            className={classes.action}
            onInputChange={(e: any) => setSearchText(e.target.value)}
            getOptionLabel={() => searchText}
            // @ts-ignore strange bug with prism
            type="single"
            data-test="clients:search"
          />
        </div>
      )}
      <div className={classes.clientList}>
        {customers && (
          <ClientList
            loading={loading}
            clients={customers}
            searchText={searchText}
            currentPage={pageNumber}
            totalPages={totalPages as number}
            handleChange={(page) => handlePageChange(page)}
            onGoToClick={handleGoTo}
            onDeleteClick={handleDelete}
          />
        )}
      </div>
    </div>
  );
};

export default withPad()(Clients);
