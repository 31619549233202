import styled from "@emotion/styled";
import {
  Button, Checkbox, CircularProgress, Grid, MenuItem, Pagination, Select, TextField, Typography
} from "@octanner/prism-core";
import React, { useContext, useEffect, useState } from "react";
import { LocaleContext } from "../../Contexts/LocaleContext";
import { MaterialStringsContext } from "../../Contexts/MaterialStringsContext";
import { constraints, descriptions, numRegex } from '../../utils/constants';
import { defaults } from "../../utils/default";
import { Locale } from "../../utils/types";
import SearchMaterialDescriptionStringsTable from "./SearchMaterialDescriptionStringsTable";

const SearchContainer = styled.div`
  margin-top: 24px;
  margin-bottom: 30px;
`;

const ButtonGrid = styled(Grid)`
  display: flex;
  align-items: center;
`;

const PaginationContainer = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 4px;
  align-self: stretch;
`;

const PlaceHolder = styled(Typography)`
  padding: 24px;
`;

const MessageContainer = styled(Grid)`
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 3px;
  background: #FBFBFB;
`;

const CheckBoxGrid = styled(Grid)`
  display: flex;
  align-items: center;
`;

const StyledCircularProgress = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  height: 60vh;
`;

const SearchMaterialDescriptionStrings = () => {

  const {
    searchBy, searchText, setSearchText, searchLocale, setSearchLocale, setMessageText, getMaterialDescriptionByFilters,
    mmId, setMmId, searchData, dataNotFound, materialDescriptionLoading } = useContext(MaterialStringsContext);

  const { localeLoading, locales } = useContext(LocaleContext);

  const [selectedDescription, setSelectedDescription] = useState<string>(descriptions[0].value);
  const [searchedDescriptionType, setSearchedDescriptionType] = useState<string>('');
  const [searchConstraint, setSearchConstraint] = useState<string>(constraints[0].value);
  const [ignoreCaseCheckInd, setIgnoreCaseCheckInd] = useState<boolean>(true);

  const [pageNumber, setPageNumber] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);

  const handleStringIdChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const inputValue = event.target.value;
    if (inputValue === '' || numRegex.test(inputValue)) {
      setMmId(inputValue);
    }
  };

  useEffect(() => {
    setSearchConstraint(constraints[0].value);
    setIgnoreCaseCheckInd(true);
    setSelectedDescription(descriptions[0].value);
  }, [searchBy]);

  const handleSearch = (pageNumber: number, itemsPerPage: number) => {
    if (materialDescriptionLoading || ((!mmId || !mmId.trim().length) && (!searchText || !searchText.trim().length))) {
      return;
    }
    setMessageText('errorMessage', '');
    setPageNumber(pageNumber);
    setItemsPerPage(itemsPerPage)
    getMaterialDescriptionByFilters({
      variables: {
        mmId: parseInt(mmId),
        searchText: searchText,
        searchMdField: selectedDescription,
        pageInfo: {
          pageNumber: pageNumber,
          pageSize: itemsPerPage
        },
        searchLocale: searchLocale,
        searchConstraint: searchConstraint,
        ignoreCaseCheckInd: ignoreCaseCheckInd
      }
    }).then(setSearchedDescriptionType(selectedDescription));
  };

  return (
    <>
      <SearchContainer>
        {searchBy === 'mmId' && <Grid container spacing={4}>
          <Grid item xs={4.5}>
            <TextField
              value={mmId}
              onChange={handleStringIdChange}
              label={defaults.enter_material_master_id}
              fullWidth
              onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => e.key === 'Enter' && handleSearch(1, 10)}
            />
          </Grid>
          <ButtonGrid item xs={4}>
            <Button disabled={!mmId || materialDescriptionLoading} onClick={() => handleSearch(1, 10)} adminColor>
              {defaults.search}
            </Button>
          </ButtonGrid>
        </Grid>}
        {searchBy === 'string' && <>
          {!localeLoading && locales && locales.length > 0 &&
            <Grid container spacing={5}>
              <Grid item xs={3.5}>
                <Select
                  label={defaults.description}
                  fullWidth
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSelectedDescription(event.target.value)}
                  value={selectedDescription}>
                  {descriptions.map((description) => (
                    <MenuItem key={description.value} value={description.value}>
                      {description.label}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={3.5}>
                {locales && locales.length && <Select
                  label={defaults.language}
                  fullWidth
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchLocale(event.target.value)}
                  value={searchLocale}>
                  {locales.map((locale: Locale) => (
                    <MenuItem key={locale.code} value={locale.code}>
                      {locale.languageDescription}
                    </MenuItem>
                  ))}
                </Select>}
              </Grid>
              <Grid item xs={3.5}>
                <Select
                  label={defaults.constraints}
                  fullWidth
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchConstraint(event.target.value)}
                  value={searchConstraint}>
                  {constraints.map((constraint) => (
                    <MenuItem key={constraint.value} value={constraint.value}>
                      {constraint.label}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <CheckBoxGrid item xs={1.5}>
                <Checkbox
                  checked={ignoreCaseCheckInd}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => setIgnoreCaseCheckInd(event.target.checked)}
                  color="primary"
                  inputProps={{ 'aria-label': 'ignore case checkbox' }}
                />
                <Typography variant="body2">
                  {defaults.ignore_case}
                </Typography>
              </CheckBoxGrid>
              <Grid item xs={7}>
                <TextField
                  value={searchText}
                  onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => setSearchText(event.target.value)}
                  label={defaults.material_description}
                  fullWidth
                  onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => e.key === 'Enter' && handleSearch(1, 10)}
                />
              </Grid>
              <ButtonGrid item xs={12}>
                <Button disabled={!searchText?.trim().length || materialDescriptionLoading}
                  onClick={() => handleSearch(1, 10)} adminColor>
                  {defaults.search}
                </Button>
              </ButtonGrid>
            </Grid>
          }
        </>
        }
      </SearchContainer>
      {materialDescriptionLoading && <StyledCircularProgress>
        <CircularProgress />
      </StyledCircularProgress>
      }
      {searchData?.lsFetchMaterialDescriptionListUsingSearchFilter?.materialDescriptionList?.length > 0 && (
        <>
          {!materialDescriptionLoading && <SearchMaterialDescriptionStringsTable descriptionType={searchedDescriptionType} />}
          {(searchData.lsFetchMaterialDescriptionListUsingSearchFilter.pageInfo.totalItemCount > 10)
            && !materialDescriptionLoading && <PaginationContainer>
              <Pagination
                rowsPerPage
                menuProps={{ value: defaults.rows_per_page }}
                page={pageNumber}
                size='small'
                itemsPerPageOptions={[10, 30, 50]}
                itemsPerPage={itemsPerPage}
                count={searchData.lsFetchMaterialDescriptionListUsingSearchFilter.pageInfo.totalPageCount}
                onChange={(event, page) => handleSearch(page, itemsPerPage)}
                onItemsPerPageChange={(itemsPerPage) => handleSearch(1, itemsPerPage)}
              />
            </PaginationContainer>}
        </>)}
      {!searchData?.lsFetchMaterialDescriptionListUsingSearchFilter?.materialDescriptionList?.length && !materialDescriptionLoading && <MessageContainer>
        <PlaceHolder color='textSecondary' variant='body1'>
          {
            dataNotFound ? defaults.no_match_data : (
              searchBy === 'mmId' ? defaults.enter_id_search : defaults.enter_text
            )
          }
        </PlaceHolder>
      </MessageContainer>}
    </>
  )
}

export default SearchMaterialDescriptionStrings;