import { Typography, MenuItem, Button } from "@octanner/prism-core";
import { useMutation } from "@apollo/client";
import { VIBE_EDIT_ROOT_NAME } from "./query";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import {
  EditClientInfoContainer,
  DisplayFlex,
  ClientStatusContainer,
  DualActionButtonContainer,
  CustomerContainer,
  DivStatusContainer,
  PipelineContainer,
} from "./styles";
import {
  ClientNameTextFeild,
  StpContainer,
  StpNumber,
  ButtonsDiv,
  CancelButton3,
  SaveClientButton,
} from "../salesOrg/style";
import { StatusDualButton } from "./styles";
import { UPDATE_CUSTOMER_CONTACT_INFO } from "./query";
import ClientUsageTypeModal from "./ClientUsageTypeModal";
import { StatusTag } from "./StatusTag";
import { CustomerStatus } from "./constants";
import { ChangeStatusConfirmation } from "./ChangeStatusConfirmation";

const EditClientInfo = ({
  dataCustomerName,
  setcompanyDetail,
  saveButtonLoading,
  setSaveButtonLoading,
  refetchCustomerName,
  customerId,
  onSapUpdateError,
  setShowStatus,
  setIsMoveToActive,
  statusText,
  setStatusText,
  showStatus,
  clientType,
  setClientType,
  setRefetchEditCustomer,
  setShowWarning,
  setSapErrorDispaly,
  setErrorMessage,
  confirmationData,
  tabsEditModeStatus,
  setTabsEditModeStatus,
  errorsDisplayNumber,
  setCustomerInfo,
  setCalledRefetch,
  isMoveToActive,
}) => {
  const { t } = useTranslation();
  const { name, customerStatus, stpNumber, customerUuid } = dataCustomerName?.customer;
  const [vibeEditRootMutation] = useMutation(VIBE_EDIT_ROOT_NAME);
  const [showClientTypeModal, setShowClientTypeModal] = useState(false);
  const [editCustomerName, setEditCustomerName] = useState(name);
  const [updateCustomerDetails] = useMutation(UPDATE_CUSTOMER_CONTACT_INFO);
  const refetchCustomerDetails = () => {
    refetchCustomerName().then(() => {
      setcompanyDetail(true), setSaveButtonLoading(false), onSapUpdateError("");
    });
  };
  const handleError = (error) => {
    setSaveButtonLoading(false), onSapUpdateError(error?.message);
  };
  const customerNameMaxLength = 40;
  const saveEditCustomer = () => {
    if (
      editCustomerName?.length > 0 &&
      editCustomerName?.length <= customerNameMaxLength
    ) {
      setSaveButtonLoading(true);
      updateCustomerDetails({
        variables: {
          input: {
            customerUuid: customerId,
            customerName: editCustomerName,
          },
        },
      })
        .then(() => {
          vibeEditRootMutation({
            variables: {
              input: {
                coreCustomerUuid: customerId,
                vibeName: editCustomerName,
              }
            }
          });
          refetchCustomerDetails();})
        .catch((error) => handleError(error));
    }
  };
  const handleDualButton = () => {
    setShowStatus(true);
    if (statusText === t("moveToActive")) {
      setIsMoveToActive(true);
    } else {
      setIsMoveToActive(false);
    }
  };
  return (
    <div>
      <CustomerContainer>
        {showStatus && (
          <ChangeStatusConfirmation
            setShowStatus={setShowStatus}
            setShowWarning={setShowWarning}
            setSapErrorDispaly={setSapErrorDispaly}
            dataCustomerName={dataCustomerName}
            setRefetchEditCustomer={setRefetchEditCustomer}
            setErrorMessage={setErrorMessage}
            confirmationData={confirmationData}
            tabsEditModeStatus={tabsEditModeStatus}
            setTabsEditModeStatus={setTabsEditModeStatus}
            errorsDisplayNumber={errorsDisplayNumber}
            setCustomerInfo={setCustomerInfo}
            setCalledRefetch={setCalledRefetch}
            isMoveToActive={isMoveToActive}
          />
        )}
      </CustomerContainer>
      <ClientUsageTypeModal
        showClientTypeModal={showClientTypeModal}
        setShowClientTypeModal={setShowClientTypeModal}
        setClientType={setClientType}
        customerId={customerId}
        dataCustomerName={dataCustomerName}
        clientType={clientType}
        setRefetchEditCustomer={setRefetchEditCustomer}
      />
      <>
        <EditClientInfoContainer>
          <DisplayFlex>
            <ClientNameTextFeild
              label={t("client_name")}
              displayCount
              maxLength={customerNameMaxLength}
              defaultValue={dataCustomerName.customer.name}
              helperText={
                editCustomerName.length > customerNameMaxLength
                  ? t("many_characters")
                  : editCustomerName.length === 0
                  ? t("required_error")
                  : ""
              }
              error={
                editCustomerName.length > customerNameMaxLength ||
                editCustomerName.length === 0
                  ? true
                  : false
              }
              onChange={(e) => setEditCustomerName(e.target.value)}
              test-id="edit-client-name"
            />
            <ButtonsDiv>
              <SaveClientButton
                loading={saveButtonLoading}
                onClick={() => saveEditCustomer()}
                variant="text"
                test-id="save-button"
              >
                {t("save")}
              </SaveClientButton>
              <CancelButton3
                variant="text"
                onClick={() => setcompanyDetail(true)}
                test-id="cancel-button"
                color="secondary"
                buttonType="danger"
              >
                {t("cancel")}
              </CancelButton3>
            </ButtonsDiv>
            <ClientStatusContainer></ClientStatusContainer>
            {/* <ClientUsageEditContainer>
              <ClientUsageTypeSelect
                  clientType={clientType}
                  setClientType={setClientType}
                  setShowClientTypeModal={setShowClientTypeModal}
                  dataCustomerName={dataCustomerName}
                />
              </ClientUsageEditContainer> */}
          </DisplayFlex>
          <DualActionButtonContainer>
            <DivStatusContainer>
              <StpNumber>
                <>
                  <Typography
                    color="textSecondary"
                    variant="body2"
                    test-id="client-status-header"
                  >
                    {t("client_status")}
                  </Typography>
                  <StatusTag customerStatus={customerStatus} />
                </>
              </StpNumber>
            </DivStatusContainer>
            {(customerStatus === CustomerStatus.SOLD ||
              customerStatus === CustomerStatus.LOST) && (
                <Button
                adminColor
                onClick={() => {
                  setShowStatus(true);
                }}
              >
                {t("moveToImplementation")}
              </Button>
            )}

            {customerStatus === CustomerStatus.IMPLEMENTATION && (
              <StatusDualButton
                adminColor
                label={statusText}
                onClick={() => handleDualButton()}
              >
                <MenuItem onClick={() => { setStatusText(t("moveToActive")); console.log('statuseditText', statusText); }}>
                  {t("moveToActive")}
                </MenuItem>
                <MenuItem onClick={() => setStatusText(t("moveToLost"))}>
                  {t("moveToLost")}
                </MenuItem>
              </StatusDualButton>
            )}

            {customerStatus === CustomerStatus.ACTIVE && (
               <Button
               adminColor
               onClick={() => {
                 setShowStatus(true);
               }}
             >
               {t("moveToLost")}
             </Button>
            )}
          </DualActionButtonContainer>
        </EditClientInfoContainer>
        <div style={{
          display: "flex",
        }}>
        {customerStatus !== CustomerStatus.SOLD && (
          <DisplayFlex>
            <div>
              <StpNumber>
                <>
                  <Typography
                    color="textSecondary"
                    variant="body2"
                    test-id="stp-header"
                  >
                    {t("stp") + " :"} {stpNumber}
                  </Typography>
                </>
              </StpNumber>
            </div>
            <PipelineContainer>
             {'|'}
            </PipelineContainer>
            <div>
            <StpContainer>
              <StpNumber>
                <>
                  <Typography
                    color="textSecondary"
                    variant="body2"
                    test-id="stp-header"
                  >
                    {t("customerUuid") + " :"} {customerUuid}
                  </Typography>
                </>
              </StpNumber>
            </StpContainer>
          </div>
          </DisplayFlex>
        )}
        </div>
      </>
    </div>
  );
};

export default EditClientInfo;
