import { Menu, MenuItem, TableCell } from '@octanner/prism-core';
import { MenuDots } from '@octanner/prism-icons';
import React from 'react';
import { Link as RouterLink, useRouteMatch } from 'react-router-dom';
import { tableRows } from '../constants';
import {
  MenuDotsContainer,
  MenuItemColor,
  StatusCellMinWidth,
  TypographyWS,
} from '../styles';

export default function SingleRow({ newOrderAllVMs, changeOrder, index }) {
  const match = useRouteMatch();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleOnMouseEnter = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {tableRows.map(({ key, test, customComponent, align }) => {
        if (customComponent) {
          return (
            <StatusCellMinWidth align={align} key={key}>
              {customComponent(newOrderAllVMs)}
            </StatusCellMinWidth>
          );
        }
        return (
          <TableCell key={key} align={align}>
            <TypographyWS data-test={`programs:${newOrderAllVMs.uuid}:${test}`}>
              {newOrderAllVMs[key] || ''}
            </TypographyWS>
          </TableCell>
        );
      })}

      <TableCell>
        <MenuDotsContainer>
          <MenuDots
            aria-controls="simple-menu"
            aria-haspopup="true"
            onMouseEnter={handleOnMouseEnter}
          />

          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <RouterLink
              style={{ textDecoration: 'none' }}
              to={`${match.url}/${newOrderAllVMs.uuid}/edit`}
              data-test={`programs:${newOrderAllVMs.uuid}:edit`}
            >
              <MenuItemColor>Edit</MenuItemColor>
            </RouterLink>
            <MenuItem
              disabled={index === 0}
              onClick={() => {
                changeOrder(index, 'UP');
                handleClose();
              }}
            >
              Move up
            </MenuItem>
            <MenuItem
              onClick={() => {
                changeOrder(index, 'DOWN');
                handleClose();
              }}
            >
              Move down
            </MenuItem>
          </Menu>
        </MenuDotsContainer>
      </TableCell>
    </>
  );
}
