export const defaults = {
  default: 'Default',
  default_text: 'Description Text',
  language_string: 'Language String',
  enter_english_text_with_max_char_limit: 'Enter English(en_US) text for new string(4000 characters max)',
  language_subtitle: 'Search, Create and Edit Language strings and request their translations',
  search_id: 'Search by String ID',
  search_by_stp: 'Search by STP',
  add_custom_text: 'Add Custom Text',
  add_a_custom_string: 'Add a Custom Text',
  bulk_upload_custom_text: 'Bulk Create',
  search_by_requested_by: 'Search by Requested by Name',
  search_by_requested_by_email: 'Search by Requested by Email',
  enter_sold_to_party: 'Enter SoldToParty',
  enter_requested_by_name: 'Enter Requested by Name',
  enter_requested_by_email: 'Enter Requested By Email',
  custom_text_within: 'Custom Text within',
  language_string_constraint: 'Constraint',
  search: 'Search',
  request_translation_info_message: 'Select a checkbox to send a translation request for the selected language',
  enter_string_id: 'Enter String ID',
  enter_string_id_to_search: 'Enter String ID to search',
  enter_mm_id: 'Enter Mm Id',
  enter_stp: 'Enter SoldToParty to Search',
  search_language_string: 'Search language string',
  search_custom_text: 'Search Custom Text',
  remarks: 'Remarks',
  notes: 'Notes',
  enter_notes: 'Enter Notes',
  enter_source_custom_text: 'Enter Text to Translate',
  enter_remarks: 'Enter Remarks',
  search_string_text: 'Search by String Text',
  search_by_description: 'Search by Description',
  enter_material_master_id: 'Enter Material Master Number',
  enter_id_search: 'Enter an ID to search',
  search_material_desc: 'Search material master description',
  application: 'Application',
  not_announced: 'N/A',
  stp: 'STP',
  pid: 'PID',
  remark: 'Remark',
  edit: 'Edit',
  cancel: 'Cancel',
  save: 'Save',
  id: 'ID',
  string_id: 'String ID',
  string: 'Text',
  active_translations: 'Active Translations',
  actions: 'Actions',
  enter_string: 'Enter a string to search',
  enter_value_to_search: 'Enter a value to search',
  enter_text: 'Enter a text to search',
  string_text: 'String Text',
  material_description: 'Material Description',
  constraints: 'Constraints',
  language_string_whose: 'Language String whose',
  send: 'Send',
  send_request: 'Send Request',
  send_translation_request: 'Sending translation requests for :',
  send_translations_request: 'Send translation request',
  add_remark: 'Add Remark',
  add_note: 'Add Note',
  request_translation: 'Request Translation',
  locale_desc: 'Locale Description',
  program_Id: 'Program Id',
  locale_code: 'Locale Code',
  sold_to_party: 'Sold-to-Party/Product',
  status: 'Status',
  save_all: 'Save All',
  translated: 'Translated',
  created_by: 'Created By',
  no_match_data: 'We couldn’t find anything that matched your search',
  no_notes: 'No Notes',
  save_and_send_request: 'Save and Send Request',
  english_locale_warning: 'If you make changes to the English translations, it could affect translations in other languages. By default, we send out requests for all the existing translations.',
  send_req_for_existing_translations: 'Send request for existing translations',
  source_text_change_value: 'If you make changes to the source text, it could affect translations in other languages. By default, we send out requests for all the existing translations.',
  send_req_for_all_translations: 'Send requests for all of the translations',
  existing_translation: 'Existing Translations',
  use_existing_translation: 'Use existing translations for the above languages and send requests for the rest',
  string_created_msg: 'Language String created successfully',
  translation_requested_msg_for_locales: 'Translation requests have been generated',  
  send_translation_request_for: 'Send translation request for :',
  send_translation: 'Send translation request',
  bulk_upload_title: 'Bulk Create',
  bulk_upload_description: 'Create and request multiple strings in bulk with ease',
  upload_excel_file_title: 'Upload Excel File',
  upload_excel_file_description: 'Please upload the Excel file containing the string IDs',
  upload_excel_file_custom_text_description: 'Please upload the file with custom text and  brief identifier text',
  bulk_request_excel_file_description: 'Please upload the Excel file containing the string IDs',
  bulk_create_excel_file_description: 'Please upload the Excel file containing the strings (Text).',
  select_languages_title: 'Select Languages',
  select_language: 'Select Language',
  filter_languages: 'Filter Languages',
  source_locale: 'Source Locale',
  select_source_locale: 'Select Source Locale',
  select_languages_description: 'Select languages you would like to have translated',
  select_source_language_description: 'Select Source language',
  bulk_create: 'Bulk Create',
  bulk_request: 'Bulk Translation Request',
  upload_excel_file_download_text: 'Download sample file',
  select_all: 'Select All',
  clear_all: 'Clear All',
  add: 'Add',
  add_stp: 'Add STP',
  add_stp_desc: 'Add STP this string is associated with',
  create_strings: 'Create Strings',
  create_custom_text: 'Create Custom Text',
  create_string: 'Create String',
  add_new: 'Add New',
  existing_string_info: 'The String is created. We found a similar phrase in our records. We already have translations for {existingTranslationCount} out of the {totalRequestedCount} languages you requested. Should we use the existing translations for those {existingTranslationCount}, or do you want to request new translations for all the requested languages?',
  file_upload_success: 'Successfully uploaded with 0 errors',
  bulk_success: 'The bulk {bulkUploadType} has been initiated. You will recieve an email notification once it is completed',
  custom_text_bulk_success: 'The custom text bulk upload has been initiated. You will recieve an email notification once it is completed',
  custom_text_bulk_error: 'An error occured while custom text bulk upload. Please try again.',
  bulk_error: 'An error occured while bulk {bulkUploadType}. Please try again.',
  optional: 'Optional',
  required: 'This Field is required *',
  sample_download_failed: 'Failed to fetch Sample Excel file',
  add_string: 'Add String',
  add_string_desc: 'Add New Language String to your application',
  add_custom_text_desc: 'Add New Custom Text String to your application',
  string_information: 'String Information',
  custom_text_information: 'Custom Text Information',
  string_info_desc: 'Select languages you would like to have translated',
  custom_text_info: 'Add custom text information that you want to add and to have translated',
  search_locale_placeholder: 'Enter Language or Locale',
  request_success: 'Translation Requests have been generated for',
  update_success: 'Changes saved successfully',
  update_error: 'An error occured while trying to save changes. Please try again.',
  add_error: 'An error occured while trying to add a custom text. Please try again.',
  fetch_error: 'No Data found for the Search',
  custom_text: 'Custom Text',
  requested_date: 'Requested Date',
  language: 'Language',
  custom_text_subtitle: 'Search, Create and Edit Custom Text strings and request their translations',
  requested_by_name: 'Requested By Name',
  requested_by: 'Requested By',
  requested_by_email: 'Requested By Email',
  ignore_case: 'Ignore Case',
  enter_the_string: 'Enter the string',
  translations: 'Translations',
  select_translations_languages: 'Select Translation Languages',
  source_language: 'Source Language',
  request: 'Request',
  material_descriptions: 'Material Descriptions',
  material_descriptions_subtitle: 'Search, Edit and Request Material Description Translations',
  enter_language_or_locale: 'Enter Language or Locale',
  description: 'Description',
  search_material_master_id: 'Search by Material Master Number',
  mmid: 'MMID',
  send_requests: 'Send Requests',
  english_locale_warning_for_mmd: 'If you change the English translation, it might affect translations in other languages, so we’ll need to ask for updates for all of them. Until you provide the new translations, we’ll stick with the ones you have now.',
  send_req_for_existing_translation: 'Use existing translations for strings that already have translations in the selected languages.',
  toggle_btn_description: 'Disabling this will submit requests for translations of the specified strings, even if translations for those strings already exist in the chosen language',
  select_lang_to_send_translation: 'Select languages, you want to send the translation for :',
  request_translations: 'Request Translations',
  submit_request: 'Submit Request',
  update_success_for_material: 'Changes saved successfully!',
  at_least_one_locale: 'Please select at least one locale',
  translation_request: 'Translation Request',
  translation_request_sub_title: 'Create, Hold, release and delete translation requests',
  requested_strings: "requested string(s)",
  translation_table_sub_heading: 'You can check all the pending requests and start them',
  enter_search_id: 'Search Id',
  select_request_info: 'Select requests to perform any action',
  sap_part_number: 'SAP Part number',
  date: 'Date',
  type: 'Type',
  locale: 'Locale',
  sourceText: 'Source Text',
  on_Hold: 'On Hold',
  delete_requests: 'Delete Requests',
  release_requests: 'Release Requests',
  hold_requests: 'Hold Requests',
  create_job: 'Create Job',
  delete: 'Delete',
  are_you_sure: 'Are you Sure?',
  create_job_error_message: 'An error occured while trying to create the job. Please try again',
  create_job_success_message: 'New job created with JobId:',
  rows_per_page: 'rows per page',
  view_job: 'View Job',
  no_pending_requests: 'No Pending Requests',
  on_hold_selected_info_message: `You've chosen a request that's currently on hold.  To start the job for the selected request, you need to release it.`,
  select_all_on_hold_message: `Strings placed on hold won't be chosen unless you release them.`,
  delete_request_success_message: 'Requests Deleted Successfully',
  delete_request_error_message: 'An error occured while trying to delete the requests. Please try again',
  no_locale_text: 'No locale present for the search text',
  delete_translation_requests_sub_title: 'Are you sure you want to delete the {count} selected request(s)? This action will not delete the original string, this will just delete the requests',
  job_management: 'Job Management',
  job_management_subtitle: 'Search, Manage and send your job for translations.',
  material_master_description: 'Material Master Description',
  fetch_material_desc_error: 'An error occured while fetching material description',
  program_id: 'Program ID',
  date_requested: 'Date Requested',
  delete_custom_text_success: 'Custom text deleted successsfully',
  delete_custom_text_error: 'An error occurred while trying to delete this custom text. Please try again.',
  delete_custom_text_modal_desc: 'Are you sure you want to delete this custom text. You will lose all the translation.',
  delete_custom_text_modal_title: 'Delete custom text?',
  translation: 'Translation',
  search_custom_text_string: 'Search custom text string',
  delete_custom_text: 'Delete Custom Text',
  fetch_custom_text_error: 'An Error Occured while fetching custom text',
  enter_job_id: 'Enter Job Id',
  select_language_or_locale: 'Select Language or Locale',
  jobs_within: 'Jobs Within',
  pending: 'Pending',
  job_details: 'Job Details',
  in_progress: 'In Progress',
  completed: 'Completed',
  job_id: 'Job Id',
  job_created: 'Job Created',
  sent_to_translation: 'Sent To Translation',
  translations_uploaded: 'Translations Uploaded',
  send_for_translation: 'Send for Translation',
  view: 'View',
  upload_translation: 'Upload Translation',
  string_Id: 'String ID',
  english_description: 'English Description',
  enter_sap_part_number: 'Enter Sap Part Number',
  delete_job_title: 'Are you sure you want to delete this job?',
  upload: 'Upload',
  upload_translation_success: 'The file has been uploaded. You will recieve an email notification once it is completed.',
  upload_translation_error: 'An error occured while uploading the file. Please try again',
  mm_id: 'Mm Id',
  request_error: 'An error occured while requesting translations',
  create_error: 'An error occured while creating a language string',
  fetch_string_data_error: 'An error occured while fetching details for Language String',
  send_mail_error: 'An error occured while trying to send the mail. Please try again',
  send_mail_success: `Translations have been sent successfully. You can check them in the 'In Progress' tab`,
  send_to: 'Send To',
  subject: 'Subject',
  rush_job: 'Rush Job',
  message: 'Message',
  attachment: 'Attachment',
  brief_identifier_text: 'Brief Identifier Text',
  source_custom_text: 'Source Custom Text',
  text_to_translate: 'Text to Translate',
  custom_text_update_success: 'Changes Saved Successfully',
  update_custom_text_error: 'Failed to update Custom Text',
  fetch_job_management_error: 'An error occured while fetching Job Management data. Please try again later',
  fetch_translation_request_error: 'An error occured while fetching Translation Request data. Please try again later',
  custom_text_creation_success: 'Custom text string created successfull',
  select_language_desc: 'Select Language for the Source Text',
  job_deleted_sucesss: 'The job has been deleted successfully',
}