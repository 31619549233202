import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { MenuItem, Typography, Button } from '@octanner/prism-core';
import {
  InvoiceHeader,
  InvoiceSelect,
  Feild,
  InvoiceSelectBilling,
  InvoiceSection,
  Invoice,
  ActionButton,
  FlexDiv,
  EditButton,
  InvoiceHead
} from './style';
import { customerId } from '../../utilities/utility';
import { useTranslation } from 'react-i18next';
import { Lock } from '@octanner/prism-icons';
import { UPDATE_CUSTOMER_CONTACT_INFO } from './query';


const InvoiceDetails = ({
  invoiceDetailsInfo,
  refetchInvoice,
  customerinvoiceInfo,
  error,
  errorsDisplayNumber,
  onSave,
  setIsChangedValue,
  onSapUpdateError,
  tabsEditModeStatus,
  setTabsEditModeStatus,
  setUpdateSapError
}) => {
  const { t } = useTranslation();
  useEffect(() => {
    if(tabsEditModeStatus.InvoiceDetails){
      updateInvoice();
      setTabsEditModeStatus({...tabsEditModeStatus, InvoiceDetails : false});
    }
  },[tabsEditModeStatus.InvoiceDetails])

  // octanner available options for invoice
  const {
    allCoreBillingSchedules = {},
    allCoreBillingTypes = {},
    allCoreInvoiceMediums = {},
  } = invoiceDetailsInfo || {};

  const { customerInvoiceConfigurationMapByCustomer } =
    customerinvoiceInfo || {};
  
  const {
    coreBillingSchedule,
    defaultCoreBillingSchedule,
  } = allCoreBillingSchedules;

  const { coreBillingTypes, defaultCoreBillingType } = allCoreBillingTypes;
  const {
    coreInvoiceMediums,
    defaultCoreInvoiceMedium,
  } = allCoreInvoiceMediums;

  let {
    billingSchedule = {},
    billingType = {},
    invoiceMedium = {},
    paymentTerm = {}
  } =
    customerInvoiceConfigurationMapByCustomer?.customerInvoiceConfiguration ||
    {};
    if(!paymentTerm){
      paymentTerm = {
        paymentTermCode : 'Z018',
        paymentTermDescription : 'Net 30'
      }
    }

  const invoiceMediumList = coreInvoiceMediums?.nodes || [];
  const billingScheduleList = coreBillingSchedule?.nodes || [];
  const billingTypeList = coreBillingTypes?.nodes || [];

  const [editInvoice, setEditInvoice] = useState(false);
  const [invoiceDetails, setInvoiceDetails] = useState<any>({});
  const [updateProgress, setUpdateProgress] = useState(false); 

  const handleInvoiceChange = (event: any, key: string) => {
    setIsChangedValue(true);
    setUpdateSapError(false);
    setInvoiceDetails(() => ({
      ...invoiceDetails,
      [key]: event?.target?.value || '',
    }));
  };

  const [updateInvoiceV2, { loading: updateLoadingV2 }] = useMutation(
    UPDATE_CUSTOMER_CONTACT_INFO,
    {
      onCompleted: () => {
        setUpdateProgress(true);
        refetchInvoice().then(() => {
            setEditInvoice(false),
            setUpdateProgress(false),
            setIsChangedValue(false)
            onSapUpdateError('')
            window.scrollTo(0, 0);
        });  
      },
      onError: (error) => {
        setUpdateProgress(false);
        onSapUpdateError(error?.message);
        window.scrollTo(0, 0);
      }
    },
  );

  const setSavedInvoiceDetailsValues = () => {
    setInvoiceDetails({
      paymentTerms: paymentTerm?.paymentTermCode,
      invoiceMedium: invoiceMedium?.invoiceMediumCode,
      billingSchedule: billingSchedule?.billingScheduleCode,
      billingType: billingType?.billingTypeCode,
    });
  }

  useEffect(() => {
    setSavedInvoiceDetailsValues();
  }, [customerInvoiceConfigurationMapByCustomer, invoiceDetailsInfo]);


  useEffect(() => {
    // If in case of error with invoice then set to defaults 
    if(error){
      updateInvoiceV2({
        variables: {
          input: {
            customerUuid: customerId,
            customerInvoiceInput: {
              billingScheduleCode: defaultCoreBillingSchedule?.billingScheduleCode,
              billingTypeCode: defaultCoreBillingType?.billingTypeCode,
              invoiceMediumCode: defaultCoreInvoiceMedium?.invoiceMediumCode,
            }
          }
        }
      })
    }
  }, [error])

  const updateInvoice = () => {
    const {invoiceMedium, billingSchedule, billingType} = invoiceDetails
      updateInvoiceV2({
        variables: {
          input: {
            customerUuid: customerId,
            customerInvoiceInput: {
              billingScheduleCode: billingSchedule,
              billingTypeCode: billingType,
              invoiceMediumCode: invoiceMedium,
            }
          }
        }
      })
  };

  const getDetails = (heading, subHeading) => (
    <>
      <Typography color="textSecondary">{heading}</Typography>
      <Typography>{subHeading}</Typography>
    </>
  );

  return (
    <Invoice>
      {editInvoice ? (
        <>
          <Typography variant='h3' test-id = "invoice-heading">{t('invoicing_details')}</Typography>
          <InvoiceSection>
            <FlexDiv>
            <InvoiceSelect
                label={t('payment_terms')}
                defaultValue={paymentTerm.paymentTermCode}
                test-id = "payment-terms"
                disabled={true}
                IconComponent={Lock}
              >
                <MenuItem value={paymentTerm.paymentTermCode} key={paymentTerm.paymentTermCode}>
                  {paymentTerm.paymentTermDescription}
                </MenuItem>
              </InvoiceSelect>
            </FlexDiv>
            <InvoiceSelect
              label={t('invoice_medium')}
              onChange={(e) => {
                handleInvoiceChange(e, 'invoiceMedium');
              }}
              defaultValue={
                invoiceMedium?.invoiceMediumCode ||
                defaultCoreInvoiceMedium?.invoiceMediumCode
              }
            >
              {invoiceMediumList.map(
                ({ invoiceMediumCode, invoiceMediumDescription }) => (
                  <MenuItem value={invoiceMediumCode} key={invoiceMediumCode}>
                    {invoiceMediumDescription}
                  </MenuItem>
                ),
              )}
            </InvoiceSelect>

            <InvoiceSelect
              label={t('billing_schedule')}
              onChange={(e) => {
                handleInvoiceChange(e, 'billingSchedule');
              }}
              defaultValue={
                billingSchedule?.billingScheduleCode ||
                defaultCoreBillingSchedule?.billingScheduleCode
              }
            >
              {billingScheduleList.map(
                ({ billingScheduleCode, billingScheduleDescription }) => (
                  <MenuItem
                    value={billingScheduleCode}
                    key={billingScheduleCode}
                  >
                    {billingScheduleDescription}
                  </MenuItem>
                ),
              )}
            </InvoiceSelect>

            <InvoiceSelectBilling
              label={t('billing_type')}
              onChange={(e) => {
                handleInvoiceChange(e, 'billingType');
              }}
              defaultValue={
                billingType?.billingTypeCode ||
                defaultCoreBillingType?.billingTypeCode
              }
            >
              {billingTypeList.map(
                ({ billingTypeCode, billingTypeDescription }) => (
                  <MenuItem value={billingTypeCode} key={billingTypeCode}>
                    {billingTypeDescription}
                  </MenuItem>
                ),
              )}
            </InvoiceSelectBilling>

            <ActionButton>
              <Button
                adminColor
                loading={updateLoadingV2 || updateProgress}
                onClick={() => updateInvoice()}
              >
                {t('save_invoice')}
              </Button>
              &nbsp;&nbsp;
              {customerInvoiceConfigurationMapByCustomer && (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    setIsChangedValue(false);
                    setEditInvoice(false);
                    setSavedInvoiceDetailsValues();
                    onSave({...errorsDisplayNumber, InvoiceDetails :  0});
                  }}>
                  {t('cancel')}
                </Button>
              )}
            </ActionButton>
          </InvoiceSection>
        </>
      ) : (
        <>
          <InvoiceHeader>
            <InvoiceHead variant='h3'>
              {t('invoicing_details')}
            </InvoiceHead>
            <EditButton
              variant="text"
              onClick={() => setEditInvoice(true)}
            >
              {t('edit_section')}
            </EditButton>
          </InvoiceHeader>
          <div style={{ display: 'flex'}}>
            <div>
              {getDetails(t('payment_terms_1'), paymentTerm.paymentTermDescription)}
            </div>
          </div>
          <Feild>
            {getDetails(t('invoice_medium_1'), invoiceMedium?.invoiceMediumDescription)}
          </Feild>
          <Feild>
            {getDetails(t('billing_schedule_1'), billingSchedule?.billingScheduleDescription)}
          </Feild>
          <Feild>
            {getDetails(t('billing_type_1'), billingType?.billingTypeDescription)}
          </Feild>
        </>
      )}
    </Invoice>
  );
};

export default InvoiceDetails;
