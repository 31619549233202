import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { CHECKED_SALES_ORG } from './query';
import { SALES_ORG_LIST } from '../../../utils/commonQueries';
import SalesOrg from './SalesOrg';
import { customerId } from '../../utilities/utility';
import LanguagesLoaderIcon from '../languages/LanguagesLoaderIcon';
import EditSalesOrgList from './EditSalesOrgList';
import { useTranslation } from 'react-i18next';

const SalesOrgLoading = ({
  confirmationData,
  onTabSwitch,
  errorsDisplayNumber,
  onSave,
  clientSalesOrgWarning,
  showWarning,
  onSapUpdateError,
  tabsEditModeStatus,
  setTabsEditModeStatus,
  setClientSalesOrgWarning,
  ccAdminEnableComboModel
}) => {
  const { t } = useTranslation();
  const { loading: salesOrgProgress, error : salesOrgError, data: salesOrgList } = useQuery(SALES_ORG_LIST);
  const {
    loading: clientSalesOrgProgress,
    data: clientSalesOrg,
    error: clientSalesOrgError,
    refetch: refetchClientSalesOrg,
  } = useQuery(CHECKED_SALES_ORG, { variables: { customerId: customerId } });

  const [editSalesOrg, setEditSalesOrg] = useState(false);
  const [salesOrgDataChanged, setSalesOrgDataChanged] = useState(false);

  useEffect(() => {
    const { customerCountrySalesOrgMap = []}  = clientSalesOrg?.customerCountrySalesOrgMapsByCustomer || {};
      setEditSalesOrg(customerCountrySalesOrgMap?.length === 0);
  }, [clientSalesOrg]);

  useEffect(() =>
  {
    if(salesOrgDataChanged){
        onTabSwitch({...confirmationData, editMode: true });
    }else{
      onTabSwitch({...confirmationData, editMode: false });
    }
  }, [salesOrgDataChanged]);





  return (
    <div>
      {(salesOrgProgress || clientSalesOrgProgress) && (
        <div style={{ marginTop: '50px' }}>
        <LanguagesLoaderIcon />
      </div>
      )}
      {(clientSalesOrg && salesOrgList) && (editSalesOrg ?
        (<SalesOrg
          salesOrgList={salesOrgList}
          clientSalesOrg={clientSalesOrg}
          refetchClientSalesOrg={refetchClientSalesOrg}
          setEditSalesOrg={setEditSalesOrg}
          errorsDisplayNumber={errorsDisplayNumber}
          onSave={onSave}
          setSalesOrgDataChanged = {setSalesOrgDataChanged}
          clientSalesOrgWarning={clientSalesOrgWarning}
          onSapUpdateError={onSapUpdateError}
          tabsEditModeStatus={tabsEditModeStatus}
          setTabsEditModeStatus={setTabsEditModeStatus}
          setClientSalesOrgWarning={setClientSalesOrgWarning}
          ccAdminEnableComboModel={ccAdminEnableComboModel}
        />)
        : (
          <EditSalesOrgList
            setEditSalesOrg={setEditSalesOrg}
            clientSalesOrg={clientSalesOrg}
            refetchClientSalesOrg={refetchClientSalesOrg}
            errorsDisplayNumber={errorsDisplayNumber}
            onSave={onSave}
            showWarning={showWarning}
            setClientSalesOrgWarning={setClientSalesOrgWarning}
            onSapUpdateError={onSapUpdateError}
          />
        )
      )}
      {( salesOrgError || clientSalesOrgError ) && (
        <div>{t('error')}</div>
      )}
    </div>
  );
};

export default SalesOrgLoading;
