import React, { useContext } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { AppContext } from '../utils/context/AppContext';
import Authorizer from '../utils/auth/Authorizer';
import { CustomerContext } from '../utils/context/CustomerContext';

interface Props extends RouteProps {
  component?: any;
  authorizer: Authorizer;
  path: string | string[];
  exact?: boolean;
}

export default function ProtectedRoute({
  component: Component,
  authorizer,
  ...rest
}: Props) {
  const context = useContext(AppContext);
  const {
    customer: { id },
    isDefault,
  } = useContext(CustomerContext);
  const customerId = isDefault ? undefined : id;

  return (
    <Route
      {...rest}
      render={(props) =>
        authorizer(context, customerId) ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/client-list',
            }}
          />
        )
      }
    />
  );
}
