import { AppContext } from '../../../../../utils/context/AppContext';
import { useContext } from 'react';
import { CREATE_NEW_ADMIN } from '../graphql/gql';
import { CreateAdminInput, CreateAdminResponse } from '../graphql/types';
import { useMutation } from '@apollo/client';

const mockData = () => [
  (options?) => {
    return new Promise((resolve) => {
      resolve({
        data: {
          rdAddRolesToAdmin: {
            identityRoleAsmts: {
              id: '1',
              identity: {
                identityUuid: '1',
              },
              role: {
                uuid: '1',
                name: 'Role 1',
                description: 'Role 1 Description',
                accessType: 'Admin',
                createdTsz: 1234567890,
                deactivatedTsz: 1234567890,
              },
            },
          },
        },
      });
    });
  },
];

export const useCreateAdmin = () => {
  const { config } = useContext(AppContext);
  const mutation = useMutation<CreateAdminResponse, CreateAdminInput>(
    CREATE_NEW_ADMIN,
    {
      context: {
        clientName: 'roleDelegator',
      },
    }
  );
  return config.useRoleDelegatorMockData ? mockData() : mutation;
};
