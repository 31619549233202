import { gql, useMutation, useQuery } from '@apollo/client';
import { CoupleLocaleInput, DecoupleLocaleInput } from './types';

export const CUSTOMER_LOCALE_BY_CUSTOMER = gql`
  query CustomerCoreLocaleMapsByCustomer(
    $input: CustomerCoreLocaleMapsByCustomerInput!
  ) {
    customerCoreLocaleMapsByCustomer(input: $input) {
      nodes {
        locale {
          code
          languageDescription
        }
        isActive
        isDefaultLocale
      }
    }
  }
`;

export const GET_LOCALES = gql`
  query GetLocales {
    getCoreLocales {
      coreLocales {
        nodes {
          code
          languageDescription
        }
      }
    }
  }
`;

export const MUTATE_COUPLE_LOCALE = gql`
  mutation CoupleCustomerAndLocale($input: CustomerLocaleMapInsertRequest!) {
    coupleCustomerAndLocale(input: $input) {
      customerLocale {
        locale {
          code
          languageDescription
        }
        isActive
        isDefaultLocale
      }
    }
  }
`;

export const MUTATE_DECOUPLE_LOCALE = gql`
  mutation DecoupleCustomerAndLocale(
    $input: DecoupleCustomerLocaleMapRequest!
  ) {
    decoupleCustomerAndLocale(input: $input) {
      customerLocale {
        locale {
          code
          languageDescription
        }
      }
    }
  }
`;

export const useGetAllLocales = () => useQuery(GET_LOCALES);

export const useGetCustomerLocale = (input: { customerId: string }) =>
  useQuery(CUSTOMER_LOCALE_BY_CUSTOMER, {
    variables: { input },
  });

export const useCoupleLocale = () =>
  useMutation<any, CoupleLocaleInput>(MUTATE_COUPLE_LOCALE);

export const useDecoupleLocale = () =>
  useMutation<any, DecoupleLocaleInput>(MUTATE_DECOUPLE_LOCALE);
