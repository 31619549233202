import { useContext } from 'react';
import { Link as RouterLink, RouteComponentProps } from 'react-router-dom';
import { Alert, Typography } from '@octanner/prism-core/';
import BasicPadding from '../../../common/BasicPadding';
import { CustomerContext } from '../../../utils/context/CustomerContext';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useBank } from './hooks';
import Loading from '../../../common/Loading';
import { toBankListVM } from './models';
import {
  BankMarginTop,
  StatusLabelMarginTop,
  MarginBottomButton,
} from './styles';

const ViewBank = (props: RouteComponentProps<{ bankId: string }>) => {
  const { bankId } = props.match.params;
  const {
    customer: { id: customerId },
  } = useContext(CustomerContext);

  const { loading, error, data } = useBank(customerId, bankId);
  const bank = data?.corePtMgmtGetBankInformation?.information
    ? toBankListVM(data?.corePtMgmtGetBankInformation?.information)
    : undefined;

  if (error) {
    return <Alert>{error}</Alert>;
  }
  if (loading) {
    return (
      <BasicPadding>
        <Loading />
      </BasicPadding>
    );
  }
  if (!data) {
    return <Alert>There is no bank with id: {bankId}</Alert>;
  }

  return (
    <BasicPadding>
      <RouterLink to={`/customer/${customerId}/banks`}>
        <MarginBottomButton
          variant="text"
          data-test="bank:back"
          startIcon={<ArrowBackIcon />}
        >
          Back to Banks
        </MarginBottomButton>
      </RouterLink>
      <Typography variant="h1" data-test="bank:name">
        {bank?.name}
      </Typography>
      <BankMarginTop>Bank Id: {bankId}</BankMarginTop>
      <BankMarginTop>Bank Type: {bank?.bankType}</BankMarginTop>

      <StatusLabelMarginTop
        status={bank?.status ? bank?.status : ''}
        dataTest="bank-status"
      />
    </BasicPadding>
  );
};

export default ViewBank;
