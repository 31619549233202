import { Switch } from '@octanner/prism-core'
import ArrowRightIcon from '@octanner/prism-icons/ArrowRight'
import clsx from 'clsx'
import { css } from '@emotion/react'
import { styled } from '@mui/material/styles'
import React from 'react'
import { CenteredTypography, PointerLabel } from '../Styled'
import { Dispatch } from '../../../models/GroupSettingsContext'

interface Props {
  isOpen: boolean
  onChange?: (isOpen: boolean) => void | Dispatch
  text: string
  variant?: 'h4' | 'body1'
  className?: string
  id?: string
  children?: JSX.Element
  hideIcon?: boolean
  fixedHeight?: boolean
  dataTestPrefix?: string
  disabled?: boolean
  enableSwitch?: boolean
}

interface IconProps {
  isOpen?: boolean
  className?: string
}

const StyledArrow = styled(ArrowRightIcon)`
  margin-top: auto;
  margin-bottom: auto;
`

const CenteredCollapseIcon = React.forwardRef<HTMLOrSVGElement, IconProps>(
  ({ isOpen, className }, ref) => (
    /* TODO figure out why 'fr is being asked for' */
    /* @ts-ignore asking for unknown prop */
    <StyledArrow
      ref={ref}
      className={className}
      style={{
        transition: 'all 200ms',
        transform: `rotateZ(${isOpen ? '90deg' : '0deg'})`,
      }}
    ></StyledArrow>
  )
)
CenteredCollapseIcon.displayName = 'CenteredCollapseIcon'

const fixedHeightClass = css`
    height: 56px;
  `,
  flexClass = css`
    display: flex;
  `,
  withChildrenClass = css`
    display: flex;
    flex-direction: column;
    > label {
      display: flex;
    }
  `,
  disabledPointer = css`
    cursor: unset;
  `

export default function Collapse({
  id,
  isOpen,
  onChange,
  className,
  text,
  variant = 'h4',
  children,
  hideIcon,
  fixedHeight,
  dataTestPrefix,
  disabled,
  enableSwitch = true,
}: Props) {
  return (
    <section
      className={clsx(
        className,
        children ? withChildrenClass : flexClass,
        fixedHeight && fixedHeightClass
      )}
      data-testid={`${dataTestPrefix}:${id}:section`}
    >
      <PointerLabel
        data-testid={`${dataTestPrefix}:${id}:label`}
        htmlFor={id}
        className={clsx(disabled && disabledPointer)}
      >
        {enableSwitch && (
          <Switch
            id={id}
            disabled={disabled}
            checked={isOpen}
            onChange={(e) => onChange(e.target.checked)}
            data-testid={`${dataTestPrefix}:${id}:switch`}
            inputProps={{
              // @ts-ignore this is a real property of inputProps
              'data-testid': `${dataTestPrefix}:${id}:input`,
            }}
          />
        )}
        <CenteredTypography
          variant={variant}
          data-testid={`${dataTestPrefix}:${id}:text`}
        >
          {text}
        </CenteredTypography>
        {!hideIcon && <CenteredCollapseIcon isOpen={isOpen} />}
      </PointerLabel>
      {isOpen && children}
    </section>
  )
}
