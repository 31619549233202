import { FeatureRow } from './type';

export const featuresRows: FeatureRow[] = [
  {
    dataTest: 'featuresFormName',
    name: 'Name',
    key: 'name',
  },
  {
    dataTest: 'featuresFormStartDate',
    name: 'Start Date',
    key: 'startDate',
  },
  {
    dataTest: 'featuresFormEndDate',
    name: 'End Date',
    key: 'endDate',
  },
  {
    dataTest: 'featuresFormStatus',
    name: 'Status',
    key: 'status',
  },
];
