import styled from '@emotion/styled';
import { Typography } from '@octanner/prism-core';
import Colors from '../../../common/constants/Colors';

export const Mb24Typography = styled(Typography)`
  margin-bottom: 24px;
`;

export const FlexBetweenDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
`;

export const LineBreak = styled.hr`
  width: 100%;
  border: solid 1px #c4c4c4;
`;

export const TitleTypography = styled(Typography)`
  margin-bottom: 8px;
`;

export const SubtitleTypography = styled(Typography)`
  margin-bottom: 8px;
  color: ${Colors.gray800};
`;

export const StrengthsRadioGroup = styled.div`
  margin-left: 28px;
`;
