import { gql } from '@apollo/client';

export const GET_CUSTOMER_NAME = gql`
  query Customer($customerId: String!) {
    customer(id: $customerId) {
      customerUuid
      name
      region
      customerType
      customerUsage
      customerStatus
      stpNumber
      launchedAtTsz
      victoriesCustomerId
      customerAltName
      customerClassName
      modifiedOn
      modifiedBy
      specialInstructions
    }
  }
`;

export const GET_CUSTOMER_DETAILS = gql`
  query Query($customerId: String!) {
    customer(id: $customerId) {
      name
      stpNumber
      contactInfo {
        salesTerritory {
          salesTerritoryCode
        }
        country {
          id
          iso3Code
          iso2Code
          countryNum
          countryName
          continent {
            continentCode
            continentName
          }
          intlDialCode
          tld
          dateFormat
          numberFormat
          currencyFormat
        }
        addressLine1
        addressLine2
        addressLine3
        city
        state {
          stateCode
        }
        postalCode
        primaryContactName
        phoneNumber
        faxNumber
        emailAddress
      }
    }
  }
`;

// queries to now where coustomer fresh or old


export const CHECKED_LOCALES_LIST = gql`
  query CustomerCoreLocaleMapsByCustomer(
    $input: CustomerCoreLocaleMapsByCustomerInput!
  ) {
    customerCoreLocaleMapsByCustomer(input: $input) {
      nodes {
        isDefaultLocale
        locale {
          code
          languageDescription
        }
      }
    }
  }
`;

export const GET_CUSTOMER_INFO = gql`
  query customerContactInformationMapByCustomer($customerId: UUID!) {
    customerContactInformationMapByCustomer(customerId: $customerId) {
      customerContactInfo {
        salesTerritory {
          salesTerritoryCode
          salesTerritoryDescription
        }
        country {
          iso2Code
          countryName
        }
        addressLine1
        addressLine2
        addressLine3
        city
        state {
          stateCode
        }
        postalCode
        primaryContactName
        phoneNumber
        faxNumber
        emailAddress
      }
    }
  }
`;

export const CHANGE_CUSTOMER_STATUS=gql`
mutation TransitionCustomerStatus($input: TransitionCustomerStatusInput!) {
  transitionCustomerStatus(input: $input) {
    customerStatusTransition {
      toStatus
    }
  }
}
`;

export const UPDATE_CUSTOMER_CONTACT_INFO = gql`
mutation EditCustomerV2($input: EditCustomerInputV2!) {
  editCustomerV2(input: $input) {
    customerUuid
  }
}
`;

export const VIBE_EDIT_ROOT_NAME = gql`
  mutation VibeEditRoot($input: VibeEditRootInput!) {
    vibeEditRoot(input: $input) {
      response
    }
  }
`;


export const UPDATE_CUSTOMER_LAUNCH_DATE = gql`
mutation UpdateCustomerLaunchDate($input: UpdateCustomerLaunchDateInput!) {
  updateCustomerLaunchDate(input: $input) {
    customerUsage
    launchedAtTsz
  }
}
`;
