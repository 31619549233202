import { BaseAccrualPointsConfig } from 'library/src/common/models/YbUpdateAccrualPointsConfig'
import useGetBudgetGroup from 'library/src/graphql/useGetBudgetGroup'
import useGetBudgetGroupRegions from 'library/src/graphql/useGetBudgetGroupRegions'
import React, { useMemo } from 'react'
import useGetBudgetGroupCountries from '../../graphql/useGetBudgetGroupCountries'
import BudgetGroupForm from '../BudgetGroupForm/BudgetGroupForm'
import useDeleteAccrualPointsConfig from '../../graphql/useDeleteAccrualPointsConfig'
import useCreateAccrualPointsConfig from 'library/src/graphql/useCreateAccrualPointsConfig'
import useUpdateAccrualPointsConfig from 'library/src/graphql/useUpdateAccrualPointsConfig'
import { YearbookProgramSettingsChangeEvent } from 'library/src/models/YearbookProgramSettingsChangeEvent'

interface Props {
  onChange: (event: YearbookProgramSettingsChangeEvent) => void
  programId: string
  budgetGroupId?: string
}

const BudgetGroupFormContainer: React.FC<Props> = ({
  programId,
  budgetGroupId,
  onChange,
}) => {
  const countries = useGetBudgetGroupCountries(programId),
    budgetGroup = useGetBudgetGroup(programId, budgetGroupId),
    regions = useGetBudgetGroupRegions(programId),
    defaultRegion = regions?.find((r) => r.defaultRegion),
    [deleteConfig] = useDeleteAccrualPointsConfig(),
    [createConfig] = useCreateAccrualPointsConfig(),
    [updateConfig] = useUpdateAccrualPointsConfig(),
    levels = useMemo(
      () =>
        defaultRegion
          ? defaultRegion.values.map((pv) => pv.celebrationLevel)
          : [],
      [defaultRegion]
    ),
    handleOnDelete = async () => {
      if (!budgetGroup || budgetGroup.defaultRegion) {
        return
      }
      await deleteConfig(budgetGroup.id, programId)
      onChange('delete')
    },
    handleSubmit = async (baseConfig: BaseAccrualPointsConfig) => {
      if (budgetGroup) {
        await updateConfig(programId, baseConfig, budgetGroupId)
        onChange('update')
      } else {
        await createConfig(programId, baseConfig)
        onChange('create')
      }
    }

  if (!countries.length || !defaultRegion || (budgetGroupId && !budgetGroup))
    return null

  return (
    <BudgetGroupForm
      defaultValue={100}
      countries={countries}
      budgetGroup={budgetGroup}
      levels={levels}
      onSubmit={handleSubmit}
      onCancel={() => onChange('cancel')}
      onDelete={handleOnDelete}
    />
  )
}

export default BudgetGroupFormContainer
