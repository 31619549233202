export const defaults = {
  client_creation : 'Client Creation',
  search_sales_territory: 'Search Sales Territory',
  search_country: 'Search Country',
  address_1: 'Address 1/PO Box',
  address_2: 'Address 2',
  address_3: 'Address 3',
  city: 'City',
  primary_contact_name: 'Primary Contact Name',
  email: 'Email',
  phone: 'Phone',
  fax: 'Fax',
  client_address_details: 'Client Address Details',
  postal_zip_code: 'Postal/Zip Code',
  state_province_region: 'State/Province/Region',
  client_contact_information: 'Client Contact Information',
  this_field_is_required: 'This field is required.',
  payment_terms: 'Payment Terms',
  invoice_medium: 'Invoice Medium',
  billing_schedule: 'Billing Schedule',
  billing_type: 'Billing Type',
  create_client: 'Create Client',
  cancel: 'Cancel',
  reset : 'Reset',
  reset_description : 'Are you sure you want to reset the form?',
  reset_title : 'Cancelling will reset the form',
  success_alert : 'Client has been successfully created',
  required_field_alert : 'Please fill out all mandatory fields',
  continue : 'Continue',
  back : 'Back',
  company_name : 'Add company/client’s name',
  select_pii_region : 'Select the PII data storage region',
  select_client_type:'Select the primary use type of this client',
  select_deafult_country : 'Select the default country for this client',
  select_deafult_sales_org:'Select the default sales org for this client',
  select_primary_country: 'Select the primary country for this client',
  select_primary_sales_org:'Select the primary sales org for this client',
  too_many_characters : 'Too many characters.',
  optional : 'Optional',
  postal_code_err:'You have not entered a postal code or postal code for the post box',
  client_creation_error : 'Client creation failed. Please try again.',
  invoicing_details : 'Invoicing Details'
};
