import { MenuItem, Search } from '@octanner/prism-core';
import { tannerGray } from '@octanner/prism-core/ThemeProvider/colors';
import { useEffect, useState } from 'react';
import { AdminRoleInformation } from '../types';
import { useDebouncedValue } from '@octanner/prism-core/hooks';
import Typography from '@octanner/prism-core/Typography';
import {
  SearchTitleAndTypeContainer,
  TypographySearchSubTitle,
  TypographySearchType,
} from '../styles';
import { TEST_ADMIN_ROLE_DATA } from '../constants';

const AddAdminRoleSearch = ({
  rolesToAdd,
  setRolesToAdd,
}: {
  rolesToAdd: AdminRoleInformation[];
  setRolesToAdd: (adminRoles: AdminRoleInformation[]) => void;
}) => {
  const [searchSuggestions, setSearchSuggestions] = useState<
    AdminRoleInformation[]
  >([]);
  const [allData, _setAllData] = useState(TEST_ADMIN_ROLE_DATA);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const debouncedText = useDebouncedValue(searchText);
  const [options, setOptions] = useState<AdminRoleInformation[]>([]);

  useEffect(() => {
    if (searchText) {
      setLoading(true);
      const timeoutId = setTimeout(() => {
        setLoading(false);
        setOptions(
          allData.filter((option) =>
            new RegExp(searchText, 'ig').test(option.name)
          )
        );
      }, 500 + Math.floor(Math.random() * 1000));
      return () => {
        clearTimeout(timeoutId);
        setLoading(false);
      };
    } else {
      setOptions([]);
    }
  }, [debouncedText]);

  return (
    <Search
      value={searchSuggestions}
      id="add-role-search"
      inputDataTestId="add-role:search:input"
      noOptionsText="No results found"
      loading={loading}
      freeSolo={false}
      forcePopupIcon={false}
      onChange={(_, value) => {
        setRolesToAdd([...value, ...rolesToAdd]);
      }}
      onInputChange={(_, e) => {
        setSearchText(e);
      }}
      placeholder="Search by role name"
      options={options}
      renderOption={(props, option) => {
        const { className, ...optionProps } = props;
        return (
          <MenuItem {...optionProps} key={option.role.uuid}>
            <div>
              <SearchTitleAndTypeContainer>
                <Typography>{option.name}</Typography>
                <TypographySearchType>
                  {option.role.accessType}
                </TypographySearchType>
              </SearchTitleAndTypeContainer>
              <TypographySearchSubTitle>
                {option.role.description}
              </TypographySearchSubTitle>
            </div>
          </MenuItem>
        );
      }}
      renderTags={(_value, _getTagProps) => null}
      filterOptions={(options) => {
        return options.filter((option) => !rolesToAdd.includes(option));
      }}
      sx={{
        width: 540,
        mt: 4,
        '& .MuiSvgIcon-root': {
          color: tannerGray[400],
        },
      }}
    />
  );
};

export default AddAdminRoleSearch;
