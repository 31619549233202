import { useContext, useEffect, useState } from 'react';
import BasicPadding from '../../../../../common/BasicPadding';
import AdminRoleHeader from '../components/adminRoleHeader';
import {
  ErrorData,
  Errors,
  Permission,
  Success,
  SuccessData,
} from '../../types';
import { ErrorHandler } from '../../ErrorHandler';
import PageWrapper from '../../components/PageWrapper';
import { useHistory, useLocation } from 'react-router';
import UnsavedModal from '../../components/UnsavedModal';
import { AssignPermContainer } from '../components/styles';
import { Typography } from '@octanner/prism-core';
import RolePermissions from '../components/rolePermissions';
import DeleteRoleModal from '../../components/DeleteRoleModal';
import { validateAdminRole, validatePermissions } from '../utils';
import { useParams } from 'react-router-dom';
import { AlertContainer } from '../styles';
import { useGetAdminRole } from '../hooks/getAdminRole';
import NotFoundPage from '../../../../notFoundPage';
import Loading from '../../../../../common/Loading';
import { useUpdateRole } from '../hooks/updateRole';
import { FetchResult } from '@apollo/client';
import { useDeleteRole } from '../hooks/deleteRole';
import { AppContext } from '../../../../../utils/context/AppContext';

const EditAdminRole = () => {
  const TYPE = 'edit';
  const history = useHistory();
  const location = useLocation();
  const { roleUuid } = useParams<{ roleUuid: string }>();
  const queryParams = new URLSearchParams(location.search);
  const { data: role, loading, refetch } = useGetAdminRole(roleUuid);
  const [roleName, setRoleName] = useState('');
  const [roleDesc, setRoleDesc] = useState('');
  const [roleType, setRoleType] = useState('ADMIN');
  const [selectedPermissions, setSelectedPermissions] = useState<Permission[]>(
    []
  );
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [unsavedModalOpen, setUnsavedModalOpen] = useState(false);
  const [deleteRoleModalOpen, setDeleteRoleModalOpen] = useState(false);
  const [saveRole] = useUpdateRole();
  const [deleteRole] = useDeleteRole();
  const { config } = useContext(AppContext);

  const [roleNameCharLimit, setRoleNameCharLimit] = useState<ErrorData>({
    error: false,
    message: '',
  });
  const [roleNameEmpty, setRoleNameEmpty] = useState<ErrorData>({
    error: false,
    message: '',
  });
  const [roleNameDuplicate, setRoleNameDuplicate] = useState<ErrorData>({
    error: false,
    message: '',
  });
  const [roleDescCharLimit, setRoleDescCharLimit] = useState<ErrorData>({
    error: false,
    message: '',
  });
  const [roleDescEmpty, setRoleDescEmpty] = useState<ErrorData>({
    error: false,
    message: '',
  });
  const [roleTypeEmpty, setRoleTypeEmpty] = useState<ErrorData>({
    error: false,
    message: '',
  });
  const [serverErrors, setServerErrors] = useState<ErrorData>({
    error: false,
    message: '',
  });

  const [hasPermission, setHasPermission] = useState<ErrorData>({
    error: false,
    message:
      'At least one permission must be active to save an active admin role. Add a permission below to continue.',
  });

  const [createSuccessData, setCreateSuccess] = useState<SuccessData>({
    enabled: false,
    message: '{Admin Role Name} was successfully added.',
  });
  const createSuccess: Success = {
    successData: createSuccessData,
    setSuccessData: setCreateSuccess,
  };

  const [editSuccessData, setEditSuccess] = useState<SuccessData>({
    enabled: false,
    message: '{Admin Role Name} was successfully saved.',
  });
  const editSuccess: Success = {
    successData: editSuccessData,
    setSuccessData: setEditSuccess,
  };

  useEffect(() => {
    if (!loading && !!role) {
      setRoleName(role.name);
      setRoleDesc(role.description || '');
      setRoleType(role.accessType);
      setSelectedPermissions(role.permissions);
      setCreateSuccess({
        ...createSuccess,
        enabled: queryParams.get('isNew') === 'true',
        message: `${role.name} was successfully added.`,
      });
    }
  }, [loading, role]);

  const permissionErrors: Errors[] = [
    {
      key: 'permissionCount',
      errorData: hasPermission,
      setErrorData: setHasPermission,
    },
  ];
  const errors: Errors[] = [
    {
      key: 'roleNameCharLimit',
      errorData: roleNameCharLimit,
      setErrorData: setRoleNameCharLimit,
    },
    {
      key: 'roleNameEmpty',
      errorData: roleNameEmpty,
      setErrorData: setRoleNameEmpty,
    },
    {
      key: 'roleNameDuplicate',
      errorData: roleNameDuplicate,
      setErrorData: setRoleNameDuplicate,
    },
    {
      key: 'roleDescCharLimit',
      errorData: roleDescCharLimit,
      setErrorData: setRoleDescCharLimit,
    },
    {
      key: 'roleDescEmpty',
      errorData: roleDescEmpty,
      setErrorData: setRoleDescEmpty,
    },
    {
      key: 'roleTypeEmpty',
      errorData: roleTypeEmpty,
      setErrorData: setRoleTypeEmpty,
    },
    {
      key: 'serverErrors',
      errorData: serverErrors,
      setErrorData: setServerErrors,
    },
  ];

  const resetErrors = () => {
    setRoleNameEmpty((prev) => ({ ...prev, error: false }));
    setRoleNameEmpty((prev) => ({ ...prev, error: false }));
    setRoleNameCharLimit((prev) => ({ ...prev, error: false }));
    setRoleNameDuplicate((prev) => ({ ...prev, error: false }));
    setRoleDescEmpty((prev) => ({ ...prev, error: false }));
    setRoleDescCharLimit((prev) => ({ ...prev, error: false }));
    setRoleTypeEmpty((prev) => ({ ...prev, error: false }));
    setHasPermission((prev) => ({ ...prev, error: false }));
    setServerErrors((prev) => ({ ...prev, error: false }));
  };

  const errorCheck = () => {
    const adminRoleError = validateAdminRole(
      roleName,
      roleDesc,
      roleType,
      setRoleNameEmpty,
      setRoleNameCharLimit,
      setRoleDescEmpty,
      setRoleDescCharLimit,
      setRoleTypeEmpty
    );
    const permissionsError = validatePermissions(
      selectedPermissions,
      setHasPermission
    );
    return adminRoleError || permissionsError;
  };

  const onSave = () => {
    resetErrors();
    let isError = errorCheck();

    if (config.useRoleDelegatorMockData) {
      if (!isError) {
        setEditSuccess({
          message: `${roleName} was successfully saved.`,
          enabled: true,
        });
      }
      return;
    }

    if (!isError) {
      saveRole({
        variables: {
          roleUuid: roleUuid,
          name: roleName,
          description: roleDesc,
          permissionUuids: selectedPermissions.map((perm) => perm.uuid),
        },
      }).then((result) => {
        const updateNameAndDescResult = (result as FetchResult).data
          ?.rdUpdateRole;
        const updatePermissionsResult = (result as FetchResult).data
          ?.rdUpdateAdminRoleWithPermissions;
        if (!updateNameAndDescResult || !updatePermissionsResult) {
          setServerErrors({
            error: true,
            message: 'An error occurred while saving.',
          });
        } else if ('rdValidationErrors' in updatePermissionsResult) {
          const message = updatePermissionsResult.rdValidationErrors
            .map((e) => e.errorMessage)
            .join('; ');
          setServerErrors({
            error: true,
            message: `Errors occurred while saving: [${message}]`,
          });
        } else {
          if (queryParams.get('isNew') === 'true') {
            history.push(`/admin-roles/${roleUuid}/edit`);
          }
          setEditSuccess({
            message: `${roleName} was successfully saved.`,
            enabled: true,
          });
          refetch();
        }
      });
    }
  };

  const onDiscard = () => {
    history.push('/admin-roles');
  };

  const onCancel = () => {
    if (hasUnsavedChanges) {
      setUnsavedModalOpen(true);
    } else {
      onDiscard();
    }
  };

  const onDelete = () => {
    setDeleteRoleModalOpen(false);
    if (config.useRoleDelegatorMockData) {
      history.push(`/admin-roles?deleted=${roleUuid}`);
      return;
    }

    deleteRole({
      variables: {
        roleUuid: roleUuid,
      },
    }).then(() => {
      history.push(`/admin-roles?deleted=${roleUuid}`);
    });
  };

  useEffect(() => {
    const rolePermissionUuids = role?.permissions.map((perm) => perm.uuid);
    const selectedPermissionUuids = selectedPermissions.map(
      (perm) => perm.uuid
    );
    const differenceOfPermissions = [
      ...(rolePermissionUuids?.filter(
        (uuid) => !selectedPermissionUuids.includes(uuid)
      ) || []),
      ...(selectedPermissionUuids?.filter(
        (uuid) => !rolePermissionUuids?.includes(uuid)
      ) || []),
    ];
    setHasUnsavedChanges(
      role?.name !== roleName ||
        role?.description !== roleDesc ||
        differenceOfPermissions.length > 0
    );
  }, [
    role?.name,
    role?.description,
    role?.permissions,
    roleName,
    roleDesc,
    selectedPermissions,
  ]);

  return (
    (loading && <Loading />) ||
    (!role && <NotFoundPage />) || (
      <PageWrapper
        type={TYPE}
        context="admin-roles"
        saveAction={onSave}
        cancelAction={onCancel}
      >
        <BasicPadding padding="sm">
          <UnsavedModal
            open={unsavedModalOpen}
            setOpen={setUnsavedModalOpen}
            onConfirm={onDiscard}
          />
          <DeleteRoleModal
            open={deleteRoleModalOpen}
            setOpen={setDeleteRoleModalOpen}
            onConfirm={onDelete}
            onCancel={() => setDeleteRoleModalOpen(false)}
            roleName={roleName}
          />
          <AdminRoleHeader
            type={TYPE}
            roleUuid={roleUuid}
            headerText="Edit an admin role"
            headerDesc="Edit the permissions for this role."
            roleNameValue={roleName}
            setRoleNameValue={setRoleName}
            roleDescriptionValue={roleDesc}
            setRoleDescriptionValue={setRoleDesc}
            roleTypeValue={roleType}
            setRoleTypeValue={setRoleType}
            errors={errors}
            createSuccess={createSuccess}
            editSuccess={editSuccess}
            onNavigateAway={onCancel}
            setDeleteRoleModalOpen={setDeleteRoleModalOpen}
          />
          <AssignPermContainer>
            <Typography variant="h2">Assign permissions</Typography>
            <Typography>
              Select permission categories below to view and add them to your
              custom role. You can also search to find specific permissions.
            </Typography>
          </AssignPermContainer>
          <AlertContainer>
            <ErrorHandler errors={permissionErrors} />
          </AlertContainer>
          <RolePermissions
            selectedPermissions={selectedPermissions}
            setSelectedPermissions={setSelectedPermissions}
          />
        </BasicPadding>
      </PageWrapper>
    )
  );
};

export default EditAdminRole;
