import { AppContextData } from '../context/AppContext';
import Authorizer from './Authorizer';

const permissionAuthorizer =
  (permission: string | string[]): Authorizer =>
  ({ permissions }: AppContextData, customerId?: string): boolean => {
    let validPermissions = permission;
    if (typeof permission === 'string') {
      validPermissions = [permission];
    }
    if (!customerId) return false;
    const customerPerms = permissions.find((p) => p.customerId === customerId);
    if (!customerPerms) return false;
    return customerPerms.permissions.some((perm) =>
      validPermissions.includes(perm)
    );
  };

export default permissionAuthorizer;
