import React from 'react';
import { Alert } from '@octanner/prism-core';

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Alert
          severity="error"
          style={{ margin: 16 }}
          data-test="program:error"
        >
          There was a problem. Please refresh and try again.
        </Alert>
      );
    }

    return this.props.children;
  }
}

type Props = React.PropsWithChildren;

interface State {
  hasError: boolean;
}

export default ErrorBoundary;
