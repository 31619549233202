import styled from '@emotion/styled';
import { Container, Modal, Typography } from '@octanner/prism-core';
import colors from '../../../common/constants/Colors';
import { css } from '@emotion/react';

export const SocialWallContainer = styled(Container)`
  max-width: 680px !important;
`;

export const FlexBetweenDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ColorBlack = styled(Typography)`
  color: ${colors.black};
`;

export const BoldBlack = styled(Typography)`
  color: ${colors.black};
  font-weight: 800;
  font-size: 14px;
  line-height: 150%;
`;

export const FlexCenterDiv = styled.div`
  display: flex;
  align-items: center;
`;

export const SocialWallModal = styled(Modal)(css`
  position: fixed;
  width: 522px;
  left: calc(50% - 225px);
`);

export const SectionHolder = styled('div', {
  shouldForwardProp: (prop) => prop !== 'disabled',
})<{ disabled?: boolean }>(
  ({ disabled }) => `
  ${disabled ? 'opacity: 0.5;' : ''}
`
);
