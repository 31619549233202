import { useMutation, useQuery } from '@apollo/client';
import {
  CORE_PT_BANKS_BY_CUSTOMER,
  CORE_PT_BANK_BY_ID,
  CREATE_BANK,
} from './graphql';
import {
  GetBankByIdResponse,
  GetBanksByCustomerInput,
  GetBanksByCustomerResponse,
} from './types';

const PAGE_SIZE: number = 9;

export const useGetBanksByCustomer = (customerUuid: string) => {
  const bankQuery = useQuery<
    GetBanksByCustomerResponse,
    GetBanksByCustomerInput
  >(CORE_PT_BANKS_BY_CUSTOMER, {
    variables: { customerUuid, pageSize: PAGE_SIZE, pageNumber: 0 },
    notifyOnNetworkStatusChange: true,
  });

  const pageNumber =
    bankQuery.data?.corePtMgmtGetBankInformationByCustomer.information.pageInfo
      .pageNumber || 0;
  const totalPages =
    bankQuery.data?.corePtMgmtGetBankInformationByCustomer.information.pageInfo
      .totalPages || 0;

  const fetchMore = () => {
    if (pageNumber < totalPages) {
      bankQuery.fetchMore({
        variables: { pageNumber: pageNumber + 1 },
      });
    }
  };

  const refetch = () => {
    bankQuery.refetch();
  };

  return {
    ...bankQuery,
    fetchMore,
    refetch,
  };
};

export const useCreateBank = (customerUuid: string) => {
  const [createBank] = useMutation(CREATE_BANK, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: CORE_PT_BANKS_BY_CUSTOMER,
        variables: { customerUuid, pageSize: PAGE_SIZE, pageNumber: 0 },
      },
    ],
  });

  return createBank;
};

export const useBank = (customerUuid: string, pointBankUuid: string) => {
  const bankQuery = useQuery<
    GetBankByIdResponse,
    { customerUuid: string; pointBankUuid: string }
  >(CORE_PT_BANK_BY_ID, { variables: { customerUuid, pointBankUuid } });

  return { ...bankQuery };
};

export const formatBankType = (name: string): string => {
  if (!name) return '';

  switch (name) {
    case 'ACCRUING_BANK':
      return 'Accruing bank';
    case 'ONE_TIME_BANK':
      return 'One-time bank';
    default:
      return 'Unknown bank';
  }
};
export const formatBillingType = (name: string): string => {
  switch (name) {
    case 'BILL_ON_REDEMPTION':
      return 'Bill on redemption';
    case 'BILL_ON_ISSUANCE':
      return 'Bill on issuance';
    default:
      return 'Unknown billing';
  }
};
