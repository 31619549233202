import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Colors from '../../common/constants/Colors';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerCell: {
      fontSize: theme.typography.subtitle2.fontSize,
      textAlign: 'center',
    },
    clientId: {
      width: 100,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    cell: {
      verticalAlign: 'text-top',
      minWidth: 300,
    },
    cell2: {
      verticalAlign: 'top',
      minWidth: 300,
    },
    cell3: {
      verticalAlign: 'text-top',
      minWidth: 150,
    },
    cell4: {
      verticalAlign: 'top',
      minWidth: 120,
    },
    clientName: {
      display: 'flex',
    },
    clientNameText: {
      marginLeft: theme.spacing(3),
      fontWeight: 'bold',
    },
    clientIdClientList: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: 150,
      minWidth: 100,
    },
    textExpand: {
      cursor: 'pointer',
    },
    repRole: {
      height: 22,
      paddingLeft: 10,
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    repRoleAlt: {
      height: 22,
      backgroundColor: '#f3f3f3',
      border: '1px solid #f3f3f3',
      borderRadius: '20px',
      paddingLeft: 10,
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    clickable: {
      cursor: 'pointer',
      textAlign: 'right',
      color: '#0060e0',
      fontWeight: 'bold',
    },
    copyCat: {
      cursor: 'pointer',
      marginRight: 5,
    },
    cancel: {
      marginRight: theme.spacing(2),
    },
    actions: {
      textAlign: 'right',
      marginTop: 'auto',
      display: 'flex',
      alignItems: 'center',
    },
    editingCell: {
      verticalAlign: 'bottom',
    },
    user: {
      marginBottom: theme.spacing(1),
      maxWidth: 400,
    },

    root: {
      textAlign: 'right',
    },
    deleteAction: {
      color: Colors.red,
    },
    actionsContainer: {
      display: 'flex',
      marginTop: theme.spacing(4),
    },
    newContainer: {
      backgroundColor: Colors.gray300,
      padding: theme.spacing(2),
      flexDirection: 'column',
    },
    action: {
      flex: '1 1 0px',
    },
    buttons: {
      textAlign: 'right',
    },
    icon: {
      color: Colors.gray500,
    },
    close: {
      cursor: 'pointer',
    },
    clientList: {
      marginTop: theme.spacing(2),
    },
    newClientContainer: {
      display: 'flex',
      backgroundColor: Colors.gray300,
      padding: theme.spacing(2),
      flexDirection: 'column',
      width: '100%',
    },
    newClientAction: {
      display: 'flex',
      flex: '1 1 auto',
      '& > .MuiFormControl-root': {
        flex: '1 1 auto',
      },
    },
    newClientButtons: {
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    createNewActions: {
      marginTop: theme.spacing(2),
      display: 'flex',
    },
    clientCancel: {
      marginLeft: theme.spacing(1),
    },
    name: {
      marginTop: theme.spacing(1),
    },
    containerFlex: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexDirection: 'row',
    },
    deleteButton: {
      backgroundColor: '#fde5e5',
      flex: 1,
    },
    buttonContainerFlex: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
    },
    centerAlign: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    clientListTitle: {
      fontSize: '28px',
      fontWeight: 800,
      lineHeight: 1,
    },
    clientListSubTitle: {
      fontSize: '14px',
      marginBottom: '24px',
    },
    searchListTitle: {
      fontSize: '14px',
    },
    searchListSubTitle: {
      fontSize: '12px',
      color: Colors.gray500,
    },
    searchOptionsWrapper: {
      marginLeft: '10px',
      marginBottom: '10px',
    },
    clientListSearchWrapper: {
      display: 'flex',
      alignItems: 'top',
    },
    clientListSearch: {
      width: '100%',
      '& .MuiFormControl-root': {
        '& .MuiInputBase-root': {
          padding: '3px',
          paddingLeft: '10px',
        },
      },
    },
    clientListSearchButtonsWrapper: {
      minWidth: '370px',
      paddingLeft: '24px',
    },
    clientListSearchButtons: {
      display: 'flex',
      '& .MuiButtonGroup-root': {
        '& .MuiButtonGroup-grouped.Mui-disabled': {
          backgroundColor: Colors.blue,
          color: Colors.white,
        },
      },
    },
    impersonateButton: {
      marginLeft: '24px',
    },
    clientDualActionButton: {
      backgroundColor: Colors.blue,
    },
    recentClientsWrapper: {
      marginTop: '36px',
    },
    recentClientsTitle: {
      fontSize: '20px',
      fontWeight: 800,
    },
    clientTableTitle: {
      fontWeight: 800,
    },
    recentClientsTableRow: {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: Colors.gray300,
      },
    },
    impersonateCell: {
      zIndex: 10,
    },
    customerStatusTag: {
      fontFamily: 'inherit',
      fontWeight: 500,
    },
  })
);
