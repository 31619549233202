import { Snackbar, Tag } from '@octanner/prism-core';
import { SnackbarProps } from './types';

const SnackbarPopup = ({
  text,
  open,
  autoHideDuration = 5000,
  handleClose,
  severity = 'info',
}: SnackbarProps) => {
  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={open}
        autoHideDuration={autoHideDuration}
        onClose={handleClose}
      >
        <div style={{ display: 'flex', width: '100%' }}>
          <Tag style={{ padding: 10 }} variant={severity}>
            {text}
          </Tag>
        </div>
      </Snackbar>
    </>
  );
};

export default SnackbarPopup;
