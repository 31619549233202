import React, { useEffect, useState } from 'react';
import { Typography, Divider } from '@octanner/prism-core';
import SortAscending from '@octanner/prism-icons/SortAscending';
import SortDescending from '@octanner/prism-icons/SortDescending';
import { useMutation } from '@apollo/client';
import {
  Header,
  CountryHeader,
  SalesOrgTab,
  CountryCodeTab,
  DisplayNoneHeaderTextContainer,
  SearchCountry,
  SortContainer,
  FilterContainer,
  SubHeader
} from './style';
import { DisplayFlex } from '../languages/style';
import { SalesOrgProps } from './types';
import { ADD_SALES_ORG  } from './query';
import DisplaySalesOrg from './DisplaySalesOrg';
import { customerId } from '../../utilities/utility';
import SelectAndUnselect from '../languages/SelectAndUnselect';
import Footer from './Footer';
import { useTranslation } from 'react-i18next';

const SalesOrg: React.FC<SalesOrgProps> = ({
  salesOrgList,
  clientSalesOrg,
  refetchClientSalesOrg,
  setEditSalesOrg,
  errorsDisplayNumber,
  onSave,
  setSalesOrgDataChanged,
  clientSalesOrgWarning,
  onSapUpdateError,
  tabsEditModeStatus,
  setTabsEditModeStatus,
  setClientSalesOrgWarning,
  ccAdminEnableComboModel
}) => {

  const [addSalesOrg] = useMutation(ADD_SALES_ORG, {
    onCompleted: () => {
      refetchClientSalesOrg().then(() => {
          setupdateProgress(false),
          setEditSalesOrg(false),
          setSalesOrgDataChanged(false),
          onSapUpdateError(''),
          setClientSalesOrgWarning(false),
          window.scrollTo(0, 0)
      });
    },
    onError: (error) => {
      setupdateProgress(false),
      onSapUpdateError(error?.message)
      window.scrollTo(0, 0)
    }
  });


  const { countryWithEligibleSalesOrgs: allCountriesWithEligibleSalesOrgs = []} = salesOrgList?.allCountriesWithEligibleSalesOrgs || {};

  const { customerCountrySalesOrgMap }= clientSalesOrg?.customerCountrySalesOrgMapsByCustomer || {};

  const [checkedCountries, setCheckedCountries] = useState(true);
  const [UncheckedCountries, setUncheckedCountries] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [isAscending, setIsAscending] = useState(true);
  const [updateProgress, setupdateProgress] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    errorsDisplayNumber.CountryAndSalesOrgSetup == 1 ? (
      onSave({...errorsDisplayNumber, CountryAndSalesOrgSetup: 1})
    ) : (
      error ? onSave({...errorsDisplayNumber, CountryAndSalesOrgSetup: 1})
      : onSave({...errorsDisplayNumber, CountryAndSalesOrgSetup: 0})
    )
  },[error]);

  useEffect(() => {
    if(clientSalesOrgWarning){
    const countrySalesOrgMaps : any[] = [];
    countriesWithEligibleSalesOrgs.map((item: any) => {
      if (item.checked === true) {
        countrySalesOrgMaps.push({
          countryIso2Code: item.coreCountry.iso2Code,
          salesOrgCode: item?.salesOrgCode,
        });
      }
    });
      if(countrySalesOrgMaps.length === 0) {
        setError(true);
        onSave({...errorsDisplayNumber, CountryAndSalesOrgSetup: 1});
      } else {
        setError(false);
        onSave({...errorsDisplayNumber, CountryAndSalesOrgSetup: 0}) ;
      }
    }
  },[clientSalesOrgWarning]);

  const { t } = useTranslation();

  const [
    countriesWithEligibleSalesOrgs,
    setCountriesWithEligibleSalesOrgs,
  ] = useState<any>(
    allCountriesWithEligibleSalesOrgs?.map(
      (countryWithSalesOrgs: any) => {
        const clientSalesOrgs = customerCountrySalesOrgMap?.filter(
          (item: any) =>
            item.coreCountry.countryName ===
            countryWithSalesOrgs.coreCountry.countryName,
        )

        if (clientSalesOrgs.length > 0) {
          return {
            ...countryWithSalesOrgs,
            checked: true,
            salesOrgCodes: clientSalesOrgs.map((clientSalesOrg) => clientSalesOrg?.assignedSalesOrg?.salesOrgCode)
          };
        }

        return {
          ...countryWithSalesOrgs,
          checked: false,
          salesOrgCodes: [countryWithSalesOrgs?.recommendedSalesOrg?.salesOrgCode]
        };
      }
    ).slice().sort((a, b) => a?.coreCountry?.countryName < b?.coreCountry?.countryName ? -1 : 1)
  );

  useEffect(() => {
    if(tabsEditModeStatus.CountryAndSalesOrgSetup){
      saveSalesOrgPrefrence();
      setTabsEditModeStatus({...tabsEditModeStatus, CountryAndSalesOrgSetup: false})
    }
  },[tabsEditModeStatus]);


  const saveSalesOrgPrefrence = () => {

    const countrySalesOrgMaps : any[] = [];
    countriesWithEligibleSalesOrgs.map((item: any) => {
      if (item.checked === true) {
        item.salesOrgCodes.forEach((salesOrg) => {
          countrySalesOrgMaps.push({
            countryIso2Code: item.coreCountry.iso2Code,
            salesOrgCode: salesOrg,
          });
        })
      }
    });

    if(countrySalesOrgMaps.length > 0){
      setupdateProgress(true);
      addSalesOrg({
        variables: {
          input: {
            customerId: customerId,
            countrySalesOrgMaps: [...countrySalesOrgMaps],
          },
        },
      });
    } else {
      setError(true);
    }
  }

  const handleSorting = () => {
    setIsAscending(!isAscending);
    setCountriesWithEligibleSalesOrgs(countriesWithEligibleSalesOrgs.reverse());
  }

  const handleSalesOrgSelection = (countryWithSalesOrg, selectedOption) => {
    const selectedIndex = countriesWithEligibleSalesOrgs.findIndex((f) =>
      f?.coreCountry?.iso2Code === countryWithSalesOrg?.coreCountry?.iso2Code
    );
    countriesWithEligibleSalesOrgs[selectedIndex].salesOrgCodes = countryWithSalesOrg?.salesOrgCodes;
    countriesWithEligibleSalesOrgs[selectedIndex].checked = selectedOption;
    setCountriesWithEligibleSalesOrgs([...countriesWithEligibleSalesOrgs]);
  }

  const filteredSalesOrgList = countriesWithEligibleSalesOrgs
  ?.filter(s => s?.coreCountry?.countryName?.toLowerCase()?.includes(searchText))
    ?.filter(f => {
      const { checked } = f;
      if(checkedCountries && UncheckedCountries){
        return (checked === !UncheckedCountries || checked === checkedCountries)
      }
      if(UncheckedCountries){
        return checked === !UncheckedCountries
      }
      if(checkedCountries){
        return checked === checkedCountries
      }
  });

  return (
    <div>
      <>
        <Header test-id = "fulfillments-header">{t('fulfillments')}</Header>
        <SubHeader color="textSecondary" variant='body2' test-id = "fulfillments-subheader">
          {t('country_sales_org')}
        </SubHeader>
        {error && (<SubHeader color="error" variant='body2'> {t('warn_empty_sales_org')}</SubHeader>)}
        <FilterContainer>
          <SelectAndUnselect
            setChecked={setCheckedCountries}
            setUnchecked={setUncheckedCountries}
            test-id = "select-unselect"
          />
          <SearchCountry
            id="searchCountry"
            onInputChange={(e: any) => setSearchText(e?.target?.value?.toLowerCase() || '' )}
            placeholder={t('search_countries')}
            size="small"
            test-id = "search-country"
            multiple={false}
            getOptionLabel={() => searchText}
          />
        </FilterContainer>
        {filteredSalesOrgList?.length > 0 ?
        (<>
          <DisplayFlex>
            <CountryHeader>
              <Typography variant='h4' test-id="country-header">{t('country')}</Typography>
              <SortContainer onClick={() => handleSorting()}>
                {isAscending ? <SortAscending /> : <SortDescending />}
              </SortContainer>
            </CountryHeader>
            <CountryCodeTab test-id="country-code-header">{t('code')}</CountryCodeTab>
            <SalesOrgTab test-id="sales-org-header">{t('sales_org')}</SalesOrgTab>
          </DisplayFlex>
          <Divider style={{ marginTop: '0px' }} />
          <div style={{ marginBottom: '120px' }}>
            {filteredSalesOrgList?.map((countryWithSalesOrg) =>
              <DisplaySalesOrg
                key={countryWithSalesOrg?.salesOrgCodes+countryWithSalesOrg?.coreCountry?.countryName}
                countryWithSalesOrg={countryWithSalesOrg}
                handleSalesOrgSelection={handleSalesOrgSelection}
                setSalesOrgDataChanged = {setSalesOrgDataChanged}
                test-id = "display-sales-org"
                ccAdminEnableComboModel={ccAdminEnableComboModel}
              />
            )}
          </div>
        </>)
        : (<>
          <DisplayNoneHeaderTextContainer
          test-id = "filter-countries"
          variant='body1'
          color='textSecondary'
          >
            {t('filter_countries')}
          </DisplayNoneHeaderTextContainer>
          <Typography
            color="textSecondary"
            variant='body2'
            test-id = "selected-countries"
          >
            {t('selected_countries')}.
          </Typography>
        </>)}
        {(checkedCountries || UncheckedCountries) && (
            <Footer
            onSave={saveSalesOrgPrefrence}
            onCancel={() => setEditSalesOrg(false)}
            loading={updateProgress}
            setSalesOrgDataChanged = {setSalesOrgDataChanged}
            clientSalesOrg={clientSalesOrg}
            test-id = "sales-org-footer"
            />
        )}
      </>
    </div>
  );
};

export default SalesOrg;
