import {
  Box,
  FormControlLabel,
  FormGroup,
  Switch,
  Typography,
} from '@octanner/prism-core'
import colors from 'library/src/common/colors'
import Collapse from 'library/src/common/components/Collapse/Collapse'
import IdentitySelector from 'library/src/common/components/IdentitySelector/IdentitySelector'
import React, { useState } from 'react'
import {
  useGroupSettings,
  useGroupSettingsDispatch,
} from '../../../utilities/GroupSettingsContext'

interface Props {
  customerId: string
}

const EmailControls: React.FC<Props> = ({ customerId }) => {
  const groupSettingsContext = useGroupSettings()
  const dispatch = useGroupSettingsDispatch()

  const [opened, setOpened] = useState(true)
  const dataTestPrefix = 'yps:email-controls'

  return (
    <>
      <Box
        onClick={() => setOpened(!opened)}
        sx={{
          padding: '16px 16px 16px 2px',
          borderTop: `1px solid ${colors.gray400}`,
          borderBottom: `1px solid ${colors.gray400}`,
        }}
      >
        <Collapse
          dataTestPrefix={dataTestPrefix}
          id="email-controls-switch"
          isOpen={opened}
          text="Email Controls"
          className={'emailControlsSwitch'}
          enableSwitch={false}
        />
      </Box>
      {opened && (
        <section>
          <Box>
            <FormGroup>
              <Typography variant="body1" sx={{ marginLeft: '43px' }}>
                For Team Members:
              </Typography>
              <FormControlLabel
                control={
                  <Switch
                    checked={
                      groupSettingsContext?.celebrantInvitePeersNotificationEnabled
                    }
                    onChange={() =>
                      dispatch({
                        type: 'toggleCelebrantInvitePeersNotification',
                      })
                    }
                  />
                }
                label="Celebrant - Invite Peers Email"
                sx={{ marginLeft: '35px', gap: '8px' }}
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={
                      groupSettingsContext?.celebrantCongratulatoryNotificationEnabled
                    }
                    onChange={() =>
                      dispatch({
                        type: 'toggleCelebrantCongratulatoryNotification',
                      })
                    }
                  />
                }
                label="Celebrant Happy Anniversary - Day Of Email"
                sx={{ marginLeft: '35px', gap: '8px' }}
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={
                      groupSettingsContext?.peersAnniversaryDayNotificationEnabled
                    }
                    onChange={() =>
                      dispatch({
                        type: 'togglePeersAnniversaryDayNotification',
                      })
                    }
                  />
                }
                label="Peer - Day of Email"
                sx={{ marginLeft: '35px', gap: '8px' }}
              />
              <Typography variant="body1" sx={{ marginLeft: '43px' }}>
                For Managers:
              </Typography>
              <Box sx={{ marginLeft: '35px' }}>
                <Collapse
                  isOpen={
                    (groupSettingsContext?.leaderNotesEnabled ||
                      groupSettingsContext?.peerNotesEnabled) &&
                    groupSettingsContext?.emptyYearbookEmailEnabled
                  }
                  disabled={
                    (!groupSettingsContext?.leaderNotesEnabled &&
                      !groupSettingsContext?.peerNotesEnabled) ||
                    !groupSettingsContext?.yearbookEnabled
                  }
                  onChange={() =>
                    dispatch({ type: 'toggleEmptyYearbookEmail' })
                  }
                  text="Empty Yearbook Email"
                  variant="body1"
                  id="manager:empty-yearbook-email"
                  dataTestPrefix={dataTestPrefix}
                  hideIcon
                >
                  <Box component="section" sx={{ paddingLeft: '72px' }}>
                    <IdentitySelector
                      customerId={customerId}
                      identity={
                        groupSettingsContext?.emptyYearbookEmailRecipientIdentity
                      }
                      onChange={(value) => {
                        dispatch({
                          type: 'setEmptyYearbookEmailRecipient',
                          identity: value,
                        })
                      }}
                    />
                  </Box>
                </Collapse>
              </Box>

              <FormControlLabel
                control={
                  <Switch
                    checked={groupSettingsContext?.presentationReminderEmail}
                    onChange={() =>
                      dispatch({ type: 'togglePresentationReminderEmail' })
                    }
                  />
                }
                label="Presentation Reminder Email"
                sx={{ marginLeft: '35px', gap: '8px' }}
              />
            </FormGroup>
          </Box>
        </section>
      )}
    </>
  )
}

export default EmailControls
