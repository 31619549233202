import { useStyles } from './styles';
import { Chip, Autocomplete, TextField } from '@octanner/prism-core';
import { Search } from '@octanner/prism-icons';
import { useContext, useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useDebouncedCallback } from 'use-debounce';
import { CustomerContext } from '../../utils/context/CustomerContext';
import { SEARCH_IDENTITY_GROUPS } from './graphql/identity';
import {
  IdentityGroup,
  IdentityGroupSearchInput,
  IdentityGroupSearchResponse,
} from './models/Identity';
import { IdentityGroupSelectProps } from './types';

const formatIdentityGroupName = (ig: IdentityGroup) => {
  if (!ig.name) return '';
  return ig?.name.trim();
};

const IdentityGroupSelect = ({
  onRemove,
  onAdd,
  selectedIdentities,
}: IdentityGroupSelectProps) => {
  const classes = useStyles();
  const {
    customer: { id: customerId },
  } = useContext(CustomerContext);
  const [searchIdentityGroups, { data, loading, error }] = useLazyQuery<
    IdentityGroupSearchResponse,
    IdentityGroupSearchInput
  >(SEARCH_IDENTITY_GROUPS);

  const [options, setOptions] = useState<IdentityGroup[]>([]);
  useEffect(() => {
    const identityOptions = data?.searchIdentityGroups || [];
    setOptions(identityOptions);
  }, [data]);

  const action = (searchText: string) => {
    if (!searchText) {
      setOptions([]);
      return;
    }
    const condition = {
      customerId,
      clause: searchText,
      returnAmount: 10,
    };
    searchIdentityGroups({ variables: condition });
  };

  const handleTextFieldChange = useDebouncedCallback(action, 500);
  const handleChange = (options: IdentityGroup[]) => {
    const optionIds = options.map((o) => o.id);
    const existingItems = selectedIdentities;
    const removed = existingItems.find(
      (i: IdentityGroup) => !optionIds.includes(i.id)
    );
    if (removed) {
      onRemove(removed);
      return;
    }

    const existingIds = existingItems.map((i) => i.id);
    const added = options.find((o) => !existingIds.includes(o.id));
    if (added) {
      onAdd(added);
    }
  };

  const selectedIds = selectedIdentities.map((i) => i.id);
  const filterOptions = (options: IdentityGroup[]) =>
    options.filter((o) => !selectedIds.includes(o.id));

  if (error) {
    return <div>Error loading identity groups</div>;
  }

  return (
    <Autocomplete
      multiple
      loading={loading}
      onChange={(_, o) => handleChange(o)}
      options={options}
      filterOptions={filterOptions}
      getOptionLabel={(option) => option.name || ''}
      filterSelectedOptions
      value={selectedIdentities}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          onChange={(e) => handleTextFieldChange(e.target.value)}
          inputProps={{
            ...params.inputProps,
            'data-test': 'role:identity-group-selector',
          }}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <div className={classes.adornmentContainer}>
                <Search style={{ fontSize: 18 }} />
                {selectedIdentities.map((identityGroup: IdentityGroup) => (
                  <Chip
                    className={classes.chip}
                    color="primary"
                    key={identityGroup.id}
                    label={formatIdentityGroupName(identityGroup)}
                    onDelete={() => onRemove(identityGroup)}
                    data-test={`role:identity-group:${identityGroup.id}`}
                  />
                ))}
              </div>
            ),
            endAdornment: null,
          }}
        />
      )}
    />
  );
};

export default IdentityGroupSelect;
