import styled from '@emotion/styled';
import {
  Alert,
  Chip,
  FileUpload,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from '@octanner/prism-core';
import { Check, Close } from '@octanner/prism-icons';
import React, { useContext, useEffect, useState } from 'react';
import Footer from '../../Components/Common/Footer';
import SelectLanguage from '../Common/SelectLanguage';
import { LocaleContext } from './../../Contexts/LocaleContext';
import { BulkUploadType, numRegex } from './../../utils/constants';
import { defaults } from './../../utils/default';
import { BulkUploadProps, Locale } from './../../utils/types';

const Container = styled.div`
  margin: 20px 35px;
  height: calc(100vh - 110px);
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
`;

const RadioButtonContainer = styled.div`
  margin-top: 30px;
  margin-left: 8px;
`;

const RadioFormControlLabel = styled(FormControlLabel)`
  margin-right: 55px;
`;

const ControlGrid = styled(Grid)`
  margin-top: 24px;
`;

const Control = styled.div`
  margin-top: 12px;
`;

const LanguageChip = styled(Chip)`
  margin-top: 12px;
  margin-right: 12px;
`;

const MessageAlert = styled(Alert)`
  margin-top: 30px;
`;

const RoundedBox = styled.div`
  padding: 12px;
  border-radius: 10px;
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
  font-size: 12px;
`;

const CloseBtn = styled(Close)`
  cursor: pointer;
  color: rgba(0, 0, 0, 0.6);
  margin-right: 12px;
`;

const UploadedTick = styled(Check)`
  color: #07702A;
`;

const FileName = styled.div`
  flex-direction: column;
  margin-left: 20px;
  flex-grow: 1;
`;

const BulkUpload: React.FC<BulkUploadProps> = ({ fetch }) => {

  //form states
  const [bulkUploadType, setBulkUploadType] = useState<string>(BulkUploadType.CREATE);
  const [selectedLocales, setSelectedLocales] = useState<Locale[]>([]);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [clientStp, setClientStp] = useState<string>('');
  const [locales, setLocales] = useState<Locale[]>([]);

  // loading states
  const [sampleFileDownloading, setSampleFileDownloading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  // error and success states
  const [fileUploadError, setFileUploadError] = useState<string>('');
  const [uploadSuccess, setUploadSuccess] = useState<boolean>(false);
  const [uploadError, setUploadError] = useState<boolean>(false);
  const [sampleDownloadError, setSampleDownloadError] = useState<boolean>(false);

  const { localeLoading, locales: allLocales } = useContext(LocaleContext);

  useEffect(() => {
    if (allLocales?.length) {
      setLocales(allLocales.filter((locale: Locale) => locale.code !== 'en-US'));
    }
  }, [allLocales])

  // display strings
  const { bulk_upload_title, bulk_upload_description, bulk_create, bulk_request,
    bulk_success, bulk_error, sample_download_failed, upload_excel_file_title,
    bulk_create_excel_file_description, bulk_request_excel_file_description,
    upload_excel_file_download_text, file_upload_success, add_stp, add_stp_desc,
    optional, cancel, send_request, create_strings, select_languages_title,
    select_languages_description } = defaults;

  const handleControlChange = (): void => {
    setUploadSuccess(false);
    setUploadError(false);
    setSampleDownloadError(false);
  };

  const bulkUploadTypeChange = (event: React.ChangeEvent<{ value: string }>) => {
    setSelectedFile(null);
    setFileUploadError(null);
    setSelectedLocales([]);
    setClientStp('');
    handleControlChange();
    setBulkUploadType(event.target.value);
  }

  const handleFileChange = (files: FileList): void => {
    setFileUploadError(null);
    setSelectedFile(files[0]);
    handleControlChange();
  };

  const onRemoveLocale = (locale: Locale): void => {
    handleControlChange();
    setSelectedLocales((previousLocales: Locale[]) => {
      const index = previousLocales.findIndex((previousLocale: Locale) => previousLocale.code === locale.code);
      if (index > -1) {
        previousLocales.splice(index, 1);
      }
      return [...previousLocales];
    });
  }

  const onCancel = (): void => {
    setSelectedFile(null);
    setSelectedLocales([]);
  }

  const clientStpChange = (event: React.ChangeEvent<{ value: string }>) => {
    const inputValue = event.target.value;
    if (inputValue === '' || numRegex.test(inputValue)) {
      setClientStp(event.target.value);
    }
  }

  const downloadSampleFile: () => Promise<void> = async () => {
    if (sampleFileDownloading) {
      return;
    }
    setSampleFileDownloading(true);
    try {
      const response = await fetch(`/api/language/bulk-upload/language-string/${bulkUploadType}/sample`, {
        method: 'GET',
        headers: {
          Accept: 'application/vnd.ms-excel'
        },
      });
      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `sampleBulk${bulkUploadType}file.xlsx`;
        a.click();
        window.URL.revokeObjectURL(url);
      } else {
        setSampleDownloadError(true);
      }
      setSampleFileDownloading(false);
    } catch (error) {
      setSampleFileDownloading(false);
      setSampleDownloadError(true);
    }
  };

  const uploadFile: () => Promise<void> = async () => {
    setLoading(true);
    handleControlChange();
    const formData = new FormData();
    formData.append('file', selectedFile);
    formData.append('locales', selectedLocales.map((locale: Locale) => locale.code).toString());
    if (bulkUploadType === BulkUploadType.CREATE && clientStp) {
      formData.append('clientStp', clientStp.toString());
    }
    try {
      const response = await fetch(`/api/language/bulk-upload/language-string/${bulkUploadType}`, {
        method: 'POST',
        body: formData
      });

      if (response.ok) {
        setUploadSuccess(true);
        setSelectedFile(null);
        setFileUploadError(null);
        setSelectedLocales([]);
        setClientStp('');
      } else {
        setUploadError(true);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setUploadError(true);
    }
  };

  return (
    <Container>
      <Typography variant='h1'>
        {bulk_upload_title}
      </Typography>
      <Typography>
        {bulk_upload_description}
      </Typography>
      <Grid container>
        <Grid item xs={8}>
          {uploadSuccess && <MessageAlert severity="success" onClose={() => setUploadSuccess(false)}>
            {bulk_success.replace('{bulkUploadType}', bulkUploadType)}</MessageAlert>}
          {uploadError && <Grid item xs={7}><MessageAlert severity="error" onClose={() => setUploadError(false)}>
            {bulk_error.replace('{bulkUploadType}', bulkUploadType)}</MessageAlert></Grid>}
          {sampleDownloadError && <Grid item xs={5}><MessageAlert severity="error" onClose={() => setSampleDownloadError(false)}>
            {sample_download_failed}</MessageAlert></Grid>}
        </Grid>
      </Grid>
      <RadioButtonContainer>
        <FormControl>
          <RadioGroup row aria-label='bulkUploadType' name='bulkUploadType'
            value={bulkUploadType} onChange={bulkUploadTypeChange}>
            <RadioFormControlLabel value={BulkUploadType.CREATE} control={<Radio />} label={bulk_create} />
            <RadioFormControlLabel value={BulkUploadType.REQUEST} control={<Radio />} label={bulk_request} />
          </RadioGroup>
        </FormControl>
      </RadioButtonContainer>
      <Grid container>
        <ControlGrid item xs={5}>
          <Typography variant='h3'>
            {upload_excel_file_title}
          </Typography>
          <Typography color='textSecondary'>
            {bulkUploadType === BulkUploadType.CREATE ? bulk_create_excel_file_description : bulk_request_excel_file_description}
          </Typography>
          <Control>
            {!selectedFile && <FileUpload
              onChange={handleFileChange}
              supportedExtensions={['xlsx']}
              sampleFileText={upload_excel_file_download_text}
              variant='md'
              onSampleFileClick={downloadSampleFile}
              data-testid='file-upload'
              onError={(e: string) => setFileUploadError(e)}
              error={!!fileUploadError}
              helperText={fileUploadError}
            />}
            {selectedFile && <RoundedBox>
              <UploadedTick />
              <FileName>
                <Typography variant='body2'>{selectedFile.name}</Typography>
                <Typography variant='body2' color='textSecondary'>
                  {file_upload_success}
                </Typography>
              </FileName>
              <CloseBtn onClick={() => setSelectedFile(null)} />
            </RoundedBox>}
          </Control>
        </ControlGrid>
      </Grid>
      <Grid container>
        {bulkUploadType === BulkUploadType.CREATE && <ControlGrid item xs={5}>
          <Typography variant='h3'>
            {add_stp}
          </Typography>
          <Typography color='textSecondary'>
            {add_stp_desc}
          </Typography>
          <Control>
            <Grid item xs={11}>
              <TextField
                value={clientStp || ''}
                onChange={clientStpChange}
                fullWidth
                label={add_stp}
                helperText={optional} />
            </Grid>
          </Control>
        </ControlGrid>}
      </Grid>
      <Grid container>
        {!localeLoading && locales && locales.length > 0 && <ControlGrid item xs={5}>
          <Typography variant='h3'>
            {select_languages_title}
          </Typography>
          <Typography color='textSecondary'>
            {select_languages_description}
          </Typography>
          <Grid item xs={11}>
            <SelectLanguage selectedLocales={selectedLocales}
              setSelectedLocales={setSelectedLocales}
              locales={locales}
              optionalInd={bulkUploadType === BulkUploadType.CREATE}></SelectLanguage>
          </Grid>
        </ControlGrid>}
      </Grid>
      {!!(selectedLocales && selectedLocales.length) &&
        selectedLocales.map((locale: Locale) => (
          <LanguageChip key={locale.code} label={locale.languageDescription} onDelete={() => onRemoveLocale(locale)}></LanguageChip>
        ))
      }
      <Footer
        cancelActionText={cancel}
        cancelButtonDisabled={loading || !((selectedLocales && selectedLocales.length) || selectedFile)}
        saveActionText={bulkUploadType === BulkUploadType.REQUEST ? send_request : create_strings}
        cancelAction={onCancel}
        loading={loading}
        saveAction={uploadFile}
        saveButtonDisabled={!selectedFile || (bulkUploadType === BulkUploadType.REQUEST && !selectedLocales?.length)} />
    </Container>
  );
}

export default BulkUpload;