import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import Button from '@octanner/prism-core/Button';
import Loader from '@octanner/prism-core/Loader';
import Typography from '@octanner/prism-core/Typography';
import { Award as AwardType } from '../types';
import ErrorMsg from '../utils/ErrorMsg';
import { SetErrorContext, useSetError } from '../utils/SetErrorProvider';
import { useAwardList } from './useAwardList';
import Award from './Award';
import AwardEditor from './AwardEditor';
import CustomerIdContext from '../utils/CustomerIdContext';

const Container = styled.div`
  margin: 48px 0;
`;

const Heading = styled(Typography)`
  && {
    margin-bottom: 24px;
  }
`;

const Ul = styled.ul`
  border-bottom: solid 1px #e1e1e1;
  margin-top: 16px;
  margin-bottom: 0;
  padding: 0;

  li {
    border-top: solid 1px #e1e1e1;
  }
`;

const CreateAwardButton = styled(Button)`
  && {
    margin-top: 24px;
  }
`;

const AwardList: React.FC<Props> = ({ customerId, programId, error }) => {
  const setError = useSetError();
  const {
    loading,
    data: awards,
    createRecogConfig,
    updateRecogConfig,
  } = useAwardList(programId);
  const [newAward, setNewAward] = React.useState(false);
  const [disableNewAward, setDisableNewAward] = React.useState(false);

  const isDuplicateName = (val, configName) => {
    const tempConfigName = val || configName;
    return val === ''
      ? false
      : awards.some((o) => o.configName === tempConfigName);
  };

  const createNewAward = () => {
    setNewAward(true);
  };

  const cancelAward = () => {
    setNewAward(false);
  };

  const saveAward = (request: AwardType) => {
    createRecogConfig({
      ...request,
    }).catch((err) => {
      setError(err.toString());
    });
    setNewAward(null);
  };

  const customerIdMemo = useMemo(() => ({ customerId }), [customerId]);

  if (loading)
    return (
      <Container>
        <Loader type="dots" loading />
      </Container>
    );

  return (
    <Container>
      <Heading variant="h2">Awards</Heading>
      <ErrorMsg error={error} setError={setError} />
      <CustomerIdContext.Provider value={customerIdMemo}>
        <Ul>
          {awards.map((award) => {
            return (
              <Award
                key={award.recogConfigId}
                award={award}
                isDuplicateName={isDuplicateName}
                setDisableNewAward={setDisableNewAward}
                updateRecogConfig={updateRecogConfig}
                programId={programId}
              />
            );
          })}
        </Ul>
        {newAward ? (
          <AwardEditor
            isDuplicateName={isDuplicateName}
            awardToEdit={{} as AwardType}
            isNew
            onCancel={cancelAward}
            onSave={saveAward}
            programId={programId}
          />
        ) : (
          <CreateAwardButton
            variant="contained"
            onClick={createNewAward}
            disabled={disableNewAward}
            data-testid="create"
          >
            {awards.length === 0 ? 'Create New Award' : 'Add Award'}
          </CreateAwardButton>
        )}
      </CustomerIdContext.Provider>
    </Container>
  );
};

interface Props {
  className?: string;
  customerId: string;
  programId: string;
  error?: string;
}

const AwardListWithErrorMsg: React.FC<Props> = (props) => {
  const [error, setError] = React.useState(null);
  return (
    <SetErrorContext.Provider value={setError}>
      <AwardList {...props} error={error} />
    </SetErrorContext.Provider>
  );
};

export default AwardListWithErrorMsg;
