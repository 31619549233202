import styled from '@emotion/styled';
import { Typography } from '@octanner/prism-core';
import colors from '../../../../../common/constants/Colors';

export const PostsTitle = styled(Typography)`
  margin-bottom: 4px;
  margin-top: 42px;
`;

export const PostsSubTitle = styled(Typography)`
  margin-bottom: 12px;
  color: ${colors.black};
`;

export const RowContainer = styled.div`
  display: flex;
  align-items: center;
`;
