import React from 'react';
import { Route, Switch } from 'react-router-dom';
import BulkUpload from '../Components/BulkUpload/BulkUpload';
import CustomText from '../Components/CustomText/CustomText';
import JobManagement from '../Components/JobManagement/JobManagement';
import LanguageStrings from '../Components/LanguageStrings/LanguageStrings';
import MaterialDescription from '../Components/MaterialDescriptions/MaterialDescription';
import TranslationRequest from '../Components/TranslationRequest/TranslationRequest';
import { CustomTextContextProvider } from '../Contexts/CustomTextContext';
import { JobManagementProvider } from '../Contexts/JobManagementContext';
import { LanguageStringsProvider } from '../Contexts/LanguageStringsContext';
import { LocaleContextProvider } from '../Contexts/LocaleContext';
import { MaterialStringsContextProvider } from '../Contexts/MaterialStringsContext';
import { TranslationRequestContextProvider } from '../Contexts/TranslationRequestContext';
import { LanguageControlProps } from '../utils/types';

const LanguageControl: React.FC<LanguageControlProps> = ({ fetch, customerEmail, customerName }) => (
  <LocaleContextProvider>
    <Switch>
      <Route path='/language-String'>
        <LanguageStringsProvider>
          <LanguageStrings />
        </LanguageStringsProvider>
      </Route>
      <Route path='/job-management'>
        <JobManagementProvider>
          <JobManagement fetch={fetch}/>
        </JobManagementProvider>
      </Route>
      <Route path="/custom-text">
        <CustomTextContextProvider fetch={fetch} customerEmail={customerEmail} customerName={customerName}>
          <CustomText />
        </CustomTextContextProvider>
      </Route>
      <Route path="/material-description">
        <MaterialStringsContextProvider>
          <MaterialDescription />
        </MaterialStringsContextProvider>
      </Route>
      <Route exact path="/bulk-upload">
        <BulkUpload fetch={fetch}/>
      </Route>
      <TranslationRequestContextProvider>
        <Route exact path="/translation-request">
          <TranslationRequest />
        </Route>
      </TranslationRequestContextProvider>
    </Switch>
  </LocaleContextProvider>
);

export default LanguageControl;