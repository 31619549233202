import {
  Autocomplete,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Switch,
  TextField,
  Typography,
  MenuItem,
} from '@octanner/prism-core';
import clsx from 'clsx';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  convertNativeDateStringToDate,
  formatDateTime,
  isEndOfTime,
  updateFormValue,
} from '../../../common/models';
import { useGetTimeZones } from '../../../graphql/timezones/hooks';
import {
  Program,
  PROGRAM_NAME_LENGTH,
  UpdateProgramForm,
} from '../models/models';
import { useStyles } from '../styles';
import { EditProgramProps, FormState } from '../types';

const convertToFormState = (form: Program): FormState => {
  const timeConversion = (date) =>
    !isEndOfTime(date) ? new Date(date).toISOString().split('T')[0] : '';
  return {
    programName: updateFormValue(form.programName, '', false),
    startTsz: updateFormValue(timeConversion(form.startTsz), '', false),
    endTsz: updateFormValue(timeConversion(form.endTsz), '', false),
    displayTz: updateFormValue(form.displayTz || '', '', false),
  };
};

const validateForm = (formState: FormState): FormState | undefined => {
  const programName = formState.programName.value.trim();
  if (!programName) {
    return {
      ...formState,
      programName: updateFormValue(
        formState.programName.value,
        'Enter a Program Name'
      ),
    };
  }
  if (programName.length > PROGRAM_NAME_LENGTH) {
    return {
      ...formState,
      programName: updateFormValue(
        formState.programName.value,
        `Program name must be less than ${PROGRAM_NAME_LENGTH} characters`
      ),
    };
  }

  if (
    !formState.displayTz.value &&
    (formState.startTsz.value || formState.endTsz.value)
  ) {
    return {
      ...formState,
      displayTz: updateFormValue(
        formState.displayTz.value,
        'Must have a Time zone.'
      ),
    };
  }

  const nowUtc = DateTime.utc();
  const startDate = convertNativeDateStringToDate(
    formState.startTsz.value,
    formState.displayTz.value
  );

  if (formState.startTsz.changed && formState.startTsz.value && !startDate) {
    return {
      ...formState,
      startTsz: updateFormValue(formState.startTsz.value, 'Invalid Start date'),
    };
  } else if (
    formState.startTsz.changed &&
    startDate &&
    startDate.toUTC() < nowUtc
  ) {
    return {
      ...formState,
      startTsz: updateFormValue(
        formState.startTsz.value,
        'Start date must be in the future'
      ),
    };
  }

  const endDate = convertNativeDateStringToDate(
    formState.endTsz.value,
    formState.displayTz.value
  );

  if (formState.endTsz.value && !endDate) {
    return {
      ...formState,
      endTsz: updateFormValue(formState.endTsz.value, 'Invalid End date'),
    };
  } else if (endDate && endDate.toUTC() < nowUtc) {
    return {
      ...formState,
      endTsz: updateFormValue(
        formState.endTsz.value,
        'End date must be in the future'
      ),
    };
  } else if (endDate && !startDate) {
    return {
      ...formState,
      endTsz: updateFormValue(formState.endTsz.value, 'Must have a start date'),
    };
  } else if (endDate && startDate && endDate.toUTC() < startDate.toUTC()) {
    return {
      ...formState,
      endTsz: updateFormValue(
        formState.endTsz.value,
        'End date must be after start date'
      ),
    };
  }
};

const convertFromFormState = (formState: FormState): UpdateProgramForm => {
  const state = validateForm(formState);
  if (state) {
    throw new Error('Invalid data');
  }

  const programName = formState.programName.value.trim();
  const startTsz = convertNativeDateStringToDate(formState.startTsz.value);
  const endTsz = convertNativeDateStringToDate(formState.endTsz.value);

  return {
    programName,
    ...(formState.displayTz.changed
      ? { displayTz: formState.displayTz.value }
      : {}),
    ...(formState.startTsz.changed ? { startTsz: startTsz?.toMillis() } : {}),
    ...(formState.endTsz.changed ? { endTsz: endTsz?.toMillis() } : {}),
  };
};

const EditProgram = ({
  program,
  onSave,
  className,
  cancelLink,
}: EditProgramProps) => {
  const classes = useStyles();
  const [state, setState] = useState(() => convertToFormState(program));
  const [hasEndTsz, setHasEndTsz] = useState(!isEndOfTime(program.endTsz));
  const [startDateTz, setStartDateTz] = useState<DateTime>();
  const [timezones, setTimezones] = useState<string[]>([]);
  const { timeZones, loading } = useGetTimeZones();

  useEffect(() => {
    setTimezones(timeZones.map((tsz) => tsz.timeZoneName));
  }, [timeZones]);

  useEffect(() => {
    if (!state.startTsz.value || !state.displayTz.value) {
      setStartDateTz(undefined);
      return;
    }
    setStartDateTz(
      convertNativeDateStringToDate(state.startTsz.value, state.displayTz.value)
    );
  }, [state.startTsz.value, state.displayTz.value]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const invalidState = validateForm(state);
    if (invalidState) {
      setState(invalidState);
      return;
    }
    const form = convertFromFormState(state);
    onSave(form);
  };

  const handleTszChange = (_: any, tsz: string | null) => {
    if (!tsz) {
      return;
    }
    setState({ ...state, displayTz: updateFormValue(tsz) });
  };

  const isStartDisabled = Date.parse(program.startTsz) < Date.now();
  const isEndDisabled = Date.parse(program.endTsz) < Date.now();

  return (
    <form className={clsx(classes.form, className)} onSubmit={handleSubmit}>
      <div className={classes.row}>
        <div>
          <TextField
            id="program-name"
            label="Program Name"
            fullWidth
            error={Boolean(state.programName.error)}
            helperText={state.programName.error}
            value={state.programName.value}
            onChange={(e) =>
              setState({
                ...state,
                programName: updateFormValue(e.target.value),
              })
            }
            autoFocus
            inputProps={{ 'data-test': 'program:edit:name' }}
          />
        </div>
        <div>
          <TextField
            id="program-startTsz"
            label="Start Date"
            fullWidth
            error={Boolean(state.startTsz.error)}
            helperText={state.startTsz.error}
            value={state.startTsz.value}
            onChange={(e) =>
              setState({ ...state, startTsz: updateFormValue(e.target.value) })
            }
            inputProps={{ 'data-test': 'program:edit:startTsz' }}
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            disabled={isStartDisabled}
          />
        </div>
        {hasEndTsz && (
          <div>
            <TextField
              id="program-endTsz"
              label="End Date"
              fullWidth
              error={Boolean(state.endTsz.error)}
              helperText={state.endTsz.error}
              value={state.endTsz.value}
              onChange={(e) =>
                setState({ ...state, endTsz: updateFormValue(e.target.value) })
              }
              inputProps={{ 'data-test': 'program:edit:endTsz' }}
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              disabled={isEndDisabled}
            />
          </div>
        )}
        <div>
          <FormControl fullWidth error={Boolean(state.displayTz.error)}>
            <Autocomplete
              disabled={isStartDisabled}
              loading={loading}
              options={timezones}
              value={state.displayTz.value}
              onChange={handleTszChange}
              renderOption={(props, timezone: any) => (
                <MenuItem
                  {...props}
                  data-test={`program:edit:displayTz:${timezone}`}
                >
                  {timezone}
                </MenuItem>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  label="Time Zone"
                  placeholder="Search by time zone"
                  inputProps={{
                    ...params.inputProps,
                    'data-test': 'program:edit:displayTz:input',
                  }}
                />
              )}
            />
            <FormHelperText>{state.displayTz.error}</FormHelperText>
          </FormControl>
        </div>
        <div>
          <FormControlLabel
            control={
              <Switch
                checked={hasEndTsz}
                onChange={(e) => setHasEndTsz(e.target.checked)}
                name="hasEndTsz"
                data-test="program:edit:hasEndTsz"
              />
            }
            label="Include end date"
          />
        </div>
      </div>
      {startDateTz && (
        <div className={classes.mdBottom}>
          <Typography>
            The program will begin{' '}
            <span data-test="program:edit:begin-text">
              {formatDateTime(startDateTz, true)}
            </span>
            .
          </Typography>
        </div>
      )}
      <div className={classes.row}>
        <div>
          <Button type="submit" data-test="program:edit:submit" adminColor>
            Update Program
          </Button>
          <RouterLink to={cancelLink} data-test="program:edit:cancel">
            <Button className={classes.smLeft} color="secondary">
              Cancel
            </Button>
          </RouterLink>
        </div>
      </div>
    </form>
  );
};

export default EditProgram;
