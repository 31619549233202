import styled from '@emotion/styled';
import {
  Button,
  FormControlLabel,
  RadioGroup,
  Select,
  SingleSelectionButtons,
  TextField,
  Typography,
} from '@octanner/prism-core';
import { Add } from '@octanner/prism-icons';

export const BankInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 24px;
  padding-top: 8px;
  padding-left: 8px;
`;

export const TextFieldWidthHeight = styled(TextField)`
  width: 356px;
  margin-top: 8px;
`;

export const AddBankSubtitle = styled(Typography)`
  padding-bottom: 8px;
`;

export const ButtonPadding = styled.div`
  padding: 2px;
`;

export const SelectWidthMT = styled(Select)`
  width: 364px;
  margin-top: 8px;
`;

export const RadioGroupMarginLT = styled(RadioGroup)`
  margin-left: 24px;
  margin-top: 9.5px;
`;

export const FormControlLabelWidth = styled(FormControlLabel)`
  width: 150px;
`;

export const ModalTypography = styled(Typography)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 12px;
`;

export const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 24px;
`;

export const DatePickerContainer = styled.div`
  width: 205px;
`;

export const DatesContainer = styled.div`
  display: flex;
  gap: 20px;
  margin-bottom: 8px;
`;

export const MoreDateContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ButtomMT = styled(Button)`
  margin-top: 10px;
`;

export const TextFieldWMB = styled(TextField)`
  width: 205px;
  margin-bottom: 5px;
`;

export const AddMR = styled(Add)`
  margin-right: 8px;
`;

export const SingleSelectionButtonsMB = styled(SingleSelectionButtons)`
  margin-bottom: 8px;
`;

export const RangeRequired = styled(Typography)<{ checkDates: boolean }>`
  color: ${(props) => (props.checkDates ? '#D10A12' : '')};
  margin-bottom: 8px;
`;

export const PoNumberTitleMB = styled(Typography)`
  margin-bottom: 8px;
`;

export const RadioGroupMLTB = styled(RadioGroup)`
  margin-left: 24px;
  margin-top: 9.5px;
`;

export const SelectPONumW = styled(Select)`
  width: 320px;
  margin-top: 6px;
`;
