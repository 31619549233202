import permissionAuthorizer from '../../utils/auth/PermissionAuthorizer';
import xcaAuthorizer from '../../utils/auth/XcaAuthorizer';
import BanksList from '../banks/viewBank/BanksList';
import NewBank from '../banks/modifyBank/createBank';
import ViewBank from '../banks/viewBank';
import { LDAddProgram, LDEditProgram } from '../programs/modifyProgram';
import YearbookBudgetGroups from '../programs/YearbookBudgetGroups';
import YearbookCelebrations from '../programs/YearbookCelebrations';
import CreateRole from '../user-auth/CreateRole';
import Role from '../user-auth/Role';
import Roles from '../user-auth/Roles';
import Client from './Client';
import ClientConfiguration from '../clientConfiguration/ClientConfiguration';
import VibesLanding from '../vibes/VibesLanding';
import VibeDetails from '../vibes/Vibe';
import NewVibe from '../vibes/NewSegment';
import LocalePage from '../locale';
import Inprogress from '../inProgress/Inprogress';
import SocialWall from '../systemSettings/socialWall';
import EditBank from '../banks/modifyBank/editBank';
import ProgramsList from '../programs/viewProgram/ProgramsList';
import SystemSettings from '../systemSettings';
import { CustomerRoutes } from './types';
import StoreExperience from '../global/StoreExperience';

export const CustomerRoute: CustomerRoutes[] = [
  {
    authorizer: xcaAuthorizer,
    path: '/client-summary',
    component: Client,
    exact: true,
  },
  {
    authorizer: xcaAuthorizer,
    path: '/client-summary/edit',
    component: Client,
    exact: true,
  },
  {
    authorizer: xcaAuthorizer,
    path: '/client-config',
    component: ClientConfiguration,
    exact: true,
  },
  {
    authorizer: xcaAuthorizer,
    path: '/system-settings',
    component: SystemSettings,
    exact: true,
  },
  {
    authorizer: permissionAuthorizer(
      'CORE_CUSTCONFIG_CUSTOMER_AND_LOCALE_WRITE'
    ),
    path: '/locale',
    component: LocalePage,
    exact: true,
  },
  {
    authorizer: permissionAuthorizer('ROLE_DELEGATOR_ROLES_VIEW'),
    path: '/roles-and-permissions',
    component: Roles,
    exact: true,
  },
  {
    authorizer: permissionAuthorizer('ROLE_DELEGATOR_ROLES_CREATE'),
    path: '/roles-and-permissions/new',
    component: CreateRole,
    exact: true,
  },
  {
    authorizer: permissionAuthorizer('ROLE_DELEGATOR_ROLES_VIEW'),
    path: '/roles-and-permissions/:roleId',
    component: Role,
    exact: true,
  },
  {
    authorizer: permissionAuthorizer('PROGRAM_ELIGIBILITY_CREATE_PROGRAM'),
    path: '/programs/new',
    component: LDAddProgram,
    exact: true,
  },
  {
    authorizer: permissionAuthorizer('PROGRAM_ELIGIBILITY_CREATE_PROGRAM'),
    path: '/programs/:programId/edit',
    component: LDEditProgram,
    exact: true,
  },
  {
    authorizer: permissionAuthorizer('CC_YEARBOOK_API_CONFIGURATION_WRITE'),
    path: '/programs/:programId/celebrations/new',
    component: YearbookCelebrations,
    exact: true,
  },
  {
    authorizer: permissionAuthorizer('CC_YEARBOOK_API_CONFIGURATION_WRITE'),
    path: '/programs/:programId/celebrations/:celebrationGroupId',
    component: YearbookCelebrations,
    exact: true,
  },
  {
    authorizer: permissionAuthorizer('CC_YEARBOOK_API_CONFIGURATION_WRITE'),
    path: '/programs/:programId/budget-groups/new',
    component: YearbookBudgetGroups,
    exact: true,
  },
  {
    authorizer: permissionAuthorizer('CC_YEARBOOK_API_CONFIGURATION_WRITE'),
    path: '/programs/:programId/budget-groups/:budgetGroupId',
    component: YearbookBudgetGroups,
    exact: true,
  },
  {
    authorizer: permissionAuthorizer('PROGRAM_ELIGIBILITY_VIEW_PROGRAM'),
    path: '/programs',
    component: ProgramsList,
    exact: true,
  },
  {
    authorizer: permissionAuthorizer('CORE_PTS_MGMT_INTERNAL_CREATE_BANK'),
    path: '/banks/new',
    component: NewBank,
    exact: true,
  },
  {
    authorizer: permissionAuthorizer('CORE_PTS_MGMT_GET_BANK_INFORMATION'),
    path: '/banks/:bankId',
    component: ViewBank,
    exact: true,
  },
  {
    authorizer: permissionAuthorizer('CORE_PTS_MGMT_INTERNAL_CREATE_BANK'),
    path: '/banks/:bankId/edit',
    component: EditBank,
    exact: true,
  },
  {
    authorizer: permissionAuthorizer('CORE_PTS_MGMT_GET_BANK_INFORMATION'),
    path: '/banks',
    component: BanksList,
    exact: true,
  },
  {
    authorizer: permissionAuthorizer(['CORE_VIBES_READ', 'CORE_VIBES_WRITE']),
    path: '/themes/new',
    component: NewVibe,
    exact: true,
  },
  {
    authorizer: permissionAuthorizer(['CORE_VIBES_READ', 'CORE_VIBES_WRITE']),
    path: '/themes/:vibeId',
    component: VibeDetails,
    exact: true,
  },
  {
    authorizer: permissionAuthorizer(['CORE_VIBES_READ', 'CORE_VIBES_WRITE']),
    path: '/themes',
    component: VibesLanding,
    exact: true,
  },
  {
    authorizer: xcaAuthorizer,
    path: '/loginAndSecurity',
    component: Inprogress,
    exact: true,
  },
  {
    authorizer: xcaAuthorizer,
    path: '/site-settings',
    component: Inprogress,
    exact: true,
  },
  {
    authorizer: xcaAuthorizer,
    path: '/social-wall',
    component: SocialWall,
    exact: true,
  },
  {
    authorizer: xcaAuthorizer,
    path: '/digitalPublishing',
    component: Inprogress,
    exact: true,
  },
  {
    authorizer: xcaAuthorizer,
    path: '/integrations',
    component: Inprogress,
    exact: true,
  },
  {
    authorizer: xcaAuthorizer,
    path: '/manageBudgets',
    component: Inprogress,
    exact: true,
  },
  {
    authorizer: xcaAuthorizer,
    path: '/recognitionCaps',
    component: Inprogress,
    exact: true,
  },
  {
    authorizer: xcaAuthorizer,
    path: '/groupDeposits',
    component: Inprogress,
    exact: true,
  },
  {
    authorizer: xcaAuthorizer,
    path: '/employeeActivity',
    component: Inprogress,
    exact: true,
  },
  {
    authorizer: xcaAuthorizer,
    path: '/orders',
    component: Inprogress,
    exact: true,
  },
  {
    authorizer: xcaAuthorizer,
    path: '/dataExport',
    component: Inprogress,
    exact: true,
  },
  {
    authorizer: xcaAuthorizer,
    path: '/dashboards',
    component: Inprogress,
    exact: true,
  },
  {
    authorizer: xcaAuthorizer,
    path: '/approval-center',
    component: Inprogress,
    exact: true,
  },
  {
    authorizer: xcaAuthorizer,
    path: '/design-management',
    component: Inprogress,
    exact: true,
  },
  {
    authorizer: xcaAuthorizer,
    path: '/price-agreements',
    component: Inprogress,
    exact: true,
  },
  {
    authorizer: xcaAuthorizer,
    path: '/products',
    component: Inprogress,
    exact: true,
  },
  {
    authorizer: xcaAuthorizer,
    path: '/store-admin',
    component: Inprogress,
    exact: true,
  },
  {
    authorizer: xcaAuthorizer,
    path: '/order-experience',
    component: Inprogress,
    exact: true,
  },
  {
    authorizer: xcaAuthorizer,
    path: '/store-experience',
    component: StoreExperience,
    exact: true,
  },
];
