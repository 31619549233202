import { AppContext } from '../../../../../utils/context/AppContext';
import { useContext } from 'react';
import { GET_CATEGORIES } from '../graphql/gql';
import { useQuery } from '@apollo/client';
import { TEST_CATEGORIES } from '../constants';
import { CategoriesResponse } from '../graphql/types';

const mockData = () => ({
  data: {
    content: TEST_CATEGORIES,
  },
  loading: false,
});

export const useGetCategories = () => {
  const { config } = useContext(AppContext);
  const query = useQuery<CategoriesResponse>(GET_CATEGORIES, {
    variables: {
      page: 0,
      size: 100,
    },
    skip: config.useRoleDelegatorMockData,
    context: {
      clientName: 'roleDelegator',
    },
  });

  return config.useRoleDelegatorMockData
    ? mockData()
    : {
        ...query,
        data: query.data?.rdCategories,
      };
};
