import { Button, Typography } from '@octanner/prism-core';
import { ActionDialog as Dialog } from '../../common/BinaryDialog';
import clsx from 'clsx';
import { useState, useContext } from 'react';
import {
  endImpersonating,
  isImpersonating,
  startImpersonating,
} from '../../utils/auth/Auth';
import { withPad } from '../../common/BasicPadding';
import {
  useCurrentIdentity,
  useIdentitySearch,
} from '../user-auth/hooks/identity';
import ImpersonateIdentityList from './ImpersonateIdentityList';
import ImpersonateSearchForm from './ImpersonateSearchForm';
import {
  IdentitySearchInput,
  IdentityWithCustomerBase,
} from '../user-auth/models/Identity';
import { AppContext } from '../../utils/context/AppContext';
import { CustomerContext } from '../../utils/context/CustomerContext';
import Loading from '../../common/Loading';
import SnackbarPopup from '../../common/snackbar';
import { useStyles } from './styles';
import ImpersonateHistory from './ImpersonateHistory';

const Impersonate = () => {
  const classes = useStyles();
  const [error, setError] = useState('');
  const [selectedIdentity, setSelectedIdentity] =
    useState<IdentityWithCustomerBase>();
  const [searchCondition, setSearchCondition] = useState<
    IdentitySearchInput['condition']
  >({});
  const { data: identityRes } = useCurrentIdentity();
  const { config } = useContext(AppContext);
  const { currentImpersonateClient, setCurrentImpersonateClient } =
    useContext(CustomerContext);

  const [orderBy, setOrderBy] = useState<string[]>(['lastName', 'desc']);
  const [page, setPage] = useState<number>(1);
  const { data, loading } = useIdentitySearch({
    ...searchCondition,
    orderBy: orderBy.join(' '),
    page: page - 1,
  });
  const handleSort = (header: string) => {
    setOrderBy(([, currentOrder]) => [
      header,
      currentOrder === 'desc' ? 'asc' : 'desc',
    ]);
  };

  if (isImpersonating()) {
    if (!identityRes?.currentIdentity) {
      return <Loading />;
    }
    const { currentIdentity: identity } = identityRes;
    const handleEndImpersonating = async () => {
      setError('');
      const isSuccesful = await endImpersonating();
      if (!isSuccesful)
        setError(
          'There was an error while trying to end impersonation please try again.'
        );
    };
    const name = `${identity?.firstName} ${identity?.lastName}`;
    const text = `(${identity?.email}, ${identity?.customer.name}, ${identity?.uniqueId})`;
    return (
      <div>
        <Typography variant="h1">Impersonate</Typography>
        <div className={clsx(classes.search, classes.endImpersonating)}>
          <Typography
            className={classes.impersonateText}
            data-test="impersonating:text"
          >
            Impersonating: <strong>{name}</strong> {text}
          </Typography>
          <Button
            data-test="impersonate:stop"
            className={classes.alignRight}
            onClick={handleEndImpersonating}
            disableRipple
            adminColor
          >
            Stop Impersonating
          </Button>
        </div>
      </div>
    );
  }
  const handleSubmit = (searchCondition: IdentitySearchInput['condition']) => {
    setPage(1);
    setSearchCondition(searchCondition);
  };
  const handleClick = (identity: IdentityWithCustomerBase) => {
    setSelectedIdentity(identity);
  };
  const openNewTab = () => {
    let newTabWindow = window.open();
    return newTabWindow;
  };

  const updateTabLocation = (tabLocation, tab) => {
    if (!tabLocation) {
      tab.close();
    }
    tab.location.href = tabLocation;
  };

  const start = async () => {
    if (!selectedIdentity) return;
    setError('');
    let myNewTab = openNewTab();
    const isSuccesful = await startImpersonating(selectedIdentity.id);
    if (!isSuccesful) {
      setSelectedIdentity(undefined);
      updateTabLocation('', myNewTab);
      return setError(
        'There was an error while trying to impersonate please try again.'
      );
    } else {
      updateTabLocation(config.cultureCloudUrl, myNewTab);
    }
  };
  const identities = data?.identitySearch.identities || [];
  const showTable = Boolean(identities.length);
  const open = Boolean(selectedIdentity);

  return (
    <div>
      <SnackbarPopup
        open={error.length ? true : false}
        handleClose={() => setError('')}
        severity="error"
        text={error}
      />
      <Typography variant="h1">Impersonate</Typography>
      <ImpersonateSearchForm
        className={classes.topSpacing}
        handleSubmit={handleSubmit}
        currentImpersonateClient={currentImpersonateClient}
        setCurrentImpersonateClient={setCurrentImpersonateClient}
      />
      {loading && <Loading />}
      {!loading && showTable && data?.identitySearch && (
        <ImpersonateIdentityList
          identities={identities}
          onRowClick={handleClick}
          onSort={handleSort}
          orderBy={orderBy}
          count={data.identitySearch.pagesCount}
          currentPage={page}
          setPage={setPage}
        />
      )}
      {currentImpersonateClient && (
        <ImpersonateHistory
          identity={identityRes?.currentIdentity}
          impersonateClient={currentImpersonateClient}
          onRowClick={handleClick}
        />
      )}
      {!loading && !showTable && data?.identitySearch && (
        <Typography>No Results</Typography>
      )}
      <Dialog
        open={open}
        onClose={() => setSelectedIdentity(undefined)}
        title="Start Impersonating"
        actions={
          <div>
            <Button
              disableRipple
              data-test="impersonate:confirm:continue"
              onClick={start}
              adminColor
            >
              Continue
            </Button>
            <Button
              data-test="impersonate:confirm:cancel"
              disableRipple
              color="secondary"
              className={classes.cancel}
              onClick={() => setSelectedIdentity(undefined)}
            >
              Cancel
            </Button>
          </div>
        }
      >
        <Typography>{`You are about to impersonate ${selectedIdentity?.firstName} ${selectedIdentity?.lastName}.`}</Typography>
      </Dialog>
    </div>
  );
};

export default withPad()(Impersonate);
