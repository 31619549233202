import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Tag,
} from '@octanner/prism-core';
import Typography from '@octanner/prism-core/Typography';
import { AdminRoleInformation, roleTypeValues } from '../types';
import {
  tannerBlue,
  tannerGray,
} from '@octanner/prism-core/ThemeProvider/colors';
import { Trash } from '@octanner/prism-icons';
import { CardActions } from '@mui/material';
import { formatDate } from '../dateUtils';
import { CardTransparency } from '../styles';

const RoleInformationCard = ({
  cardState,
  adminRole,
  isActionDisabled,
  onAction,
  isPending,
}: {
  cardState: 'active' | 'inactive' | 'deleted';
  adminRole: AdminRoleInformation;
  isActionDisabled: boolean;
  onAction: (role: AdminRoleInformation) => void;
  isPending?: boolean;
}) => {
  return (
    <Card
      sx={{
        mt: 4,
        position: 'relative',
        backgroundColor: cardState !== 'active' ? tannerGray[50] : 'default',
        boxShadow: cardState !== 'active' ? 'none' : 'default',
        border: isPending ? `2px solid ${tannerBlue[500]}` : 'none',
      }}
    >
      {cardState === 'deleted' && <CardTransparency />}
      <Box>
        <CardHeader
          title={adminRole.role.name}
          subheader={
            <Box>
              <Typography
                variant="body2"
                component="span"
                sx={{ color: tannerGray[700] }}
              >
                {`${
                  roleTypeValues[adminRole.role.accessType as string]
                    .displayName
                } | Assigned on : `}
              </Typography>
              <Typography variant="body2" component="span">
                {formatDate(
                  adminRole.assignedOnDate || new Date().toISOString()
                )}
              </Typography>
              {cardState !== 'active' && (
                <>
                  <Typography
                    variant="body2"
                    component="span"
                    sx={{ color: tannerGray[700] }}
                  >
                    {` | Removed on : `}
                  </Typography>
                  <Typography variant="body2" component="span">
                    {formatDate(
                      adminRole.removedOnDate || new Date().toISOString()
                    )}
                  </Typography>
                </>
              )}
            </Box>
          }
          action={
            <>
              {cardState === 'active' ? (
                <IconButton onClick={() => onAction(adminRole)}>
                  <Trash />
                </IconButton>
              ) : (
                <Button
                  color="secondary"
                  onClick={() => onAction(adminRole)}
                  disabled={isActionDisabled}
                >
                  Re-assign
                </Button>
              )}
            </>
          }
          sx={{ paddingBottom: 0 }}
        />
        <CardContent
          sx={{ width: 752, paddingLeft: 16, paddingTop: 3, paddingBottom: 3 }}
        >
          <Typography>{adminRole.role.description}</Typography>
        </CardContent>
        <CardActions>
          {cardState === 'deleted' ? (
            <>
              <Tag variant="error">Deleted</Tag>
              <Box>
                <Typography
                  variant="body2"
                  component="span"
                  sx={{ color: tannerGray[700] }}
                >
                  {`Deleted on : `}
                </Typography>
                <Typography variant="body2" component="span">
                  {formatDate(
                    adminRole.role.deactivatedTsz || new Date().toISOString()
                  )}
                </Typography>
              </Box>
            </>
          ) : (
            <>
              <Tag variant="archived">{`${adminRole.permissionCount} Permissions`}</Tag>
              <Tag variant="archived">{`${adminRole.clientCount} Clients`}</Tag>
              <Tag variant="archived">{`${adminRole.adminCount} Admins`}</Tag>
            </>
          )}
        </CardActions>
      </Box>
    </Card>
  );
};

export default RoleInformationCard;
