import styled from "@emotion/styled";
import { Alert, Button, Grid, Typography } from "@octanner/prism-core";
import React, { useContext, useState } from "react";
import ViewJobModal from "../JobManagement/Modal/ViewJobModal";
import { TranslationRequestContext } from "../../Contexts/TranslationRequestContext";
import { defaults } from "../../utils/default";

const SuccessMessageContainer = styled.div`
  display: flex;
  align-items: center;
`

const AlertBox = styled(Grid)`
  margin-top: 12px;
  .MuiAlert-action {
    align-items: center;
  }
`

const TranslationRequestAlert: React.FC = () => {

  const { createdJobNumber, setCreatedJobNumber, unTranslatedStringList,
    messages, closeMessage, translationCategory } = useContext(TranslationRequestContext);

  const [showViewJobModal, setShowViewJobModal] = useState<boolean>(false);

  const closeAlert = (messageKey: string) => {
    if (messageKey === 'successMessage') {
      setCreatedJobNumber(0)
    }
    closeMessage(messageKey);
  }

  return (<>
    {showViewJobModal && <ViewJobModal showViewJobModal={showViewJobModal} jobNumber={createdJobNumber}
      setShowViewJobModal={setShowViewJobModal} translationCategory={translationCategory}></ViewJobModal>}
    {messages.map(message => (
      message?.text?.length > 0 && (message.key !== 'selectOnHoldInfo' ||
        unTranslatedStringList.some((item) => item.selected && item.onHoldInd)) && (
        <AlertBox key={message.key} item xs={message.gridSize}>
          <Alert severity={message.severity} onClose={() => closeAlert(message.key)} action={
            (message.key === 'successMessage' && createdJobNumber > 0) ?
              <Button onClick={() => setShowViewJobModal(true)} variant="text" size="small">{defaults.view_job}</Button> : <></>}>
            {message.key !== 'successMessage' && <>{message.text}</>}
            {message.key === 'successMessage' && <SuccessMessageContainer>
              {message.text}{createdJobNumber > 0 &&
                <>&nbsp;{createdJobNumber > 0 && <Typography variant="h4">{createdJobNumber}</Typography>}</>}
            </SuccessMessageContainer>}
          </Alert>
        </AlertBox>
      )
    ))}
  </>);
}

export default TranslationRequestAlert;