import { ApolloError, useQuery } from '@apollo/client';
import OnComplete from '../models/OnComplete';
import { GET_ALL_FEATURES } from './graphql';
import { FeatureListConnection } from '../models/models';

export const useGetAllFeatures = (
  onCompleted?: OnComplete<FeatureListConnection>,
): [FeatureListConnection | undefined, boolean, ApolloError | undefined] => {
  const { data, loading, error } = useQuery<FeatureListConnection>(
    GET_ALL_FEATURES,
    {
      onCompleted,
    },
  );
  return [data, loading, error];
};
