import { useQuery } from '@apollo/client';
import { SEARCH_ADMIN_ROLES } from '../graphql/gql';
import {
  PagedAdminRolesResponse,
  SearchAdminRolesInput,
} from '../graphql/types';
import { TEST_ADMIN_ROLE_DATA } from '../../constants';
import { useContext } from 'react';
import { AppContext } from '../../../../../utils/context/AppContext';

const shouldSkip = (search: string[]) => {
  return search.filter((s) => s.length > 0 && s.length < 3).length > 0;
};

function compareRoles(searchRolesInput: SearchAdminRolesInput) {
  return (a, b) => {
    const { sortA, sortB } =
      searchRolesInput.sortDirection === 'DESC'
        ? { sortA: b, sortB: a }
        : { sortA: a, sortB: b };
    if (searchRolesInput.sortBy === 'ADMIN_COUNT') {
      return sortA.adminCount - sortB.adminCount;
    } else if (searchRolesInput.sortBy === 'PERMISSION_COUNT') {
      return sortA.permissionCount - sortB.permissionCount;
    } else if (searchRolesInput.sortBy === 'CLIENT_COUNT') {
      return sortA.clientCount - sortB.clientCount;
    } else if (searchRolesInput.sortBy === 'ACCESS_TYPE') {
      return sortA.role.accessType.localeCompare(sortB.role.accessType);
    } else if (searchRolesInput.sortBy === 'CREATED_DATE') {
      return sortA.role.createdTsz.getTime() - sortB.role.createdTsz.getTime();
    } else {
      return sortA.name.localeCompare(sortB.name);
    }
  };
}

const mockData = (searchRolesInput: SearchAdminRolesInput) => ({
  data: {
    content: TEST_ADMIN_ROLE_DATA.filter(
      (adminRole) =>
        searchRolesInput.searchTerms.length === 0 ||
        shouldSkip(searchRolesInput.searchTerms) ||
        searchRolesInput.searchTerms.some(
          (search) =>
            adminRole.name.toLowerCase().includes(search || '') ||
            adminRole.role.uuid.includes(search || '')
        )
    ).sort(compareRoles(searchRolesInput)),
    total: 234,
    totalPages: 24,
    last: false,
  },
  loading: false,
  refetch: () => {},
});

export const useSearchAdminRoles = (
  searchRolesInput: SearchAdminRolesInput
) => {
  const { config } = useContext(AppContext);
  const query = useQuery<PagedAdminRolesResponse>(SEARCH_ADMIN_ROLES, {
    variables: searchRolesInput,
    skip:
      shouldSkip(searchRolesInput.searchTerms) ||
      config.useRoleDelegatorMockData,
    context: {
      clientName: 'roleDelegator',
    },
    fetchPolicy: 'network-only',
  });
  return config.useRoleDelegatorMockData
    ? mockData(searchRolesInput)
    : {
        ...query,
        data: query.data?.rdFindPagedAdminRoles,
      };
};
