import {
  AdminRoleInformation,
  Permission,
  RoleInformationWithPermissions,
} from './types';
import { PagedAdminRoles } from './adminRoles/graphql/types';

export const PERMISSION_1_READ: Permission = {
  uuid: '11',
  name: 'Permission 1 Read',
};
export const PERMISSION_1_WRITE: Permission = {
  uuid: '12',
  name: 'Permission 1 Write',
};
export const PERMISSION_2_READ: Permission = {
  uuid: '21',
  name: 'Permission 2 Read',
};
export const PERMISSION_2_WRITE: Permission = {
  uuid: '22',
  name: 'Permission 2 Write',
};
export const PERMISSION_3_READ: Permission = {
  uuid: '31',
  name: 'Permission 3 Read',
};
export const PERMISSION_3_WRITE: Permission = {
  uuid: '32',
  name: 'Permission 3 Write',
};
export const PERMISSION_4_READ: Permission = {
  uuid: '41',
  name: 'Permission 4 Read',
};
export const PERMISSION_4_WRITE: Permission = {
  uuid: '42',
  name: 'Permission 4 Write',
};
export const PERMISSION_5_READ: Permission = {
  uuid: '51',
  name: 'Permission 5 Read',
};
export const PERMISSION_5_WRITE: Permission = {
  uuid: '52',
  name: 'Permission 5 Write',
};
export const PERMISSION_6_READ: Permission = {
  uuid: '61',
  name: 'Permission 6 Read',
};
export const PERMISSION_6_WRITE: Permission = {
  uuid: '62',
  name: 'Permission 6 Write',
};
export const PERMISSION_7_READ: Permission = {
  uuid: '71',
  name: 'Permission 7 Read',
};
export const PERMISSION_7_WRITE: Permission = {
  uuid: '72',
  name: 'Permission 7 Write',
};

export const ROLE_1: RoleInformationWithPermissions = {
  uuid: '1',
  name: 'Global Permissions admin',
  description: 'This is a short role description',
  accessType: 'ADMIN',
  createdTsz: '2023-12-07',
  permissions: [
    PERMISSION_1_READ,
    PERMISSION_1_WRITE,
    PERMISSION_2_READ,
    PERMISSION_2_WRITE,
    PERMISSION_3_READ,
    PERMISSION_3_WRITE,
    PERMISSION_4_READ,
    PERMISSION_4_WRITE,
    PERMISSION_5_READ,
    PERMISSION_5_WRITE,
    PERMISSION_6_READ,
    PERMISSION_6_WRITE,
    PERMISSION_7_READ,
    PERMISSION_7_WRITE,
  ],
};

export const ROLE_2: RoleInformationWithPermissions = {
  uuid: '2',
  name: 'Client Success - Client Admin',
  description:
    'This is a medium length role description.  Lorem ipsum dolor sit amet, consectetur adipiscing elit adipiscing elit.',
  accessType: 'GLOBAL',
  createdTsz: '2020-07-19',
  permissions: [
    PERMISSION_1_READ,
    PERMISSION_1_WRITE,
    PERMISSION_2_READ,
    PERMISSION_2_WRITE,
    PERMISSION_3_READ,
    PERMISSION_3_WRITE,
  ],
};

export const ROLE_3: RoleInformationWithPermissions = {
  uuid: '3',
  name: 'Data Specialist',
  description:
    'This is a long role description that spans multiple lines. Lorem ipsum dolor sit amet, consectetur adipiscing elit adipiscing elit. Praesent nec molestie nisi. Ut sodales mattis ex. Lorem ipsum dolor sit amet, consectetur adipiscing elit adipiscing elit. Praesent nec molestie nisi. Ut sodales mattis ex.',
  accessType: 'ADMIN',
  createdTsz: '2024-04-28',
  permissions: [
    PERMISSION_1_READ,
    PERMISSION_1_WRITE,
    PERMISSION_2_READ,
    PERMISSION_2_WRITE,
    PERMISSION_3_READ,
    PERMISSION_3_WRITE,
    PERMISSION_4_READ,
    PERMISSION_4_WRITE,
  ],
};

export const ROLE_4: RoleInformationWithPermissions = {
  uuid: '4',
  name: 'Testing',
  accessType: 'ADMIN',
  createdTsz: '18 Sep 2022',
  deactivatedTsz: '18 Oct 2023',
  permissions: [PERMISSION_1_READ, PERMISSION_1_WRITE],
};

export const ROLE_5: RoleInformationWithPermissions = {
  uuid: '5',
  name: 'Corporate Projects',
  accessType: 'GLOBAL',
  createdTsz: '22 Jan 2021',
  permissions: [PERMISSION_1_READ, PERMISSION_1_WRITE],
};

export const ROLE_6: RoleInformationWithPermissions = {
  uuid: '6',
  name: 'Engineering',
  accessType: 'GLOBAL',
  createdTsz: '29 Feb 2020',
  permissions: [PERMISSION_1_READ, PERMISSION_1_WRITE],
};

export const ROLE_7: RoleInformationWithPermissions = {
  uuid: '7',
  name: 'Marketing',
  accessType: 'ADMIN',
  createdTsz: '10 Mar 2023',
  permissions: [PERMISSION_1_READ, PERMISSION_1_WRITE],
};

export const ROLE_8: RoleInformationWithPermissions = {
  uuid: '8',
  name: 'Reporting',
  accessType: 'ADMIN',
  createdTsz: '8 Jul 2023',
  permissions: [PERMISSION_1_READ, PERMISSION_1_WRITE],
};

export const ROLE_9: RoleInformationWithPermissions = {
  uuid: '9',
  name: 'Sales',
  accessType: 'ADMIN',
  createdTsz: '10 Aug 2023',
  permissions: [PERMISSION_1_READ, PERMISSION_1_WRITE],
};

export const TEST_ADMIN_ROLE_DATA: AdminRoleInformation[] = [
  {
    name: 'Global Permissions admin',
    role: ROLE_1,
    assignedOnDate: '21 Jun 2023',
    permissionCount: 25,
    adminCount: 24,
    clientCount: 30,
  },
  {
    name: 'Client Success - Client Admin',
    role: ROLE_2,
    assignedOnDate: '21 Oct 2023',
    permissionCount: 25,
    adminCount: 30,
    clientCount: 24,
  },
  {
    name: 'Data Specialist',
    role: ROLE_3,
    assignedOnDate: '21 Dec 2023',
    permissionCount: 20,
    adminCount: 100,
    clientCount: 99,
  },
  {
    name: 'Testing',
    role: ROLE_4,
    assignedOnDate: '26 Apr 2023',
    permissionCount: 18,
    adminCount: 78,
    clientCount: 78,
  },
  {
    name: 'Corporate Projects',
    role: ROLE_5,
    assignedOnDate: '11 Apr 2023',
    permissionCount: 37,
    adminCount: 25,
    clientCount: 83,
  },
  {
    name: 'Engineering',
    role: ROLE_6,
    assignedOnDate: '10 Apr 2023',
    permissionCount: 2,
    adminCount: 78,
    clientCount: 78,
  },
  {
    name: 'Marketing',
    role: ROLE_7,
    assignedOnDate: '11 Feb 2023',
    permissionCount: 168,
    adminCount: 55,
    clientCount: 22,
  },
  {
    name: 'Reporting',
    role: ROLE_8,
    assignedOnDate: '21 Jan 2023',
    permissionCount: 2,
    adminCount: 1,
    clientCount: 2,
  },
  {
    name: 'Sales',
    role: ROLE_9,
    assignedOnDate: '20 Jan 2023',
    permissionCount: 65,
    adminCount: 52,
    clientCount: 78,
  },
];

export const TEST_ACTIVE_ADMIN_ROLES_PAGED_DATA: PagedAdminRoles = {
  content: [
    {
      name: 'Reporting',
      role: ROLE_8,
      assignedOnDate: '21 Jan 2023',
      permissionCount: 2,
      adminCount: 1,
      clientCount: 2,
    },
  ],
  total: 1,
  totalPages: 2,
};

export const TEST_INACTIVE_ADMIN_ROLES_PAGED_DATA: PagedAdminRoles = {
  content: [
    {
      name: 'Data Specialist',
      role: ROLE_3,
      assignedOnDate: '21 Dec 2023',
      removedOnDate: '21 Jan 2024',
      permissionCount: 20,
      adminCount: 100,
      clientCount: 99,
    },
    {
      name: 'Data Specialist',
      role: ROLE_3,
      assignedOnDate: '14 Feb 2024',
      removedOnDate: '18 Jun 2024',
      permissionCount: 20,
      adminCount: 100,
      clientCount: 99,
    },
    {
      name: 'Testing',
      role: ROLE_4,
      assignedOnDate: '26 Apr 2023',
      removedOnDate: '20 Jan 2024',
      permissionCount: 18,
      adminCount: 78,
      clientCount: 78,
    },
  ],
  total: 2,
  totalPages: 1,
};
