import {
  HeadingToolbar,
  Plate,
  useEventEditorId,
  ToolbarProps,
  withPlateEventProvider,
} from '@udecode/plate';
import { BasicElementToolbarButtons } from './toolbar';
import plugins from './SlateComponents';
import { RTCProps } from './types';
import { MyValue } from './plateTypes';
import { useEffect, useState } from 'react';

export const Toolbar = withPlateEventProvider((props: ToolbarProps) => (
  <HeadingToolbar {...props} />
));

//? Reload must be used here, as Plate will bake in and not allow a rerender if the value is changed in edit only mode.

const RichTextEditor = (props: RTCProps) => {
  const [value, setValue] = useState<MyValue>([
    { children: [{ text: '' }] },
  ] as MyValue);
  const [reload, setReload] = useState(false);
  const editing = useEventEditorId() === 'edit';
  const toolbar =
    typeof props.enableToolbar === 'boolean' ? props.enableToolbar : true;

  useEffect(() => {
    setValue((props.value as MyValue) || [{ children: [{ text: '' }] }]);
    if (props.viewMode) {
      setReload(true);
      setTimeout(() => setReload(false), 200);
    }
    //eslint-disable-next-line
  }, [props]);

  return (
    <>
      {toolbar && (
        <Toolbar>
          <BasicElementToolbarButtons disabled={props.disabled || []} />
        </Toolbar>
      )}
      {!reload && (
        <>
          {/* @ts-ignore plate types issue, props isnt allowed although is possible.*/}
          <Plate<MyValue>
            {...props}
            value={value as MyValue}
            plugins={plugins}
            editableProps={{ readOnly: !editing, contentEditable: editing }}
          />
        </>
      )}
    </>
  );
};

export default RichTextEditor;
