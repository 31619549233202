import { useMutation, useQuery } from '@apollo/client';
import {
  GET_SOCIAL_CONFIG_BY_UUID,
  GET_SOCIAL_CARD_CONFIG_BY_UUID,
  HIDE_AWARD_LEVEL_IN_BANNER,
  HIDE_AWARD_LEVEL_IN_TITLE,
  HIDE_BUSINESS_UNIT,
  HIDE_MILESTONE_AWARD_LEVEL,
  HIDE_RECOGNITION_AWARD_LEVEL,
  SHOW_AWARD_LEVEL_IN_BANNER,
  SHOW_AWARD_LEVEL_IN_TITLE,
  SHOW_BUSINESS_UNIT,
  SHOW_MILESTONE_AWARD_LEVEL,
  SHOW_RECOGNITION_AWARD_LEVEL,
  TURN_OFF_RECOGNITION_MESSAGE,
  TURN_ON_RECOGNITION_MESSAGE,
} from '../../graphql/posts/posts';
import {
  SocialConfigResponse,
  SocialCardConfigResponse,
  UpdateSocialPostsInput,
  UpdateSocialPostsResponse,
} from '../../models/socialWall';
import { GET_CUSTOMER } from '../../../../customer/graphql/customer';

export const useGetSocialCardConfigByUuid = (customerUuid: string) =>
  useQuery<SocialCardConfigResponse, UpdateSocialPostsInput>(
    GET_SOCIAL_CARD_CONFIG_BY_UUID,
    {
      variables: {
        customerUuid,
      },
    }
  );

export const useGetSocialConfigByUuid = (customerUuid: string) =>
  useQuery<SocialConfigResponse, UpdateSocialPostsInput>(
    GET_SOCIAL_CONFIG_BY_UUID,
    {
      variables: {
        customerUuid,
      },
    }
  );

export const useShowRecognitionAwardLevel = (customerUuid: string) =>
  useMutation<UpdateSocialPostsResponse, UpdateSocialPostsInput>(
    SHOW_RECOGNITION_AWARD_LEVEL,
    {
      refetchQueries: [
        {
          query: GET_CUSTOMER,
          variables: { id: customerUuid, customerId: customerUuid },
        },
      ],
    }
  );

export const useHideRecognitionAwardLevel = (customerUuid: string) =>
  useMutation<UpdateSocialPostsResponse, UpdateSocialPostsInput>(
    HIDE_RECOGNITION_AWARD_LEVEL,
    {
      refetchQueries: [
        {
          query: GET_CUSTOMER,
          variables: { id: customerUuid, customerId: customerUuid },
        },
      ],
    }
  );

export const useShowAwardLevelInBanner = (customerUuid: string) =>
  useMutation<UpdateSocialPostsResponse, UpdateSocialPostsInput>(
    SHOW_AWARD_LEVEL_IN_BANNER,
    {
      refetchQueries: [
        {
          query: GET_SOCIAL_CONFIG_BY_UUID,
          variables: {
            customerUuid,
          },
        },
      ],
    }
  );

export const useHideAwardLevelInBanner = (customerUuid: string) =>
  useMutation<UpdateSocialPostsResponse, UpdateSocialPostsInput>(
    HIDE_AWARD_LEVEL_IN_BANNER,
    {
      refetchQueries: [
        {
          query: GET_SOCIAL_CONFIG_BY_UUID,
          variables: {
            customerUuid,
          },
        },
      ],
    }
  );

export const useShowAwardLevelInTitle = (customerUuid: string) =>
  useMutation<UpdateSocialPostsResponse, UpdateSocialPostsInput>(
    SHOW_AWARD_LEVEL_IN_TITLE,
    {
      refetchQueries: [
        {
          query: GET_SOCIAL_CONFIG_BY_UUID,
          variables: {
            customerUuid,
          },
        },
      ],
    }
  );

export const useHideAwardLevelInTitle = (customerUuid: string) =>
  useMutation<UpdateSocialPostsResponse, UpdateSocialPostsInput>(
    HIDE_AWARD_LEVEL_IN_TITLE,
    {
      refetchQueries: [
        {
          query: GET_SOCIAL_CONFIG_BY_UUID,
          variables: {
            customerUuid,
          },
        },
      ],
    }
  );

export const useShowMilestoneAwardLevel = (customerUuid: string) =>
  useMutation<UpdateSocialPostsResponse, UpdateSocialPostsInput>(
    SHOW_MILESTONE_AWARD_LEVEL,
    {
      refetchQueries: [
        {
          query: GET_SOCIAL_CONFIG_BY_UUID,
          variables: {
            customerUuid,
          },
        },
      ],
    }
  );

export const useHideMilestoneAwardLevel = (customerUuid: string) =>
  useMutation<UpdateSocialPostsResponse, UpdateSocialPostsInput>(
    HIDE_MILESTONE_AWARD_LEVEL,
    {
      refetchQueries: [
        {
          query: GET_SOCIAL_CONFIG_BY_UUID,
          variables: {
            customerUuid,
          },
        },
      ],
    }
  );

export const useShowRecognitionMessage = (customerUuid: string) =>
  useMutation<UpdateSocialPostsResponse, UpdateSocialPostsInput>(
    TURN_ON_RECOGNITION_MESSAGE,
    {
      refetchQueries: [
        {
          query: GET_SOCIAL_CONFIG_BY_UUID,
          variables: {
            customerUuid,
          },
        },
      ],
    }
  );

export const useHideRecognitionMessage = (customerUuid: string) =>
  useMutation<UpdateSocialPostsResponse, UpdateSocialPostsInput>(
    TURN_OFF_RECOGNITION_MESSAGE,
    {
      refetchQueries: [
        {
          query: GET_SOCIAL_CONFIG_BY_UUID,
          variables: {
            customerUuid,
          },
        },
      ],
    }
  );

export const useShowBusinessUnit = (customerUuid: string) =>
  useMutation<UpdateSocialPostsResponse, UpdateSocialPostsInput>(
    SHOW_BUSINESS_UNIT,
    {
      refetchQueries: [
        {
          query: GET_CUSTOMER,
          variables: { id: customerUuid, customerId: customerUuid },
        },
      ],
    }
  );

export const useHideBusinessUnit = (customerUuid: string) =>
  useMutation<UpdateSocialPostsResponse, UpdateSocialPostsInput>(
    HIDE_BUSINESS_UNIT,
    {
      refetchQueries: [
        {
          query: GET_CUSTOMER,
          variables: { id: customerUuid, customerId: customerUuid },
        },
      ],
    }
  );
