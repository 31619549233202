import React, { useState , useEffect } from 'react';
import { Typography, Table, TableHead, TableRow, TableBody } from '@octanner/prism-core';
import SortAscending from '@octanner/prism-icons/SortAscending';
import SortDescending from '@octanner/prism-icons/SortDescending';
import { SearchCountry } from './style';
import { EditSalesOrgProps } from './types';
import { useTranslation } from 'react-i18next';
import { countryWithSalesOrg } from './types';
import {
  SaveandContinueFlexContainer,
  SalesOrgEditButton,
  FilterContainer,
  CountryNameTableCell,
  CountryCodeTableCell,
  FlexContainer,
  SortContainer,
  StyledTableContainer
} from './style';
import styled from "@emotion/styled";
import TableCell from "@octanner/prism-core/TableCell";

const SalesOrgCurrency = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const DisplayFlex = styled.div`
  display: flex;
`;

const EditSalesOrgList: React.FC<EditSalesOrgProps> = ({
  setEditSalesOrg,
  showWarning,
  errorsDisplayNumber,
  onSave,
  clientSalesOrg
}) => {

  const { customerCountrySalesOrgMap = [] } = clientSalesOrg?.customerCountrySalesOrgMapsByCustomer || {};

  const [clientSalesOrgList, setClientSalesOrgList] = useState([]);
  const [isAscending, setIsAscending] = useState(true);
  const [searchText, setSearchText] = useState('');
  const { t } = useTranslation();
  const [countrySalesOrgList, setCountrySalesOrgList] = useState<any>({});

  useEffect(() => {
    showWarning ? onSave({...errorsDisplayNumber, CountryAndSalesOrgSetup: 1}) : onSave({...errorsDisplayNumber, CountryAndSalesOrgSetup: 0})
  }, [showWarning]);

  useEffect(() => {
    const clientSalesOrgSortedList = customerCountrySalesOrgMap.slice().sort((a, b) => a?.coreCountry?.countryName < b?.coreCountry?.countryName ? -1 : 1);
    setClientSalesOrgList(
        clientSalesOrgSortedList
    );
    handleSalesOrgsAndCurrencies(clientSalesOrgSortedList);
  }, [customerCountrySalesOrgMap])

  const handelEditCountryList = () => {
    setEditSalesOrg(true);
  }
  const handleSorting = () => {
    setIsAscending(!isAscending);
    const sortedList = [...clientSalesOrgList.reverse()];
    setClientSalesOrgList(sortedList);
    handleSalesOrgsAndCurrencies(sortedList);
  }

  const handleSalesOrgsAndCurrencies = (clientSalesOrgList) => {
    const countrySalesOrgList = [];
    clientSalesOrgList.map((item) => {
      const newValues = [item];
      if (item?.coreCountry?.iso2Code in countrySalesOrgList) {
        countrySalesOrgList[item?.coreCountry?.iso2Code].push(...newValues);
      } else {
        countrySalesOrgList[item?.coreCountry?.iso2Code] = newValues;
      }
    });
    setCountrySalesOrgList(countrySalesOrgList);
  };

  // TODO: use table component instead of having custom component
  return (
      <>
      <FilterContainer>
          <Typography variant='h3' gutterBottom>{t('country_sales_org_setup')}</Typography>
      </FilterContainer>
      <SearchCountry
          id="searchCountry"
          onInputChange={(e: any) => setSearchText(e?.target?.value || '')}
          placeholder={t('search_countries')}
          style={{ margin : '16px 0px'}}
          size="small"
          multiple={false}
          getOptionLabel={() => searchText}
        />
        <>
          <StyledTableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <CountryNameTableCell>
                    <FlexContainer>
                    <Typography variant='h4'>{t('country')}</Typography>
                    <SortContainer onClick={() => handleSorting()}>
                      {isAscending ? <SortAscending /> : <SortDescending />}
                    </SortContainer>
                  </FlexContainer>
                  </CountryNameTableCell>
                  <CountryCodeTableCell><Typography variant='h4'>{t('code')}</Typography></CountryCodeTableCell>
                  <TableCell><Typography variant='h4'>{t('sales_org')}</Typography></TableCell>
                </TableRow>
              </TableHead>
            <TableBody>
              {Object.entries(countrySalesOrgList).map(([key, value]: [string, countryWithSalesOrg[]]) => {
                const item:any = value.filter((item) =>
                    item?.coreCountry?.countryName?.toLowerCase()
                        .includes(searchText?.toLowerCase())
                );
                const coreCountry = item.find(item => item.coreCountry)?.coreCountry;
                return (
                  <TableRow style={{ height: '8px' }}>
                    <CountryNameTableCell>
                      <FlexContainer>
                        <Typography style={{ paddingTop: '8px' }}>
                          {coreCountry?.countryName}
                        </Typography>
                      </FlexContainer>
                    </CountryNameTableCell>

                    <CountryCodeTableCell>
                      <Typography>
                        {coreCountry?.iso2Code}
                      </Typography>

                    </CountryCodeTableCell>

                    <TableCell>
                      <DisplayFlex>
                        <SalesOrgCurrency>
                          {item.map((salesOrgItem) => {
                            return (
                                <>
                                  <DisplayFlex>
                                    <div>
                                      <Typography>
                                        {`${salesOrgItem?.assignedSalesOrg?.salesOrgCode} - ${salesOrgItem?.assignedSalesOrg?.salesOrgName}`}
                                      </Typography>
                                      <Typography color="textSecondary" variant="body2">
                                        {`${t('currency')} - ${salesOrgItem?.assignedSalesOrg?.currency?.currencyCode}`}
                                      </Typography>
                                    </div>
                                  </DisplayFlex>
                                </>


                            );
                          })}
                        </SalesOrgCurrency>
                      </DisplayFlex>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
            </Table>
          </StyledTableContainer>
        </>
        <SaveandContinueFlexContainer>
          <SalesOrgEditButton
            variant="contained"
            color="secondary"
            onClick={() =>  handelEditCountryList()}
          >
            {t('edit_country_list')}
          </SalesOrgEditButton>
        </SaveandContinueFlexContainer>
      </>
    );
};

export default EditSalesOrgList;
