import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client';
import { Identity } from '@octanner/prism-core/Header/interfaces';
import { useDebouncedValue } from '@octanner/prism-core/hooks';
import { useSetError } from './SetErrorProvider';

export const IDENTITY_SEARCH_QUERY = gql`
  query identitySearch($searchText: String!, $customerId: String) {
    identitySearch(
      condition: {
        active: true
        customerId: $customerId
        name: $searchText
        email: $searchText
        searchCondition: "or"
        orderBy: "firstName, lastName, id"
        size: 50
      }
    ) {
      identities {
        id: identityUuid
        firstName
        lastName
        email
        preferredName
        managerId
        profile {
          identityUUID
          avatar
        }
        businessUnit {
          buName
        }
        manager {
          preferredName
          firstName
          lastName
        }
      }
    }
  }
`;

interface QueryResult {
  identitySearch: { identities: Identity[] };
}

interface UseIdentitySearchResult {
  loading: boolean;
  data: QueryResult | undefined;
}

const useIdentitySearch = (
  searchText: string,
  customerId: string,
): UseIdentitySearchResult => {
  const setError = useSetError();
  const debouncedSearchText = useDebouncedValue(searchText);
  const { loading, data } = useQuery<QueryResult>(IDENTITY_SEARCH_QUERY, {
    variables: { searchText: debouncedSearchText, customerId },
    skip: !debouncedSearchText || debouncedSearchText.length < 3,
    onError: setError,
  });

  return {
    loading,
    data,
  };
};

export default useIdentitySearch;
