import styled from '@emotion/styled';
import {
  Button,
  Tab,
  Typography,
  Paper,
  Select,
  Alert,
} from '@octanner/prism-core';
import { tannerGray } from '@octanner/prism-core/ThemeProvider/colors';

export const AddProgramTitle = styled(Typography)`
  margin-top: 16px;
  margin-bottom: 16px;
`;

export const FlexColumnDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MLButton = styled(Button)`
  margin-left: 8px;
`;

export const ColoredTabs = styled(Tab)`
  color: #000 !important;
`;

export const ColoredTabProduct = styled(Tab)`
  color: #c4c4c4 !important;
`;

export const SolidLineAcross = styled.div`
  border-bottom: 1.2px solid ${tannerGray[200]};
`;

export const PendingChangesSeparator = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
  border-bottom: 1px solid ${tannerGray[200]};
`;

export const SaveBar = styled.div`
  margin-left: 16px;
  padding-top: 16px;
  padding-bottom: 16px;
`;

export const SubmitButton = styled(Button)`
  margin-right: 8px;
`;

export const BottomBar = styled(Paper)`
  margin-top: calc(10% + 60px);
  position: fixed;
  bottom: 0;
  border: 0;
  width: 100%;
  box-shadow: 0 0px 5px rgba(0, 0, 0, 0.1);
  z-index: 100;
`;

export const SelectTimeZoneWidth = styled(Select)`
  width: 364px;
`;

export const MBAlert = styled(Alert)`
  margin-bottom: 8px;
`;

export const ErrorTypography = styled(Typography)`
  color: #d10a12;
  margin-left: 16px;
`;

export const MB32Div = styled.div`
  margin-bottom: 32px;
`;

export const MT16Div = styled.div`
  margin-top: 16px;
`;

export const FlexGap48 = styled.div`
  display: flex;
  gap: 48px;
`;

export const SubtitleTypo = styled(Typography)`
  color: #707070;
  font-size: 12px;
`;

export const ErrorChip = styled.div`
  background-color: #d70e16;
  color: white;
  width: 14px;
  height: 14px;
  font-weight: bold;
  margin-left: 8px;
`;

export const MBWrapper = styled.div`
  margin-bottom: 10px;
`;

export const ProgramNameAndActivateContainer = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const DeactivateModalSubTitle = styled(Typography)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 12px;
  width: 98%;
  text-align: center;
`;

export const BYPSubTitle = styled(Typography)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 12px;
`;

export const ProgramIDSubTitle = styled(Typography)`
  color: #707070;
  font-size: 12px;
  margin-bottom: 4px;
`;

export const SaveAndCancelFlexContainer = styled.div`
  display: flex;
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  z-index: 7;
  left: 256px;
  gap: 1px;
  margin-left: 24px;
  padding-bottom: 16px;
  padding-top: 16px;
`;
