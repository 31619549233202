import { useStyles } from './styles';
import { Link, ListItem, ListItemText } from '@octanner/prism-core';
import { Link as RouterLink, useRouteMatch } from 'react-router-dom';
import ProtectedFeature from '../../common/ProtectedFeature';
import permissionAuthorizer from '../../utils/auth/PermissionAuthorizer';
import { RoleItemProps } from './types';

export default function RoleItem(props: RoleItemProps) {
  const { role, onDuplicate, onDelete } = props;
  const match = useRouteMatch();
  const { listItem, deleteRole, clickable, action } = useStyles();

  return (
    <ListItem className={listItem} data-test={`role:item:${role.uuid}`}>
      <ListItemText primary={role.name} data-test={`role:text:${role.uuid}`} />
      <ProtectedFeature
        authorizer={permissionAuthorizer('ROLE_DELEGATOR_ROLES_EDIT')}
      >
        <Link
          component={RouterLink}
          className={action}
          // @ts-ignore prism issue
          to={`${match.url}/${role.id}`}
          data-test={`role:edit:${role.uuid}`}
        >
          Edit
        </Link>
      </ProtectedFeature>
      <ProtectedFeature
        authorizer={permissionAuthorizer('ROLE_DELEGATOR_ROLES_CREATE')}
      >
        <Link
          className={[action, clickable].join(' ')}
          onClick={() => onDuplicate()}
          data-test={`role:duplicate:${role.uuid}`}
        >
          Duplicate
        </Link>
      </ProtectedFeature>
      <ProtectedFeature
        authorizer={permissionAuthorizer('ROLE_DELEGATOR_ROLES_DELETE')}
      >
        <Link
          className={[action, clickable, deleteRole].join(' ')}
          onClick={() => onDelete()}
          data-test={`role:delete:${role.uuid}`}
        >
          Delete
        </Link>
      </ProtectedFeature>
    </ListItem>
  );
}
