import React from 'react'
import { styled } from '@mui/material/styles'
import { Typography } from '@octanner/prism-core'

interface RadioLabelProps {
  text: string
  htmlFor: string
  className?: string
}

const Label = styled('label')`
  margin-bottom: ${({ theme }) => theme.spacing(3)};
`

const RadioLabel = React.forwardRef<HTMLLabelElement, RadioLabelProps>(
  ({ text, className, htmlFor }, ref) => (
    <Label htmlFor={htmlFor} className={className} ref={ref}>
      <Typography variant="h5">{text}</Typography>
    </Label>
  )
)
RadioLabel.displayName = 'RadioLabel'

export default RadioLabel
