import { DrawerProps } from './types';
import clsx from 'clsx';
import { useStyles } from './styles';
import { Drawer } from '@octanner/prism-core';

// ? Drawer Content Wrapper allows for drawer to resize and scale any components passed in, and allowing for a customizable drawer from MUI.
// ? For now, styles will handle the push over, might change to floating, in which case this becomes very simplified after. For now, keep as is.
const DrawerContentWrapper = ({
  open,
  back,
  handleBack,
  children,
  drawerComponents,
}: DrawerProps) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        PaperProps={{
          style: {
            overflow: 'hidden',
          },
        }}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        {/* drawerComponents is what you want the children inside the drawer to be */}
        {drawerComponents()}
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        {/* children is the main content of the app */}
        {children}
      </main>
    </div>
  );
};

export default DrawerContentWrapper;
