export  const clientTypes = [
    {
      name: 'Real',
      value: 'real',
    },
    {
      name: 'Test',
      value: 'test',
    },
    {
      name: 'Demo',
      value: 'demo',
    }
  ];

  enum CustomColor {
    Warning = 'warning',
    Success = 'success',
    Archived = 'archived',
    Info = 'info',
  }

  export const CLIENT_STATUS = [
    {
      key: 'active',
      name: 'Active',
      variant: CustomColor.Success,
    },
    {
      key: 'impl',
      name: 'Implementation',
      variant: CustomColor.Info, 
    },
    {
      key: 'sold',
      name: 'Sold',
      variant: CustomColor.Warning, 
    },
    {
      key: 'lost',
      name: 'Lost',
      variant: CustomColor.Archived, 
    },
  ];

  export enum CustomerStatus {
    SOLD = 'sold',
    LOST = 'lost',
    ACTIVE = 'active',
    IMPLEMENTATION = 'impl',
  }
  
