import { defaults } from './default'

export const constraints = [
  { label: "Contains", value: "CONTAINS" },
  { label: "Is Exactly", value: "EXACT" },
  { label: "Begins With", value: "BEGINS" },
  { label: "Ends With", value: "ENDS" },
];

export const numRegex = /^[0-9]*$/;

export const descriptions = [{ label: "Short Description", value: "SHORT" },
{ label: "Web-Short Description", value: "WEB_SHORT" },
{ label: "Medium Description", value: "MEDIUM" },
{ label: "Long Description", value: "LONG" },
{ label: "Detail Description", value: "DETAIL" }];

export const TagVariant = {
  UNTRANSLATED: {
    text: 'Not translated',
    variant: 'archived',
  },
  TRANSLATED: {
    text: 'Translated',
    variant: 'success',
  },
  REQUESTED: {
    text: 'Requested',
    variant: 'info',
  },
  IN_PROGRESS: {
    text: 'In progress',
    variant: 'warning',
  },
  HOLD: {
    text: 'On hold',
    variant: 'warning'
  }
}

export const MmdCharLimitEnglishLocale = {
  SHORT: {
    text: 'Short(30 chars)',
    limit: 30
  },
  WEB_SHORT: {
    text: 'Web-Short(100 chars)',
    limit: 100
  },
  MEDIUM: {
    text: 'Medium(190 chars)',
    limit: 190
  },
  LONG: {
    text: 'Long(2000 chars)',
    limit: 2000
  },
  DETAIL: {
    text: 'Details'
  }
}

export const MmdCharLimitForeignLocales = {
  SHORT: {
    text: 'Short(40 chars)',
    limit: 40
  },
  WEB_SHORT: {
    text: 'Web-Short(135 chars)',
    limit: 135
  },
  MEDIUM: {
    text: 'Medium(255 chars)',
    limit: 255
  },
  LONG: {
    text: 'Long(2700 chars)',
    limit: 2700
  },
  DETAIL: {
    text: 'Details'
  }
}

export const emailregex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const durationTypes = [
  { label: "Today", value: "TODAY" },
  { label: "One Week", value: "ONE_WEEK" },
  { label: "Two Weeks", value: "TWO_WEEKS" },
  { label: "One Month", value: "ONE_MONTH" },
  { label: "Three Months", value: "THREE_MONTHS" },
  { label: "Six Months", value: "SIX_MONTHS" },
  { label: "One Year", value: "ONE_YEAR" }
];

export const backLinks = {
  add: {
    title: defaults.add_string,
    path: '/language-string/add'
  },
  jobManagement: {
    title: defaults.job_details,
    path: '/job-management'
  },
  edit: {
    title: defaults.add_custom_text,
    path: '/custom-text/add'
  }
}
export const translationCategories = {
  LANGUAGE_STRING: {
    value: 'LANGUAGE_STRING',
    label: 'Language String',
    countVariableName: 'languageStringCount'
  },
  MATERIAL_DESCRIPTION: {
    value: 'MATERIAL_DESCRIPTION',
    label: 'Material Master Description',
    countVariableName: 'materialDescriptionCount'
  },
  CUSTOM_TEXT: {
    value: 'CUSTOM_TEXT',
    label: 'Custom Text',
    countVariableName: 'customTextCount'
  }
}

export const jobsDurationType = [
  {
    label: 'Today',
    value: 'TODAY'
  },
  {
    label: 'Last One Week',
    value: 'ONE_WEEK'
  },
  {
    label: 'Last Two Weeks',
    value: 'TWO_WEEKS'
  },
  {
    label: 'Last Month',
    value: 'ONE_MONTH'
  },
  {
    label: 'Last Quarter',
    value: 'THREE_MONTHS'
  },
  {
    label: 'Last Six Months',
    value: 'SIX_MONTHS'
  },
  {
    label: 'Last Year',
    value: 'ONE_YEAR'
  },
];

export const sendForTranslationFields = {
  recipientEmail: 'OCTanner@medialocate.com',
  subject: 'OC Tanner Translation Request #{jobNumber} (to be translated in {locale})',
  message: 'Attached to this email, you will find the Excel file named "{fileName}" which contains the strings in their original language. We kindly request your support in providing the corresponding {locale} translations in a new column or a separate document. The quality and accuracy of the translations are of the utmost importance to us, as they will be used in our communication materials.',
  priority: 'PRIORITY!'
}

export const translationStatusTabs = {
  PENDING: {
    value: 'PENDING',
    label: 'Pending'
  },
  IN_PROGRESS: {
    value: 'IN_PROGRESS',
    label: 'In Progress'
  },
  COMPLETED: {
    value: 'COMPLETED',
    label: 'Completed'
  }
}

export enum ActionType {
  VIEW = 'view',
  DELETE = 'delete',
  UPLOAD = 'upload',
  SEND = 'send'
}

export enum BulkUploadType {
  CREATE = 'create',
  REQUEST = 'request'
}

export enum AddCustomTextBy {
  CUSTOM_TEXT = 'customText',
  BULK_UPLOAD = 'bulkUpload'
}
