import { IdentityPresenter } from '../types';
import { BusinessUnit } from '@octanner/prism-core/Header/interfaces';
export interface AdjudicationConfiguration {
  id: string;
  name: string;
  adjudicationRounds: Round[];
}

export interface DefinedApproverBusinessUnit extends BusinessUnit {
  buUuid: string;
}

export interface DefinedApproversForBusinessUnits {
  definedApproverUuid: string;
  approver?: IdentityPresenter;
  businessUnit?: DefinedApproverBusinessUnit;
}

export interface SubmitDefinedApprovers {
  approverIdentityUuid: string;
  businessUnitUuid?: string;
}

export interface DefinedApprovers {
  defaultDefinedApprover?: {
    definedApproverUuid?: string;
    approver?: IdentityPresenter;
  };
  definedApproversForBusinessUnits?: DefinedApproversForBusinessUnits[];
}

export interface Round {
  adjudicationType: RoundTypes;
  screener: { screener: Screeners }; // SCREENING only
  commentOnlyRound: boolean; // SCREENING only
  definedApprovers?: DefinedApprovers;
  advancement: {
    type: AdvancementTypes.MANUAL;
  };
}

export enum RoundTypes {
  SCREENING = 'SCREENING',
}

export enum Screeners {
  CANDIDATE_MANAGER = 'CANDIDATE_MANAGER',
  DEFINED_APPROVER = 'DEFINED_APPROVER',
  NOMINATOR_MANAGER = 'NOMINATOR_MANAGER',
}

export enum AdvancementTypes {
  MANUAL = 'MANUAL',
}
