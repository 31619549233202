import { CelebrationLevelConfigBase } from '../../common/models/CelebrationLevelConfig'
import { TextFormValue } from '../CelebrationGroupEdit/models'

export type LevelFormItem = CelebrationLevelConfigBase & TextFormValue

export interface CountryFormInput {
  iso2Code: string
  countryName: string
  regionName?: string
  checked: boolean
}

export const validateLevelForm = (item: LevelFormItem): LevelFormItem => ({
    ...item,
    errors: parseInt(item.value)
      ? undefined
      : ['Point must be greater than 0.'],
  }),
  isValidCountry = (countryInput: CountryFormInput): boolean =>
    countryInput.checked && !countryInput.regionName

export const groupNameErrorMessage = 'Group name is required.',
  countryErrorMessage = 'Country selection is required.',
  pointsErrorMessage = 'Points are required.'
