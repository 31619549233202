import {
  createPlateUI,
  ELEMENT_TABLE,
  ELEMENT_TD,
  StyledElement,
  withProps,
} from '@udecode/plate';

export const plateUI = createPlateUI({
  [ELEMENT_TABLE]: withProps(StyledElement, {
    as: 'table',
    styles: {
      root: {
        border: '2px dotted #ccc',
        width: '100%',
        tableLayout: 'fixed',
      },
    },
  }),
  [ELEMENT_TD]: withProps(StyledElement, {
    as: 'td',
    styles: {
      root: {
        verticalAlign: 'top',
        border: '2px dotted #ccc',
      },
    },
  }),
});
