import styled from '@emotion/styled';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@octanner/prism-core';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { MaterialStringsContext } from '../../Contexts/MaterialStringsContext';
import { descriptions } from '../../utils/constants';
import { defaults } from '../../utils/default';
import { SearchMaterialDescriptionStringsTableProps } from '../../utils/types';

const StringIdCell = styled(TableCell)`
  width: 15%;
`;

const ActionsCell = styled(TableCell)`
  text-align: left;
  width: 10%;
`;

const StringTextTypography = styled(Typography)`
  white-space: pre;
`;

const SearchMaterialDescriptionStringsTable: React.FC<SearchMaterialDescriptionStringsTableProps> = ({ descriptionType }) => {

  const history = useHistory();
  const { searchData } = useContext(MaterialStringsContext);

  const handleEdit = (mmId: number) => {
    history.push('/material-description/edit/' + mmId);
  };

  return (
    <Table>
      <TableHead>
        <TableRow>
          <StringIdCell>
            <Typography variant='h4'>{defaults.mmid}</Typography>
          </StringIdCell>
          <TableCell>
            <Typography variant='h4'>{descriptions.find(desc => desc.value === descriptionType) ? descriptions.find(desc => desc.value === descriptionType).label
              : defaults.description}</Typography>
          </TableCell>
          <ActionsCell>
            <Typography variant='h4'>{defaults.actions}</Typography>
          </ActionsCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {searchData.lsFetchMaterialDescriptionListUsingSearchFilter.materialDescriptionList
          .map(({ mmId, descriptionList }) => (
            <TableRow key={mmId}>
              <StringIdCell>
                <Typography>{mmId}</Typography>
              </StringIdCell>
              <TableCell>
              {descriptionList?.length > 0 && <StringTextTypography>{descriptionList[0].text}</StringTextTypography>}
              </TableCell>
              <ActionsCell>
                <Button variant='text' onClick={() => handleEdit(mmId)}>
                  {defaults.edit}
                </Button>
              </ActionsCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
}

export default SearchMaterialDescriptionStringsTable;