export const formatDate = (dateString: string, locale: string = 'en-US') => {
  const date = new Date(dateString);
  const day = new Intl.DateTimeFormat(locale, { day: 'numeric' }).format(date);
  const month = new Intl.DateTimeFormat(locale, { month: 'short' }).format(
    date
  );
  const year = new Intl.DateTimeFormat(locale, { year: 'numeric' }).format(
    date
  );
  return `${day} ${month} ${year}`;
};

// sorts by most recent first
export const compareDates = (date1String?: string, date2String?: string) => {
  if (!date1String && !date2String) {
    return 0;
  }
  if (!date1String) {
    return -1;
  }
  if (!date2String) {
    return 1;
  }
  const date1 = new Date(date1String);
  const date2 = new Date(date2String);
  return date2.getTime() - date1.getTime();
};
