import { Box, Card, CardContent, CardHeader, Tag } from '@octanner/prism-core';
import Typography from '@octanner/prism-core/Typography';
import { tannerGray } from '@octanner/prism-core/ThemeProvider/colors';
import { CustomerWithAdminRoles } from '../types';
import { statusVariantMap } from '../constants';

const ClientInfoField = ({
  fieldName,
  fieldValue,
}: {
  fieldName: string;
  fieldValue: string;
}) => {
  return (
    <Box sx={{ flex: '1' }}>
      <Typography
        variant="body2"
        component="span"
        sx={{ color: tannerGray[700] }}
      >{`${fieldName} : `}</Typography>
      <Typography component="span">{fieldValue}</Typography>
    </Box>
  );
};

const ClientCard = ({ customer }: { customer: CustomerWithAdminRoles }) => {
  return (
    <Card
      sx={{
        mt: 4,
        width: 540,
      }}
    >
      <CardHeader title={customer.name} />
      <CardContent sx={{ paddingTop: 1 }}>
        <ClientInfoField fieldName="STP" fieldValue={customer.stpNumber} />
        <Box sx={{ display: 'flex' }}>
          <ClientInfoField
            fieldName="Client Core ID"
            fieldValue={customer.customerUuid}
          />
          <Tag
            variant={
              statusVariantMap[customer.customerStatus].variant || 'error'
            }
          >
            {customer.customerStatus}
          </Tag>
        </Box>
      </CardContent>
    </Card>
  );
};

export default ClientCard;
