import {
  Button,
  DatePicker,
  MenuItem,
  Modal,
  TextField,
} from '@octanner/prism-core';
import {
  DeactivateModalSubTitle,
  ErrorTypography,
  FlexColumnDiv,
  MLButton,
  ProgramNameAndActivateContainer,
  SelectTimeZoneWidth,
} from '../../styles';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DivFlexG16MT16, SubtitleTypo } from '../programDetails/styles';
import { GeneralSettingsProps } from '../../types';
import { useGetTimeZones } from '../../../../../graphql/timezones/hooks';
import { CountryTimeZone } from '../../../../../graphql/timezones/models';
import { DateTime } from 'luxon';
import { useActivateProgram, useDeactivateProgram } from '../../graphql';
import { useEffect, useState } from 'react';

const GeneralSettings = ({
  type,
  programName,
  hasProgramName,
  programStartDate,
  programEndDate,
  handleProgramSettingChange,
  startDateError,
  endDateError,
  customerUuid = '',
  programId = 0,
  handleSave,
  programTimeZone,
}: GeneralSettingsProps) => {
  const { timeZones, loading } = useGetTimeZones();
  const defaultTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [activateProgram] = useActivateProgram();
  const [deactivateProgram] = useDeactivateProgram();
  const [activation, setActivation] = useState<boolean>(false);
  const [modalVisible, setModalVisible] = useState(false);

  const dateConverter = (date: string) =>
    DateTime.fromISO(date).toISODate() || '';
  const isValidProgramTimeZone = timeZones.some(
    (timezone) => timezone.timeZoneName === programTimeZone
  );
  const timeZonevalue = isValidProgramTimeZone ? programTimeZone : '';

  useEffect(() => {
    if (
      programEndDate &&
      dateConverter(programEndDate as unknown as string) <=
        (DateTime.now().toISODate() || '')
    ) {
      setActivation(true);
    } else if (
      programStartDate &&
      dateConverter(programStartDate as unknown as string) >
        (DateTime.now().toISODate() || '')
    ) {
      setActivation(true);
    } else {
      setActivation(false);
    }
    // eslint-disable-next-line
  }, [programStartDate, programEndDate]);

  const flipActivation = () => {
    setActivation(!activation);
  };

  const handleDeactivateProgram = () => {
    setModalVisible(true);
  };

  const handleModalClose = () => {
    setModalVisible(false);
  };

  const handleDeactivateConfirm = () => {
    deactivateProgram({
      variables: { input: { programId, customerUuid } },
    }).then(({ data }) => {
      if (data) {
        const { startDate, endDate } = data.deactivateCoreProgramV2;
        handleProgramSettingChange(
          'startDate',
          DateTime.fromISO(startDate as string).toISODate(),
          false
        );
        handleProgramSettingChange(
          'endDate',
          DateTime.fromISO(endDate as string).toISODate(),
          false
        );
      }
    });
    flipActivation();
    setModalVisible(false);
  };

  return (
    <FlexColumnDiv>
      {modalVisible && (
        <Modal
          actions={
            <>
              <Button onClick={handleDeactivateConfirm}>
                Deactivate program
              </Button>
              <Button onClick={handleModalClose} color="secondary">
                Cancel
              </Button>
            </>
          }
          id="collapse-Modal"
          onClose={handleModalClose}
          open={modalVisible}
          title="Deactivate this program?"
        >
          <DeactivateModalSubTitle>
            This program will end this program and remove it from the client.
            Are you sure you would like to continue?
          </DeactivateModalSubTitle>
        </Modal>
      )}
      <ProgramNameAndActivateContainer>
        <div>
          <TextField
            style={{ width: programName.length < 55 ? `350px` : '500px' }}
            maxRows={4}
            multiline={programName.length > 67}
            maxLength={100}
            displayCount={programName.length > 100}
            id="filled-basic"
            label="Program name"
            helperText={programName.length > 100 ? 'Too many characters.' : ''}
            error={!hasProgramName}
            value={programName}
            onChange={(e) =>
              handleProgramSettingChange('programName', e.target.value)
            }
          />
          {!hasProgramName && programName.length === 0 && (
            <ErrorTypography>This is a required field.</ErrorTypography>
          )}
        </div>

        {type === 'add' ? (
          <MLButton adminColor onClick={handleSave}>
            Activate program
          </MLButton>
        ) : (
          <>
            <MLButton
              adminColor={activation}
              color={activation ? 'primary' : 'secondary'}
              onClick={() => {
                activation
                  ? activateProgram({
                      variables: { input: { programId, customerUuid } },
                    }).then(({ data }) => {
                      if (data) {
                        const { startDate } = data.activateCoreProgramV2;
                        handleProgramSettingChange(
                          'startDate',
                          DateTime.fromISO(startDate as string).toISODate(),
                          false
                        );
                      }
                    })
                  : handleDeactivateProgram();
              }}
            >
              {activation ? 'Activate ' : 'Deactivate '}
              program
            </MLButton>
          </>
        )}
      </ProgramNameAndActivateContainer>
      <DivFlexG16MT16>
        <div>
          <DatePicker
            disableMaskedInput
            label="Start Date"
            value={programStartDate}
            dateAdapter={AdapterLuxon}
            onChange={(date) => handleProgramSettingChange('startDate', date)}
          />
          <SubtitleTypo style={{ color: startDateError ? '#D10A12' : '' }}>
            {startDateError ? 'Start date cannot be in the past.' : 'Optional'}
          </SubtitleTypo>
        </div>
        <div>
          <DatePicker
            disableMaskedInput
            label="End Date"
            value={programEndDate}
            dateAdapter={AdapterLuxon}
            onChange={(date) => handleProgramSettingChange('endDate', date)}
          />
          <SubtitleTypo style={{ color: endDateError ? '#D10A12' : '' }}>
            {endDateError ? 'End date cannot be in the past' : 'Optional'}
          </SubtitleTypo>
        </div>
        <SelectTimeZoneWidth
          label="Time zone"
          defaultValue={defaultTimezone}
          value={timeZonevalue}
          onChange={(e) =>
            handleProgramSettingChange('displayTz', e.target.value as string)
          }
        >
          {!loading &&
            timeZones.map(({ id, timeZoneName }: CountryTimeZone) => (
              <MenuItem key={id} value={timeZoneName}>
                {timeZoneName}
              </MenuItem>
            ))}
        </SelectTimeZoneWidth>
      </DivFlexG16MT16>
    </FlexColumnDiv>
  );
};

export default GeneralSettings;
