import { MenuItems } from './types';
import xcaAuthorizer from '../utils/auth/XcaAuthorizer';
import permissionAuthorizer from '../utils/auth/PermissionAuthorizer';
import { useContext, useMemo } from 'react';
import { AppContext } from '../utils/context/AppContext';
import { CustomerContext } from '../utils/context/CustomerContext';

export const useCustomerMenuItems = () => {
  const context = useContext(AppContext);
  const { customer } = useContext(CustomerContext);
  const { config } = context;
  const { CORE_ADMIN_URL } = config;
  const menuItems: MenuItems = useMemo(
    () => [
      {
        translationId: 'client-summary',
        text: 'Client Summary',
        to: '/client-summary',
        dataTest: 'clientSummary',
        authorizer: xcaAuthorizer,
      },
      {
        translationId: 'client-setup',
        text: 'Client Setup',
        dataTest: 'customerSetup',
        authorizer: xcaAuthorizer,
        children: [
          {
            translationId: 'client-configuration',
            text: 'Client Configuration',
            to: '/client-config',
            dataTest: 'clientConfiguration',
            authorizer: xcaAuthorizer,
          },

          {
            translationId: 'banks',
            text: 'Banks',
            to: '/banks',
            dataTest: 'banks',
            authorizer: permissionAuthorizer(
              'CORE_PTS_MGMT_GET_BANK_INFORMATION'
            ),
          },
          {
            translationId: 'data-management',
            text: 'Data Management',
            href: `${CORE_ADMIN_URL}/#/customer/${customer.id}`,
            dataTest: 'dataManagement',
            authorizer: xcaAuthorizer,
            target: '_blank',
          },
        ],
      },
      {
        translationId: 'store',
        text: 'Store',
        dataTest: 'client-store',
        authorizer: xcaAuthorizer,
        children: [
          {
            translationId: 'store-experience',
            text: 'Store Experience',
            to: '/store-experience',
            dataTest: 'storeExperience',
            authorizer: xcaAuthorizer,
          },
        ],
      },
      {
        translationId: 'employee-management',
        text: 'Employee Management',
        dataTest: 'employeeManagement',
        authorizer: xcaAuthorizer,
        children: [
          {
            translationId: 'roles-and-permissions',
            text: 'Roles and Permissions',
            to: '/roles-and-permissions',
            dataTest: 'rolesAndPermissions',
            authorizer: xcaAuthorizer,
          },
        ],
      },
      {
        translationId: 'programs',
        text: 'Programs',
        to: '/programs',
        dataTest: 'programs',
        authorizer: xcaAuthorizer,
      },
      {
        translationId: 'themes',
        text: 'Themes',
        to: '/themes',
        dataTest: 'themes',
        authorizer: permissionAuthorizer([
          'CORE_VIBES_READ',
          'CORE_VIBES_WRITE',
        ]),
      },
      {
        translationId: 'system-settings',
        text: 'System Settings',
        to: '/system-settings',
        dataTest: 'systemSettings',
        authorizer: xcaAuthorizer,
      },
    ],
    [CORE_ADMIN_URL, customer]
  );

  return menuItems;
};
