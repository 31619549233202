import React from 'react';
import { Skeleton } from '@octanner/prism-core';

const LanguagesLoaderIcon = () => (
    <>
      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((id) => (
        <Skeleton key={`table_skeleton_${id}`} style={{ marginTop: '10px' }} width={'100%'} height={40} animation="wave" />
      ))}
    </>
);

export default LanguagesLoaderIcon;
