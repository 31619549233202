import { gql, useMutation, useQuery } from '@apollo/client';
import { GET_CUSTOMER } from '../../../customer/graphql/customer';
import {
  GetAvatarResponse,
  SocialConfigTabInput,
  SocialConfigTabResponse,
  GetPreferredPronounsResponse,
  EmailEditResponse,
  EmailEditInput,
} from '../types';

const GET_AVATAR_UPLOAD = gql`
  query CcWebAvatarUpload($customerUuid: UUID!) {
    ccWebAvatarUpload(input: { customerUuid: $customerUuid }) {
      enabled
    }
  }
`;

const DISABLE_AVATAR_UPLOAD = gql`
  mutation CcWebAvatarUploadDisable($customerUuid: UUID!) {
    ccWebAvatarUploadDisable(input: { customerUuid: $customerUuid }) {
      enabled
    }
  }
`;

const ENABLE_AVATAR_UPLOAD = gql`
  mutation CcWebAvatarUploadEnable($customerUuid: UUID!) {
    ccWebAvatarUploadEnable(input: { customerUuid: $customerUuid }) {
      enabled
    }
  }
`;

const HIDE_FOLLOW_LIST = gql`
  mutation CcWebHideFollowListInPublicProfile($customerUuid: UUID!) {
    ccWebHideFollowListInPublicProfile(customerUUID: $customerUuid) {
      visible
    }
  }
`;

const SHOW_FOLLOW_LIST = gql`
  mutation CcWebShowFollowListInPublicProfile($customerUuid: UUID!) {
    ccWebShowFollowListInPublicProfile(customerUUID: $customerUuid) {
      visible
    }
  }
`;

const GET_STRENGTHS_TAB = gql`
  query CcWebRecognizedStrengths($customerUuid: UUID!) {
    ccWebRecognizedStrengths(customerUuid: $customerUuid) {
      visibility
      enabled
    }
  }
`;

const HIDE_STRENGTHS_TAB = gql`
  mutation CcWebHideRecognizedStrengths($customerUuid: UUID!) {
    ccWebHideRecognizedStrengths(customerUuid: $customerUuid) {
      enabled
    }
  }
`;

const SHOW_STRENGTHS_TAB = gql`
  mutation CcWebShowRecognizedStrengths($customerUuid: UUID!) {
    ccWebShowRecognizedStrengths(customerUuid: $customerUuid) {
      enabled
    }
  }
`;

const SHOW_PRIVATE_STRENGTHS = gql`
  mutation CcWebPrivateStrengths($customerUuid: UUID!) {
    ccWebPrivateStrengths(customerUuid: $customerUuid) {
      visibility
    }
  }
`;

const SHOW_PUBLIC_STRENGTHS = gql`
  mutation CcWebPublicStrengths($customerUuid: UUID!) {
    ccWebPublicStrengths(customerUuid: $customerUuid) {
      visibility
    }
  }
`;

const ENABLE_PREFERRED_PRONOUNS = gql`
  mutation CcWebEnabledDisplayedPronouns($customerUuid: UUID!) {
    ccWebEnabledDisplayedPronouns(input: { customerUuid: $customerUuid }) {
      enabled
    }
  }
`;

const DISABLE_PREFERRED_PRONOUNS = gql`
  mutation CcWebDisabledDisplayedPronouns($customerUuid: UUID!) {
    ccWebDisabledDisplayedPronouns(input: { customerUuid: $customerUuid }) {
      enabled
    }
  }
`;

const GET_PREFERRED_PRONOUNS = gql`
  query CcWebPreferredPronouns($customerUuid: UUID!) {
    ccWebPreferredPronouns(input: { customerUuid: $customerUuid }) {
      enabled
    }
  }
`;

const UPDATE_CUSTOMER_SETTINGS = gql`
  mutation UpdateOrInsertCustomerSettings(
    $request: CustomerSettingsInsertRequest!
  ) {
    updateOrInsertCustomerSettings(request: $request) {
      emailEditingDisabledTsz
    }
  }
`;

export const useUpdateCustomerSettings = (
  customerUuid: string,
  showImageAvatar: boolean,
  emailEditingEnabled: boolean
) =>
  useMutation<EmailEditResponse, EmailEditInput>(UPDATE_CUSTOMER_SETTINGS, {
    variables: {
      request: { customerUuid, emailEditingEnabled, showImageAvatar },
    },
    refetchQueries: [
      {
        query: GET_CUSTOMER,
        variables: { id: customerUuid, customerId: customerUuid },
      },
    ],
  });

export const useHideStrengthsTab = (customerUuid: string) =>
  useMutation<SocialConfigTabResponse, SocialConfigTabInput>(
    HIDE_STRENGTHS_TAB,
    {
      refetchQueries: [
        {
          query: GET_STRENGTHS_TAB,
          variables: {
            customerUuid,
          },
        },
      ],
    }
  );

export const useShowStrengthsTab = (customerUuid: string) =>
  useMutation<SocialConfigTabResponse, SocialConfigTabInput>(
    SHOW_STRENGTHS_TAB,
    {
      refetchQueries: [
        {
          query: GET_STRENGTHS_TAB,
          variables: {
            customerUuid,
          },
        },
      ],
    }
  );

export const useShowPublicStrengths = (customerUuid: string) =>
  useMutation<SocialConfigTabResponse, SocialConfigTabInput>(
    SHOW_PUBLIC_STRENGTHS,
    {
      refetchQueries: [
        {
          query: GET_STRENGTHS_TAB,
          variables: {
            customerUuid,
          },
        },
      ],
    }
  );

export const useShowPrivateStrengths = (customerUuid: string) =>
  useMutation<SocialConfigTabResponse, SocialConfigTabInput>(
    SHOW_PRIVATE_STRENGTHS,
    {
      refetchQueries: [
        {
          query: GET_STRENGTHS_TAB,
          variables: {
            customerUuid,
          },
        },
      ],
    }
  );

export const useHideFollowingList = (customerUuid: string) =>
  useMutation<SocialConfigTabResponse, SocialConfigTabInput>(HIDE_FOLLOW_LIST, {
    refetchQueries: [
      {
        query: GET_CUSTOMER,
        variables: { id: customerUuid, customerId: customerUuid },
      },
    ],
  });

export const useShowFollowingList = (customerUuid: string) =>
  useMutation<SocialConfigTabResponse, SocialConfigTabInput>(SHOW_FOLLOW_LIST, {
    refetchQueries: [
      {
        query: GET_CUSTOMER,
        variables: { id: customerUuid, customerId: customerUuid },
      },
    ],
  });

export const useEnableCustomerAvatar = (customerUuid: string) =>
  useMutation<GetAvatarResponse, SocialConfigTabInput>(ENABLE_AVATAR_UPLOAD, {
    refetchQueries: [
      {
        query: GET_AVATAR_UPLOAD,
        variables: {
          customerUuid,
        },
      },
    ],
  });

export const useDisableCustomerAvatar = (customerUuid: string) =>
  useMutation<GetAvatarResponse, SocialConfigTabInput>(DISABLE_AVATAR_UPLOAD, {
    refetchQueries: [
      {
        query: GET_AVATAR_UPLOAD,
        variables: {
          customerUuid,
        },
      },
    ],
  });

export const useEnablePreferredPronouns = (customerUuid: string) =>
  useMutation<GetPreferredPronounsResponse, SocialConfigTabInput>(
    ENABLE_PREFERRED_PRONOUNS,
    {
      refetchQueries: [
        {
          query: GET_PREFERRED_PRONOUNS,
          variables: {
            customerUuid,
          },
        },
      ],
    }
  );

export const useDisablePreferredPronouns = (customerUuid: string) =>
  useMutation<GetPreferredPronounsResponse, SocialConfigTabInput>(
    DISABLE_PREFERRED_PRONOUNS,
    {
      refetchQueries: [
        {
          query: GET_PREFERRED_PRONOUNS,
          variables: {
            customerUuid,
          },
        },
      ],
    }
  );

export const useGetStrengthsTab = (customerUuid: string) =>
  useQuery(GET_STRENGTHS_TAB, {
    variables: {
      customerUuid,
    },
  });

export const useGetCustomerAvatar = (customerUuid: string) =>
  useQuery(GET_AVATAR_UPLOAD, {
    variables: {
      customerUuid,
    },
  });

export const useGetPreferredPronoun = (customerUuid: string) =>
  useQuery(GET_PREFERRED_PRONOUNS, {
    variables: {
      customerUuid,
    },
  });
