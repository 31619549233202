import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client';
import { useDebouncedValue } from '@octanner/prism-core/hooks';
import { useSetError } from './SetErrorProvider';
import { BusinessUnit } from '@octanner/prism-core/Header/interfaces';
interface SearchBusinessUnitsInput {
  customerId: string;
  searchContent: string;
}

export const SEARCH_BUSINESS_UNIT_QUERY = gql`
  query SearchBusinessUnits($input: SearchBusinessUnitsInput!) {
    searchBusinessUnits(input: $input) {
      businessUnits {
        buName
        buUuid
      }
    }
  }
`;

interface QueryResult {
  searchBusinessUnits: {
    businessUnits: BusinessUnit[];
  };
}

interface UseBusinessUnitSearch {
  loading: boolean;
  data: QueryResult | undefined;
}

export const useBusinessUnitSearch = (
  input: SearchBusinessUnitsInput,
): UseBusinessUnitSearch => {
  const setError = useSetError();
  const debouncedSearchText = useDebouncedValue(input?.searchContent);
  const { loading, data } = useQuery<QueryResult>(SEARCH_BUSINESS_UNIT_QUERY, {
    variables: {
      input: {
        ...input,
        searchContent: debouncedSearchText,
        activeOnly: true,
      },
    },
    skip: !debouncedSearchText || debouncedSearchText.length < 3,
    onError: (error) => {
      setError(error);
    },
  });

  return {
    loading,
    data,
  };
};

export default useBusinessUnitSearch;
