import {
  Alert,
  Link,
  MenuItem,
  Select,
  Typography,
} from '@octanner/prism-core';
import RichTextEditor from '../../common/slateEditor/RichTextEditor';
import URLSearchParams from '@ungap/url-search-params';
import clsx from 'clsx';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import BasicPadding from '../../common/BasicPadding';
import ContentVersionItemFormComponent from './ContentVersionItemForm';
import { ContentVersion, ContentVersionForm, SupportedLocales } from './types';
import VersionList from './VersionList';
import { useStyles } from './styles';
import { ContentItemProps, EditModes } from './types';
import { supportedLang } from './constants';

const ContentItemComponent = ({
  contentVersions,
  createVersion,
  error,
  typeText,
}: ContentItemProps) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const [filteredVersions, setFilteredVersions] = useState<
    ContentVersion<DateTime>[]
  >([]);

  const params = new URLSearchParams(location.search);
  const versionId = parseInt(params.get('versionId') || '0', 10);
  const languageCode = params.get('languageCode') || 'en_US';
  const editVersion = params.get('editVersion');
  const [version, setVersion] = useState<ContentVersion<DateTime>>();

  useEffect(() => {
    setVersion(contentVersions.find((v) => v.id === versionId));
    //eslint-disable-next-line
  }, [versionId, params]);

  useEffect(() => {
    if (!contentVersions.length) {
      return;
    }
    setFilteredVersions(
      contentVersions.filter((v) => v.locale === languageCode)
    );
  }, [languageCode, contentVersions]);

  const handleCreateVersion = async (formData: ContentVersionForm) => {
    const locale = languageCode as SupportedLocales;
    const effectiveOn = new Date().toISOString();
    const newVersion = await createVersion({
      ...formData,
      locale,
      effectiveOn,
    });

    if (newVersion) setVersion(newVersion);
    if (version) handleClick(version);
    setEdit(false, EditModes.version);
  };

  const handleLanguageChange = (code: unknown) => {
    const params = new URLSearchParams(location.search);
    params.set('languageCode', '' + code);
    history.push({ search: params.toString() });
  };

  const setEdit = (isEdit: boolean, type: EditModes) => {
    const params = new URLSearchParams(location.search);
    if (!isEdit) {
      params.delete(type);
      history.push({ search: params.toString() });
      return;
    }
    params.set(type, 'true');
    history.push({ search: params.toString() });
  };

  const isEditVersion = Boolean(editVersion);

  const handleClick = (version: ContentVersion<DateTime>) => {
    const params = new URLSearchParams(location.search);
    params.set('versionId', `${version.id}`);
    if (isEditVersion) {
      params.delete('editVersion');
    }
    history.push({ search: params.toString() });
  };

  const handleNewVersion = () => {
    const params = new URLSearchParams(location.search);
    params.delete('versionId');
    params.set(EditModes.version, 'true');
    history.push({ search: params.toString() });
  };

  const contentContainerClassName = clsx(
    classes.contentContainer,
    isEditVersion ? classes.editContentContainer : ''
  );

  return (
    <BasicPadding className={classes.root}>
      <Typography variant="h1">{typeText}</Typography>
      {error && (
        <Alert
          severity="error"
          // onClose={clearError}
          className={classes.upperSpace}
        >
          <Typography data-test="consentItem:error">{error}</Typography>
        </Alert>
      )}
      <div>
        <hr />
      </div>
      <Select
        value={languageCode}
        onChange={(event) => handleLanguageChange(event.target.value)}
        label="Language"
        data-test="version:language-code"
        inputProps={{
          name: 'language',
        }}
      >
        {Object.keys(SupportedLocales).map((locale) => (
          <MenuItem
            value={SupportedLocales[locale]} // @ts-ignore
            data-test={`version:language-code:${SupportedLocales[locale]}`}
            key={locale}
          >
            {supportedLang(locale)}
          </MenuItem>
        ))}
      </Select>
      <div className={clsx(classes.container, classes.versions)}>
        <div className={classes.sideContent}>
          <Typography variant="h2">Versions</Typography>
          <div className={classes.listLength}>
            <VersionList
              onClick={handleClick}
              selectedVersionId={isEditVersion ? 0 : versionId}
              versions={filteredVersions}
            />
            <Link
              style={{ flex: 1 }}
              className={classes.edit}
              onClick={handleNewVersion}
              data-test={`consentItem:version:new`}
            >
              Create New Version
            </Link>
          </div>
        </div>
        <div className={classes.mainContent}>
          <div className={classes.detailsHeader}>
            <Typography variant="h2">
              {version && !isEditVersion && 'Preview'}
              {isEditVersion && 'Create New Version'}
            </Typography>
            {version && !isEditVersion && (
              <Link
                className={clsx(classes.edit, classes.leftAuto)}
                onClick={() => setEdit(true, EditModes.version)}
                data-test={`consentItem:version:edit:${version.id}`}
              >
                Clone Version
              </Link>
            )}
          </div>
          <div className={contentContainerClassName}>
            {version && !isEditVersion && (
              <div style={{ width: '100%' }}>
                <RichTextEditor
                  enableToolbar={false}
                  viewMode={true}
                  value={version.content}
                  data-testid={`consentItem:preview:${version.id}`}
                  editableProps={{
                    contentEditable: false,
                  }}
                />
              </div>
            )}
            {isEditVersion && (
              <ContentVersionItemFormComponent
                typeText={typeText}
                material={!filteredVersions.length}
                cloneVersion={version}
                onSave={handleCreateVersion}
                onCancel={() => setEdit(false, EditModes.version)}
                disableConfirmation
              />
            )}
          </div>
        </div>
      </div>
    </BasicPadding>
  );
};

export default ContentItemComponent;
