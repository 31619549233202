import styled from '@emotion/styled';
import { Button, TableCell, TableRow, Typography } from '@octanner/prism-core';
import { Upload } from '@octanner/prism-icons';
import React, { useContext } from 'react';
import { JobManagementRowProps } from 'utils/types';
import { JobManagementContext } from '../../Contexts/JobManagementContext';
import { LocaleContext } from '../../Contexts/LocaleContext';
import { formatDate } from '../../utils/commonActions';
import { ActionType, translationStatusTabs } from '../../utils/constants';
import { defaults } from '../../utils/default';

const ActionCell = styled(TableCell)`
  display: flex;
  justify-content: space-between;
`

const JobIdCell = styled(TableCell)`
  width: 10%;
`

const LocaleCell = styled(TableCell)`
  width: 25%;
`

const JobManagementRow: React.FC<JobManagementRowProps> = ({
  translationJob,
  performJobAction,
  disabled
}) => {

  const { translationStatus } = useContext(JobManagementContext);
  const { localeMap } = useContext(LocaleContext)
  const { send_for_translation, upload_translation, view, delete: deleteJob } = defaults;
  const { uploading, jobNumber, locale, sentToTranslationDate, backFromTranslationDate, downloadedDate } = translationJob

  return (<TableRow key={jobNumber}>
    <JobIdCell>
      <Typography>{jobNumber}</Typography>
    </JobIdCell>
    <LocaleCell>
      <Typography>{localeMap[locale]}</Typography>
    </LocaleCell>
    <TableCell>
      <Typography>{formatDate(downloadedDate)}</Typography>
    </TableCell>
    {(translationStatus === translationStatusTabs.IN_PROGRESS.value
      || translationStatus === translationStatusTabs.COMPLETED.value) && <TableCell>
        <Typography>{formatDate(sentToTranslationDate)}</Typography>
      </TableCell>}
    {translationStatus === translationStatusTabs.COMPLETED.value && <TableCell>
      <Typography>{formatDate(backFromTranslationDate)}</Typography>
    </TableCell>}
    <ActionCell>
      {translationStatus === translationStatusTabs.PENDING.value &&
        <Button disabled={uploading || disabled} variant='text' onClick={() => performJobAction(jobNumber, ActionType.SEND, locale, downloadedDate)}>
          {send_for_translation}
        </Button>}
      {translationStatus === translationStatusTabs.IN_PROGRESS.value &&
        <Button disabled={!uploading && disabled} loading={uploading} onClick={() => !uploading && performJobAction(jobNumber, ActionType.UPLOAD)} variant='text' startIcon={!uploading && <Upload />}>
          {upload_translation}
        </Button>}
      <Button disabled={uploading || disabled} variant='text' onClick={() => performJobAction(jobNumber, ActionType.VIEW)}>
        {view}
      </Button>
      {translationStatus === translationStatusTabs.PENDING.value &&
        <Button disabled={uploading || disabled} onClick={() => performJobAction(jobNumber, ActionType.DELETE)} variant='text' buttonType="danger">
          {deleteJob}
        </Button>}
    </ActionCell>
  </TableRow>);
}

export default JobManagementRow;