import React, { useContext, ReactNode } from 'react';
import { AppContext } from '../utils/context/AppContext';
import Authorizer from '../utils/auth/Authorizer';
import { CustomerContext } from '../utils/context/CustomerContext';

interface Props {
  children: ReactNode;
  authorizer: Authorizer;
}

export default function ProtectedFeature({ children, authorizer }: Props) {
  const context = useContext(AppContext);
  const {
    customer: { id },
    isDefault,
  } = useContext(CustomerContext);
  const customerId = isDefault ? undefined : id;

  // note <></> is a workaround to a known @types/react bug
  // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/20544
  return authorizer(context, customerId) ? <>{children}</> : null;
}
