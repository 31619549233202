import {
  Button,
  FormControlLabel,
  Switch,
  TextField,
  Grid,
} from '@octanner/prism-core';
import clsx from 'clsx';
import React, { useState, useEffect, useContext } from 'react';
import { useSearchCustomers } from '../global/hooks/customer';
import { CustomerBase } from '../customer/models/Customer';
import { useDebouncedCallback } from 'use-debounce';
import { ImpersonateFormRows, defaultState } from './constants';
import { ImpersonateSearchFormProps } from './types';
import { useStyles } from './styles';
import { CustomerContext } from '../../utils/context/CustomerContext';

const ImpersonateSearchForm = (props: ImpersonateSearchFormProps) => {
  const classes = useStyles();
  const [text, setText] = useState('');
  const [state, setState] = useState(defaultState);
  const { data, loading } = useSearchCustomers(
    { pageSize: 50, pageNumber: 0 },
    text
  );
  const [customers, setCustomers] = useState<CustomerBase[]>([]);
  const [customer, setCustomer] = useState<CustomerBase>();

  const { setCurrentImpersonateClient } = useContext(CustomerContext);

  const nodes = data?.searchCustomers?.nodes;
  const currentImpersonateClient = props?.currentImpersonateClient;

  useEffect(() => {
    const users = nodes ? nodes : [];
    setCustomers(users);
    if (currentImpersonateClient != null) {
      setCustomers([currentImpersonateClient]);
      handleCustomerSelection('', currentImpersonateClient);
    }
    return () => {
      props.setCurrentImpersonateClient(null);
    };
    //eslint-disable-next-line
  }, [data, nodes, currentImpersonateClient]);

  const handleTextChange = useDebouncedCallback(
    (text: string, customers?: CustomerBase[]) => {
      setText(text);
      if (customers) setCustomers(customers);
    },
    250
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name as keyof typeof state;
    let value: string | boolean | undefined =
      event.target.checked || event.target.value || undefined;
    // ? Not sure why, but the value stayed stagnant so I had to do this in order for it to work.
    if (value === 'on') {
      value = false;
    } else if (value === 'off') {
      value = true;
    }
    setState({
      ...state,
      [name]: value,
    });
  };
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { hideTerminated, ...rest } = state;
    const active = hideTerminated ? true : undefined;
    const searchCondition = {
      ...rest,
      active,
      ...(customer ? { customerId: customer.id } : {}),
    };
    props.handleSubmit(searchCondition);
  };
  const handleCustomerSelection = (_: any, value: CustomerBase) => {
    setCustomer(value);
    setCurrentImpersonateClient(value);
  };

  return (
    <form
      onSubmit={handleSubmit}
      className={clsx(classes.form, props.className)}
    >
      <Grid container spacing={3}>
        {ImpersonateFormRows.map(
          ({ customComponent, key, label, dataTest, size }) => {
            if (customComponent) {
              return (
                <Grid item xs={size} key={key + label}>
                  {customComponent({
                    handleCustomerSelection,
                    loading,
                    customers,
                    customer,
                    handleTextChange,
                  })}
                </Grid>
              );
            }
            return (
              <Grid item xs={size} key={key + label}>
                <TextField
                  value={state[key] || ''}
                  onChange={handleChange}
                  label={label}
                  inputProps={{
                    name: key,
                    'data-test': dataTest,
                  }}
                  fullWidth
                />
              </Grid>
            );
          }
        )}
      </Grid>
      <div className={clsx(classes.formRow, classes.search)}>
        <Button
          type="submit"
          disableRipple
          data-test="impersonate:search:submit"
          adminColor
        >
          Search
        </Button>
        <FormControlLabel
          className={classes.alignRight}
          control={
            <Switch
              checked={state.hideTerminated}
              onChange={handleChange}
              name="hideTerminated"
              data-test="impersonate:search:hideTerminated"
            />
          }
          labelPlacement="start"
          label="Hide terminated users"
        />
      </div>
    </form>
  );
};

export default ImpersonateSearchForm;
