import styled from "@emotion/styled";
import { Alert, Avatar, Breadcrumbs, Button, Grid, Link, Typography } from "@octanner/prism-core";
import { ArrowLeft, Trash } from "@octanner/prism-icons";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { CustomTextContext } from "../../Contexts/CustomTextContext";
import { formatDate } from "../../utils/commonActions";
import { backLinks } from "../../utils/constants";
import { defaults } from "../../utils/default";
import StyledTextField from "../../utils/styledComponents";
import { AlertMessage, CustomTextDetailsProps } from "../../utils/types";
import CustomTextRequestTranslationModal from "./Modals/CustomTextRequestTranslationModal";
import DeleteCustomTextModal from "./Modals/DeleteCustomTextModal";

const StringDetailsContainer = styled.div`
  margin-bottom: 24px;
`;

const BackLink = styled(Breadcrumbs)`
  margin-bottom: 24px;
`;

const FlexContainer = styled(Grid)`
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
`;

const DetailsContainer = styled.div`
  margin-left: 12px;
`;

const NotesContainer = styled.div`
  background: #f8f8f8;
  padding: 8px 16px;
  max-width: 50%;
  margin-top: 14px;
`;

const FlexContainerColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const NotesHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const NotesHeader = styled(Typography)`
  display: flex;
  align-items: center;
  margin-right: 16px;
`;

const Remarks = styled(Typography)`
  display: flex;
  align-items: center;
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const StpContainer = styled.div`
  margin-top: 10px;
  margin-right: 48px;
  display: flex;
  flex-direction: column;
`;

const StyledTypography = styled(Typography)`
  margin-bottom: 8px;
`;

const Header = styled(Grid)`
  display: flex;
  align-items: top;
  justify-content: flex-end;
`;

const DeleteButtonContainer = styled(Button)`
  border: none;
  background: none;
  color: red;
  &:hover {
    background: none;
  }
  margin-right: 5px;
  margin-left: 10px;
  display: flex;
  align-items: right;
`;

const DeleteIcon = styled(Trash)`
  margin-right: 5px;
`;

const MessageAlert = styled(Alert)`
  margin-bottom: 12px;
`;

const NotesTextField = styled.div`
  margin-top: 10px;
  width: 50%;
`;

const ButtonContainer = styled.div`
  margin : 8px 0px;
`;

const StringDetailContainer = styled(Grid)`
  display:flex;
  justify-content: left;
  margin-top: 12px;
`;

const CustomTextDetails: React.FC<Partial<CustomTextDetailsProps>> = ({ customTextDetails }) => {

  const { stringId } = useParams();
  const history = useHistory();
  const note = customTextDetails.notes || '';

  const [notes, setNotes] = useState<string>(note || '');
  const [showCustomTextNoteEdit, setShowCustomTextNoteEdit] = useState<boolean>(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const backLinkType = queryParams.get('type');
  const [noteProcessing, setNoteProcessing] = useState<boolean>(false);
  const [showDeleteCustomTextModal, setShowDeleteCustomTextModal] = useState<boolean>(false);
  const [showRequestTranslationModal, setShowRequestTranslationModal] = useState<boolean>(false);
  const { messages, setMessageText, searchData, searchCustomText, customTextUniqueId,
    saveCustomTextNotes } = useContext(CustomTextContext);
  const [backLink, setBackLink] = useState<{ title: string, path: string }>(null);
  const [disableBackLink, setDisableBackLink] = useState(false);
  const { requested_by_name, not_announced, requested_by_email, program_id, stp, date_requested } = defaults;

  useEffect(() => {
    if (backLinkType) {
      setBackLink(backLinks[backLinkType])
    }
  }, [backLinkType]);

  useEffect(() => {
    setNotes(customTextDetails.notes || '');
  }, [customTextDetails?.notes]);

  const handleNoteCancel = (): void => {
    setShowCustomTextNoteEdit(false);
    setNotes(note);
  }

  const toggleNoteEdit = (): void => {
    setShowCustomTextNoteEdit(!showCustomTextNoteEdit);
  }

  const handleCustomTextNoteSave = (): void => {
    if (notes.trim() !== note.trim()) {
      setNoteProcessing(true);
      saveCustomTextNotes({
        variables: {
          input: {
            notes: notes.trim(),
            customTextRequestUniqueId: customTextUniqueId || customTextDetails.uniqueId
          },
        },
      }).then(() => {
        setNoteProcessing(false);
        setShowCustomTextNoteEdit(false);
      });
    } else {
      setShowCustomTextNoteEdit(false);
    }
  };

  const backToSearch = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.preventDefault();
    if (disableBackLink) {
      return;
    }
    setDisableBackLink(true);
    setMessageText('requestSuccessMessage', '');
    setMessageText('editSuccessMessage', '');
    setMessageText('errorMessage', '');
    setMessageText('createSuccessMessage', '');
    setMessageText('createWarningMessage', '');
    if (backLinkType) {
      setDisableBackLink(false);
      history.push(backLink.path);
      return;
    }
    if (!searchData) {
      searchCustomText({
        variables: {
          stringId: parseInt(stringId)
        }
      })
    }
    setDisableBackLink(false);
    setMessageText('deleteSuccessMessage', '');
    history.push('/custom-text')
  }

  return (
    <StringDetailsContainer>
      <BackLink>
        <Link href="#" color="inherit" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => backToSearch(e)}><ArrowLeft />
          {(backLink ? backLink.title : defaults.search_custom_text_string)}</Link>
        <Typography color="inherit">{stringId}</Typography>
      </BackLink>
      {messages.map((message: AlertMessage) => (
        message?.text?.length > 0 &&
        (<Grid key={message.key} container>
          <Grid item xs={message.gridSize}><MessageAlert
            onClose={() => setMessageText(message.key, '')}
            severity={message.severity}>
            {message.text}
          </MessageAlert></Grid></Grid>)
      ))}
      <FlexContainer container spacing={2}>
        <StringDetailContainer item xs={7.8}>
          <Avatar
            size='sm'
            src='https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png'
            alt='Avatar'
          />
          <DetailsContainer>
            <Typography variant='h4'>{defaults.string_id + ' : ' + stringId}</Typography>
            <Container>
              <StpContainer>
                <StyledTypography color='textSecondary' variant='body2'>{requested_by_name}: &nbsp;</StyledTypography>
                <StyledTypography variant='body2'>{customTextDetails.requesterName || not_announced}</StyledTypography>
              </StpContainer>
              <StpContainer>
                <StyledTypography color='textSecondary' variant='body2'>{requested_by_email}: &nbsp;</StyledTypography>
                <StyledTypography variant='body2'>{customTextDetails.requesterEmail || not_announced}</StyledTypography>
              </StpContainer>
              <StpContainer>
                <StyledTypography color='textSecondary' variant='body2'>{program_id}: &nbsp;</StyledTypography>
                <StyledTypography variant='body2'>{customTextDetails.programId || not_announced}</StyledTypography>
              </StpContainer>
              <StpContainer>
                <StyledTypography color='textSecondary' variant='body2'>{stp}: &nbsp;</StyledTypography>
                <StyledTypography variant='body2'>{customTextDetails.soldToPartyNumber || not_announced}</StyledTypography>
              </StpContainer>
              <StpContainer>
                <StyledTypography color='textSecondary' variant='body2'>{date_requested}: &nbsp;</StyledTypography>
                <StyledTypography variant='body2'>{formatDate(customTextDetails.requestRaisedDate) || defaults.not_announced}</StyledTypography>
              </StpContainer>
            </Container>
            <>
              {(!showCustomTextNoteEdit && !noteProcessing) ? (
                <>
                  <NotesContainer>
                    <FlexContainerColumn>
                      <NotesHeaderContainer>
                        <NotesHeader color='textSecondary' variant='body1'>
                          {defaults.notes} :
                        </NotesHeader>
                        <Button variant='text' onClick={toggleNoteEdit}>
                          {defaults.edit}
                        </Button>
                      </NotesHeaderContainer>
                      <Remarks variant='body2' color={notes ? 'textPrimary' : 'textSecondary'}>
                        {notes ? notes : defaults.no_notes}
                      </Remarks>
                    </FlexContainerColumn>
                  </NotesContainer>
                </>
              ) : (
                <>
                  <NotesTextField>
                    <StyledTextField multiline rows={2} label={defaults.add_note} fullWidth value={notes} displayCount={true}
                      maxLength={4000} onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => setNotes(event.target.value)} />
                  </NotesTextField>
                  <ButtonContainer>
                    <Button disabled={note.trim() === notes.trim() || notes?.trim()?.length > 4000}
                      variant='text' color='primary' loading={noteProcessing} onClick={handleCustomTextNoteSave}>
                      {defaults.save}
                    </Button>
                    <Button variant='text' color="secondary" buttonType="danger" disabled={noteProcessing} onClick={handleNoteCancel}>
                      {defaults.cancel}
                    </Button>
                  </ButtonContainer>
                </>
              )}
            </>
          </DetailsContainer>
        </StringDetailContainer>
        <Header item xs={4.2}>
          <div>
            <DeleteButtonContainer onClick={() => setShowDeleteCustomTextModal(true)}>
              <DeleteIcon /> {defaults.delete_custom_text}
            </DeleteButtonContainer>
          </div>
          <div>
            <Button adminColor onClick={() => setShowRequestTranslationModal(true)}>
              {defaults.request_translations}
            </Button>
          </div>
        </Header>
      </FlexContainer>
      {showRequestTranslationModal && <CustomTextRequestTranslationModal showRequestTranslationModal={showRequestTranslationModal} setShowRequestTranslationModal={setShowRequestTranslationModal}
        note={notes}></CustomTextRequestTranslationModal>}
      <DeleteCustomTextModal showDeleteCustomTextModal={showDeleteCustomTextModal}
        setShowDeleteCustomTextModal={setShowDeleteCustomTextModal} customTextRequest={customTextDetails}></DeleteCustomTextModal>
    </StringDetailsContainer>
  )
}

export default CustomTextDetails;