import { SocialFeedSearchView } from '../../../customer/models/Customer';

export enum PostDataNames {
  BUSINESSUNIT = 'Business Unit',
  RECOGNITIONAWARDLEVEL = 'Recognition Award Level',
  MILESTONEAWARD = 'Anniversaries/Milestone Award Level',
  RECOGNITIONMESSAGE = 'Message for nomination and e-Card',
}
export enum PostDataTerms {
  BANNER = 'Banner',
  TITLE = 'Title',
}

export interface UpdatePersonalFeedOverrideInput {
  customerUuid: string;
}
export interface UpdatePersonalFeedOverrideResponse {
  social: {
    personalFeedOverride: boolean;
  };
}

export interface UpdateFeedViewTypeInput {
  customerUuid: string;
  defaultViewType: SocialFeedSearchView;
}

export interface UpdateFeedViewTypeResponse {
  viewType: SocialFeedSearchView;
}

export interface UpdateSocialFilterInput {
  terms: string[];
  key: string;
  customerUuid: string;
}

export interface UpdateSocialFilterResponse {
  term: string;
  active: boolean;
  selected: boolean;
}

export interface UpdateSocialPostsInput {
  customerUuid: string;
}

export interface UpdateSocialPostsResponse {
  display: boolean;
}

export interface SocialCardConfigResponse {
  socialCardConfigByCustomerUuid: SocialCardConfigByCustomerUuid;
}

export interface SocialConfigResponse {
  socialConfigByCustomerUuid: SocialConfigByCustomerUuid;
}

export interface SocialCardConfigByCustomerUuid {
  displayAwardLevel: boolean;
  displayBusinessUnit: boolean;
  displayMilestoneAward: boolean;
  displayRecognitionMessage: boolean;
  recognitionAwardLevel: {
    bannerCheckBox: boolean;
    recognitionAward: boolean;
    titleCheckBox: boolean;
  };
}

export interface SocialConfigByCustomerUuid {
  socialConfig: {
    displayComments: boolean;
    displayFollowingTab: boolean;
    displayLikes: boolean;
    personalFeedOverride: boolean;
    displayAwardLevel: boolean;
    displayBusinessUnit: boolean;
    displayMilestoneAward: boolean;
    displayRecognitionMessage: boolean;
    viewType: SocialFeedSearchView;
    recognitionAwardLevel: {
      bannerCheckBox: boolean;
      recognitionAward: boolean;
      titleCheckBox: boolean;
    };
  };
}
