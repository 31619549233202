import React, { useState } from 'react';
import styled from '@emotion/styled';
import {
  Accordion,
  AccordionSummary,
  Button,
  FormControl,
  FormLabel,
  Loader,
  Typography,
} from '@octanner/prism-core';
import { ArrowDown } from '@octanner/prism-icons';

import DisplayOnCCHome from './DisplayOnCCHome';
import ErrorMsg from '../utils/ErrorMsg';
import { SetErrorContext, useSetError } from '../utils/SetErrorProvider';
import HomepageWidgetForm from './HomepageWidgetForm';

import { HomepageWidgetHeaderEnum, Program } from '../types';
import { useAwardList } from '../Awards/useAwardList';
import { useUpdateProgramConfiguration } from './useUpdateProgramConfiguration';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 48px 0;
  margin-top: 26px; /* 48 - 22px padding from the Accordion */
`;

const PrestigeAccordion = styled(Accordion)`
  box-shadow: none;
`;

const Summary = styled(AccordionSummary)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0;
  padding: 0;
`;

const Heading = styled(Typography)``;

const HomepageWidgetHeading = styled(Typography)`
  padding-bottom: 16px;
`;

const SaveButton = styled(Button)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 16px;
  margin-top: 32px;
`;

const AdditionalProgramSettings: React.FC<Props> = ({
  error,
  program,
  programId,
}) => {
  const setError = useSetError();
  const [displayOnCCHome, setDisplayOnCCHome] = useState(
    program?.displayOnCCHome || false,
  );
  const [customHeaderText, setCustomHeaderText] = useState(
    program?.promoHeader || '',
  );
  const [widgetText, setWidgetText] = useState(program?.promoBody || '');
  const [homepageWidgetHeader, setHomepageWidgetHeader] = useState(
    program?.promoHeader
      ? ('CUSTOM_TEXT' as HomepageWidgetHeaderEnum)
      : ('USE_PROGRAM_NAME' as HomepageWidgetHeaderEnum),
  );
  // Handle use cases for null object on first pass
  // but not populating until the React makes a second pass.
  // This also accounts for two opposing bugs
  // (Save Additional Settings not showing correct state,
  // and empty programs being allowed such as in editing a new Program).
  const [isPreExistingProgram, setIsPreExistingProgram] = useState(!!program);
  if (!isPreExistingProgram && program) {
    setIsPreExistingProgram(true);
    setDisplayOnCCHome(program.displayOnCCHome);
    setCustomHeaderText(program.promoHeader);
    setWidgetText(program.promoBody);
    setHomepageWidgetHeader('CUSTOM_TEXT' as HomepageWidgetHeaderEnum);
  }

  const { loading, saveProgramConfiguration } = useUpdateProgramConfiguration();

  const handleChange = (event) => {
    if (event.target.value === 'USE_PROGRAM_NAME') setCustomHeaderText('');
    setHomepageWidgetHeader('USE_PROGRAM_NAME' as HomepageWidgetHeaderEnum);
    if (event.target.value === 'CUSTOM_TEXT')
      setHomepageWidgetHeader('CUSTOM_TEXT' as HomepageWidgetHeaderEnum);
    setCustomHeaderText(program?.promoHeader);
  };

  const isSaveDisabled =
    displayOnCCHome &&
    ((homepageWidgetHeader === ('CUSTOM_TEXT' as HomepageWidgetHeaderEnum) &&
      !customHeaderText) ||
      widgetText === null ||
      widgetText === '');

  return (
    <Container>
      <PrestigeAccordion
        // disableGutters={true}
        aria-label="program menu"
        defaultExpanded={true}
      >
        <Summary expandIcon={<ArrowDown />}>
          <div>
            <Heading variant="h2">Additional Program Settings</Heading>
          </div>
        </Summary>
        <ErrorMsg error={error} setError={setError} />
        <div>
          <FormControl>
            <FormLabel component="legend">
              <HomepageWidgetHeading variant="h4">
                Homepage promotion widget
              </HomepageWidgetHeading>
            </FormLabel>
          </FormControl>
          <DisplayOnCCHome
            displayOnCCHome={displayOnCCHome}
            setDisplayOnCCHome={setDisplayOnCCHome}
          />
          {displayOnCCHome && (
            <HomepageWidgetForm
              customHeaderText={customHeaderText}
              setCustomHeaderText={setCustomHeaderText}
              widgetText={widgetText}
              setWidgetText={setWidgetText}
              homepageWidgetHeader={homepageWidgetHeader}
              handleChange={handleChange}
            />
          )}
        </div>
        <div>
          <SaveButton
            loading={loading}
            onClick={() => {
              saveProgramConfiguration({
                id: programId,
                promoBody: widgetText,
                promoHeader:
                  homepageWidgetHeader ===
                  ('CUSTOM_TEXT' as HomepageWidgetHeaderEnum)
                    ? customHeaderText
                    : program?.programName,
                displayOnCCHome,
              });
            }}
            disabled={isSaveDisabled}
          >
            Save Additional Settings
          </SaveButton>
        </div>
      </PrestigeAccordion>
    </Container>
  );
};

interface Props {
  program?: Program;
  programId: string;
  error?: string;
}

const AdditionalProgramSettingsWithEditor: React.FC<Props> = (props) => {
  const { loading: loadingProgram, program } = useAwardList(props.programId);
  return loadingProgram ? (
    <Loader type="dots" loading />
  ) : (
    <AdditionalProgramSettings {...props} program={program} />
  );
};

const AdditionalProgramSettingsWithErrorMsg: React.FC<Props> = (props) => {
  const [error, setError] = React.useState(null);
  return (
    <SetErrorContext.Provider value={setError}>
      <AdditionalProgramSettingsWithEditor {...props} error={error} />
    </SetErrorContext.Provider>
  );
};

export default AdditionalProgramSettingsWithErrorMsg;
