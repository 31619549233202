import { ProgramSkeleton } from '../styles';
import { TableCell, TableRow } from '@octanner/prism-core';

// TODO: Fix typing
const ProgramRowSkeleton: any = () => {
  //? Arr is key values for each, good for rerender.
  return Array.from(['r1', 'r2', 'r3', 'r4', 'r5', 'r6'], (r) => (
    <TableRow key={r}>
      <TableCell>
        <ProgramSkeleton
          variant="rectangular"
          height={15}
          animation="wave"
          width={'100%'}
        />
      </TableCell>
      <TableCell>
        <ProgramSkeleton
          variant="rectangular"
          height={15}
          animation="wave"
          width={'100%'}
        />
      </TableCell>
      <TableCell>
        <ProgramSkeleton
          variant="rectangular"
          height={15}
          animation="wave"
          width={'100%'}
        />
      </TableCell>
      <TableCell>
        <ProgramSkeleton
          variant="rectangular"
          height={15}
          animation="wave"
          width={'100%'}
        />
      </TableCell>
      <TableCell>
        <ProgramSkeleton
          variant="rectangular"
          height={15}
          animation="wave"
          width={'100%'}
        />
      </TableCell>
      <TableCell>
        <ProgramSkeleton
          variant="rectangular"
          height={15}
          animation="wave"
          width={'100%'}
        />
      </TableCell>
    </TableRow>
  ));
};

export default ProgramRowSkeleton;
