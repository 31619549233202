import React from 'react';
import styled from '@emotion/styled';
import { tannerGray } from '@octanner/prism-core/ThemeProvider/colors';
import { Award as AwardType } from '../types';
import MenuDots from '@octanner/prism-icons/MenuDots';
import {
  IconButton,
  Typography,
  Menu,
  MenuItem,
  Loader,
  Tag,
} from '@octanner/prism-core';
import { useRounds } from '../ApprovalPath/useRounds';
import AwardEditor from './AwardEditor';
import { useAwardList } from './useAwardList';

const AwardContainer = styled.li`
  align-items: center;
  display: flex;
  font-size: 14px;
  padding: 16px;
`;

const AwardTitle = styled.span`
  flex: 1 1 auto;
  margin-right: 8px;
`;

const AwardName = styled(Typography)`
  color: #000000;
`;

const FlexFieldsCount = styled.div`
  color: ${tannerGray['600']};
`;

const Award: React.FC<Props> = ({
  award,
  isDuplicateName,
  setDisableNewAward,
  updateRecogConfig,
  programId,
}) => {
  const [showAwardEditor, setShowAwardEditor] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { awardLevel, loading } = useRounds(
    award.recogConfigId,
    !showAwardEditor,
  );

  const isInitialLoad = loading && !awardLevel;

  const cancelAward = () => {
    setShowAwardEditor(false);
    setDisableNewAward(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onEdit = () => {
    handleClose();
    setShowAwardEditor(true);
    setDisableNewAward(true);
  };

  const flexFieldsCountText = () => {
    const flexFieldCount = award.relatedFlexFields.length;
    if (flexFieldCount > 0) {
      return flexFieldCount === 1
        ? `${flexFieldCount} field`
        : `${flexFieldCount} fields`;
    }
    return '';
  };

  return (
    <>
      <AwardContainer>
        <AwardTitle>
          <AwardName>{award.configName}</AwardName>
          <FlexFieldsCount>{flexFieldsCountText()}</FlexFieldsCount>
        </AwardTitle>
        {showAwardEditor ? (
          <Tag variant="warning">In progress</Tag>
        ) : award.active ? (
          <Tag variant="success">Active</Tag>
        ) : (
          <Tag variant="archived">Inactive</Tag>
        )}
        <IconButton
          onClick={handleClick}
          aria-label="award menu"
          sx={{ ml: 3 }}
        >
          <MenuDots />
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={onEdit}>Edit</MenuItem>
        </Menu>
      </AwardContainer>
      {showAwardEditor &&
        (isInitialLoad || !awardLevel ? (
          <Loader type="dots" loading />
        ) : (
          <AwardEditor
            awardToEdit={awardLevel}
            isDuplicateName={isDuplicateName}
            onCancel={cancelAward}
            onSave={updateRecogConfig}
            programId={programId}
          />
        ))}
    </>
  );
};

interface Props {
  award: AwardType;
  isDuplicateName: (val: string, configName: string) => boolean;
  programId: string;
  setDisableNewAward: (awardToEdit: boolean) => void;
  updateRecogConfig: ReturnType<typeof useAwardList>['updateRecogConfig'];
}

export default Award;
