import styled from '@emotion/styled';
import { LanguageControl } from '@octanner/cc-lang-ui';
import { Button, Typography } from '@octanner/prism-core';
import ArrowTailLeftIcon from '@octanner/prism-icons/ArrowTailLeft';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { authFetch } from '../../utils/auth/Auth';
import permissionAuthorizer from '../../utils/auth/PermissionAuthorizer';
import { AppContext } from '../../utils/context/AppContext';
import { useCurrentIdentity } from '../user-auth/hooks/identity';

const NotAllowedContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
`;

const LanguageLanding: React.FC = () => {
  const authorizer = permissionAuthorizer(['LANGUAGE_CONTROL']);
  const context = useContext(AppContext);
  const permissionAllowed = authorizer(context, context.decodedToken.cus);
  const { data } = useCurrentIdentity();
  const currentIdentity = data?.currentIdentity;
  const name = currentIdentity
    ? `${currentIdentity.firstName} ${currentIdentity.lastName}`
    : '';

  if (!permissionAllowed) {
    return (
      <NotAllowedContainer>
        <Typography variant="h4">
          Invalid permissions to view this Page.
        </Typography>
        <Link data-test="go-to-landing" to="/">
          <Button startIcon={<ArrowTailLeftIcon />} variant="text">
            <Typography variant="h6">Back</Typography>
          </Button>
        </Link>
      </NotAllowedContainer>
    );
  }

  return (
    <LanguageControl
      customerEmail={currentIdentity?.email || ''}
      customerName={name}
      fetch={authFetch}
    />
  );
};

export default LanguageLanding;
