import { gql } from '@apollo/client';

export const ACTIVATE_SOCIAL_FILTER = gql`
  mutation SocialFilterActivate($input: SocialFilterInput!) {
    socialFilterActivate(input: $input) {
      key
      filters {
        term
        active
        selected
      }
    }
  }
`;

export const DEACTIVATE_SOCIAL_FILTER = gql`
  mutation SocialFilterDeactivate($input: SocialFilterInput!) {
    socialFilterDeactivate(input: $input) {
      key
      filters {
        term
        active
        selected
      }
    }
  }
`;

export const SELECT_SOCIAL_FILTER = gql`
  mutation SocialFilterSelect($input: SocialFilterInput!) {
    socialFilterSelect(input: $input) {
      key
      filters {
        term
        active
        selected
      }
    }
  }
`;

export const DESELECT_SOCIAL_FILTER = gql`
  mutation SocialFilterUnselect($input: SocialFilterInput!) {
    socialFilterUnselect(input: $input) {
      key
      filters {
        term
        active
        selected
      }
    }
  }
`;
