import { useQuery, useMutation } from '@apollo/client';
import {
  GET_ALL_ROLE_INFORMATION,
  GET_ROLE_IDENTITY_INFORMATION,
} from '../graphql/graphql';
import {
  ADD_IDENTITY,
  REMOVE_IDENTITY,
  ADD_IDENTITY_GROUP,
  REMOVE_IDENTITY_GROUP,
} from '../graphql/identity';
import {
  AddIdentityResponse,
  AddRemoveIdentityInput,
  IdentitesByRoleInput,
  RemoveIdentityResponse,
  AddIdentityGroupResponse,
  AddRemoveIdentityGroupInput,
  RemoveIdentityGroupResponse,
} from '../models/Identity';
import {
  GetAllRoleInformationResponse,
  GetRoleIdentityInformationResponse,
} from '../models/models';
import { RoleInput } from '../models/Role';

export const useGetAllRoleInfo = (variables: RoleInput) => {
  const roleInfoQuery = useQuery<GetAllRoleInformationResponse, RoleInput>(
    GET_ALL_ROLE_INFORMATION,
    {
      variables,
    }
  );

  return {
    ...roleInfoQuery,
  };
};

export const useGetRoleIdentityInfo = (variables: IdentitesByRoleInput) => {
  const roleIdentityQuery = useQuery<
    GetRoleIdentityInformationResponse,
    IdentitesByRoleInput
  >(GET_ROLE_IDENTITY_INFORMATION, { variables, skip: !variables.roleUuid });

  const fetchMore = (page: number) => {
    return roleIdentityQuery.fetchMore({
      variables: {
        ...variables,
        page,
      },
    });
  };

  const [addIdentity, { loading: addIdentityLoading }] = useMutation<
    AddIdentityResponse,
    AddRemoveIdentityInput
  >(ADD_IDENTITY, {
    update(cache, { data }) {
      const currentData = cache.readQuery<GetRoleIdentityInformationResponse>({
        query: GET_ROLE_IDENTITY_INFORMATION,
        variables,
      });
      if (!currentData || !data) return;

      cache.writeQuery({
        query: GET_ROLE_IDENTITY_INFORMATION,
        variables,
        data: {
          ...currentData,
          identitiesByRole: {
            ...currentData.identitiesByRole,
            content: [
              ...currentData.identitiesByRole.content,
              data.coupleIdentityAndRole.identity,
            ],
          },
        },
      });
    },
  });

  const [removeIdentity, { loading: removeIdentityLoading }] = useMutation<
    RemoveIdentityResponse,
    AddRemoveIdentityInput
  >(REMOVE_IDENTITY, {
    update(cache, { data }) {
      const currentData = cache.readQuery<GetRoleIdentityInformationResponse>({
        query: GET_ROLE_IDENTITY_INFORMATION,
        variables,
      });
      if (!currentData || !data) return;

      cache.writeQuery({
        query: GET_ROLE_IDENTITY_INFORMATION,
        variables,
        data: {
          ...currentData,
          identitiesByRole: {
            ...currentData.identitiesByRole,
            content: currentData.identitiesByRole.content.filter(
              (identity) =>
                identity.id !== data.decoupleIdentityAndRole.identity.id
            ),
          },
        },
      });
    },
  });

  const [addIdentityGroup, { loading: addGroupLoading }] = useMutation<
    AddIdentityGroupResponse,
    AddRemoveIdentityGroupInput
  >(ADD_IDENTITY_GROUP, {
    update(cache, { data }) {
      const currentData = cache.readQuery<GetRoleIdentityInformationResponse>({
        query: GET_ROLE_IDENTITY_INFORMATION,
        variables,
      });
      if (!currentData || !data) return;

      cache.writeQuery({
        query: GET_ROLE_IDENTITY_INFORMATION,
        variables,
        data: {
          ...currentData,
          identityGroupsByRole: {
            ...currentData.identityGroupsByRole,
            content: [
              ...currentData.identityGroupsByRole.content,
              data.coupleIdentityGroupAndRole.identityGroup,
            ],
          },
        },
      });
    },
  });

  const [removeIdentityGroup, { loading: removeGroupLoading }] = useMutation<
    RemoveIdentityGroupResponse,
    AddRemoveIdentityGroupInput
  >(REMOVE_IDENTITY_GROUP, {
    update(cache, { data }) {
      const currentData = cache.readQuery<GetRoleIdentityInformationResponse>({
        query: GET_ROLE_IDENTITY_INFORMATION,
        variables,
      });
      if (!currentData || !data) return;

      cache.writeQuery({
        query: GET_ROLE_IDENTITY_INFORMATION,
        variables,
        data: {
          ...currentData,
          identityGroupsByRole: {
            ...currentData.identityGroupsByRole,
            content: currentData.identityGroupsByRole.content.filter(
              (group) =>
                group.id !== data.decoupleIdentityGroupAndRole.identityGroup.id
            ),
          },
        },
      });
    },
  });

  return {
    ...roleIdentityQuery,
    identityLoading: addIdentityLoading || removeIdentityLoading,
    groupLoading: addGroupLoading || removeGroupLoading,
    actions: {
      roleFetchMore: fetchMore,
      addIdentity,
      removeIdentity,
      addIdentityGroup,
      removeIdentityGroup,
    },
  };
};
