import { PaginationContainer } from '../styles';
import { Pagination } from '@octanner/prism-core';

type AdminPaginationProps = {
  totalCount: number;
  pageNumber: number;
  pageSize: number;
  setPageNumber: (page: number) => void;
  setPageSize: (size: number) => void;
};

const AdminPagination = ({
  totalCount,
  pageNumber,
  pageSize,
  setPageNumber,
  setPageSize,
}: AdminPaginationProps) => {
  return (
    <PaginationContainer>
      {totalCount > 10 && (
        <Pagination
          rowsPerPage
          menuProps={{ value: 'rows per page' }}
          totalRecord={totalCount}
          page={pageNumber}
          onChange={(_, page) => setPageNumber(page)}
          itemsPerPage={pageSize}
          itemsPerPageOptions={[10, 20, 30, 40, 50]}
          onItemsPerPageChange={(size) => setPageSize(size)}
          sx={{
            '& .MuiPagination-ul': {
              flexWrap: 'nowrap',
            },
          }}
        />
      )}
    </PaginationContainer>
  );
};

export default AdminPagination;
