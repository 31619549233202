import { Alert, Button } from '@octanner/prism-core'
import { styled } from '@mui/material/styles'
import colors from 'library/src/common/colors'
import { YbProgramOffsets } from 'library/src/common/models/ProgramOffsets'
import { useGetProgramOffsetsByProgramId } from 'library/src/graphql/useGetProgramOffsetsByProgramId'
import usePatchOffsets from 'library/src/graphql/usePatchOffsets'
import { usePatchProgram } from 'library/src/graphql/usePatchProgram'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { EditSection } from '../../common/models/EditSection'
import useCreateDefaultProgram from '../../graphql/useCreateDefaultProgram'
import CelebrationGroupPointsView from '../CelebrationGroupPointsView/CelebrationGroupPointsView'
import CelebrationGroupView from '../CelebrationGroupView/CelebrationGroupView'
import GeneralProgramSettings from '../GeneralProgramSettings/GeneralProgramSettings'
import GeneralProgramSettingsEdit, {
  OnSubmitForm,
} from '../GeneralProgramSettingsEdit/GeneralProgramSettingsEdit'
import Loading from '../Loading'
import PointLevelConfigurationEdit from '../PointLevelConfigurationEdit/PointLevelConfigurationEdit'
import PointLevelConfigurationView from '../PointLevelConfigurationView/PointLevelConfigurationView'
import SchedulingForm, { OffsetsInput } from '../SchedulingForm/SchedulingForm'
import SchedulingSettings from '../SchedulingSettings/SchedulingSettings'
import StylesProvider from '../StylesProvider/StylesProvider'

interface Props {
  programId: string
  onEditGroupClick: (groupId?: string) => void
  onEditAccrualPointRegion: (regionId: string) => void
  onAddRegion: () => void
}

const Root = styled('section')`
  display: grid;
  grid-gap: ${({ theme }) => theme.spacing(6)};
  > section {
    padding-top: ${({ theme }) => theme.spacing(5)};
    border-top: 1px solid ${colors.gray400};
  }
`

type CommonProps = {
  onCancel: () => void
  hideEdit: boolean
} & Partial<YbProgramOffsets>

const MainSettings = (props: Props) => {
  const [showCreateButton, setShowCreatebutton] = useState<boolean>(false)
  const [showCreationError, setShowCreationError] = useState<boolean>(false)
  const [editSection, setEditSection] = useState<EditSection>()
  const [coreAwardLevelSuccessAlert, setCoreAwardLevelSuccessAlert] =
    useState(false)

  const { data, startPolling, stopPolling } = useGetProgramOffsetsByProgramId(
    props.programId
  )
  const [patchOffsets] = usePatchOffsets(props.programId)
  const [createDefaultProgram, { error }] = useCreateDefaultProgram(
    props.programId
  )
  const patchProgramConfig = usePatchProgram(props.programId)
  const handleAlertClose = () => setShowCreationError(false)
  const handleCancel = useCallback(
    () => setEditSection(undefined),
    [setEditSection]
  )

  useEffect(() => {
    startPolling(1000)
  }, [startPolling])

  useEffect(() => {
    if (coreAwardLevelSuccessAlert)
      setTimeout(() => {
        setCoreAwardLevelSuccessAlert(false)
      }, 6000)
  }, [coreAwardLevelSuccessAlert])

  useEffect(() => {
    const timer = setTimeout(() => {
      stopPolling()
      if (!data?.program) {
        setShowCreationError(true)
        setShowCreatebutton(true)
      }
    }, 4000)
    return () => clearTimeout(timer)
  }, [stopPolling, data?.program])

  useEffect(() => {
    if (data?.program) stopPolling()
  }, [data?.program, stopPolling])

  const resubmitProgramCreation = () => {
    setShowCreatebutton(false)
    setShowCreationError(false)
    createDefaultProgram()
  }

  const commonProps: CommonProps = useMemo(
    () => ({
      onCancel: handleCancel,
      hideEdit: Boolean(editSection),
      ...data,
    }),
    [handleCancel, editSection, data]
  )

  const handlePatchOffsets = async (input: OffsetsInput) => {
    await patchOffsets({
      variables: { input: { ...input, programId: props.programId } },
    })
    handleCancel()
  }

  const handlePatchProgram = async ({
    yearbookLocales,
    ...input
  }: OnSubmitForm) => {
    const existing = commonProps.program?.yearbookLocales ?? []
    const added = yearbookLocales.filter((locale) => !existing.includes(locale))
    const removed = existing.filter(
      (locale) => !yearbookLocales.includes(locale)
    )
    const isNew = Boolean(!commonProps.program)

    await patchProgramConfig(
      {
        programConfigInput: {
          ...input,
          id: props.programId,
          ...(isNew && { programStatus: 'BUILDING' }),
        },
        ...(added.length > 0 && {
          addLocalesInput: {
            programId: props.programId,
            yearbookLocales: added,
          },
        }),
        ...(removed.length > 0 && {
          removeLocalesInput: {
            programId: props.programId,
            yearbookLocales: removed,
          },
        }),
      },
      isNew
    )
    handleCancel()
  }

  if (error) {
    return (
      <Alert severity="error" data-testid="manual-creation-error">
        Something has gone wrong and we are not able to display the program
        settings. Please reach out to support for assistance.
      </Alert>
    )
  }

  if (!commonProps.program && !showCreateButton && !showCreationError) {
    return <Loading />
  }

  return (
    <>
      {showCreationError && (
        <Alert
          severity="error"
          onClose={handleAlertClose}
          data-testid="automatic-creation-error"
        >
          Something has gone wrong and we are not able to display the program
          settings. Please wait for a moment and click the button below to add
          them manually.
        </Alert>
      )}
      {showCreateButton && (
        <Button
          sx={{ width: 192 }}
          onClick={resubmitProgramCreation}
          data-testid="manual-creation-button"
        >
          Add program settings
        </Button>
      )}
      {!showCreateButton && !showCreationError && (
        <StylesProvider>
          <Root data-testid="mainSettingsWrapper">
            {editSection === 'general' ? (
              <GeneralProgramSettingsEdit
                {...commonProps}
                onSubmit={handlePatchProgram}
              />
            ) : (
              <GeneralProgramSettings
                onEditClick={() => setEditSection('general')}
                {...commonProps}
              />
            )}
            <CelebrationGroupView
              onEditGroupClick={props.onEditGroupClick}
              {...commonProps}
            />
            {(commonProps.program?.celebrationLevelGroupConfigs || []).some(
              (c) => c.accrualPointsForServiceEnabled
            ) && (
              <CelebrationGroupPointsView
                {...commonProps}
                onEditAccrualPointRegion={props.onEditAccrualPointRegion}
                onAddRegion={props.onAddRegion}
              />
            )}
            {editSection === 'pointLevelConfig' &&
            (commonProps.program?.celebrationLevelGroupConfigs || []).some(
              (c) => c.userSelectedOrder
            ) ? (
              <PointLevelConfigurationEdit
                setAlert={setCoreAwardLevelSuccessAlert}
                customerUuid={commonProps.program?.coreProgram.customer.id}
                celebrationLevelGroupConfigs={commonProps.program?.celebrationLevelGroupConfigs.filter(
                  (c) => c.userSelectedOrder && c.milestone
                )}
                onCancel={() => setEditSection(undefined)}
              />
            ) : (
              (commonProps.program?.celebrationLevelGroupConfigs || []).some(
                (c) => c.userSelectedOrder
              ) && (
                <PointLevelConfigurationView
                  alert={coreAwardLevelSuccessAlert}
                  celebrationLevelGroupConfigs={commonProps.program?.celebrationLevelGroupConfigs.filter(
                    (c) => c.userSelectedOrder && c.milestone
                  )}
                  onEditClick={() => setEditSection('pointLevelConfig')}
                />
              )
            )}
            {editSection === 'scheduling' ? (
              <SchedulingForm {...commonProps} onSubmit={handlePatchOffsets} />
            ) : (
              <SchedulingSettings
                onEditClick={() => setEditSection('scheduling')}
                {...commonProps}
              />
            )}
          </Root>
        </StylesProvider>
      )}
    </>
  )
}

export default MainSettings
