import ConfirmModal from './ConfirmModal';
import { BaseModalProps } from '../types';

const ReAssignRoleModal = (modalProps: BaseModalProps) => {
  return (
    <ConfirmModal
      {...modalProps}
      title="Re-assign role"
      message="Are you sure you want to re-assign this role?"
      confirmText="Re-assign role"
      onConfirm={() => {
        modalProps.setOpen(false);
        modalProps.onConfirm();
      }}
    />
  );
};

export default ReAssignRoleModal;
