import { RedepositTab } from '@octanner/cc-admin-ucat-config-ui';
import { withPad } from '../../common/BasicPadding';
import { useCurrentIdentity } from '../user-auth/hooks/identity';

const OrdersPage = () => {
  const { data } = useCurrentIdentity();
  return (
    <div>
      <RedepositTab userInfo={data?.currentIdentity as any} />
    </div>
  );
};

export default withPad()(OrdersPage);
