import { MenuDots, Pencil,  Trash } from "@octanner/prism-icons";
import  styled  from "@emotion/styled";
import { Typography, Button } from "@octanner/prism-core";

export const CoreValuesContainer = styled.div`
    width: 75%;
`;

export const Header = styled(Typography)`
    margin-bottom: 16px;
    margin-top: 24px;
`;
export const HeaderContainer = styled.div`
  display : flex;
  margin-top : 8px;
`;

export const CancelButton = styled(Button)`
  margin-left : 8px;
`;

export const ListHeader = styled(Typography)`
  margin-top : 16px;
`;

export const ListContainer = styled.div`
  margin-top : 32px;
`;

export const FlexContainer = styled.div`
  display : flex;
  justify-content : space-between;
`;

export const ListItemContainer = styled.div`
  display : flex;
  justify-content : space-between;
`;

export const SwitchContainer = styled.div`
  display : flex;
`;

export const SwitchLabel = styled(Typography)`
  display : flex;
  align-items : center;
  margin : 8px 0px 0px 8px;
`;

export const StyledPencil = styled(Pencil)`
  margin-right : 8px;
`;

export const StyledTrash = styled(Trash)`
  margin-right : 8px;
`;

export const StyledMenuDots = styled(MenuDots)`
  margin-right : 8px;
  margin-top : 30px;
`;

export const SaveContainer = styled.div`
  display : flex;
  height : fit-content;
  margin-top : 7px;
  margin-left : 16px;
`;

export const SaveButton = styled(Button)`
  margin-right : 8px;
`;

export const TextFieldContainer = styled.div`
  width : 456px;
  display : flex;
  align-items : center;
  
`;

export const EditModeContainer = styled.div`
  display : flex;
  align-items : center;
  margin : 16px 0px;
`;

export const DisplayModeContainer = styled.div`
  display : flex;
  align-items : center;
  margin : 12px 0px 16px 0px;
`;
