import {
  CardHeaderColor,
  DefaultStatus,
  IconAndTypography,
  LabelContainer,
  LockMB,
  ShoppingCartMB,
  SingleCardContainer,
  TypographyText,
} from './styles';
import { Button, CardContent, Typography } from '@octanner/prism-core';
import { useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { formatBankType, formatBillingType } from './hooks';
import StatusLabel from '../../../common/statusLabel';
import { CustomerContext } from '../../../utils/context/CustomerContext';
import { SingleCardProps } from './types';
import { Gift } from '@octanner/prism-icons';

const SingleCard = (props: SingleCardProps) => {
  const { uuid, name, status, billConfig, programCount, defaultBank } = props;
  const type = props.bankType ? formatBankType(props.bankType) : '';
  const billType = formatBillingType(billConfig.billType);

  const {
    customer: { id: customerId },
  } = useContext(CustomerContext);

  return (
    <>
      <SingleCardContainer>
        <CardHeaderColor
          title={<Typography variant="h2">{name}</Typography>}
          action={
            <Button
              variant="text"
              data-test="view-bank:{uuid}"
              component={RouterLink}
              // @ts-ignore prism issue
              to={`/customer/${customerId}/banks/${uuid}/edit`}
              underline="none"
            >
              Edit
            </Button>
          }
        />
        <CardContent>
          <OptionalDiv value={type} />
          <IconAndTypography>
            <ShoppingCartMB fontSize="small" />
            <TypographyText>{billType}</TypographyText>
          </IconAndTypography>
          <IconAndTypography>
            <Gift fontSize="small" />
            <TypographyText>{programCount} programs attached</TypographyText>
          </IconAndTypography>
          <LabelContainer>
            <StatusLabel status={status} dataTest={`banks:${uuid}:status`} />
            {defaultBank === true ? (
              <DefaultStatus variant="archived">Default</DefaultStatus>
            ) : (
              ''
            )}
          </LabelContainer>
        </CardContent>
      </SingleCardContainer>
    </>
  );
};

export default SingleCard;

const OptionalDiv = ({ value }: { value: string }) => {
  if (value === '') return null;
  return (
    <IconAndTypography>
      <LockMB fontSize="small" />
      <TypographyText>{value}</TypographyText>
    </IconAndTypography>
  );
};
