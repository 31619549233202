import { gql } from '@apollo/client';

export const CORE_BUSINESS_UNIT_FRAGMENT = gql`
  fragment CoreBusinessUnitFragment on BusinessUnit {
    uuid: buUuid
    code: buCode
    name: buName
    archivedTsz
  }
`;

export const SEARCH_BUSINESS_UNITS = gql`
  ${CORE_BUSINESS_UNIT_FRAGMENT}
  query searchBusinessUnits($input: SearchBusinessUnitsInput!) {
    searchBusinessUnits(input: $input) {
      businessUnits {
        ...CoreBusinessUnitFragment
      }
    }
  }
`;
