import { useLazyQuery } from '@apollo/client';
import React, { createContext, PropsWithChildren, useMemo, useState } from 'react';
import { jobsDurationType, translationCategories, translationStatusTabs } from '../utils/constants';
import { defaults } from '../utils/default';
import { JOB_LIST_BY_FILTER } from '../utils/query';
import { AlertMessage, JobManagementContextType, JobManagementFilterParams, JobSearchData, PageConfig, TranslationJob } from '../utils/types';

const JobManagementContext = createContext<JobManagementContextType>({
  searchJobsByFilter: () => { },
  searchLoading: false,
  dataNotFound: false,
  setDataNotFound: () => { },
  messages: [],
  setMessageText: () => { },
  jobSearchDataList: [],
  setJobSearchDataList: () => { },
  translationStatus: 'PENDING',
  setTranslationStatus: () => { },
  pageConfig: null,
  setPageConfig: () => { },
  searchedTranslationCategory: 'LANGUAGE_STRING',
  setSearchedTranslationCategory: () => { },
  filterParams: null,
  updateFilterParams: () => { },
  refetchJobsByFilter: () => { },
  jobSearchData: null
});

const JobManagementProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {

  const [jobSearchData, setJobSearchData] = useState<any>(null);
  const [dataNotFound, setDataNotFound] = useState<boolean>(null);
  const [pageConfig, setPageConfig] = useState<PageConfig>({ pageNumber: 1, itemsPerPage: 10 });
  const [translationStatus, setTranslationStatus] = useState<string>(translationStatusTabs.PENDING.value);
  const [searchedTranslationCategory, setSearchedTranslationCategory] = useState(translationCategories.LANGUAGE_STRING.value);
  const [jobSearchDataList, setJobSearchDataList] = useState<TranslationJob[]>([]);
  const [messages, setMessages] = useState<Partial<AlertMessage>[]>([
    { key: 'errorMessage', text: '', severity: 'error', gridSize: 11 },
    { key: 'successMessage', text: '', severity: 'success', gridSize: 12},
    { key: 'deleteSuccessMessage', text: '', severity: 'success', gridSize: 6}
  ]);

  const [searchJobsByFilter, { loading: searchLoading, refetch: refetchJobsByFilter }] =
    useLazyQuery(JOB_LIST_BY_FILTER, {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache',
      onCompleted: (data: JobSearchData) => {
        if (!data?.lsFetchJobList?.translationJobList?.length) {
          setDataNotFound(true);
          setJobSearchDataList([]);
        } else {
          if (data?.lsFetchJobList?.translationJobList?.length > 0) {
            setJobSearchDataList(data?.lsFetchJobList?.translationJobList.map((jobData) => {
              return {
                ...jobData,
                uploading: false
              }
            }));
          }
        }
        setJobSearchData(data);
      },
      onError: () => {
        setMessageText('errorMessage', defaults.fetch_job_management_error);
        setJobSearchDataList([]);
        setJobSearchData(null);
      }
    });

  const setMessageText = (messageKey: string, text: string) => {
    setMessages(prevMessages =>
      prevMessages.map(message =>
        message.key === messageKey ? { ...message, text: text } : message
      )
    );
  };

  const [filterParams, setFilterParams] = useState<JobManagementFilterParams>({
    translationCategory: translationCategories.LANGUAGE_STRING.value,
    durationType: jobsDurationType[3].value,
    stringId: null,
    mmId: null,
    searchLocale: 'All',
    jobNumber: null
  });

  const updateFilterParams = (paramName: string, value: string | number) => {
    setFilterParams(prevParams => {
      return {
        ...prevParams,
        [paramName]: value
      }
    })
  };

  const contextValue = useMemo(() => ({
    searchLoading, searchJobsByFilter,
    dataNotFound, setDataNotFound,
    jobSearchData,
    jobSearchDataList, translationStatus,
    setTranslationStatus, setJobSearchDataList,
    pageConfig, setPageConfig,
    messages, setMessageText,
    filterParams, updateFilterParams, refetchJobsByFilter,
    searchedTranslationCategory, setSearchedTranslationCategory
  }), [searchLoading, searchJobsByFilter, dataNotFound, refetchJobsByFilter, jobSearchData,
    setDataNotFound, jobSearchDataList, translationStatus, messages, setMessageText,
    setTranslationStatus, pageConfig, setPageConfig, setJobSearchDataList,
    filterParams, updateFilterParams, searchedTranslationCategory, setSearchedTranslationCategory]);

  return (
    <JobManagementContext.Provider value={contextValue}>
      {children}
    </JobManagementContext.Provider>
  );
};

export { JobManagementContext, JobManagementProvider };

