import styled from '@emotion/styled';
import { Box, CircularProgress, Pagination, Tab, Table, TableBody, TableCell, TableHead, TableRow, Tabs, Typography } from '@octanner/prism-core';
import React, { useContext, useState } from 'react';
import { JobManagementContext } from '../../Contexts/JobManagementContext';
import { ActionType, translationStatusTabs } from '../../utils/constants';
import { defaults } from '../../utils/default';
import JobManagementRow from './JobManagementRow';
import DeleteJobModal from './Modal/DeleteJobModal';
import SendForTranslationModal from './Modal/SendForTranslationModal';
import UploadTranslationModal from './Modal/UploadTranslationModal';
import ViewJobModal from './Modal/ViewJobModal';

const TabsContainer = styled(Box)`
  margin-top: 48px;
  border-bottom: 1px solid #e0e0e0;
`;

const StatusTab = styled(Tab)`
  min-width: 120px !important;
`;

const StyledCircularProgress = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: 45vh;
`;

const JobTable = styled(Table)`
  margin-top: 24px;
`;

const PaginationContainer = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 4px;
  align-self: stretch;
`;

const NoDataBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30vh;
`;

const JobManagementTable = ({ fetch }) => {

  const { searchLoading, jobSearchData, setPageConfig, pageConfig, dataNotFound,
    translationStatus, setTranslationStatus, searchedTranslationCategory,
    setJobSearchDataList, jobSearchDataList, setMessageText,
    refetchJobsByFilter } = useContext(JobManagementContext);

  const [actionJobNumber, setActionJobNumber] = useState<number>();
  const [localeForJob, setLocaleForJob] = useState<string>('');
  const [dateForSend, setDateForSend] = useState<string>('');

  const [showViewJobModal, setShowViewJobModal] = useState<boolean>(false);
  const [showDeleteJobModal, setShowDeleteJobModal] = useState<boolean>(false);
  const [showUploadTranslationModal, setShowUploadTranslationModal] = useState<boolean>(false);
  const [showSendForTranslationModal, setShowSendForTranslationModal] = useState<boolean>(false);

  const { job_id, language, job_created, sent_to_translation, translations_uploaded, rows_per_page } = defaults;

  const uploadFile: (selectedFile: File) => Promise<void> = async (selectedFile) => {
    toggleUploading(true);
    setMessageText('successMessage', '');
    setMessageText('errorMessage', '');
    setShowUploadTranslationModal(false);
    const formData = new FormData();
    formData.append('file', selectedFile);
    formData.append('jobNumber', actionJobNumber.toString());
    formData.append('translationCategory', searchedTranslationCategory)
    try {
      const response = await fetch(`/api/language/translation/upload`, {
        method: 'POST',
        body: formData,
      });
      if (response.ok) {
        setMessageText('successMessage', defaults.upload_translation_success);
        refetchJobsByFilter();
      } else {
        setMessageText('errorMessage', defaults.upload_translation_error);
      }
      toggleUploading(false);
    } catch (error) {
      setMessageText('errorMessage', defaults.upload_translation_error);
      toggleUploading(false);
    }
  };

  const toggleUploading = (uploading: boolean) => {
    setJobSearchDataList((prevList) => {
      return prevList.map((jobData) => {
        if (jobData.jobNumber === actionJobNumber) {
          jobData.uploading = uploading;
        }
        return jobData;
      });
    });
  }

  const performJobAction = (jobNumber: number, actionType: ActionType, localeForJob?: string, dateForSend?: string) => {
    setActionJobNumber(jobNumber);
    if (actionType === ActionType.VIEW) {
      setShowViewJobModal(true);
    }
    if (actionType === ActionType.DELETE) {
      setShowDeleteJobModal(true);
    }
    if (actionType === ActionType.UPLOAD) {
      setShowUploadTranslationModal(true);
    }
    if (actionType === ActionType.SEND) {
      setLocaleForJob(localeForJob);
      setDateForSend(dateForSend);
      setShowSendForTranslationModal(true);
    }
  }

  return (<>
    {showViewJobModal && <ViewJobModal showViewJobModal={showViewJobModal} jobNumber={actionJobNumber}
      setShowViewJobModal={setShowViewJobModal} translationCategory={searchedTranslationCategory}></ViewJobModal>}
    {showDeleteJobModal && <DeleteJobModal showDeleteJobModal={showDeleteJobModal}
      jobNumber={actionJobNumber} setShowDeleteJobModal={setShowDeleteJobModal}></DeleteJobModal>}
    {showUploadTranslationModal && <UploadTranslationModal showUploadTranslationModal={showUploadTranslationModal}
      uploadFile={uploadFile}
      setShowUploadTranslationModal={setShowUploadTranslationModal}></UploadTranslationModal>}
    {showSendForTranslationModal && <SendForTranslationModal showSendForTranslationModal={showSendForTranslationModal} locale={localeForJob}
      jobNumber={actionJobNumber} dateForSend={dateForSend}
      setShowSendForTranslationModal={setShowSendForTranslationModal}></SendForTranslationModal>}
    <TabsContainer>
      <Tabs textColor='inherit' indicatorColor='primary' variant='fullWidth'
        value={translationStatus} onChange={(_event, newValue) => setTranslationStatus(newValue)}>
        {Object.values(translationStatusTabs).map(status => (
          <StatusTab disabled={searchLoading || jobSearchDataList.some((job) => job.uploading)}
            key={status.value}
            label={status.label}
            value={status.value}
          />
        ))}
      </Tabs>
    </TabsContainer>
    {!searchLoading && !dataNotFound && jobSearchData?.lsFetchJobList?.translationJobList.length > 0 &&
      <>
        <JobTable>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant='h4'>{job_id}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant='h4'>{language}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant='h4'>{job_created}</Typography>
              </TableCell>
              {(translationStatus === translationStatusTabs.IN_PROGRESS.value
                || translationStatus === translationStatusTabs.COMPLETED.value) && <TableCell>
                  <Typography variant='h4'>{sent_to_translation}</Typography>
                </TableCell>}
              {translationStatus === translationStatusTabs.COMPLETED.value && <TableCell>
                <Typography variant='h4'>{translations_uploaded}</Typography>
              </TableCell>}
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {jobSearchDataList.map((translationJob) => (
              <JobManagementRow
                key={translationJob.jobNumber}
                translationJob={translationJob}
                disabled={jobSearchDataList.some((job) => job.uploading && job.jobNumber !== translationJob.jobNumber)}
                performJobAction={performJobAction} />))}
          </TableBody >
        </JobTable >
        {jobSearchData.lsFetchJobList.pageInfo.totalItemCount > 10 && <PaginationContainer>
          <Pagination
            disabled={searchLoading || jobSearchDataList.some((job) => job.uploading)}
            size='small'
            rowsPerPage
            menuProps={{ value: rows_per_page }}
            page={pageConfig.pageNumber}
            itemsPerPageOptions={[10, 30, 50]}
            itemsPerPage={pageConfig.itemsPerPage}
            count={jobSearchData.lsFetchJobList.pageInfo.totalPageCount}
            onChange={(event, page) => setPageConfig({ pageNumber: page, itemsPerPage: pageConfig.itemsPerPage })}
            onItemsPerPageChange={(itemsPerPage) => setPageConfig({ pageNumber: pageConfig.pageNumber, itemsPerPage })}
          />
        </PaginationContainer>}
      </>}
    {!searchLoading && dataNotFound && <NoDataBox>
      <Typography color="textSecondary">{defaults.no_match_data}</Typography>
    </NoDataBox>}
    {searchLoading && <StyledCircularProgress>
      <CircularProgress />
    </StyledCircularProgress>}
  </>);
}

export default JobManagementTable;