import { Switch } from '@octanner/prism-core';
import { BoldTypo, FlexDiv } from './styles';
import { SortableList } from './sortableList';
import { DraggableRowProps } from './types';

const DraggableRow = ({ item, onChange }: DraggableRowProps) => {
  const { translationStringId, fieldName, enabled } = item;
  return (
    <SortableList.Item id={item.translationStringId}>
      <FlexDiv>
        <SortableList.DragHandle />
        <BoldTypo data-testid={`universal-search:title:${translationStringId}`}>
          {fieldName}
        </BoldTypo>
      </FlexDiv>
      <Switch
        data-testid={`universal-search:switch:${translationStringId}`}
        checked={enabled}
        onChange={(event, checked) =>
          onChange(event, checked, translationStringId)
        }
      />
    </SortableList.Item>
  );
};
export default DraggableRow;
