import styled from '@emotion/styled';
import { Alert, CircularProgress, Grid, Typography } from "@octanner/prism-core";
import React, { useContext, useEffect } from 'react';
import { Locale, unTranslatedLocale } from 'utils/types';
import { LocaleContext } from '../../Contexts/LocaleContext';
import { TranslationRequestContext } from '../../Contexts/TranslationRequestContext';
import { defaults } from '../../utils/default';
import SelectLocale from './SelectLocale';
import TranslationsRequestTable from './TranslationsRequestTable';

const Container = styled.div`
  margin-top: 24px;
  margin-left: 40px;
  margin-right: 40px;
  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
`
const TranslationRequestGrid = styled(Grid)`
  margin-top: 24px;
  border: 1px solid #E1E1E1;
  border-radius: 3px;
  height: 85vh;
`
const StyledCircularProgress = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const MessageAlert = styled(Alert)`
  margin-top: 12px;
`

const TranslationRequest: React.FC = () => {

  const { localeLoading, locales } = useContext(LocaleContext);
  const { selectedLocale, fetchUntranslatedAllLocaleCount, setSelectedLocale } = useContext(TranslationRequestContext);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [newModifiedLocales, setNewModifiedLocales] = React.useState<Locale[]>([]);

  useEffect(() => {
    if (locales && locales.length > 0) {
      const sortedLanguages = [...locales].sort((a, b) => {
        return a.countryDescription.localeCompare(b.countryDescription);
      }
      );
      const enUSIndex = sortedLanguages.findIndex(language => language.code === 'en-US');
      if (enUSIndex !== -1) {
        const enUSLanguage = sortedLanguages.splice(enUSIndex, 1)[0];
        sortedLanguages.push(enUSLanguage);
        setNewModifiedLocales(sortedLanguages);
      }
      const newSelectedLocale: unTranslatedLocale = {
        locale: sortedLanguages[0].code,
        materialDescriptionCount: null,
        customTextCount: null,
        languageStringCount: null
      };
      setSelectedLocale(newSelectedLocale);
    }
  }, [locales]);

  useEffect(() => {
    if (selectedLocale && selectedLocale.languageStringCount === null) {
      fetchUntranslatedAllLocaleCount();
    }
  }, [selectedLocale]);

  return (
    <Container>
      <Typography variant='h1'>
        {defaults.translation_request}
      </Typography>
      <Typography>
        {defaults.translation_request_sub_title}
        {errorMessage?.length > 0 &&
          <MessageAlert onClose={() => setErrorMessage('')} severity="error">{errorMessage}</MessageAlert>}
      </Typography>
      {!localeLoading && locales?.length > 0 &&
        <TranslationRequestGrid container spacing={3}>
          <SelectLocale locales={newModifiedLocales} />
          {selectedLocale && <TranslationsRequestTable />}
        </TranslationRequestGrid>}
      {(localeLoading) && <StyledCircularProgress>
        <CircularProgress />
      </StyledCircularProgress>}
    </Container>);
}
export default TranslationRequest;