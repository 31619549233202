import styled from '@emotion/styled';
import { Checkbox, IconButton, Select, Typography } from '@octanner/prism-core';
import colors from '../../../../../common/constants/Colors';

export const AlignedTextBox = styled.div`
  display: flex;
  align-items: center;
  padding-left: 60px;
`;

export const FlexCenterDiv = styled.div`
  display: flex;
  align-items: center;
`;

export const FlexColumnDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: -10px;
`;

export const SpecificWidthSelect = styled(Select)`
  width: 330px;
  margin: 4px;
`;

export const VerticalAlignedIconButton = styled(IconButton)`
  vertical-align: top;
  margin-right: 8px;
  margin-left: 10px;
`;

export const SpecificWidthCell = styled.div`
  width: 480px;
  display: flex;
  flex-direction: row;
`;

export const MainText = styled(Typography)`
  font-weight: bold;
`;

export const SalesOrgTitle = styled(Typography)`
  margin-top: 24px;
`;

export const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 9px 0px 16px 0px;
  margin-bottom: 1px;
`;

export const ProgramTitle = styled(Typography)`
  margin-bottom: 4px;
  margin-top: 42px;
`;

export const ProgramSubTitle = styled(Typography)`
  margin-bottom: 12px;
  color: ${colors.black};
`;

export const CheckboxMargin = styled(Checkbox)`
  margin-right: 4px;
`;
