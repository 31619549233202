import { useStyles } from './styles';
import { Button, Link, List, Typography } from '@octanner/prism-core';
import { ActionDialog as Dialog } from '../../common/BinaryDialog';
import { useContext, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Link as RouterLink, useRouteMatch } from 'react-router-dom';
import permissionAuthorizer from '../../utils/auth/PermissionAuthorizer';
import BasicPadding from '../../common/BasicPadding';
import ProtectedFeature from '../../common/ProtectedFeature';
import { CustomerContext } from '../../utils/context/CustomerContext';
import SnackbarPopup from '../../common/snackbar/snackbar';
import {
  DELETE_ROLE,
  DUPLICATE_ROLE,
  updateRoleFromCache,
} from './graphql/roles';
import { useGetRoles } from './hooks/role';
import {
  DeleteRoleInput,
  DeleteRoleResponse,
  DuplicateRoleInput,
  DuplicateRoleResponse,
  Role,
  RolesResponse,
} from './models/Role';
import RoleItem from './RoleItem';
import Loading from '../../common/Loading';

const Roles = () => {
  const match = useRouteMatch();
  const classes = useStyles();
  const {
    customer: { id: customerId },
  } = useContext(CustomerContext);
  const queryVariables = { customerId };
  const [role, setRole] = useState<Role>();
  const [roles, setRoles] = useState<RolesResponse>();
  const { data, loading, fetchMore } = useGetRoles(queryVariables.customerId);
  const [deleting, setDeleting] = useState(false);
  const [error, setError] = useState({ err: false, msg: '' });
  const [deleteRole] = useMutation<DeleteRoleResponse, DeleteRoleInput>(
    DELETE_ROLE,
    {
      update: updateRoleFromCache(queryVariables),
    }
  );

  const getAll = async () => {
    if (data !== undefined) {
      setRoles(data);
      if (data.roles.last === false) {
        const newRoleList: RolesResponse = {
          ...data,
          roles: {
            ...data.roles,
            content: await fetchMore(),
          },
        };
        setRoles(newRoleList);
      }
    }
  };
  useEffect(() => {
    getAll();
    //eslint-disable-next-line
  }, [data]);

  const [duplicateRole] = useMutation<
    DuplicateRoleResponse,
    DuplicateRoleInput
  >(DUPLICATE_ROLE, {
    update: updateRoleFromCache(queryVariables),
  });

  const handleDelete = async (role: Role) => {
    if (!customerId) {
      return;
    }
    setDeleting(true);
    await deleteRole({ variables: { customerId, id: role.id } });
    setDeleting(false);
    setRole(undefined);
  };
  const handleDuplicate = async ({ id, name }: Role) => {
    try {
      const newName = `${name} (Copy)`;
      await duplicateRole({
        variables: { name: newName, existingRoleId: id, customerId },
      });
    } catch (err) {
      setError({
        err: true,
        msg: 'An Error Occured. Please, try again.',
      });
    }
  };

  return (
    <BasicPadding>
      <Typography variant="h1" className={classes.header}>
        Roles and Permissions
      </Typography>
      <Typography variant="h3" className={classes.leftPadded}>
        Role
      </Typography>
      <List>
        {loading && !roles && <Loading />}
        {roles?.roles.content.map((role) => (
          <RoleItem
            role={role}
            key={role.uuid}
            onDuplicate={() => handleDuplicate(role)}
            onDelete={() => setRole(role)}
          />
        ))}
      </List>
      <ProtectedFeature
        authorizer={permissionAuthorizer('ROLE_DELEGATOR_ROLES_CREATE')}
      >
        <Link
          component={RouterLink}
          className={classes.leftPadded}
          // @ts-ignore
          to={`${match.url}/new`}
          data-test="role:new"
        >
          Create new role
        </Link>
      </ProtectedFeature>
      <Dialog
        actions={
          <>
            {role && (
              <Button
                disabled={deleting}
                data-test="role:delete:confirm"
                onClick={() => handleDelete(role)}
                adminColor
              >
                Delete Role
              </Button>
            )}
            <Button
              disabled={deleting}
              onClick={() => setRole(undefined)}
              color="secondary"
            >
              Cancel
            </Button>
          </>
        }
        onClose={() => setRole(undefined)}
        open={Boolean(role)}
        title="Delete Role Confirmation"
      >
        <>
          <Typography>
            Are you sure you want delete role {role?.name}?
          </Typography>
          {deleting && <Loading height={48} size={32} />}
        </>
      </Dialog>
      <SnackbarPopup
        open={error.err}
        severity="error"
        handleClose={() => setError({ err: false, msg: '' })}
        text={error.msg}
      />
    </BasicPadding>
  );
};

export default Roles;
