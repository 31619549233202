import { Switch } from '@octanner/prism-core';
import { ActionDialog as Dialog } from '../../../common/BinaryDialog';
import { Button, Typography } from '@octanner/prism-core';
import SnackbarPopup from '../../../common/snackbar/snackbar';
import {
  FlexBetweenDiv,
  TitleTypography,
  SubtitleTypography,
  Mb24Typography,
  LineBreak,
} from './styles';
import {
  useGetCustomerWelcomeEmailToggle,
  useUpdateCustomerWelcomeEmailToggle,
} from './graphql';
import { CustomerContext } from '../../../utils/context/CustomerContext';
import { useContext, useEffect, useState } from 'react';
import Loading from '../../../common/Loading';
import { communications } from './constants';
import { Communication } from './types';
import { AppContext } from '../../../utils/context/AppContext';
import permissionAuthorizer from '../../../utils/auth/PermissionAuthorizer';
import { useFlags } from 'launchdarkly-react-client-sdk';
import BasicPadding from '../../../common/BasicPadding';

const Communications = () => {
  const {
    customer: { id: customerId },
  } = useContext(CustomerContext);
  const appContext = useContext(AppContext);
  const authorizor = permissionAuthorizer('COS_CUSTOMER_WRITE');
  const isPermissionAllowed = authorizor(appContext, customerId);
  const [welcomeEmailsEnabled, setWelcomeEmailsEnabled] = useState(false);
  const { data, loading } = useGetCustomerWelcomeEmailToggle(
    customerId,
    'CC_WELCOME_EMAIL'
  );
  const [welcomeEmailUpdating, setWelcomeEmailUpdating] = useState(false);
  const [error, setError] = useState('');
  const [updateWelcomeEmailToggle] = useUpdateCustomerWelcomeEmailToggle(
    customerId,
    !welcomeEmailsEnabled,
    'CC_WELCOME_EMAIL'
  );
  const { ccAdminSystemSettings } = useFlags<{
    ccAdminSystemSettings: boolean;
  }>();

  useEffect(() => {
    const val =
      data?.cosCustomerNotificationType.customerNotificationType.enabledTsz;
    setWelcomeEmailsEnabled(val ? true : false);
  }, [data]);

  const welcomeEmailSwitch = async () => {
    await updateWelcomeEmailToggle()
      .then((response) => {
        const switchedOn =
          response.data?.cosToggleCustomerNotificationType
            .customerNotificationType.enabledTsz;
        if (switchedOn) {
          setWelcomeEmailsEnabled(true);
        } else {
          setWelcomeEmailsEnabled(false);
        }
      })
      .catch((e) => {
        if (isPermissionAllowed) setError('Error: Unable to make the request.');
        else
          setError(
            'You don’t have the proper permissions to perform this action.'
          );
      });
    setWelcomeEmailUpdating(false); // close modal
  };

  const handleWelcomeEmailToggle = async () => {
    if (!customerId) {
      return;
    }
    // if it's on, turn off
    if (welcomeEmailsEnabled) {
      welcomeEmailSwitch();
    } else {
      // if it's off, display modal to verify action before turning on
      setWelcomeEmailUpdating(true);
    }
  };

  if (loading) return <Loading />;

  return (
    <BasicPadding padding={ccAdminSystemSettings ? 'xs' : 'lg'}>
      {!ccAdminSystemSettings && (
        <>
          <Mb24Typography variant="h1">Communications</Mb24Typography>
          <LineBreak />
        </>
      )}

      {communications.map(
        ({ key, title, description, type, editable }: Communication) => (
          <FlexBetweenDiv key={key}>
            <div>
              <TitleTypography variant="h3">{title}</TitleTypography>
              <SubtitleTypography variant="subtitle2">
                {description}
              </SubtitleTypography>
            </div>
            <div>
              {type === 'boolean' && (
                <Switch
                  data-testId="welcome-email-switch"
                  checked={welcomeEmailsEnabled}
                  disabled={!editable}
                  onChange={() => handleWelcomeEmailToggle()}
                />
              )}
            </div>
          </FlexBetweenDiv>
        )
      )}
      <Dialog
        actions={
          <>
            <Button
              data-test="welcome:activate:confirm"
              onClick={() => welcomeEmailSwitch()}
              adminColor
            >
              Send
            </Button>
            <Button
              onClick={() => setWelcomeEmailUpdating(false)}
              color="secondary"
            >
              Cancel
            </Button>
          </>
        }
        onClose={() => setWelcomeEmailUpdating(false)}
        open={Boolean(welcomeEmailUpdating)}
        title="Are you sure?"
      >
        <>
          <Typography>
            Once this feature is enabled, all new users will start receiving
            welcome emails. It should not be enabled if a client has not been
            launched yet.
          </Typography>
        </>
      </Dialog>
      <SnackbarPopup
        open={error.length > 0}
        severity="error"
        handleClose={() => setError('')}
        text={error}
      />
    </BasicPadding>
  );
};

export default Communications;
