import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Colors from '../../common/constants/Colors';
import { THEME_SPACING } from '../../common/constants/themeSpacing';
import Styles from '../../common/constants/Styles';

const boxShadow = '0px 1px 0px #DADCDD, inset 0px 1px 0px #DADCDD';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    adornmentContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      fontSize: 24,
    },
    chip: {
      color: Colors.white,
      borderRadius: 20,
      margin: 3,
      backgroundColor: Colors.blue,
    },
    nested: {
      paddingLeft: theme.spacing(8),
      boxShadow,
    },
    textContainer: {
      display: 'flex',
    },
    text: {
      marginTop: 'auto',
      marginBottom: 'auto',
      fontSize: theme.typography.overline.fontSize,
    },
    textExpand: {
      marginTop: 'auto',
      marginBottom: 'auto',
      marginLeft: theme.spacing(1),
    },
    item: {
      paddingLeft: theme.spacing(2),
      boxShadow,
      position: 'sticky',
      top: 0,
      backgroundColor: Colors.white,
      zIndex: 1,
      '&:hover': {
        backgroundColor: Colors.white,
      },
    },
    toggle: {
      marginRight: theme.spacing(3),
    },
    loadingContainer: {
      display: 'flex',
    },
    permissions: {
      marginTop: theme.spacing(THEME_SPACING.large),
      fontSize: theme.typography.overline.fontSize,
    },
    selectContainers: {
      marginTop: theme.spacing(THEME_SPACING.small),
      marginBottom: 6,
      display: 'flex',
    },
    selectText: {
      fontSize: theme.typography.overline.fontSize,
    },
    selectLoading: {
      marginLeft: theme.spacing(THEME_SPACING.small),
    },
    listItem: {
      boxShadow: Styles.boxShadowTopAndBottom,
    },
    action: {
      paddingLeft: theme.spacing(THEME_SPACING.large),
    },
    clickable: {
      cursor: 'pointer',
    },
    deleteRole: {
      color: Colors.red,
    },
    roleName: {
      marginBottom: 25,
    },
    roleNameLabel: {
      fontSize: theme.typography.overline.fontSize,
      marginBottom: 6,
      display: 'block',
    },
    back: {
      display: 'block',
      marginBottom: theme.spacing(THEME_SPACING.small),
    },
    save: {
      flex: '0 0 auto',
    },
    errorAlert: {
      marginBottom: theme.spacing(THEME_SPACING.small),
    },
    header: {
      marginBottom: theme.spacing(THEME_SPACING.medium),
    },
    leftPadded: {
      marginLeft: theme.spacing(THEME_SPACING.small),
    },
  })
);
