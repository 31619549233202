import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { THEME_SPACING } from '../../common/constants/themeSpacing';
import Colors from '../../common/constants/Colors';
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 1200,
      height: '100%',
      display: 'flex',
      flex: '1 1 auto',
      flexDirection: 'column',
      '& > *': {
        flex: '0 0 auto',
      },
    },
    container: {
      display: 'flex',
      minHeight: 0,
      overflow: 'hidden',
    },
    defaultLabel: {
      marginLeft: theme.spacing(THEME_SPACING.small),
    },
    upperSpace: {
      marginTop: theme.spacing(THEME_SPACING.small),
    },
    versions: {
      marginTop: theme.spacing(THEME_SPACING.medium),
      flex: '1 1 auto',
      minHeight: '500px',
    },
    edit: {
      cursor: 'pointer',
      marginLeft: theme.spacing(THEME_SPACING.small),
      marginTop: 'auto',
      marginBottom: 'auto',
    },
    leftAuto: {
      marginLeft: 'auto',
    },
    sideContent: {
      display: 'flex',
      flexDirection: 'column',
      flex: '0 0 300px',
    },
    mainContent: {
      marginLeft: theme.spacing(THEME_SPACING.small),
      display: 'flex',
      flexDirection: 'column',
      flex: '1 1 auto',
      overflowY: 'auto',
    },
    detailsHeader: {
      display: 'flex',
      marginBottom: theme.spacing(THEME_SPACING.small),
    },
    contentContainer: {
      display: 'flex',
      flex: '1 1 1px',
      overflowY: 'auto',
    },
    editContentContainer: {
      backgroundColor: Colors.gray100,
    },
    contentVersionItemFormRoot: {
      padding: theme.spacing(THEME_SPACING.small),
      display: 'flex',
      flexDirection: 'column',
      flex: '1 1 auto',
      '& > * + *': {
        marginTop: theme.spacing(THEME_SPACING.small),
      },
    },
    editorContainer: {
      display: 'flex',
      flexDirection: 'column',
      flex: '1 1 auto',
      backgroundColor: theme.palette.background.paper,
      overflowY: 'auto',
    },
    actions: {
      display: 'flex',
      flex: '0 0 auto',
      paddingTop: theme.spacing(THEME_SPACING.small),
      paddingBottom: theme.spacing(THEME_SPACING.small),
      position: 'sticky',
      bottom: 0,
      backgroundColor: Colors.gray100,
      width: '100%',
    },
    cancelButton: {
      marginLeft: theme.spacing(THEME_SPACING.small),
    },
    leftAutoFlex: {
      marginLeft: 'auto',
      flex: '0 0 auto',
    },
    label: {
      fontSize: theme.typography.overline.fontSize,
      marginBottom: 6,
      display: 'block',
    },
    materialSwitch: {
      marginLeft: 0,
    },
    VersionRoot: {
      width: '100%',
      minHeight: 0,
      overflowY: 'auto',
    },
    listLength: { display: 'flex', flexDirection: 'column', height: '70vh' },
  })
);
