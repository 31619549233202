import { Button } from '@octanner/prism-core';
import { Participants } from '../../types';
import { ParticipationRow } from './participationRow';
import { MB16Div, MR4Button, PB16Typography } from './styles';
import { EligibilitySettingsProps } from './types';

const EligibilitySettings = ({
  participantCount = 0,
  eligibilitySettings,
  toggleSettings,
  modifiableParticipants,
  setModifiableParticipants,
  handleRemoveEligibility,
  handleAddEligability,
  handleSave,
}: EligibilitySettingsProps) => {
  const handleVerbage = (participant: Participants) => {
    switch (participant.segmentType) {
      case 'all':
        return 'all participants';
      case 'businessUnit':
        return `participants who belong to ${participant.businessUnits
          ?.map((x) => x.name)
          .join(', ')}`;
      case 'homeCountry':
        return `participants who live in ${participant.countries
          ?.map((x) => x.countryName)
          .join(', ')}`;
      case 'workCountry':
        return `participants who work in ${participant.countries
          ?.map((x) => x.countryName)
          .join(', ')}`;
    }
  };
  return (
    <>
      <PB16Typography variant="h2">Manage participants</PB16Typography>
      {!eligibilitySettings ? (
        <div>
          <PB16Typography>{participantCount} Participant(s)</PB16Typography>
          {modifiableParticipants.map((participant, index) => (
            <PB16Typography key={index}>
              {participant.isIncluded ? 'Including' : 'Excluding'}{' '}
              {handleVerbage(participant)}
            </PB16Typography>
          ))}
          <Button variant="text" onClick={() => toggleSettings(true)}>
            Update eligibility settings
          </Button>
        </div>
      ) : (
        <div>
          {modifiableParticipants.map((participant, index) => (
            <ParticipationRow
              key={index}
              participant={participant}
              index={index}
              modifiableParticipants={modifiableParticipants}
              setModifiableParticipants={setModifiableParticipants}
              handleRemoveEligibility={handleRemoveEligibility}
            />
          ))}
          <MB16Div>
            <Button variant="text" onClick={handleAddEligability}>
              Add eligability rule
            </Button>
          </MB16Div>
          <MR4Button adminColor onClick={handleSave}>
            Save Eligability Settings
          </MR4Button>
          <Button color="secondary" onClick={() => toggleSettings(false)}>
            Cancel
          </Button>
        </div>
      )}
    </>
  );
};

export default EligibilitySettings;
