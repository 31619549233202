import { gql } from '@apollo/client';
import { CORE_FEATURE_BASE_FRAGMENT } from '../../../graphql/core-feature/graphql';
import { ROLE_FRAGMENT } from './roles';

export const GET_ALL_ROLE_INFORMATION = gql`
  ${ROLE_FRAGMENT}
  ${CORE_FEATURE_BASE_FRAGMENT}
  query allRoleInformation($roleId: Long!, $customerId: UUID!) {
    role(id: $roleId, customerId: $customerId) {
      ...RoleFragment
    }
    getPermissionTotalsByRoleId(customerId: $customerId, roleId: $roleId) {
      feature {
        ...CoreFeatureBaseFragment
      }
      total
      totalCoupled
    }
  }
`;

export const GET_ROLE_IDENTITY_INFORMATION = gql`
  query roleIdentityInformation(
    $customerId: UUID!
    $roleUuid: UUID!
    $page: Int!
    $size: Int!
  ) {
    identitiesByRole(
      customerId: $customerId
      roleUuid: $roleUuid
      page: $page
      size: $size
    ) {
      content {
        id
        firstName
        lastName
      }
      last
    }
    identityGroupsByRole(
      customerId: $customerId
      roleId: $roleUuid
      page: $page
      size: $size
    ) {
      content {
        name: identityGroupName
        id: identityGroupUuid
      }
      last
      total
      totalPages
    }
  }
`;
