import { useMutation, FetchResult, MutationResult } from '@apollo/client'
import {
  GET_PROGRAM_OFFSETS_BY_PROGRAM_ID,
  YB_UPDATE_ACCRUAL_POINTS_CONFIG,
} from '.'
import { YbAccrualPointsRegion } from '../common/models/YbAccrualPointsRegion'
import {
  BaseAccrualPointsConfig,
  YbUpdateAccrualPointsConfig,
} from '../common/models/YbUpdateAccrualPointsConfig'

type Data = { ybAccrualPointsRegion: YbAccrualPointsRegion }
type Variables = {
  input: YbUpdateAccrualPointsConfig
}
type UpdateConfig = (
  programId: string,
  baseConfig: BaseAccrualPointsConfig,
  accrualPointsRegionId: string
) => Promise<FetchResult<Data>>

export default function useUpdateAccrualPointsConfig(): [
  UpdateConfig,
  MutationResult<Data>
] {
  // Pass mutation to useMutation
  const [mutate, result] = useMutation<Data, Variables>(
      YB_UPDATE_ACCRUAL_POINTS_CONFIG
    ),
    updateConfig: UpdateConfig = (
      programId,
      baseConfig,
      accrualPointsRegionId
    ) => {
      return mutate({
        variables: { input: { accrualPointsRegionId, ...baseConfig } },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: GET_PROGRAM_OFFSETS_BY_PROGRAM_ID,
            variables: { programId },
          },
        ],
      })
    }
  return [updateConfig, result]
}
