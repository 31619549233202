import { gql } from '@apollo/client';

const CORE_PT_BANK_FRAGMENT = gql`
  fragment CorePtMgmtBankInformationFragment on CorePtMgmtBankInformation {
    pointBankUuid
    pointBankName
    defaultAccruingBank
    isActive
    countPrograms
    customer {
      id
      defaultAccruingBank {
        defaultAccruingBank
      }
    }
    pointBankType
    billingConfig {
      billingType
    }
  }
`;

export const CORE_PT_BANKS_BY_CUSTOMER = gql`
  ${CORE_PT_BANK_FRAGMENT}
  query corePtMgmtGetBankInformationByCustomer(
    $customerUuid: UUID!
    $pageNumber: NonNegativeInt!
    $pageSize: PositiveInt!
  ) {
    corePtMgmtGetBankInformationByCustomer(
      request: {
        customerUuid: $customerUuid
        pagination: { pageNumber: $pageNumber, pageSize: $pageSize }
      }
    ) {
      information {
        nodes {
          ...CorePtMgmtBankInformationFragment
        }
        pageInfo {
          pageNumber
          totalPages
        }
      }
    }
  }
`;

export const CORE_PT_BANK_BY_ID = gql`
  ${CORE_PT_BANK_FRAGMENT}
  query corePtMgmtGetBankInformation(
    $customerUuid: UUID!
    $pointBankUuid: UUID!
  ) {
    corePtMgmtGetBankInformation(
      request: { customerUuid: $customerUuid, pointBankUuid: $pointBankUuid }
    ) {
      information {
        ...CorePtMgmtBankInformationFragment
      }
    }
  }
`;

export const CREATE_BANK = gql`
  mutation corePtMgmtCreateNewBank(
    $pointBankName: String!
    $customerUuid: UUID!
    $isActive: Boolean!
    $pointBankType: CorePtMgmtPointBankType!
  ) {
    corePtMgmtCreateNewBank(
      request: {
        pointBankName: $pointBankName
        customerUuid: $customerUuid
        isActive: $isActive
        pointBankType: $pointBankType
      }
    ) {
      information {
        pointBankUuid
        pointBankName
      }
    }
  }
`;
