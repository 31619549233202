import { useLazyQuery, useMutation } from '@apollo/client';
import React, { createContext, useState } from 'react';
import { durationTypes } from './../utils/constants';
import { defaults } from './../utils/default';
import { SEARCH_CUSTOM_TEXT, UPDATE_CUSTOM_TEXT } from './../utils/query';
import { AlertMessage, CustomTextContextProps, CustomTextContextType, CustomTextList, CustomTextListPayload } from './../utils/types';

const CustomTextContext = createContext<CustomTextContextType>({
  stringId: '',
  setStringId: () => { },
  searchInDuration: '',
  SetSearchInDuration: () => { },
  searchData: null,
  setSearchData: () => { },
  showFooter: false,
  setShowFooter: () => { },
  dataNotFound: false,
  addCustomTextBy: '',
  setAddCustomTextBy: () => { },
  setDataNotFound: () => { },
  searchLoading: false,
  searchRequestObject: null,
  setSearchRequestObject: () => { },
  searchCustomTextHandler: () => { },
  searchCustomText: () => { },
  messages: [],
  setMessageText: () => { },
  customTextData: null,
  setCustomTextData: () => { },
  targetLocales: [],
  setTargetLocales: () => { },
  customTextUniqueId: '',
  setCustomTextUniqueId: () => { },
  customTextRefetchInd: false,
  setCustomTextRefetchInd: () => { },
  saveCustomTextNotes: () => { },
  saveCustomTextNotesProcessing: false,
  customTextNote: '',
  setCustomTextNote: () => { },
  fetch,
  targetLocale: '',
  setTargetLocale: () => { },
  customerEmail: '',
  customerName: ''
});

const CustomTextContextProvider: React.FC<CustomTextContextProps> = ({ fetch, children, customerEmail, customerName }) => {
  const [stringId, setStringId] = useState<string>('');
  const [searchInDuration, SetSearchInDuration] = useState<string>(durationTypes[3].value);
  const [addCustomTextBy, setAddCustomTextBy] = useState<string>("customText");
  const [searchData, setSearchData] = useState<CustomTextList>(null);
  const [showFooter, setShowFooter] = useState<boolean>(false);
  const [dataNotFound, setDataNotFound] = useState<boolean>(false);
  const [customTextData, setCustomTextData] = useState<CustomTextListPayload>(null);
  const [targetLocales, setTargetLocales] = useState<string[]>([]);
  const [customTextUniqueId, setCustomTextUniqueId] = useState<string>('');
  const [customTextRefetchInd, setCustomTextRefetchInd] = useState<boolean>(false);
  const [customTextNote, setCustomTextNote] = useState<string>('');
  const [targetLocale, setTargetLocale] = useState<string>('');

  const [searchRequestObject, setSearchRequestObject] = useState({
    value: '',
    type: 'stringId'
  });
  const [searchCustomText, { loading: searchLoading }] =
    useLazyQuery(SEARCH_CUSTOM_TEXT, {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (data?.lsFetchCustomTextListUsingSearchFilters?.customTextList?.length === 0) {
          setDataNotFound(true);
        }
        setSearchData(data);
        setMessageText('errorMessage', null)
      },
      onError: () => {
        setSearchData(null);
        setMessageText('errorMessage', defaults.fetch_custom_text_error)
      }
    });

  const [saveCustomTextNotes, { loading: saveCustomTextNotesProcessing }] = useMutation(UPDATE_CUSTOM_TEXT, {
    onCompleted: () => {
      setMessageText('createSuccessMessage', '');
      setMessageText('requestSuccessMessage', '');
      setMessageText('editSuccessMessage', defaults.custom_text_update_success);
      setCustomTextRefetchInd(true);
    },
    onError: () => {
      setMessageText('editSuccessMessage', '');
      setMessageText('errorMessage', defaults.update_custom_text_error)
    }
  })

  const searchCustomTextHandler = (pageNumber: number, itemsPerPage: number) => {
    const variables = {
      stringId: searchRequestObject.type === 'stringId' ? parseInt(searchRequestObject.value) : undefined,
      soldToPartyNumber: searchRequestObject.type === 'stp' ? searchRequestObject.value : undefined,
      requesterName: searchRequestObject.type === 'requestedBy' ? searchRequestObject.value : undefined,
      requesterEmail: searchRequestObject.type === 'requesterEmail' ? searchRequestObject.value : undefined,
      durationType: searchInDuration,
      pageInfo: {
        pageNumber: pageNumber,
        pageSize: itemsPerPage
      }
    };
    searchCustomText({
      variables: variables
    });
  };

  const [messages, setMessages] = useState<Partial<AlertMessage>[]>([
    { key: 'errorMessage', text: '', severity: 'error', gridSize: 6 },
    { key: 'deleteSuccessMessage', text: '', severity: 'success', gridSize: 4 },
    { key: 'createSuccessMessage', text: '', severity: 'success', gridSize: 6 },
    { key: 'createWarningMessage', text: '', severity: 'warning', gridSize: 6 },
    { key: 'editSuccessMessage', text: '', severity: 'success', gridSize: 3 },
    { key: 'requestSuccessMessage', text: '', severity: 'success', gridSize: 9 }
  ]);

  const setMessageText = (messageKey: string, text: string) => {
    setMessages(prevMessages =>
      prevMessages.map(message =>
        message.key === messageKey ? { ...message, text: text } : message
      )
    );
  };

  return (
    <CustomTextContext.Provider value={{
      stringId, setStringId,
      addCustomTextBy, setAddCustomTextBy,
      searchInDuration, SetSearchInDuration,
      searchRequestObject, setSearchRequestObject,
      searchData, setSearchData,
      showFooter, setShowFooter,
      dataNotFound, setDataNotFound,
      searchLoading, searchCustomTextHandler,
      searchCustomText, messages, setMessageText,
      saveCustomTextNotes,
      customTextData, setCustomTextData,
      targetLocales, setTargetLocales,
      customTextUniqueId, setCustomTextUniqueId,
      customTextRefetchInd, setCustomTextRefetchInd,
      saveCustomTextNotesProcessing,
      customTextNote, setCustomTextNote, fetch,
      targetLocale, setTargetLocale,
      customerEmail, customerName
    }}>
      {children}
    </CustomTextContext.Provider>
  );
};

export { CustomTextContext, CustomTextContextProvider };

