import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { useDebounce } from 'use-debounce';
import {
  SearchSalesTerritoryInput,
  SearchSalesTerritoryResponse,
  SearchCountriesResponse,
  SearchCountriesInput,
} from './types';
import {
  GET_SALES_TERRITORY,
  SEARCH_COUNTRY,
  CREATE_CUSTOMER_V2
} from './query';
import {
  GET_STATES,
  SALES_ORG_LIST,
  GET_INVOICE_CONFIGURATION
} from '../../utils/commonQueries'


export const useSearchSalesTerritory = (
  search?: string
) => {
  const [searchText] = useDebounce(search?.trim() || '', 250);
  const query = useQuery<SearchSalesTerritoryResponse, SearchSalesTerritoryInput>(
    GET_SALES_TERRITORY,
    {
      variables: { searchText },
    }
  );
  return query;
};


export const useGetInvoiceConfiguration = () => {
  const query = useQuery(GET_INVOICE_CONFIGURATION);
  return query;
}

export const useGetCountryDetails = (
  search?: string
) => {
  const [clause] = useDebounce(search?.trim() || '', 250);
  const query = useQuery<SearchCountriesResponse, SearchCountriesInput>(
    SEARCH_COUNTRY,
    {
      variables: { clause },
    }
  );
  return query;
}

export const useGetStates = (
  countryIso2Code?: string
) => {
  return useLazyQuery(GET_STATES, {
    variables: { countryIso2Code },
  });
}

export const useGetSalesOrgList = () => {
  return useQuery(SALES_ORG_LIST);
}

export const useCreateCustomerV2 = () => {
  const [createCustomerV2] = useMutation<
    any,any
  >(CREATE_CUSTOMER_V2);
  return createCustomerV2;
}
