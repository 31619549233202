import styled from '@emotion/styled';
import { Alert, Grid, Typography } from "@octanner/prism-core";
import React, { useContext } from "react";
import { JobManagementContext } from '../../Contexts/JobManagementContext';
import { defaults } from '../../utils/default';
import { AlertMessage, JobManagementProps } from '../../utils/types';
import JobManagementFilters from './JobManagementFilters';
import JobManagementTable from './JobManagementTable';

const Container = styled.div`
  margin-top: 24px;
  margin-left: 40px;
  margin-right: 40px;
  margin-bottom: 24px;
  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
}`;

const MessageAlert = styled(Alert)`
  width: 60%;
  margin-bottom: 24px;
  margin-top: 24px;
`;

const JobManagement: React.FC<JobManagementProps> = ({ fetch }) => {

  const { job_management, job_management_subtitle } = defaults;
  const { messages, setMessageText } = useContext(JobManagementContext)

  return (
    <Container>
      <Typography variant='h1'>
        {job_management}
      </Typography>
      <Typography>
        {job_management_subtitle}
      </Typography>
      {messages.map((message: AlertMessage) => (
        message?.text?.length > 0 &&
        (<Grid key={message.key} container>
          <Grid item xs={message.gridSize}><MessageAlert
            onClose={() => setMessageText(message.key, '')}
            severity={message.severity}>
            {message.text}
          </MessageAlert></Grid></Grid>)
      ))}
      <JobManagementFilters />
      <JobManagementTable fetch={fetch} />
    </Container>
  );
}

export default JobManagement;