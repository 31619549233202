import { CloseSharp } from '@material-ui/icons';
import { MBAlert } from '../../banks/modifyBank/styles';
import { Typography } from '@octanner/prism-core';
import { Dispatch, SetStateAction } from 'react';

interface ErrorProps {
  universalSearchError: boolean;
  setUniversalSearchError: Dispatch<SetStateAction<boolean>>;
  saveError: string | null;
  setSaveError: Dispatch<SetStateAction<string | null>>;
}
const Errors = ({
  universalSearchError,
  setUniversalSearchError,
  saveError,
  setSaveError,
}: ErrorProps) => {
  return (
    <>
      {universalSearchError && (
        <MBAlert
          severity="error"
          action={
            <CloseSharp
              style={{ cursor: 'pointer' }}
              onClick={() => setUniversalSearchError(false)}
            />
          }
        >
          <Typography data-test="universal-search:error">
            Failed to load Universal Search Fields.
          </Typography>
        </MBAlert>
      )}
      {saveError && (
        <MBAlert
          severity="error"
          action={
            <CloseSharp
              style={{ cursor: 'pointer' }}
              onClick={() => setSaveError(null)}
            />
          }
        >
          <Typography data-test="universal-search:error">
            {saveError}
          </Typography>
        </MBAlert>
      )}
    </>
  );
};

export default Errors;
