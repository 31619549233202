import { useEffect } from 'react';

export const usePendoScript = (accountId: string, companyId: string) => {
  const pendoScript = `
  (function (apiKey) {
    (function (p, e, n, d, o) {
      var v, w, x, y, z;
      o = p[d] = p[d] || {};
      o._q = o._q || [];
      v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track'];
      for (w = 0, x = v.length; w < x; ++w)
        (function (m) {
          o[m] =
            o[m] ||
            function () {
              o._q[m === v[0] ? 'unshift' : 'push'](
                [m].concat([].slice.call(arguments, 0))
              );
            };
        })(v[w]);
      y = e.createElement(n);
      y.async = !0;
      y.src = 'https://cdn.pendo.io/agent/static/' + apiKey + '/pendo.js';
      z = e.getElementsByTagName(n)[0];
      z.parentNode.insertBefore(y, z);
    })(window, document, 'script', 'pendo');
    pendo.initialize({
      visitor: { id: '${accountId}' },
      account: { id: '${companyId}' },
    });
  })('55acdea7-9864-4dca-57b5-bef70c5fc999');
            `;

  useEffect(() => {
    if (!accountId || !companyId) return;
    const script = document.createElement('script');
    script.innerHTML = pendoScript;
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
    //eslint-disable-next-line
  }, [accountId, companyId]);
};
