import {
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
} from '@material-ui/icons';
import {
  Autocomplete,
  Checkbox,
  MenuItem,
  TextField,
  Typography,
} from '@octanner/prism-core';
import React, { useContext, useEffect, useState } from 'react';
import { listToText } from '../../../common/models';
import { useGetCountriesByCustomer } from '../../../graphql/country/hooks';
import { CustomerContext } from '../../../utils/context/CustomerContext';
import { CountryInputProps } from '../types';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const CountryInput = ({
  selectedCountries,
  onChange,
  dataTestId,
}: CountryInputProps) => {
  const {
    customer: { id: customerId },
  } = useContext(CustomerContext);
  const [valueText, setValueText] = useState('');
  const { data: options, loading } = useGetCountriesByCustomer(customerId);
  const selectedUUID = selectedCountries.map((x) => x.id);
  useEffect(() => {
    const names = selectedCountries.map((country) => country.countryName);
    setValueText(listToText(names, 'Countries', 40));
  }, [selectedCountries]);

  return (
    <Autocomplete
      multiple
      loading={loading}
      options={options}
      disableCloseOnSelect
      onChange={(_, o) => onChange(o)}
      value={selectedCountries}
      getOptionLabel={(option) => option.countryName}
      disableClearable
      isOptionEqualToValue={(a: any, b: any) => a.iso2Code === b.iso2Code}
      renderOption={(props: any, option: any) => (
        <MenuItem {...props}>
          <Checkbox
            data-test={`${dataTestId}:country:${option.iso2Code}`}
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selectedUUID.indexOf(option.id) !== -1}
          />
          {option.countryName}
        </MenuItem>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Countries"
          inputProps={{
            ...params.inputProps,
            'data-test': `${dataTestId}:country:input`,
          }}
          InputProps={{
            ...params.InputProps,
            startAdornment: <Typography>{valueText}</Typography>,
          }}
        />
      )}
    />
  );
};

export default CountryInput;
