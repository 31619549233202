import { useEffect, useState } from 'react';
import { FlexBox } from './styles';
import { PermissionCategory } from '../types';
import CategoriesContainer from './CategoriesContainer';
import SelectionContainer from './PermissionSelectionContainer';
import { BoxMTB } from '../styles';
import { Permission } from '../../types';
import { useGetCategories } from '../hooks/getCategories';
import Loading from '../../../../../common/Loading';

interface GlobalToolProps {
  selectedPermissions: Permission[];
  setSelectedPermissions: (permissions: Permission[]) => void;
}

const RolePermissions = ({
  selectedPermissions,
  setSelectedPermissions,
}: GlobalToolProps) => {
  const [selectedCategory, setSelectedCategory] =
    useState<PermissionCategory>();
  const [search, setSearch] = useState('');
  const { data, loading } = useGetCategories();
  const [categories, setCategories] = useState<PermissionCategory[]>([]);

  useEffect(() => {
    if (!loading && data?.content) {
      setCategories(data.content);
    }
  }, [loading, data]);

  if (loading) {
    return <Loading />;
  }
  return (
    <BoxMTB>
      <FlexBox>
        <CategoriesContainer
          categories={categories}
          selectedPermissions={selectedPermissions}
          activeContainer={selectedCategory}
          setActiveContainer={setSelectedCategory}
          search={search}
          setSearch={setSearch}
        />
        <SelectionContainer
          activeContainer={selectedCategory}
          selectedPermissions={selectedPermissions}
          onPermissionSelect={(perm) => {
            if (!selectedPermissions.includes(perm)) {
              setSelectedPermissions([perm, ...selectedPermissions]);
            }
          }}
          onPermissionDeselect={(perm) => {
            setSelectedPermissions(
              selectedPermissions.filter((p) => p.uuid !== perm.uuid)
            );
          }}
          search={search}
        />
      </FlexBox>
    </BoxMTB>
  );
};

export default RolePermissions;
