import { gql } from '@apollo/client';
import { CUSTOMER_BASE_FRAGMENT } from '../../customer/graphql/customer';

export const CREATE_CUSTOMER = gql`
  ${CUSTOMER_BASE_FRAGMENT}
  mutation createCustomer($input: CreateCustomerInput!) {
    createCustomer(input: $input) {
      ...CustomerBaseFragment
    }
  }
`;

export const SEARCH_CUSTOMERS = gql`
  query searchCustomers(
    $searchText: String!
    $pagination: MasterDataPaginationOptions
  ) {
    searchCustomers(input: $searchText, pagination: $pagination) {
      nodes {
        id: customerUuid
        name
        stpNumber
        customerStatus
      }
      pageInfo {
        pageNumber
        pageSize
        totalPages
        totalElements
        hasPrevious
        hasNext
      }
    }
  }
`;

export const getCustomerQueries = (count: number) => gql`
  query Customer(${generateCustomerVariables(count)}) {
    ${generateCustomerFields(count)}
  }
`;

function generateCustomerVariables(count: number): string {
  const variables: string[] = [];
  for (let i = 1; i <= count; i++) {
    variables.push(`$customer${i}Id: String!`);
  }
  return variables.join(', ');
}

function generateCustomerFields(count: number): string {
  const fields: string[] = [];
  for (let i = 1; i <= count; i++) {
    fields.push(`
      customer${i}: customer(id: $customer${i}Id) {
        id: customerUuid
        name
        stpNumber
        customerStatus
        customerUsage
      }
    `);
  }
  return fields.join('\n');
}

export const DELETE_CUSTOMER = gql`
  mutation deleteCustomer($customerId: String!) {
    deleteCustomer(id: $customerId)
  }
`;
