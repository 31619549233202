import ThemeProvider from '@octanner/prism-core/ThemeProvider';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import Typography from '@octanner/prism-core/Typography';
import { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import ApolloProvider from './utils/context/ApolloProvider';
import AppContextComponent from './utils/context/AppContext';
import { fetchInfo, PublicState } from './utils/auth/Auth';
import BasicPadding from './common/BasicPadding';
import Config from './common/models/Config';
import CustomerContextComponent from './utils/context/CustomerContext';
import Routes from './Routes';
import Loading from './common/Loading';
import {
  jssPreset,
  StylesProvider,
  createGenerateClassName,
} from '@mui/styles';
import { create } from 'jss';
import TranslationLoader from './utils/TranslationLoader';

const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById('jss-insertion-point') ?? undefined,
});
const generateClassName = createGenerateClassName({
  productionPrefix: 'CCMIN',
});
const { PUBLIC_URL: publicUrl } = process.env;
const initialize = async (): Promise<false | (Config & PublicState)> => {
  const state = await fetchInfo();
  if (!state) {
    return false;
  }
  return fetch(`${publicUrl}/config`)
    .then((r) => r.json())
    .then((config: Config) => ({ ...config, ...state }));
};

const App = () => {
  const [isInit, updateInit] = useState(() => false);
  const [config, setConfig] = useState<Config & PublicState>();

  useEffect(() => {
    if (isInit) {
      return;
    }
    initialize()
      .then((r) => {
        if (r) {
          setConfig(r);
        }
      })
      .finally(() => {
        updateInit(true);
      });
  }, [isInit]);

  if (!isInit || !config) {
    return <Loading />;
  }

  if (!config.decodedToken) {
    return (
      <ApolloProvider uri={config.graphqlUri}>
        <ThemeProvider>
          <BasicPadding>
            <Typography>
              Something went wrong while trying to load your information please
              reload the page.
            </Typography>
          </BasicPadding>
        </ThemeProvider>
      </ApolloProvider>
    );
  }

  const initApp = () => (
    <ApolloProvider uri={config.graphqlUri}>
      <StylesProvider jss={jss} generateClassName={generateClassName}>
        <ThemeProvider>
          <TranslationLoader translationsApi={config.translationsUrl}>
            <AppContextComponent
              config={config}
              decodedToken={config.decodedToken as any}
              permissions={config.permissions}
            >
              <BrowserRouter basename={publicUrl}>
                <CustomerContextComponent>
                  <Routes />
                </CustomerContextComponent>
              </BrowserRouter>
            </AppContextComponent>
          </TranslationLoader>
        </ThemeProvider>
      </StylesProvider>
    </ApolloProvider>
  );
  const LDProvider = withLDProvider({
    clientSideID: config.launchDarklyClientId as any,
  })(initApp);

  return <LDProvider />;
};

export default App;
