import { PagedResult } from '../../../common/models/PagedResult';
import { CustomerBase } from '../../customer/models/Customer';

export interface IdentityBase {
  id: string;
  firstName: string;
  lastName: string;
  language: string;
}

export interface Identity extends IdentityBase {
  email: string;
  uniqueId: string;
  customerId: string;
  employeeSecurityRole: string;
}

export interface ImpersonateIdentity extends Identity {
  dateOfBirth: string;
  identityUuid: string;
  preferredName: string;
}
export interface IdentityWithCustomerBase extends Identity {
  customer: CustomerBase;
}

export interface IdentitySearchInput {
  condition: {
    customerId?: string;
    name?: string;
    firstName?: string;
    lastName?: string;
    id?: string;
    uniqueId?: string;
    email?: string;
    active?: boolean;
    internalUser?: boolean;
    orderBy?: string;
    page?: number;
    size?: number;
  };
}

export interface IdentityGroupSearchResponse {
  searchIdentityGroups: IdentityGroup[];
}

export interface IdentityGroupSearchInput {
  clause?: string;
  preferredName?: string;
  lastName?: string;
  id?: string;
  customerId?: string;
  returnAmount: number;
  uniqueId?: string;
  email?: string;
}

export interface IdentitySearchResponse {
  identitySearch: {
    identities: IdentityWithCustomerBase[];
    page: number;
    size: number;
    pagesCount: number;
    recordsCount: number;
    totalRecordsCount: number;
  };
}

export interface AddRemoveIdentityInput {
  identityId: string;
  roleId: number;
}

export interface AddRemoveIdentityGroupInput {
  customerId: string;
  identityGroupId: string;
  roleUuid: string;
}

export interface AddIdentityGroupResponse {
  coupleIdentityGroupAndRole: {
    id: number;
    identityGroup: IdentityGroup;
  };
}

export interface RemoveIdentityGroupResponse {
  decoupleIdentityGroupAndRole: {
    id: number;
    identityGroup: IdentityGroup;
  };
}

export interface AddIdentityResponse {
  coupleIdentityAndRole: {
    identity: IdentityBase;
  };
}

export interface RemoveIdentityResponse {
  decoupleIdentityAndRole: {
    identity: IdentityBase;
  };
}

export interface IdentityGroup {
  id: string;
  name: string;
}

export interface IdentityGroupsResponse {
  identityGroups: PagedResult<IdentityGroup>;
}

export function isIdentity(object: any): object is IdentityBase {
  return 'firstName' in object;
}

export function isAddIdentityResponse(
  object: any
): object is AddIdentityResponse {
  return 'coupleIdentityAndRole' in object;
}

export interface IdentitiesByRoleResponse {
  identitiesByRole: PagedResult<IdentityBase>;
}

export interface IdentityGroupsByRoleResponse {
  identityGroupsByRole: PagedResult<IdentityGroup>;
}

export interface CurrentIdentityResponse {
  currentIdentity: IdentityWithCustomerBase;
}

export interface IdentitesByRoleInput {
  customerId: string;
  roleUuid: string;
  page: number;
  size: number;
}

export type IdentityRoleMapping = AddIdentityResponse | RemoveIdentityResponse;

export type IdentityGroupRoleMapping =
  | AddIdentityGroupResponse
  | RemoveIdentityGroupResponse;

export function isAddIdentityGroupResponse(
  object: any
): object is AddIdentityGroupResponse {
  return 'coupleIdentityGroupAndRole' in object;
}

export const formatName = (identity?: IdentityBase) =>
  `${identity?.firstName} ${identity?.lastName}`.trim();

export const sortIdentities = (a: IdentityBase, b: IdentityBase) =>
  formatName(a).localeCompare(formatName(b));

export const sortIdentityGroups = (a: IdentityGroup, b: IdentityGroup) =>
  a.name.localeCompare(b.name);
