import { gql, useMutation, useQuery } from '@apollo/client';
import { DateTime } from 'luxon';
import {
  BusinessUnitEligibilities,
  EligibilitySet,
  HomeCountryEligibilities,
  WorkCountryEligibilities,
} from '../types';
import { Countries, CreateProgramPayload, Participants } from './types';

const checkInfiniteDate = (date: string) => {
  const iso = DateTime.fromISO(date).year;
  return iso === 9999 ? null : date;
};

const GET_ALL_BANKS_BY_ID = gql`
  query CorePtMgmtGetBankInformationByCustomer(
    $request: CorePtMgmtGetBankInformationByCustomerRequest!
  ) {
    corePtMgmtGetBankInformationByCustomer(request: $request) {
      information {
        nodes {
          pointBankUuid
          pointBankName
        }
      }
    }
  }
`;

type GetBankByIdRequest = {
  request: { customerUuid: string; pagination: { pageSize: number } };
};
type GetBankByIdResponse = {
  corePtMgmtGetBankInformationByCustomer: {
    information: {
      nodes: {
        pointBankUuid: string;
        pointBankName: string;
      }[];
    };
  };
};

export const useBankById = (customerUuid: string) =>
  useQuery<GetBankByIdResponse, GetBankByIdRequest>(GET_ALL_BANKS_BY_ID, {
    variables: {
      request: { customerUuid, pagination: { pageSize: 50 } },
    },
  });

//export const GET_PROGRAM_SALES_ORG_AND_COUNTRY = gql`
//  query CustomerCountrySalesOrgMapsByCustomer($customerId: UUID!) {
//    customerCountrySalesOrgMapsByCustomer(customerId: $customerId) {
//      customerCountrySalesOrgMap {
//        customerCountrySalesOrgId
//        coreCountry {
//          countryName
//          id
//        }
//        assignedSalesOrg {
//          salesOrgCode
//          salesOrgName
//        }
//      }
//    }
//  }
//`;

//type SalesOrgInput = {
//  customerId: string;
//};

//type AssignedSalesOrg = { salesOrgName: string; salesOrgCode: string };
//type CoreCountry = { countryName: string; id: string };

//export type CustomerCountrySalesOrgMap = {
//  customerCountrySalesOrgId: string;
//  assignedSalesOrg: AssignedSalesOrg;
//  coreCountry: CoreCountry;
//};
//type SalesOrgResponse = {
//  customerCountrySalesOrgMapsByCustomer: {
//    customerCountrySalesOrgMap: CustomerCountrySalesOrgMap[];
//  };
//};

//export const useProgramSalesOrgAndCountry = (input: { customerId: string }) => {
//  return useQuery<SalesOrgResponse, SalesOrgInput>(
//    GET_PROGRAM_SALES_ORG_AND_COUNTRY,
//    {
//      variables: input,
//    }
//  );
//};

const GET_BANK_SALES_ORG_BY_ID = gql`
  query CorePtMgmtGetBankInformation(
    $request: CorePtMgmtGetBankInformationRequest!
  ) {
    corePtMgmtGetBankInformation(request: $request) {
      information {
        pointBankSalesOrges {
          pointBankSalesOrgUuid
          customerCountrySalesOrgId
          customerCountrySalesOrg {
            coreCountry {
              countryName
              id
            }
            assignedSalesOrg {
              salesOrgCode
              salesOrgName
            }
          }
        }
      }
    }
  }
`;

export type BankSalesOrg = {
  pointBankSalesOrgUuid: string;
  customerCountrySalesOrgId: string;
  customerCountrySalesOrg: {
    coreCountry: {
      countryName: string;
      id: string;
    };
    assignedSalesOrg: {
      salesOrgCode: string;
      salesOrgName: string;
    };
  };
};

type BankSalesOrgResponse = {
  corePtMgmtGetBankInformation: {
    information: {
      pointBankSalesOrges: BankSalesOrg[];
    };
  };
};
type BankSalesOrgInput = {
  request: {
    customerUuid: string;
    pointBankUuid: string;
  };
};

export const useBankSalesOrgsById = (request: BankSalesOrgInput) =>
  useQuery<BankSalesOrgResponse, BankSalesOrgInput>(GET_BANK_SALES_ORG_BY_ID, {
    variables: request,
  });

const PROGRAM_TYPES = gql`
  query ProgramFinanceTypes {
    coreProgramFinanceTypes {
      programFinanceTypes {
        nodes {
          programFinanceTypeName
          programFinanceTypeCode
        }
      }
    }
  }
`;

type ProgramTypesReponse = {
  coreProgramFinanceTypes: {
    programFinanceTypes: {
      nodes: {
        programFinanceTypeName: string;
        programFinanceTypeCode: string;
      }[];
    };
  };
};

export const useProgramTypes = () =>
  useQuery<ProgramTypesReponse>(PROGRAM_TYPES);

const CREATE_PROGRAM = gql`
  mutation CreateCoreProgramV2($input: CoreProgramInsertRequestV2) {
    createCoreProgramV2(input: $input) {
      id
      programUuid
    }
  }
`;

type CreateProgramResponse = {
  createCoreProgramV2: {
    id: number;
    programUuid: string;
  };
  id: string;
  programUuid: string;
};

type ProgramSalesOrgs = {
  pointBankSalesOrg: {
    pointBankSalesOrgUuid: string;
  };
  disabledTsz: DateTime;
};

export interface ProgramPayload {
  programCategoryId: number | null; // ? recognition, anniversaries, birthdays
  customerId: string;
  displayTz: string;
  endDate: string | null;
  startDate: string | null;
  programFinanceTypeCode: string;
  pointBankUuid: string;
  programName: string;
  programDscr: string;
  programSalesOrgs?: { pointBankSalesOrgUuid: string }[];
}

export const useCreateProgram = () =>
  useMutation<CreateProgramResponse, { input: ProgramPayload }>(CREATE_PROGRAM);

export type EligibilityInput = {
  programUuid: string;
  allParticipants: boolean | null;
  businessUnitEligibilities: BusinessUnitEligibilities[];
  homeCountryEligibilities: HomeCountryEligibilities[];
  workCountryEligibilities: WorkCountryEligibilities[];
};

type CreateEligibiltyResponse = {};

type CreateEligibilityInput = {
  request: EligibilityInput;
};

const CREATE_PROGRAM_ELIGIBLITY = gql`
  mutation CreateNewCoreProgramEligibility(
    $request: CoreProgramEligibilityInsertRequest!
  ) {
    createNewCoreProgramEligibility(request: $request) {
      programEligibilityUuid
    }
  }
`;

export const useCreateEligibility = () =>
  useMutation<CreateEligibiltyResponse, CreateEligibilityInput>(
    CREATE_PROGRAM_ELIGIBLITY
  );

interface BusinessUnitEligibilityResponse extends EligibilitySet {
  businessUnit: {
    buUuid: string;
    buCode: string;
    buName: string;
    archivedTsz?: number;
  };
  businessUnitEligibilityUuid: string;
}

interface HomeCountryEligibilityResponse extends EligibilitySet {
  coreCountry: Countries;
  homeCountryEligibilityUuid: string;
}

interface WorkCountryEligibilityReponse extends EligibilitySet {
  coreCountry: Countries;
  workCountryEligibilityUuid: string;
}

type ProgramEligibility = {
  programEligibility: {
    programEligibilityUuid: string;
    allParticipants: boolean | null;
    businessUnitEligibilities: BusinessUnitEligibilityResponse[];
    homeCountryEligibilities: HomeCountryEligibilityResponse[];
    workCountryEligibilities: WorkCountryEligibilityReponse[];
  };
};

type CoreProgram = {
  id: number;
  customer: {
    customerUuid: string;
  };
  programCategory: { id: number };
  displayTz: string;
  startDate: DateTime | null;
  endDate: DateTime | null;
  programFinanceType: {
    programFinanceTypeCode: string;
  };
  bank: {
    pointBankUuid: string;
  };
  programName: string;
  programDscr: string;
  programSalesOrgs: {
    nodes: ProgramSalesOrgs[];
  };
  eligibleCount: {
    eligibleIdentityCount: number;
  };
  managerDashboardEnabled: boolean;
};

type GetProgramByIdResponse = {
  coreProgramByProgramUuid: { coreProgram: CoreProgram };
};

type GetEligibilityByIdResponse = {
  coreProgramEligibilityByProgram: ProgramEligibility;
};

// ? I unfortuantely cant combine these as the typing is different on the gql side of things.
type GetProgramByIdInput = {
  input: {
    programUuid: string;
  };
};

const GET_PROGRAM_ELIGIBILITIES_BY_ID = gql`
  query ($input: CoreProgramEligibilityByProgramInput) {
    coreProgramEligibilityByProgram(input: $input) {
      programEligibility {
        allParticipants
        programEligibilityUuid
        businessUnitEligibilities {
          businessUnit {
            buName
            buUuid
            buCode
            archivedTsz
          }
          businessUnitEligibilityUuid
          eligibilitySet
        }
        homeCountryEligibilities {
          coreCountry {
            id
            countryName
            iso2Code
          }
          homeCountryEligibilityUuid
          eligibilitySet
        }
        workCountryEligibilities {
          coreCountry {
            id
            countryName
            iso2Code
          }
          workCountryEligibilityUuid
          eligibilitySet
        }
      }
    }
  }
`;
const GET_PROGRAM_BY_ID = gql`
  query CoreProgramByProgramUuid($input: CoreProgramByProgramUuidQueryInput) {
    coreProgramByProgramUuid(input: $input) {
      coreProgram {
        id
        customer {
          customerUuid
        }
        programCategory {
          id
        }
        displayTz
        startDate: startTsz
        endDate: endTsz
        programFinanceType {
          programFinanceTypeCode
        }
        bank {
          pointBankUuid
        }
        programName
        programDscr
        programSalesOrgs {
          nodes {
            disabledTsz
            pointBankSalesOrg {
              pointBankSalesOrgUuid
            }
          }
        }
        managerDashboardEnabled
        eligibleCount {
          eligibleIdentityCount
        }
      }
    }
  }
`;

export const businessUnitRemap = (
  businessUnitEligibilities: BusinessUnitEligibilityResponse[],
  inputList: Participants[]
) => {
  const eligibilityList: Participants[] = inputList;
  businessUnitEligibilities.forEach(({ businessUnit, eligibilitySet }) => {
    const {
      buName: name,
      buCode: code,
      buUuid: uuid,
      archivedTsz,
    } = businessUnit;
    const isIncluded = eligibilitySet === 'INCLUDE' ? true : false;

    const businessUnitIndex = eligibilityList
      .map((x) => x.segmentType)
      .indexOf('businessUnit');

    if (
      businessUnitIndex === -1 ||
      eligibilityList[businessUnitIndex].isIncluded !== isIncluded
    ) {
      eligibilityList.push({
        isIncluded,
        segmentType: 'businessUnit',
        businessUnits: [{ name, code, uuid, archivedTsz }],
      });
    } else if (eligibilityList[businessUnitIndex].isIncluded === isIncluded) {
      eligibilityList[businessUnitIndex].businessUnits?.push({
        name,
        code,
        uuid,
        archivedTsz,
      });
    }
  });

  return eligibilityList;
};

export const homeCountryRemap = (
  homeCountryEligibilities: HomeCountryEligibilityResponse[],
  inputList: Participants[]
) => {
  const eligibilityList: Participants[] = inputList;
  homeCountryEligibilities.forEach(({ coreCountry, eligibilitySet }) => {
    const { id, countryName, iso2Code } = coreCountry;
    const isIncluded = eligibilitySet === 'INCLUDE' ? true : false;

    const countryIndex = eligibilityList
      .map((x) => x.segmentType)
      .indexOf('homeCountry');
    if (
      countryIndex === -1 ||
      eligibilityList[countryIndex].isIncluded !== isIncluded
    ) {
      eligibilityList.push({
        isIncluded,
        segmentType: 'homeCountry',
        countries: [{ id, countryName, iso2Code }],
      });
    } else if (eligibilityList[countryIndex].isIncluded === isIncluded) {
      eligibilityList[countryIndex].countries?.push({
        id,
        countryName,
        iso2Code,
      });
    }
  });
  return eligibilityList;
};

export const workCountryRemap = (
  workCountryEligibilities: WorkCountryEligibilityReponse[],
  inputList: Participants[]
) => {
  const eligibilityList: Participants[] = inputList;
  workCountryEligibilities.forEach(({ coreCountry, eligibilitySet }) => {
    const { id, countryName, iso2Code } = coreCountry;
    const isIncluded = eligibilitySet === 'INCLUDE' ? true : false;

    const countryIndex = eligibilityList
      .map((x) => x.segmentType)
      .indexOf('workCountry');
    if (
      countryIndex === -1 ||
      eligibilityList[countryIndex].isIncluded !== isIncluded
    ) {
      eligibilityList.push({
        isIncluded,
        segmentType: 'workCountry',
        countries: [{ id, countryName, iso2Code }],
      });
    } else if (eligibilityList[countryIndex].isIncluded === isIncluded) {
      eligibilityList[countryIndex].countries?.push({
        id,
        countryName,
        iso2Code,
      });
    }
  });
  return eligibilityList;
};

export const allParticipantsRemap = (
  allParticipants: boolean | null,
  inputList: Participants[]
) => {
  const eligibilityList: Participants[] = inputList;
  if (allParticipants === true)
    eligibilityList.push({
      isIncluded: true,
      segmentType: 'all',
    });

  return eligibilityList;
};

export const cleanProgramData = (
  coreProgramResponse: GetProgramByIdResponse,
  coreEligibilityResponse: GetEligibilityByIdResponse
) => {
  const variable = coreProgramResponse.coreProgramByProgramUuid.coreProgram;
  const eligibility =
    coreEligibilityResponse.coreProgramEligibilityByProgram.programEligibility;
  const {
    programCategory,
    customer,
    displayTz,
    endDate,
    startDate,
    programFinanceType,
    bank,
    programDscr,
    programName,
    //? This is not needed for now, but will be needed in the future
    // programSalesOrgs,
    eligibleCount,
    //?Do not need this for now
    // managerDashboardEnabled,
  } = variable;
  let eligibilityList: Participants[] = [];

  if (eligibility) {
    const {
      allParticipants,
      businessUnitEligibilities,
      homeCountryEligibilities,
      workCountryEligibilities,
    } = eligibility;

    //? This maps through the current eligibilityList and modifies it to our needs.

    eligibilityList = businessUnitRemap(
      businessUnitEligibilities,
      eligibilityList
    );
    eligibilityList = homeCountryRemap(
      homeCountryEligibilities,
      eligibilityList
    );
    eligibilityList = workCountryRemap(
      workCountryEligibilities,
      eligibilityList
    );
    eligibilityList = allParticipantsRemap(allParticipants, eligibilityList);
  }

  const restructuredProgram: CreateProgramPayload = {
    programCategoryId: programCategory.id,
    customerId: customer.customerUuid,
    displayTz,
    endDate: checkInfiniteDate(endDate as unknown as string),
    startDate: checkInfiniteDate(startDate as unknown as string),
    programFinanceTypeCode: programFinanceType?.programFinanceTypeCode || '',
    pointBankUuid: bank?.pointBankUuid || '',
    programName,
    programDscr,
    //? This is not needed for now, but will be needed in the future
    // programSalesOrgs: programSalesOrgs.nodes.map((x) => ({
    //   pointBankSalesOrgUuid: x.pointBankSalesOrg.pointBankSalesOrgUuid,
    //   disabledTsz: x.disabledTsz,
    // })),
    //?Do not need this for now
    // managerDashboardEnabled,
  };
  return {
    eligibleCount,
    restructuredProgram,
    eligibilityList,
  };
};

export const useGetProgramById = ({ programUuid }: { programUuid: string }) =>
  useQuery<GetProgramByIdResponse, GetProgramByIdInput>(GET_PROGRAM_BY_ID, {
    variables: { input: { programUuid } },
  });
export const useGetEligibilityById = ({
  programUuid,
}: {
  programUuid: string;
}) =>
  useQuery<GetEligibilityByIdResponse, GetProgramByIdInput>(
    GET_PROGRAM_ELIGIBILITIES_BY_ID,
    {
      variables: { input: { programUuid } },
      fetchPolicy: 'no-cache',
    }
  );

export interface UpdateProgramRequest {
  customerId: string;
  programId: number;
  displayTz?: string;
  startDate?: any;
  endDate?: any;
  pointBankUuid?: string;
  programAssignmentCode?: string;
  programDscr?: string;
  programFinanceTypeCode?: string;
  programName?: string;
  programSalesOrgs?: { pointBankSalesOrgUuid: string }[];
}

const UPDATE_PROGRAM = gql`
  mutation UpdateCoreProgramV2($input: CoreProgramUpdateRequestV2) {
    updateCoreProgramV2(input: $input) {
      id
      customer {
        customerUuid
      }
      coreProgramType {
        id
      }
      displayTz
      startDate: startTsz
      endDate: endTsz
      programFinanceType {
        programFinanceTypeCode
      }
      bank {
        pointBankUuid
      }
      programName
      programDscr
      programSalesOrgs {
        nodes {
          disabledTsz
          pointBankSalesOrg {
            pointBankSalesOrgUuid
          }
        }
      }
      eligibleCount {
        eligibleIdentityCount
      }
    }
  }
`;

export type UpdateProgramResponse = { updateCoreProgramV2: CoreProgram };

export const useUpdateProgram = () =>
  useMutation<UpdateProgramResponse, { input: UpdateProgramRequest }>(
    UPDATE_PROGRAM
  );

const DEACTIVATE_PROGRAM = gql`
  mutation DeactivateCoreProgramV2($input: DeactivateCoreProgramInput!) {
    deactivateCoreProgramV2(input: $input) {
      id
      endDate: endTsz
      startDate: startTsz
    }
  }
`;

const ACTIVATE_PROGRAM = gql`
  mutation ActivateCoreProgramV2($input: ActivateCoreProgramInput!) {
    activateCoreProgramV2(input: $input) {
      id
      endDate: endTsz
      startDate: startTsz
    }
  }
`;

type DeactivateProgramResponse = {
  deactivateCoreProgramV2: { id: number; endDate: any; startDate: any };
};
type ActivateProgramResponse = {
  activateCoreProgramV2: { id: number; endDate: any; startDate: any };
};
type ProgramInput = { customerUuid: string; programId: number };

export const useDeactivateProgram = () =>
  useMutation<DeactivateProgramResponse, { input: ProgramInput }>(
    DEACTIVATE_PROGRAM
  );

export const useActivateProgram = () =>
  useMutation<ActivateProgramResponse, { input: ProgramInput }>(
    ACTIVATE_PROGRAM
  );

const UPDATE_ELIGIBITY = gql`
  mutation UpdateOneCoreProgramEligibility(
    $request: CoreProgramEligibilityUpdateRequest!
  ) {
    updateOneCoreProgramEligibility(request: $request) {
      allParticipants
      programEligibilityUuid
      businessUnitEligibilities {
        businessUnit {
          buName
          buUuid
          buCode
          archivedTsz
        }
        businessUnitEligibilityUuid
        eligibilitySet
      }
      homeCountryEligibilities {
        coreCountry {
          id
          countryName
          iso2Code
        }
        homeCountryEligibilityUuid
        eligibilitySet
      }
      workCountryEligibilities {
        coreCountry {
          id
          countryName
          iso2Code
        }
        workCountryEligibilityUuid
        eligibilitySet
      }
    }
  }
`;

interface UpdateEligibilityInput extends EligibilityInput {
  programEligibilityUuid: string;
}
type UpdateEligibilityResponse = EligibilityInput;

export const useUpdateEligibility = () =>
  useMutation<UpdateEligibilityResponse, { request: UpdateEligibilityInput }>(
    UPDATE_ELIGIBITY
  );

type ProgramCategoryReponse = {
  coreProgramCategories: {
    coreProgramCategories: {
      nodes: {
        id: number;
        programCategoryCode: string;
        programCategoryDscr: string;
        programCategoryName: string;
      }[];
    };
  };
};
const GET_CORE_PROGRAMS_CATEGORIES = gql`
  query CoreProgramCategories {
    coreProgramCategories {
      coreProgramCategories {
        nodes {
          id
          programCategoryCode
          programCategoryDscr
          programCategoryName
        }
      }
    }
  }
`;
export const useCoreProgramCategories = () =>
  useQuery<ProgramCategoryReponse>(GET_CORE_PROGRAMS_CATEGORIES);
