import { gql } from '@apollo/client';

const CUSTOMER_WITH_ADMIN_ROLES = gql`
  fragment CustomerWithAdminRoles on Customer {
    customerUuid
    name
    stpNumber
    customerStatus
    activeRoleCount
    adminCount
  }
`;

export const SEARCH_CUSTOMERS = gql`
  query RdSearchCustomers(
    $searchTerms: [String!]!
    $sortBy: RdClientSortInput
    $sortDirection: RdSortDirectionInput
    $page: NonNegativeInt!
    $pageSize: PositiveInt!
  ) {
    rdPagedClients(
      input: {
        searchTerms: $searchTerms
        sortBy: $sortBy
        sortDirection: $sortDirection
        page: $page
        pageSize: $pageSize
      }
    ) {
      content {
        ...CustomerWithAdminRoles
      }
      total
      totalPages
    }
  }

  ${CUSTOMER_WITH_ADMIN_ROLES}
`;
