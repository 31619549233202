import { useMutation, useQuery, ApolloError } from '@apollo/client';
import OnComplete from '../../common/models/OnComplete';
import {
  CREATE_PROGRAM,
  CREATE_PROGRAM_ELIGIBLITY,
  GET_PROGRAM_SALES_ORG_AND_COUNTRY,
  GET_PROGRAM_TYPES,
  GET_PROGRAM_WITH_ELGIBILITY,
  GET_SALES_REPS,
  PROGRAMS_BY_CUSTOMER,
  UPDATE_PROGRAM,
  UPDATE_PROGRAM_ELIGIBLITY,
} from './graphql';
import {
  CreateProgramEligibilityInput,
  CreateProgramEligibilityResponse,
  CreateProgramInput,
  CreateProgramResponse,
  GetProgramResponse,
  GetProgramsByCustomerResponse,
  GetProgramTypesResponse,
  UpdateProgramEligibilityInput,
  UpdateProgramEligibilityResponse,
  UpdateProgramRequest,
  UpdateProgramResponse,
  GetProgramsByCustomerInput,
} from './models/models';

export const useGetProgramTypes = (
  onCompleted?: OnComplete<GetProgramTypesResponse>
): [GetProgramTypesResponse | undefined, boolean, ApolloError | undefined] => {
  const { data, loading, error } = useQuery<GetProgramTypesResponse>(
    GET_PROGRAM_TYPES,
    {
      onCompleted,
    }
  );
  return [data, loading, error];
};

export const useCreateProgram = (customerId: string) => {
  const [createProgram] = useMutation<
    CreateProgramResponse,
    CreateProgramInput
  >(CREATE_PROGRAM, {
    update(cache, { data }) {
      if (data) {
        cache.modify({
          id: 'CoreProgramListConnection:{}',
          fields(value, { storeFieldName, toReference }) {
            if (storeFieldName === `nodes:${customerId}`) {
              const newProgramRef = toReference(data.createNewCoreProgram);
              return [...value, newProgramRef];
            }
            return value;
          },
        });
      }
    },
  });
  return createProgram;
};

export const useProgram = (programUuid: string) => {
  const programQuery = useQuery<GetProgramResponse, { programUuid: string }>(
    GET_PROGRAM_WITH_ELGIBILITY,
    {
      variables: {
        programUuid,
      },
    }
  );
  const [updateProgram] = useMutation<
    UpdateProgramResponse,
    UpdateProgramRequest
  >(UPDATE_PROGRAM);
  const [createProgramEligbility] = useMutation<
    CreateProgramEligibilityResponse,
    { input: CreateProgramEligibilityInput }
  >(CREATE_PROGRAM_ELIGIBLITY, {
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: GET_PROGRAM_WITH_ELGIBILITY, variables: { programUuid } },
    ],
  });
  const [updateProgramEligbility] = useMutation<
    UpdateProgramEligibilityResponse,
    { input: UpdateProgramEligibilityInput }
  >(UPDATE_PROGRAM_ELIGIBLITY, {
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: GET_PROGRAM_WITH_ELGIBILITY, variables: { programUuid } },
    ],
  });

  return {
    ...programQuery,
    actions: {
      updateProgram,
      createProgramEligbility,
      updateProgramEligbility,
    },
  };
};
const PAGE_SIZE: number = 13;
export const useGetProgramsByCustomer = (customerId: string) => {
  const programsQuery = useQuery<
    GetProgramsByCustomerResponse,
    GetProgramsByCustomerInput
  >(PROGRAMS_BY_CUSTOMER, {
    variables: { customerId, pageSize: PAGE_SIZE, pageNumber: 0 },
    notifyOnNetworkStatusChange: true,
  });

  const pageNumber =
    programsQuery.data?.coreProgramsByCustomer.pageInfo.pageNumber || 0;
  const totalPages =
    programsQuery.data?.coreProgramsByCustomer.pageInfo.totalPages || 0;

  const fetchMore = () => {
    if (pageNumber < totalPages) {
      programsQuery.fetchMore({
        variables: { pageNumber: pageNumber + 1 },
      });
    }
  };

  const refetch = () => {
    programsQuery.refetch();
  };

  return {
    ...programsQuery,
    fetchMore,
    refetch,
  };
};

type SalesOrgInput = {
  customerId: string;
};

type AssignedSalesOrg = { salesOrgName: string; salesOrgCode: string };
type CoreCountry = {
  countryName: string;
  id: string;
  iso2Code: string;
  iso3Code: string;
};

type SalesOrgResponse = {
  customerCountrySalesOrgMapsByCustomer: {
    customerCountrySalesOrgMap: {
      customerCountrySalesOrgId: string;
      assignedSalesOrg: AssignedSalesOrg;
      coreCountry: CoreCountry;
    }[];
  };
};

export const useProgramSalesOrgAndCountry = (input: { customerId: string }) => {
  return useQuery<SalesOrgResponse, SalesOrgInput>(
    GET_PROGRAM_SALES_ORG_AND_COUNTRY,
    {
      variables: input,
    }
  );
};

export type SalesNodes = {
  salesRepName: string;
  salesRepUuid: string;
  salesRepNumber: string;
  email: string;
};

type SalesRepInput = {
  salesRepName: string;
};

type SalesRepResponse = {
  searchSalesRep: {
    nodes: SalesNodes[];
  };
};

export const useGetSalesReps = (salesRepName: string) => {
  return useQuery<SalesRepResponse, { input: SalesRepInput }>(GET_SALES_REPS, {
    variables: {
      input: {
        salesRepName: salesRepName,
      },
    },
  });
};
