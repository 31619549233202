import { gql } from '@apollo/client'

const YB_CELEBRATION_DATE_OFFSETS = gql`
  fragment YbCelebrationDateOffsetsFragment on YbCelebrationDateOffsets {
    inHandTszOffset
    celebrationWindowStart
    presentationPreparationEmail
    pointsDeposit
    fallbackAwardOrder
    surveyEmail
    celebrationWindowEnd
  }
`

const YB_IN_HAND_DATE_OFFSETS = gql`
  fragment YbInHandDateOffsetsFragment on YbInHandDateOffsets {
    updateProfile
    managerNotification
    peerNotification
    emptyYearbookEmail
    autoshipAward
  }
`

export const IDENTITY = gql`
  fragment Identity on Identity {
    id
    firstName
    lastName
    preferredName
    email
  }
`

export const YB_CELEBRATION_GROUP_CONFIG = gql`
  ${IDENTITY}
  fragment YbCelebrationGroupConfig on YbCelebrationLevelGroupConfig {
    id
    name
    milestone
    yearbookEnabled
    printCoverType
    leaderNotesEnabled
    leaderPhotoEnabled
    leaderVideoEnabled
    peerNotesEnabled
    peerPhotoEnabled
    peerVideoEnabled
    printCertificateEnabled
    printYearbookEnabled
    userSelectedOrder
    fallbackOrder
    autoshipAwards
    autoInviteEnabled
    emptyYearbookEmailEnabled
    emptyYearbookEmailRecipientIdentity {
      ...Identity
    }
    presentationReminderEmail
    surveyEnabled
    surveyUrl
    shipToAddressType
    shipToAttentionType
    billToLocationType
    celebrationLevelConfigs {
      id
      rank
      name
      userSelectedAwardCoreAwardLevel {
        maxPoints
        minPoints
        awardLevelUuid
        awardLevelName
        awardLevelDscr
      }
      autoShipAwardCoreAwardLevel {
        awardLevelDscr
        awardLevelName
        awardLevelUuid
        maxPoints
        minPoints
      }
    }
    accrualPointsForServiceEnabled
    celebrantCongratulatoryNotificationEnabled
    celebrantInvitePeersNotificationEnabled
    peersAnniversaryDayNotificationEnabled
    managerAnniversaryDayNotificationEnabled
  }
`

export const YB_PROGRAM_CELEBRATION_GROUP_CONFIG_EXT = gql`
  ${YB_CELEBRATION_GROUP_CONFIG}
  fragment YbProgramCelebrationGroupConfigExt on YbProgramRepresentation {
    celebrationLevelGroupConfigs {
      ...YbCelebrationGroupConfig
    }
  }
`

export const YB_PROGRAM_CELEBRATION_OFFSETS_EXT = gql`
  ${YB_CELEBRATION_DATE_OFFSETS}
  fragment YbProgramCelebrationOffsetsExt on YbProgramOffsets {
    celebrationDateOffsets {
      ...YbCelebrationDateOffsetsFragment
    }
  }
`

export const YB_PROGRAM_IN_HAND_OFFSETS_EXT = gql`
  ${YB_IN_HAND_DATE_OFFSETS}
  fragment YbProgramInHandOffsetsExt on YbProgramOffsets {
    inHandDateOffsets {
      ...YbInHandDateOffsetsFragment
    }
  }
`

export const YB_ACRUAL_POINTS_REGION_FRAGMENT = gql`
  fragment YbAccrualPointsRegion on YbAccrualPointsRegion {
    id
    name
    defaultRegion
    countries {
      iso2Code
      countryName
    }
    values {
      celebrationLevel {
        id
        rank
      }
      pointsAmount
    }
  }
`

export const YB_PROGRAM_ACCRUAL_POINTS_REGIONS_EXT = gql`
  ${YB_ACRUAL_POINTS_REGION_FRAGMENT}
  fragment YbProgramAccrualPointsRegionsExt on YbProgramRepresentation {
    accrualPointsRegions {
      ...YbAccrualPointsRegion
    }
  }
`

export const YB_PROGRAM_BASE_FRAGMENT = gql`
  ${YB_ACRUAL_POINTS_REGION_FRAGMENT}
  fragment YbProgramBaseFragment on YbProgramRepresentation {
    id
    coreProgram {
      customer {
        id
      }
    }
    celebrationFrequency
    catchupTimestamp
    batchMonth
    batchDay
    annualCelebrationTimestamp
    yearbookLocales
    accrualPointsRegions {
      ...YbAccrualPointsRegion
    }
    victoriesProgram {
      id
    }
  }
`

export const YB_PROGRAM_OFFSETS_FRAGMENT = gql`
  ${YB_PROGRAM_BASE_FRAGMENT}
  ${YB_PROGRAM_CELEBRATION_GROUP_CONFIG_EXT}
  ${YB_PROGRAM_IN_HAND_OFFSETS_EXT}
  ${YB_PROGRAM_CELEBRATION_OFFSETS_EXT}
  ${YB_PROGRAM_ACCRUAL_POINTS_REGIONS_EXT}
  fragment YbProgramOffsets on YbProgramOffsets {
    program {
      ...YbProgramBaseFragment
      ...YbProgramCelebrationGroupConfigExt
      ...YbProgramAccrualPointsRegionsExt
    }
    ...YbProgramInHandOffsetsExt
    ...YbProgramCelebrationOffsetsExt
  }
`
