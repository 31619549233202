import { useMutation } from '@apollo/client';
import styled from '@emotion/styled';
import { Button, Chip, Modal, Switch, Typography } from '@octanner/prism-core';
import React, { useContext, useState } from 'react';
import { LanguageStringsContext } from '../../../../Contexts/LanguageStringsContext';
import { defaults } from '../../../../utils/default';
import { INSERT_CORRECTION, REQUEST_TRANSLATION, SAVE_NOTES } from '../../../../utils/query';
import StyledTextField from '../../../../utils/styledComponents';
import { EditEnglishTranslationModalProps, Translation } from '../../../../utils/types';

const SwitchContainer = styled.div`
  display : flex;
  align-items : center;
  margin-top : 24px;
`;

const StyledChip = styled(Chip)`
  margin: 8px 8px 0px 0px;
`;

const Header = styled(Typography)`
  margin: 16px 0px;
`;

const ChipContainer = styled.div`
  margin: 16px 0px 24px 0px;
  display: flex;
  flex-wrap: wrap; 
`;

const EditEnglishTranslationModal: React.FC<EditEnglishTranslationModalProps> = ({
  openEditEnglishTranslationModal,
  setOpenEditEnglishTranslationModal,
  selectedString,
  englishRequestedString
}) => {

  const { setRefetchInd, setMessageText } =
    useContext(LanguageStringsContext);

  const { stringId, translationList = [] } = selectedString || {};
  const note = selectedString?.notes || '';
  const [upsertTranslation] = useMutation(INSERT_CORRECTION, {
    onError: () => {
      setMessageText('errorMessage', defaults.update_error);
    }
  });
  const [requestTranslation] = useMutation(REQUEST_TRANSLATION, {
    onError: () => {
      setMessageText('errorMessage', defaults.update_error);
    }
  });
  const [saveNotes] = useMutation(SAVE_NOTES);
  const allTranslatedLocales = translationList
    .filter((item) => ((item.translationStatus === 'TRANSLATED' || item.translationStatus === 'INPROGRESS')
      && item.locale !== 'en-US'))
    .map((item) => ({ locale: item.locale, localeDescription: item.localeDescription }));
  const [notes, setNotes] = useState<string>(note || '');
  const [requestExistingTranslation, setRequestExistingTranslation] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);

  const handleRequest = () => {
    setLoading(true);
    upsertTranslation({
      variables: {
        input: {
          deleteExistingDataInd: false,
          stringId: stringId,
          translationStringInput: {
            locale: 'en-US',
            stringText: englishRequestedString
          }
        }
      }
    }).then((response) => {
      if (response.errors) {
        setMessageText('errorMessage', defaults.update_error);
        closeModal();
      } else {
        setMessageText('editSuccessMessage', defaults.update_success);
        requestTranslations();
      }
    });
  }

  const requestTranslations = () => {
    if (requestExistingTranslation && allTranslatedLocales && allTranslatedLocales.length > 0) {
      requestTranslation({
        variables: {
          input: {
            localeList: allTranslatedLocales.map((translation: Translation) => translation.locale),
            stringId,
            useExistingTranslationInd: false
          }
        }
      }).then((response) => {
        if (response.errors) {
          setMessageText('errorMessage', defaults.update_error);
          closeModal();
        } else {
          let successMessage = defaults.request_success;
          if (allTranslatedLocales.length > 0) {
            const localeDescriptions = allTranslatedLocales.map(translation => translation.localeDescription);
            if (localeDescriptions.length === 1) {
              successMessage += ` for ${localeDescriptions[0]}`;
            } else {
              const lastLocale = localeDescriptions.pop();
              successMessage += ` for ${localeDescriptions.join(', ')} and ${lastLocale}`;
            }
          }
          setMessageText('requestSuccessMessage', successMessage);
          handleNote();
        }
      })
    } else {
      handleNote();
    }
  }

  const handleNote = () => {
    if (note != null && notes.trim() !== note.trim()) {
      saveNotes({
        variables: {
          input: {
            stringId,
            languageStringNotes: notes
          }
        }
      }).then(closeModal);
    } else {
      closeModal()
    }
  }

  const closeModal = () => {
    setOpenEditEnglishTranslationModal(false);
    setRefetchInd(true);
  }

  const resetModal = () => {
    if (!loading) {
      setRequestExistingTranslation(true);
      setNotes(note || '');
      setOpenEditEnglishTranslationModal(false);
    }
  }

  return (
    <div>
      <Modal
        actions={<>
          <Button color="secondary" disabled={loading} onClick={() => resetModal()}>{
            defaults.cancel}
          </Button>
          <Button color="primary" onClick={() => handleRequest()} loading={loading} disabled=
            {notes?.trim()?.length > 4000}>{defaults.save} </Button>
        </>}
        id="confirm-english-translation"
        title={defaults.send_translation}
        open={openEditEnglishTranslationModal}
        onClose={() => resetModal()}>
        <Typography variant="body1">
          {defaults.english_locale_warning}
        </Typography>
        {allTranslatedLocales && allTranslatedLocales.length > 0 && (
          <>
            <SwitchContainer>
              <Switch
                checked={requestExistingTranslation}
                onChange={() => setRequestExistingTranslation(!requestExistingTranslation)}
              />
              <Typography variant="body1">
                {defaults.send_req_for_existing_translations}
              </Typography>
            </SwitchContainer>
            {requestExistingTranslation && (
              <>
                <Header variant="h4">{defaults.send_translation_request}</Header>
                <ChipContainer>
                  {allTranslatedLocales.map((item) => (
                    <StyledChip
                      key={item.locale}
                      label={item.localeDescription}
                      variant='outlined'
                    />
                  ))}
                </ChipContainer>
              </>
            )}
          </>
        )}
        <Header variant="h4">{defaults.remark} :</Header>
        <StyledTextField
          placeholder={`${defaults.remark} :`}
          multiline
          fullWidth
          displayCount={true}
          maxLength={4000}
          rows={4}
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
        />
      </Modal>
    </div>
  );
}

export default EditEnglishTranslationModal;