import { useContext, useEffect, useState } from 'react';
import BasicPadding from '../../../common/BasicPadding';
import { BadgeSecondary, Button, Modal, Tabs } from '@octanner/prism-core';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import {
  AddProgramTitle,
  BYPSubTitle,
  ColoredTabProduct,
  ColoredTabs,
  MB32Div,
  MT16Div,
  SaveAndCancelFlexContainer,
  SolidLineAcross,
  SubmitButton,
} from './styles';
import ProductCategorySettings from './sections/productCategorySettings';
import ProgramDetails from './sections/programDetails';
import {
  CreateProgramPayload,
  ErrorObject,
  Participants,
  ProgramSettingValues,
} from './types';
import { CustomerContext } from '../../../utils/context/CustomerContext';
import { INITIAL_ELIGIBILITY_STATE, INITIAL_PROGRAM_STATE } from './constants';
import GeneralSettings from './sections/generalSettings';
import { TabPanel } from './tabPanel';
import {
  EligibilityInput,
  ProgramPayload,
  //? This is not needed for now, but will be needed in the future
  // useBankSalesOrgsById,
  useCreateEligibility,
  useCreateProgram,
} from './graphql';
import ErrorHandlers from './errorHandlers';
import { DateTime } from 'luxon';
import { ArrowLeft } from '@octanner/prism-icons';
const AddProgram = () => {
  const {
    customer: { id: customerId },
  } = useContext(CustomerContext);
  const history = useHistory();
  const [createProgram] = useCreateProgram();
  const [createEligibility] = useCreateEligibility();
  const [programSettings, setProgramSettings] = useState<CreateProgramPayload>(
    INITIAL_PROGRAM_STATE
  );
  const [tabValue, setTabValue] = useState(0);
  const [programDetailError, setProgramDetailError] = useState<ErrorObject>({
    enabled: false,
    count: 0,
  });
  const [productCategoryError, setProductCategoryError] = useState<ErrorObject>(
    {
      enabled: false,
      count: 0,
    }
  );
  const [startDateError, setStartDateError] = useState(false);
  const [endDateError, setEndDateError] = useState(false);
  const [bankError, setBankError] = useState(false);
  const [typeError, setTypeError] = useState(false);
  const [categoryError, setCategoryError] = useState(false);
  const [includedSalesOrgError, setIncludedSalesOrgError] = useState(false);
  const [hasProgramName, setHasProgramName] = useState(true);
  const [modifiableParticipants, setModifiableParticipants] = useState<
    Participants[]
  >([]);
  const [createProgramError, setCreateProgramError] = useState<string>('');
  const [, setProgramEligibility] = useState<EligibilityInput>(
    INITIAL_ELIGIBILITY_STATE
  );
  //? This is not needed for now, but will be needed in the future
  // const { data: salesOrgs } = useBankSalesOrgsById({
  //   request: {
  //     customerUuid: customerId,
  //     pointBankUuid: programSettings.pointBankUuid,
  //   },
  // });
  const [open, setOpen] = useState(false);
  const [isValueChanged, setIsValueChanged] = useState(false);
  useEffect(() => {
    if (customerId) handleUpdateProgramSettings('customerId', customerId);
  }, [customerId]);
  const handleTabValueChange = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    event.preventDefault();
    setTabValue(newValue);
  };
  const resetState = () => {
    // ? State reset
    setHasProgramName(true);
    setBankError(false);
    setTypeError(false);
    setCategoryError(false);
    setCreateProgramError('');
    setStartDateError(false);
    setEndDateError(false);
    setIncludedSalesOrgError(false);
    setProgramDetailError({
      enabled: false,
      count: 0,
    });
    setProductCategoryError({
      enabled: false,
      count: 0,
    });
  };

  const createParticipants = (programUuid: string) => {
    let participantEligibility: EligibilityInput = {
      programUuid,
      allParticipants: false,
      businessUnitEligibilities: [],
      workCountryEligibilities: [],
      homeCountryEligibilities: [],
    };
    const segmentTypeIsAll = modifiableParticipants[0].segmentType === 'all';
    if (modifiableParticipants.length === 0 || segmentTypeIsAll) {
      participantEligibility.allParticipants = true;
      return participantEligibility;
    } else {
      participantEligibility.allParticipants = false;
      modifiableParticipants.forEach((participant) => {
        const isIncluded = participant.isIncluded ? 'INCLUDE' : 'EXCLUDE';
        switch (participant.segmentType) {
          case 'businessUnit':
            if (participant.businessUnits) {
              participant.businessUnits.forEach(({ uuid: buUuid }) =>
                participantEligibility.businessUnitEligibilities.push({
                  eligibilitySet: isIncluded,
                  buUuid,
                })
              );
            }
            break;
          case 'homeCountry':
            if (participant.countries) {
              participant.countries.forEach(({ iso2Code: countryIso2Code }) =>
                participantEligibility.homeCountryEligibilities.push({
                  eligibilitySet: isIncluded,
                  countryIso2Code,
                })
              );
            }
            break;
          case 'workCountry':
            if (participant.countries) {
              participant.countries.forEach(({ iso2Code: countryIso2Code }) =>
                participantEligibility.workCountryEligibilities.push({
                  eligibilitySet: isIncluded,
                  countryIso2Code,
                })
              );
            }
            break;
        }
      });
    }
    setProgramEligibility(participantEligibility);
    return participantEligibility;
  };
  const errorChecking = () => {
    let isError = false;
    const currentDate = DateTime.now();
    if (
      programSettings.startDate &&
      DateTime.fromISO(programSettings.startDate).startOf('day') <
        currentDate.startOf('day')
    ) {
      isError = true;
      incrementProgramCount('STARTDATE');
    }
    if (
      programSettings.startDate &&
      programSettings.endDate &&
      DateTime.fromISO(programSettings.endDate).startOf('day') <
        DateTime.fromISO(programSettings.startDate).startOf('day')
    ) {
      isError = true;
      incrementProgramCount('ENDDATE');
    }
    if (!programSettings.programName) {
      setHasProgramName(false);
      isError = true;
    }
    if (programSettings.pointBankUuid === '') {
      isError = true;
      incrementProgramCount('BANK');
    }
    if (programSettings.programFinanceTypeCode === '') {
      isError = true;
      setTypeError(true);
      setProgramDetailError((prev) => ({
        enabled: true,
        count: prev.count + 1,
      }));
    }
    //? This is not needed for now, but will be needed in the future
    // if (
    //   programSettings.programSalesOrgs.length === 0 ||
    //   !programSettings.programSalesOrgs.some((org) => org.disabledTsz === null)
    // ) {
    //   isError = true;
    //   incrementProgramCount('SALESORG');
    // }
    if (programSettings.coreProgramTypeId === null) {
      isError = true;
      setCategoryError(true);
      setProgramDetailError((prev) => ({
        enabled: true,
        count: prev.count + 1,
      }));
    }
    return isError;
  };
  const handleSaveProgram = (e) => {
    e.preventDefault();
    resetState();
    const isError = errorChecking();
    if (!isError) {
      const payload = programSettings as ProgramPayload;
      // ? Fixes date types
      if (programSettings.startDate) {
        payload.startDate = DateTime.fromISO(programSettings.startDate)
          .startOf('day')
          .toISODate();
      }
      if (programSettings.endDate !== null) {
        payload.endDate = DateTime.fromISO(programSettings.endDate)
          .startOf('day')
          .toISODate();
      }
      //? This is not needed for now, but will be needed in the future
      // const filteredSalesOrgs = programSettings.programSalesOrgs?.filter(
      //   (x) => x.disabledTsz === null
      // );
      // const updatedSelectedSalesOrg =
      //   filteredSalesOrgs &&
      //   filteredSalesOrgs.map(({ pointBankSalesOrgUuid }) => ({
      //     pointBankSalesOrgUuid,
      //   }));
      // payload.programSalesOrgs = updatedSelectedSalesOrg;
      createProgram({ variables: { input: { ...payload } } })
        .then(({ data }) => {
          if (data?.createCoreProgramV2.programUuid) {
            if (modifiableParticipants.length) {
              const participants = createParticipants(
                data.createCoreProgramV2.programUuid
              );
              createEligibility({ variables: { request: participants } }).catch(
                () => {
                  history.push(
                    `/customer/${customerId}/programs/${data.createCoreProgramV2.programUuid}/edit?eligibilityWarning=true`
                  );
                }
              );
            }
            history.push(
              `/customer/${customerId}/programs/${data.createCoreProgramV2.programUuid}/edit`
            );
          }
        })
        .catch((e) => {
          setCreateProgramError(e.message);
        });
    }
  };
  const handleUpdateProgramSettings = (
    key: keyof CreateProgramPayload,
    value: ProgramSettingValues
  ) => {
    setProgramSettings((prev) => ({ ...prev, [key]: value }));
    if (key !== 'customerId') {
      setIsValueChanged(!!value);
    }
  };
  const incrementProgramCount = (
    key: 'BANK' | 'PARTICIPANTS' | 'STARTDATE' | 'ENDDATE' | 'SALESORG'
  ) => {
    switch (key) {
      case 'BANK':
        setBankError(true);
        break;
      case 'STARTDATE':
        setStartDateError(true);
        break;
      case 'ENDDATE':
        setEndDateError(true);
        break;
      case 'SALESORG':
        setIncludedSalesOrgError(true);
        break;
      case 'PARTICIPANTS':
        // ? participantError was an old feature, I think this can be left out for now.
        //setParticipantError(true);
        break;
    }
    setProgramDetailError((prev) => ({ enabled: true, count: prev.count + 1 }));
  };
  const handleModalCancel = () => {
    setOpen(false);
  };
  const handleModalDiscard = () => {
    history.push(`/customer/${customerId}/programs`);
    setOpen(true);
  };
  const handlePageCancel = () => {
    if (isValueChanged) {
      setOpen(true);
    } else {
      history.push(`/customer/${customerId}/programs`);
    }
  };
  return (
    <>
      <BasicPadding padding="md">
        <Button
          variant="text"
          data-test="program:add"
          // @ts-ignore prism issue
          underline="none"
          startIcon={<ArrowLeft />}
          component={isValueChanged ? 'button' : RouterLink}
          to={`/customer/${customerId}/programs`}
          onClick={() => {
            if (isValueChanged) {
              setOpen(true);
            }
          }}
        >
          Back to Your Programs
        </Button>
        <Modal
          actions={
            <>
              <Button onClick={handleModalDiscard}>Discard</Button>
              <Button onClick={handleModalCancel} color="secondary">
                Cancel
              </Button>
            </>
          }
          id="collapse-Modal"
          onClose={handleModalCancel}
          open={open}
          title="You have unsaved changes."
        >
          <BYPSubTitle>
            Going away from this page will discard any unsaved changes.
          </BYPSubTitle>
        </Modal>
        <AddProgramTitle variant="h1">Add Program</AddProgramTitle>
        <ErrorHandlers
          eligibilityWarning={false}
          productCategoryError={productCategoryError}
          programDetailError={programDetailError}
          setEligibilityWarning={() => null} // ? This is only required in editProgram, but requires this type so im doing nothing with it.
          setEligibilitySaveError={() => null} // ? This is only required in editProgram, but requires this type so im doing nothing with it.
          setProductCategoryError={setProductCategoryError}
          setProgramDetailError={setProgramDetailError}
          createProgramError={createProgramError}
          setCreateProgramError={setCreateProgramError}
        />
        <GeneralSettings
          type="add"
          programName={programSettings.programName}
          hasProgramName={hasProgramName}
          programStartDate={programSettings.startDate}
          programEndDate={programSettings.endDate}
          handleProgramSettingChange={handleUpdateProgramSettings}
          startDateError={startDateError}
          endDateError={endDateError}
          handleSave={handleSaveProgram}
          programTimeZone={programSettings.displayTz}
        />
        <Tabs
          style={{ height: 60 }}
          value={tabValue}
          onChange={handleTabValueChange}
          TabIndicatorProps={{
            style: {
              backgroundColor: '#0060E0',
            },
          }}
        >
          <ColoredTabs
            icon={
              <>
                {programDetailError.enabled && (
                  <BadgeSecondary variant="error" sx={{ marginLeft: '6px' }}>
                    {programDetailError.count}
                  </BadgeSecondary>
                )}
              </>
            }
            label="Program details"
            iconPosition="end"
          />
          <ColoredTabProduct
            disabled
            icon={
              <>
                {productCategoryError.enabled && (
                  <BadgeSecondary variant="error" sx={{ marginLeft: '6px' }}>
                    {productCategoryError.count}
                  </BadgeSecondary>
                )}
              </>
            }
            label="Product category settings"
            iconPosition="end"
          />
        </Tabs>
        <SolidLineAcross />
        <TabPanel value={tabValue} index={0}>
          <MT16Div>
            <ProgramDetails
              type="add"
              customerId={customerId}
              bankUuid={programSettings.pointBankUuid}
              //? These two are not needed for now, but will be needed in the future
              // salesOrgs={
              //   salesOrgs?.corePtMgmtGetBankInformation.information
              //     .pointBankSalesOrges || []
              // }
              // selectedSalesOrgs={programSettings.programSalesOrgs}
              handleProgramSettingChange={handleUpdateProgramSettings}
              modifiableParticipants={modifiableParticipants}
              setModifiableParticipants={setModifiableParticipants}
              programSettings={programSettings}
              bankError={bankError}
              participantCount={0} // TODO : Change to actual number
              typeError={typeError}
              categoryError={categoryError}
              includedSalesOrgError={includedSalesOrgError}
            />
          </MT16Div>
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <ProductCategorySettings
            type="add"
            handleProgramSettingChange={handleUpdateProgramSettings}
          />
        </TabPanel>
        <MB32Div />
      </BasicPadding>
      <SaveAndCancelFlexContainer>
        <SubmitButton adminColor onClick={handleSaveProgram}>
          Save and continue
        </SubmitButton>
        <Button color="secondary" onClick={handlePageCancel}>
          Cancel
        </Button>
      </SaveAndCancelFlexContainer>
    </>
  );
};
export default AddProgram;
