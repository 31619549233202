import {
  MenuItem,
  Typography,
  TableHead,
  Autocomplete,
  TableRow,
} from '@octanner/prism-core';
import { Search as SearchIcon } from '@octanner/prism-icons';
import { useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { CustomerBase } from '../../../../customer/models/Customer';
import { useSearchCustomers } from '../../../../global/hooks/customer';
import { HorizontalLine } from '../../../../styles';
import { MILESTONE_ROWS } from '../../constants';
import { SectionProps } from '../../types';
import {
  AutomCompleteTM,
  BorderBottomNone,
  FlexColumnDiv,
  M16Div,
  MileStoneSubTitle,
  MileStoneTitle,
  SelectWidth,
  TableWidth,
  TypographyMT,
} from './styles';

const MilestoneSection = ({ data }: SectionProps) => {
  const [customers, setCustomers] = useState<CustomerBase[]>([]);
  const [customer, setCustomer] = useState<CustomerBase>();
  const [text, setText] = useState('');
  const { data: customerData, loading } = useSearchCustomers(
    { pageSize: 50, pageNumber: 0 },
    text
  );

  useEffect(() => {
    setCustomers(customerData?.searchCustomers?.nodes || []);
  }, [customerData]);
  const handleTextChange = useDebouncedCallback(
    (text: string, customers?: CustomerBase[]) => {
      setText(text);
      if (customers) setCustomers(customers);
    },
    250
  );
  const RenderOption = (props: any, options: CustomerBase) => (
    <MenuItem
      key={options.stp + options.id}
      {...props}
      data-test={`impersonate:search:customer-selector:customer:${options.id}`}
    >
      <FlexColumnDiv>
        <Typography>{options.name}</Typography>
        <Typography color="textSecondary">
          STP: {options.stpNumber || '-'}
        </Typography>
        <Typography color="textSecondary">Core ID: {options.id}</Typography>
      </FlexColumnDiv>
    </MenuItem>
  );

  const RenderInput = (params) => (
    <AutomCompleteTM
      {...params}
      variant="outlined"
      onChange={(e) => handleTextChange(e.target.value, customers)}
      placeholder="Search Client Number"
      InputProps={{
        ...params.InputProps,
        startAdornment: <SearchIcon />,
      }}
      inputProps={{
        ...params.inputProps,
        'data-test': 'impersonate:search:customer-search',
      }}
      disabled={!data.enabled}
    />
  );
  return (
    <div>
      <MileStoneTitle variant="h2">Milestone</MileStoneTitle>
      <MileStoneSubTitle>
        Configure what milestone programs appear on the social feed.
      </MileStoneSubTitle>
      <HorizontalLine />
      <Autocomplete
        loading={loading}
        options={customers}
        value={customer || null}
        isOptionEqualToValue={(a: CustomerBase, b: CustomerBase) =>
          a.id === b.id
        }
        onChange={(_, value: CustomerBase | null) =>
          setCustomer(value || undefined)
        }
        getOptionLabel={(c: any) => `${c.name} (${c.id}) ${c.stpNumber || ''}`}
        renderOption={RenderOption}
        renderInput={RenderInput}
        disabled={!data.enabled}
      />
      <M16Div />
      {/* TODO: get programs from  client*/}
      {/* There is a bug in prism with the select hover state when disabled */}
      <SelectWidth disabled={!data.enabled} label="Program Name">
        <MenuItem>as</MenuItem>
      </SelectWidth>
      <TableWidth>
        <TableHead>
          <TableRow>
            {MILESTONE_ROWS.map((name) => (
              <BorderBottomNone key={name}>
                <TypographyMT variant="h3">{name}</TypographyMT>
              </BorderBottomNone>
            ))}
          </TableRow>
        </TableHead>
      </TableWidth>
      <HorizontalLine />
    </div>
  );
};

export default MilestoneSection;
