import styled from '@emotion/styled';
import { JobManagementContext } from '../../Contexts/JobManagementContext';
import { LocaleContext } from "../../Contexts/LocaleContext";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Locale } from "../../utils/types";
import { Button, FormControl, FormControlLabel, Grid, MenuItem, Radio, RadioGroup, Select, TextField } from "@octanner/prism-core";
import { jobsDurationType, numRegex, translationCategories } from '../../utils/constants';
import { defaults } from '../../utils/default';

const FilterContainer = styled.div`
  margin-top: 24px;
`

const FilterDiv = styled.div`
  margin-bottom: 16px;
`

const RadioButtonContainer = styled.div`
  margin-left: 8px;
`

const RadioFormControlLabel = styled(FormControlLabel)`
  margin-right: 56px;
`

const JobManagementFilters = () => {

  const { localeLoading, locales: localeData } = useContext(LocaleContext);
  const { filterParams,
    setPageConfig, updateFilterParams,
    pageConfig, translationStatus,
    searchLoading, searchJobsByFilter, setMessageText,
    setDataNotFound, setSearchedTranslationCategory
  } = useContext(JobManagementContext);
  const { select_language_or_locale, jobs_within, enter_job_id,
    enter_material_master_id, enter_string_id, search } = defaults;

  const translationStatusRef = useRef(null);

  const [locales, setLocales] = useState<Locale[]>([{ code: 'All', languageDescription: 'All' }]);
  const [searchId, setSearchId] = useState<string>('');

  useEffect(() => {
    if (localeData) {
      setLocales(prevLocales => prevLocales.concat(localeData));
    }
  }, [localeData]);

  useEffect(() => {
    if (translationStatus !== translationStatusRef.current) {
      translationStatusRef.current = translationStatus;
      setPageConfig({ pageNumber: 1, itemsPerPage: 10 });
    }
  }, [translationStatus]);

  useEffect(() => {
    if (searchLoading) {
      return;
    }
    setMessageText('successMessage', '');
    setMessageText('errorMessage', '');
    const { pageNumber, itemsPerPage } = pageConfig;
    if (filterParams.jobNumber > 0 || filterParams.stringId > 0 || filterParams.mmId > 0) {
      searchJobsByFilter({
        variables: {
          translationStatus,
          ...filterParams,
          durationType: null,
          pageInfo: {
            pageNumber: pageNumber,
            pageSize: itemsPerPage
          }
        }
      });
    } else {
      searchJobsByFilter({
        variables: {
          translationStatus,
          ...filterParams,
          locale: filterParams.searchLocale !== 'All' ? filterParams.searchLocale : null,
          pageInfo: {
            pageNumber: pageNumber,
            pageSize: itemsPerPage
          }
        }
      });
    }
    setDataNotFound(false);
    setSearchedTranslationCategory(filterParams.translationCategory);
  }, [pageConfig]);

  const handleJobNumberChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const inputValue = event.target.value;
    if (inputValue === '' || numRegex.test(inputValue)) {
      updateFilterParams('jobNumber', inputValue.trim() === '' ? null : parseInt(inputValue));
    }
  };

  const handleSearchIdChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const inputValue = event.target.value;
    if (inputValue === '' || numRegex.test(inputValue)) {
      if (filterParams.translationCategory === translationCategories.MATERIAL_DESCRIPTION.value) {
        updateFilterParams('mmId', parseInt(inputValue));
        updateFilterParams('stringId', null);
      } else {
        updateFilterParams('mmId', null);
        updateFilterParams('stringId', parseInt(inputValue));
      }
      setSearchId(inputValue);
    }
  }

  const handleTranslationCategoryChange = (event: React.ChangeEvent<{ value: string }>) => {
    updateFilterParams('jobNumber', null);
    updateFilterParams('stringId', null);
    setSearchId('');
    updateFilterParams('mmId', null);
    updateFilterParams('searchLocale', 'All');
    updateFilterParams('durationType', jobsDurationType[3].value)
    updateFilterParams('translationCategory', event.target.value);
  }

  return (<>{
    !localeLoading && localeData?.length > 0 && <FilterContainer>
      <FilterDiv>
        <RadioButtonContainer>
          <FormControl>
            <RadioGroup row
              value={filterParams.translationCategory}
              onChange={handleTranslationCategoryChange}>
              {Object.values(translationCategories).map(category => (
                <RadioFormControlLabel
                  key={category.value}
                  value={category.value}
                  control={<Radio />} label={category.label} />
              ))}
            </RadioGroup>
          </FormControl>
        </RadioButtonContainer>
      </FilterDiv>
      <FilterDiv>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <Select
              disabled={filterParams.jobNumber > 0 || searchId !== ''}
              label={select_language_or_locale}
              fullWidth
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => updateFilterParams('searchLocale', event.target.value)}
              value={filterParams.searchLocale}>
              {locales.map((locale: Locale) => (
                <MenuItem key={locale.code} value={locale.code}>
                  {locale.languageDescription}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={4}>
            <Select
              disabled={filterParams.jobNumber > 0 || searchId !== ''}
              label={jobs_within}
              fullWidth
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => updateFilterParams('durationType', event.target.value)}
              value={filterParams.durationType}>
              {jobsDurationType.map((durationType: { label: string, value: string }) => (
                <MenuItem key={durationType.value} value={durationType.value}>
                  {durationType.label}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={4}>
            <TextField
              disabled={searchId !== ''}
              value={filterParams.jobNumber || ''}
              onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => handleJobNumberChange(event)}
              label={enter_job_id}
              fullWidth
            />
          </Grid>
        </Grid>
      </FilterDiv>
      <FilterDiv>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <TextField
              disabled={filterParams.jobNumber > 0}
              value={searchId}
              onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => handleSearchIdChange(event)}
              label={filterParams.translationCategory === translationCategories.MATERIAL_DESCRIPTION.value ? enter_material_master_id : enter_string_id}
              fullWidth
            />
          </Grid>
        </Grid>
      </FilterDiv>
      <FilterDiv>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <Button onClick={() => setPageConfig({ pageNumber: 1, itemsPerPage: 10 })}
              disabled={searchLoading} adminColor>{search}
            </Button>
          </Grid>
        </Grid>
      </FilterDiv>
    </FilterContainer>}
  </>);
}

export default JobManagementFilters;