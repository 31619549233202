import { useMutation } from "@apollo/client";
import styled from '@emotion/styled';
import { Button, FormControlLabel, Modal, Radio, RadioGroup, Typography } from "@octanner/prism-core";
import { ArrowRight } from "@octanner/prism-icons";
import ArrowDown from "@octanner/prism-icons/ArrowDown";
import React, { useContext, useState } from "react";
import { useHistory } from 'react-router-dom';
import { LanguageStringsContext } from "../../../Contexts/LanguageStringsContext";
import { defaults } from "../../../utils/default";
import { REQUEST_TRANSLATION } from "../../../utils/query";
import { Locale, SendTranslationRequestModalProps, Translation } from "../../../utils/types";

const RadioFormControlLabel = styled(FormControlLabel)`
  margin-right: 55px;
  margin-bottom: 12px;
`;

const StyledTypography = styled(Typography)`
  display : flex;
  align-items : center;
`;

const Control = styled.div`
  margin-top: 24px;
`;

const PaperWrapper = styled.div`
  margin-top: 12px;
  margin-bottom: 12px;
`;

const RadioButtonWrapper = styled.div`
  margin-top: 24px;
  margin-bottom: 12px;
`;

const ModalContainer = styled(Modal)`
  .MuiPaper-root {
    max-width: 526px;
  }
  #send-existing-translation-request-modal-title {
    span {
      margin-top: 24px;
    }
  }
`;

const StyledPaper = styled.div`
  padding: 12px;
  text-align: left;
  position: relative;
  background-color: #F8F8F8;
`;

const LanguageContainer = styled.div`
  display:flex;
  flex-direction: column;
  margin-bottom: 12px;
`;

const LanguageHeader = styled.div`
  display: flex;
  flex-direction: row;
`;

const LanguageName = styled.div`
  margin-right: 5px;
`;

const ArrowContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
`;

const ArrowIcon = styled.div`
  cursor: pointer;
  transition: transform 0.3s;
`;

const SendTranslationRequestModal: React.FC<SendTranslationRequestModalProps> = ({
  existingLocales,
  selectedLocales,
  stringId,
  onCancel,
  openRequestTranslationModal,
  setOpenRequestTranslationModal
}) => {

  const { setMessageText } = useContext(LanguageStringsContext);

  const history = useHistory();

  const requestedLocales = selectedLocales
    .filter((item: Locale) => (item.languageDescription !== null && item.code !== 'en-US'))
    .map((item: Locale) => item.code);

  const [requestTranslation, { loading: requesting }] = useMutation(REQUEST_TRANSLATION);

  const [existingSelectedInd, setExistingSelectedInd] = useState<boolean>(false);
  const [expandedItems, setExpandedItems] = useState<boolean[]>(Array(existingLocales.length).fill(false));

  const existingTranslationCount: string = String(existingLocales.length)
  const totalRequestedLocales: string = String(selectedLocales.length);

  const handleRaiseTranslationRequest = () => {
    if (requesting) {
      return;
    }
    if (stringId.length !== 0) {
      requestTranslation({
        variables: {
          input: {
            stringId: stringId,
            localeList: requestedLocales,
            useExistingTranslationInd: existingSelectedInd
          }
        }
      }).then(() => {
        if (existingSelectedInd) {
          updateWarningMessageExcludingExistingLocales();
        }
        goToLanguageString();
      });
    } else {
      goToLanguageString();
    }
  };

  const goToLanguageString = () => {
    setOpenRequestTranslationModal(false);
    history.push('/language-string/edit/' + stringId + '?type=add');
  }

  const updateWarningMessageExcludingExistingLocales = () => {
    let successMessage = defaults.translation_requested_msg_for_locales;
    if (selectedLocales.length === 0) {
      setMessageText('createWarningMessage', '');
      return;
    }
    const filteredObject = selectedLocales.filter((selectedLocale: Locale) =>
      selectedLocale.code !== 'en-US' &&
      !existingLocales.some((existingLocale: Partial<Translation>) => existingLocale.locale === selectedLocale.code)
    );
    const localeDescriptions = filteredObject.map((locale: Locale) => locale.languageDescription);
    if (localeDescriptions.length > 0) {
      const lastLocale = localeDescriptions.pop();
      if (localeDescriptions.length > 1) {
        successMessage += ` for ${localeDescriptions.join(', ')}${localeDescriptions.length > 1 ? ',' : ''} and ${lastLocale}`;
      } else {
        successMessage += ` for ${lastLocale}`;
      }
    } else {
      successMessage = '';
    }
    setMessageText('createWarningMessage', successMessage);
  };

  const handleClose = () => {
    if (!requesting) {
      setOpenRequestTranslationModal(false);
    }
    onCancel();
  };

  const handleArrowClick = (index: number) => {
    setExpandedItems((prevExpandedItems: boolean[]) => {
      const newExpandedItems = [...prevExpandedItems];
      newExpandedItems[index] = !newExpandedItems[index];
      return newExpandedItems;
    });
  };

  return (
    <>
      <ModalContainer
        actions={
          <>
            <Button disabled={requesting} onClick={handleClose} color="secondary">
              {defaults.cancel}
            </Button>
            <Button
              onClick={handleRaiseTranslationRequest}
              loading={requesting}>
              {defaults.request}
            </Button>
          </>
        }
        color='secondary'
        id="send-existing-translation-request-modal"
        open={openRequestTranslationModal}
        onClose={() => handleClose()}
        title={defaults.send_translations_request}>
        <div>
          <StyledTypography variant="body1">
            {defaults.existing_string_info.replace('{existingTranslationCount}', existingTranslationCount)
              .replace('{totalRequestedCount}', totalRequestedLocales).replace('{existingTranslationCount}', existingTranslationCount)}
          </StyledTypography>
          <Control>
            <StyledTypography variant="body1">
              {defaults.existing_translation}
            </StyledTypography>
          </Control>
          <PaperWrapper >
            <StyledPaper>
              {existingLocales.map((language, index) => (
                <LanguageContainer key={index}>
                  <LanguageHeader onClick={() => handleArrowClick(index)}>
                    <LanguageName>
                      <Typography variant="h4">
                        {language.localeDescription}
                      </Typography>
                    </LanguageName>
                    <ArrowContainer>
                      {expandedItems[index] ? (
                        <ArrowIcon as={ArrowDown} />
                      ) : (
                        <ArrowIcon as={ArrowRight} />
                      )}
                    </ArrowContainer>
                  </LanguageHeader>
                  {expandedItems[index] && (
                    <Typography>
                      {language.translationString}
                    </Typography>
                  )}
                </LanguageContainer>
              ))}
            </StyledPaper>
          </PaperWrapper>
        </div>
        <RadioButtonWrapper>
          <RadioGroup row aria-label='requestTranslation' name='requestTranslation'
            value={existingSelectedInd} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setExistingSelectedInd(Boolean(e.target.value))}>
            <RadioFormControlLabel label={defaults.use_existing_translation} value={true} control={<Radio />} />
            <RadioFormControlLabel label={defaults.send_req_for_all_translations} value={false} control={<Radio />} />
          </RadioGroup>
        </RadioButtonWrapper>
      </ModalContainer>
    </>
  );
};

export default SendTranslationRequestModal;
