import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@octanner/prism-core/Typography';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    text: {
      marginTop: theme.spacing(3),
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  })
);

const NotFoundPage = () => {
  const classes = useStyles();
  return (
    <Typography variant="h1" className={classes.text}>
      Page not found
    </Typography>
  );
};

export default NotFoundPage;
