import { ApolloCache, useMutation, useQuery } from '@apollo/client';
import { CustomerFeature } from '../../../graphql/core-feature/models';
import { GET_ALL_ROLE_INFORMATION } from '../graphql/graphql';
import {
  COUPLE_FEATURE_PERMISSIONS,
  COUPLE_PERMISSIONS,
  DECOUPLE_FEATURE_PERMISSIONS,
  DECOUPLE_PERMISSIONS,
  GET_PERMISSION_SUMMARIES,
} from '../graphql/permissions';
import {
  CoupleDecoupleFeaturePermissionsInput,
  CoupleDecouplePermissionsInput,
  CoupleFeaturePermissionsResponse,
  CouplePermissionsAndRoleResponse,
  DecoupleFeaturePermissionsResponse,
  DecouplePermissionsAndRoleResponse,
  PermissionSummaryInput,
  PermissionSummaryResponse,
} from '../models/Permission';
import { Role } from '../models/Role';

const togglePermission = (
  cache: ApolloCache<any>,
  permission: any,
  value: boolean
) => {
  const permissionId = cache.identify({
    permission,
    __typename: 'PermissionSummary',
  });
  cache.modify({
    id: permissionId,
    fields: {
      isCoupled() {
        return value;
      },
    },
  });
};

export const useGetPermissionSummary = (
  customerUuid: string,
  feature: CustomerFeature,
  role: Role,
  open: boolean = false
) => {
  const variables = {
    customerUuid,
    featureCode: feature.code,
    roleId: role.id,
    page: 0,
    size: 50,
  };
  const featureVariables = {
    customerId: customerUuid,
    featureCode: feature.code,
    roleId: role.uuid,
  };
  const permissionVariables = {
    customerId: customerUuid,
    roleId: role.uuid,
  };
  const refetchQueries = [
    {
      query: GET_ALL_ROLE_INFORMATION,
      variables: {
        roleId: Number(role.id),
        customerId: customerUuid,
      },
    },
  ];

  const permissionQuery = useQuery<
    PermissionSummaryResponse,
    PermissionSummaryInput
  >(GET_PERMISSION_SUMMARIES, {
    variables,
    skip: !open,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });

  const [couplePermissions, { loading: couplePermissionsLoading }] =
    useMutation<
      CouplePermissionsAndRoleResponse,
      Partial<CoupleDecouplePermissionsInput>
    >(COUPLE_PERMISSIONS, {
      variables: permissionVariables,
      refetchQueries,
      update(cache, { data }) {
        data?.couplePermissionsAndRole.rolePermissionMapperList.forEach(
          (permissionMapper) =>
            togglePermission(cache, permissionMapper.permission, true)
        );
      },
    });

  const [decouplePermissions, { loading: decouplePermissionsLoading }] =
    useMutation<
      DecouplePermissionsAndRoleResponse,
      Partial<CoupleDecouplePermissionsInput>
    >(DECOUPLE_PERMISSIONS, {
      variables: permissionVariables,
      refetchQueries,
      update(cache, { data }) {
        data?.decouplePermissionsAndRole.rolePermissionMapperList.forEach(
          (permissionMapper) =>
            togglePermission(cache, permissionMapper.permission, false)
        );
      },
    });

  const [coupleFeature, { loading: coupleFeatureLoading }] = useMutation<
    CoupleFeaturePermissionsResponse,
    CoupleDecoupleFeaturePermissionsInput
  >(COUPLE_FEATURE_PERMISSIONS, {
    variables: featureVariables,
    refetchQueries,
    update(cache, { data }) {
      data?.couplePermissionsToRoleByFeature.forEach((permissionMapper) =>
        togglePermission(cache, permissionMapper.permission, true)
      );
    },
  });

  const [decoupleFeature, { loading: decoupleFeatureLoading }] = useMutation<
    DecoupleFeaturePermissionsResponse,
    CoupleDecoupleFeaturePermissionsInput
  >(DECOUPLE_FEATURE_PERMISSIONS, {
    variables: featureVariables,
    refetchQueries,
    update(cache, { data }) {
      data?.decouplePermissionsToRoleByFeature.forEach((permissionMapper) =>
        togglePermission(cache, permissionMapper.permission, false)
      );
    },
  });

  return {
    ...permissionQuery,
    loading:
      couplePermissionsLoading ||
      decouplePermissionsLoading ||
      coupleFeatureLoading ||
      decoupleFeatureLoading ||
      permissionQuery.loading,
    actions: {
      coupleFeature,
      decoupleFeature,
      couplePermissions,
      decouplePermissions,
    },
  };
};
