import ConfirmModal from './ConfirmModal';
import { BaseModalProps } from '../types';

interface ModalProps extends BaseModalProps {
  entityName: string;
}

const RemoveAllRolesModal = (modalProps: ModalProps) => {
  return (
    <ConfirmModal
      {...modalProps}
      title="Remove all active roles"
      message={`Are you sure you want to remove all of the active roles for ${modalProps.entityName}? This can’t be undone.`}
      confirmText="Remove all"
      color="error"
      onConfirm={() => {
        modalProps.setOpen(false);
        modalProps.onConfirm();
      }}
    />
  );
};

export default RemoveAllRolesModal;
