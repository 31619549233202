import { ApolloError } from '@apollo/client';
import { Button } from '@octanner/prism-core';
import { ArrowBack as ArrowBackIcon } from '@material-ui/icons';
import { Alert, Typography } from '@octanner/prism-core';
import RecognitionUI from '@octanner/recognition-ui';
import { MainSettings } from '@octanner/yearbook-program-settings';
import URLSearchParams from '@ungap/url-search-params';
import clsx from 'clsx';
import { useContext, useEffect, useState } from 'react';
import { Link as RouterLink, RouteComponentProps } from 'react-router-dom';
import { AppContext } from '../../../utils/context/AppContext';
import Loading from '../../../common/Loading';
import { CustomerContext } from '../../../utils/context/CustomerContext';
import EditProgram from './EditProgram.old';
import { useProgram } from '../hooks';
import {
  archiveStatus,
  ProgramEligibilityInput,
  programToViewModel,
  UpdateProgramForm,
} from '../models/models';
import ProgramEligibilityComp from './programEligibility/ProgramEligibility';
import ProgramEligibilityForm from './programEligibility/ProgramEligibilityForm';
import StatusLabel from '../../../common/statusLabel';
import { useStyles } from '../styles';
import BasicPadding from '../../../common/BasicPadding';
import Box from '@mui/material/Box';

const parseError = ({ message }: ApolloError) => {
  let parsedError =
    'There was an error while saving, if this continues please contact the Core Team.';
  try {
    const error: {
      businessUnits: string[];
      code: string;
      homeIso2Code: string[];
      message: string;
      workIso2Code: string[];
    } = JSON.parse(message.replace(/^.*?:\s*/, ''));
    const hasConflicts =
      error.businessUnits.length > 0 ||
      error.homeIso2Code.length > 0 ||
      error.workIso2Code.length > 0;

    parsedError = hasConflicts
      ? `There was a conflict on
      ${
        error.businessUnits.length
          ? `business unit(s): ${error.businessUnits.join(', ')}`
          : ''
      }
      ${
        error.homeIso2Code.length
          ? `home country: ${error.homeIso2Code.join(', ')}`
          : ''
      }
      ${
        error.workIso2Code.length
          ? `work country: ${error.workIso2Code.join(', ')}`
          : ''
      }`
      : error.message;
  } catch {}
  throw parsedError;
};

const ViewProgram = ({
  location,
  match,
  history,
}: RouteComponentProps<{ programId: string }>) => {
  const classes = useStyles();
  const [error, setError] = useState('');

  const params = new URLSearchParams(location.search);
  const isEditingProgram = Boolean(params.get('editProgram'));
  params.set('editProgram', 'true');
  const editProgramLink = params.toString();
  params.delete('editProgram');
  const cancelProgramLink = `${location.pathname}?${params}`;

  const {
    customer: { id: customerId },
  } = useContext(CustomerContext);
  const {
    config: { recognitionLaunchDarklyClientId },
  } = useContext(AppContext);
  const { programId } = match.params;

  const { data, loading, actions } = useProgram(programId);
  const program = data
    ? programToViewModel(data?.coreProgramByProgramUuid.coreProgram)
    : undefined;
  const eligibility = data?.coreProgramEligibilityByProgram.programEligibility;
  const isEditingEligibility =
    Boolean(params.get('editEligibility')) && program?.isFuture;

  useEffect(() => {
    if (isEditingProgram) {
      return;
    }
    setError('');
  }, [isEditingProgram]);

  if (loading) {
    return (
      <BasicPadding>
        <Loading />
      </BasicPadding>
    );
  }
  if (!program || !data?.coreProgramByProgramUuid) {
    return <Alert>There is no program with id: {programId}</Alert>;
  }

  const handleSave = (form: UpdateProgramForm) => {
    setError('');
    actions
      .updateProgram({
        variables: { request: { ...form, id: program.id } },
      })
      .catch(parseError)
      .catch(setError);
    const params = new URLSearchParams(location.search);
    params.delete('editProgram');
    history.push(`?${params}`);
  };

  const handleEdit = () => {
    const params = new URLSearchParams(location.search);
    params.set('editEligibility', 'true');
    history.push(`?${params.toString()}`);
  };

  const cancelEligibilityEdit = () => {
    const params = new URLSearchParams(location.search);
    params.delete('editEligibility');
    history.push(`?${params.toString()}`);
  };

  const handleEligibilitySave = (input: ProgramEligibilityInput) => {
    setError('');
    if (!eligibility) {
      actions
        .createProgramEligbility({
          variables: {
            input: {
              programUuid: program.programUuid,
              ...input,
            },
          },
        })
        .then(cancelEligibilityEdit)
        .catch(parseError)
        .catch(setError);
    } else {
      actions
        .updateProgramEligbility({
          variables: {
            input: {
              programEligibilityUuid: eligibility.programEligibilityUuid,
              programUuid: program.programUuid,
              ...input,
            },
          },
        })
        .then(cancelEligibilityEdit)
        .catch(parseError)
        .catch(setError);
    }
  };
  const baseCustomerUrl = `/customer/${customerId}/programs/${programId}`;

  // yearbook stuff
  const handleEditGroupClick = (groupId?: string) => {
      groupId
        ? history.push(`${baseCustomerUrl}/celebrations/${groupId}`)
        : history.push(`${baseCustomerUrl}/celebrations/new`);
    },
    onEditAccrualPointRegion = (regionId: string) => {
      history.push(`${baseCustomerUrl}/budget-groups/${regionId}`);
    },
    onAddRegion = () => {
      history.push(`${baseCustomerUrl}/budget-groups/new`);
    };

  const actualProgramEdit =
    isEditingProgram && !archiveStatus.isStatus(program);

  const { feature, type, programUuid, programName } = program;

  return (
    <BasicPadding>
      <RouterLink to={`/customer/${customerId}/programs`}>
        <Button
          variant="text"
          data-test="program:back"
          className={classes.mdBottom}
          startIcon={<ArrowBackIcon />}
        >
          Back to Programs
        </Button>
      </RouterLink>
      {error && (
        <Alert
          severity="error"
          className={classes.xsBottom}
          data-test="program:error"
        >
          <Typography data-test="program:error">{error}</Typography>
        </Alert>
      )}
      <Typography variant="h1" data-test="program:name">
        {programName}
      </Typography>
      <Typography className={classes.smTop}>
        <span data-test="program:feature-name">{feature}</span>
        ,&nbsp;
        <span data-test="program:program-type">{type}</span> | ID:{' '}
        <span data-test="program:uuid">{programUuid}</span>
      </Typography>
      <StatusLabel
        program={data.coreProgramByProgramUuid.coreProgram}
        className={classes.xsTop}
        dataTest="program:status"
      />
      {!actualProgramEdit && (
        <>
          <Typography className={classes.xsTop} data-test="program:startTsz">
            {program.startText}
          </Typography>
          {program.endText && (
            <Typography className={classes.xsTop} data-test="program:endTsz">
              {program.endText}
            </Typography>
          )}
          {!archiveStatus.isStatus(program) && (
            <div className={clsx(classes.xsTop, classes.mdBottom)}>
              <RouterLink
                to={`?${editProgramLink}`}
                data-test="program:edit-dates"
              >
                <Button style={{ display: 'inline-flex' }} variant="text">
                  Update program name and dates
                </Button>
              </RouterLink>
            </div>
          )}
        </>
      )}
      {actualProgramEdit && (
        <EditProgram
          program={program}
          className={clsx(classes.mdTop, classes.mdBottom)}
          cancelLink={cancelProgramLink}
          onSave={handleSave}
        />
      )}
      <hr />
      {!isEditingEligibility && (
        <ProgramEligibilityComp
          canEdit={program.isFuture}
          participantCount={program.eligibleCount.eligibleIdentityCount}
          eligibility={eligibility}
          className={clsx(classes.mdTop, classes.mdBottom)}
          onEdit={handleEdit}
        />
      )}
      {isEditingEligibility && (
        <ProgramEligibilityForm
          program={data.coreProgramByProgramUuid.coreProgram}
          eligibility={eligibility}
          className={clsx(classes.mdTop, classes.mdBottom)}
          onCancel={cancelEligibilityEdit}
          onSave={handleEligibilitySave}
        />
      )}
      {program.type === 'Recognition' && (
        <>
          <hr />
          <RecognitionUI
            className={clsx(classes.mdTop, classes.mdBottom)}
            programId={programId}
            customerId={customerId}
            launchDarklyClientId={recognitionLaunchDarklyClientId || ''}
            // setError={setError}
          />
        </>
      )}
      {program.type === 'Career Anniversary' && (
        <Box sx={{ my: 3 }}>
          <MainSettings
            programId={programId}
            onEditGroupClick={handleEditGroupClick}
            onEditAccrualPointRegion={onEditAccrualPointRegion}
            onAddRegion={onAddRegion}
          />
        </Box>
      )}
      <hr />
    </BasicPadding>
  );
};

export default ViewProgram;
