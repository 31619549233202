import { Button, Skeleton, Switch, Typography } from '@octanner/prism-core';
import { useContext, useEffect, useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';

import {
  ColorBlack,
  SocialWallModal,
  FlexCenterDiv,
  SectionHolder,
} from './styles';
import { ConfirmationModalProps } from './types';
import Inprogress from '../../inProgress/Inprogress';
import {
  useActivatePersonalFeed,
  useDeactivatePersonalFeed,
} from './hooks/socialWall/socialWall';
import { CustomerContext } from '../../../utils/context/CustomerContext';
import { useGetSocialConfigByUuid } from './hooks/posts/posts';
import { CREATE_POSTS, PROGRAMS } from './constants';
import FeedSection from './sections/feeds';
import AwardLevelSection from './sections/awardLevels';
import PostSection from './sections/posts';
import MilestoneSection from './sections/milestones';
//import ClientTranslationContext from '../../../utils/context/ClientTranslationContext';
import { SocialFeedSearchView } from '../../customer/models/Customer';
//import { authFetch } from '../../../utils/auth/Auth';

const SocialWall = () => {
  const {
    customer: { id: customerId },
  } = useContext(CustomerContext);
  const { data: socialConfigData, loading: socialConfigLoading } =
    useGetSocialConfigByUuid(customerId);
  const socialConfig =
    socialConfigData?.socialConfigByCustomerUuid?.socialConfig;

  const personalFeedOverride = socialConfig?.personalFeedOverride || false;
  const viewType = socialConfig?.viewType || SocialFeedSearchView.CORPORATE;
  const displayAwardLevel = socialConfig?.displayAwardLevel || false;

  const [socialWallEnabled, setSocialWallEnabled] = useState(
    !personalFeedOverride
  );
  const [, setError] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [disableText, setDisableText] = useState(false);
  const { ccAdminHideSocialWallD230425, ccAdminHideFullSocialWall } =
    useFlags();
  // ? create better way to create object to be passed
  const socialWallData = {
    enabled: socialWallEnabled,
    viewType,
    // ? get name/options info from gql?
    // * productFilters will have the information, but needs to be updated by BE
    programAwards: PROGRAMS,
    // * These are hard coded values for all clients
    posts: CREATE_POSTS(
      // displayBusinessUnit,
      displayAwardLevel,
      socialConfig
    ),
    milestones: [],
    customerUuid: customerId,
  };

  const customerUuidVariable = {
    variables: {
      customerUuid: customerId,
    },
  };
  const [activatePersonalFeed] = useActivatePersonalFeed(customerId);
  const [deactivatePersonalFeed] = useDeactivatePersonalFeed(customerId);
  //const clientTranslations = useContext(ClientTranslationContext);

  // ? Example of pulling translation off client response.
  //console.log(clientTranslations?.['account-answer']);

  const getClientProgramAwardLevels = async () => {
    //const GIVE_URL = '/api/give/programAwardLevels';
    //const data = await authFetch(GIVE_URL).then((res) => res.json());
    //console.log(data);
  };

  useEffect(() => {
    getClientProgramAwardLevels();
  }, []);

  useEffect(() => {
    setSocialWallEnabled(!personalFeedOverride);
    if (personalFeedOverride) {
      setDisableText(true);
    }
  }, [personalFeedOverride]);

  const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
    showModal,
  }) => {
    const handleCloseCancel = () => {
      setShowModal(false);
      setDisableText(false);
    };
    const handleCloseConfirm = () => {
      // update social wall with updated social config object
      setShowModal(false);
      setDisableText(true);
      activatePersonalFeed(customerUuidVariable);
      setSocialWallEnabled(false);
    };
    return (
      <SocialWallModal
        open={showModal}
        id="confirmation-modal"
        title="Turn Off Social Wall"
        onClose={handleCloseCancel}
        actions={
          <>
            <Button
              color="blue"
              onClick={handleCloseConfirm}
              data-testid="social-wall:button:confirm"
            >
              Confirm
            </Button>
            <Button
              color="secondary"
              onClick={handleCloseCancel}
              data-testid="social-wall:button:cancel"
            >
              Cancel
            </Button>
          </>
        }
      >
        <Typography>
          Are you sure you want to turn off the social wall? Public recognition
          has a much greater impact than recognition given in private.
        </Typography>
      </SocialWallModal>
    );
  };

  if (ccAdminHideSocialWallD230425) {
    return <Inprogress />;
  }

  if (socialConfigLoading) {
    return (
      <div>
        <Skeleton height={50} />
        <SectionHolder disabled={disableText}>
          <Skeleton height={160} />
          <Skeleton height={150} />
          <Skeleton height={150} />
          <Skeleton height={350} />
          <Skeleton height={150} />
          <Skeleton height={150} />
        </SectionHolder>
      </div>
    );
  }
  return (
    <div>
      <FlexCenterDiv>
        <Switch
          data-testid="social-wall:switch:toggle"
          checked={socialWallEnabled}
          onChange={() => {
            if (personalFeedOverride) {
              deactivatePersonalFeed(customerUuidVariable);
              setSocialWallEnabled(true);
              setDisableText(false);
            } else {
              setShowModal(true);
            }
          }}
        />
        <Typography variant="h2">Social Wall</Typography>
      </FlexCenterDiv>
      <div>
        <ColorBlack>
          Turning the social wall off will revert the users view to the personal
          wall.
        </ColorBlack>
      </div>
      <br></br>
      {!ccAdminHideFullSocialWall && (
        <SectionHolder disabled={disableText}>
          <FeedSection data={socialWallData} setError={setError} />
          <AwardLevelSection data={socialWallData} setError={setError} />
          <PostSection data={socialWallData} setError={setError} />
          <MilestoneSection data={socialWallData} setError={setError} />
        </SectionHolder>
      )}

      <ConfirmationModal showModal={showModal} />
    </div>
  );
};

export default SocialWall;
