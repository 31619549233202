import {
  Redirect,
  Route,
  Switch,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import ProtectedRoute from '../../common/ProtectedRoute';
import { MigrationRoute } from './routes';

const MigrationsRoutes = () => {
  const match = useRouteMatch();
  const params = useParams<{ migrationuuid: string }>();

  return (
    <Switch>
      {MigrationRoute.map(({ authorizer, path, component, exact }) => {
        return (
          <ProtectedRoute
            key={path}
            authorizer={authorizer}
            path={[`${match.path}${path}`]}
            component={component}
            exact={exact}
          />
        );
      })}

      <Route path={`${match.path}`} exact={true}>
        <Redirect to={`/migration/${params.migrationuuid}`} />
      </Route>
    </Switch>
  );
};
export default MigrationsRoutes;
