import { Typography, Button } from '@octanner/prism-core';
import { Link as RouterLink } from 'react-router-dom';
import { ArrowTailLeft } from '@octanner/prism-icons';
import { useParams } from 'react-router-dom';
import { useStyles } from './styles';
import { useSearchCustomers } from '../global/hooks/customer';
import DataMigration from './DataMigration';
import InitiativeMigration from './InitiativeMigration';
import CircularProgress from '../../common/CircularProgress';

const CurrentMigration = () => {
  const classes = useStyles();
  const { coreCustomerUuid } = useParams<{ coreCustomerUuid: string }>();
  const { data: customerSearchData, loading: customerLoading } =
    useSearchCustomers({ pageSize: 1, pageNumber: 0 }, coreCustomerUuid);
  const name = customerSearchData?.searchCustomers?.nodes[0]?.name;

  return (
    <div className={classes.page}>
      <RouterLink to="/migration" data-testid="go-to-migrations">
        <Button
          className={classes.back}
          variant="text"
          startIcon={<ArrowTailLeft />}
        >
          Back
        </Button>
      </RouterLink>
      <div>
        {customerLoading ? (
          <CircularProgress
            className={classes.nameLoad}
            size={20}
            thickness={4}
          />
        ) : (
          <Typography
            variant="h1"
            className={classes.name}
            data-testid={'client-name'}
          >
            {name}
          </Typography>
        )}
      </div>
      <DataMigration />
      <br />
      <InitiativeMigration coreCustomerUuid={coreCustomerUuid} />
    </div>
  );
};

export default CurrentMigration;
