import { gql } from '@apollo/client';
import {
  ADMIN_ROLE_FRAGMENT,
  ROLE_FRAGMENT,
  VALIDATION_ERROR_FRAGMENT,
} from '../../graphql/gql';

const ADMIN_ROLE_SUMMARY_FRAGMENT = gql`
  fragment AdminRoleSummaryFragment on RdAdminRoleSummary {
    activeRoleCount
    activeClientCount
    inactiveRoleCount
  }
`;

const BUSINESS_UNIT_FRAGMENT = gql`
  fragment BusinessUnitFragment on BusinessUnit {
    buName
  }
`;

const IDENTITY_PROFILE_FRAGMENT = gql`
  fragment IdentityProfileFragment on Profile {
    avatar
  }
`;

const ADMIN_SUMMARY_IDENTITY_FRAGMENT = gql`
  fragment IdentityFragment on Identity {
    identityUuid
    preferredName
    firstName
    lastName
    email
    rdAdminRoleSummary {
      ...AdminRoleSummaryFragment
    }
    terminationDate
    uniqueId
    businessUnit {
      ...BusinessUnitFragment
    }
    jobTitle
    profile {
      ...IdentityProfileFragment
    }
  }

  ${ADMIN_ROLE_SUMMARY_FRAGMENT}

  ${BUSINESS_UNIT_FRAGMENT}

  ${IDENTITY_PROFILE_FRAGMENT}
`;

const IDENTITY_UUID_FRAGMENT = gql`
  fragment IdentityUuidFragment on Identity {
    identityUuid
  }
`;

const IDENTITY_ROLE_ASMT_FRAGMENT = gql`
  fragment IdentityRoleAsmtFragment on IdentityRoleAsmt {
    id
    identity {
      ...IdentityUuidFragment
    }
    role {
      ...RoleFragment
    }
  }

  ${IDENTITY_UUID_FRAGMENT}

  ${ROLE_FRAGMENT}
`;

export const GET_ADMIN_SUMMARY = gql`
  query RdGetAdminCardInfo($identityUuid: String!) {
    identity(id: $identityUuid) {
      ...IdentityFragment
    }
  }

  ${ADMIN_SUMMARY_IDENTITY_FRAGMENT}
`;

export const GET_ADMIN_ACTIVE_ROLES = gql`
  query RdFindActiveRolesByAdmin(
    $identityUuid: UUID!
    $pageNumber: NonNegativeInt!
    $pageSize: PositiveInt!
    $sortBy: RdAdminRoleSortInput
    $sortDirection: RdSortDirectionInput
  ) {
    rdFindActiveRolesByAdmin(
      input: {
        identityUuid: $identityUuid
        page: $pageNumber
        pageSize: $pageSize
        sortBy: $sortBy
        sortDirection: $sortDirection
      }
    ) {
      content {
        ...AdminRoleFragment
      }
      total
      totalPages
    }
  }

  ${ADMIN_ROLE_FRAGMENT}
`;

export const GET_ADMIN_INACTIVE_ROLES = gql`
  query RdFindInactiveRolesByAdmin(
    $identityUuid: UUID!
    $pageNumber: NonNegativeInt!
    $pageSize: PositiveInt!
    $sortBy: RdAdminRoleSortInput
    $sortDirection: RdSortDirectionInput
  ) {
    rdFindInactiveRolesByAdmin(
      input: {
        identityUuid: $identityUuid
        page: $pageNumber
        pageSize: $pageSize
        sortBy: $sortBy
        sortDirection: $sortDirection
      }
    ) {
      content {
        ...AdminRoleFragment
      }
      total
      totalPages
    }
  }

  ${ADMIN_ROLE_FRAGMENT}
`;

export const CREATE_NEW_ADMIN = gql`
  mutation RdCreateAdmin($identityUuid: UUID!, $roleUuids: [UUID!]!) {
    rdAddRolesToAdmin(
      input: { identityUuid: $identityUuid, roleUuids: $roleUuids }
    ) {
      ... on RdAddRolesToAdminSuccess {
        identityRoleAsmts {
          ...IdentityRoleAsmtFragment
        }
      }
      ... on RdAddRolesToAdminFailure {
        rdValidationErrors {
          ...ValidationErrorFragment
        }
      }
    }
  }

  ${IDENTITY_ROLE_ASMT_FRAGMENT}

  ${VALIDATION_ERROR_FRAGMENT}
`;

export const UPDATE_ADMIN = gql`
  mutation RdUpdateAdmin(
    $identityUuid: UUID!
    $includeRemoveAllRoles: Boolean!
    $includeRemoveRoles: Boolean!
    $removeRoleUuids: [UUID!]!
    $includeAddRoles: Boolean!
    $addRoleUuids: [UUID!]!
  ) {
    rdRemoveAllAdminRolesFromAdmin(identityUuid: $identityUuid)
      @include(if: $includeRemoveAllRoles) {
      ... on RdRemoveAllAdminRolesFromAdminSuccess {
        identityUuid
      }
      ... on RdRemoveAllAdminRolesFromAdminFailure {
        rdValidationErrors {
          ...ValidationErrorFragment
        }
      }
    }
    rdRemoveRolesFromAdmin(
      input: { identityUuid: $identityUuid, roleUuids: $removeRoleUuids }
    ) @include(if: $includeRemoveRoles) {
      ... on RdRemoveRolesFromAdminSuccess {
        identityRoleAsmts {
          ...IdentityRoleAsmtFragment
        }
      }
      ... on RdRemoveRolesFromAdminFailure {
        rdValidationErrors {
          ...ValidationErrorFragment
        }
      }
    }
    rdAddRolesToAdmin(
      input: { identityUuid: $identityUuid, roleUuids: $addRoleUuids }
    ) @include(if: $includeAddRoles) {
      ... on RdAddRolesToAdminSuccess {
        identityRoleAsmts {
          ...IdentityRoleAsmtFragment
        }
      }
      ... on RdAddRolesToAdminFailure {
        rdValidationErrors {
          ...ValidationErrorFragment
        }
      }
    }
  }

  ${IDENTITY_ROLE_ASMT_FRAGMENT}

  ${VALIDATION_ERROR_FRAGMENT}
`;
